<template>
  <div>
    <div>
      <div
        class="dashboard-content-inner my_custom_dash employer_manage_job mobile_min_width_1000 desktop_padding_top_zero"
      >
        <!-- Dashboard Headline -->
        <div class="dashboard-headline heading_with_btn manage_jobs_new_layout_btn">
          <h1>Manage Jobs</h1>
          <div class="manage_jobs_new_layout_btn_div">
            <b-link
              @click="openShareJobModalKeyword()"
              class="mrgn_left_zero_mobile btn green-border-btn text-white"
              v-if="show('copy url')"
            >
              Copy URL With Keywords
            </b-link>
            <b-link
              class="btn green-border-btn ml-2 text-white desktop_margin_top_zero transparent_btn"
              @click="exportJobsByName()"
              :class="jobDatas.length == 0 ? 'disabled' : ''"
              v-if="showPermissionEM('export job by name')"
              >Export Job By Name</b-link
            >
            <b-link
              class="btn green-border-btn ml-2 text-white desktop_margin_top_zero transparent_btn"
              @click="exportJobs()"
              :class="jobDatas.length == 0 ? 'disabled' : ''"
              v-if="showPermissionEM('export job')"
              >Export Job</b-link
            >
          </div>
          <b-link
            class="post_btn_manage_jobnew_layout btn green-border-btn desktop_margin_top_zero"
            :to="{ name: 'DailyJobPostAdmin' }"
            >Post Job</b-link
          >
          <!-- Breadcrumbs -->
          <nav id="breadcrumbs" class="dark employer_nav">
            <ul>
              <li><a href="#">Home</a></li>
              <li><a href="#">Job</a></li>
              <li>Manage Jobs</li>
            </ul>
          </nav>
        </div>
        <!-- Row -->
        <!-- <div class="row emplpyees_manage_job_emp_row" > -->
        <div class="row">
          <!-- Dashboard Box -->
          <!-- <div class="col-xl-12 mobile_left_right_zero_pdng"> -->
          <div class="col-xl-12">
            <div class="dashboard-box margin-top-0 daily_dashboard_box">
              <!-- Headline -->
              <!--<div class="headline">
                            <h3><i class="icon-material-outline-business-center"></i> My Job Listings</h3>
                        </div>-->
              <!-- <div v-if="loading" >
                        <div class = 'loader'>
                        </div> -->

              <!-- here put a spinner or whatever you want to indicate that a request is in progress -->
              <!-- </div> -->
              <!-- <div style="float: right;margin-top: -25px;">
                    <b-link class="btn green-border-btn" :to="{name:'DailyJobPost'}">Post Job</b-link>
                </div> -->
              <!-- <div class="double_btn" style="text-align:left !important"> -->

              <!-- <button>Create Job</button> -->

              <!-- <div class="col-xl-12"> -->

              <!-- </div> -->
              <div class="tab-section daily_job_mange_div manage_job_new_tabs_layout">
                <b-tabs class="my_tab_job remove_bx">
                  <div class="row desktop_search_top_div">
                    <div class="col-xl-12">
                      <div class="row">
                        <!-- <div class="upper_mix_functiobality"> -->
                        <!-- <div class="col-xl-4 col-md-4 mobile_max_width_and_flex mobile_paddding_unset search_div_daily_job new_search_manage_job"> -->
                        <div
                          class="col-xl-4 search_common_margin_bottom_28 submit-field mobile_tab_max_width_flex col-md-4 mobile_max_width_and_flex new_search_div_daily_job new_search_manage_job"
                        >
                          <div
                            class="search-box custom_search_admin new_custom_search_admin_remove"
                          >
                            <div role="group" class="input-group">
                              <!---->
                              <div
                                class="input-group-prepend new_border_color_manage_job search_text_with_btn_manage_job"
                              >
                                <input
                                  id="searchBtn"
                                  type="text"
                                  placeholder="Search by keyword"
                                  v-model="filterSearch"
                                  v-on:keyup.enter="
                                    getList(activeTab, activeApplicantTab, filterSearch)
                                  "
                                  class="form-control"
                                />
                                <b-button class="btn points_events"
                                  ><i
                                    class="fa fa-search"
                                    @click="getList(activeTab, activeApplicantTab, filterSearch)"
                                  ></i
                                ></b-button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-md-3 dropdown_daily_job">
                                    <div class="label_div text_align_end_desktop">
                                        <span>Company:</span>
                                    </div>
                                    <div class="input_div select_dropdown">
                                    <select name="cars" id="cars">
                                        <option value="All">All</option>
                                        <option value="saab">Saab</option>
                                        <option value="mercedes">Mercedes</option>
                                        <option value="audi">Audi</option>
                                        </select>
                                    </div>
                                </div> -->

                        <div
                          class="common_mobile_hide col-6 col-sm-6 col-md-6 col-xs-6 search_common_margin_bottom_28 submit-field"
                        >
                          <div class="reset_btn_full_flex">
                            <div
                              class="calender_div_job_in_job calneder_custom_icon width_100_per calender_border"
                            >
                              <label
                                class="new_style_label desktop_margin_bottom_zero new_layout_manage_job_label"
                                >Date Range:</label
                              >
                              <!-- <div class="input_div"> -->
                              <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">
                                <date-range-picker
                                  ref="picker"
                                  :minDate="minDate"
                                  :opens="center"
                                  :locale-data="locale"
                                  :auto-apply="auto"
                                  v-model="dateRange"
                                  :ranges="range"
                                  @update="
                                    changeTabStatus(
                                      activeTab,
                                      activeApplicantTab,
                                      filterSearch,
                                      'datepick'
                                    )
                                  "
                                  class="manage_job_date_claneder date_range_border_color_manage_job common_date_range_picker_new"
                                >
                                  <div slot="input" slot-scope="picker">
                                    {{ picker.startDate | datePicker1 }} -
                                    {{ picker.endDate | datePicker1 }}
                                  </div>
                                </date-range-picker>
                                <span class="input-icon manage_job_icon_input new_icon_input">
                                  <b-img
                                    v-bind:src="webUrl + 'assets/images/calendar-grey-icon02.svg'"
                                    alt="Calander Icon"
                                  ></b-img>
                                </span>
                              </b-form-group>
                              <!-- </div> -->
                              <!-- <div class="input_div">
                                                <input type="date" id="birthday" name="birthday">
                                            </div> -->
                            </div>
                            <!-- <div class="reset_btn_top reset_btn_mrgn reset_btn_manage_job_new">
                                        <button @click="clear" class="">Reset</button>
                                        </div> -->
                          </div>
                        </div>
                        <div class="common_mobile_hide col-xl-2 col-md-2">
                          <div class="reset_btn_top reset_btn_mrgn reset_btn_manage_job_new">
                            <button @click="clear" class="">Reset Filter</button>
                          </div>
                        </div>

                        <div
                          class="common_desktop_hide col-8 col-sm-8 col-md-8 col-xs-8 search_common_margin_bottom_28 submit-field"
                        >
                          <div class="reset_btn_full_flex">
                            <div
                              class="calender_div_job_in_job calneder_custom_icon width_100_per calender_border"
                            >
                              <label
                                class="new_style_label desktop_margin_bottom_zero new_layout_manage_job_label"
                                >Date Range:</label
                              >
                              <!-- <div class="input_div"> -->
                              <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">
                                <date-range-picker
                                  ref="picker"
                                  :minDate="minDate"
                                  :opens="center"
                                  :locale-data="locale"
                                  :auto-apply="auto"
                                  v-model="dateRange"
                                  :ranges="range"
                                  @update="
                                    changeTabStatus(activeTab, activeApplicantTab, filterSearch, '')
                                  "
                                  class="manage_job_date_claneder date_range_border_color_manage_job common_date_range_picker_new"
                                >
                                  <div slot="input" slot-scope="picker">
                                    {{ picker.startDate | datePicker1 }} -
                                    {{ picker.endDate | datePicker1 }}
                                  </div>
                                </date-range-picker>
                                <span class="input-icon manage_job_icon_input new_icon_input">
                                  <b-img
                                    v-bind:src="webUrl + 'assets/images/calendar-grey-icon02.svg'"
                                    alt="Calander Icon"
                                  ></b-img>
                                </span>
                              </b-form-group>
                              <!-- </div> -->
                              <!-- <div class="input_div">
                                                <input type="date" id="birthday" name="birthday">
                                            </div> -->
                            </div>
                            <!-- <div class="reset_btn_top reset_btn_mrgn reset_btn_manage_job_new">
                                        <button @click="clear" class="">Reset</button>
                                        </div> -->
                          </div>
                        </div>
                        <div class="common_desktop_hide col-4 col-sm-4 col-md-4 col-xs-4">
                          <div class="reset_btn_top reset_btn_mrgn reset_btn_manage_job_new">
                            <button @click="clear" class="">Reset Filter</button>
                          </div>
                        </div>
                        <!-- </div> -->
                      </div>
                    </div>
                  </div>
                  <b-tab
                    :title="'IN PROGRESS (' + totalRows + ')'"
                    :active="inprogress"
                    @click="changeStatus('in-progress', 'all', 'all', '')"
                    class=""
                  >
                    <div class="top_desc_div" v-for="(jobDataVal, jobDatakey) in jobDatas">
                      <div class="box_shadow box_new_shadow">
                        <div class="padding_manage_job">
                          <ul class="com_id_ul">
                            <li>{{ jobDataVal.job_unique_id }}</li>

                            <!-- <li>SERVICE AMT: S${{jobDataVal.service_fee}}</li> -->
                            <li v-if="jobDataVal.assign_job">
                              <button
                                class="demand_btn assign_user_name"
                                style="pointer-events: none"
                              >
                                Assigned User : {{ jobDataVal.assign_job.last_name }}
                                {{ jobDataVal.assign_job.first_name }}
                              </button>
                            </li>

                            <li>
                              <button
                                class="service_cat_name_manage_job"
                                style="pointer-events: none; border: 1px solid"
                                :style="{
                                  color: jobDataVal.service_category.color,
                                  borderColor: jobDataVal.service_category.color,
                                }"
                              >
                                {{ jobDataVal.service_category.name }}
                              </button>
                            </li>

                            <li class="dark-red-bg text-white" v-if="jobDataVal.job_type_id == 2">
                              {{ "ON DEMAND JOB" }}
                            </li>
                            <li
                              class="light-grey-bg text-black"
                              v-else-if="jobDataVal.job_type_id == 8"
                            >
                              {{ "RECOMMENDED JOB" }}
                            </li>
                            <li>
                              <strong>Service Amt: </strong
                              >{{
                                jobDataVal.service_fee != null
                                  ? "S$" + jobDataVal.service_fee
                                  : "S$" + 0
                              }}
                              <img
                                v-if="jobDataVal.is_exempted == 'yes'"
                                :src="webUrl + '/images/24hrs.png'"
                                alt="User Icon"
                                height="30px"
                                width="30px"
                              />
                            </li>
                            <li v-if="jobDataVal.approval_status == 'pending'">
                              <button
                                class="demand_btn"
                                style="
                                  background-color: rgb(229, 138, 10) !important;
                                  pointer-events: none;
                                "
                              >
                                Pending Approval
                              </button>
                            </li>

                            <li v-if="jobDataVal.job_type_id == 2">
                              <button
                                class="demand_btn"
                                style="background: #28a745; pointer-events: none"
                              >
                                ON DEMAND JOB
                              </button>
                            </li>
                          </ul>
                          <div
                            class="toggle-action common_ul_radius toggle_margin_unset new-action-view-detail"
                          >
                            <!-- <b-dropdown class="mx-1" right text="Action" boundary="window"> -->
                            <b-dropdown class="mx-1" left text="Action" boundary="window">
                              <b-dropdown-item
                                @click="
                                  addNote(
                                    jobDataVal.id,
                                    jobDataVal.company_id,
                                    jobDataVal.assign_job
                                  )
                                "
                                v-if="showPermissionEM('assign user')"
                                >Assign Supervisor
                              </b-dropdown-item>

                              <b-dropdown-item
                                v-if="jobDataVal.ot_job_created"
                                v-on:click="openOTModal(jobDataVal.ot_job_id, 'view')"
                                class="mrgn_left_zero_mobile"
                                >View OT Job</b-dropdown-item
                              >

                              <b-dropdown-item @click="openBroadcastModal(jobDataVal.id)"
                                >Brodcast
                              </b-dropdown-item>
                              <b-dropdown-item
                                @click="
                                  openTransactionModal(
                                    jobDataVal.id,
                                    jobDataVal.job_company.name,
                                    jobDataVal.job_unique_id,
                                    'in-progress'
                                  )
                                "
                                v-if="showPermissionEM('assign user')"
                                >Create Transaction
                              </b-dropdown-item>
                              <b-dropdown-item
                                @click="
                                  addNote(
                                    jobDataVal.id,
                                    jobDataVal.company_id,
                                    jobDataVal.assign_job
                                  )
                                "
                                v-if="showPermissionEM('assign user')"
                                >Export Freelancer
                              </b-dropdown-item>
                              <b-dropdown-item
                                @click="
                                  addNote(
                                    jobDataVal.id,
                                    jobDataVal.company_id,
                                    jobDataVal.assign_job
                                  )
                                "
                              >
                                Edit Jobs
                              </b-dropdown-item>
                              <b-dropdown-item
                                :to="{
                                  name: 'repostJobDaily',
                                  params: { id: encrypt(jobDataVal.id) },
                                }"
                                v-if="showPermissionEM('duplicate job')"
                                >Repost
                              </b-dropdown-item>
                              <b-dropdown-item
                                @click="
                                  openShareJobModal(
                                    jobDataVal.id,
                                    jobDataVal.company_id,
                                    jobDataVal.job_company.name,
                                    jobDataVal.job_company.alias,
                                    jobDataVal.posted_by,
                                    jobDataVal.alias
                                  )
                                "
                                >Share Job
                              </b-dropdown-item>
                              <b-dropdown-item
                                @click="cancelJob(jobDataVal.id)"
                                v-if="showPermissionEM('assign user')"
                                >Cancel Job
                              </b-dropdown-item>
                              <b-dropdown-item
                                @click="
                                  showQR(
                                    jobDataVal.job_code,
                                    jobDataVal.job_unique_id,
                                    jobDataVal.id
                                  )
                                "
                                v-if="showPermissionEM('assign user')"
                                >Show QR Code
                              </b-dropdown-item>
                              <b-dropdown-item
                                @click="
                                  addNote(
                                    jobDataVal.id,
                                    jobDataVal.company_id,
                                    jobDataVal.assign_job
                                  )
                                "
                                v-if="
                                  showPermissionEM('assign user') && jobDataVal.job_type_id == 1
                                "
                                >Set Recomended Job
                              </b-dropdown-item>
                              <b-dropdown-item
                                @click="
                                  setBackToNormalJob(
                                    jobDataVal.job_type_id == 8
                                      ? 'recommended_to_normal'
                                      : 'on_demand_to_normal'
                                  )
                                "
                                v-if="
                                  showPermissionEM('assign user') && jobDataVal.job_type_id == 8
                                "
                                >SET BACK TO NORMAL JOB
                              </b-dropdown-item>
                              <b-dropdown-item
                                @click="resetVacancy(jobDataVal.id, 'full')"
                                v-if="showPermissionEM('assign user')"
                                >Update to Full Vacancy
                              </b-dropdown-item>

                              <!-- <b-dropdown-item
                                v-on:click="
                                  showQR(
                                    jobDataVal.job_code,
                                    jobDataVal.job_unique_id,
                                    jobDataVal.id
                                  )
                                "
                                v-if="
                                  jobDataVal.job_company &&
                                  jobDataVal.job_company.company_admin.qr_based_arrival == 'yes' &&
                                  jobDataVal.start_date + ' ' + jobDataVal.start_time <= addDays
                                "
                                >Show QR Code
                              </b-dropdown-item> -->
                              <b-dropdown-item
                                @click="locationPtuser(jobDataVal.id, jobDataVal.assigned_user)"
                                >Track Users
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                          <div class="heading_with_status">
                            <div class="comp_heading_div">
                              <h2 class="top_color_h2_single">{{ jobDataVal.title }}</h2>
                              <viewer>
                                <img
                                  v-if="
                                    jobDataVal.job_company &&
                                    jobDataVal.job_company.thumbnail != null
                                  "
                                  :src="jobDataVal.job_company.thumbnail"
                                  :key="jobDataVal.thumbnail"
                                  alt="User Icon"
                                />
                                <img
                                  v-else
                                  :src="webUrl + '/images/small-logo.png'"
                                  alt="User Icon"
                                />
                              </viewer>
                              <h3 class="top_color_h2_single">{{ jobDataVal.job_company.name }}</h3>

                              <div class="dtl_text" v-if="jobDataVal.actual_posted_by">
                                <h2>Posted By:</h2>
                                <p class="mb-0">
                                  {{
                                    jobDataVal.actual_posted_by &&
                                    jobDataVal.actual_posted_by.last_name
                                      ? jobDataVal.actual_posted_by.last_name
                                      : ""
                                  }}
                                  {{
                                    jobDataVal.actual_posted_by &&
                                    jobDataVal.actual_posted_by.first_name
                                      ? jobDataVal.actual_posted_by.first_name
                                      : ""
                                  }}
                                </p>
                                <p class="mb-0">
                                  {{ jobDataVal.created_at | customFormatterOnecreated }}
                                </p>
                              </div>
                            </div>
                            <!-- <div class="rate_div new_rate_divs"> -->

                            <!-- <div class="status_btn new_status_btn_divs"> -->
                            <!-- <div class="rate_text"> -->
                            <!-- <h2 class="rate_text" v-if="showPermissionEM('show list rate') && showJobListRateAdmin('show_job_list_rate')">S${{jobDataVal.hourly_cost}}/HR</h2> -->
                            <!-- </div> -->
                            <!-- <button class="ack_btn new_ack_btn_divs"  @click="openAttachSupModal(jobDataVal.id)">Assign User</button>
                                                                <b-link href="" :to="{name: 'repostJobDaily', params: {id: encrypt(jobDataVal.id)}}" class="button gray ripple-effect ico repost_icon_btn" title="Repost" data-tippy-placement="top"><i class="fa fa-retweet"></i></b-link>
                                                                <b-link href="" :to="{name: 'DailyJobEdit', params: {id: encrypt(jobDataVal.id)}}" class="button gray ripple-effect ico edit_icon_btn" title="Edit" data-tippy-placement="top"><i class="fa fa-edit"></i></b-link>
                                                                <b-button style="background-color:#4A5060;color: white;" v-on:click="showQR(jobDataVal.job_code, jobDataVal.job_unique_id, jobDataVal.id)" v-if="jobDataVal.userData.qr_based_arrival == 'yes' && (jobDataVal.start_date+' '+jobDataVal.start_time) <= addDays">Show QR Code</b-button>
                                                                <button class="ack_btn new_ack_btn_divs"  @click="locationPtuser(jobDataVal.id, jobDataVal.assigned_user)">Track Users</button>
                                                            -->
                            <!-- <b-link class="button gray ripple-effect ico repost_icon_btn"  @click="openAttachSupModal(jobDataVal.id)" v-if="showPermissionEM('assign user')" title="Assign User" data-tippy-placement="top"><i class="material-icons-outlined assign_users_i">person_add_alt_outlined</i></b-link>
                                                            <b-link href="" :to="{name: 'repostJobDaily', params: {id: encrypt(jobDataVal.id)}}" v-if="showPermissionEM('duplicate job')" class="button gray ripple-effect ico repost_icon_btn" title="Repost" data-tippy-placement="top"><i class="material-icons-outlined assign_users_i">repeat_outlined</i></b-link>
                                                            <b-link style="background-color:#4A5060;color: white;" v-on:click="showQR(jobDataVal.job_code, jobDataVal.job_unique_id, jobDataVal.id)" v-if="jobDataVal.userData && jobDataVal.userData.companydata && jobDataVal.userData.companydata.companyuser.qr_based_arrival == 'yes' && (jobDataVal.start_date+' '+jobDataVal.start_time) <= addDays" class="button gray ripple-effect ico repost_icon_btn" title="Show QR Code" data-tippy-placement="top"><i class="material-icons-outlined assign_users_i">qr_code_2_outlined</i></b-link>
                                                            <b-link class="button gray ripple-effect ico repost_icon_btn"  @click="locationPtuser(jobDataVal.id, jobDataVal.assigned_user)" title="Track Users" data-tippy-placement="top"><i class="material-icons-outlined assign_users_i">person_search_outlined</i></b-link> -->

                            <!-- </div> -->

                            <!-- <div class="rate_text" v-if="showPermissionEM('show list rate') && showJobListRateAdmin('show_job_list_rate')">
                                                        <h2>S${{jobDataVal.hourly_cost}}/HR</h2>
                                                    </div> -->

                            <!-- <div class="action_div">
                                                        <button class="action_btn">ACTIONS<i class="fa fa-caret-down"></i></button>
                                                    </div> -->
                            <!-- </div> -->
                          </div>
                          <!-- <div class="img_with_desc">

                                            </div> -->
                          <ul class="manage_job_top_ul_nw">
                            <li>
                              <span class="material-icons-outlined">calendar_month_outlined</span
                              >{{ jobDataVal.start_date | moment_1 }}
                            </li>
                            <li>
                              <span class="material-icons-outlined">access_time_outlined</span
                              >{{ jobDataVal.start_time | jobStartEndTime }} -
                              {{ jobDataVal.end_time | jobStartEndTime }}
                              <span class="red_note_manage_job"
                                ><p v-if="jobDataVal.lunch_pay_hours != null">
                                  <strong>Note:</strong> Break hour not paid -
                                  {{ jobDataVal.lunch_pay_hours }} Hr
                                </p></span
                              >
                            </li>
                            <li
                              v-if="
                                showPermissionEM('show list rate') &&
                                showJobListRateAdmin('show_job_list_rate')
                              "
                            >
                              <span class="material-icons-outlined">monetization_on_outlined</span
                              >${{ jobDataVal.hourly_cost }}/Hour
                            </li>
                          </ul>
                          <span class="view-count-text fa fa-eye" v-if="jobDataVal.view_count"
                            ><span>{{
                              jobDataVal.view_count ? jobDataVal.view_count : 0
                            }}</span></span
                          >
                          <div class="user_apl">
                            <div class="person_div_manage_job">
                              <span class="material-icons person_logo">person</span>
                              <span class="total_vacany_list"
                                >Applicant / Total Vacancy:
                                {{ jobDataVal.job_count_active_working_count }}/{{
                                  totalVacancy(jobDataVal.total_vacancies)
                                }}</span
                              >
                            </div>
                            <div class="person_div_manage_job">
                              <span class="material-icons person_logo">person</span>
                              <span class="total_vacany_list"
                                >Applicant / True Vacancy:
                                {{ jobDataVal.job_count_active_working_count }}/{{
                                  jobDataVal.true_vacancies
                                }}</span
                              >
                            </div>
                            <div class="working_completed_section">
                              <span>Working: {{ jobDataVal.job_count_active_working_count }}</span
                              ><span>Completed: {{ jobDataVal.job_count_completed_count }}</span>
                            </div>
                          </div>
                          <div class="full_desc_emps">
                            <!-- <ul>
                                  <li>Posted By:<span>{{(jobDataVal.userData && jobDataVal.userData.last_name) ? jobDataVal.userData.last_name : ''}} {{(jobDataVal.userData && jobDataVal.userData.first_name) ? jobDataVal.userData.first_name : ''}}</span></li>
                                  <li>Job Posted D& Time:<span>{{jobDataVal.start_date | moment}} <br> {{jobDataVal.start_time | jobStartEndTime}}</span></li>
                                  <li>Job Duration:<span>{{jobDataVal.start_date | moment}} <br> {{jobDataVal.start_time | jobStartEndTime}} - {{jobDataVal.start_time | jobStartEndTime}}</span></li>
                                  <li>Job Hours:<span>{{jobDataVal.working_hours}}</span></li>
                                  <li>Vacancy:<span>{{jobDataVal.userJobs.length}}/{{jobDataVal.total_vacancies}}</span></li>
                                  <li v-if="jobDataVal.work_site_contact_person">Work Site Contact Person Name:<span>{{jobDataVal.work_site_contact_person ? jobDataVal.work_site_contact_person : ''}}</span></li>
                                  <li v-if="jobDataVal.work_site_contact_person_mobile_no">Work Site Contact Person Number:<span>{{jobDataVal.work_site_contact_person_country_code ? jobDataVal.work_site_contact_person_country_code : ''}}-{{jobDataVal.work_site_contact_person_mobile_no ? jobDataVal.work_site_contact_person_mobile_no : ''}}</span></li>
                              </ul> -->
                            <div class="single_accordian manage_job_accordian mb-3">
                              <!-- <br> -->
                              <div>
                                <div class="faq-content-box clearfix">
                                  <div>
                                    <div class="accordion-listing-box coloured_icon">
                                      <b-card no-body>
                                        <b-card-header header-tag="header" role="tab">
                                          <b-button
                                            v-b-toggle="
                                              'accordion_view_job_details_inprogress-' + jobDatakey
                                            "
                                            class="m-1"
                                            variant="info"
                                          >
                                            <div class="dashboard-box">
                                              <div class="headline">
                                                <h3 class="heading_section_h3">VIEW JOB DETAILS</h3>
                                              </div>
                                            </div>
                                          </b-button>
                                        </b-card-header>
                                        <b-collapse
                                          :id="
                                            'accordion_view_job_details_inprogress-' + jobDatakey
                                          "
                                          role="tabpanel"
                                        >
                                          <b-card-body>
                                            <div class="">
                                              <div class="row">
                                                <div class="col-xl-12">
                                                  <div
                                                    class="comp_heading_div_manage_job"
                                                    v-if="jobDataVal.actual_posted_by"
                                                  >
                                                    <div class="dtl_text">
                                                      <h2>Posted By:</h2>
                                                      <p class="mb-0">
                                                        {{
                                                          jobDataVal.actual_posted_by &&
                                                          jobDataVal.actual_posted_by.last_name
                                                            ? jobDataVal.actual_posted_by.last_name
                                                            : ""
                                                        }}
                                                        {{
                                                          jobDataVal.actual_posted_by &&
                                                          jobDataVal.actual_posted_by.first_name
                                                            ? jobDataVal.actual_posted_by.first_name
                                                            : ""
                                                        }}
                                                      </p>
                                                      <p class="mb-0">
                                                        {{
                                                          jobDataVal.created_at
                                                            | customFormatterOnecreated
                                                        }}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div
                                                    class="table-responsive whole_custom_tbl common_table_scroll manage_job_new_tblz"
                                                  >
                                                    <table
                                                      class="table custom_manage_job_tbl unset_last_super"
                                                    >
                                                      <thead>
                                                        <tr>
                                                          <!-- <th v-if="jobDataVal.userData">Posted By:</th> -->
                                                          <!-- <th>Job Posted Date & Time:</th> -->
                                                          <!-- <th>Job Date & Time:</th> -->
                                                          <!-- <th>Job Hours:</th> -->
                                                          <!-- <th>Vacancy:</th> -->
                                                          <th
                                                            v-if="
                                                              jobDataVal.work_site_contact_person ||
                                                              jobDataVal.work_site_contact_person_mobile_no
                                                            "
                                                          >
                                                            Work Site Contact Person
                                                          </th>
                                                          <th></th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        <tr>
                                                          <!-- <td v-if="jobDataVal.userData">
                                                                                            {{(jobDataVal.userData && jobDataVal.userData.last_name) ? jobDataVal.userData.last_name : ''}} {{(jobDataVal.userData && jobDataVal.userData.first_name) ? jobDataVal.userData.first_name : ''}}
                                                                                        </td> -->
                                                          <!-- <td>
                                                                                            {{jobDataVal.created_at | customFormatterOnecreated}}
                                                                                        </td> -->
                                                          <!-- <td>{{jobDataVal.start_date | moment_1}} {{jobDataVal.start_time | jobStartEndTime}} - {{jobDataVal.end_time | jobStartEndTime}}</td> -->
                                                          <!-- <td>{{jobDataVal.working_hours}}</td> -->
                                                          <!-- <td>
                                                                                            {{jobDataVal.userJobsselected.length}}/{{jobDataVal.total_vacancies}}
                                                                                        </td> -->
                                                          <td
                                                            v-if="
                                                              jobDataVal.work_site_contact_person ||
                                                              jobDataVal.work_site_contact_person_mobile_no
                                                            "
                                                          >
                                                            {{
                                                              jobDataVal.work_site_contact_person
                                                                ? jobDataVal.work_site_contact_person
                                                                : ""
                                                            }}
                                                            <br />
                                                            {{
                                                              jobDataVal.work_site_contact_person_country_code
                                                                ? jobDataVal.work_site_contact_person_country_code +
                                                                  "-"
                                                                : ""
                                                            }}{{
                                                              jobDataVal.work_site_contact_person_mobile_no
                                                                ? jobDataVal.work_site_contact_person_mobile_no
                                                                : ""
                                                            }}<br />
                                                          </td>
                                                          <td
                                                            v-if="
                                                              jobDataVal.work_site_contact_person ||
                                                              jobDataVal.work_site_contact_person_mobile_no
                                                            "
                                                          >
                                                            {{
                                                              jobDataVal.work_site_contact_person_2
                                                                ? jobDataVal.work_site_contact_person_2
                                                                : ""
                                                            }}<br />
                                                            {{
                                                              jobDataVal.work_site_contact_person_2_mobile_no
                                                                ? jobDataVal.work_site_contact_person_2_country_code +
                                                                  "-"
                                                                : ""
                                                            }}{{
                                                              jobDataVal.work_site_contact_person_2_mobile_no
                                                                ? jobDataVal.work_site_contact_person_2_mobile_no
                                                                : ""
                                                            }}
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                  <div class="dtl_text">
                                                    <div
                                                      class="large-content-requirement-inprogress"
                                                    >
                                                      <h2 v-if="jobDataVal.description">
                                                        Job Requirements
                                                        <span class="manage_job_eye_span"
                                                          ><i
                                                            class="fa fa-eye-slash"
                                                            :id="
                                                              'manage_job_requirement_eye_icon' +
                                                              jobDatakey
                                                            "
                                                            @click="
                                                              ManageJobRequirementEyeReveal(
                                                                jobDatakey
                                                              )
                                                            "
                                                            style="cursor: pointer"
                                                          ></i
                                                        ></span>
                                                      </h2>
                                                      <p
                                                        :id="
                                                          'visible-content-requirement-inprogress' +
                                                          jobDatakey
                                                        "
                                                        style=""
                                                        class=""
                                                        v-if="jobDataVal.description.length <= 249"
                                                      >
                                                        {{
                                                          jobDataVal.description.substring(0, 249)
                                                        }}
                                                      </p>
                                                      <p
                                                        :id="
                                                          'visible-content-requirement-inprogress' +
                                                          jobDatakey
                                                        "
                                                        style=""
                                                        class=""
                                                        v-if="jobDataVal.description.length >= 250"
                                                      >
                                                        {{
                                                          jobDataVal.description.substring(0, 250) +
                                                          ".."
                                                        }}
                                                      </p>
                                                      <p
                                                        :id="
                                                          'invisible-content-requirement-inprogress' +
                                                          jobDatakey
                                                        "
                                                        style="display: none"
                                                        class=""
                                                      >
                                                        {{ jobDataVal.description }}
                                                      </p>
                                                    </div>
                                                    <div class="large-content-scope-inprogress">
                                                      <h2 v-if="jobDataVal.job_scope">
                                                        Job Scope:
                                                        <span class="manage_job_eye_span"
                                                          ><i
                                                            class="fa fa-eye-slash"
                                                            :id="
                                                              'manage_job_scope_eye_icon' +
                                                              jobDatakey
                                                            "
                                                            @click="
                                                              ManageJobScopeEyeReveal(jobDatakey)
                                                            "
                                                            style="cursor: pointer"
                                                          ></i
                                                        ></span>
                                                      </h2>
                                                      <p
                                                        :id="
                                                          'visible-content-scope-inprogress' +
                                                          jobDatakey
                                                        "
                                                        style=""
                                                        class=""
                                                        v-if="jobDataVal.job_scope.length <= 249"
                                                      >
                                                        {{ jobDataVal.job_scope.substring(0, 249) }}
                                                      </p>
                                                      <p
                                                        :id="
                                                          'visible-content-scope-inprogress' +
                                                          jobDatakey
                                                        "
                                                        style=""
                                                        class=""
                                                        v-if="jobDataVal.job_scope.length >= 250"
                                                      >
                                                        {{
                                                          jobDataVal.job_scope.substring(0, 250) +
                                                          ".."
                                                        }}
                                                      </p>
                                                      <p
                                                        :id="
                                                          'invisible-content-scope-inprogress' +
                                                          jobDatakey
                                                        "
                                                        style="display: none"
                                                        class=""
                                                      >
                                                        {{ jobDataVal.job_scope }}
                                                      </p>
                                                    </div>
                                                    <h2 v-if="jobDataVal.address">Job Location</h2>
                                                    <p v-if="jobDataVal.address" class="mb-0">
                                                      {{ jobDataVal.address }}
                                                    </p>
                                                    <!-- <p v-if="jobDataVal.address"><i class="fa fa-map-marker"></i>{{jobDataVal.address}}</p> -->
                                                    <!-- <p v-if="jobDataVal.lunch_pay_hours != null"><strong>Note:</strong>Break hour not paid - {{jobDataVal.lunch_pay_hours}} Hr</p> -->
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </b-card-body>
                                        </b-collapse>
                                      </b-card>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="single_accordian manage_job_accordian">
                              <!-- <br> -->
                              <div>
                                <div class="faq-content-box clearfix">
                                  <div>
                                    <div class="accordion-listing-box">
                                      <b-card no-body>
                                        <b-card-header
                                          header-tag="header"
                                          role="tab"
                                          @click="
                                            getList(
                                              jobDataVal.id,
                                              jobDatakey,
                                              'in-progress',
                                              user_id
                                            )
                                          "
                                        >
                                          <b-button
                                            v-b-toggle="
                                              'accordion_view_candidates_inprogress-' + jobDatakey
                                            "
                                            class="m-1"
                                            variant="info"
                                          >
                                            <div class="dashboard-box">
                                              <div class="headline">
                                                <h3 class="heading_section_h3">View candidates</h3>
                                              </div>
                                            </div>
                                          </b-button>
                                        </b-card-header>
                                        <b-collapse
                                          :id="'accordion_view_candidates_inprogress-' + jobDatakey"
                                          role="tabpanel"
                                        >
                                          <b-card-body>
                                            <!-- <div class="col-md-4">
                                              <div class="search-box">
                                                <b-form-group>
                                                  <b-form-input
                                                    id="searchBtn"
                                                    v-model="filterSearchSelected"
                                                    @change="
                                                      changeApplicantTabStatus(
                                                        'inprogress',
                                                        'selected',
                                                        jobDataVal.id,
                                                        jobDatakey,
                                                        jobDataVal.posted_by
                                                      )
                                                    "
                                                    placeholder="Search by Name"
                                                  ></b-form-input>
                                                  <b-button class="search_btn_tw_grd"></b-button>
                                                </b-form-group>
                                              </div>
                                            </div> -->
                                            <div class="">
                                              <div class="row">
                                                <div class="col-xl-12">
                                                  <b-tabs
                                                    class="custom_tabs_daily_manage_jobs custom_tabs_daily_manage_jobs_inprogress"
                                                  >
                                                    <div v-if="jobDataVal.selected">
                                                      <b-tab
                                                        active
                                                        :title="
                                                          'SELECTED (' +
                                                          jobDataVal.selected.length +
                                                          ')'
                                                        "
                                                        class="reject_last_li mobile_min_width_1000"
                                                        @click="
                                                          getList(
                                                            jobDataVal.id,
                                                            jobDatakey,
                                                            'in-progress',
                                                            user_id
                                                          )
                                                        "
                                                      >
                                                        <div class="col-md-8">
                                                          <div
                                                            class="listing-head-right text-lg-right text-sm-right mt-sm-0 mt-2"
                                                          >
                                                            <b-link
                                                              v-if="show('download list')"
                                                              @click="downloadList(jobDataVal.id)"
                                                              class="btn green-bg-btn green_download_btn search_btn_green_width"
                                                              >Download List</b-link
                                                            >
                                                            <b-link
                                                              v-if="show('add fl')"
                                                              @click="
                                                                openArrivalModel(
                                                                  '',
                                                                  'arrival',
                                                                  true
                                                                )
                                                              "
                                                              :disabled="
                                                                selectedUser.length > 0 &&
                                                                !selectAllCompleted
                                                                  ? false
                                                                  : true
                                                              "
                                                              class="btn green-bg-btn green_download_btn search_btn_green_width"
                                                              >Adjust Arrival Time</b-link
                                                            >
                                                            <b-link
                                                              v-if="show('add fl')"
                                                              @click="
                                                                openArrivalModel(
                                                                  '',
                                                                  'adjust_arrival',
                                                                  true
                                                                )
                                                              "
                                                              :disabled="
                                                                selectAllCompleted ? false : true
                                                              "
                                                              class="btn green-bg-btn green_download_btn search_btn_green_width"
                                                              >Adjust User Time</b-link
                                                            >
                                                            <b-link
                                                              v-if="show('add fl')"
                                                              @click="
                                                                applicantArrival(
                                                                  '',
                                                                  'ptArrival',
                                                                  true
                                                                )
                                                              "
                                                              :disabled="
                                                                selectedUser.length > 0 &&
                                                                !selectAllCompleted
                                                                  ? false
                                                                  : true
                                                              "
                                                              class="btn green-bg-btn green_download_btn search_btn_green_width"
                                                              >Mark Arrival</b-link
                                                            >
                                                            <b-link
                                                              v-if="show('add fl')"
                                                              @click="
                                                                applicantArrival(
                                                                  '',
                                                                  'ptCompletion',
                                                                  true
                                                                )
                                                              "
                                                              :disabled="
                                                                selectedUser.length > 0 &&
                                                                !selectAllCompleted
                                                                  ? false
                                                                  : true
                                                              "
                                                              class="btn green-bg-btn green_download_btn search_btn_green_width"
                                                              >Mark Completion</b-link
                                                            >
                                                          </div>
                                                        </div>
                                                        <div
                                                          v-if="
                                                            jobDataVal.selected &&
                                                            jobDataVal.selected.length > 0
                                                          "
                                                        >
                                                          <div
                                                            class="row"
                                                            v-if="jobDataVal.selected.length > 0"
                                                          >
                                                            <div
                                                              class="col-md-6 col-sm-12 mobile_max_widht_and_flex"
                                                            >
                                                              <div
                                                                class="select-service-box clearfix check_label_single"
                                                              >
                                                                <b-form-group>
                                                                  <b-form-checkbox
                                                                    v-model="selectAll"
                                                                    @change="allSelected(jobDatakey)"
                                                                    >Select All
                                                                    In-Progress</b-form-checkbox
                                                                  >
                                                                  <b-form-checkbox
                                                                    v-model="selectAllCompleted"
                                                                    @change="
                                                                      allSelectCompleted(jobDatakey)
                                                                    "
                                                                    >Select All
                                                                    Completed</b-form-checkbox
                                                                  >
                                                                </b-form-group>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <b-table
                                                            ref="datatable"
                                                            show-empty
                                                            striped
                                                            hover
                                                            responsive
                                                            :items="jobDataVal.selected"
                                                            :fields="fields"
                                                            :filter="filter"
                                                            :sort-by.sync="sortBy"
                                                            :sort-desc.sync="sortDesc"
                                                            :sort-direction="sortDirection"
                                                            class="tbl_height new_box_tbl_mrgn daily_manage_job_dipper"
                                                          >
                                                            <template #cell(checkbox)="items">
                                                              <b-col lg="3" md="3">
                                                                <b-form-group>
                                                                  <b-form-checkbox
                                                                    :value="items.item.id"
                                                                    v-model="selectedUser"
                                                                  ></b-form-checkbox>
                                                                </b-form-group>
                                                              </b-col>
                                                            </template>
                                                            <template #cell(name)="items">
                                                              <div class="daily_image_job">
                                                                <div
                                                                  class="daily_job_manage_img_div"
                                                                >
                                                                  <b-img
                                                                    :src="
                                                                      items.item.profile_image !=
                                                                      null
                                                                        ? items.item.profile_image
                                                                        : webUrl +
                                                                          'assets/images/user-avatar-small-01.png'
                                                                    "
                                                                    alt="User Icon"
                                                                    height="100px"
                                                                    width="100px"
                                                                    class="user_img_emp"
                                                                  ></b-img>
                                                                  <div
                                                                    class="custom_star_rate_manage_job"
                                                                  >
                                                                    <span
                                                                      ><i class="fa fa-star"></i
                                                                      >{{
                                                                        items.item.average_rating
                                                                          ? items.item
                                                                              .average_rating
                                                                          : 0
                                                                      }}</span
                                                                    >
                                                                  </div>
                                                                </div>
                                                                <div
                                                                  class="daily_job_manage_user_description_div"
                                                                >
                                                                  <b-link
                                                                    v-if="
                                                                      showPermissionEM(
                                                                        'view profile'
                                                                      )
                                                                    "
                                                                    :to="{
                                                                      name: 'employer-jobseeker-dp-profile-view',
                                                                      params: {
                                                                        id: encrypt(
                                                                          items.item.user_id
                                                                        ),
                                                                      },
                                                                    }"
                                                                    target="_blank"
                                                                  >
                                                                    <h2>
                                                                      {{
                                                                        items.item.last_name
                                                                          ? items.item.last_name
                                                                          : ""
                                                                      }}
                                                                      {{
                                                                        items.item.first_name
                                                                          ? items.item.first_name
                                                                          : ""
                                                                      }}
                                                                    </h2></b-link
                                                                  >
                                                                  <h2 v-else>
                                                                    {{
                                                                      items.item.last_name
                                                                        ? items.item.last_name
                                                                        : ""
                                                                    }}
                                                                    {{
                                                                      items.item.first_name
                                                                        ? items.item.first_name
                                                                        : ""
                                                                    }}
                                                                  </h2>
                                                                  <!-- <template #cell(mobile_number)="items"> -->
                                                                  <div class="phone_nmber_div">
                                                                    <a href="#"
                                                                      >{{
                                                                        items.item.country_code
                                                                          ? items.item.country_code
                                                                          : ""
                                                                      }}-{{
                                                                        items.item.mobile_number
                                                                          ? items.item.mobile_number
                                                                          : ""
                                                                      }}</a
                                                                    >
                                                                  </div>
                                                                  <!-- </template> -->
                                                                  <div class="star-rating">
                                                                    <!-- <star-rating v-model="items.item.userdetail.average_rating" v-bind:star-size="20" :show-rating="false"></star-rating> -->
                                                                    <div class="com_imgs">
                                                                      <b-img
                                                                        class="worked_for_logo"
                                                                        v-if="
                                                                          items.item
                                                                            .worked_for_company !=
                                                                            null &&
                                                                          items.item
                                                                            .worked_for_company ==
                                                                            'yes'
                                                                        "
                                                                        :src="
                                                                          jobDataVal.job_company
                                                                            .thumbnail
                                                                        "
                                                                        :title="
                                                                          jobDataVal.job_company
                                                                            .name
                                                                        "
                                                                        alt="User Icon"
                                                                      ></b-img>
                                                                    </div>
                                                                    <!-- <span class="star"></span>
                                                                                        <span class="star"></span>
                                                                                        <span class="star"></span>
                                                                                        <span class="star"></span>
                                                                                        <span class="star"></span> -->
                                                                  </div>

                                                                  <div
                                                                    v-if="
                                                                      items.item.user_badges != null
                                                                    "
                                                                  >
                                                                    <b-img
                                                                      class="worked_for_logo"
                                                                      v-for="(usb, us) in items.item
                                                                        .user_badges"
                                                                      :key="us"
                                                                      :src="usb.badges.badge_image"
                                                                      :title="usb.badges.name"
                                                                      alt="User Icon"
                                                                    ></b-img>
                                                                  </div>
                                                                  <div>
                                                                    <p
                                                                      v-if="
                                                                        jobDataVal.proposed_arrival_time !=
                                                                          null &&
                                                                        jobDataVal.job_type_id == 2
                                                                      "
                                                                    >
                                                                      ETA:
                                                                      {{
                                                                        jobDataVal.proposed_arrival_time !=
                                                                        null
                                                                          ? jobDataVal.proposed_arrival_time
                                                                          : "" | moment
                                                                      }}
                                                                      |
                                                                      {{
                                                                        jobDataVal.proposed_arrival_time !=
                                                                        null
                                                                          ? jobDataVal.proposed_arrival_time
                                                                          : ""
                                                                            | applicantOriginalSET
                                                                      }}
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </template>
                                                            <template #cell(mobile_number1)="items">
                                                              <div class="phone_nmber_div">
                                                                <a href="#"
                                                                  >Upcoming Applied -
                                                                  {{ items.item.applied_count }}</a
                                                                >
                                                              </div>
                                                              <div class="phone_nmber_div">
                                                                <a href="#"
                                                                  >Completed -
                                                                  {{
                                                                    items.item.completed_count
                                                                  }}</a
                                                                >
                                                              </div>
                                                            </template>
                                                            <!-- <template #cell(mobile_number)="items">
                                                                            <div class="phone_nmber_div">
                                                                                <a href="#">{{items.item.userdetail.country_code ? items.item.userdetail.country_code : ''}}-{{items.item.userdetail.mobile_number ? items.item.userdetail.mobile_number : ''}}</a>
                                                                            </div>
                                                                            </template> -->
                                                            <template #cell(time)="items">
                                                              <div class="timing_div arrow_time">
                                                                <ul>
                                                                  <li>
                                                                    <i
                                                                      class="fa fa-arrow-down green_arrow"
                                                                    ></i
                                                                    ><span>{{
                                                                      items.item.start_time
                                                                        ? items.item.start_time
                                                                        : "" | applicantStartEndTime
                                                                    }}</span>
                                                                    <i
                                                                      class="fa fa-arrow-up red_arrow green_arrow"
                                                                    ></i
                                                                    ><span>{{
                                                                      items.item.end_time
                                                                        ? items.item.end_time
                                                                        : "" | applicantStartEndTime
                                                                    }}</span>
                                                                  </li>
                                                                </ul>
                                                              </div>
                                                            </template>
                                                            <template #cell(thumb)="items">
                                                              <div class="phone_nmber_div">
                                                                <a href="#">{{
                                                                  items.item.extended_hours
                                                                    ? "+" +
                                                                      items.item.extended_hours +
                                                                      " Hours @$" +
                                                                      items.item.extension_rate
                                                                    : ""
                                                                }}</a>
                                                              </div>
                                                            </template>

                                                            <template #cell(ac_button)="items">
                                                              <div
                                                                class="status_btn"
                                                                v-if="
                                                                  items.item.mark_as_arrived == null
                                                                "
                                                              >
                                                                <button
                                                                  class="ack_btn tag_ack_btn upcoming_btn"
                                                                  style="background-color: #ffbb00"
                                                                >
                                                                  Upcoming
                                                                </button>
                                                                <b-button
                                                                  v-if="show('confirm arrival')"
                                                                  class="green-border-btn"
                                                                  @click="
                                                                    applicantArrival(
                                                                      items.item.user_id,
                                                                      'ptArrivalConfirmation',
                                                                      false,
                                                                      items.item.job_id,
                                                                      items.item.lat,
                                                                      items.item.long,
                                                                      items.item.job_code
                                                                    )
                                                                  "
                                                                  >CONFIRM ARRIVAl</b-button
                                                                >

                                                                <li
                                                                  v-if="
                                                                    items.item.acknowledged ==
                                                                      'yes' &&
                                                                    items.item.job_status !=
                                                                      'suspended'
                                                                  "
                                                                >
                                                                  <b>ACNOWLEDGED</b>
                                                                </li>

                                                                <!-- <b-button class="grey-border-btn">CONFIRM ARRIVAl</b-button> -->
                                                              </div>
                                                              <div
                                                                class="status_btn"
                                                                v-if="
                                                                  items.item.mark_as_arrived ==
                                                                    'yes' &&
                                                                  items.item.marked_by == null
                                                                "
                                                              >
                                                                <button
                                                                  class="ack_btn tag_ack_btn upcoming_btn"
                                                                  style="background-color: #ffbb00"
                                                                >
                                                                  Upcoming
                                                                </button>
                                                                <b-button
                                                                  v-if="show('confirm arrival')"
                                                                  class="green-border-btn"
                                                                  @click="
                                                                    applicantArrival(
                                                                      items.item.user_id,
                                                                      'ptArrivalConfirmation',
                                                                      false,
                                                                      items.item.job_id,
                                                                      items.item.lat,
                                                                      items.item.long,
                                                                      items.item.job_code
                                                                    )
                                                                  "
                                                                  >CONFIRM ARRIVAl</b-button
                                                                >
                                                              </div>
                                                              <div
                                                                class="status_btn"
                                                                v-if="
                                                                  items.item.marked_by != null &&
                                                                  items.item
                                                                    .confirm_job_completion !=
                                                                    'yes' &&
                                                                  items.item.job_status !=
                                                                    'suspended'
                                                                "
                                                              >
                                                                <button
                                                                  class="ack_btn tag_ack_btn"
                                                                  style="background-color: #4c8bf5"
                                                                >
                                                                  In Progress
                                                                </button>
                                                                <b-button
                                                                  class="green-border-btn"
                                                                  @click="
                                                                    applicantArrival(
                                                                      items.item.user_id,
                                                                      'ptCompletion',
                                                                      false,
                                                                      items.item.job_id,
                                                                      items.item.lat,
                                                                      items.item.long,
                                                                      items.item.job_code
                                                                    )
                                                                  "
                                                                  >MARK COMPLETION</b-button
                                                                >
                                                              </div>
                                                              <div
                                                                class="status_btn"
                                                                v-if="
                                                                  items.item
                                                                    .confirm_job_completion ==
                                                                    'yes' &&
                                                                  items.item.original_end_time ==
                                                                    null
                                                                "
                                                              >
                                                                <button
                                                                  class="ack_btn tag_ack_btn"
                                                                  style="background-color: #4c8bf5"
                                                                >
                                                                  In Progress
                                                                </button>

                                                                <b-button
                                                                  class="green-border-btn"
                                                                  @click="
                                                                    applicantArrival(
                                                                      items.item.user_id,
                                                                      'ptCompletion',
                                                                      false,
                                                                      items.item.job_id,
                                                                      items.item.lat,
                                                                      items.item.long,
                                                                      items.item.job_code
                                                                    )
                                                                  "
                                                                  >MARK COMPLETION</b-button
                                                                >
                                                              </div>
                                                              <div
                                                                class="status_btn"
                                                                v-if="
                                                                  items.item
                                                                    .confirm_job_completion ==
                                                                    'yes' &&
                                                                  items.item.original_end_time !=
                                                                    null
                                                                "
                                                              >
                                                                <button
                                                                  class="ack_btn tag_ack_btn completed_btn"
                                                                  style="background-color: green"
                                                                >
                                                                  Completed
                                                                </button>
                                                                <button
                                                                  class="ack_btn tag_ack_btn"
                                                                  v-if="
                                                                    items.item
                                                                      .rated_by_supervisor != null
                                                                  "
                                                                  style="background-color: green"
                                                                >
                                                                  Rated
                                                                </button>
                                                                <button
                                                                  class="prg_btn"
                                                                  v-if="
                                                                    items.item
                                                                      .rated_by_supervisor == null
                                                                  "
                                                                  @click="
                                                                    ratePtUser(
                                                                      items.item.user_id,
                                                                      items.item.job_id,
                                                                      jobDataKey
                                                                    )
                                                                  "
                                                                >
                                                                  Rate
                                                                </button>
                                                              </div>
                                                              <div
                                                                class="status_btn"
                                                                v-if="
                                                                  items.item.job_status ==
                                                                    'suspended' &&
                                                                  items.item.suspended_by != null
                                                                "
                                                              >
                                                                <b-button
                                                                  class="prg_btn tag_ack_btn"
                                                                  v-if="
                                                                    items.item.job_status ==
                                                                    'suspended'
                                                                  "
                                                                  style="background-color: red"
                                                                  >Stopped</b-button
                                                                >
                                                              </div>
                                                            </template>
                                                            <!-- <template #cell(ac_button)="items">
                                                                                <div class="status_btn">
                                                                                    <button class="ack_btn" v-if="items.item.acknowledged == 'yes' && items.item.job_status != 'suspended'">ACNOWLEDGED</button>
                                                                                    <button class="prg_btn" v-if="items.item.mark_as_arrived == 'yes' && items.item.job_status != 'suspended'">INPROGRESS</button>
                                                                                    <button class="prg_btn" v-if="items.item.mark_as_arrived != 'yes' && items.item.job_status != 'suspended'">MARK ARRIVED</button>
                                                                                    <button class="prg_btn" v-if="items.item.job_status == 'suspended'" style="background-color:red;">Stopped</button>



                                                                                </div>
                                                                            </template>  -->
                                                            <template
                                                              #cell(toggleAction)="items"
                                                              v-if="
                                                                showPermissionEM('view job action')
                                                              "
                                                            >
                                                              <div
                                                                class="toggle-action custom_toggle_three_dot dp_job_action"
                                                              >
                                                                <b-dropdown
                                                                  class="mx-1"
                                                                  right
                                                                  text=""
                                                                  boundary="window"
                                                                >
                                                                  <b-dropdown-item
                                                                    v-if="
                                                                      items.item.user_job_remarks !=
                                                                        null &&
                                                                      show('update remarks')
                                                                    "
                                                                    @click="
                                                                      openRemarksModel(
                                                                        items.item.user_id,
                                                                        'update',
                                                                        items.item.remarks,
                                                                        items.item.job_id
                                                                      )
                                                                    "
                                                                    >UPDATE REMARKS
                                                                  </b-dropdown-item>
                                                                  <b-dropdown-item
                                                                    v-else-if="
                                                                      items.item.user_job_remarks ==
                                                                        null && show('add remarks')
                                                                    "
                                                                    @click="
                                                                      openRemarksModel(
                                                                        items.item.user_id,
                                                                        'add',
                                                                        items.item.remarks,
                                                                        items.item.job_id
                                                                      )
                                                                    "
                                                                    >ADD REMARKS
                                                                  </b-dropdown-item>
                                                                  <b-dropdown-item
                                                                    v-if="show('more info')"
                                                                    @click="
                                                                      viewMoreInfo(
                                                                        items.item.user_id,
                                                                        items.item.job_id
                                                                      )
                                                                    "
                                                                    >MORE INFO
                                                                  </b-dropdown-item>
                                                                  <b-dropdown-item
                                                                    v-if="
                                                                      items.item.job_status ==
                                                                        'upcoming' &&
                                                                      items.item.acknowledged ==
                                                                        'no' &&
                                                                      show('acknowledge fl')
                                                                    "
                                                                    @click="
                                                                      acknowledgeApplicant(
                                                                        items.item.id
                                                                      )
                                                                    "
                                                                    >ACKNOWLEDGE
                                                                  </b-dropdown-item>
                                                                  <b-dropdown-item
                                                                    @click="
                                                                      openArrivalModel(
                                                                        items.item.user_id,
                                                                        'arrival',
                                                                        false,
                                                                        items.item.job_id
                                                                      )
                                                                    "
                                                                    v-if="
                                                                      items.item
                                                                        .original_start_time !=
                                                                        null &&
                                                                      items.item.job_status ==
                                                                        'in-progress' &&
                                                                      show('adjust arrival time')
                                                                    "
                                                                    >ADJUST ARRIVAL TIME
                                                                  </b-dropdown-item>
                                                                  <b-dropdown-item
                                                                    v-if="
                                                                      items.item.marked_by !=
                                                                        null &&
                                                                      (items.item.job_status !=
                                                                        'suspended' ||
                                                                        items.item.job_status !=
                                                                          'completed') &&
                                                                      items.item
                                                                        .lunch_pay_not_provided ==
                                                                        'yes' &&
                                                                      show('add lunch break')
                                                                    "
                                                                    @click="
                                                                      openModel(
                                                                        items.item.user_id,
                                                                        'add_break',
                                                                        items.item.break_hour_taken,
                                                                        items.item.job_id
                                                                      )
                                                                    "
                                                                    >ADD/REMOVE BREAK
                                                                  </b-dropdown-item>
                                                                  <b-dropdown-item
                                                                    @click="
                                                                      revertCompletion(
                                                                        items.item.user_id,
                                                                        'arrival',
                                                                        items.item.job_id
                                                                      )
                                                                    "
                                                                    v-if="
                                                                      (items.item.job_status ==
                                                                        'completed' ||
                                                                        items.item.job_status ==
                                                                          'suspended') &&
                                                                      show('revert completion')
                                                                    "
                                                                    >REVERT COMPLETION
                                                                  </b-dropdown-item>
                                                                  <b-dropdown-item
                                                                    v-if="
                                                                      items.item.job_status !=
                                                                        'cancelled' &&
                                                                      items.item
                                                                        .confirm_job_completion_by ==
                                                                        null &&
                                                                      items.item.job_status !=
                                                                        'suspended' &&
                                                                      show('cancel fl')
                                                                    "
                                                                    @click="
                                                                      cancelApplicant(
                                                                        items.item.user_id
                                                                      )
                                                                    "
                                                                    >CANCEL
                                                                  </b-dropdown-item>
                                                                  <b-dropdown-item
                                                                    v-if="
                                                                      items.item.marked_by !=
                                                                        null &&
                                                                      items.item.job_status !=
                                                                        'suspended' &&
                                                                      items.item
                                                                        .original_end_time ==
                                                                        null &&
                                                                      items.item.extended_hours ==
                                                                        null &&
                                                                      items.item
                                                                        .rated_by_supervisor ==
                                                                        null &&
                                                                      show('extend hour')
                                                                    "
                                                                    @click="
                                                                      extendHour(
                                                                        items.item.user_id,
                                                                        items.item.hourly_cost,
                                                                        'extension',
                                                                        items.item.extended_hours,
                                                                        items.item.extension_rate,
                                                                        items.item.extra_break_hrs,
                                                                        items.item.extra_break_taken
                                                                      )
                                                                    "
                                                                    >EXTEND HOUR
                                                                  </b-dropdown-item>
                                                                  <b-dropdown-item
                                                                    v-if="
                                                                      items.item.marked_by !=
                                                                        null &&
                                                                      items.item.job_status !=
                                                                        'suspended' &&
                                                                      items.item
                                                                        .original_end_time ==
                                                                        null &&
                                                                      items.item.extended_hours !=
                                                                        null &&
                                                                      items.item
                                                                        .rated_by_supervisor ==
                                                                        null &&
                                                                      show('adjust extension hours')
                                                                    "
                                                                    @click="
                                                                      extendHour(
                                                                        items.item.user_id,
                                                                        items.item.hourly_cost,
                                                                        'adjust extension',
                                                                        items.item.extended_hours,
                                                                        items.item.extension_rate,
                                                                        items.item.extra_break_hrs,
                                                                        items.item.extra_break_taken
                                                                      )
                                                                    "
                                                                    >ADJUST EXTENSION HOUR
                                                                  </b-dropdown-item>
                                                                  <b-dropdown-item
                                                                    v-if="
                                                                      items.item.extended_hours !=
                                                                        null &&
                                                                      items.item.job_status !=
                                                                        'suspended' &&
                                                                      items.item
                                                                        .original_end_time ==
                                                                        null &&
                                                                      show('remove extension')
                                                                    "
                                                                    @click="
                                                                      removeExtension(
                                                                        items.item.user_id,
                                                                        items.item.job_id
                                                                      )
                                                                    "
                                                                    >REMOVE EXTENSION
                                                                  </b-dropdown-item>
                                                                  <b-dropdown-item
                                                                    v-if="
                                                                      items.item
                                                                        .original_start_time !=
                                                                        null &&
                                                                      items.item.extended_hours ==
                                                                        null &&
                                                                      items.item.job_status ==
                                                                        'in-progress' &&
                                                                      show('stop work')
                                                                    "
                                                                    @click="
                                                                      openModel(
                                                                        items.item.user_id,
                                                                        'stop',
                                                                        items.item.start_time,
                                                                        items.item.job_id
                                                                      )
                                                                    "
                                                                    >STOP WORK
                                                                  </b-dropdown-item>
                                                                  <b-dropdown-item
                                                                    v-if="
                                                                      (items.item.job_status ==
                                                                        'completed' ||
                                                                        items.item.job_status ==
                                                                          'suspended') &&
                                                                      show('modify fl time')
                                                                    "
                                                                    @click="
                                                                      openModel(
                                                                        items.item.user_id,
                                                                        'modify',
                                                                        '',
                                                                        items.item.job_id
                                                                      )
                                                                    "
                                                                    >MODIFY USER TIME
                                                                  </b-dropdown-item>
                                                                  <b-dropdown-item
                                                                    v-if="
                                                                      items.item.job_status ==
                                                                        'suspended' &&
                                                                      show('view suspension reason')
                                                                    "
                                                                    @click="
                                                                      viewSuspensionReason(
                                                                        items.item.user_id,
                                                                        items.item.suspention_reason
                                                                      )
                                                                    "
                                                                    >MODIFY SUSPENSION REASON
                                                                  </b-dropdown-item>
                                                                  <b-dropdown-item
                                                                    v-if="show('generate ts')"
                                                                    @click="
                                                                      openModel(
                                                                        items.item.user_id,
                                                                        'generate_ts',
                                                                        '',
                                                                        items.item.job_id
                                                                      )
                                                                    "
                                                                    >REGENERATE TS
                                                                  </b-dropdown-item>
                                                                </b-dropdown>
                                                              </div>
                                                              <div
                                                                class="toggle-action custom_toggle_three_dot dp_job_action"
                                                                v-if="
                                                                  items.item.job_status ==
                                                                  'suspended'
                                                                "
                                                              >
                                                                <b-dropdown
                                                                  class="mx-1"
                                                                  right
                                                                  text=""
                                                                  boundary="window"
                                                                >
                                                                  <b-dropdown-item
                                                                    v-if="
                                                                      items.item.job_status ==
                                                                      'suspended'
                                                                    "
                                                                    @click="
                                                                      viewSuspensionReason(
                                                                        items.item.suspention_reason
                                                                      )
                                                                    "
                                                                    >View Suspension Reason
                                                                  </b-dropdown-item>
                                                                </b-dropdown>
                                                              </div>
                                                            </template>
                                                          </b-table>
                                                        </div>
                                                        <div v-else>
                                                          <div
                                                            class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                                                          >
                                                            <p class="actionData">
                                                              No Record Found
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </b-tab>
                                                    </div>
                                                    <div>
                                                      <b-tab
                                                        :title="
                                                          'ALL APPLICANTS (' +
                                                          jobDataVal.total.length +
                                                          ')'
                                                        "
                                                        class="reject_last_li mobile_min_width_1000"
                                                        @click="
                                                          getList(
                                                            jobDataVal.id,
                                                            jobDatakey,
                                                            'in-progress',
                                                            user_id
                                                          )
                                                        "
                                                      >
                                                        <div class="col-md-6">
                                                          <div
                                                            class="listing-head-right text-lg-right text-sm-right mt-sm-0 mt-2"
                                                          >
                                                            <b-link
                                                              v-if="show('add fl')"
                                                              @click="addMw(jobDataVal.id)"
                                                              class="btn green-bg-btn green_download_btn search_btn_green_width"
                                                              >ADD MW</b-link
                                                            >
                                                            <b-link
                                                              v-if="show('award fl')"
                                                              :disabled="
                                                                selectedUser.length > 0
                                                                  ? false
                                                                  : true
                                                              "
                                                              class="btn info-bg-btn green_download_btn search_btn_green_width"
                                                              @click="awardMultipleUsers(jobDataVal.id)"
                                                              >Award</b-link
                                                            >
                                                        </div>
                                                        </div>
                                                        <div v-if="jobDataVal.total.length > 0">
                                                          <b-table
                                                            ref="datatable"
                                                            show-empty
                                                            striped
                                                            hover
                                                            responsive
                                                            :items="jobDataVal.total"
                                                            :fields="fields"
                                                            :filter="filter"
                                                            :sort-by.sync="sortBy"
                                                            :sort-desc.sync="sortDesc"
                                                            :sort-direction="sortDirection"
                                                            class="tbl_height new_box_tbl_mrgn daily_manage_job_dipper"
                                                          >
                                                            <template #cell(name)="items">
                                                              <div class="daily_image_job">
                                                                <div
                                                                  class="daily_job_manage_img_div"
                                                                >
                                                                  <b-img
                                                                    :src="
                                                                      items.item.profile_image !=
                                                                      null
                                                                        ? items.item.profile_image
                                                                        : webUrl +
                                                                          'assets/images/user-avatar-small-01.png'
                                                                    "
                                                                    alt="User Icon"
                                                                    height="100px"
                                                                    width="100px"
                                                                    class="user_img_emp"
                                                                  ></b-img>
                                                                  <div
                                                                    class="custom_star_rate_manage_job"
                                                                  >
                                                                    <span
                                                                      ><i class="fa fa-star"></i
                                                                      >{{
                                                                        items.item.average_rating
                                                                          ? items.item
                                                                              .average_rating
                                                                          : 0
                                                                      }}</span
                                                                    >
                                                                  </div>
                                                                </div>
                                                                <div
                                                                  class="daily_job_manage_user_description_div"
                                                                >
                                                                  <b-link
                                                                    v-if="
                                                                      showPermissionEM(
                                                                        'view profile'
                                                                      )
                                                                    "
                                                                    :to="{
                                                                      name: 'employer-jobseeker-dp-profile-view',
                                                                      params: {
                                                                        id: encrypt(
                                                                          items.item.user_id
                                                                        ),
                                                                      },
                                                                    }"
                                                                    target="_blank"
                                                                  >
                                                                    <h2>
                                                                      {{
                                                                        items.item.last_name
                                                                          ? items.item.last_name
                                                                          : ""
                                                                      }}
                                                                      {{
                                                                        items.item.first_name
                                                                          ? items.item.first_name
                                                                          : ""
                                                                      }}
                                                                    </h2></b-link
                                                                  >
                                                                  <h2 v-else>
                                                                    {{
                                                                      items.item.last_name
                                                                        ? items.item.last_name
                                                                        : ""
                                                                    }}
                                                                    {{
                                                                      items.item.first_name
                                                                        ? items.item.first_name
                                                                        : ""
                                                                    }}
                                                                  </h2>

                                                                  <div class="star-rating">
                                                                    <!-- <star-rating v-model="items.item.userdetail.average_rating" v-bind:star-size="20" :show-rating="false"></star-rating> -->
                                                                    <!-- <span class="star"></span>
                                                                                            <span class="star"></span>
                                                                                            <span class="star"></span>
                                                                                            <span class="star"></span>
                                                                                            <span class="star"></span> -->
                                                                    <div class="com_imgs">
                                                                      <b-img
                                                                        class="worked_for_logo"
                                                                        v-if="
                                                                          items.item
                                                                            .worked_for_company !=
                                                                            null &&
                                                                          items.item
                                                                            .worked_for_company ==
                                                                            'yes'
                                                                        "
                                                                        :src="
                                                                          jobDataVal.job_company
                                                                            .thumbnail
                                                                        "
                                                                        :title="
                                                                          jobDataVal.job_company
                                                                            .name
                                                                        "
                                                                        alt="User Icon"
                                                                      ></b-img>
                                                                    </div>
                                                                  </div>
                                                                  <div
                                                                    v-if="
                                                                      items.item.user_badges != null
                                                                    "
                                                                  >
                                                                    <b-img
                                                                      class="worked_for_logo"
                                                                      v-for="(usb, us) in items.item
                                                                        .user_badges"
                                                                      :key="us"
                                                                      :src="usb.badges.badge_image"
                                                                      :title="usb.badges.name"
                                                                      alt="User Icon"
                                                                    ></b-img>
                                                                  </div>
                                                                  <!-- <div>
                                                                                                <p v-if="items.item.proposed_arrival_time != null">ETA: {{items.item.proposed_arrival_time != null ? items.item.proposed_arrival_time : '' | moment }} | {{ items.item.proposed_arrival_time != null ? items.item.proposed_arrival_time : '' | applicantOriginalSET }}</p>
                                                                                                <p v-else>ETA: NA</p>
                                                                                            </div> -->
                                                                </div>
                                                              </div>
                                                            </template>
                                                            <template #cell(mobile_number1)="items">
                                                              <div class="phone_nmber_div">
                                                                <a href="#"
                                                                  >Upcoming Applied -
                                                                  {{ items.item.applied_count }}</a
                                                                >
                                                              </div>

                                                              <div class="phone_nmber_div">
                                                                <a href="#"
                                                                  >Completed -
                                                                  {{
                                                                    items.item.completed_count
                                                                  }}</a
                                                                >
                                                              </div>
                                                            </template>

                                                            <template #cell(time)="items">
                                                              <div class="phone_nmber_div">
                                                                <p href="#">
                                                                  <span>{{
                                                                    items.item.created_at
                                                                      ? items.item.created_at
                                                                      : "" | moment
                                                                  }}</span>
                                                                  <span>
                                                                    {{
                                                                      items.item.created_at
                                                                        ? items.item.created_at
                                                                        : "" | applicantOriginalSET
                                                                    }}</span
                                                                  >
                                                                </p>
                                                              </div>
                                                            </template>
                                                            <!-- <template #cell(time)="items">
                                                                                <div class="timing_div">
                                                                                    <ul>
                                                                                        <li><i class="fa fa-arrow-down green_arrow"></i><span>{{items.item.original_start_time ? items.item.original_start_time : '' | applicantOriginalSET}}</span><i class="fa fa-arrow-up red_arrow green_arrow"></i><span>{{items.item.original_end_time ? items.item.original_end_time : '' | applicantOriginalSET}}</span></li>
                                                                                        <li><i class="fa fa-arrow-down green_arrow gray_tab_round"></i><span>{{items.item.start_time ? items.item.start_time : '' | applicantStartEndTime}}</span><i class="fa fa-arrow-up  green_arrow gray_tab_round"></i><span>{{items.item.end_time ? items.item.end_time : '' | applicantStartEndTime}}</span></li>
                                                                                    </ul>
                                                                            </div>
                                                                            </template>  -->
                                                            <!-- <template #cell(thumb)="items">
                                                                                <div class="thumb_div">
                                                                                <i class="fa fa-thumbs-up blue_thumb"></i>
                                                                            </div>
                                                                            </template>  -->
                                                            <template
                                                              #cell(ac_button)="items"
                                                              v-if="
                                                                showPermissionEM('view job action')
                                                              "
                                                            >
                                                              <div class="status_btn">
                                                                <li
                                                                  v-if="
                                                                    items.item.acknowledged ==
                                                                      'yes' &&
                                                                    items.item.job_status !=
                                                                      'suspended'
                                                                  "
                                                                >
                                                                  <b>ACNOWLEDGED</b>
                                                                </li>
                                                                <button
                                                                  @click="
                                                                    awardJob(
                                                                      items.item.user_id,
                                                                      items.item.job_id,
                                                                      jobDatakey,
                                                                      jobDataVal.posted_by
                                                                    )
                                                                  "
                                                                  class="ack_btn"
                                                                  style="
                                                                    background-color: green !important;
                                                                  "
                                                                >
                                                                  Award
                                                                </button>
                                                                <button
                                                                  @click="
                                                                    notSuitable(
                                                                      items.item.user_id,
                                                                      items.item.job_id,
                                                                      jobDatakey
                                                                    )
                                                                  "
                                                                  class="ack_btn"
                                                                  style="
                                                                    background-color: red !important;
                                                                  "
                                                                >
                                                                  Not Suitable
                                                                </button>
                                                              </div>
                                                              <div class="status_btn"></div>
                                                            </template>

                                                            <template
                                                              #cell(toggleAction)="items"
                                                              v-if="
                                                                showPermissionEM('view job action')
                                                              "
                                                            >
                                                              <div
                                                                class="toggle-action custom_toggle_three_dot dp_job_action"
                                                              >
                                                                <b-dropdown
                                                                  class="mx-1"
                                                                  right
                                                                  text=""
                                                                  boundary="window"
                                                                >
                                                                  <b-dropdown-item
                                                                    v-if="show('more info')"
                                                                    @click="
                                                                      viewMoreInfo(
                                                                        items.item.user_id,
                                                                        items.item.job_id
                                                                      )
                                                                    "
                                                                    >MORE INFO
                                                                  </b-dropdown-item>
                                                                  <b-dropdown-item
                                                                    v-if="
                                                                      items.item.marked_by ==
                                                                        null &&
                                                                      items.item.job_status !=
                                                                        'cancelled' &&
                                                                      items.item
                                                                        .withdraw_application ==
                                                                        'no'
                                                                    "
                                                                    @click="
                                                                      withdrawApplicant(
                                                                        items.item.user_id,
                                                                        items.item.job_id
                                                                      )
                                                                    "
                                                                    >WITHDRAW JOB
                                                                  </b-dropdown-item>
                                                                </b-dropdown>
                                                              </div>
                                                            </template>
                                                            <!-- <template #cell(toggleAction)="items" v-if="items.item.mark_as_arrived == null &&  items.item.withdraw_application == 'no'" >
                                                                                <div class="toggle-action custom_toggle_three_dot dp_job_action">
                                                                                    <b-dropdown class="mx-1" right text="" boundary="window">
                                                                                    <b-dropdown-item v-if="items.item.mark_as_arrived == null &&  items.item.withdraw_application == 'no'" @click="cancelApplicant(items.item.id)">CANCEL
                                                                                        </b-dropdown-item>
                                                                                    </b-dropdown>
                                                                                </div>
                                                                            </template> -->
                                                          </b-table>
                                                        </div>
                                                        <div v-else>
                                                          <div
                                                            class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                                                          >
                                                            <p class="actionData">
                                                              No Record Found
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </b-tab>
                                                    </div>
                                                    <div>
                                                      <b-tab
                                                        :title="
                                                          'CANCELLED (' +
                                                          jobDataVal.cancelled.length +
                                                          ')'
                                                        "
                                                        class="reject_last_li mobile_min_width_1000"
                                                        @click="
                                                          getList(
                                                            jobDataVal.id,
                                                            jobDatakey,
                                                            'in-progress',
                                                            user_id
                                                          )
                                                        "
                                                      >
                                                        <div
                                                          v-if="
                                                            jobDataVal.cancelled &&
                                                            jobDataVal.cancelled.length > 0
                                                          "
                                                        >
                                                          <b-table
                                                            ref="datatable"
                                                            show-empty
                                                            striped
                                                            hover
                                                            responsive
                                                            :items="jobDataVal.cancelled"
                                                            :fields="fields"
                                                            :filter="filter"
                                                            :sort-by.sync="sortBy"
                                                            :sort-desc.sync="sortDesc"
                                                            :sort-direction="sortDirection"
                                                            class="tbl_height new_box_tbl_mrgn daily_manage_job_dipper"
                                                          >
                                                            <template #cell(name)="items">
                                                              <div class="daily_image_job">
                                                                <div
                                                                  class="daily_job_manage_img_div"
                                                                >
                                                                  <b-img
                                                                    :src="
                                                                      items.item.profile_image !=
                                                                      null
                                                                        ? items.item.profile_image
                                                                        : webUrl +
                                                                          'assets/images/user-avatar-small-01.png'
                                                                    "
                                                                    alt="User Icon"
                                                                    height="100px"
                                                                    width="100px"
                                                                    class="user_img_emp"
                                                                  ></b-img>
                                                                  <div
                                                                    class="custom_star_rate_manage_job"
                                                                  >
                                                                    <span
                                                                      ><i class="fa fa-star"></i
                                                                      >{{
                                                                        items.item.average_rating
                                                                          ? items.item
                                                                              .average_rating
                                                                          : 0
                                                                      }}</span
                                                                    >
                                                                  </div>
                                                                </div>
                                                                <div
                                                                  class="daily_job_manage_user_description_div"
                                                                >
                                                                  <b-link
                                                                    v-if="
                                                                      showPermissionEM(
                                                                        'view profile'
                                                                      )
                                                                    "
                                                                    :to="{
                                                                      name: 'employer-jobseeker-dp-profile-view',
                                                                      params: {
                                                                        id: encrypt(
                                                                          items.item.user_id
                                                                        ),
                                                                      },
                                                                    }"
                                                                    target="_blank"
                                                                  >
                                                                    <h2>
                                                                      {{
                                                                        items.item.last_name
                                                                          ? items.item.last_name
                                                                          : ""
                                                                      }}
                                                                      {{
                                                                        items.item.first_name
                                                                          ? items.item.first_name
                                                                          : ""
                                                                      }}
                                                                    </h2></b-link
                                                                  >
                                                                  <h2 v-else>
                                                                    {{
                                                                      items.item.last_name
                                                                        ? items.item.last_name
                                                                        : ""
                                                                    }}
                                                                    {{
                                                                      items.item.first_name
                                                                        ? items.item.first_name
                                                                        : ""
                                                                    }}
                                                                  </h2>
                                                                  <div class="phone_nmber_div">
                                                                    <a href="#"
                                                                      >{{
                                                                        items.item.country_code
                                                                          ? items.item.country_code
                                                                          : ""
                                                                      }}-{{
                                                                        items.item.mobile_number
                                                                          ? items.item.mobile_number
                                                                          : ""
                                                                      }}</a
                                                                    >
                                                                  </div>
                                                                  <div class="star-rating">
                                                                    <!-- <star-rating v-model="items.item.userdetail.average_rating" v-bind:star-size="20" :show-rating="false"></star-rating> -->
                                                                    <!-- <span class="star"></span>
                                                                                        <span class="star"></span>
                                                                                        <span class="star"></span>
                                                                                        <span class="star"></span>
                                                                                        <span class="star"></span> -->
                                                                  </div>
                                                                  <div
                                                                    v-if="
                                                                      items.item.user_badges != null
                                                                    "
                                                                  >
                                                                    <b-img
                                                                      class="worked_for_logo"
                                                                      v-for="(usb, us) in items.item
                                                                        .user_badges"
                                                                      :key="us"
                                                                      :src="usb.badges.badge_image"
                                                                      :title="usb.badges.name"
                                                                      alt="User Icon"
                                                                    ></b-img>
                                                                  </div>
                                                                  <div>
                                                                    <p
                                                                      v-if="
                                                                        jobDataVal.proposed_arrival_time !=
                                                                          null &&
                                                                        jobDataVal.job_type_id == 2
                                                                      "
                                                                    >
                                                                      ETA:
                                                                      {{
                                                                        jobDataVal.proposed_arrival_time !=
                                                                        null
                                                                          ? jobDataVal.proposed_arrival_time
                                                                          : "" | moment
                                                                      }}
                                                                      |
                                                                      {{
                                                                        jobDataVal.proposed_arrival_time !=
                                                                        null
                                                                          ? jobDataVal.proposed_arrival_time
                                                                          : ""
                                                                            | applicantOriginalSET
                                                                      }}
                                                                    </p>
                                                                    <!-- <p v-else>ETA: NA</p> -->
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </template>
                                                            <template #cell(mobile_number1)="items">
                                                              <div class="phone_nmber_div">
                                                                <a href="#"
                                                                  >Upcoming Applied -
                                                                  {{ items.item.applied_count }}</a
                                                                >
                                                              </div>
                                                              <div class="phone_nmber_div">
                                                                <a href="#"
                                                                  >Completed -
                                                                  {{
                                                                    items.item.completed_count
                                                                  }}</a
                                                                >
                                                              </div>
                                                            </template>
                                                            <!-- <template #cell(mobile_number)="items">
                                                                            <div class="phone_nmber_div">
                                                                                <a href="#">{{items.item.userdetail.country_code ? items.item.userdetail.country_code : ''}}-{{items.item.userdetail.mobile_number ? items.item.userdetail.mobile_number : ''}}</a>
                                                                            </div>
                                                                            </template> -->
                                                            <template #cell(time)="items">
                                                              <div
                                                                class="timing_div cancelled_div_tb"
                                                              >
                                                                <ul>
                                                                  <li>
                                                                    <span
                                                                      ><b
                                                                        >Cancelled Reason:</b
                                                                      ></span
                                                                    >
                                                                    <span>{{
                                                                      items.item.cancellation_reason
                                                                        ? items.item
                                                                            .cancellation_reason
                                                                        : ""
                                                                    }}</span>
                                                                  </li>
                                                                  <br />
                                                                  <li>
                                                                    <span
                                                                      ><b>Cancelled At:</b></span
                                                                    >
                                                                    <span>{{
                                                                      items.item.cancelled_at
                                                                        ? items.item.cancelled_at
                                                                        : "" | customFormatterOne
                                                                    }}</span>
                                                                  </li>
                                                                </ul>
                                                              </div>
                                                            </template>
                                                            <!-- <template #cell(thumb)="items">
                                                                                <div class="thumb_div">
                                                                                <i class="fa fa-thumbs-up blue_thumb"></i>
                                                                            </div>
                                                                            </template>  -->

                                                            <template #cell(toggleAction)="items">
                                                              <div
                                                                class="toggle-action custom_toggle_three_dot dp_job_action"
                                                              >
                                                                <b-dropdown
                                                                  class="mx-1"
                                                                  right
                                                                  text=""
                                                                  boundary="window"
                                                                >
                                                                  <b-dropdown-item
                                                                    v-if="show('more info')"
                                                                    @click="
                                                                      viewMoreInfo(
                                                                        items.item.user_id,
                                                                        items.item.job_id
                                                                      )
                                                                    "
                                                                    >MORE INFO
                                                                  </b-dropdown-item>

                                                                  <b-dropdown-item
                                                                    v-if="show('revive fl')"
                                                                    @click="
                                                                      openArrivalModel(
                                                                        items.item.user_id,
                                                                        'revive',
                                                                        false,
                                                                        items.item.job_id
                                                                      )
                                                                    "
                                                                    >REVIVE USER
                                                                  </b-dropdown-item>
                                                                  <b-dropdown-item
                                                                    v-if="
                                                                      items.item
                                                                        .withdraw_application ==
                                                                      'yes'
                                                                    "
                                                                    @click="
                                                                      addBack(
                                                                        items.item.user_id,
                                                                        items.item.job_id
                                                                      )
                                                                    "
                                                                    >ADD BACK
                                                                  </b-dropdown-item>
                                                                </b-dropdown>
                                                              </div>
                                                            </template>
                                                          </b-table>
                                                        </div>
                                                        <div v-else>
                                                          <div
                                                            class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                                                          >
                                                            <p class="actionData">
                                                              No Record Found
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </b-tab>
                                                    </div>
                                                    <b-tab
                                                      :title="
                                                        'APPLICANT WIDTHDRAWN (' +
                                                        jobDataVal.total_withdrawn_by_self.length +
                                                        ')'
                                                      "
                                                      class="reject_last_li mobile_min_width_1000"
                                                      @click="
                                                        getList(
                                                          jobDataVal.id,
                                                          jobDatakey,
                                                          'in-progress',
                                                          user_id
                                                        )
                                                      "
                                                    >
                                                      <div
                                                        v-if="
                                                          jobDataVal.total_withdrawn_by_self
                                                            .length > 0
                                                        "
                                                      >
                                                        <b-table
                                                          ref="datatable"
                                                          show-empty
                                                          striped
                                                          hover
                                                          responsive
                                                          :items="
                                                            jobDataVal.total_withdrawn_by_self
                                                          "
                                                          :fields="fields"
                                                          :filter="filter"
                                                          :sort-by.sync="sortBy"
                                                          :sort-desc.sync="sortDesc"
                                                          :sort-direction="sortDirection"
                                                          class="tbl_height new_box_tbl_mrgn daily_manage_job_dipper"
                                                        >
                                                          <template #cell(name)="items">
                                                            <div class="daily_image_job">
                                                              <div class="daily_job_manage_img_div">
                                                                <b-img
                                                                  :src="
                                                                    items.item.thumbnail != null
                                                                      ? items.item.thumbnail
                                                                      : webUrl +
                                                                        'assets/images/user-avatar-small-01.png'
                                                                  "
                                                                  alt="User Icon"
                                                                  height="100px"
                                                                  width="100px"
                                                                  class="user_img_emp"
                                                                ></b-img>
                                                              </div>
                                                              <div
                                                                class="daily_job_manage_user_description_div"
                                                              >
                                                                <b-link
                                                                  :to="{
                                                                    name: 'employer-jobseeker-dp-profile-view',
                                                                    params: {
                                                                      id: encrypt(
                                                                        items.item.user_id
                                                                      ),
                                                                    },
                                                                  }"
                                                                  target="_blank"
                                                                  ><h2>
                                                                    {{
                                                                      items.item.last_name
                                                                        ? items.item.last_name
                                                                        : ""
                                                                    }}
                                                                    {{
                                                                      items.item.first_name
                                                                        ? items.item.first_name
                                                                        : ""
                                                                    }}
                                                                  </h2></b-link
                                                                >
                                                                <div class="star-rating">
                                                                  <star-rating
                                                                    v-model="
                                                                      items.item.average_rating
                                                                    "
                                                                    v-bind:star-size="20"
                                                                    :show-rating="false"
                                                                  ></star-rating>
                                                                </div>
                                                                <div>
                                                                  <p
                                                                    v-if="
                                                                      jobDataVal.proposed_arrival_time !=
                                                                        null &&
                                                                      jobDataVal.job_type_id == 2
                                                                    "
                                                                  >
                                                                    ETA:
                                                                    {{
                                                                      jobDataVal.proposed_arrival_time !=
                                                                      null
                                                                        ? jobDataVal.proposed_arrival_time
                                                                        : "" | moment
                                                                    }}
                                                                    |
                                                                    {{
                                                                      jobDataVal.proposed_arrival_time !=
                                                                      null
                                                                        ? jobDataVal.proposed_arrival_time
                                                                        : "" | applicantOriginalSET
                                                                    }}
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </template>
                                                          <template #cell(mobile_number1)="items">
                                                            <div class="phone_nmber_div">
                                                              <a href="#"
                                                                >Applied -
                                                                {{ items.item.applied_count }}</a
                                                              >
                                                            </div>
                                                          </template>
                                                          <template #cell(mobile_number)="items">
                                                            <div class="phone_nmber_div">
                                                              <a href="#"
                                                                >{{
                                                                  items.item.country_code
                                                                    ? items.item.country_code
                                                                    : ""
                                                                }}-{{
                                                                  items.item.mobile_number
                                                                    ? items.item.mobile_number
                                                                    : ""
                                                                }}</a
                                                              >
                                                            </div>
                                                          </template>
                                                          <template #cell(time)="items">
                                                            <div class="timing_div">
                                                              <ul>
                                                                <li>
                                                                  Applied On<span>{{
                                                                    items.item.created_at
                                                                      ? items.item.created_at
                                                                      : "" | customFormatterOne
                                                                  }}</span>
                                                                </li>
                                                              </ul>
                                                            </div>
                                                          </template>
                                                          <template #cell(thumb)="items">
                                                            <div class="thumb_div">
                                                              <i
                                                                class="fa fa-thumbs-up blue_thumb"
                                                              ></i>
                                                            </div>
                                                          </template>
                                                          <template #cell(ac_button)="items">
                                                            <div class="status_btn">
                                                              <li
                                                                v-if="
                                                                  items.item.acknowledged ==
                                                                    'yes' &&
                                                                  items.item.job_status !=
                                                                    'suspended'
                                                                "
                                                              >
                                                                <b>ACNOWLEDGED</b>
                                                              </li>
                                                            </div>
                                                          </template>

                                                          <template #cell(toggleAction)="items">
                                                            <div
                                                              class="toggle-action custom_toggle_three_dot dp_job_action"
                                                            >
                                                              <b-dropdown
                                                                class="mx-1"
                                                                right
                                                                text=""
                                                                boundary="window"
                                                              >
                                                                <b-dropdown-item
                                                                  v-if="show('more info')"
                                                                  @click="
                                                                    viewMoreInfo(
                                                                      items.item.user_id,
                                                                      items.item.job_id
                                                                    )
                                                                  "
                                                                  >MORE INFO
                                                                </b-dropdown-item>

                                                                <b-dropdown-item
                                                                  v-if="
                                                                    items.item
                                                                      .withdraw_application == 'yes'
                                                                  "
                                                                  @click="
                                                                    addBack(
                                                                      items.item.user_id,
                                                                      items.item.job_id
                                                                    )
                                                                  "
                                                                  >ADD BACK
                                                                </b-dropdown-item>
                                                              </b-dropdown>
                                                            </div>
                                                          </template>
                                                        </b-table>
                                                      </div>

                                                      <div v-else>
                                                        <div
                                                          class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                                                        >
                                                          <p class="actionData">No Record Found</p>
                                                        </div>
                                                      </div>
                                                    </b-tab>
                                                    <b-tab
                                                      :title="
                                                        ' SYSTEM WIDTHDRAWN (' +
                                                        jobDataVal.total_withdrawn_by_system
                                                          .length +
                                                        ')'
                                                      "
                                                      class="reject_last_li mobile_min_width_1000"
                                                      @click="
                                                        getList(
                                                          jobDataVal.id,
                                                          jobDatakey,
                                                          'in-progress',
                                                          user_id
                                                        )
                                                      "
                                                    >
                                                      <div
                                                        v-if="
                                                          jobDataVal.total_withdrawn_by_system
                                                            .length > 0
                                                        "
                                                      >
                                                        <b-table
                                                          ref="datatable"
                                                          show-empty
                                                          striped
                                                          hover
                                                          responsive
                                                          :items="
                                                            jobDataVal.total_withdrawn_by_system
                                                          "
                                                          :fields="fields"
                                                          :filter="filter"
                                                          :sort-by.sync="sortBy"
                                                          :sort-desc.sync="sortDesc"
                                                          :sort-direction="sortDirection"
                                                          class="tbl_height new_box_tbl_mrgn daily_manage_job_dipper"
                                                        >
                                                          <template #cell(name)="items">
                                                            <div class="daily_image_job">
                                                              <div class="daily_job_manage_img_div">
                                                                <b-img
                                                                  :src="
                                                                    items.item.thumbnail != null
                                                                      ? items.item.thumbnail
                                                                      : webUrl +
                                                                        'assets/images/user-avatar-small-01.png'
                                                                  "
                                                                  alt="User Icon"
                                                                  height="100px"
                                                                  width="100px"
                                                                  class="user_img_emp"
                                                                ></b-img>
                                                              </div>
                                                              <div
                                                                class="daily_job_manage_user_description_div"
                                                              >
                                                                <b-link
                                                                  :to="{
                                                                    name: 'employer-jobseeker-dp-profile-view',
                                                                    params: {
                                                                      id: encrypt(
                                                                        items.item.user_id
                                                                      ),
                                                                    },
                                                                  }"
                                                                  target="_blank"
                                                                >
                                                                  <h2>
                                                                    {{
                                                                      items.item.last_name
                                                                        ? items.item.last_name
                                                                        : ""
                                                                    }}
                                                                    {{
                                                                      items.item.first_name
                                                                        ? items.item.first_name
                                                                        : ""
                                                                    }}
                                                                  </h2></b-link
                                                                >
                                                                <div class="star-rating">
                                                                  <star-rating
                                                                    v-model="
                                                                      items.item.average_rating
                                                                    "
                                                                    v-bind:star-size="20"
                                                                    :show-rating="false"
                                                                  ></star-rating>
                                                                </div>
                                                                <div>
                                                                  <p
                                                                    v-if="
                                                                      jobDataVal.proposed_arrival_time !=
                                                                        null &&
                                                                      jobDataVal.job_type_id == 2
                                                                    "
                                                                  >
                                                                    ETA:
                                                                    {{
                                                                      jobDataVal.proposed_arrival_time !=
                                                                      null
                                                                        ? jobDataVal.proposed_arrival_time
                                                                        : "" | moment
                                                                    }}
                                                                    |
                                                                    {{
                                                                      jobDataVal.proposed_arrival_time !=
                                                                      null
                                                                        ? jobDataVal.proposed_arrival_time
                                                                        : "" | applicantOriginalSET
                                                                    }}
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </template>
                                                          <template #cell(mobile_number1)="items">
                                                            <div class="phone_nmber_div">
                                                              <a href="#"
                                                                >Applied -
                                                                {{ items.item.applied_count }}</a
                                                              >
                                                            </div>
                                                          </template>
                                                          <template #cell(mobile_number)="items">
                                                            <div class="phone_nmber_div">
                                                              <a href="#"
                                                                >{{
                                                                  items.item.country_code
                                                                    ? items.item.country_code
                                                                    : ""
                                                                }}-{{
                                                                  items.item.mobile_number
                                                                    ? items.item.mobile_number
                                                                    : ""
                                                                }}</a
                                                              >
                                                            </div>
                                                          </template>
                                                          <template #cell(time)="items">
                                                            <div class="timing_div">
                                                              <ul>
                                                                <li>
                                                                  <i
                                                                    class="fa fa-arrow-down green_arrow"
                                                                  ></i
                                                                  ><span>{{
                                                                    items.item.start_time
                                                                      ? items.item.start_time
                                                                      : "" | applicantStartEndTime
                                                                  }}</span
                                                                  ><i
                                                                    class="fa fa-arrow-up red_arrow green_arrow"
                                                                  ></i
                                                                  ><span>{{
                                                                    items.item.end_time
                                                                      ? items.item.end_time
                                                                      : "" | applicantOriginalSET
                                                                  }}</span>
                                                                </li>
                                                              </ul>
                                                            </div>
                                                          </template>
                                                          <template #cell(thumb)="items">
                                                            <div class="thumb_div">
                                                              <i
                                                                class="fa fa-thumbs-up blue_thumb"
                                                              ></i>
                                                            </div>
                                                          </template>
                                                          <template #cell(ac_button)="items">
                                                            <div class="status_btn">
                                                              <li
                                                                v-if="
                                                                  items.item.acknowledged ==
                                                                    'yes' &&
                                                                  items.item.job_status !=
                                                                    'suspended'
                                                                "
                                                              >
                                                                <b>ACNOWLEDGED</b>
                                                              </li>
                                                            </div>
                                                          </template>
                                                          <template #cell(toggleAction)="items">
                                                            <div
                                                              class="toggle-action custom_toggle_three_dot dp_job_action"
                                                            >
                                                              <b-dropdown
                                                                class="mx-1"
                                                                right
                                                                text=""
                                                                boundary="window"
                                                              >
                                                                <b-dropdown-item
                                                                  v-if="show('more info')"
                                                                  @click="
                                                                    viewMoreInfo(
                                                                      items.item.user_id,
                                                                      items.item.job_id
                                                                    )
                                                                  "
                                                                  >MORE INFO
                                                                </b-dropdown-item>

                                                                <b-dropdown-item
                                                                  v-if="
                                                                    items.item
                                                                      .withdraw_application == 'yes'
                                                                  "
                                                                  @click="
                                                                    addBack(
                                                                      items.item.user_id,
                                                                      items.item.job_id
                                                                    )
                                                                  "
                                                                  >ADD BACK
                                                                </b-dropdown-item>
                                                              </b-dropdown>
                                                            </div>
                                                          </template>
                                                        </b-table>
                                                      </div>
                                                      <div v-else>
                                                        <div
                                                          class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                                                        >
                                                          <p class="actionData">No Record Found</p>
                                                        </div>
                                                      </div>
                                                    </b-tab>
                                                  </b-tabs>
                                                </div>
                                              </div>
                                            </div>
                                          </b-card-body>
                                        </b-collapse>
                                      </b-card>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div
                        class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                        v-if="jobDatas.length == 0"
                      >
                        <p class="actionData">No Record Found</p>
                      </div> -->
                    </div>
                    <!-- <div
                      class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                      v-if="jobDatas.length == 0"
                    >
                      <p class="actionData"><strong>No Record Found</strong></p>
                    </div> -->
                  </b-tab>

                  <b-tab
                    :title="'UPCOMING (' + upcomingCount + ')'"
                    :active="upcoming"
                    @click="changeStatus('upcoming', 'all', 'all', '')"
                    class="reject_last_li"
                  >
                    <div class="top_desc_div" v-for="(jobDataVal, jobDatakey) in jobDatas">
                      <div class="box_shadow box_new_shadow">
                        <div class="padding_manage_job">
                          <ul class="com_id_ul new_com_id_ulz">
                            <li>{{ jobDataVal.job_unique_id }}</li>

                            <!-- <li>SERVICE AMT: S${{jobDataVal.service_fee}}</li> -->
                            <li v-if="jobDataVal.assign_user">
                              <button
                                class="demand_btn assign_user_name"
                                style="pointer-events: none"
                              >
                                Assigned User : {{ jobDataVal.assign_user.last_name }}
                                {{ jobDataVal.assign_user.first_name }}
                              </button>
                            </li>
                            <li>
                              <button
                                class="service_cat_name_manage_job"
                                style="pointer-events: none; border: 1px solid"
                                :style="{
                                  color: jobDataVal.service_category.color,
                                  borderColor: jobDataVal.service_category.color,
                                }"
                              >
                                {{ jobDataVal.service_category.name }}
                              </button>
                            </li>
                            <li v-if="jobDataVal.approval_status == 'pending'">
                              <button
                                class="demand_btn"
                                style="
                                  background-color: rgb(229, 138, 10) !important;
                                  pointer-events: none;
                                "
                              >
                                Pending Approval
                              </button>
                            </li>

                            <li v-if="jobDataVal.job_type_id == 2">
                              <button
                                class="demand_btn"
                                style="background: #28a745; pointer-events: none"
                              >
                                ON DEMAND JOB
                              </button>
                            </li>
                          </ul>
                          <div
                            class="toggle-action common_ul_radius toggle_margin_unset new-action-view-detail"
                          >
                            <!-- <b-dropdown class="mx-1" right text="Action" boundary="window"> -->
                            <b-dropdown class="mx-1" left text="Action" boundary="window">
                              <b-dropdown-item
                                @click="openAttachSupModal(jobDataVal.id)"
                                v-if="showPermissionEM('assign user')"
                                >Assign User
                              </b-dropdown-item>
                              <b-dropdown-item
                                v-on:click="cancelJob(jobDataVal.id)"
                                v-if="showPermissionEM('cancel job')"
                                >Cancel Job
                              </b-dropdown-item>
                              <b-dropdown-item
                                :to="{
                                  name: 'repostJobDaily',
                                  params: { id: encrypt(jobDataVal.id) },
                                }"
                                v-if="showPermissionEM('duplicate job')"
                                >Repost
                              </b-dropdown-item>
                              <b-dropdown-item
                                :to="{
                                  name: 'DailyJobEdit',
                                  params: { id: encrypt(jobDataVal.id) },
                                }"
                                v-if="showPermissionEM('edit job')"
                                >Edit
                              </b-dropdown-item>
                              <b-dropdown-item
                                v-on:click="
                                  showQR(
                                    jobDataVal.job_code,
                                    jobDataVal.job_unique_id,
                                    jobDataVal.id
                                  )
                                "
                                >Show QR Code
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                          <div class="heading_with_status">
                            <div class="comp_heading_div">
                              <h2 class="top_color_h2_single">{{ jobDataVal.title }}</h2>
                              <!-- <div class="dtl_text" v-if="jobDataVal.userData">
                                <h2>Posted By:</h2>
                                <p class="mb-0">
                                  {{
                                    jobDataVal.userData && jobDataVal.userData.last_name
                                      ? jobDataVal.userData.last_name
                                      : ""
                                  }}
                                  {{
                                    jobDataVal.userData && jobDataVal.userData.first_name
                                      ? jobDataVal.userData.first_name
                                      : ""
                                  }}
                                </p>
                                <p class="mb-0">
                                  {{ jobDataVal.created_at | customFormatterOnecreated }}
                                </p>
                              </div> -->
                            </div>
                            <!-- <div class="rate_div new_rate_divs"> -->
                            <!-- <div class="status_btn new_status_btn_divs"> -->
                            <!-- <button class="ack_btn new_ack_btn_divs"  @click="openAttachSupModal(jobDataVal.id)">Assign User</button>
                                                            <b-button style="background-color:#rgb(216 43 37);color: white;" v-on:click="cancelJob(jobDataVal.id)">CANCEL JOB</b-button>
                                                            <b-link href="" :to="{name: 'repostJobDaily', params: {id: encrypt(jobDataVal.id)}}" class="button gray ripple-effect ico repost_icon_btn" title="Repost" data-tippy-placement="top"><i class="fa fa-retweet"></i></b-link>
                                                            <b-link href="" :to="{name: 'DailyJobEdit', params: {id: encrypt(jobDataVal.id)}}" class="button gray ripple-effect ico edit_icon_btn" title="Edit" data-tippy-placement="top"><i class="fa fa-edit"></i></b-link>
                                                            <b-button style="background-color:#4A5060;color: white;" v-on:click="showQR(jobDataVal.job_code, jobDataVal.job_unique_id, jobDataVal.id)" v-if="jobDataVal.userData.qr_based_arrival == 'yes' && (jobDataVal.start_date+' '+jobDataVal.start_time) <= addDays">Show QR Code</b-button> -->
                            <!-- <h2 class="rate_text" v-if="showPermissionEM('show list rate') && showJobListRateAdmin('show_job_list_rate')">S${{jobDataVal.hourly_cost}}/HR</h2> -->
                            <!-- <b-link class="button gray ripple-effect ico repost_icon_btn" title="Assign User" data-tippy-placement="top" @click="openAttachSupModal(jobDataVal.id)" v-if="showPermissionEM('assign user')"><i class="material-icons-outlined assign_users_i">person_add_alt_outlined</i></b-link>
                                                            <b-link style="background-color:#rgb(216 43 37);color: white;" v-on:click="cancelJob(jobDataVal.id)" v-if="showPermissionEM('cancel job')" class="button gray ripple-effect ico repost_icon_btn" title="Cancel Job" data-tippy-placement="top"><i class="material-icons-outlined assign_users_i">cancel_outlined</i></b-link>
                                                            <b-link href="" :to="{name: 'repostJobDaily', params: {id: encrypt(jobDataVal.id)}}" v-if="showPermissionEM('duplicate job')" class="button gray ripple-effect ico repost_icon_btn" title="Repost" data-tippy-placement="top"><i class="material-icons-outlined assign_users_i">repeat_outlined</i></b-link>
                                                            <b-link href="" :to="{name: 'DailyJobEdit', params: {id: encrypt(jobDataVal.id)}}" v-if="showPermissionEM('edit job')" class="button gray ripple-effect ico edit_icon_btn" title="Edit" data-tippy-placement="top"><i class="material-icons-outlined assign_users_i">border_color_outlined</i></b-link>
                                                            <b-link style="background-color:#4A5060;color: white;" v-on:click="showQR(jobDataVal.job_code, jobDataVal.job_unique_id, jobDataVal.id)" v-if="jobDataVal.userData && jobDataVal.userData.companydata &&  jobDataVal.userData.companydata.companyuser.qr_based_arrival == 'yes' && (jobDataVal.start_date+' '+jobDataVal.start_time) <= addDays" class="button gray ripple-effect ico repost_icon_btn" title="Show QR Code" data-tippy-placement="top"><i class="material-icons-outlined assign_users_i">qr_code_2_outlined</i></b-link> -->
                            <!-- </div> -->
                            <!-- <div class="status_btn">
                                                            <b-link href="" :to="{name: 'DailyJobEdit', params: {id: encrypt(jobDataVal.id)}}" class="button gray ripple-effect ico edit_icon_btn" title="Edit" data-tippy-placement="top"><i class="fa fa-edit"></i></b-link>
                                                    </div> -->
                            <!-- <div class="rate_text" v-if="showPermissionEM('show list rate') && showJobListRateAdmin('show_job_list_rate')">
                                                        <h2>S${{jobDataVal.hourly_cost}}/HR</h2>
                                                    </div> -->

                            <!-- <div class="action_div">
                                                        <button class="action_btn">ACTIONS<i class="fa fa-caret-down"></i></button>
                                                    </div> -->
                            <!-- </div> -->
                          </div>
                          <!-- <div class="img_with_desc">

                                            </div> -->
                          <ul class="manage_job_top_ul_nw">
                            <li>
                              <span class="material-icons-outlined">calendar_month_outlined</span
                              >{{ jobDataVal.start_date | moment_1 }}
                            </li>
                            <li>
                              <span class="material-icons-outlined">access_time_outlined</span
                              >{{ jobDataVal.start_time | jobStartEndTime }} -
                              {{ jobDataVal.end_time | jobStartEndTime }}
                              <span class="red_note_manage_job"
                                ><p v-if="jobDataVal.lunch_pay_hours != null">
                                  <strong>Note:</strong> Break hour not paid -
                                  {{ jobDataVal.lunch_pay_hours }} Hr
                                </p></span
                              >
                            </li>
                            <li
                              v-if="
                                showPermissionEM('show list rate') &&
                                showJobListRateAdmin('show_job_list_rate')
                              "
                            >
                              <span class="material-icons-outlined">monetization_on_outlined</span
                              >${{ jobDataVal.hourly_cost }}/Hour
                            </li>
                          </ul>
                          <div class="user_apl">
                            <div class="person_div_manage_job">
                              <span class="material-icons person_logo">person</span>
                              <span class="total_vacany_list"
                                >Applicant / Total Vacancy:
                                {{ jobDataVal.job_count_active_working_count }}/{{
                                  totalVacancy(jobDataVal.total_vacancies)
                                }}</span
                              >
                            </div>
                            <div class="working_completed_section">
                              <span>Awarded: {{ jobDataVal.total_applicants_count }}</span
                              ><span>Pending: {{ jobDataVal.job_count_active_working_count }}</span>
                            </div>
                          </div>
                          <div class="full_desc_emps">
                            <!-- <ul>
                                  <li>Posted By:<span>{{(jobDataVal.userData && jobDataVal.userData.last_name) ? jobDataVal.userData.last_name : ''}} {{(jobDataVal.userData && jobDataVal.userData.first_name) ? jobDataVal.userData.first_name : ''}}</span></li>
                                  <li>Job Posted D& Time:<span>{{jobDataVal.start_date | moment}} {{jobDataVal.start_time | jobStartEndTime}}</span></li>
                                  <li>Job Duration:<span>{{jobDataVal.start_date | moment}} {{jobDataVal.start_time | jobStartEndTime}} - {{jobDataVal.start_time | jobStartEndTime}}</span></li>
                                  <li>Job Hours:<span>{{jobDataVal.working_hours}}</span></li>
                                  <li>Vacancy:<span>{{jobDataVal.userJobs.length}}/{{jobDataVal.total_vacancies}}</span></li>
                                  <li v-if="jobDataVal.work_site_contact_person">Work Site Contact Person Name:<span>{{jobDataVal.work_site_contact_person ? jobDataVal.work_site_contact_person : ''}}</span></li>
                                  <li v-if="jobDataVal.work_site_contact_person_mobile_no">Work Site Contact Person Number:<span>{{jobDataVal.work_site_contact_person_country_code ? jobDataVal.work_site_contact_person_country_code : ''}}-{{jobDataVal.work_site_contact_person_mobile_no ? jobDataVal.work_site_contact_person_mobile_no : ''}}</span></li>
                              </ul> -->
                            <div class="single_accordian manage_job_accordian mb-3">
                              <!-- <br> -->
                              <div>
                                <div class="faq-content-box clearfix">
                                  <div>
                                    <div class="accordion-listing-box coloured_icon">
                                      <b-card no-body>
                                        <b-card-header header-tag="header" role="tab">
                                          <b-button
                                            v-b-toggle="
                                              'accordion_view_job_details_upcoming-' + jobDatakey
                                            "
                                            class="m-1"
                                            variant="info"
                                          >
                                            <div class="dashboard-box">
                                              <div class="headline">
                                                <h3 class="heading_section_h3">VIEW JOB DETAILS</h3>
                                              </div>
                                            </div>
                                          </b-button>
                                        </b-card-header>
                                        <b-collapse
                                          :id="'accordion_view_job_details_upcoming-' + jobDatakey"
                                          role="tabpanel"
                                        >
                                          <b-card-body>
                                            <div class="">
                                              <div class="row">
                                                <div class="col-xl-12">
                                                  <div class="comp_heading_div_manage_job">
                                                    <div class="dtl_text">
                                                      <h2>Posted By:</h2>
                                                      <p class="mb-0">
                                                        {{
                                                          jobDataVal.last_name
                                                            ? jobDataVal.last_name
                                                            : ""
                                                        }}
                                                        {{
                                                          jobDataVal.first_name
                                                            ? jobDataVal.first_name
                                                            : ""
                                                        }}
                                                      </p>
                                                      <p class="mb-0">
                                                        {{
                                                          jobDataVal.created_at
                                                            | customFormatterOnecreated
                                                        }}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div
                                                    class="table-responsive whole_custom_tbl common_table_scroll manage_job_new_tblz"
                                                  >
                                                    <table
                                                      class="table custom_manage_job_tbl unset_last_super"
                                                    >
                                                      <thead>
                                                        <tr>
                                                          <!-- <th v-if="jobDataVal.userData">Posted By:</th> -->
                                                          <!-- <th>Job Posted Date & Time:</th> -->
                                                          <!-- <th>Job Date & Time:</th> -->
                                                          <!-- <th>Job Hours:</th> -->
                                                          <!-- <th>Vacancy:</th> -->
                                                          <th
                                                            v-if="
                                                              jobDataVal.work_site_contact_person ||
                                                              jobDataVal.work_site_contact_person_mobile_no
                                                            "
                                                          >
                                                            Work Site Contact Person
                                                          </th>
                                                          <th></th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        <tr>
                                                          <!-- <td v-if="jobDataVal.userData">
                                                                                              {{(jobDataVal.userData && jobDataVal.userData.last_name) ? jobDataVal.userData.last_name : ''}} {{(jobDataVal.userData && jobDataVal.userData.first_name) ? jobDataVal.userData.first_name : ''}}
                                                                                          </td> -->
                                                          <!-- <td>
                                                                                              {{jobDataVal.created_at | customFormatterOnecreated}}
                                                                                          </td> -->
                                                          <!-- <td>{{jobDataVal.start_date | moment_1}} {{jobDataVal.start_time | jobStartEndTime}} - {{jobDataVal.end_time | jobStartEndTime}} </td>
                                                                                          <td>{{jobDataVal.working_hours}}</td>
                                                                                          <td>
                                                                                              {{jobDataVal.userJobsselected.length}}/{{jobDataVal.total_vacancies}}
                                                                                          </td> -->
                                                          <td
                                                            v-if="
                                                              jobDataVal.work_site_contact_person ||
                                                              jobDataVal.work_site_contact_person_mobile_no
                                                            "
                                                          >
                                                            {{
                                                              jobDataVal.work_site_contact_person
                                                                ? jobDataVal.work_site_contact_person
                                                                : ""
                                                            }}
                                                            <br />
                                                            {{
                                                              jobDataVal.work_site_contact_person_country_code
                                                                ? jobDataVal.work_site_contact_person_country_code +
                                                                  "-"
                                                                : ""
                                                            }}{{
                                                              jobDataVal.work_site_contact_person_mobile_no
                                                                ? jobDataVal.work_site_contact_person_mobile_no
                                                                : ""
                                                            }}<br />
                                                          </td>
                                                          <td
                                                            v-if="
                                                              jobDataVal.work_site_contact_person ||
                                                              jobDataVal.work_site_contact_person_mobile_no
                                                            "
                                                          >
                                                            {{
                                                              jobDataVal.work_site_contact_person_2
                                                                ? jobDataVal.work_site_contact_person_2
                                                                : ""
                                                            }}<br />
                                                            {{
                                                              jobDataVal.work_site_contact_person_2_mobile_no
                                                                ? jobDataVal.work_site_contact_person_2_country_code +
                                                                  "-"
                                                                : ""
                                                            }}{{
                                                              jobDataVal.work_site_contact_person_2_mobile_no
                                                                ? jobDataVal.work_site_contact_person_2_mobile_no
                                                                : ""
                                                            }}
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                  <div class="dtl_text">
                                                    <div class="large-content-requirement-upcoming">
                                                      <h2 v-if="jobDataVal.description">
                                                        Job Requirements
                                                        <span class="manage_job_eye_span"
                                                          ><i
                                                            class="fa fa-eye-slash"
                                                            :id="
                                                              'manage_job_requirement_upcoming_eye_icon' +
                                                              jobDatakey
                                                            "
                                                            @click="
                                                              ManageJobRequirementUpcomingEyeReveal(
                                                                jobDatakey
                                                              )
                                                            "
                                                            style="cursor: pointer"
                                                          ></i
                                                        ></span>
                                                      </h2>
                                                      <p
                                                        :id="
                                                          'visible-content-requirement-upcoming' +
                                                          jobDatakey
                                                        "
                                                        style=""
                                                        class=""
                                                        v-if="jobDataVal.description.length <= 249"
                                                      >
                                                        {{
                                                          jobDataVal.description.substring(0, 249)
                                                        }}
                                                      </p>
                                                      <p
                                                        :id="
                                                          'visible-content-requirement-upcoming' +
                                                          jobDatakey
                                                        "
                                                        style=""
                                                        class=""
                                                        v-if="jobDataVal.description.length >= 250"
                                                      >
                                                        {{
                                                          jobDataVal.description.substring(0, 250) +
                                                          ".."
                                                        }}
                                                      </p>
                                                      <p
                                                        :id="
                                                          'invisible-content-requirement-upcoming' +
                                                          jobDatakey
                                                        "
                                                        style="display: none"
                                                        class=""
                                                      >
                                                        {{ jobDataVal.description }}
                                                      </p>
                                                    </div>
                                                    <div class="large-content-scope-upcoming">
                                                      <h2 v-if="jobDataVal.job_scope">
                                                        Job Scope:
                                                        <span class="manage_job_eye_span"
                                                          ><i
                                                            class="fa fa-eye-slash"
                                                            :id="
                                                              'manage_job_scope_upcoming_eye_icon' +
                                                              jobDatakey
                                                            "
                                                            @click="
                                                              ManageJobScopeUpcomingEyeReveal(
                                                                jobDatakey
                                                              )
                                                            "
                                                            style="cursor: pointer"
                                                          ></i
                                                        ></span>
                                                      </h2>
                                                      <p
                                                        :id="
                                                          'visible-content-scope-upcoming' +
                                                          jobDatakey
                                                        "
                                                        style=""
                                                        class=""
                                                        v-if="jobDataVal.job_scope.length <= 249"
                                                      >
                                                        {{ jobDataVal.job_scope.substring(0, 249) }}
                                                      </p>
                                                      <p
                                                        :id="
                                                          'visible-content-scope-upcoming' +
                                                          jobDatakey
                                                        "
                                                        style=""
                                                        class=""
                                                        v-if="jobDataVal.job_scope.length >= 250"
                                                      >
                                                        {{
                                                          jobDataVal.job_scope.substring(0, 250) +
                                                          ".."
                                                        }}
                                                      </p>
                                                      <p
                                                        :id="
                                                          'invisible-content-scope-upcoming' +
                                                          jobDatakey
                                                        "
                                                        style="display: none"
                                                        class=""
                                                      >
                                                        {{ jobDataVal.job_scope }}
                                                      </p>
                                                    </div>
                                                    <h2 v-if="jobDataVal.address">Job Location</h2>
                                                    <p v-if="jobDataVal.address" class="mb-0">
                                                      {{ jobDataVal.address }}
                                                    </p>
                                                    <!-- <p v-if="jobDataVal.address"><i class="fa fa-map-marker"></i>{{jobDataVal.address}}</p> -->
                                                    <!-- <p v-if="jobDataVal.lunch_pay_hours != null"><strong>Note:</strong>Break hour not paid - {{jobDataVal.lunch_pay_hours}} Hr</p> -->
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </b-card-body>
                                        </b-collapse>
                                      </b-card>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="single_accordian manage_job_accordian">
                              <!-- <br> -->
                              <div>
                                <div class="faq-content-box clearfix">
                                  <div>
                                    <div class="accordion-listing-box coloured_icon">
                                      <b-card no-body>
                                        <b-card-header header-tag="header" role="tab">
                                          <b-button
                                            v-b-toggle="
                                              'accordion_view_candidates_upcoming-' + jobDatakey
                                            "
                                            class="m-1"
                                            variant="info"
                                            @click="
                                              getList(
                                                jobDataVal.id,
                                                jobDatakey,
                                                'upcoming',
                                                user_id
                                              )
                                            "
                                          >
                                            <div class="dashboard-box">
                                              <div class="headline">
                                                <h3 class="heading_section_h3">View candidates</h3>
                                              </div>
                                            </div>
                                          </b-button>
                                        </b-card-header>
                                        <b-collapse
                                          :id="'accordion_view_candidates_upcoming-' + jobDatakey"
                                          role="tabpanel"
                                        >
                                          <b-card-body>
                                            <!-- <div class="col-md-4">
                                              <div class="search-box">
                                                <b-form-group>
                                                  <b-form-input
                                                    id="searchBtn"
                                                    v-model="filterSearchSelected"
                                                    @change="
                                                      changeApplicantTabStatus(
                                                        'upcoming',
                                                        'selected',
                                                        jobDataVal.id,
                                                        jobDatakey,
                                                        jobDataVal.posted_by
                                                      )
                                                    "
                                                    placeholder="Search by Name"
                                                  ></b-form-input>
                                                  <b-button class="search_btn_tw_grd"></b-button>
                                                </b-form-group>
                                              </div>
                                            </div> -->
                                            <div class="">
                                              <div class="row">
                                                <div class="col-xl-12">
                                                  <b-tabs
                                                    class="custom_tabs_daily_manage_jobs custom_tabs_daily_manage_jobs_upcoming"
                                                  >
                                                    <div v-if="jobDataVal.selected">
                                                      <b-tab
                                                        :title="
                                                          'SELECTED (' +
                                                          jobDataVal.selected.length +
                                                          ')'
                                                        "
                                                        class="reject_last_li"
                                                        @click="
                                                          changeApplicantTabStatus(
                                                            'upcoming',
                                                            'selected',
                                                            jobDataVal.id,
                                                            jobDatakey,
                                                            jobDataVal.posted_by
                                                          )
                                                        "
                                                      >
                                                        <div
                                                          v-if="
                                                            jobDataVal.selected &&
                                                            jobDataVal.selected.length > 0
                                                          "
                                                        >
                                                          <div class="row" v-if="items.length > 0">
                                                            <div
                                                              class="col-md-6 col-sm-12 mobile_max_widht_and_flex"
                                                            >
                                                              <div
                                                                class="select-service-box clearfix check_label_single"
                                                              >
                                                                <b-form-group>
                                                                  <b-form-checkbox
                                                                    v-model="selectAll"
                                                                    @change="
                                                                      allSelected(jobDatakey)
                                                                    "
                                                                    >Select All
                                                                    In-Progress</b-form-checkbox
                                                                  >
                                                                  <b-form-checkbox
                                                                    v-model="selectAllCompleted"
                                                                    @change="
                                                                      allSelectCompleted(jobDatakey)
                                                                    "
                                                                    >Select All
                                                                    Completed</b-form-checkbox
                                                                  >
                                                                </b-form-group>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <b-table
                                                            ref="datatable"
                                                            show-empty
                                                            striped
                                                            hover
                                                            responsive
                                                            :items="jobDataVal.selected"
                                                            :fields="fields"
                                                            :filter="filter"
                                                            :sort-by.sync="sortBy"
                                                            :sort-desc.sync="sortDesc"
                                                            :sort-direction="sortDirection"
                                                            class="tbl_height new_box_tbl_mrgn daily_manage_job_dipper"
                                                          >
                                                            <template #cell(name)="items">
                                                              <div class="daily_image_job">
                                                                <div
                                                                  class="daily_job_manage_img_div"
                                                                >
                                                                  <b-img
                                                                    :src="
                                                                      items.item.profile_image !=
                                                                      null
                                                                        ? items.item.profile_image
                                                                        : webUrl +
                                                                          'assets/images/user-avatar-small-01.png'
                                                                    "
                                                                    alt="User Icon"
                                                                    height="100px"
                                                                    width="100px"
                                                                    class="user_img_emp"
                                                                  ></b-img>
                                                                  <div
                                                                    class="custom_star_rate_manage_job"
                                                                  >
                                                                    <span
                                                                      ><i class="fa fa-star"></i
                                                                      >{{
                                                                        items.item.average_rating
                                                                          ? items.item
                                                                              .average_rating
                                                                          : 0
                                                                      }}</span
                                                                    >
                                                                  </div>
                                                                </div>
                                                                <div
                                                                  class="daily_job_manage_user_description_div"
                                                                >
                                                                  <b-link
                                                                    v-if="
                                                                      showPermissionEM(
                                                                        'view profile'
                                                                      )
                                                                    "
                                                                    :to="{
                                                                      name: 'employer-jobseeker-dp-profile-view',
                                                                      params: {
                                                                        id: encrypt(
                                                                          items.item.user_id
                                                                        ),
                                                                      },
                                                                    }"
                                                                    target="_blank"
                                                                  >
                                                                    <h2>
                                                                      {{
                                                                        items.item.last_name
                                                                          ? items.item.last_name
                                                                          : ""
                                                                      }}
                                                                      {{
                                                                        items.item.first_name
                                                                          ? items.item.first_name
                                                                          : ""
                                                                      }}
                                                                    </h2></b-link
                                                                  >
                                                                  <h2 v-else>
                                                                    {{
                                                                      items.item.last_name
                                                                        ? items.item.last_name
                                                                        : ""
                                                                    }}
                                                                    {{
                                                                      items.item.first_name
                                                                        ? items.item.first_name
                                                                        : ""
                                                                    }}
                                                                  </h2>
                                                                  <div class="phone_nmber_div">
                                                                    <a href="#"
                                                                      >{{
                                                                        items.item.country_code
                                                                          ? items.item.country_code
                                                                          : ""
                                                                      }}-{{
                                                                        items.item.mobile_number
                                                                          ? items.item.mobile_number
                                                                          : ""
                                                                      }}</a
                                                                    >
                                                                  </div>
                                                                  <div class="star-rating">
                                                                    <!-- <star-rating v-model="items.item.userdetail.average_rating" v-bind:star-size="20" :show-rating="false"></star-rating> -->
                                                                    <!-- <span class="star"></span>
                                                                                                <span class="star"></span>
                                                                                                <span class="star"></span>
                                                                                                <span class="star"></span>
                                                                                                <span class="star"></span> -->
                                                                    <div class="com_imgs">
                                                                      <img
                                                                        v-if="
                                                                          items.item
                                                                            .completed_count > 0 &&
                                                                          jobDataVal.job_company &&
                                                                          jobDataVal.job_company
                                                                            .company_logo != null
                                                                        "
                                                                        :src="
                                                                          jobDataVal.job_company
                                                                            .thumbnail
                                                                            ? jobDataVal.job_company
                                                                                .thumbnail
                                                                            : jobDataVal.job_company
                                                                                .company_logo
                                                                        "
                                                                        :key="
                                                                          jobDataVal.job_company
                                                                            .thumbnail
                                                                            ? jobDataVal.job_company
                                                                                .thumbnail
                                                                            : jobDataVal.job_company
                                                                                .company_logo
                                                                        "
                                                                        alt="User Icon"
                                                                        style="width: 63%"
                                                                        class="innner_round_image_in_tab"
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                  <div
                                                                    v-if="
                                                                      items.item.user_badges != null
                                                                    "
                                                                  >
                                                                    <b-img
                                                                      class="worked_for_logo"
                                                                      v-for="(usb, us) in items.item
                                                                        .user_badges"
                                                                      :key="us"
                                                                      :src="usb.badges.badge_image"
                                                                      :title="usb.badges.name"
                                                                      alt="User Icon"
                                                                    ></b-img>
                                                                  </div>

                                                                  <div>
                                                                    <p
                                                                      v-if="
                                                                        jobDataVal.proposed_arrival_time !=
                                                                          null &&
                                                                        jobDataVal.job_type_id == 2
                                                                      "
                                                                    >
                                                                      ETA:
                                                                      {{
                                                                        jobDataVal.proposed_arrival_time !=
                                                                        null
                                                                          ? jobDataVal.proposed_arrival_time
                                                                          : "" | moment
                                                                      }}
                                                                      |
                                                                      {{
                                                                        jobDataVal.proposed_arrival_time !=
                                                                        null
                                                                          ? jobDataVal.proposed_arrival_time
                                                                          : ""
                                                                            | applicantOriginalSET
                                                                      }}
                                                                    </p>
                                                                    <!-- <p v-else>ETA: NA</p> -->
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </template>
                                                            <template #cell(mobile_number1)="items">
                                                              <div class="phone_nmber_div">
                                                                <a href="#"
                                                                  >Upcoming Applied -
                                                                  {{ items.item.timesheet_url }}</a
                                                                >
                                                              </div>
                                                              <div class="phone_nmber_div">
                                                                <a href="#"
                                                                  >Completed -
                                                                  {{
                                                                    items.item
                                                                      .confirm_job_completion_by
                                                                  }}</a
                                                                >
                                                              </div>
                                                            </template>
                                                            <!-- <template #cell(mobile_number)="items">
                                                                                <div class="phone_nmber_div">
                                                                                    <a href="#">{{items.item.userdetail.country_code ? items.item.userdetail.country_code : ''}}-{{items.item.userdetail.mobile_number ? items.item.userdetail.mobile_number : ''}}</a>
                                                                                </div>
                                                                                </template> -->

                                                            <!-- <template #cell(thumb)="items">
                                                                                    <div class="thumb_div">
                                                                                    <i class="fa fa-thumbs-up blue_thumb"></i>
                                                                                </div>
                                                                                </template>  -->
                                                            <template #cell(ac_button)="items">
                                                              <div class="status_btn">
                                                                <li
                                                                  v-if="
                                                                    items.item.acknowledged ==
                                                                      'yes' &&
                                                                    items.item.job_status !=
                                                                      'suspended'
                                                                  "
                                                                >
                                                                  <b>ACNOWLEDGED</b>
                                                                </li>
                                                                <button
                                                                  class="ack_btn upcoming_btn"
                                                                >
                                                                  {{
                                                                    items.item.job_status
                                                                      | capitalize
                                                                  }}
                                                                </button>
                                                              </div>
                                                            </template>
                                                            <template
                                                              #cell(toggleAction)="items"
                                                              v-if="
                                                                showPermissionEM('view job action')
                                                              "
                                                            >
                                                              <div
                                                                class="toggle-action custom_toggle_three_dot dp_job_action"
                                                                v-if="
                                                                  items.item.job_status ==
                                                                  'upcoming'
                                                                "
                                                              >
                                                                <b-dropdown
                                                                  class="mx-1"
                                                                  right
                                                                  text=""
                                                                  boundary="window"
                                                                >
                                                                  <b-dropdown-item
                                                                    v-if="
                                                                      items.item.job_status ==
                                                                      'upcoming'
                                                                    "
                                                                    @click="
                                                                      cancelApplicant(
                                                                        items.item.user_id,
                                                                        items.item.job_id,
                                                                        jobDatakey
                                                                      )
                                                                    "
                                                                    >Cancel
                                                                  </b-dropdown-item>
                                                                </b-dropdown>
                                                              </div>
                                                            </template>
                                                          </b-table>
                                                        </div>
                                                        <div v-else>
                                                          <div
                                                            class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                                                          >
                                                            <p class="actionData">
                                                              No Record Found
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </b-tab>
                                                    </div>
                                                    <div>
                                                      <b-tab
                                                        :title="
                                                          'ALL APPLICANTS (' +
                                                          jobDataVal.total.length +
                                                          ')'
                                                        "
                                                        class="reject_last_li mobile_min_width_1000"
                                                        @click="
                                                          getList(
                                                            jobDataVal.id,
                                                            jobDatakey,
                                                            'in-progress',
                                                            user_id
                                                          )
                                                        "
                                                      >
                                                        <div v-if="jobDataVal.total > 0">
                                                          <b-table
                                                            ref="datatable"
                                                            show-empty
                                                            striped
                                                            hover
                                                            responsive
                                                            :items="jobDataVal.total"
                                                            :fields="fields"
                                                            :filter="filter"
                                                            :sort-by.sync="sortBy"
                                                            :sort-desc.sync="sortDesc"
                                                            :sort-direction="sortDirection"
                                                            class="tbl_height new_box_tbl_mrgn daily_manage_job_dipper"
                                                          >
                                                            <template #cell(name)="items">
                                                              <div class="daily_image_job">
                                                                <div
                                                                  class="daily_job_manage_img_div"
                                                                >
                                                                  <b-img
                                                                    :src="
                                                                      items.item.profile_image !=
                                                                      null
                                                                        ? items.item.profile_image
                                                                        : webUrl +
                                                                          'assets/images/user-avatar-small-01.png'
                                                                    "
                                                                    alt="User Icon"
                                                                    height="100px"
                                                                    width="100px"
                                                                    class="user_img_emp"
                                                                  ></b-img>
                                                                  <div
                                                                    class="custom_star_rate_manage_job"
                                                                  >
                                                                    <span
                                                                      ><i class="fa fa-star"></i
                                                                      >{{
                                                                        items.item.average_rating
                                                                          ? items.item
                                                                              .average_rating
                                                                          : 0
                                                                      }}</span
                                                                    >
                                                                  </div>
                                                                </div>
                                                                <div
                                                                  class="daily_job_manage_user_description_div"
                                                                >
                                                                  <b-link
                                                                    v-if="
                                                                      showPermissionEM(
                                                                        'view profile'
                                                                      )
                                                                    "
                                                                    :to="{
                                                                      name: 'employer-jobseeker-dp-profile-view',
                                                                      params: {
                                                                        id: encrypt(
                                                                          items.item.user_id
                                                                        ),
                                                                      },
                                                                    }"
                                                                    target="_blank"
                                                                  >
                                                                    <h2>
                                                                      {{
                                                                        items.item.last_name
                                                                          ? items.item.last_name
                                                                          : ""
                                                                      }}
                                                                      {{
                                                                        items.item.first_name
                                                                          ? items.item.first_name
                                                                          : ""
                                                                      }}
                                                                    </h2></b-link
                                                                  >
                                                                  <h2 v-else>
                                                                    {{
                                                                      items.item.last_name
                                                                        ? items.item.last_name
                                                                        : ""
                                                                    }}
                                                                    {{
                                                                      items.item.first_name
                                                                        ? items.item.first_name
                                                                        : ""
                                                                    }}
                                                                  </h2>

                                                                  <div class="star-rating">
                                                                    <!-- <star-rating v-model="items.item.userdetail.average_rating" v-bind:star-size="20" :show-rating="false"></star-rating> -->
                                                                    <!-- <span class="star"></span>
                                                                                            <span class="star"></span>
                                                                                            <span class="star"></span>
                                                                                            <span class="star"></span>
                                                                                            <span class="star"></span> -->
                                                                    <div class="com_imgs">
                                                                      <b-img
                                                                        class="worked_for_logo"
                                                                        v-if="
                                                                          items.item
                                                                            .worked_for_company !=
                                                                            null &&
                                                                          items.item
                                                                            .worked_for_company ==
                                                                            'yes'
                                                                        "
                                                                        :src="
                                                                          jobDataVal.job_company
                                                                            .thumbnail
                                                                        "
                                                                        :title="
                                                                          jobDataVal.job_company
                                                                            .name
                                                                        "
                                                                        alt="User Icon"
                                                                      ></b-img>
                                                                    </div>
                                                                  </div>
                                                                  <div
                                                                    v-if="
                                                                      items.item.user_badges != null
                                                                    "
                                                                  >
                                                                    <b-img
                                                                      class="worked_for_logo"
                                                                      v-for="(usb, us) in items.item
                                                                        .user_badges"
                                                                      :key="us"
                                                                      :src="usb.badges.badge_image"
                                                                      :title="usb.badges.name"
                                                                      alt="User Icon"
                                                                    ></b-img>
                                                                  </div>
                                                                  <!-- <div>
                                                                                                <p v-if="items.item.proposed_arrival_time != null">ETA: {{items.item.proposed_arrival_time != null ? items.item.proposed_arrival_time : '' | moment }} | {{ items.item.proposed_arrival_time != null ? items.item.proposed_arrival_time : '' | applicantOriginalSET }}</p>
                                                                                                <p v-else>ETA: NA</p>
                                                                                            </div> -->
                                                                </div>
                                                              </div>
                                                            </template>
                                                            <template #cell(mobile_number1)="items">
                                                              <div class="phone_nmber_div">
                                                                <a href="#"
                                                                  >Upcoming Applied -
                                                                  {{ items.item.applied_count }}</a
                                                                >
                                                              </div>

                                                              <div class="phone_nmber_div">
                                                                <a href="#"
                                                                  >Completed -
                                                                  {{
                                                                    items.item.completed_count
                                                                  }}</a
                                                                >
                                                              </div>
                                                            </template>

                                                            <template #cell(time)="items">
                                                              <div class="phone_nmber_div">
                                                                <p href="#">
                                                                  <span>{{
                                                                    items.item.created_at
                                                                      ? items.item.created_at
                                                                      : "" | moment
                                                                  }}</span>
                                                                  <span>
                                                                    {{
                                                                      items.item.created_at
                                                                        ? items.item.created_at
                                                                        : "" | applicantOriginalSET
                                                                    }}</span
                                                                  >
                                                                </p>
                                                              </div>
                                                            </template>
                                                            <!-- <template #cell(time)="items">
                                                                                <div class="timing_div">
                                                                                    <ul>
                                                                                        <li><i class="fa fa-arrow-down green_arrow"></i><span>{{items.item.original_start_time ? items.item.original_start_time : '' | applicantOriginalSET}}</span><i class="fa fa-arrow-up red_arrow green_arrow"></i><span>{{items.item.original_end_time ? items.item.original_end_time : '' | applicantOriginalSET}}</span></li>
                                                                                        <li><i class="fa fa-arrow-down green_arrow gray_tab_round"></i><span>{{items.item.start_time ? items.item.start_time : '' | applicantStartEndTime}}</span><i class="fa fa-arrow-up  green_arrow gray_tab_round"></i><span>{{items.item.end_time ? items.item.end_time : '' | applicantStartEndTime}}</span></li>
                                                                                    </ul>
                                                                            </div>
                                                                            </template>  -->
                                                            <!-- <template #cell(thumb)="items">
                                                                                <div class="thumb_div">
                                                                                <i class="fa fa-thumbs-up blue_thumb"></i>
                                                                            </div>
                                                                            </template>  -->
                                                            <template
                                                              #cell(ac_button)="items"
                                                              v-if="
                                                                showPermissionEM('view job action')
                                                              "
                                                            >
                                                              <div class="status_btn">
                                                                <li
                                                                  v-if="
                                                                    items.item.acknowledged ==
                                                                      'yes' &&
                                                                    items.item.job_status !=
                                                                      'suspended'
                                                                  "
                                                                >
                                                                  <b>ACNOWLEDGED</b>
                                                                </li>
                                                                <button
                                                                  @click="
                                                                    awardJob(
                                                                      items.item.user_id,
                                                                      items.item.job_id,
                                                                      jobDatakey,
                                                                      jobDataVal.posted_by
                                                                    )
                                                                  "
                                                                  class="ack_btn"
                                                                  style="
                                                                    background-color: green !important;
                                                                  "
                                                                >
                                                                  Award
                                                                </button>
                                                                <button
                                                                  @click="
                                                                    notSuitable(
                                                                      items.item.user_id,
                                                                      items.item.job_id,
                                                                      jobDatakey
                                                                    )
                                                                  "
                                                                  class="ack_btn"
                                                                  style="
                                                                    background-color: red !important;
                                                                  "
                                                                >
                                                                  Not Suitable
                                                                </button>
                                                              </div>
                                                              <div class="status_btn"></div>
                                                            </template>
                                                            <!-- <template #cell(toggleAction)="items" v-if="items.item.mark_as_arrived == null &&  items.item.withdraw_application == 'no'" >
                                                                                <div class="toggle-action custom_toggle_three_dot dp_job_action">
                                                                                    <b-dropdown class="mx-1" right text="" boundary="window">
                                                                                    <b-dropdown-item v-if="items.item.mark_as_arrived == null &&  items.item.withdraw_application == 'no'" @click="cancelApplicant(items.item.id)">CANCEL
                                                                                        </b-dropdown-item>
                                                                                    </b-dropdown>
                                                                                </div>
                                                                            </template> -->
                                                          </b-table>
                                                        </div>
                                                        <div v-else>
                                                          <div
                                                            class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                                                          >
                                                            <p class="actionData">
                                                              No Record Found
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </b-tab>
                                                    </div>
                                                    <div>
                                                      <b-tab
                                                        :title="
                                                          'CANCELLED (' +
                                                          jobDataVal.cancelled.length +
                                                          ')'
                                                        "
                                                        class="reject_last_li mobile_min_width_1000"
                                                        @click="
                                                          getList(
                                                            jobDataVal.id,
                                                            jobDatakey,
                                                            'in-progress',
                                                            user_id
                                                          )
                                                        "
                                                      >
                                                        <div
                                                          v-if="
                                                            jobDataVal.cancelled &&
                                                            jobDataVal.cancelled.length > 0
                                                          "
                                                        >
                                                          <b-table
                                                            ref="datatable"
                                                            show-empty
                                                            striped
                                                            hover
                                                            responsive
                                                            :items="jobDataVal.cancelled"
                                                            :fields="fields"
                                                            :filter="filter"
                                                            :sort-by.sync="sortBy"
                                                            :sort-desc.sync="sortDesc"
                                                            :sort-direction="sortDirection"
                                                            class="tbl_height new_box_tbl_mrgn daily_manage_job_dipper"
                                                          >
                                                            <template #cell(name)="items">
                                                              <div class="daily_image_job">
                                                                <div
                                                                  class="daily_job_manage_img_div"
                                                                >
                                                                  <b-img
                                                                    :src="
                                                                      items.item.profile_image !=
                                                                      null
                                                                        ? items.item.profile_image
                                                                        : webUrl +
                                                                          'assets/images/user-avatar-small-01.png'
                                                                    "
                                                                    alt="User Icon"
                                                                    height="100px"
                                                                    width="100px"
                                                                    class="user_img_emp"
                                                                  ></b-img>
                                                                  <div
                                                                    class="custom_star_rate_manage_job"
                                                                  >
                                                                    <span
                                                                      ><i class="fa fa-star"></i
                                                                      >{{
                                                                        items.item.average_rating
                                                                          ? items.item
                                                                              .average_rating
                                                                          : 0
                                                                      }}</span
                                                                    >
                                                                  </div>
                                                                </div>
                                                                <div
                                                                  class="daily_job_manage_user_description_div"
                                                                >
                                                                  <b-link
                                                                    v-if="
                                                                      showPermissionEM(
                                                                        'view profile'
                                                                      )
                                                                    "
                                                                    :to="{
                                                                      name: 'employer-jobseeker-dp-profile-view',
                                                                      params: {
                                                                        id: encrypt(
                                                                          items.item.user_id
                                                                        ),
                                                                      },
                                                                    }"
                                                                    target="_blank"
                                                                  >
                                                                    <h2>
                                                                      {{
                                                                        items.item.last_name
                                                                          ? items.item.last_name
                                                                          : ""
                                                                      }}
                                                                      {{
                                                                        items.item.first_name
                                                                          ? items.item.first_name
                                                                          : ""
                                                                      }}
                                                                    </h2></b-link
                                                                  >
                                                                  <h2 v-else>
                                                                    {{
                                                                      items.item.last_name
                                                                        ? items.item.last_name
                                                                        : ""
                                                                    }}
                                                                    {{
                                                                      items.item.first_name
                                                                        ? items.item.first_name
                                                                        : ""
                                                                    }}
                                                                  </h2>
                                                                  <div class="phone_nmber_div">
                                                                    <a href="#"
                                                                      >{{
                                                                        items.item.country_code
                                                                          ? items.item.country_code
                                                                          : ""
                                                                      }}-{{
                                                                        items.item.mobile_number
                                                                          ? items.item.mobile_number
                                                                          : ""
                                                                      }}</a
                                                                    >
                                                                  </div>
                                                                  <div class="star-rating">
                                                                    <!-- <star-rating v-model="items.item.userdetail.average_rating" v-bind:star-size="20" :show-rating="false"></star-rating> -->
                                                                    <!-- <span class="star"></span>
                                                                                        <span class="star"></span>
                                                                                        <span class="star"></span>
                                                                                        <span class="star"></span>
                                                                                        <span class="star"></span> -->
                                                                  </div>
                                                                  <div
                                                                    v-if="
                                                                      items.item.user_badges != null
                                                                    "
                                                                  >
                                                                    <b-img
                                                                      class="worked_for_logo"
                                                                      v-for="(usb, us) in items.item
                                                                        .user_badges"
                                                                      :key="us"
                                                                      :src="usb.badges.badge_image"
                                                                      :title="usb.badges.name"
                                                                      alt="User Icon"
                                                                    ></b-img>
                                                                  </div>
                                                                  <div>
                                                                    <p
                                                                      v-if="
                                                                        jobDataVal.proposed_arrival_time !=
                                                                          null &&
                                                                        jobDataVal.job_type_id == 2
                                                                      "
                                                                    >
                                                                      ETA:
                                                                      {{
                                                                        jobDataVal.proposed_arrival_time !=
                                                                        null
                                                                          ? jobDataVal.proposed_arrival_time
                                                                          : "" | moment
                                                                      }}
                                                                      |
                                                                      {{
                                                                        jobDataVal.proposed_arrival_time !=
                                                                        null
                                                                          ? jobDataVal.proposed_arrival_time
                                                                          : ""
                                                                            | applicantOriginalSET
                                                                      }}
                                                                    </p>
                                                                    <!-- <p v-else>ETA: NA</p> -->
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </template>
                                                            <template #cell(mobile_number1)="items">
                                                              <div class="phone_nmber_div">
                                                                <a href="#"
                                                                  >Upcoming Applied -
                                                                  {{ items.item.applied_count }}</a
                                                                >
                                                              </div>
                                                              <div class="phone_nmber_div">
                                                                <a href="#"
                                                                  >Completed -
                                                                  {{
                                                                    items.item.completed_count
                                                                  }}</a
                                                                >
                                                              </div>
                                                            </template>
                                                            <!-- <template #cell(mobile_number)="items">
                                                                            <div class="phone_nmber_div">
                                                                                <a href="#">{{items.item.userdetail.country_code ? items.item.userdetail.country_code : ''}}-{{items.item.userdetail.mobile_number ? items.item.userdetail.mobile_number : ''}}</a>
                                                                            </div>
                                                                            </template> -->
                                                            <template #cell(time)="items">
                                                              <div
                                                                class="timing_div cancelled_div_tb"
                                                              >
                                                                <ul>
                                                                  <li>
                                                                    <span
                                                                      ><b
                                                                        >Cancelled Reason:</b
                                                                      ></span
                                                                    >
                                                                    <span>{{
                                                                      items.item.cancellation_reason
                                                                        ? items.item
                                                                            .cancellation_reason
                                                                        : ""
                                                                    }}</span>
                                                                  </li>
                                                                  <br />
                                                                  <li>
                                                                    <span
                                                                      ><b>Cancelled At:</b></span
                                                                    >
                                                                    <span>{{
                                                                      items.item.cancelled_at
                                                                        ? items.item.cancelled_at
                                                                        : "" | customFormatterOne
                                                                    }}</span>
                                                                  </li>
                                                                </ul>
                                                              </div>
                                                            </template>
                                                            <!-- <template #cell(thumb)="items">
                                                                                <div class="thumb_div">
                                                                                <i class="fa fa-thumbs-up blue_thumb"></i>
                                                                            </div>
                                                                            </template>  -->

                                                            <!-- <template #cell(toggleAction)="items">
                                                                                <div class="toggle-action custom_toggle_three_dot dp_job_action">
                                                                                    <b-dropdown class="mx-1" right text="" boundary="window">
                                                                                        <b-dropdown-item :to="{name: 'empManageUserEdit', params: {id: encrypt(items.item.id)}}">Edit
                                                                                        </b-dropdown-item>
                                                                                        <b-dropdown-item :to="{name: 'emp-manage-user-view', params: {id: encrypt(items.item.id)}}">View Profile
                                                                                        </b-dropdown-item>
                                                                                        <b-dropdown-item v-on:click="deleteEmployer(items.item.id)">Delete</b-dropdown-item>
                                                                                    </b-dropdown>
                                                                                </div>
                                                                            </template> -->
                                                          </b-table>
                                                        </div>
                                                        <div v-else>
                                                          <div
                                                            class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                                                          >
                                                            <p class="actionData">
                                                              No Record Found
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </b-tab>
                                                    </div>
                                                    <!-- <b-tab :title="'APPLICANT WIDTHDRAWN (' + jobDataVal.userJobsApplicationWithdrawn.length + ')'"  class="reject_last_li" @click="changeApplicantTabStatus('upcoming','applicant_withdrawn',jobDataVal.id,jobDatakey)">
                                                                        <b-table ref="datatable" show-empty striped hover responsive :items="jobDataVal.userJobs" :fields="fields"  :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="   tbl_height   new_box_tbl_mrgn daily_manage_job_dipper">
                                                                            <template #cell(name)="items">
                                                                                <div class="daily_job_manage_img_div">
                                                                                    <b-img :src="(items.item.userdetail.thumbnail != null ? items.item.userdetail.thumbnail: webUrl+'assets/images/user-avatar-small-01.png')" alt="User Icon" height="100px" width="100px" class="user_img_emp"></b-img>
                                                                                </div>
                                                                                <div class="daily_job_manage_user_description_div">
                                                                                    <h2>{{items.item.userdetail.last_name ? items.item.userdetail.last_name : ''}} {{items.item.userdetail.first_name ? items.item.userdetail.first_name : ''}}</h2>
                                                                                    <div class="star-rating">
                                                                                        <star-rating v-model="items.item.userdetail.average_rating" v-bind:star-size="20" :show-rating="false"></star-rating>

                                                                                    </div>
                                                                                        <div>
                                                                                            <p v-if="items.item.proposed_arrival_time != null">ETA: {{items.item.proposed_arrival_time != null ? items.item.proposed_arrival_time : '' | moment }} | {{ items.item.proposed_arrival_time != null ? items.item.proposed_arrival_time : '' | applicantOriginalSET }}</p>
                                                                                            <p v-else>ETA: NA</p>
                                                                                        </div>
                                                                                </div>

                                                                            </template>
                                                                            <template #cell(mobile_number)="items">
                                                                            <div class="phone_nmber_div">
                                                                                <a href="#">{{items.item.userdetail.country_code ? items.item.userdetail.country_code : ''}}-{{items.item.userdetail.mobile_number ? items.item.userdetail.mobile_number : ''}}</a>
                                                                            </div>
                                                                            </template>
                                                                            <template #cell(time)="items">
                                                                                <div class="timing_div">
                                                                                    <ul>
                                                                                        <li>Applied On<span>{{items.item.created_at ? items.item.created_at : '' | customFormatterOne}}</span></li>
                                                                                    </ul>
                                                                            </div>
                                                                            </template>
                                                                            <template #cell(thumb)="items">
                                                                                <div class="thumb_div">
                                                                                <i class="fa fa-thumbs-up blue_thumb"></i>
                                                                            </div>
                                                                            </template>
                                                                            <template #cell(ac_button)="items">
                                                                                <div class="status_btn">

                                                                                </div>
                                                                            </template>

                                                                        </b-table>
                                                                    </b-tab>
                                                                    <b-tab :title="' SYSTEM WIDTHDRAWN (' + jobDataVal.userJobsSystemWithdrawn.length + ')'" class="reject_last_li" @click="changeApplicantTabStatus('upcoming','system_withdrawn',jobDataVal.id,jobDatakey)">
                                                                        <b-table ref="datatable" show-empty striped hover responsive :items="jobDataVal.userJobs" :fields="fields"  :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="   tbl_height   new_box_tbl_mrgn daily_manage_job_dipper">
                                                                            <template #cell(name)="items">
                                                                                <div class="daily_job_manage_img_div">
                                                                                    <b-img :src="(items.item.userdetail.thumbnail != null ? items.item.userdetail.thumbnail: webUrl+'assets/images/user-avatar-small-01.png')" alt="User Icon" height="100px" width="100px" class="user_img_emp"></b-img>
                                                                                </div>
                                                                                <div class="daily_job_manage_user_description_div">
                                                                                    <h2>{{items.item.userdetail.last_name ? items.item.userdetail.last_name : ''}} {{items.item.userdetail.first_name ? items.item.userdetail.first_name : ''}}</h2>
                                                                                    <div class="star-rating">
                                                                                        <star-rating v-model="items.item.userdetail.average_rating" v-bind:star-size="20" :show-rating="false"></star-rating>

                                                                                    </div>
                                                                                        <div>
                                                                                            <p v-if="items.item.proposed_arrival_time != null">ETA: {{items.item.proposed_arrival_time != null ? items.item.proposed_arrival_time : '' | moment }} | {{ items.item.proposed_arrival_time != null ? items.item.proposed_arrival_time : '' | applicantOriginalSET }}</p>
                                                                                            <p v-else>ETA: NA</p>
                                                                                        </div>
                                                                                </div>

                                                                            </template>
                                                                            <template #cell(mobile_number)="items">
                                                                            <div class="phone_nmber_div">
                                                                                <a href="#">{{items.item.userdetail.country_code ? items.item.userdetail.country_code : ''}}-{{items.item.userdetail.mobile_number ? items.item.userdetail.mobile_number : ''}}</a>
                                                                            </div>
                                                                            </template>
                                                                            <template #cell(time)="items">
                                                                                <div class="timing_div">
                                                                                    <ul>
                                                                                        <li><i class="fa fa-arrow-down green_arrow"></i><span>{{items.item.original_start_time ? items.item.original_start_time : '' | applicantOriginalSET}}</span><i class="fa fa-arrow-up red_arrow green_arrow"></i><span>{{items.item.original_end_time ? items.item.original_end_time : '' | applicantOriginalSET}}</span></li>
                                                                                        <li><i class="fa fa-arrow-down green_arrow gray_tab_round"></i><span>{{items.item.start_time ? items.item.start_time : '' | applicantStartEndTime}}</span><i class="fa fa-arrow-up  green_arrow gray_tab_round"></i><span>{{items.item.end_time ? items.item.end_time : '' | applicantStartEndTime}}</span></li>
                                                                                    </ul>
                                                                            </div>
                                                                            </template>
                                                                            <template #cell(thumb)="items">
                                                                                <div class="thumb_div">
                                                                                <i class="fa fa-thumbs-up blue_thumb"></i>
                                                                            </div>
                                                                            </template>
                                                                            <template #cell(ac_button)="items">
                                                                                <div class="status_btn">
                                                                                    <button class="ack_btn">ACNOWLEDGED</button>
                                                                                    <button class="prg_btn">INPROGRESS</button>
                                                                                    <button class="comp_tbn">MAKE COMPLETION</button>
                                                                                </div>
                                                                            </template>

                                                                        </b-table>
                                                                    </b-tab>
                                                                    <b-tab :title="' RATE & REVIEW (' + jobDataVal.ratings.length + ')'"  class="reject_last_li" @click="changeApplicantTabStatus('upcoming','selected',jobDataVal.id,jobDatakey)">
                                                                    <b-table ref="datatable" show-empty striped hover responsive :items="jobDataVal.userJobs" :fields="fields" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="   tbl_height   new_box_tbl_mrgn daily_manage_job_dipper">
                                                                            <template #cell(name)="items">
                                                                                <div class="daily_job_manage_img_div">
                                                                                    <b-img :src="(items.item.userdetail.thumbnail != null ? items.item.userdetail.thumbnail: webUrl+'assets/images/user-avatar-small-01.png')" alt="User Icon" height="100px" width="100px" class="user_img_emp"></b-img>
                                                                                </div>
                                                                                <div class="daily_job_manage_user_description_div">
                                                                                    <h2>{{items.item.userdetail.last_name ? items.item.userdetail.last_name : ''}} {{items.item.userdetail.first_name ? items.item.userdetail.first_name : ''}}</h2>
                                                                                    <div class="star-rating">
                                                                                        <star-rating v-model="items.item.userdetail.average_rating" v-bind:star-size="20" :show-rating="false"></star-rating>

                                                                                    </div>
                                                                                        <div>
                                                                                            <p v-if="items.item.proposed_arrival_time != null">ETA: {{items.item.proposed_arrival_time != null ? items.item.proposed_arrival_time : '' | moment }} | {{ items.item.proposed_arrival_time != null ? items.item.proposed_arrival_time : '' | applicantOriginalSET }}</p>
                                                                                            <p v-else>ETA: NA</p>
                                                                                        </div>
                                                                                </div>

                                                                            </template>
                                                                            <template #cell(mobile_number)="items">
                                                                            <div class="phone_nmber_div">
                                                                                <a href="#">{{items.item.userdetail.country_code ? items.item.userdetail.country_code : ''}}-{{items.item.userdetail.mobile_number ? items.item.userdetail.mobile_number : ''}}</a>
                                                                            </div>
                                                                            </template>
                                                                            <template #cell(time)="items">
                                                                                <div class="timing_div">
                                                                                    <ul>
                                                                                        <li><i class="fa fa-arrow-down green_arrow"></i><span>{{items.item.original_start_time ? items.item.original_start_time : '' | applicantOriginalSET}}</span><i class="fa fa-arrow-up red_arrow green_arrow"></i><span>{{items.item.original_end_time ? items.item.original_end_time : '' | applicantOriginalSET}}</span></li>
                                                                                        <li><i class="fa fa-arrow-down green_arrow gray_tab_round"></i><span>{{items.item.start_time ? items.item.start_time : '' | applicantStartEndTime}}</span><i class="fa fa-arrow-up  green_arrow gray_tab_round"></i><span>{{items.item.end_time ? items.item.end_time : '' | applicantStartEndTime}}</span></li>
                                                                                    </ul>
                                                                            </div>
                                                                            </template>
                                                                            <template #cell(thumb)="items">
                                                                                <div class="thumb_div">
                                                                                <i class="fa fa-thumbs-up blue_thumb"></i>
                                                                            </div>
                                                                            </template>
                                                                            <template #cell(ac_button)="items">
                                                                                <div class="status_btn">
                                                                                    <button class="ack_btn">ACNOWLEDGED</button>
                                                                                    <button class="prg_btn">INPROGRESS</button>
                                                                                    <button class="comp_tbn">MAKE COMPLETION</button>
                                                                                </div>
                                                                            </template>

                                                                        </b-table>
                                                                    </b-tab> -->
                                                  </b-tabs>
                                                </div>
                                              </div>
                                            </div>
                                          </b-card-body>
                                        </b-collapse>
                                      </b-card>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div
                      class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                      v-if="jobDatas.length == 0"
                    >
                      <p class="actionData"><strong>No Record Found</strong></p>
                    </div> -->
                  </b-tab>

                  <b-tab
                    :title="'COMPLETED'"
                    :active="completed"
                    @click="changeStatus('completed', 'all', 'all', '')"
                    class="reject_last_li"
                  >
                    <div class="top_desc_div" v-for="(jobDataVal, jobDatakey) in jobDatas">
                      <div class="box_shadow box_new_shadow">
                        <div class="padding_manage_job">
                          <ul class="com_id_ul">
                            <li>{{ jobDataVal.job_unique_id }}</li>

                            <!-- <li>SERVICE AMT: S${{jobDataVal.service_fee}}</li> -->
                            <li v-if="jobDataVal.assign_user">
                              <button
                                class="demand_btn assign_user_name"
                                style="pointer-events: none"
                              >
                                Assigned User : {{ jobDataVal.assign_user.last_name }}
                                {{ jobDataVal.assign_user.first_name }}
                              </button>
                            </li>
                            <li>
                              <button
                                class="service_cat_name_manage_job"
                                style="pointer-events: none; border: 1px solid"
                                :style="{
                                  color: jobDataVal.service_category.color,
                                  borderColor: jobDataVal.service_category.color,
                                }"
                              >
                                {{ jobDataVal.service_category.name }}
                              </button>
                            </li>
                            <li v-if="jobDataVal.approval_status == 'pending'">
                              <button
                                class="demand_btn"
                                style="
                                  background-color: rgb(229, 138, 10) !important;
                                  pointer-events: none;
                                "
                              >
                                Pending Approval
                              </button>
                            </li>

                            <li v-if="jobDataVal.job_type_id == 2">
                              <button
                                class="demand_btn"
                                style="background: #28a745; pointer-events: none"
                              >
                                ON DEMAND JOB
                              </button>
                            </li>
                          </ul>
                          <div
                            class="toggle-action common_ul_radius toggle_margin_unset new-action-view-detail"
                          >
                            <!-- <b-dropdown class="mx-1" right text="Action" boundary="window"> -->
                            <b-dropdown class="mx-1" left text="Action" boundary="window">
                              <b-dropdown-item
                                @click="
                                  openTransactionModal(
                                    jobDataVal.id,
                                    jobDataVal.job_company.name,
                                    jobDataVal.job_unique_id,
                                    'completed'
                                  )
                                "
                                v-if="showPermissionEM('assign user')"
                                >Create Transaction
                              </b-dropdown-item>

                              <b-dropdown-item
                                @click="
                                  addNote(
                                    jobDataVal.id,
                                    jobDataVal.company_id,
                                    jobDataVal.assign_job
                                  )
                                "
                              >
                                Edit Jobs
                              </b-dropdown-item>
                              <b-dropdown-item
                                :to="{
                                  name: 'repostJobDaily',
                                  params: { id: encrypt(jobDataVal.id) },
                                }"
                                v-if="showPermissionEM('duplicate job')"
                                >Repost
                              </b-dropdown-item>

                              <b-dropdown-item @click="openPlaformFeeModal(jobDataVal)"
                                >Modify Plateform Fee
                              </b-dropdown-item>

                              <!-- <b-dropdown-item
                                v-on:click="
                                  showQR(
                                    jobDataVal.job_code,
                                    jobDataVal.job_unique_id,
                                    jobDataVal.id
                                  )
                                "
                                v-if="
                                  jobDataVal.job_company &&
                                  jobDataVal.job_company.company_admin.qr_based_arrival == 'yes' &&
                                  jobDataVal.start_date + ' ' + jobDataVal.start_time <= addDays
                                "
                                >Show QR Code
                              </b-dropdown-item> -->
                            </b-dropdown>
                          </div>
                          <div class="heading_with_status">
                            <div class="comp_heading_div">
                              <h2 class="top_color_h2_single">{{ jobDataVal.title }}</h2>
                            </div>
                            <!-- <div class="rate_div new_rate_divs"> -->
                            <!-- <div class="status_btn new_status_btn_divs"> -->
                            <!-- <h2 class="rate_text" v-if="showPermissionEM('show list rate') && showJobListRateAdmin('show_job_list_rate')">S${{jobDataVal.hourly_cost}}/HR</h2> -->
                            <!-- <b-link href="" :to="{name: 'repostJobDaily', params: {id: encrypt(jobDataVal.id)}}" v-if="showPermissionEM('duplicate job')" class="button gray ripple-effect ico repost_icon_btn" title="Repost" data-tippy-placement="top"><i class="material-icons-outlined assign_users_i">repeat_outlined</i></b-link> -->
                            <!-- <b-link href="" :to="{name: 'DailyJobEdit', params: {id: encrypt(jobDataVal.id)}}" class="button gray ripple-effect ico edit_icon_btn" title="Edit" data-tippy-placement="top"><i class="fa fa-edit"></i></b-link> -->

                            <!-- <div class="task-count-text" v-if="jobDataVal.pdf_url != null && showPermissionEM('show sr') && showJobListRateAdmin('show_job_list_rate')">
                                                                <div class="small_pdf_box">
                                                                    <img :src="webUrl+ 'assets/images/pdf.png'" style="max-height: 36px;" @click="pdfViewer(jobDataVal.pdf_url)" >
                                                                </div>
                                                            </div> -->
                            <!-- </div> -->
                            <!-- <div class="status_btn">
                                                            <b-link href="" :to="{name: 'DailyJobEdit', params: {id: encrypt(jobDataVal.id)}}" class="button gray ripple-effect ico edit_icon_btn" title="Edit" data-tippy-placement="top"><i class="fa fa-edit"></i></b-link>
                                                    </div> -->
                            <!-- <div class="rate_text" v-if="showPermissionEM('show list rate') && showJobListRateAdmin('show_job_list_rate')">

                                                        <h2>S${{jobDataVal.hourly_cost}}/HR</h2>
                                                    </div> -->
                            <!-- <div class="action_div">
                                                        <button class="action_btn">ACTIONS<i class="fa fa-caret-down"></i></button>
                                                    </div> -->
                            <!-- </div> -->
                          </div>
                          <!-- <div class="img_with_desc">

                                            </div> -->
                          <ul class="manage_job_top_ul_nw">
                            <li>
                              <span class="material-icons-outlined">calendar_month_outlined</span
                              >{{ jobDataVal.start_date | moment_1 }}
                            </li>
                            <li>
                              <span class="material-icons-outlined">access_time_outlined</span
                              >{{ jobDataVal.start_time | jobStartEndTime }} -
                              {{ jobDataVal.end_time | jobStartEndTime }}
                              <span class="red_note_manage_job"
                                ><p v-if="jobDataVal.lunch_pay_hours != null">
                                  <strong>Note:</strong> Break hour not paid -
                                  {{ jobDataVal.lunch_pay_hours }} Hr
                                </p></span
                              >
                            </li>
                            <li
                              v-if="
                                showPermissionEM('show list rate') &&
                                showJobListRateAdmin('show_job_list_rate')
                              "
                            >
                              <span class="material-icons-outlined">monetization_on_outlined</span
                              >${{ jobDataVal.hourly_cost }}/Hour
                            </li>
                          </ul>
                          <div class="user_apl">
                            <img
                              :src="webUrl + 'assets/images/pdf.png'"
                              v-if="
                                jobDataVal.pdf_url != null &&
                                showPermissionEM('show sr') &&
                                showJobListRateAdmin('show_job_list_rate')
                              "
                              style="max-height: 36px"
                              @click="pdfViewer(jobDataVal.pdf_url)"
                              class="pdf_manage_job"
                            />
                            <div class="person_div_manage_job">
                              <span class="material-icons person_logo">person</span>
                              <span class="total_vacany_list"
                                >{{ jobDataVal.selected.length }}/{{
                                  totalVacancy(jobDataVal.total_vacancies)
                                }}</span
                              >
                            </div>
                            <!-- <div class="working_completed_section">
                              <span>Working: {{ jobDataVal.inprogressCount.length }}</span
                              ><span>Completed: {{ jobDataVal.completedCount.length }}</span>
                            </div> -->
                          </div>
                          <div class="full_desc_emps">
                            <!-- <ul>
                                  <li>Posted By:<span>{{(jobDataVal.userData && jobDataVal.userData.last_name) ? jobDataVal.userData.last_name : ''}} {{(jobDataVal.userData && jobDataVal.userData.first_name) ? jobDataVal.userData.first_name : ''}}</span></li>
                                  <li>Job Posted D& Time:<span>{{jobDataVal.start_date | moment}} {{jobDataVal.start_time | jobStartEndTime}}</span></li>
                                  <li>Job Duration:<span>{{jobDataVal.start_date | moment}} {{jobDataVal.start_time | jobStartEndTime}} - {{jobDataVal.start_time | jobStartEndTime}}</span></li>
                                  <li>Job Hours:<span>{{jobDataVal.working_hours}}</span></li>
                                  <li>Vacancy:<span>{{jobDataVal.userJobs.length}}/{{jobDataVal.total_vacancies}}</span></li>
                                  <li v-if="jobDataVal.work_site_contact_person">Work Site Contact Person Name:<span>{{jobDataVal.work_site_contact_person ? jobDataVal.work_site_contact_person : ''}}</span></li>
                                  <li v-if="jobDataVal.work_site_contact_person_mobile_no">Work Site Contact Person Number:<span>{{jobDataVal.work_site_contact_person_country_code ? jobDataVal.work_site_contact_person_country_code : ''}}-{{jobDataVal.work_site_contact_person_mobile_no ? jobDataVal.work_site_contact_person_mobile_no : ''}}</span></li>
                              </ul> -->
                            <div class="single_accordian manage_job_accordian mb-3">
                              <!-- <br> -->
                              <div>
                                <div class="faq-content-box clearfix">
                                  <div>
                                    <div class="accordion-listing-box coloured_icon">
                                      <b-card no-body>
                                        <b-card-header header-tag="header" role="tab">
                                          <b-button
                                            v-b-toggle="
                                              'accordion_view_job_details_completed-' + jobDatakey
                                            "
                                            class="m-1"
                                            variant="info"
                                          >
                                            <div class="dashboard-box">
                                              <div class="headline">
                                                <h3 class="heading_section_h3">VIEW JOB DETAILS</h3>
                                              </div>
                                            </div>
                                          </b-button>
                                        </b-card-header>
                                        <b-collapse
                                          :id="'accordion_view_job_details_completed-' + jobDatakey"
                                          role="tabpanel"
                                        >
                                          <b-card-body>
                                            <div class="">
                                              <div class="row">
                                                <div class="col-xl-12">
                                                  <div class="comp_heading_div_manage_job">
                                                    <div class="dtl_text">
                                                      <h2>Posted By:</h2>
                                                      <p class="mb-0">
                                                        {{
                                                          jobDataVal.last_name
                                                            ? jobDataVal.last_name
                                                            : ""
                                                        }}
                                                        {{
                                                          jobDataVal.first_name
                                                            ? jobDataVal.first_name
                                                            : ""
                                                        }}
                                                      </p>
                                                      <p class="mb-0">
                                                        {{
                                                          jobDataVal.created_at
                                                            | customFormatterOnecreated
                                                        }}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div
                                                    class="table-responsive whole_custom_tbl common_table_scroll manage_job_new_tblz"
                                                  >
                                                    <table
                                                      class="table custom_manage_job_tbl unset_last_super"
                                                    >
                                                      <thead>
                                                        <tr>
                                                          <!-- <th v-if="jobDataVal.userData">Posted By:</th> -->
                                                          <!-- <th>Job Posted Date & Time:</th> -->
                                                          <!-- <th>Job Date & Time:</th>
                                                                                          <th>Job Hours:</th>
                                                                                          <th>Vacancy:</th> -->
                                                          <th
                                                            v-if="
                                                              jobDataVal.work_site_contact_person ||
                                                              jobDataVal.work_site_contact_person_mobile_no
                                                            "
                                                          >
                                                            Work Site Contact Person
                                                          </th>
                                                          <th></th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        <tr>
                                                          <!-- <td v-if="jobDataVal.userData">
                                                                                              {{(jobDataVal.userData && jobDataVal.userData.last_name) ? jobDataVal.userData.last_name : ''}} {{(jobDataVal.userData && jobDataVal.userData.first_name) ? jobDataVal.userData.first_name : ''}}
                                                                                          </td> -->
                                                          <!-- <td>
                                                                                              {{jobDataVal.created_at | customFormatterOnecreated}}
                                                                                          </td> -->
                                                          <!-- <td>{{jobDataVal.start_date | moment_1}} {{jobDataVal.start_time | jobStartEndTime}} - {{jobDataVal.end_time | jobStartEndTime}} </td>
                                                                                          <td>{{jobDataVal.working_hours}}</td>
                                                                                          <td>
                                                                                              {{jobDataVal.userJobsselected.length}}/{{jobDataVal.total_vacancies}}
                                                                                          </td> -->
                                                          <td
                                                            v-if="
                                                              jobDataVal.work_site_contact_person ||
                                                              jobDataVal.work_site_contact_person_mobile_no
                                                            "
                                                          >
                                                            {{
                                                              jobDataVal.work_site_contact_person
                                                                ? jobDataVal.work_site_contact_person
                                                                : ""
                                                            }}
                                                            <br />
                                                            {{
                                                              jobDataVal.work_site_contact_person_country_code
                                                                ? jobDataVal.work_site_contact_person_country_code +
                                                                  "-"
                                                                : ""
                                                            }}{{
                                                              jobDataVal.work_site_contact_person_mobile_no
                                                                ? jobDataVal.work_site_contact_person_mobile_no
                                                                : ""
                                                            }}<br />
                                                          </td>
                                                          <td
                                                            v-if="
                                                              jobDataVal.work_site_contact_person ||
                                                              jobDataVal.work_site_contact_person_mobile_no
                                                            "
                                                          >
                                                            {{
                                                              jobDataVal.work_site_contact_person_2
                                                                ? jobDataVal.work_site_contact_person_2
                                                                : ""
                                                            }}<br />
                                                            {{
                                                              jobDataVal.work_site_contact_person_2_mobile_no
                                                                ? jobDataVal.work_site_contact_person_2_country_code +
                                                                  "-"
                                                                : ""
                                                            }}{{
                                                              jobDataVal.work_site_contact_person_2_mobile_no
                                                                ? jobDataVal.work_site_contact_person_2_mobile_no
                                                                : ""
                                                            }}
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                  <div class="dtl_text">
                                                    <div
                                                      class="large-content-requirement-completed"
                                                    >
                                                      <h2 v-if="jobDataVal.description">
                                                        Job Requirements
                                                        <span class="manage_job_eye_span"
                                                          ><i
                                                            class="fa fa-eye-slash"
                                                            :id="
                                                              'manage_job_requirement_completed_eye_icon' +
                                                              jobDatakey
                                                            "
                                                            @click="
                                                              ManageJobRequirementCompletedEyeReveal(
                                                                jobDatakey
                                                              )
                                                            "
                                                            style="cursor: pointer"
                                                          ></i
                                                        ></span>
                                                      </h2>
                                                      <p
                                                        :id="
                                                          'visible-content-requirement-completed' +
                                                          jobDatakey
                                                        "
                                                        style=""
                                                        class=""
                                                        v-if="jobDataVal.description.length <= 249"
                                                      >
                                                        {{
                                                          jobDataVal.description.substring(0, 249)
                                                        }}
                                                      </p>
                                                      <p
                                                        :id="
                                                          'visible-content-requirement-completed' +
                                                          jobDatakey
                                                        "
                                                        style=""
                                                        class=""
                                                        v-if="jobDataVal.description.length >= 250"
                                                      >
                                                        {{
                                                          jobDataVal.description.substring(0, 250) +
                                                          ".."
                                                        }}
                                                      </p>
                                                      <p
                                                        :id="
                                                          'invisible-content-requirement-completed' +
                                                          jobDatakey
                                                        "
                                                        style="display: none"
                                                        class=""
                                                      >
                                                        {{ jobDataVal.description }}
                                                      </p>
                                                    </div>
                                                    <div class="large-content-scope-completed">
                                                      <h2 v-if="jobDataVal.job_scope">
                                                        Job Scope:
                                                        <span class="manage_job_eye_span"
                                                          ><i
                                                            class="fa fa-eye-slash"
                                                            :id="
                                                              'manage_job_scope_completed_eye_icon' +
                                                              jobDatakey
                                                            "
                                                            @click="
                                                              ManageJobScopeCompletedEyeReveal(
                                                                jobDatakey
                                                              )
                                                            "
                                                            style="cursor: pointer"
                                                          ></i
                                                        ></span>
                                                      </h2>
                                                      <p
                                                        :id="
                                                          'visible-content-scope-completed' +
                                                          jobDatakey
                                                        "
                                                        style=""
                                                        class=""
                                                        v-if="jobDataVal.job_scope.length <= 249"
                                                      >
                                                        {{ jobDataVal.job_scope.substring(0, 249) }}
                                                      </p>
                                                      <p
                                                        :id="
                                                          'visible-content-scope-completed' +
                                                          jobDatakey
                                                        "
                                                        style=""
                                                        class=""
                                                        v-if="jobDataVal.job_scope.length >= 250"
                                                      >
                                                        {{
                                                          jobDataVal.job_scope.substring(0, 250) +
                                                          ".."
                                                        }}
                                                      </p>
                                                      <p
                                                        :id="
                                                          'invisible-content-scope-completed' +
                                                          jobDatakey
                                                        "
                                                        style="display: none"
                                                        class=""
                                                      >
                                                        {{ jobDataVal.job_scope }}
                                                      </p>
                                                    </div>
                                                    <h2 v-if="jobDataVal.address">Job Location</h2>
                                                    <p v-if="jobDataVal.address" class="mb-0">
                                                      {{ jobDataVal.address }}
                                                    </p>
                                                    <!-- <p v-if="jobDataVal.address"><i class="fa fa-map-marker"></i>{{jobDataVal.address}}</p> -->
                                                    <!-- <p v-if="jobDataVal.lunch_pay_hours != null"><strong>Note:</strong>Break hour not paid - {{jobDataVal.lunch_pay_hours}} Hr</p> -->
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </b-card-body>
                                        </b-collapse>
                                      </b-card>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="single_accordian manage_job_accordian">
                              <!-- <br> -->
                              <div>
                                <div class="faq-content-box clearfix">
                                  <div>
                                    <div class="accordion-listing-box coloured_icon">
                                      <b-card no-body>
                                        <b-card-header header-tag="header" role="tab">
                                          <b-button
                                            v-b-toggle="
                                              'accordion_view_candidates_completed-' + jobDatakey
                                            "
                                            class="m-1"
                                            variant="info"
                                            @click="
                                              getList(
                                                jobDataVal.id,
                                                jobDatakey,
                                                'completed',
                                                user_id
                                              )
                                            "
                                          >
                                            <div class="dashboard-box">
                                              <div class="headline">
                                                <h3 class="heading_section_h3">View candidates</h3>
                                              </div>
                                            </div>
                                          </b-button>
                                        </b-card-header>
                                        <b-collapse
                                          :id="'accordion_view_candidates_completed-' + jobDatakey"
                                          role="tabpanel"
                                        >
                                          <b-card-body>
                                            <div class="">
                                              <div class="row">
                                                <div class="col-xl-12">
                                                  <b-tabs
                                                    class="custom_tabs_daily_manage_jobs custom_tabs_daily_manage_jobs_completed"
                                                  >
                                                    <div>
                                                      <b-tab
                                                        :title="
                                                          'SELECTED (' +
                                                          jobDataVal.selected.length +
                                                          ')'
                                                        "
                                                        class="reject_last_li"
                                                        @click="
                                                          changeApplicantTabStatus(
                                                            'completed',
                                                            'selected',
                                                            jobDataVal.id,
                                                            jobDatakey,
                                                            jobDataVal.posted_by
                                                          )
                                                        "
                                                      >
                                                        <div v-if="jobDataVal.selected.length > 0">
                                                          <b-table
                                                            ref="datatable"
                                                            show-empty
                                                            striped
                                                            hover
                                                            responsive
                                                            :items="jobDataVal.selected"
                                                            :fields="fields"
                                                            :filter="filter"
                                                            :sort-by.sync="sortBy"
                                                            :sort-desc.sync="sortDesc"
                                                            :sort-direction="sortDirection"
                                                            class="tbl_height new_box_tbl_mrgn daily_manage_job_dipper"
                                                          >
                                                            <template #cell(name)="items">
                                                              <div class="daily_image_job">
                                                                <div
                                                                  class="daily_job_manage_img_div"
                                                                >
                                                                  <b-img
                                                                    :src="
                                                                      items.item.profile_image !=
                                                                      null
                                                                        ? items.item.profile_image
                                                                        : webUrl +
                                                                          'assets/images/user-avatar-small-01.png'
                                                                    "
                                                                    alt="User Icon"
                                                                    height="100px"
                                                                    width="100px"
                                                                    class="user_img_emp"
                                                                  ></b-img>
                                                                  <div
                                                                    class="custom_star_rate_manage_job"
                                                                  >
                                                                    <span
                                                                      ><i class="fa fa-star"></i
                                                                      >{{
                                                                        items.item.average_rating
                                                                          ? items.item
                                                                              .average_rating
                                                                          : 0
                                                                      }}</span
                                                                    >
                                                                  </div>
                                                                </div>
                                                                <div
                                                                  class="daily_job_manage_user_description_div"
                                                                >
                                                                  <b-link
                                                                    v-if="
                                                                      showPermissionEM(
                                                                        'view profile'
                                                                      )
                                                                    "
                                                                    :to="{
                                                                      name: 'employer-jobseeker-dp-profile-view',
                                                                      params: {
                                                                        id: encrypt(
                                                                          items.item.user_id
                                                                        ),
                                                                      },
                                                                    }"
                                                                    target="_blank"
                                                                    ><h2>
                                                                      {{
                                                                        items.item.last_name
                                                                          ? items.item.last_name
                                                                          : ""
                                                                      }}
                                                                      {{
                                                                        items.item.first_name
                                                                          ? items.item.first_name
                                                                          : ""
                                                                      }}
                                                                    </h2></b-link
                                                                  >
                                                                  <h2 v-else>
                                                                    {{
                                                                      items.item.last_name
                                                                        ? items.item.last_name
                                                                        : ""
                                                                    }}
                                                                    {{
                                                                      items.item.first_name
                                                                        ? items.item.first_name
                                                                        : ""
                                                                    }}
                                                                  </h2>
                                                                  <div class="phone_nmber_div">
                                                                    <a href="#"
                                                                      >{{
                                                                        items.item.country_code
                                                                          ? items.item.country_code
                                                                          : ""
                                                                      }}-{{
                                                                        items.item.mobile_number
                                                                          ? items.item.mobile_number
                                                                          : ""
                                                                      }}</a
                                                                    >
                                                                  </div>
                                                                  <div class="star-rating">
                                                                    <!-- <star-rating v-model="items.item.userdetail.average_rating" v-bind:star-size="20" :show-rating="false"></star-rating> -->
                                                                    <!-- <span class="star"></span>
                                                                                            <span class="star"></span>
                                                                                            <span class="star"></span>
                                                                                            <span class="star"></span>
                                                                                            <span class="star"></span> -->
                                                                  </div>
                                                                  <div
                                                                    v-if="
                                                                      items.item.user_badges != null
                                                                    "
                                                                  >
                                                                    <b-img
                                                                      class="worked_for_logo"
                                                                      v-for="(usb, us) in items.item
                                                                        .user_badges"
                                                                      :key="us"
                                                                      :src="usb.badges.badge_image"
                                                                      :title="usb.badges.name"
                                                                      alt="User Icon"
                                                                    ></b-img>
                                                                  </div>
                                                                  <div>
                                                                    <p
                                                                      v-if="
                                                                        jobDataVal.proposed_arrival_time !=
                                                                          null &&
                                                                        jobDataVal.job_type_id == 2
                                                                      "
                                                                    >
                                                                      ETA:
                                                                      {{
                                                                        jobDataVal.proposed_arrival_time !=
                                                                        null
                                                                          ? jobDataVal.proposed_arrival_time
                                                                          : "" | moment
                                                                      }}
                                                                      |
                                                                      {{
                                                                        jobDataVal.proposed_arrival_time !=
                                                                        null
                                                                          ? jobDataVal.proposed_arrival_time
                                                                          : ""
                                                                            | applicantOriginalSET
                                                                      }}
                                                                    </p>
                                                                    <!-- <p v-else>ETA: NA</p> -->
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </template>

                                                            <!-- <template #cell(mobile_number)="items">
                                                                            <div class="phone_nmber_div">
                                                                                <a href="#">{{items.item.userdetail.country_code ? items.item.userdetail.country_code : ''}}-{{items.item.userdetail.mobile_number ? items.item.userdetail.mobile_number : ''}}</a>
                                                                            </div>
                                                                            </template> -->
                                                            <template #cell(time)="items">
                                                              <div class="timing_div arrow_time">
                                                                <ul>
                                                                  <li>
                                                                    <i
                                                                      class="fa fa-arrow-down green_arrow"
                                                                    ></i
                                                                    ><span>{{
                                                                      items.item.start_time
                                                                        ? items.item.start_time
                                                                        : "" | applicantStartEndTime
                                                                    }}</span
                                                                    ><i
                                                                      class="fa fa-arrow-up red_arrow green_arrow"
                                                                    ></i
                                                                    ><span>{{
                                                                      items.item.end_time
                                                                        ? items.item.end_time
                                                                        : "" | applicantStartEndTime
                                                                    }}</span>
                                                                  </li>
                                                                </ul>
                                                              </div>
                                                            </template>
                                                            <template #cell(thumb)="items">
                                                              <div class="thumb_div">
                                                                <li
                                                                  v-if="
                                                                    items.item.acknowledged ==
                                                                      'yes' &&
                                                                    items.item.job_status !=
                                                                      'suspended'
                                                                  "
                                                                >
                                                                  <b>ACNOWLEDGED</b>
                                                                </li>
                                                              </div>
                                                            </template>
                                                            <template
                                                              #cell(ac_button)="items"
                                                              v-if="
                                                                showPermissionEM('view job action')
                                                              "
                                                            >
                                                              <div class="status_btn">
                                                                <b-button
                                                                  class="ack_btn tag_ack_btn"
                                                                  v-if="
                                                                    items.item.job_status ==
                                                                      'completed' &&
                                                                    items.item
                                                                      .rated_by_supervisor == 'yes'
                                                                  "
                                                                  >Rated</b-button
                                                                >
                                                                <b-button
                                                                  class="prg_btn"
                                                                  v-if="
                                                                    items.item.job_status ==
                                                                      'completed' &&
                                                                    items.item
                                                                      .rated_by_supervisor != 'yes'
                                                                  "
                                                                  @click="
                                                                    ratePtUser(
                                                                      items.item.user_id,
                                                                      items.item.job_id,
                                                                      jobDataKey
                                                                    )
                                                                  "
                                                                  >Rate</b-button
                                                                >
                                                                <b-button
                                                                  class="red-bg comp_tbn tag_ack_btn"
                                                                  v-if="
                                                                    items.item.job_status ==
                                                                    'suspended'
                                                                  "
                                                                  style="background-color: red"
                                                                  >Stopped</b-button
                                                                >
                                                                <!-- <b-button class="comp_tbn" v-if="items.item.timesheet_url != null && charge_type != 'fixed_rate'"  @click="pdfViewer(items.item.timesheet_url)" >Timesheet</b-button> -->
                                                              </div>
                                                            </template>
                                                            <template
                                                              #cell(toggleAction)="items"
                                                              v-if="
                                                                showPermissionEM('view job action')
                                                              "
                                                            >
                                                              <div
                                                                class="toggle-action custom_toggle_three_dot dp_job_action"
                                                              >
                                                                <b-dropdown
                                                                  class="mx-1"
                                                                  right
                                                                  text=""
                                                                  boundary="window"
                                                                >
                                                                  <b-dropdown-item
                                                                    v-if="
                                                                      items.item.user_job_remarks !=
                                                                        null &&
                                                                      show('update remarks')
                                                                    "
                                                                    @click="
                                                                      openRemarksModel(
                                                                        items.item.user_id,
                                                                        'update',
                                                                        items.item.remarks,
                                                                        items.item.job_id
                                                                      )
                                                                    "
                                                                    >UPDATE REMARKS
                                                                  </b-dropdown-item>
                                                                  <b-dropdown-item
                                                                    v-else-if="
                                                                      items.item.user_job_remarks ==
                                                                        null && show('add remarks')
                                                                    "
                                                                    @click="
                                                                      openRemarksModel(
                                                                        items.item.user_id,
                                                                        'add',
                                                                        items.item.remarks,
                                                                        items.item.job_id
                                                                      )
                                                                    "
                                                                    >ADD REMARKS
                                                                  </b-dropdown-item>
                                                                  <b-dropdown-item
                                                                    v-if="show('more info')"
                                                                    @click="
                                                                      viewMoreInfo(
                                                                        items.item.user_id,
                                                                        items.item.job_id
                                                                      )
                                                                    "
                                                                    >MORE INFO
                                                                  </b-dropdown-item>
                                                                  <b-dropdown-item
                                                                    v-if="
                                                                      items.item.job_status ==
                                                                        'upcoming' &&
                                                                      items.item.acknowledged ==
                                                                        'no' &&
                                                                      show('acknowledge fl')
                                                                    "
                                                                    @click="
                                                                      acknowledgeApplicant(
                                                                        items.item.id
                                                                      )
                                                                    "
                                                                    >ACKNOWLEDGE
                                                                  </b-dropdown-item>
                                                                  <b-dropdown-item
                                                                    @click="
                                                                      openArrivalModel(
                                                                        items.item.user_id,
                                                                        'arrival',
                                                                        false,
                                                                        items.item.job_id
                                                                      )
                                                                    "
                                                                    v-if="
                                                                      items.item
                                                                        .original_start_time !=
                                                                        null &&
                                                                      items.item.job_status ==
                                                                        'in-progress' &&
                                                                      show('adjust arrival time')
                                                                    "
                                                                    >ADJUST ARRIVAL TIME
                                                                  </b-dropdown-item>
                                                                  <b-dropdown-item
                                                                    v-if="
                                                                      items.item.marked_by !=
                                                                        null &&
                                                                      (items.item.job_status !=
                                                                        'suspended' ||
                                                                        items.item.job_status !=
                                                                          'completed') &&
                                                                      items.item
                                                                        .lunch_pay_not_provided ==
                                                                        'yes' &&
                                                                      show('add lunch break')
                                                                    "
                                                                    @click="
                                                                      openModel(
                                                                        items.item.user_id,
                                                                        'add_break',
                                                                        items.item.break_hour_taken,
                                                                        items.item.job_id
                                                                      )
                                                                    "
                                                                    >ADD/REMOVE BREAK
                                                                  </b-dropdown-item>
                                                                  <b-dropdown-item
                                                                    @click="
                                                                      revertCompletion(
                                                                        items.item.user_id,
                                                                        'arrival',
                                                                        items.item.job_id
                                                                      )
                                                                    "
                                                                    v-if="
                                                                      (items.item.job_status ==
                                                                        'completed' ||
                                                                        items.item.job_status ==
                                                                          'suspended') &&
                                                                      show('revert completion')
                                                                    "
                                                                    >REVERT COMPLETION
                                                                  </b-dropdown-item>
                                                                  <b-dropdown-item
                                                                    v-if="
                                                                      items.item.job_status !=
                                                                        'cancelled' &&
                                                                      items.item
                                                                        .confirm_job_completion_by ==
                                                                        null &&
                                                                      items.item.job_status !=
                                                                        'suspended' &&
                                                                      show('cancel fl')
                                                                    "
                                                                    @click="
                                                                      cancelApplicant(
                                                                        items.item.user_id
                                                                      )
                                                                    "
                                                                    >CANCEL
                                                                  </b-dropdown-item>
                                                                  <b-dropdown-item
                                                                    v-if="
                                                                      items.item.marked_by !=
                                                                        null &&
                                                                      items.item.job_status !=
                                                                        'suspended' &&
                                                                      items.item
                                                                        .original_end_time ==
                                                                        null &&
                                                                      items.item.extended_hours ==
                                                                        null &&
                                                                      items.item
                                                                        .rated_by_supervisor ==
                                                                        null &&
                                                                      show('extend hour')
                                                                    "
                                                                    @click="
                                                                      extendHour(
                                                                        items.item.user_id,
                                                                        items.item.hourly_cost,
                                                                        'extension',
                                                                        items.item.extended_hours,
                                                                        items.item.extension_rate,
                                                                        items.item.extra_break_hrs,
                                                                        items.item.extra_break_taken
                                                                      )
                                                                    "
                                                                    >EXTEND HOUR
                                                                  </b-dropdown-item>
                                                                  <b-dropdown-item
                                                                    v-if="
                                                                      items.item.marked_by !=
                                                                        null &&
                                                                      items.item.job_status !=
                                                                        'suspended' &&
                                                                      items.item
                                                                        .original_end_time ==
                                                                        null &&
                                                                      items.item.extended_hours !=
                                                                        null &&
                                                                      items.item
                                                                        .rated_by_supervisor ==
                                                                        null &&
                                                                      show('adjust extension hours')
                                                                    "
                                                                    @click="
                                                                      extendHour(
                                                                        items.item.user_id,
                                                                        items.item.hourly_cost,
                                                                        'adjust extension',
                                                                        items.item.extended_hours,
                                                                        items.item.extension_rate,
                                                                        items.item.extra_break_hrs,
                                                                        items.item.extra_break_taken
                                                                      )
                                                                    "
                                                                    >ADJUST EXTENSION HOUR
                                                                  </b-dropdown-item>
                                                                  <b-dropdown-item
                                                                    v-if="
                                                                      items.item.extended_hours !=
                                                                        null &&
                                                                      items.item.job_status !=
                                                                        'suspended' &&
                                                                      items.item
                                                                        .original_end_time ==
                                                                        null &&
                                                                      show('remove extension')
                                                                    "
                                                                    @click="
                                                                      removeExtension(
                                                                        items.item.user_id,
                                                                        items.item.job_id
                                                                      )
                                                                    "
                                                                    >REMOVE EXTENSION
                                                                  </b-dropdown-item>
                                                                  <b-dropdown-item
                                                                    v-if="
                                                                      items.item
                                                                        .original_start_time !=
                                                                        null &&
                                                                      items.item.extended_hours ==
                                                                        null &&
                                                                      items.item.job_status ==
                                                                        'in-progress' &&
                                                                      show('stop work')
                                                                    "
                                                                    @click="
                                                                      openModel(
                                                                        items.item.user_id,
                                                                        'stop',
                                                                        items.item.start_time,
                                                                        items.item.job_id
                                                                      )
                                                                    "
                                                                    >STOP WORK
                                                                  </b-dropdown-item>
                                                                  <b-dropdown-item
                                                                    v-if="
                                                                      (items.item.job_status ==
                                                                        'completed' ||
                                                                        items.item.job_status ==
                                                                          'suspended') &&
                                                                      show('modify fl time')
                                                                    "
                                                                    @click="
                                                                      openModel(
                                                                        items.item.user_id,
                                                                        'modify',
                                                                        '',
                                                                        items.item.job_id
                                                                      )
                                                                    "
                                                                    >MODIFY USER TIME
                                                                  </b-dropdown-item>
                                                                  <b-dropdown-item
                                                                    v-if="
                                                                      items.item.job_status ==
                                                                        'suspended' &&
                                                                      show('view suspension reason')
                                                                    "
                                                                    @click="
                                                                      viewSuspensionReason(
                                                                        items.item.user_id,
                                                                        items.item.suspention_reason
                                                                      )
                                                                    "
                                                                    >MODIFY SUSPENSION REASON
                                                                  </b-dropdown-item>
                                                                  <b-dropdown-item
                                                                    v-if="show('generate ts')"
                                                                    @click="
                                                                      openModel(
                                                                        items.item.user_id,
                                                                        'generate_ts',
                                                                        '',
                                                                        items.item.job_id
                                                                      )
                                                                    "
                                                                    >REGENERATE TS
                                                                  </b-dropdown-item>
                                                                </b-dropdown>
                                                              </div>
                                                              <div
                                                                class="toggle-action custom_toggle_three_dot dp_job_action"
                                                                v-if="
                                                                  items.item.job_status ==
                                                                  'suspended'
                                                                "
                                                              >
                                                                <b-dropdown
                                                                  class="mx-1"
                                                                  right
                                                                  text=""
                                                                  boundary="window"
                                                                >
                                                                  <b-dropdown-item
                                                                    v-if="
                                                                      items.item.job_status ==
                                                                      'suspended'
                                                                    "
                                                                    @click="
                                                                      viewSuspensionReason(
                                                                        items.item.suspention_reason
                                                                      )
                                                                    "
                                                                    >View Suspension Reason
                                                                  </b-dropdown-item>
                                                                </b-dropdown>
                                                              </div>
                                                            </template>
                                                          </b-table>
                                                        </div>

                                                        <div v-else>
                                                          <div
                                                            class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                                                          >
                                                            <p class="actionData">
                                                              No Record Found
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </b-tab>
                                                    </div>
                                                    <div v-if="jobDataVal.cancelled">
                                                      <b-tab
                                                        :title="
                                                          'CANCELLED (' +
                                                          jobDataVal.cancelled.length +
                                                          ')'
                                                        "
                                                        class="reject_last_li"
                                                        @click="
                                                          changeApplicantTabStatus(
                                                            'completed',
                                                            'cancelled',
                                                            jobDataVal.id,
                                                            jobDatakey,
                                                            jobDataVal.posted_by
                                                          )
                                                        "
                                                      >
                                                        <div
                                                          v-if="
                                                            jobDataVal.cancelled &&
                                                            jobDataVal.cancelled.length > 0
                                                          "
                                                        >
                                                          <b-table
                                                            ref="datatable"
                                                            show-empty
                                                            striped
                                                            hover
                                                            responsive
                                                            :items="jobDataVal.cancelled"
                                                            :fields="fields"
                                                            :filter="filter"
                                                            :sort-by.sync="sortBy"
                                                            :sort-desc.sync="sortDesc"
                                                            :sort-direction="sortDirection"
                                                            class="tbl_height new_box_tbl_mrgn daily_manage_job_dipper cancelled_div_tb"
                                                          >
                                                            <template #cell(name)="items">
                                                              <div class="daily_image_job">
                                                                <div
                                                                  class="daily_job_manage_img_div"
                                                                >
                                                                  <b-img
                                                                    :src="
                                                                      items.item.profile_image !=
                                                                      null
                                                                        ? items.item.profile_image
                                                                        : webUrl +
                                                                          'assets/images/user-avatar-small-01.png'
                                                                    "
                                                                    alt="User Icon"
                                                                    height="100px"
                                                                    width="100px"
                                                                    class="user_img_emp"
                                                                  ></b-img>
                                                                  <div
                                                                    class="custom_star_rate_manage_job"
                                                                  >
                                                                    <span
                                                                      ><i class="fa fa-star"></i
                                                                      >{{
                                                                        items.item.average_rating
                                                                          ? items.item
                                                                              .average_rating
                                                                          : 0
                                                                      }}</span
                                                                    >
                                                                  </div>
                                                                </div>
                                                                <div
                                                                  class="daily_job_manage_user_description_div"
                                                                >
                                                                  <b-link
                                                                    v-if="
                                                                      showPermissionEM(
                                                                        'view profile'
                                                                      )
                                                                    "
                                                                    :to="{
                                                                      name: 'employer-jobseeker-dp-profile-view',
                                                                      params: {
                                                                        id: encrypt(
                                                                          items.item.user_id
                                                                        ),
                                                                      },
                                                                    }"
                                                                    target="_blank"
                                                                    ><h2>
                                                                      {{
                                                                        items.item.last_name
                                                                          ? items.item.last_name
                                                                          : ""
                                                                      }}
                                                                      {{
                                                                        items.item.first_name
                                                                          ? items.item.first_name
                                                                          : ""
                                                                      }}
                                                                    </h2></b-link
                                                                  >
                                                                  <h2 v-else>
                                                                    {{
                                                                      items.item.last_name
                                                                        ? items.item.last_name
                                                                        : ""
                                                                    }}
                                                                    {{
                                                                      items.item.first_name
                                                                        ? items.item.first_name
                                                                        : ""
                                                                    }}
                                                                  </h2>
                                                                  <div class="phone_nmber_div">
                                                                    <a href="#"
                                                                      >{{
                                                                        items.item.country_code
                                                                          ? items.item.country_code
                                                                          : ""
                                                                      }}-{{
                                                                        items.item.mobile_number
                                                                          ? items.item.mobile_number
                                                                          : ""
                                                                      }}</a
                                                                    >
                                                                  </div>
                                                                  <div class="star-rating">
                                                                    <!-- <star-rating v-model="items.item.userdetail.average_rating" v-bind:star-size="20" :show-rating="false"></star-rating> -->
                                                                  </div>
                                                                  <div
                                                                    v-if="
                                                                      items.item.user_badges != null
                                                                    "
                                                                  >
                                                                    <b-img
                                                                      class="worked_for_logo"
                                                                      v-for="(usb, us) in items.item
                                                                        .user_badges"
                                                                      :key="us"
                                                                      :src="usb.badges.badge_image"
                                                                      :title="usb.badges.name"
                                                                      alt="User Icon"
                                                                    ></b-img>
                                                                  </div>
                                                                  <div>
                                                                    <p
                                                                      v-if="
                                                                        jobDataVal.proposed_arrival_time !=
                                                                          null &&
                                                                        jobDataVal.job_type_id == 2
                                                                      "
                                                                    >
                                                                      ETA:
                                                                      {{
                                                                        jobDataVal.proposed_arrival_time !=
                                                                        null
                                                                          ? jobDataVal.proposed_arrival_time
                                                                          : "" | moment
                                                                      }}
                                                                      |
                                                                      {{
                                                                        jobDataVal.proposed_arrival_time !=
                                                                        null
                                                                          ? jobDataVal.proposed_arrival_time
                                                                          : ""
                                                                            | applicantOriginalSET
                                                                      }}
                                                                    </p>
                                                                    <!-- <p v-else>ETA: NA</p> -->
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </template>

                                                            <!-- <template #cell(mobile_number)="items">
                                                                            <div class="phone_nmber_div">
                                                                                <a href="#">{{items.item.userdetail.country_code ? items.item.userdetail.country_code : ''}}-{{items.item.userdetail.mobile_number ? items.item.userdetail.mobile_number : ''}}</a>
                                                                            </div>
                                                                            </template> -->
                                                            <template #cell(time)="items">
                                                              <div class="timing_div">
                                                                <ul>
                                                                  <li>
                                                                    <span
                                                                      ><b
                                                                        >Cancelled Reason:</b
                                                                      ></span
                                                                    >
                                                                    <span>{{
                                                                      items.item.cancellation_reason
                                                                        ? items.item
                                                                            .cancellation_reason
                                                                        : ""
                                                                    }}</span>
                                                                  </li>
                                                                  <br />
                                                                  <li>
                                                                    <span
                                                                      ><b>Cancelled At:</b></span
                                                                    >
                                                                    <span>{{
                                                                      items.item.cancelled_at
                                                                        ? items.item.cancelled_at
                                                                        : "" | customFormatterOne
                                                                    }}</span>
                                                                  </li>
                                                                </ul>
                                                              </div>
                                                            </template>
                                                            <!-- <template #cell(thumb)="items">
                                                                  <div class="thumb_div">
                                                                    <i class="fa fa-thumbs-up blue_thumb"></i>
                                                                  </div>
                                                                </template> -->
                                                            <template #cell(ac_button)="items">
                                                              <div class="status_btn">
                                                                <li
                                                                  v-if="
                                                                    items.item.acknowledged ==
                                                                      'yes' &&
                                                                    items.item.job_status !=
                                                                      'suspended'
                                                                  "
                                                                >
                                                                  <b>ACNOWLEDGED</b>
                                                                </li>
                                                                <button class="comp_tbn">
                                                                  AWARDED
                                                                </button>
                                                              </div>
                                                            </template>
                                                            <template #cell(toggleAction)="items">
                                                              <div
                                                                class="toggle-action custom_toggle_three_dot dp_job_action"
                                                              >
                                                                <b-dropdown
                                                                  class="mx-1"
                                                                  right
                                                                  text=""
                                                                  boundary="window"
                                                                >
                                                                  <b-dropdown-item
                                                                    v-if="show('more info')"
                                                                    @click="
                                                                      viewMoreInfo(
                                                                        items.item.user_id,
                                                                        items.item.job_id
                                                                      )
                                                                    "
                                                                    >MORE INFO
                                                                  </b-dropdown-item>

                                                                  <b-dropdown-item
                                                                    v-if="
                                                                      items.item
                                                                        .withdraw_application ==
                                                                      'yes'
                                                                    "
                                                                    @click="
                                                                      addBack(
                                                                        items.item.user_id,
                                                                        items.item.job_id
                                                                      )
                                                                    "
                                                                    >ADD BACK
                                                                  </b-dropdown-item>
                                                                </b-dropdown>
                                                              </div>
                                                            </template>
                                                          </b-table>
                                                        </div>
                                                        <div v-else>
                                                          <div
                                                            class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                                                          >
                                                            <p class="actionData">
                                                              No Record Found
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </b-tab>
                                                    </div>
                                                    <b-tab
                                                      :title="
                                                        'APPLICANT WIDTHDRAWN (' +
                                                        jobDataVal.total_withdrawn_by_self.length +
                                                        ')'
                                                      "
                                                      class="reject_last_li mobile_min_width_1000"
                                                      @click="
                                                        getList(
                                                          jobDataVal.id,
                                                          jobDatakey,
                                                          'in-progress',
                                                          user_id
                                                        )
                                                      "
                                                    >
                                                      <div
                                                        v-if="
                                                          jobDataVal.total_withdrawn_by_self
                                                            .length > 0
                                                        "
                                                      >
                                                        <b-table
                                                          ref="datatable"
                                                          show-empty
                                                          striped
                                                          hover
                                                          responsive
                                                          :items="
                                                            jobDataVal.total_withdrawn_by_self
                                                          "
                                                          :fields="fields"
                                                          :filter="filter"
                                                          :sort-by.sync="sortBy"
                                                          :sort-desc.sync="sortDesc"
                                                          :sort-direction="sortDirection"
                                                          class="tbl_height new_box_tbl_mrgn daily_manage_job_dipper"
                                                        >
                                                          <template #cell(name)="items">
                                                            <div class="daily_image_job">
                                                              <div class="daily_job_manage_img_div">
                                                                <b-img
                                                                  :src="
                                                                    items.item.thumbnail != null
                                                                      ? items.item.thumbnail
                                                                      : webUrl +
                                                                        'assets/images/user-avatar-small-01.png'
                                                                  "
                                                                  alt="User Icon"
                                                                  height="100px"
                                                                  width="100px"
                                                                  class="user_img_emp"
                                                                ></b-img>
                                                              </div>
                                                              <div
                                                                class="daily_job_manage_user_description_div"
                                                              >
                                                                <b-link
                                                                  :to="{
                                                                    name: 'employer-jobseeker-dp-profile-view',
                                                                    params: {
                                                                      id: encrypt(
                                                                        items.item.user_id
                                                                      ),
                                                                    },
                                                                  }"
                                                                  target="_blank"
                                                                  ><h2>
                                                                    {{
                                                                      items.item.last_name
                                                                        ? items.item.last_name
                                                                        : ""
                                                                    }}
                                                                    {{
                                                                      items.item.first_name
                                                                        ? items.item.first_name
                                                                        : ""
                                                                    }}
                                                                  </h2></b-link
                                                                >
                                                                <div class="star-rating">
                                                                  <star-rating
                                                                    v-model="
                                                                      items.item.average_rating
                                                                    "
                                                                    v-bind:star-size="20"
                                                                    :show-rating="false"
                                                                  ></star-rating>
                                                                </div>
                                                                <div>
                                                                  <p
                                                                    v-if="
                                                                      jobDataVal.proposed_arrival_time !=
                                                                        null &&
                                                                      jobDataVal.job_type_id == 2
                                                                    "
                                                                  >
                                                                    ETA:
                                                                    {{
                                                                      jobDataVal.proposed_arrival_time !=
                                                                      null
                                                                        ? jobDataVal.proposed_arrival_time
                                                                        : "" | moment
                                                                    }}
                                                                    |
                                                                    {{
                                                                      jobDataVal.proposed_arrival_time !=
                                                                      null
                                                                        ? jobDataVal.proposed_arrival_time
                                                                        : "" | applicantOriginalSET
                                                                    }}
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </template>
                                                          <template #cell(mobile_number1)="items">
                                                            <div class="phone_nmber_div">
                                                              <a href="#"
                                                                >Applied -
                                                                {{ items.item.applied_count }}</a
                                                              >
                                                            </div>
                                                          </template>
                                                          <template #cell(mobile_number)="items">
                                                            <div class="phone_nmber_div">
                                                              <a href="#"
                                                                >{{
                                                                  items.item.country_code
                                                                    ? items.item.country_code
                                                                    : ""
                                                                }}-{{
                                                                  items.item.mobile_number
                                                                    ? items.item.mobile_number
                                                                    : ""
                                                                }}</a
                                                              >
                                                            </div>
                                                          </template>
                                                          <template #cell(time)="items">
                                                            <div class="timing_div">
                                                              <ul>
                                                                <li>
                                                                  Applied On<span>{{
                                                                    items.item.created_at
                                                                      ? items.item.created_at
                                                                      : "" | customFormatterOne
                                                                  }}</span>
                                                                </li>
                                                              </ul>
                                                            </div>
                                                          </template>
                                                          <template #cell(thumb)="items">
                                                            <div class="thumb_div">
                                                              <i
                                                                class="fa fa-thumbs-up blue_thumb"
                                                              ></i>
                                                            </div>
                                                          </template>
                                                          <template #cell(ac_button)="items">
                                                            <div class="status_btn">
                                                              <li
                                                                v-if="
                                                                  items.item.acknowledged ==
                                                                    'yes' &&
                                                                  items.item.job_status !=
                                                                    'suspended'
                                                                "
                                                              >
                                                                <b>ACNOWLEDGED</b>
                                                              </li>
                                                            </div>
                                                          </template>

                                                          <template #cell(toggleAction)="items">
                                                            <div
                                                              class="toggle-action custom_toggle_three_dot dp_job_action"
                                                            >
                                                              <b-dropdown
                                                                class="mx-1"
                                                                right
                                                                text=""
                                                                boundary="window"
                                                              >
                                                                <b-dropdown-item
                                                                  v-if="show('more info')"
                                                                  @click="
                                                                    viewMoreInfo(
                                                                      items.item.user_id,
                                                                      items.item.job_id
                                                                    )
                                                                  "
                                                                  >MORE INFO
                                                                </b-dropdown-item>
                                                              </b-dropdown>
                                                            </div>
                                                          </template>
                                                        </b-table>
                                                      </div>

                                                      <div v-else>
                                                        <div
                                                          class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                                                        >
                                                          <p class="actionData">No Record Found</p>
                                                        </div>
                                                      </div>
                                                    </b-tab>
                                                    <b-tab
                                                      :title="
                                                        ' SYSTEM WIDTHDRAWN (' +
                                                        jobDataVal.total_withdrawn_by_system
                                                          .length +
                                                        ')'
                                                      "
                                                      class="reject_last_li mobile_min_width_1000"
                                                      @click="
                                                        getList(
                                                          jobDataVal.id,
                                                          jobDatakey,
                                                          'in-progress',
                                                          user_id
                                                        )
                                                      "
                                                    >
                                                      <div
                                                        v-if="
                                                          jobDataVal.total_withdrawn_by_system
                                                            .length > 0
                                                        "
                                                      >
                                                        <b-table
                                                          ref="datatable"
                                                          show-empty
                                                          striped
                                                          hover
                                                          responsive
                                                          :items="
                                                            jobDataVal.total_withdrawn_by_system
                                                          "
                                                          :fields="fields"
                                                          :filter="filter"
                                                          :sort-by.sync="sortBy"
                                                          :sort-desc.sync="sortDesc"
                                                          :sort-direction="sortDirection"
                                                          class="tbl_height new_box_tbl_mrgn daily_manage_job_dipper"
                                                        >
                                                          <template #cell(name)="items">
                                                            <div class="daily_image_job">
                                                              <div class="daily_job_manage_img_div">
                                                                <b-img
                                                                  :src="
                                                                    items.item.thumbnail != null
                                                                      ? items.item.thumbnail
                                                                      : webUrl +
                                                                        'assets/images/user-avatar-small-01.png'
                                                                  "
                                                                  alt="User Icon"
                                                                  height="100px"
                                                                  width="100px"
                                                                  class="user_img_emp"
                                                                ></b-img>
                                                              </div>
                                                              <div
                                                                class="daily_job_manage_user_description_div"
                                                              >
                                                                <b-link
                                                                  :to="{
                                                                    name: 'employer-jobseeker-dp-profile-view',
                                                                    params: {
                                                                      id: encrypt(
                                                                        items.item.user_id
                                                                      ),
                                                                    },
                                                                  }"
                                                                  target="_blank"
                                                                >
                                                                  <h2>
                                                                    {{
                                                                      items.item.last_name
                                                                        ? items.item.last_name
                                                                        : ""
                                                                    }}
                                                                    {{
                                                                      items.item.first_name
                                                                        ? items.item.first_name
                                                                        : ""
                                                                    }}
                                                                  </h2></b-link
                                                                >
                                                                <div class="star-rating">
                                                                  <star-rating
                                                                    v-model="
                                                                      items.item.average_rating
                                                                    "
                                                                    v-bind:star-size="20"
                                                                    :show-rating="false"
                                                                  ></star-rating>
                                                                </div>
                                                                <div>
                                                                  <p
                                                                    v-if="
                                                                      jobDataVal.proposed_arrival_time !=
                                                                        null &&
                                                                      jobDataVal.job_type_id == 2
                                                                    "
                                                                  >
                                                                    ETA:
                                                                    {{
                                                                      jobDataVal.proposed_arrival_time !=
                                                                      null
                                                                        ? jobDataVal.proposed_arrival_time
                                                                        : "" | moment
                                                                    }}
                                                                    |
                                                                    {{
                                                                      jobDataVal.proposed_arrival_time !=
                                                                      null
                                                                        ? jobDataVal.proposed_arrival_time
                                                                        : "" | applicantOriginalSET
                                                                    }}
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </template>
                                                          <template #cell(mobile_number1)="items">
                                                            <div class="phone_nmber_div">
                                                              <a href="#"
                                                                >Applied -
                                                                {{ items.item.applied_count }}</a
                                                              >
                                                            </div>
                                                          </template>
                                                          <template #cell(mobile_number)="items">
                                                            <div class="phone_nmber_div">
                                                              <a href="#"
                                                                >{{
                                                                  items.item.country_code
                                                                    ? items.item.country_code
                                                                    : ""
                                                                }}-{{
                                                                  items.item.mobile_number
                                                                    ? items.item.mobile_number
                                                                    : ""
                                                                }}</a
                                                              >
                                                            </div>
                                                          </template>
                                                          <template #cell(time)="items">
                                                            <div class="timing_div">
                                                              <ul>
                                                                <li>
                                                                  <i
                                                                    class="fa fa-arrow-down green_arrow"
                                                                  ></i
                                                                  ><span>{{
                                                                    items.item.start_time
                                                                      ? items.item.start_time
                                                                      : "" | applicantStartEndTime
                                                                  }}</span
                                                                  ><i
                                                                    class="fa fa-arrow-up red_arrow green_arrow"
                                                                  ></i
                                                                  ><span>{{
                                                                    items.item.end_time
                                                                      ? items.item.end_time
                                                                      : "" | applicantOriginalSET
                                                                  }}</span>
                                                                </li>
                                                              </ul>
                                                            </div>
                                                          </template>
                                                          <template #cell(thumb)="items">
                                                            <div class="thumb_div">
                                                              <i
                                                                class="fa fa-thumbs-up blue_thumb"
                                                              ></i>
                                                            </div>
                                                          </template>
                                                          <template #cell(ac_button)="items">
                                                            <div class="status_btn">
                                                              <li
                                                                v-if="
                                                                  items.item.acknowledged ==
                                                                    'yes' &&
                                                                  items.item.job_status !=
                                                                    'suspended'
                                                                "
                                                              >
                                                                <b>ACNOWLEDGED</b>
                                                              </li>
                                                            </div>
                                                          </template>
                                                          <template #cell(toggleAction)="items">
                                                            <div
                                                              class="toggle-action custom_toggle_three_dot dp_job_action"
                                                            >
                                                              <b-dropdown
                                                                class="mx-1"
                                                                right
                                                                text=""
                                                                boundary="window"
                                                              >
                                                                <b-dropdown-item
                                                                  v-if="show('more info')"
                                                                  @click="
                                                                    viewMoreInfo(
                                                                      items.item.user_id,
                                                                      items.item.job_id
                                                                    )
                                                                  "
                                                                  >MORE INFO
                                                                </b-dropdown-item>
                                                              </b-dropdown>
                                                            </div>
                                                          </template>
                                                        </b-table>
                                                      </div>
                                                      <div v-else>
                                                        <div
                                                          class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                                                        >
                                                          <p class="actionData">No Record Found</p>
                                                        </div>
                                                      </div>
                                                    </b-tab>
                                                  </b-tabs>
                                                </div>
                                              </div>
                                            </div>
                                          </b-card-body>
                                        </b-collapse>
                                      </b-card>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div
                      class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                      v-if="jobDatas.length == 0"
                    >
                      <p class="actionData"><strong>No Record Found</strong></p>
                    </div> -->
                  </b-tab>
                  <b-tab
                    :title="'CANCELLED'"
                    :active="cancelled"
                    @click="changeStatus('cancelled', 'all', 'all', '')"
                    class="reject_last_li"
                  >
                    <div class="top_desc_div" v-for="(jobDataVal, jobDatakey) in jobDatas">
                      <div class="box_shadow box_new_shadow">
                        <div class="padding_manage_job">
                          <ul class="com_id_ul">
                            <li>{{ jobDataVal.job_unique_id }}</li>

                            <!-- <li>SERVICE AMT: S${{jobDataVal.service_fee}}</li> -->
                            <li>
                              <button
                                class="service_cat_name_manage_job"
                                style="pointer-events: none; border: 1px solid"
                                :style="{
                                  color: jobDataVal.service_category.color,
                                  borderColor: jobDataVal.service_category.color,
                                }"
                              >
                                {{ jobDataVal.service_category.name }}
                              </button>
                            </li>
                            <li v-if="jobDataVal.approval_status == 'pending'">
                              <button
                                class="demand_btn"
                                style="
                                  background-color: rgb(229, 138, 10) !important;
                                  pointer-events: none;
                                "
                              >
                                Pending Approval
                              </button>
                            </li>
                            <li v-if="jobDataVal.job_type_id == 2">
                              <button class="demand_btn" style="background: #28a745">
                                ON DEMAND JOB
                              </button>
                            </li>
                          </ul>
                          <div
                            class="toggle-action common_ul_radius toggle_margin_unset new-action-view-detail"
                          >
                            <!-- <b-dropdown class="mx-1" right text="Action" boundary="window"> -->
                            <b-dropdown class="mx-1" left text="Action" boundary="window">
                              <b-dropdown-item
                                :to="{
                                  name: 'repostJobDaily',
                                  params: { id: encrypt(jobDataVal.id) },
                                }"
                                v-if="showPermissionEM('duplicate job')"
                                >Repost
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                          <div class="heading_with_status">
                            <div class="comp_heading_div">
                              <h2 class="top_color_h2_single">{{ jobDataVal.title }}</h2>
                              <!-- <div class="dtl_text" v-if="jobDataVal.userData">
                                <h2>Posted By:</h2>
                                <p class="mb-0">
                                  {{
                                    jobDataVal.userData && jobDataVal.userData.last_name
                                      ? jobDataVal.userData.last_name
                                      : ""
                                  }}
                                  {{
                                    jobDataVal.userData && jobDataVal.userData.first_name
                                      ? jobDataVal.userData.first_name
                                      : ""
                                  }}
                                </p>
                                <p class="mb-0">
                                  {{ jobDataVal.created_at | customFormatterOnecreated }}
                                </p>
                              </div> -->
                            </div>
                            <!-- <div class="rate_div new_rate_divs"> -->
                            <!-- <div class="status_btn new_status_btn_divs"> -->
                            <!-- <h2 class="rate_text" v-if="showPermissionEM('show list rate') && showJobListRateAdmin('show_job_list_rate')">S${{jobDataVal.hourly_cost}}/HR</h2> -->
                            <!-- <b-link href="" :to="{name: 'repostJobDaily', params: {id: encrypt(jobDataVal.id)}}" v-if="showPermissionEM('duplicate job')" class="button gray ripple-effect ico repost_icon_btn" title="Repost" data-tippy-placement="top"><i class="material-icons-outlined assign_users_i">repeat_outlined</i></b-link> -->
                            <!-- <b-link href="" :to="{name: 'DailyJobEdit', params: {id: encrypt(jobDataVal.id)}}" class="button gray ripple-effect ico edit_icon_btn" title="Edit" data-tippy-placement="top"><i class="fa fa-edit"></i></b-link> -->
                            <!-- </div> -->
                            <!-- <div class="status_btn">
                                                            <b-link href="" :to="{name: 'DailyJobEdit', params: {id: encrypt(jobDataVal.id)}}" class="button gray ripple-effect ico edit_icon_btn" title="Edit" data-tippy-placement="top"><i class="fa fa-edit"></i></b-link>
                                                    </div> -->
                            <!-- <div class="rate_text" v-if="showPermissionEM('show list rate') && showJobListRateAdmin('show_job_list_rate')">
                                                        <h2>S${{jobDataVal.hourly_cost}}/HR</h2>
                                                    </div> -->
                            <!-- <div class="action_div">
                                                        <button class="action_btn">ACTIONS<i class="fa fa-caret-down"></i></button>
                                                    </div> -->
                            <!-- </div> -->
                          </div>
                          <!-- <div class="img_with_desc">

                                            </div> -->
                          <ul class="manage_job_top_ul_nw">
                            <li>
                              <span class="material-icons-outlined">calendar_month_outlined</span
                              >{{ jobDataVal.start_date | moment_1 }}
                            </li>
                            <li>
                              <span class="material-icons-outlined">access_time_outlined</span
                              >{{ jobDataVal.start_time | jobStartEndTime }} -
                              {{ jobDataVal.end_time | jobStartEndTime }}
                              <span class="red_note_manage_job"
                                ><p v-if="jobDataVal.lunch_pay_hours != null">
                                  <strong>Note:</strong> Break hour not paid -
                                  {{ jobDataVal.lunch_pay_hours }} Hr
                                </p></span
                              >
                            </li>
                            <li
                              v-if="
                                showPermissionEM('show list rate') &&
                                showJobListRateAdmin('show_job_list_rate')
                              "
                            >
                              <span class="material-icons-outlined">monetization_on_outlined</span
                              >${{ jobDataVal.hourly_cost }}/Hour
                            </li>
                          </ul>

                          <!-- <div class="working_completed_section">
                              <span>Working: {{ jobDataVal.inprogressCount.length }}</span
                              ><span>Completed: {{ jobDataVal.completedCount.length }}</span>
                            </div> -->
                        </div>
                        <div class="full_desc_emps">
                          <!-- <ul>
                                  <li>Posted By:<span>{{(jobDataVal.userData && jobDataVal.userData.last_name) ? jobDataVal.userData.last_name : ''}} {{(jobDataVal.userData && jobDataVal.userData.first_name) ? jobDataVal.userData.first_name : ''}}</span></li>
                                  <li>Job Posted D& Time:<span>{{jobDataVal.start_date | moment}} {{jobDataVal.start_time | jobStartEndTime}}</span></li>
                                  <li>Job Duration:<span>{{jobDataVal.start_date | moment}} {{jobDataVal.start_time | jobStartEndTime}} - {{jobDataVal.start_time | jobStartEndTime}}</span></li>
                                  <li>Job Hours:<span>{{jobDataVal.working_hours}}</span></li>
                                  <li>Vacancy:<span>{{jobDataVal.userJobs.length}}/{{jobDataVal.total_vacancies}}</span></li>
                                  <li v-if="jobDataVal.work_site_contact_person">Work Site Contact Person Name:<span>{{jobDataVal.work_site_contact_person ? jobDataVal.work_site_contact_person : ''}}</span></li>
                                  <li v-if="jobDataVal.work_site_contact_person_mobile_no">Work Site Contact Person Number:<span>{{jobDataVal.work_site_contact_person_country_code ? jobDataVal.work_site_contact_person_country_code : ''}}-{{jobDataVal.work_site_contact_person_mobile_no ? jobDataVal.work_site_contact_person_mobile_no : ''}}</span></li>
                              </ul> -->
                          <div class="single_accordian manage_job_accordian mb-3">
                            <!-- <br> -->
                            <div>
                              <div class="faq-content-box clearfix">
                                <div>
                                  <div class="accordion-listing-box coloured_icon">
                                    <b-card no-body>
                                      <b-card-header header-tag="header" role="tab">
                                        <b-button
                                          v-b-toggle="
                                            'accordion_view_job_details_cancelled-' + jobDatakey
                                          "
                                          class="m-1"
                                          variant="info"
                                        >
                                          <div class="dashboard-box">
                                            <div class="headline">
                                              <h3 class="heading_section_h3">VIEW JOB DETAILS</h3>
                                            </div>
                                          </div>
                                        </b-button>
                                      </b-card-header>
                                      <b-collapse
                                        :id="'accordion_view_job_details_cancelled-' + jobDatakey"
                                        role="tabpanel"
                                      >
                                        <b-card-body>
                                          <div class="">
                                            <div class="row">
                                              <div class="col-xl-12">
                                                <div class="comp_heading_div_manage_job">
                                                  <div class="dtl_text">
                                                    <h2>Posted By:</h2>
                                                    <p class="mb-0">
                                                      {{
                                                        jobDataVal && jobDataVal.last_name
                                                          ? jobDataVal.last_name
                                                          : ""
                                                      }}
                                                      {{
                                                        jobDataVal.first_name
                                                          ? jobDataVal.first_name
                                                          : ""
                                                      }}
                                                    </p>
                                                    <p class="mb-0">
                                                      {{
                                                        jobDataVal.created_at
                                                          | customFormatterOnecreated
                                                      }}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div
                                                  class="table-responsive whole_custom_tbl common_table_scroll manage_job_new_tblz"
                                                >
                                                  <table
                                                    class="table custom_manage_job_tbl unset_last_super"
                                                  >
                                                    <thead>
                                                      <tr>
                                                        <!-- <th v-if="jobDataVal.userData">Posted By:</th> -->
                                                        <!-- <th>Job Posted Date & Time:</th> -->
                                                        <!-- <th>Job Date & Time:</th>
                                                                                        <th>Job Hours:</th>
                                                                                        <th>Vacancy:</th> -->
                                                        <th
                                                          v-if="
                                                            jobDataVal.work_site_contact_person ||
                                                            jobDataVal.work_site_contact_person_mobile_no
                                                          "
                                                        >
                                                          Work Site Contact Person Name:
                                                        </th>
                                                        <th></th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr>
                                                        <!-- <td v-if="jobDataVal.userData">
                                                                                            {{(jobDataVal.userData && jobDataVal.userData.last_name) ? jobDataVal.userData.last_name : ''}} {{(jobDataVal.userData && jobDataVal.userData.first_name) ? jobDataVal.userData.first_name : ''}}
                                                                                        </td> -->
                                                        <!-- <td>
                                                                                            {{jobDataVal.created_at | customFormatterOnecreated}}
                                                                                        </td> -->
                                                        <!-- <td>{{jobDataVal.start_date | moment_1}} {{jobDataVal.start_time | jobStartEndTime}} - {{jobDataVal.end_time | jobStartEndTime}} </td>
                                                                                        <td>{{jobDataVal.working_hours}}</td>
                                                                                        <td>
                                                                                            {{jobDataVal.userJobsselected.length}}/{{jobDataVal.total_vacancies}}
                                                                                        </td> -->
                                                        <td
                                                          v-if="
                                                            jobDataVal.work_site_contact_person ||
                                                            jobDataVal.work_site_contact_person_mobile_no
                                                          "
                                                        >
                                                          {{
                                                            jobDataVal.work_site_contact_person
                                                              ? jobDataVal.work_site_contact_person
                                                              : ""
                                                          }}
                                                          <br />
                                                          {{
                                                            jobDataVal.work_site_contact_person_country_code
                                                              ? jobDataVal.work_site_contact_person_country_code +
                                                                "-"
                                                              : ""
                                                          }}{{
                                                            jobDataVal.work_site_contact_person_mobile_no
                                                              ? jobDataVal.work_site_contact_person_mobile_no
                                                              : ""
                                                          }}<br />
                                                        </td>
                                                        <td
                                                          v-if="
                                                            jobDataVal.work_site_contact_person ||
                                                            jobDataVal.work_site_contact_person_mobile_no
                                                          "
                                                        >
                                                          {{
                                                            jobDataVal.work_site_contact_person_2
                                                              ? jobDataVal.work_site_contact_person_2
                                                              : ""
                                                          }}<br />
                                                          {{
                                                            jobDataVal.work_site_contact_person_2_mobile_no
                                                              ? jobDataVal.work_site_contact_person_2_country_code +
                                                                "-"
                                                              : ""
                                                          }}{{
                                                            jobDataVal.work_site_contact_person_2_mobile_no
                                                              ? jobDataVal.work_site_contact_person_2_mobile_no
                                                              : ""
                                                          }}
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                                <div class="dtl_text">
                                                  <div class="large-content-requirement-cancelled">
                                                    <h2 v-if="jobDataVal.description">
                                                      Job Requirements
                                                      <span class="manage_job_eye_span"
                                                        ><i
                                                          class="fa fa-eye-slash"
                                                          :id="
                                                            'manage_job_requirement_cancelled_eye_icon' +
                                                            jobDatakey
                                                          "
                                                          @click="
                                                            ManageJobRequirementCancelledEyeReveal(
                                                              jobDatakey
                                                            )
                                                          "
                                                          style="cursor: pointer"
                                                        ></i
                                                      ></span>
                                                    </h2>
                                                    <p
                                                      :id="
                                                        'visible-content-requirement-cancelled' +
                                                        jobDatakey
                                                      "
                                                      style=""
                                                      class=""
                                                      v-if="jobDataVal.description.length <= 249"
                                                    >
                                                      {{ jobDataVal.description.substring(0, 249) }}
                                                    </p>
                                                    <p
                                                      :id="
                                                        'visible-content-requirement-cancelled' +
                                                        jobDatakey
                                                      "
                                                      style=""
                                                      class=""
                                                      v-if="jobDataVal.description.length >= 250"
                                                    >
                                                      {{
                                                        jobDataVal.description.substring(0, 250) +
                                                        ".."
                                                      }}
                                                    </p>
                                                    <p
                                                      :id="
                                                        'invisible-content-requirement-cancelled' +
                                                        jobDatakey
                                                      "
                                                      style="display: none"
                                                      class=""
                                                    >
                                                      {{ jobDataVal.description }}
                                                    </p>
                                                  </div>
                                                  <div class="large-content-scope-cancelled">
                                                    <h2 v-if="jobDataVal.job_scope">
                                                      Job Scope:
                                                      <span class="manage_job_eye_span"
                                                        ><i
                                                          class="fa fa-eye-slash"
                                                          :id="
                                                            'manage_job_scope_cancelled_eye_icon' +
                                                            jobDatakey
                                                          "
                                                          @click="
                                                            ManageJobScopeCancelledEyeReveal(
                                                              jobDatakey
                                                            )
                                                          "
                                                          style="cursor: pointer"
                                                        ></i
                                                      ></span>
                                                    </h2>
                                                    <p
                                                      :id="
                                                        'visible-content-scope-cancelled' +
                                                        jobDatakey
                                                      "
                                                      style=""
                                                      class=""
                                                      v-if="jobDataVal.job_scope.length <= 249"
                                                    >
                                                      {{ jobDataVal.job_scope.substring(0, 249) }}
                                                    </p>
                                                    <p
                                                      :id="
                                                        'visible-content-scope-cancelled' +
                                                        jobDatakey
                                                      "
                                                      style=""
                                                      class=""
                                                      v-if="jobDataVal.job_scope.length >= 250"
                                                    >
                                                      {{
                                                        jobDataVal.job_scope.substring(0, 250) +
                                                        ".."
                                                      }}
                                                    </p>
                                                    <p
                                                      :id="
                                                        'invisible-content-scope-cancelled' +
                                                        jobDatakey
                                                      "
                                                      style="display: none"
                                                      class=""
                                                    >
                                                      {{ jobDataVal.job_scope }}
                                                    </p>
                                                  </div>
                                                  <h2 v-if="jobDataVal.address">Job Location</h2>
                                                  <p v-if="jobDataVal.address">
                                                    {{ jobDataVal.address }}
                                                  </p>
                                                  <!-- <p v-if="jobDataVal.address"><i class="fa fa-map-marker"></i>{{jobDataVal.address}}</p> -->
                                                  <!-- <p v-if="jobDataVal.lunch_pay_hours != null"><strong>Note:</strong>Break hour not paid - {{jobDataVal.lunch_pay_hours}} Hr</p> -->
                                                  <p class="mb-0">
                                                    <strong>Cancellation Reason - </strong
                                                    >{{ jobDataVal.cancellation_reason }}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </b-card-body>
                                      </b-collapse>
                                    </b-card>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="single_accordian manage_job_accordian">
                            <!-- <br> -->
                            <div>
                              <div class="faq-content-box clearfix">
                                <div>
                                  <div class="accordion-listing-box coloured_icon">
                                    <b-card no-body>
                                      <b-card-header header-tag="header" role="tab">
                                        <b-button
                                          v-b-toggle="
                                            'accordion_view_candidates_cancelled-' + jobDatakey
                                          "
                                          class="m-1"
                                          variant="info"
                                          @click="
                                            getList(jobDataVal.id, jobDatakey, 'cancelled', user_id)
                                          "
                                        >
                                          <div class="dashboard-box">
                                            <div class="headline">
                                              <h3 class="heading_section_h3">View candidates</h3>
                                            </div>
                                          </div>
                                        </b-button>
                                      </b-card-header>
                                      <b-collapse
                                        :id="'accordion_view_candidates_cancelled-' + jobDatakey"
                                        role="tabpanel"
                                      >
                                        <b-card-body>
                                          <div class="">
                                            <div class="row">
                                              <div class="col-xl-12">
                                                <b-tabs class="custom_tabs_daily_manage_jobs">
                                                  <div v-if="jobDataVal.total">
                                                    <b-tab
                                                      :title="
                                                        'ALL APPLICANTS (' +
                                                        jobDataVal.total.length +
                                                        ')'
                                                      "
                                                      class="reject_last_li"
                                                      @click="
                                                        changeApplicantTabStatus(
                                                          'cancelled',
                                                          'applicationCancel',
                                                          jobDataVal.id,
                                                          jobDatakey,
                                                          jobDataVal.posted_by
                                                        )
                                                      "
                                                    >
                                                      <b-table
                                                        ref="datatable"
                                                        show-empty
                                                        striped
                                                        hover
                                                        responsive
                                                        :items="jobDataVal.total"
                                                        :fields="fields"
                                                        :filter="filter"
                                                        :sort-by.sync="sortBy"
                                                        :sort-desc.sync="sortDesc"
                                                        :sort-direction="sortDirection"
                                                        class="tbl_height new_box_tbl_mrgn daily_manage_job_dipper"
                                                      >
                                                        <template #cell(name)="items">
                                                          <div class="daily_image_job">
                                                            <div class="daily_job_manage_img_div">
                                                              <b-img
                                                                :src="
                                                                  items.item.profile_image != null
                                                                    ? items.item.profile_image
                                                                    : webUrl +
                                                                      'assets/images/user-avatar-small-01.png'
                                                                "
                                                                alt="User Icon"
                                                                height="100px"
                                                                width="100px"
                                                                class="user_img_emp"
                                                              ></b-img>
                                                              <div
                                                                class="custom_star_rate_manage_job"
                                                              >
                                                                <span
                                                                  ><i class="fa fa-star"></i
                                                                  >{{
                                                                    items.item.average_rating
                                                                      ? items.item.average_rating
                                                                      : 0
                                                                  }}</span
                                                                >
                                                              </div>
                                                            </div>
                                                            <div
                                                              class="daily_job_manage_user_description_div"
                                                            >
                                                              <b-link
                                                                v-if="
                                                                  showPermissionEM('view profile')
                                                                "
                                                                target="_blank"
                                                                :to="{
                                                                  name: 'employer-jobseeker-dp-profile-view',
                                                                  params: {
                                                                    id: encrypt(items.item.user_id),
                                                                  },
                                                                }"
                                                                ><h2>
                                                                  {{
                                                                    items.item.last_name
                                                                      ? items.item.last_name
                                                                      : ""
                                                                  }}
                                                                  {{
                                                                    items.item.first_name
                                                                      ? items.item.first_name
                                                                      : ""
                                                                  }}
                                                                </h2></b-link
                                                              >
                                                              <h2 v-else>
                                                                {{
                                                                  items.item.last_name
                                                                    ? items.item.last_name
                                                                    : ""
                                                                }}
                                                                {{
                                                                  items.item.first_name
                                                                    ? items.item.first_name
                                                                    : ""
                                                                }}
                                                              </h2>

                                                              <div class="star-rating">
                                                                <!-- <star-rating v-model="items.item.userdetail.average_rating" v-bind:star-size="20" :show-rating="false"></star-rating> -->
                                                              </div>
                                                              <div>
                                                                <p
                                                                  v-if="
                                                                    jobDataVal.proposed_arrival_time !=
                                                                      null &&
                                                                    jobDataVal.job_type_id == 2
                                                                  "
                                                                >
                                                                  ETA:
                                                                  {{
                                                                    jobDataVal.proposed_arrival_time !=
                                                                    null
                                                                      ? jobDataVal.proposed_arrival_time
                                                                      : "" | moment
                                                                  }}
                                                                  |
                                                                  {{
                                                                    jobDataVal.proposed_arrival_time !=
                                                                    null
                                                                      ? jobDataVal.proposed_arrival_time
                                                                      : "" | applicantOriginalSET
                                                                  }}
                                                                </p>
                                                                <!-- <p v-else>ETA: NA</p> -->
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </template>

                                                        <template #cell(time)="items">
                                                          <div class="timing_div cancelled_div_tb">
                                                            <ul>
                                                              <li>
                                                                <span
                                                                  ><b>Cancelled Reason:</b></span
                                                                >
                                                                <span>{{
                                                                  items.item.cancellation_reason
                                                                    ? items.item.cancellation_reason
                                                                    : ""
                                                                }}</span>
                                                              </li>
                                                              <br />
                                                              <li>
                                                                <span><b>Cancelled At:</b></span
                                                                ><span>{{
                                                                  items.item.cancelled_at
                                                                    ? items.item.cancelled_at
                                                                    : "" | customFormatterOne
                                                                }}</span>
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </template>
                                                        <!-- <template #cell(thumb)="items">
                                                                                <div class="thumb_div">
                                                                                <i class="fa fa-thumbs-up blue_thumb"></i>
                                                                            </div>
                                                                            </template> -->
                                                        <template #cell(ac_button)="items">
                                                          <div class="status_btn flex_item_start">
                                                            <li
                                                              v-if="
                                                                items.item.acknowledged_count > 0
                                                              "
                                                            >
                                                              <b
                                                                >Acknowleged -
                                                                {{ items.item.acknowledged_count }}
                                                              </b>
                                                            </li>
                                                            <button
                                                              class="comp_tbn"
                                                              v-if="items.item.awarded_by != null"
                                                            >
                                                              Awarded
                                                            </button>
                                                          </div>
                                                        </template>
                                                      </b-table>
                                                    </b-tab>
                                                  </div>

                                                  <!-- <b-tab :title="'APPLICATIONS (' + jobDataVal.userJobsApplication.length + ')'"  class="reject_last_li" @click="changeApplicantTabStatus('cancelled','application',jobDataVal.id,jobDatakey)">
                                                                        <b-table ref="datatable" show-empty striped hover responsive :items="jobDataVal.userJobs" :fields="fields"  :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="   tbl_height   new_box_tbl_mrgn daily_manage_job_dipper">
                                                                            <template #cell(name)="items">
                                                                                <div class="daily_job_manage_img_div">
                                                                                    <b-img :src="(items.item.userdetail.thumbnail != null ? items.item.userdetail.thumbnail: webUrl+'assets/images/user-avatar-small-01.png')" alt="User Icon" height="100px" width="100px" class="user_img_emp"></b-img>
                                                                                </div>
                                                                                <div class="daily_job_manage_user_description_div">
                                                                                    <h2>{{items.item.userdetail.last_name ? items.item.userdetail.last_name : ''}} {{items.item.userdetail.first_name ? items.item.userdetail.first_name : ''}}</h2>
                                                                                    <div class="star-rating">
                                                                                        <star-rating v-model="items.item.userdetail.average_rating" v-bind:star-size="20" :show-rating="false"></star-rating>

                                                                                    </div>
                                                                                        <div>
                                                                                            <p v-if="items.item.proposed_arrival_time != null">ETA: {{items.item.proposed_arrival_time != null ? items.item.proposed_arrival_time : '' | moment }} | {{ items.item.proposed_arrival_time != null ? items.item.proposed_arrival_time : '' | applicantOriginalSET }}</p>
                                                                                            <p v-else>ETA: NA</p>
                                                                                        </div>
                                                                                </div>

                                                                            </template>
                                                                            <template #cell(mobile_number)="items">
                                                                            <div class="phone_nmber_div">
                                                                                <a href="#">{{items.item.userdetail.country_code ? items.item.userdetail.country_code : ''}}-{{items.item.userdetail.mobile_number ? items.item.userdetail.mobile_number : ''}}</a>
                                                                            </div>
                                                                            </template>
                                                                            <template #cell(time)="items">
                                                                                <div class="timing_div">
                                                                                    <ul>
                                                                                        <li><i class="fa fa-arrow-down green_arrow"></i><span>{{items.item.original_start_time ? items.item.original_start_time : '' | applicantOriginalSET}}</span><i class="fa fa-arrow-up red_arrow green_arrow"></i><span>{{items.item.original_end_time ? items.item.original_end_time : '' | applicantOriginalSET}}</span></li>
                                                                                        <li><i class="fa fa-arrow-down green_arrow gray_tab_round"></i><span>{{items.item.start_time ? items.item.start_time : '' | applicantStartEndTime}}</span><i class="fa fa-arrow-up  green_arrow gray_tab_round"></i><span>{{items.item.end_time ? items.item.end_time : '' | applicantStartEndTime}}</span></li>
                                                                                    </ul>
                                                                            </div>
                                                                            </template>
                                                                            <template #cell(thumb)="items">
                                                                                <div class="thumb_div">
                                                                                <i class="fa fa-thumbs-up blue_thumb"></i>
                                                                            </div>
                                                                            </template>
                                                                            <template #cell(ac_button)="items">
                                                                                <div class="status_btn">
                                                                                    <button @click="notSuitable(items.item.user_id, items.item.job_id)" class="ack_btn">Not Suitable</button>

                                                                                </div>
                                                                            </template>
                                                                            <template #cell(toggleAction)="items">
                                                                                <div class="toggle-action custom_toggle_three_dot dp_job_action">
                                                                                    <b-dropdown class="mx-1" right text="" boundary="window">
                                                                                        <b-dropdown-item :to="{name: 'empManageUserEdit', params: {id: encrypt(items.item.id)}}">Edit
                                                                                        </b-dropdown-item>
                                                                                        <b-dropdown-item :to="{name: 'emp-manage-user-view', params: {id: encrypt(items.item.id)}}">View Profile
                                                                                        </b-dropdown-item>
                                                                                        <b-dropdown-item v-on:click="deleteEmployer(items.item.id)">Delete</b-dropdown-item>
                                                                                    </b-dropdown>
                                                                                </div>
                                                                            </template>
                                                                        </b-table>
                                                                    </b-tab>
                                                                    -->
                                                </b-tabs>
                                              </div>
                                            </div>
                                          </div>
                                        </b-card-body>
                                      </b-collapse>
                                    </b-card>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-tab>

                  <nav
                    class="pagination-box custom_pagination_border_radius manage_job_employer_pagination"
                  >
                    <div class="page-row-box d-inline-block">
                      <b-form-group id="input-group-4">
                        <span class="d-inline-block">Rows per page</span>
                        <span class="d-inline-block">
                          <b-form-select
                            v-model="form.rowsPerPage"
                            @change="getListing(activeTab, 'selected', filterSearch)"
                          >
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                            <option>75</option>
                            <option>100</option>
                          </b-form-select></span
                        >
                      </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block custom_pgn_tab">
                      <ul>
                        <li>
                          <span v-if="totalRows != 0"
                            >{{ from }} - {{ to }} of {{ totalRows }}</span
                          >
                        </li>
                      </ul>
                    </div>
                    <b-pagination
                      :total-rows="totalRows"
                      @input="getListing(activeTab, 'selected', filterSearch, '', user_id)"
                      :per-page="perPage"
                      v-model="currentPage"
                      prev-text=""
                      next-text=""
                      hide-goto-end-buttons
                    />
                  </nav>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
        <!-- Row / End -->
        <!-- Row / End -->
        <div class="clearfix"></div>

        <div class="clearfix"></div>
        <!-- <div class="margin-top-70"></div> -->
      </div>

      <b-modal ref="platform-fee-modal" title="Modify Platform Fee" hide-footer>
        <!-- <b-form> -->
        <div class="edit-form-box">
          <div class="profile-repeate form-box-field">
            <b-row>
              <b-col lg="12" md="12">
                <div class="form-map-box">
                  <b-col lg="6" md="6">
                    <b-form-group id="input-group-9" :label="platform_fee_title" class="required">
                      <b-form-input
                        id="input-9"
                        maxlength="6"
                        v-model="platform_fee"
                        type="text"
                        placeholder=""
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" md="6">
                    <div class="permission_listing">
                      <b-form-group>
                        <b-form-checkbox value="true" unchecked-value="false" v-model="generateSr"
                          ><strong>Generate SR?</strong></b-form-checkbox
                        >
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-form-group
                    id="input-group-8"
                    label="With Email"
                    class="category-radio required"
                  >
                    <b-form-radio v-model="withNoEmail" name="withNoEmail" value="yes"
                      >Yes</b-form-radio
                    >
                    <b-form-radio v-model="withNoEmail" name="withNoEmail" value="no"
                      >No</b-form-radio
                    >
                  </b-form-group>

                  <div slot="footer" class="form-btn">
                    <b-button type="submit" variant="success" @click="modifyPlatformFee()"
                      >Submit</b-button
                    >
                    <b-button @click="hideModel()" variant="light">Cancel</b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <!-- </b-form> -->
      </b-modal>

      <b-modal ref="review-modal" title="Rate and Review" hide-footer>
        <b-form @submit="onSubmitReview">
          <div class="edit-form-box">
            <div class="profile-repeate form-box-field">
              <b-row>
                <b-col lg="12" md="12">
                  <div class="radio-star">
                    <b-form-group id="input-group-4" class="rating-star-list">
                      <star-rating
                        v-model="rating"
                        :star-size="40"
                        :show-rating="false"
                      ></star-rating>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
              <b-col lg="12" md="12">
                <b-form-group id="input-group-3" label="Review">
                  <b-form-textarea
                    id="textarea-3"
                    type="textarea"
                    placeholder="Enter Comment"
                    v-model="form.review"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
              <div class="pt-2 mt-3 text-md-left text-center">
                <b-button type="submit" variant="danger" class="text-uppercase x-md btn-shadow"
                  >Submit</b-button
                >
              </div>
            </div>
          </div>
        </b-form>
      </b-modal>
      <b-modal ref="location-modal" title="Location" hide-footer @shown="refreshMap">
        <!-- <b-form @submit="onSubmitLocation"> -->
        <div class="edit-form-box">
          <div class="profile-repeate form-box-field">
            <b-row>
              <b-col lg="12" md="12">
                <div class="form-map-box">
                  <gmap-map
                    ref="googleMap"
                    :center="center"
                    :zoom="12"
                    style="width: 100%; height: 400px"
                  >
                    <gmap-marker
                      :key="index"
                      v-for="(m, index) in latlongMarkers"
                      :position="m.position"
                      :icon="m.icon"
                      @click="toggleInfoWindow(m, index)"
                    ></gmap-marker>
                    <gmap-info-window
                      :options="infoOptions"
                      :position="infoWindowPos"
                      :opened="infoWinOpen"
                      @closeclick="infoWinOpen = false"
                    >
                      <div v-html="infoContent"></div>
                    </gmap-info-window>
                  </gmap-map>
                </div>
              </b-col>
            </b-row>
            <!-- <div class="pt-2 mt-3 text-md-left text-center">
                            <b-button type="submit" variant="danger" class="text-uppercase x-md btn-shadow">Submit</b-button>
                        </div> -->
          </div>
        </div>
        <!-- </b-form> -->
      </b-modal>
      <b-modal ref="cancel-modal" title="Cancel Applicant" hide-footer>
        <b-form @submit="onSubmitCancel">
          <div class="edit-form-box">
            <div class="profile-repeate form-box-field">
              <b-row>
                <b-col lg="12" md="12">
                  <b-form-group id="input-group-3" label="Cancellation Reason">
                    <b-form-textarea
                      id="textarea-3"
                      type="textarea"
                      placeholder="Enter Reason"
                      v-model="form.cancellation_reason"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="pt-2 mt-3 text-md-left text-center">
                <b-button type="submit" variant="danger" class="text-uppercase x-md btn-shadow"
                  >Submit</b-button
                >
              </div>
            </div>
          </div>
        </b-form>
      </b-modal>
      <b-modal ref="job-cancel-modal" title="Cancel Job" hide-footer>
        <b-form @submit="onSubmitCancelJob">
          <div class="edit-form-box">
            <!-- <b-alert :show="dismissCountDownSuspend" dismissible variant="danger" @dismissed="dismissCountDownSuspend=0" @dismiss-count-down="countDownChangedSuspend">
                            <span>{{ error_messages_suspend }}</span>
                        </b-alert> -->
            <div class="profile-repeate form-box-field">
              <b-row>
                <b-col lg="12" md="12">
                  <b-form-group id="input-group-3" label="Cancellation Reason">
                    <b-form-textarea
                      id="textarea-3"
                      type="textarea"
                      placeholder="Enter Reason"
                      v-model="form.job_cancellation_reason"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="pt-2 mt-3 text-md-left text-center">
                <b-button type="submit" variant="danger" class="text-uppercase x-md btn-shadow"
                  >Submit</b-button
                >
              </div>
            </div>
          </div>
        </b-form>
      </b-modal>
      <b-modal ref="arrival-modal" :title="arrival_model_title" hide-footer>
        <b-form>
          <div class="edit-form-box">
            <div class="profile-repeate form-box-field">
              <b-row>
                <b-col lg="12" md="12" v-if="arrival_condition == 'revive'">
                  <b-form-group
                    id="input-group-8"
                    label="Select Option "
                    class="category-radio required"
                  >
                    <b-form-radio v-model="user_revived_with" name="gender" value="as_selected"
                      >As Selected</b-form-radio
                    >
                    <b-form-radio
                      v-model="user_revived_with"
                      name="gender"
                      value="with_mark_arrival"
                      >With Mark Arrival</b-form-radio
                    >
                    <b-form-radio
                      v-model="user_revived_with"
                      name="gender"
                      value="with_confirm_arrival"
                      >With Confirm Arrival</b-form-radio
                    >
                  </b-form-group>
                </b-col>
                <b-col lg="12" md="12" v-if="user_revived_with != 'as_selected'">
                  <b-form-group>
                    <b-form-checkbox v-model="arrival_time_next_day"
                      ><strong>Next day arrival?</strong></b-form-checkbox
                    >
                  </b-form-group>
                </b-col>
                <b-col lg="12" md="12">
                  <b-form-group
                    id="input-group-7"
                    label="Arrival Time"
                    class="required"
                    v-if="user_revived_with != 'as_selected'"
                  >
                    <vue-timepicker format="hh:mm A" v-model="arrival_time"></vue-timepicker>
                    <span class="input-icon"
                      ><b-img
                        v-bind:src="weburl + '/images/clock-grey-icon02.svg'"
                        alt="Clock Icon"
                      ></b-img
                    ></span>
                  </b-form-group>
                </b-col>
                <!--
                                <b-col lg="12" md="12" v-if="arrival_condition == 'arrival'">
                                    <b-form-group>
                                        <b-form-checkbox v-model = 'subjected_to_late_penalty' @input="late_penalty($event)"><strong>Subjected to Late Penalty</strong></b-form-checkbox>
                                    </b-form-group>
                                </b-col> -->
                <b-col lg="12" md="12" v-if="arrival_condition == 'arrival'">
                  <div class="permission_listing">
                    <b-form-group>
                      <b-form-checkbox value="yes" unchecked-value="no" v-model="notify_fl"
                        ><strong>Notify FL?</strong></b-form-checkbox
                      >
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
              <div class="pt-2 mt-3 text-md-left text-center desktop_margin_top_zero">
                <b-button
                  type="button"
                  variant="success"
                  class="text-uppercase x-md btn-shadow desktop_margin_left_zero"
                  @click="onArrivalTimeSubmit()"
                  >Submit</b-button
                >

                <b-button
                  type="button"
                  variant="danger"
                  class="text-uppercase x-md btn-shadow"
                  @click="hideModel()"
                  >Cancel</b-button
                >
              </div>
            </div>
          </div>
        </b-form>
      </b-modal>
      <b-modal ref="adjust-arrival-modal" title="Adjust User Time" hide-footer>
        <b-form>
          <div class="edit-form-box">
            <div class="profile-repeate form-box-field">
              <b-row>
                <b-col lg="12" md="12">
                  <b-form-group
                    id="input-group-8"
                    label="Modify Time"
                    class="category-radio required"
                  >
                    <b-form-radio v-model="modify_time" name="modify_time" value="start"
                      >Start Time</b-form-radio
                    >
                    <b-form-radio v-model="modify_time" name="modify_time" value="end"
                      >End Time</b-form-radio
                    >
                  </b-form-group>
                </b-col>
                <b-col lg="12" md="12">
                  <b-form-group>
                    <b-form-checkbox v-model="arrival_time_next_day" v-if="modify_time == 'start'"
                      ><strong>Next day arrival?</strong></b-form-checkbox
                    >
                    <b-form-checkbox v-model="arrival_time_next_day" v-else
                      ><strong>Next day completion?</strong></b-form-checkbox
                    >
                  </b-form-group>
                </b-col>
                <b-col lg="12" md="12" v-if="modify_time == 'start'">
                  <b-form-group id="input-group-7" label="Arrival Time" class="required">
                    <vue-timepicker format="hh:mm A" v-model="job_start_time"></vue-timepicker>
                    <span class="input-icon"
                      ><b-img
                        v-bind:src="weburl + '/images/clock-grey-icon02.svg'"
                        alt="Clock Icon"
                      ></b-img
                    ></span>
                  </b-form-group>
                </b-col>
                <b-col lg="12" md="12" v-if="modify_time == 'end'">
                  <b-form-group id="input-group-7" label="Completion Time" class="required">
                    <vue-timepicker format="hh:mm A" v-model="job_end_time"></vue-timepicker>
                    <span class="input-icon"
                      ><b-img
                        v-bind:src="weburl + '/images/clock-grey-icon02.svg'"
                        alt="Clock Icon"
                      ></b-img
                    ></span>
                  </b-form-group>
                </b-col>
                <b-col lg="12" md="12">
                  <div class="permission_listing">
                    <b-form-group>
                      <b-form-checkbox
                        value="yes"
                        unchecked-value="no"
                        v-model="lunch_pay_not_provided"
                        @input="lunch_paid($event)"
                        ><strong>Lunch Break Taken</strong></b-form-checkbox
                      >
                    </b-form-group>
                  </div>
                </b-col>
                <b-col lg="12" md="12" v-if="lunch_pay_not_provided == 'yes'">
                  <b-form-group id="input-group-4" label="Break Hours" class="required">
                    <span class="custom-dropdown">
                      <b-form-select v-model="lunch_pay_hours">
                        <option value="" disabled="true">Please Select Break Hours</option>
                        <option :value="arr" v-for="arr in lunch_hours_arr">{{ arr }}</option>
                      </b-form-select>
                    </span>
                  </b-form-group>
                </b-col>
                <b-col lg="12" md="12">
                  <div class="permission_listing">
                    <b-form-group>
                      <b-form-checkbox value="yes" unchecked-value="no" v-model="notify_fl"
                        ><strong>Notify FL?</strong></b-form-checkbox
                      >
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
              <div class="pt-2 mt-3 text-md-left text-center desktop_margin_top_zero">
                <b-button
                  type="button"
                  variant="success"
                  class="text-uppercase x-md btn-shadow desktop_margin_left_zero"
                  @click="onAdjustTimeSubmit()"
                  >Submit</b-button
                >

                <b-button
                  type="button"
                  variant="danger"
                  class="text-uppercase x-md btn-shadow"
                  @click="hideModel()"
                  >Cancel</b-button
                >
              </div>
            </div>
          </div>
        </b-form>
      </b-modal>
      <b-modal ref="add-mw-modal" title="Add Freelancer" hide-footer>
        <div class="edit-form-box">
          <div class="profile-repeate form-box-field">
            <b-row>
              <b-col lg="12" md="12">
                <b-form-group id="input-group-4" label="Select Freelancer" class="required">
                  <span class="custom-dropdown">
                    <multiselect
                      v-model="form.selected_mw_arr"
                      id="ajax"
                      label="fl_name"
                      track-by="id"
                      placeholder="Type to search freelancer"
                      open-direction="bottom"
                      :options="mwList"
                      :multiple="false"
                      :loading="isLoading"
                      :internal-search="false"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="20"
                      :limit="3"
                      :max-height="600"
                      :show-no-results="false"
                      :hide-selected="true"
                      @search-change="asyncFind"
                      :searchable="true"
                      @input="onFlInput(form.selected_mw_arr)"
                    >
                      <span slot="noResult">No Freelancer Found.</span>
                    </multiselect>
                  </span>
                </b-form-group>
              </b-col>
              <!-- <b-col lg="12" md="12" >
                                <b-form-group id="input-group-8" label="Insurance Opted" class="category-radio required">
                                    <b-form-radio v-model="insurance_opted" name="insurance_opted" value="yes">yes</b-form-radio>
                                    <b-form-radio v-model="insurance_opted" name="insurance_opted" value="no">No</b-form-radio>
                                </b-form-group>
                            </b-col>
                            <b-col lg="12" md="12" v-if="insurance_opted == 'yes'">
                                <b-form-group id="input-group-8" label="Insurance In Future Required?" class="category-radio required">
                                    <b-form-radio v-model="insurance_future_required" name="insurance_future_required" value="yes">Yes</b-form-radio>
                                    <b-form-radio v-model="insurance_future_required" name="insurance_future_required" value="no">No</b-form-radio>
                                </b-form-group>
                            </b-col> -->
            </b-row>
            <div class="pt-2 mt-3 text-md-left text-center">
              <b-button
                type="button"
                variant="success"
                class="text-uppercase x-md btn-shadow"
                @click="submitMw"
                >Submit</b-button
              >
              <!-- <b-button type="button" variant="danger" class="text-uppercase x-md btn-shadow" @click="hideModel()">Cancel</b-button> -->
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal ref="extend-modal" title="Extend Hours" hide-footer>
        <b-form>
          <div class="edit-form-box">
            <!-- <b-alert :show="dismissCountDownExtend" dismissible variant="danger" @dismissed="dismissCountDownExtend=0" @dismiss-count-down="countDownChangedExtend">
                            <span>{{ error_messages_extend }}</span>
                        </b-alert> -->
            <div class="profile-repeate form-box-field">
              <b-row>
                <b-col lg="12" md="12">
                  <b-form-group id="input-group-4" label="Hours to Extend">
                    <span class="custom-dropdown">
                      <b-form-select v-model="form.extended_hours">
                        <option value="" disabled="true">Please Select Hours to Extend</option>
                        <option :value="arr" v-for="arr in working_hours_arr">{{ arr }}</option>
                      </b-form-select>
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" md="12">
                  <!-- <b-form-group id="input-group-4" label="Minutes to Extend">

                                        <span class="custom-dropdown">
                                            <b-form-select v-model="form.extended_min">
                                                <option value="" disabled="true">Please Select Minutes to Extend</option>
                                                <option :value="arr" v-for="arr in working_minutes_arr">{{arr}}</option>
                                            </b-form-select>
                                        </span>
                                    </b-form-group> -->
                  <b-form-group id="input-group-9" label="Minutes to Extend" class="required">
                    <b-form-input
                      id="input-9"
                      maxlength="6"
                      v-model="form.extended_min"
                      type="text"
                      placeholder=""
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" md="12">
                  <b-form-group
                    id="input-group-3"
                    label="Total price rate for Extended Time"
                    class="required"
                  >
                    <span class="custom-dropdown">
                      <b-form-select v-model="form.extension_rate" @change="getCostList">
                        <option value="" disabled="true">Please Select Extension Rate</option>
                        <option :value="cost.per_hour_cost" v-for="cost in costListing">
                          {{ cost.per_hour_cost }}
                        </option>
                      </b-form-select>
                    </span>
                    <!--  <b-form-textarea id="textarea-3" type="textarea" placeholder="Enter Rate" v-model="form.extension_rate"></b-form-textarea> -->
                  </b-form-group>
                </b-col>
                <b-col lg="12" md="12">
                  <div class="permission_listing">
                    <b-form-group>
                      <b-form-checkbox
                        value="yes"
                        unchecked-value="no"
                        v-model="form.extra_break_taken"
                        ><strong>Extra Break Taken?</strong></b-form-checkbox
                      >
                    </b-form-group>
                  </div>
                </b-col>
                <b-col lg="12" md="12" v-if="form.extra_break_taken == 'yes'">
                  <b-form-group id="input-group-4" label="Extra Break Hours" class="required">
                    <span class="custom-dropdown">
                      <b-form-select v-model="form.extra_break_hrs">
                        <option value="" disabled="true">Please Select Extra Break Hours</option>
                        <option :value="arr" v-for="arr in lunch_hours_arr">{{ arr }}</option>
                      </b-form-select>
                    </span>
                  </b-form-group>
                </b-col>
                <b-col lg="12" md="12">
                  <div class="permission_listing">
                    <b-form-group>
                      <b-form-checkbox value="yes" unchecked-value="no" v-model="notify_fl"
                        ><strong>Notify FL?</strong></b-form-checkbox
                      >
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
              <div class="pt-2 mt-3 text-md-left text-center desktop_margin_top_zero">
                <b-button
                  type="button"
                  @click="onSubmitExtend"
                  variant="danger"
                  class="text-uppercase x-md btn-shadow desktop_margin_left_zero"
                  >Submit</b-button
                >
              </div>
            </div>
          </div>
        </b-form>
      </b-modal>
      <b-modal ref="suspend-modal" title="Stop Work" hide-footer>
        <b-form method="post">
          <div class="edit-form-box">
            <!-- <b-alert :show="dismissCountDownSuspend" dismissible variant="danger" @dismissed="dismissCountDownSuspend=0" @dismiss-count-down="countDownChangedSuspend">
                            <span>{{ error_messages_suspend }}</span>
                        </b-alert> -->
            <div class="profile-repeate form-box-field">
              <b-row>
                <b-col lg="12" md="12">
                  <b-form-group id="input-group-3" label="Stoppage Reason">
                    <b-form-textarea
                      id="textarea-3"
                      type="textarea"
                      placeholder="Enter Reason"
                      v-model="form.suspention_reason"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
                <b-col lg="6" md="6">
                  <b-form-group id="input-group-7" label="Start Date" class="required">
                    <datepicker v-model="job_start_date" :format="customFormatterTwo"></datepicker>
                    <span class="input-icon"
                      ><b-img
                        :src="weburl + '/images/calendar-grey-icon02.svg'"
                        alt="Calander Icon"
                      ></b-img
                    ></span>
                  </b-form-group>
                </b-col>
                <b-col lg="6" md="6">
                  <b-form-group id="input-group-7" label="Start Time">
                    <vue-timepicker format="hh:mm A" v-model="job_start_time"></vue-timepicker>
                    <span class="input-icon"
                      ><b-img
                        v-bind:src="weburl + '/images/clock-grey-icon02.svg'"
                        alt="Clock Icon"
                      ></b-img
                    ></span>
                  </b-form-group>
                </b-col>
                <b-col lg="6" md="6">
                  <b-form-group id="input-group-7" label="End Date" class="required">
                    <datepicker v-model="job_end_date" :format="customFormatterTwo"></datepicker>
                    <span class="input-icon"
                      ><b-img
                        :src="weburl + '/images/calendar-grey-icon02.svg'"
                        alt="Calander Icon"
                      ></b-img
                    ></span>
                  </b-form-group>
                </b-col>
                <b-col lg="6" md="6">
                  <b-form-group id="input-group-7" label="End Time">
                    <vue-timepicker format="hh:mm A" v-model="job_end_time"></vue-timepicker>
                    <span class="input-icon"
                      ><b-img
                        v-bind:src="weburl + '/images/clock-grey-icon02.svg'"
                        alt="Clock Icon"
                      ></b-img
                    ></span>
                  </b-form-group>
                </b-col>
                <b-col lg="12" md="12" v-if="lunch_break == 'yes'">
                  <div class="permission_listing">
                    <b-form-group>
                      <b-form-checkbox
                        value="yes"
                        unchecked-value="no"
                        v-model="lunch_pay_not_provided"
                        ><strong>Lunch Break Taken?</strong></b-form-checkbox
                      >
                    </b-form-group>
                  </div>
                </b-col>
                <b-col lg="12" md="12" v-if="lunch_pay_not_provided == 'yes'">
                  <b-form-group id="input-group-4" label="No. Of Hours" class="required">
                    <span class="custom-dropdown">
                      <b-form-select v-model="lunch_pay_hours">
                        <option value="" disabled="true">Please Select No. of Hours</option>
                        <option :value="arr" v-for="arr in lunch_hours_arr">{{ arr }}</option>
                      </b-form-select>
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="pt-2 mt-3 text-md-left text-center desktop_margin_top_zero">
                <b-button
                  type="button"
                  :disabled="disabledSuspend"
                  @click="onSubmitSuspend('submit')"
                  variant="danger"
                  class="text-uppercase x-md btn-shadow desktop_margin_left_zero"
                  >Submit</b-button
                >
                <b-button
                  type="button"
                  :disabled="disabledSuspendNp"
                  @click="onSubmitSuspend('no_pay')"
                  variant="info"
                  class="text-uppercase x-md btn-shadow"
                  >Stop work with no pay</b-button
                >
              </div>
            </div>
          </div>
        </b-form>
      </b-modal>
      <b-modal
        @hidden="hideModal"
        hide-footer
        hide-header
        id="add-note-modal-prevent-closing"
        ref="share-modal"
        centered
      >
        <div class="edit-task">
          <div class="popup-header">
            <h2 class="">Sharable Url</h2>
            <div class="mt-2">
              <div class="addUserForm">
                <div class="editForm mw-100 pl-0">
                  <b-row>
                    <b-col lg="12" md="12">
                      <b-form-group id="input-group-5" label="Job Url">
                        <b-form-input
                          id="copy-url"
                          type="text"
                          placeholder=""
                          v-model="job_url"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <div slot="footer" class="form-btn">
                    <b-button
                      @click="copyURL()"
                      variant="success desktop_margin_left_zero share-job-class"
                      >Copy Url</b-button
                    >
                    <b-button @click="hideModel()" variant="light">Cancel</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal
        @hidden="hideModal"
        hide-footer
        hide-header
        id="add-note-modal-prevent-closing"
        ref="share-company-modal"
        centered
      >
        <div class="edit-task">
          <div class="popup-header">
            <h2 class="">Sharable Company's Job URL</h2>
            <div class="mt-2">
              <div class="addUserForm">
                <div class="editForm mw-100 pl-0">
                  <b-row>
                    <b-col lg="12" md="12">
                      <b-form-group id="input-group-5" label="Company's Job Url">
                        <b-form-input
                          id="copy-company-url"
                          type="text"
                          placeholder=""
                          v-model="company_url"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <div slot="footer" class="form-btn">
                    <b-button
                      @click="copyURLComp()"
                      variant="success desktop_margin_left_zero share-comp-class"
                      >Copy Url</b-button
                    >
                    <b-button @click="hideModel()" variant="light">Cancel</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal ref="not-suitable-modal" title="Not suitable remarks" hide-footer>
        <b-form method="post">
          <div class="edit-form-box">
            <div class="profile-repeate form-box-field">
              <b-row>
                <b-col lg="12" md="12">
                  <b-form-group id="input-group-3" label="Reason">
                    <b-form-textarea
                      id="textarea-3"
                      type="textarea"
                      placeholder="Enter Reason"
                      v-model="not_suitable_reason"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="pt-2 mt-3 text-md-left text-center">
                <b-button
                  type="button"
                  @click="notSuitable()"
                  variant="danger"
                  class="text-uppercase x-md btn-shadow"
                  >Submit</b-button
                >
              </div>
            </div>
          </div>
        </b-form>
      </b-modal>
      <b-modal ref="remarks-modal" title="Suspension Remarks" hide-footer>
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12">
              <b-form-group id="input-group-3" label="Remarks" class="required">
                <b-form-textarea
                  id="textarea-3"
                  type="textarea"
                  placeholder="Enter Remarks"
                  v-model="remarks"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="pt-2 mt-3 text-md-left text-center">
            <b-button
              type="button"
              @click="addUserJobsRemark()"
              variant="success"
              class="text-uppercase x-md btn-shadow"
              >Submit</b-button
            >
          </div>
        </div>
      </b-modal>
      <b-modal ref="modify-user-time-modal" title="Modify User's Job Time" hide-footer>
        <b-form>
          <div class="edit-form-box">
            <div class="profile-repeate form-box-field">
              <b-row>
                <b-col lg="6" md="6">
                  <b-form-group id="input-group-7" label="Job Start Date" class="required">
                    <datepicker v-model="job_start_date" :format="customFormatterTwo"></datepicker>
                    <span class="input-icon"
                      ><b-img
                        :src="weburl + '/images/calendar-grey-icon02.svg'"
                        alt="Calander Icon"
                      ></b-img
                    ></span>
                  </b-form-group>
                </b-col>
                <b-col lg="6" md="6">
                  <b-form-group id="input-group-7" label="Job Start Time">
                    <vue-timepicker format="hh:mm A" v-model="job_start_time"></vue-timepicker>
                    <span class="input-icon"
                      ><b-img
                        v-bind:src="weburl + '/images/clock-grey-icon02.svg'"
                        alt="Clock Icon"
                      ></b-img
                    ></span>
                  </b-form-group>
                </b-col>
                <b-col lg="6" md="6">
                  <b-form-group id="input-group-7" label="Job End Date" class="required">
                    <datepicker v-model="job_end_date" :format="customFormatterTwo"></datepicker>
                    <span class="input-icon"
                      ><b-img
                        :src="weburl + '/images/calendar-grey-icon02.svg'"
                        alt="Calander Icon"
                      ></b-img
                    ></span>
                  </b-form-group>
                </b-col>
                <b-col lg="6" md="6">
                  <b-form-group id="input-group-7" label="Job End Time (Exclude Extension Hours)">
                    <vue-timepicker format="hh:mm A" v-model="job_end_time"></vue-timepicker>
                    <span class="input-icon"
                      ><b-img
                        v-bind:src="weburl + '/images/clock-grey-icon02.svg'"
                        alt="Clock Icon"
                      ></b-img
                    ></span>
                  </b-form-group>
                </b-col>
                <b-col lg="12" md="12">
                  <div class="permission_listing">
                    <b-form-group>
                      <b-form-checkbox
                        value="yes"
                        unchecked-value="no"
                        v-model="lunch_pay_not_provided"
                        ><strong>Lunch Break Taken</strong></b-form-checkbox
                      >
                    </b-form-group>
                  </div>
                </b-col>
                <b-col lg="12" md="12" v-if="lunch_pay_not_provided == 'yes'">
                  <b-form-group id="input-group-4" label="Break Hours" class="required">
                    <span class="custom-dropdown">
                      <b-form-select v-model="lunch_pay_hours">
                        <option value="" disabled="true">Please Select Break Hours</option>
                        <option :value="arr" v-for="arr in lunch_hours_arr">{{ arr }}</option>
                      </b-form-select>
                    </span>
                  </b-form-group>
                </b-col>
                <b-col lg="12" md="12">
                  <div class="permission_listing">
                    <b-form-group>
                      <b-form-checkbox
                        value="yes"
                        unchecked-value="no"
                        v-model="is_extension"
                        @input="isExtension($event)"
                        ><strong>Extension</strong></b-form-checkbox
                      >
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
              <b-row v-if="is_extension == 'yes'">
                <b-col lg="4" md="4">
                  <b-form-group id="input-group-4" label="Select No. Of Hours" class="required">
                    <span class="custom-dropdown">
                      <b-form-select v-model="extension_hours">
                        <option value="" disabled="true">No. of Hours</option>
                        <option :value="arr" v-for="arr in working_hours_arr">{{ arr }}</option>
                      </b-form-select>
                    </span>
                  </b-form-group>
                </b-col>
                <b-col lg="4" md="4">
                  <!-- <b-form-group id="input-group-4" label="Select No. of Minutes" class="required">

                                        <span class="custom-dropdown">
                                            <b-form-select v-model="extension_min">
                                                <option value="" disabled="true">No. of Minutes</option>
                                                <option :value="arr" v-for="arr in working_minutes_arr">{{arr}}</option>
                                            </b-form-select>
                                        </span>
                                    </b-form-group> -->
                  <b-form-group id="input-group-9" label="Select No. of Minutes" class="required">
                    <b-form-input
                      id="input-9"
                      maxlength="6"
                      v-model="extension_min"
                      type="text"
                      placeholder=""
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg="4" md="4">
                  <b-form-group id="input-group-4" label="Select Per Hour Cost" class="required">
                    <span class="custom-dropdown">
                      <b-form-select v-model="extension_rate" @change="getCostList">
                        <option value="" disabled="true">Per Hour Cost</option>
                        <option :value="cost.per_hour_cost" v-for="cost in costListing">
                          {{ cost.per_hour_cost }}
                        </option>
                      </b-form-select>
                    </span>
                  </b-form-group>
                </b-col>
                <b-col lg="12" md="12">
                  <div class="permission_listing">
                    <b-form-group>
                      <b-form-checkbox value="yes" unchecked-value="no" v-model="extra_break_taken"
                        ><strong>Extra Break Taken?</strong></b-form-checkbox
                      >
                    </b-form-group>
                  </div>
                </b-col>
                <b-col lg="12" md="12" v-if="extra_break_taken == 'yes'">
                  <b-form-group id="input-group-4" label="Extra Break Hours" class="required">
                    <span class="custom-dropdown">
                      <b-form-select v-model="extra_break_hrs">
                        <option value="" disabled="true">Please Select Extra Break Hours</option>
                        <option :value="arr" v-for="arr in lunch_hours_arr">{{ arr }}</option>
                      </b-form-select>
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- <b-col lg="12" md="12">
                                    <div class="permission_listing">
                                        <b-form-group>
                                            <b-form-checkbox value="yes" unchecked-value="no" v-model="with_insurance"><strong>With Insurance?</strong></b-form-checkbox>
                                        </b-form-group>
                                    </div>
                                </b-col>

                                <b-col lg="12" md="12" v-if="with_insurance == 'yes'">
                                    <b-form-group id="input-group-8" label="Insurance Opted" class="category-radio required">
                                        <b-form-radio v-model="insurance_opted" name="insurance_opted" value="yes">yes</b-form-radio>
                                        <b-form-radio v-model="insurance_opted" name="insurance_opted" value="no">No</b-form-radio>
                                    </b-form-group>
                                </b-col>
                                <b-col lg="12" md="12" v-if="insurance_opted == 'yes'">
                                    <b-form-group id="input-group-8" label="Insurance In Future Required?" class="category-radio required">
                                        <b-form-radio v-model="insurance_future_required" name="insurance_future_required" value="yes">Yes</b-form-radio>
                                        <b-form-radio v-model="insurance_future_required" name="insurance_future_required" value="no">No</b-form-radio>
                                    </b-form-group>
                                </b-col> -->
                <b-col lg="12" md="12">
                  <div class="permission_listing">
                    <b-form-group>
                      <b-form-checkbox value="yes" unchecked-value="no" v-model="notify_fl"
                        ><strong>Notify FL?</strong></b-form-checkbox
                      >
                    </b-form-group>
                  </div>
                </b-col>
                <b-col lg="12" md="12">
                  <b-form-group id="input-group-3" label="Remarks" class="required">
                    <b-form-textarea
                      id="textarea-3"
                      type="textarea"
                      placeholder="Enter Remarks"
                      v-model="user_jobs_remark"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="pt-2 mt-3 text-md-left text-center desktop_margin_top_zero">
                <b-button
                  type="button"
                  variant="success"
                  class="text-uppercase x-md btn-shadow desktop_margin_left_zero"
                  @click="onTimeSubmit()"
                  >Submit</b-button
                >

                <b-button
                  type="button"
                  variant="danger"
                  class="text-uppercase x-md btn-shadow"
                  @click="hideModel()"
                  >Cancel</b-button
                >
              </div>
            </div>
          </div>
        </b-form>
      </b-modal>
      <b-modal ref="remarks1-modal" title="Fulltime Remarks" hide-footer>
        <!-- <b-form> -->
        <div class="edit-form-box">
          <div class="profile-repeate form-box-field">
            <b-row>
              <b-col lg="12" md="12">
                <div class="form-map-box">
                  <b-col lg="6" md="6">
                    <p>{{ fulltime_remarks }}</p> </b-col
                  ><br />
                  <div slot="footer" class="form-btn">
                    <b-button @click="hideModel()" variant="dark">Close</b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <!-- </b-form> -->
      </b-modal>
      <b-modal
        @hidden="hideModal"
        hide-footer
        hide-header
        id="add-note-modal-prevent-closing"
        ref="share-modal"
        centered
      >
        <div class="edit-task">
          <div class="popup-header">
            <h2 class="">Sharable Url</h2>
            <div class="mt-2">
              <div class="addUserForm">
                <div class="editForm mw-100 pl-0">
                  <b-row>
                    <b-col lg="8" md="8">
                      <b-form-group id="input-group-5" label="Job Url">
                        <b-form-input
                          id="copy-url"
                          type="text"
                          placeholder=""
                          v-model="job_url"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col lg="4" md="4">
                      <b-button
                        @click="copyURL()"
                        variant="success desktop_margin_left_zero share-job-class"
                        style="margin-top: 24px"
                        >Copy Url</b-button
                      >
                    </b-col>

                    <b-col lg="8" md="8">
                      <b-form-group id="input-group-5" label="Company's Job Url">
                        <b-form-input
                          id="copy-company-url"
                          type="text"
                          placeholder=""
                          v-model="company_url"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col lg="4" md="4">
                      <b-button
                        @click="copyURLComp()"
                        variant="success desktop_margin_left_zero share-comp-class"
                        style="margin-top: 24px"
                        >Copy Url</b-button
                      >
                    </b-col>
                  </b-row>

                  <div slot="footer" class="form-btn">
                    <b-button @click="hideModel()" variant="light">Cancel</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal ref="info-modal" title="More Info" hide-footer>
        <!-- <b-form> -->
        <div class="edit-form-box">
          <div class="profile-repeate form-box-field">
            <b-row>
              <b-col lg="12" md="12">
                <div class="form-map-box">
                  <b-col lg="12" md="12">
                    <table>
                      <tbody>
                        <tr v-for="(key, val) in moreInfo" :key="key">
                          <td>
                            <strong>{{ val | removeUnderscore }}</strong>
                          </td>
                          <td>&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp;</td>
                          <td>
                            {{ key }}
                          </td>
                        </tr>
                      </tbody>
                    </table> </b-col
                  ><br />
                  <div slot="footer" class="form-btn">
                    <b-button @click="hideModel()" variant="dark">Close</b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <!-- </b-form> -->
      </b-modal>
      <b-modal ref="user-jobs-remark-modal" title="Remarks" hide-footer>
        <b-form method="post">
          <div class="edit-form-box">
            <div class="profile-repeate form-box-field">
              <b-row>
                <b-col lg="12" md="12">
                  <b-form-group id="input-group-3" label="Remarks" class="required">
                    <b-form-textarea
                      id="textarea-3"
                      type="textarea"
                      placeholder="Enter Remarks"
                      v-model="user_jobs_remark"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="pt-2 mt-3 text-md-left text-center">
                <b-button
                  type="button"
                  @click="addUserJobsRemark()"
                  variant="success"
                  class="text-uppercase x-md btn-shadow"
                  >Submit</b-button
                >
              </div>
            </div>
          </div>
        </b-form>
      </b-modal>
      <b-modal ref="sr-modal" :title="generateTitle" hide-footer>
        <!-- <b-form> -->
        <div class="edit-form-box">
          <div class="profile-repeate form-box-field">
            <b-row>
              <b-col lg="12" md="12">
                <div class="form-map-box">
                  <b-col lg="6" md="6" v-if="regen == 'generate_sr'">
                    <b-form-group>
                      <b-form-checkbox v-model="generateSr" disabled
                        ><strong>Generate SR</strong></b-form-checkbox
                      >
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" md="6">
                    <b-form-group>
                      <b-form-checkbox
                        v-model="generateTs"
                        :disabled="regen != 'generate_sr' ? true : false"
                        ><strong>Generate TS?</strong></b-form-checkbox
                      >
                    </b-form-group>
                  </b-col>
                  <b-form-group
                    id="input-group-8"
                    label="With Email"
                    class="category-radio required"
                  >
                    <b-form-radio v-model="withNoEmail" name="withNoEmail" value="yes"
                      >Yes</b-form-radio
                    >
                    <b-form-radio v-model="withNoEmail" name="withNoEmail" value="no"
                      >No</b-form-radio
                    >
                  </b-form-group>

                  <div slot="footer" class="form-btn">
                    <b-button
                      type="submit"
                      variant="success"
                      v-if="regen == 'generate_ts'"
                      @click="generateTS()"
                      >Submit</b-button
                    >
                    <b-button type="submit" variant="success" v-else @click="generateSR()"
                      >Submit</b-button
                    >

                    <b-button @click="hideModel()" variant="light">Cancel</b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <!-- </b-form> -->
      </b-modal>
      <b-modal ref="add-break-modal" title="Edit Break Hours" hide-footer>
        <b-form>
          <div class="edit-form-box">
            <div class="profile-repeate form-box-field">
              <b-row>
                <b-col lg="12" md="12">
                  <!-- <b-form-group id="input-group-8" label="Select Type" class="category-radio required" >
                                        <b-form-radio v-model="break_type" name="break_type" value="add">{{break_type == 'remove' ? 'Update' : 'Add'}}</b-form-radio>
                                        <b-form-radio v-model="break_type" name="break_type" value="remove">Remove</b-form-radio>
                                    </b-form-group> -->
                  <b-form-group id="input-group-4" label="Break Hours" class="required">
                    <span class="custom-dropdown">
                      <b-form-select v-model="lunch_pay_hours">
                        <option value="" disabled="true">Please Select Break Hours</option>
                        <option value="0">0</option>
                        <option :value="arr" v-for="arr in lunch_hours_arr">{{ arr }}</option>
                      </b-form-select>
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="pt-2 mt-3 text-md-left text-center desktop_margin_top_zero">
                <b-button
                  type="button"
                  variant="success"
                  class="text-uppercase x-md btn-shadow desktop_margin_left_zero"
                  @click="onBreakSubmit()"
                  >Submit</b-button
                >

                <b-button
                  type="button"
                  variant="danger"
                  class="text-uppercase x-md btn-shadow"
                  @click="hideModel()"
                  >Cancel</b-button
                >
              </div>
            </div>
          </div>
        </b-form>
      </b-modal>
      <b-modal
        v-if="screenWidth <= 999"
        id="modal-job-detail-admin"
        ref="job-detail-mobile-action-model"
        title=""
        content-class="common_model_header common_model_title"
        hide-footer
        modal-class="background_custom_class common_mobile_model_classes"
      >
        <div v-if="selectedTab == 'Selected'">
          <b-dropdown-item
            v-if="designation == 'External Agent'"
            :to="{
              name: 'ViewApplicantProfileAdmin',
              params: { id: encrypt(modelitems.user_id), type: 'selected' },
            }"
            @click="viewFl(encrypt(modelitems.user_id), 'selected')"
            target="_blank"
            >VIEW PROFILE
          </b-dropdown-item>
          <b-dropdown-item @click="viewFl(encrypt(modelitems.user_id), 'selected')" target="_blank"
            >VIEW PROFILE
          </b-dropdown-item>
          <b-dropdown-item
            v-if="modelitems.user_job_remarks != null && show('update remarks')"
            @click="openRemarksModel(modelitems.user_id, 'update', modelitems.remarks)"
            >UPDATE REMARKS
          </b-dropdown-item>
          <b-dropdown-item
            v-else-if="modelitems.user_job_remarks == null && show('add remarks')"
            @click="openRemarksModel(modelitems.user_id, 'add', modelitems.remarks)"
            >ADD REMARKS
          </b-dropdown-item>
          <b-dropdown-item v-if="show('more info')" @click="viewMoreInfo(modelitems.user_id)"
            >MORE INFO
          </b-dropdown-item>
          <b-dropdown-item
            v-if="
              modelitems.job_status == 'upcoming' &&
              modelitems.acknowledged == 'no' &&
              show('acknowledge fl')
            "
            @click="acknowledgeApplicant(modelitems.id)"
            >ACKNOWLEDGE
          </b-dropdown-item>
          <b-dropdown-item
            @click="openArrivalModel(modelitems.user_id, 'arrival', false, modelitems.job_id)"
            v-if="
              modelitems.original_start_time != null &&
              modelitems.job_status == 'in-progress' &&
              show('adjust arrival time')
            "
            >ADJUST ARRIVAL TIME
          </b-dropdown-item>
          <b-dropdown-item
            v-if="
              modelitems.marked_by != null &&
              (modelitems.job_status != 'suspended' || modelitems.job_status != 'completed') &&
              modelitems.lunch_pay_not_provided == 'yes' &&
              show('add lunch break')
            "
            @click="openModel(modelitems.user_id, 'add_break', modelitems.break_hour_taken)"
            >ADD/REMOVE BREAK
          </b-dropdown-item>
          <b-dropdown-item
            @click="revertCompletion(modelitems.user_id, 'arrival')"
            v-if="
              (modelitems.job_status == 'completed' || modelitems.job_status == 'suspended') &&
              show('revert completion')
            "
            >REVERT COMPLETION
          </b-dropdown-item>
          <b-dropdown-item
            v-if="
              modelitems.job_status != 'cancelled' &&
              modelitems.confirm_job_completion_by == null &&
              modelitems.job_status != 'suspended' &&
              show('cancel fl')
            "
            @click="cancelApplicant(modelitems.user_id)"
            >CANCEL
          </b-dropdown-item>
          <b-dropdown-item
            v-if="
              modelitems.marked_by != null &&
              modelitems.job_status != 'suspended' &&
              modelitems.original_end_time == null &&
              modelitems.extended_hours == null &&
              modelitems.rated_by_supervisor == null &&
              show('extend hour')
            "
            @click="
              extendHour(
                modelitems.user_id,
                modelitems.hourly_cost,
                'extension',
                modelitems.extended_hours,
                modelitems.extension_rate,
                modelitems.extra_break_hrs,
                modelitems.extra_break_taken
              )
            "
            >EXTEND HOUR
          </b-dropdown-item>
          <b-dropdown-item
            v-if="
              modelitems.marked_by != null &&
              modelitems.job_status != 'suspended' &&
              modelitems.original_end_time == null &&
              modelitems.extended_hours != null &&
              modelitems.rated_by_supervisor == null &&
              show('adjust extension hours')
            "
            @click="
              extendHour(
                modelitems.user_id,
                modelitems.hourly_cost,
                'adjust extension',
                modelitems.extended_hours,
                modelitems.extension_rate,
                modelitems.extra_break_hrs,
                modelitems.extra_break_taken
              )
            "
            >ADJUST EXTENSION HOUR
          </b-dropdown-item>
          <b-dropdown-item
            v-if="
              modelitems.extended_hours != null &&
              modelitems.job_status != 'suspended' &&
              modelitems.original_end_time == null &&
              show('remove extension')
            "
            @click="removeExtension(modelitems.user_id, modelitems.job_id)"
            >REMOVE EXTENSION
          </b-dropdown-item>
          <b-dropdown-item
            v-if="
              modelitems.original_start_time != null &&
              modelitems.extended_hours == null &&
              modelitems.job_status == 'in-progress' &&
              show('stop work')
            "
            @click="openModel(modelitems.user_id, 'stop', modelitems.start_time)"
            >STOP WORK
          </b-dropdown-item>
          <b-dropdown-item
            v-if="
              (modelitems.job_status == 'completed' || modelitems.job_status == 'suspended') &&
              show('modify fl time')
            "
            @click="openModel(modelitems.user_id, 'modify', '')"
            >MODIFY USER TIME
          </b-dropdown-item>
          <b-dropdown-item
            v-if="modelitems.job_status == 'suspended' && show('view suspension reason')"
            @click="viewSuspensionReason(modelitems.user_id, modelitems.suspention_reason)"
            >MODIFY SUSPENSION REASON
          </b-dropdown-item>
          <b-dropdown-item
            v-if="show('generate ts')"
            @click="openModel(modelitems.user_id, 'generate_ts')"
            >REGENERATE TS
          </b-dropdown-item>
        </div>
        <div v-if="selectedTab == 'All Applicant'">
          <b-dropdown-item
            v-if="designation == 'External Agent'"
            :to="{
              name: 'ViewApplicantProfileAdmin',
              params: { id: encrypt(modelitems.user_id), type: 'selected' },
            }"
            target="_blank"
            >VIEW PROFILE
          </b-dropdown-item>
          <b-dropdown-item
            v-else
            @click="viewFl(encrypt(modelitems.user_id), 'all')"
            target="_blank"
            >VIEW PROFILE
          </b-dropdown-item>
          <b-dropdown-item v-if="show('more info')" @click="viewMoreInfo(modelitems.user_id)"
            >MORE INFO
          </b-dropdown-item>

          <b-dropdown-item
            v-if="
              modelitems.marked_by == null &&
              modelitems.job_status != 'cancelled' &&
              modelitems.withdraw_application == 'no' &&
              show('withdraw fl')
            "
            @click="withdrawApplicant(modelitems.user_id, modelitems.job_id)"
            >WITHDRAW JOB
          </b-dropdown-item>

          <b-dropdown-item
            v-if="
              modelitems.marked_by == null &&
              modelitems.job_status != 'cancelled' &&
              modelitems.withdraw_application == 'yes' &&
              show('add back')
            "
            @click="addBack(modelitems.user_id)"
            >ADD BACK
          </b-dropdown-item>
        </div>
        <div v-if="selectedTab == 'Cancelled'">
          <b-dropdown-item
            v-if="designation == 'External Agent'"
            :to="{
              name: 'ViewApplicantProfileAdmin',
              params: { id: encrypt(modelitems.user_id), type: 'selected' },
            }"
            target="_blank"
            >VIEW PROFILE
          </b-dropdown-item>
          <b-dropdown-item
            v-else
            @click="viewFl(encrypt(modelitems.user_id), 'cancelled')"
            target="_blank"
            >VIEW PROFILE
          </b-dropdown-item>
          <b-dropdown-item v-if="show('more info')" @click="viewMoreInfo(modelitems.user_id)"
            >MORE INFO
          </b-dropdown-item>
          <b-dropdown-item
            v-if="show('revive fl')"
            @click="openArrivalModel(modelitems.user_id, 'revive', false)"
            >REVIVE USER
          </b-dropdown-item>
        </div>
        <div v-if="selectedTab == 'Applicant Withdrawn'">
          <b-dropdown-item
            v-if="designation == 'External Agent'"
            :to="{
              name: 'ViewApplicantProfileAdmin',
              params: { id: encrypt(odelitems.user_id), type: 'selected' },
            }"
            target="_blank"
            >VIEW PROFILE
          </b-dropdown-item>
          <b-dropdown-item
            v-else
            @click="viewFl(encrypt(modelitems.user_id), 'all')"
            target="_blank"
            >VIEW PROFILE
          </b-dropdown-item>
          <b-dropdown-item v-if="show('more info')" @click="viewMoreInfo(modelitems.user_id)"
            >MORE INFO
          </b-dropdown-item>

          <b-dropdown-item
            v-if="modelitems.withdraw_application == 'yes' && show('add back')"
            @click="addBack(modelitems.user_id)"
            >ADD BACK
          </b-dropdown-item>
        </div>
        <div v-if="selectedTab == 'System Withdrawn'">
          <b-dropdown-item
            v-if="designation == 'External Agent'"
            :to="{
              name: 'ViewApplicantProfileAdmin',
              params: { id: encrypt(odelitems.user_id), type: 'selected' },
            }"
            target="_blank"
            >VIEW PROFILE
          </b-dropdown-item>
          <b-dropdown-item
            v-else
            @click="viewFl(encrypt(modelitems.user_id), 'all')"
            target="_blank"
            >VIEW PROFILE
          </b-dropdown-item>
          <b-dropdown-item v-if="show('more info')" @click="viewMoreInfo(modelitems.user_id)"
            >MORE INFO
          </b-dropdown-item>
          <b-dropdown-item
            v-if="modelitems.withdraw_application == 'yes' && show('add back')"
            @click="addBack(modelitems.user_id)"
            >ADD BACK
          </b-dropdown-item>
        </div>
      </b-modal>

      <b-modal ref="job-cancel-modal" title="Cancel Job" hide-footer>
        <b-form @submit="onSubmitCancelJob">
          <div class="edit-form-box">
            <!-- <b-alert :show="dismissCountDownSuspend" dismissible variant="danger" @dismissed="dismissCountDownSuspend=0" @dismiss-count-down="countDownChangedSuspend">
                            <span>{{ error_messages_suspend }}</span>
                        </b-alert> -->
            <div class="profile-repeate form-box-field">
              <b-row>
                <b-col lg="12" md="12">
                  <b-form-group id="input-group-3" label="Cancellation Reason">
                    <b-form-textarea
                      id="textarea-3"
                      type="textarea"
                      placeholder="Enter Reason"
                      v-model="form.job_cancellation_reason"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="pt-2 mt-3 text-md-left text-center">
                <b-button type="submit" variant="danger" class="text-uppercase x-md btn-shadow"
                  >Submit</b-button
                >
              </div>
            </div>
          </div>
        </b-form>
      </b-modal>

      <b-modal id="pdf-modal" title="PDF" hide-footer>
        <iframe :src="link"></iframe>
      </b-modal>
      <b-modal
        @hidden="resetModal"
        hide-footer
        hide-header
        id="add-note-modal-prevent-closing"
        ref="add-note-modal"
        centered
      >
        <div class="edit-task">
          <b-form @submit.prevent="addNoteFormSubmit" _method="post">
            <div class="popup-header">
              <h2 class="">Select Supervisor</h2>
              <div class="mt-2">
                <div class="addUserForm">
                  <div class="editForm mw-100 pl-0">
                    <b-row>
                      <b-col lg="12" md="12">
                        <b-form-group id="input-group-8" label="All Supervisors" class="required">
                          <multiselect
                            v-model="supervisorName"
                            id="ajax"
                            :custom-label="customLabel"
                            track-by="id"
                            placeholder="Type to search supervisor name"
                            open-direction="bottom"
                            :options="selectedSupervisor"
                            :searchable="true"
                            :clear-on-select="true"
                            :close-on-select="true"
                            :allow-empty="true"
                            @input=""
                          >
                            <span slot="noResult">No Supervisor Found.</span>
                          </multiselect>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <div slot="footer" class="form-btn">
                      <b-button type="submit" variant="success desktop_margin_left_zero"
                        >Submit</b-button
                      >
                      <b-button @click="hideModal()" variant="light">Cancel</b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </b-modal>
      <b-modal
        @hidden="resetModal"
        hide-footer
        hide-header
        size="md"
        id="qr-code-modal"
        ref="qr-code-modal"
        centered
      >
        <div class="edit-task">
          <div class="d-block text-center">
            <h2>
              <b>{{ qr_title }}</b>
            </h2>
            <br />
            <VueQRCodeComponent class="qr-class" :text="qrcode"></VueQRCodeComponent>
            <div class="mt-3">
              <b-button @click="downloadQR()" variant="success">Download</b-button>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal
        @hidden="hideModal"
        hide-footer
        hide-header
        id="add-note-modal-prevent-closing"
        ref="broadcast-modal"
        centered
      >
        <div class="edit-task">
          <b-form @submit.prevent="broadcastFormSubmit" _method="post">
            <div class="popup-header">
              <h2 class="">Broadcast Notification</h2>
              <div class="mt-2">
                <div class="addUserForm">
                  <div class="editForm mw-100 pl-0">
                    <b-row>
                      <b-col lg="12" md="12">
                        <b-form-group id="input-group-5" label="Title" class="required">
                          <b-form-input
                            id="input-1"
                            type="text"
                            placeholder=""
                            v-model="notif_title"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>

                      <b-col lg="12" md="12">
                        <b-form-group id="input-group-5" label="Description" class="required">
                          <b-form-textarea
                            id="input-5"
                            type="text"
                            placeholder=""
                            v-model="notif_description"
                          ></b-form-textarea>
                        </b-form-group>
                      </b-col>
                      <b-col lg="12" md="12">
                        <b-form-group id="input-group-5" label="Scheduled for Date">
                          <b-form-input
                            id="input-1"
                            type="date"
                            placeholder=""
                            v-model="broadcast_date"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col lg="12" md="12">
                        <b-form-group id="input-group-5" label="Scheduled for Time">
                          <b-form-input
                            id="input-1"
                            type="time"
                            placeholder=""
                            v-model="broadcast_time"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <div slot="footer" class="form-btn">
                      <b-button type="submit" variant="success desktop_margin_left_zero"
                        >Submit</b-button
                      >
                      <b-button @click="hideModal()" variant="light">Cancel</b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </b-modal>

      <b-modal
        @hidden="hideModal"
        hide-footer
        hide-header
        id="add-note-modal-prevent-closing"
        ref="share-modal"
        centered
      >
        <div class="edit-task">
          <div class="popup-header">
            <h2 class="">Sharable Url</h2>
            <div class="mt-2">
              <div class="addUserForm">
                <div class="editForm mw-100 pl-0">
                  <b-row>
                    <b-col lg="8" md="8">
                      <b-form-group id="input-group-5" label="Job Url">
                        <b-form-input
                          id="copy-url"
                          type="text"
                          placeholder=""
                          v-model="job_url"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col lg="4" md="4">
                      <b-button
                        @click="copyURL()"
                        variant="success desktop_margin_left_zero share-job-class"
                        style="margin-top: 24px"
                        >Copy Url</b-button
                      >
                    </b-col>

                    <b-col lg="8" md="8">
                      <b-form-group id="input-group-5" label="Company's Job Url">
                        <b-form-input
                          id="copy-company-url"
                          type="text"
                          placeholder=""
                          v-model="company_url"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col lg="4" md="4">
                      <b-button
                        @click="copyURLComp()"
                        variant="success desktop_margin_left_zero share-comp-class"
                        style="margin-top: 24px"
                        >Copy Url</b-button
                      >
                    </b-col>
                  </b-row>

                  <div slot="footer" class="form-btn">
                    <b-button @click="hideModal()" variant="light">Cancel</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>

      <b-modal
        @hidden="hideModal"
        hide-footer
        hide-header
        id="add-note-modal-prevent-closing"
        ref="share-modal-keyword"
        centered
      >
        <div class="edit-task">
          <div class="popup-header">
            <h2 class="">Sharable Url</h2>
            <div class="mt-2">
              <div class="addUserForm">
                <div class="editForm mw-100 pl-0">
                  <b-row>
                    <b-col lg="8" md="8">
                      <b-form-group id="input-group-5" label="Search Text">
                        <b-form-input type="text" placeholder="" v-model="search"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col lg="8" md="8">
                      <b-form-group id="input-group-4" label="Category" class="required">
                        <span class="custom-dropdown">
                          <b-form-select v-model="share_service_id" @click="searchUrl()">
                            <option value="0">Please Select Services</option>
                            <option
                              :value="categoryVal.id"
                              v-for="categoryVal in serviceCategories"
                            >
                              {{ categoryVal.name }}
                            </option>
                          </b-form-select>
                        </span>
                      </b-form-group>
                    </b-col>

                    <b-col lg="4" md="4">
                      <b-button
                        @click="searchUrl()"
                        variant="success desktop_margin_left_zero share-job-class"
                        style="margin-top: 24px"
                        >Generate URL</b-button
                      >
                    </b-col>

                    <b-col lg="8" md="8">
                      <b-form-group id="input-group-5" label="Job Url with Keyword">
                        <b-form-input
                          id="copy-keyword-url"
                          type="text"
                          placeholder=""
                          v-model="keyword_url"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col lg="4" md="4">
                      <b-button
                        @click="copyURLKeyword()"
                        variant="success desktop_margin_left_zero share-key-class"
                        style="margin-top: 24px"
                        >Copy Url</b-button
                      >
                    </b-col>
                  </b-row>

                  <div slot="footer" class="form-btn">
                    <b-button @click="hideModal()" variant="light">Cancel</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>

      <b-modal
        @hidden="hideModal"
        hide-footer
        hide-header
        id="add-note-modal-prevent-closing"
        ref="share-company-modal"
        centered
      >
        <div class="edit-task">
          <div class="popup-header">
            <h2 class="">Sharable Company's Job URL</h2>
            <div class="mt-2">
              <div class="addUserForm">
                <div class="editForm mw-100 pl-0">
                  <b-row>
                    <b-col lg="12" md="12">
                      <b-form-group id="input-group-5" label="Company's Job Url">
                        <b-form-input
                          id="copy-company-url"
                          type="text"
                          placeholder=""
                          v-model="company_url"
                        ></b-form-input>
                      </b-form-group>

                      <b-button
                        @click="copyURLComp()"
                        variant="success desktop_margin_left_zero share-comp-class"
                        >Copy Url</b-button
                      >
                    </b-col>
                  </b-row>

                  <div slot="footer" class="form-btn">
                    <b-button @click="hideModal()" variant="light">Cancel</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>

      <b-modal ref="location-modal" title="Location" hide-footer @shown="refreshMap">
        <!-- <b-form @submit="onSubmitLocation"> -->
        <div class="edit-form-box">
          <div class="profile-repeate form-box-field">
            <b-row>
              <b-col lg="12" md="12">
                <div class="form-map-box">
                  <gmap-map
                    ref="googleMap"
                    :center="center"
                    :zoom="12"
                    style="width: 100%; height: 400px"
                  >
                    <gmap-marker
                      :key="index"
                      v-for="(m, index) in latlongMarkers"
                      :position="m.position"
                      :icon="m.icon"
                      @click="toggleInfoWindow(m, index)"
                    ></gmap-marker>
                    <gmap-info-window
                      :options="infoOptions"
                      :position="infoWindowPos"
                      :opened="infoWinOpen"
                      @closeclick="infoWinOpen = false"
                    >
                      <div v-html="infoContent"></div>
                    </gmap-info-window>
                  </gmap-map>
                </div>
              </b-col>
            </b-row>
            <!-- <div class="pt-2 mt-3 text-md-left text-center">
                            <b-button type="submit" variant="danger" class="text-uppercase x-md btn-shadow">Submit</b-button>
                        </div> -->
          </div>
        </div>
        <!-- </b-form> -->
      </b-modal>

      <b-modal ref="remarks-modal" title="Fulltime Remarks" hide-footer>
        <!-- <b-form> -->
        <div class="edit-form-box">
          <div class="profile-repeate form-box-field">
            <b-row>
              <b-col lg="12" md="12">
                <div class="form-map-box">
                  <b-col lg="6" md="6">
                    <p>{{ fulltime_remarks }}</p> </b-col
                  ><br />
                  <div slot="footer" class="form-btn">
                    <b-button @click="hideModel()" variant="dark">Close</b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <!-- </b-form> -->
      </b-modal>
      <b-modal
        hide-footer
        hide-header
        id="ot-modal"
        ref="ot-modal"
        size="lg"
        centered
        modal-class="ot_modal_class"
      >
        <div class="edit-task ot_job_table">
          <b-form @submit.prevent="submitOTJob" _method="post">
            <div class="popup-header" v-if="ot_type == 'view'">
              <h2 class="">OT Jobs</h2>
              <div class="mt-2">
                <div class="addUserForm">
                  <table class="">
                    <thead>
                      <tr>
                        <th>Job ID</th>
                        <th>Title</th>
                        <th>Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody v-if="ot_jobs_array.length > 0">
                      <tr v-for="oja in ot_jobs_array" :key="oja.id">
                        <td>{{ oja.job_unique_id }}</td>
                        <td>{{ oja.title | capitalize }}</td>
                        <td>{{ (oja.start_date + " " + oja.start_time) | cancelledVal }}</td>
                        <td>
                          <b-link
                            target="_blank"
                            v-if="oja.status == 'in-progress'"
                            href=""
                            :to="{
                              name: 'ViewJobDetailInprogressAdmin',
                              params: {
                                id: encrypt(oja.id),
                                from: 'null',
                                search: 'null',
                                companyFilter: 'null',
                                serviceCategory: 'null',
                              },
                            }"
                            >View</b-link
                          >

                          <b-link
                            target="_blank"
                            v-if="oja.status == 'active'"
                            href=""
                            :to="{
                              name: 'ViewJobDetailUpcomingAdmin',
                              params: {
                                id: encrypt(oja.id),
                                from: 'null',
                                search: 'null',
                                companyFilter: 'null',
                                serviceCategory: 'null',
                              },
                            }"
                            >View</b-link
                          >

                          <b-link
                            target="_blank"
                            v-if="oja.status == 'completed'"
                            href=""
                            :to="{
                              name: 'ViewJobDetailCompletedAdmin',
                              params: {
                                id: encrypt(oja.id),
                                from: 'null',
                                search: 'null',
                                companyFilter: 'null',
                                serviceCategory: 'null',
                              },
                            }"
                            >View</b-link
                          >

                          <b-link
                            target="_blank"
                            v-if="oja.status == 'cancelled'"
                            href=""
                            :to="{
                              name: 'ViewJobDetailCancelledAdmin',
                              params: {
                                id: encrypt(oja.id),
                                from: 'null',
                                search: 'null',
                                companyFilter: 'null',
                                serviceCategory: 'null',
                              },
                            }"
                            >View</b-link
                          >
                        </td>
                      </tr>
                    </tbody>
                    <tr v-else>
                      <td colspan="4" class="else_td">No Jobs Found</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="popup-header" v-else>
              <h2 class="">Create OT Jobs</h2>
              <div class="mt-2">
                <div class="addUserForm">
                  <div class="editForm mw-100 pl-0">
                    <b-row>
                      <b-col lg="12" md="12">
                        <b-form-group
                          id="input-group-8"
                          label="Job Time"
                          class="category-radio required"
                        >
                          <b-form-radio v-model="job_time" name="job_time" value="before"
                            >Before Start Time</b-form-radio
                          >
                          <b-form-radio v-model="job_time" name="job_time" value="after"
                            >After End Time</b-form-radio
                          >
                        </b-form-group>
                      </b-col>
                      <b-col lg="12" md="12">
                        <b-form-group id="input-group-5" label="Title" class="required">
                          <b-form-input
                            id="input-1"
                            type="text"
                            placeholder=""
                            v-model="title"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col lg="6" md="6">
                        <b-form-group id="input-group-4" label="No. Of Hours" class="required">
                          <span class="custom-dropdown">
                            <b-form-select v-model="working_hours">
                              <option value="" disabled="true">Please Select No. of Hours</option>
                              <option :value="arr" v-for="arr in working_hours_arr">
                                {{ arr }}
                              </option>
                            </b-form-select>
                          </span>
                        </b-form-group>
                      </b-col>
                      <b-col lg="6" md="6">
                        <b-form-group id="input-group-4" label="No. of Minutes" class="required">
                          <span class="custom-dropdown">
                            <b-form-select v-model="working_minutes">
                              <option value="" disabled="true">Please Select No. of Minutes</option>
                              <option :value="arr" v-for="arr in working_minutes_arr">
                                {{ arr }}
                              </option>
                            </b-form-select>
                          </span>
                        </b-form-group>
                      </b-col>
                      <b-col lg="6" md="6">
                        <b-form-group id="input-group-9" label="Vacancy" class="required">
                          <b-form-input
                            id="input-9"
                            maxlength="6"
                            v-model="vacancy"
                            type="text"
                            placeholder=""
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col lg="6" md="6">
                        <b-form-group
                          id="input-group-4"
                          label="Select Per Hour Cost"
                          class="required"
                        >
                          <span class="custom-dropdown">
                            <b-form-select v-model="price" @change="getCostList">
                              <option value="" disabled="true">Please Select Per Hour Cost</option>
                              <option :value="cost.per_hour_cost" v-for="cost in costListing">
                                {{ cost.per_hour_cost }}
                              </option>
                            </b-form-select>
                          </span>
                        </b-form-group>
                      </b-col>
                      <b-col lg="12" md="12">
                        <div class="permission_listing">
                          <b-form-group>
                            <b-form-checkbox
                              value="yes"
                              unchecked-value="no"
                              v-model="is_perm_partime"
                              ><strong>Is Permanent Part Time job?</strong></b-form-checkbox
                            >
                          </b-form-group>
                        </div>
                      </b-col>
                      <b-col lg="12" md="12">
                        <div class="permission_listing">
                          <b-form-group>
                            <b-form-checkbox
                              value="yes"
                              unchecked-value="no"
                              v-model="lunch_pay_not_provided"
                              ><strong>Unpaid break?</strong></b-form-checkbox
                            >
                          </b-form-group>
                        </div>
                      </b-col>
                      <b-col lg="12" md="12" v-if="lunch_pay_not_provided == 'yes'">
                        <b-form-group
                          id="input-group-4"
                          label="No. Of Hours"
                          class="required desktop_margin_bottom_10"
                        >
                          <span class="custom-dropdown">
                            <b-form-select v-model="lunch_pay_hours">
                              <option value="" disabled="true">Please Select No. of Hours</option>
                              <option :value="arr" v-for="arr in lunch_hours_arr">{{ arr }}</option>
                            </b-form-select>
                          </span>
                        </b-form-group>
                      </b-col>
                      <b-col lg="12" md="12" v-if="mwList.length > 0">
                        <div class="select-service-box clearfix check_label_single">
                          <b-form-group>
                            <b-form-checkbox v-model="selectAll" @change="allSelected"
                              >SELECT ALL</b-form-checkbox
                            >
                          </b-form-group>
                        </div>
                      </b-col>
                      <b-col lg="12" md="12">
                        <b-form-group id="input-group-4" label="Select Freelancer" class="required">
                          <span class="custom-dropdown">
                            <multiselect
                              v-model="selected_mw_arr"
                              id="ajax"
                              label="fl_name"
                              track-by="id"
                              placeholder="Type to search freelancer"
                              open-direction="bottom"
                              :options="mwList"
                              :multiple="true"
                              :clear-on-select="false"
                              :close-on-select="false"
                              :show-no-results="true"
                              :hide-selected="true"
                              :searchable="true"
                              @input="onFlInput(selected_mw_arr)"
                            >
                              <template slot="selection" slot-scope="{ values, search, isOpen }">
                                <span class="multiselect__single" v-if="values.length && !isOpen">
                                  {{ values.length }} Freelancer(s) selected
                                </span>
                              </template>

                              <span slot="noResult">No Freelancer Found.</span>
                            </multiselect>
                          </span>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <div slot="footer" class="form-btn">
                      <b-button
                        type="submit"
                        variant="success desktop_margin_left_zero"
                        :disabled="disabledBtn"
                        >Submit</b-button
                      >
                      <b-button @click="hideModal()" variant="light">Cancel</b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </b-modal>
      <b-modal
        @hidden="hideModal"
        hide-footer
        hide-header
        id="transaction-modal"
        ref="transaction-modal"
        size="lg"
        centered
        modal-class="ot_modal_class"
      >
        <div class="edit-task ot_job_table">
          <b-form @submit.prevent="submitTransaction" _method="post">
            <div class="popup-header">
              <h2 class="">Create Transaction</h2>
              <div class="mt-2">
                <!-- <div class="addUserForm"> -->
                <div class="editForm mw-100 pl-0">
                  <b-row>
                    <b-col lg="12" md="12">
                      <!-- <b-form-group id="input-group-8" label="Billable" class="category-radio required" >
                                                <b-form-radio v-model="billable" name="billable" value="yes">Yes</b-form-radio>
                                                <b-form-radio v-model="billable" name="billable" value="no">No</b-form-radio>
                                            </b-form-group> -->
                      <b-form-group
                        id="input-group-4"
                        label="Billing Type"
                        class="required desktop_margin_bottom_10"
                      >
                        <span class="custom-dropdown">
                          <b-form-select
                            v-model="billing_type"
                            class="model_margin_bottom_zero"
                            @change="updateName()"
                          >
                            <option value="" disabled>Select Bill Type</option>
                            <option :value="bt.id" v-for="bt in billing_types">
                              {{ bt.name | capitalize }}
                            </option>
                          </b-form-select>
                        </span>
                      </b-form-group>
                    </b-col>
                    <b-col lg="12" md="12">
                      <b-form-group id="input-group-5" label="Amount" class="required">
                        <!-- <b-form-input id="input-1" type="hidden" style="display:none" placeholder="" v-model="billable_name"></b-form-input> -->

                        <b-form-input
                          id="input-1"
                          type="text"
                          placeholder=""
                          v-model="amount"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col lg="12" md="12">
                      <b-form-group id="input-group-3" label="Remarks" class="required">
                        <b-form-textarea
                          id="textarea-3"
                          type="textarea"
                          placeholder="Enter Remarks"
                          v-model="remarks"
                        ></b-form-textarea>
                      </b-form-group>
                    </b-col>

                    <b-col lg="12" md="12">
                      <b-form-group id="input-group-3" label="Internal Remarks" class="required">
                        <b-form-textarea
                          id="textarea-3"
                          type="textarea"
                          placeholder="Enter Internal Remarks"
                          v-model="internal_remarks"
                        ></b-form-textarea>
                      </b-form-group>
                    </b-col>
                    <!-- <b-col lg="12" md="12" v-if="mwList.length >0">
                                            <div class="select-service-box clearfix check_label_single">
                                                <b-form-group>
                                                    <b-form-checkbox v-model = 'selectAll' @change="allSelected">SELECT ALL</b-form-checkbox>
                                                </b-form-group>
                                            </div>
                                        </b-col> -->
                    <b-col lg="12" md="12">
                      <b-form-group
                        id="input-group-8"
                        label="FL Type"
                        class="category-radio required"
                      >
                        <b-form-radio
                          v-model="fl_type"
                          name="fl_type"
                          value="selected"
                          @change="selectFLType('selected')"
                          >Selected</b-form-radio
                        >
                        <b-form-radio
                          v-model="fl_type"
                          name="fl_type"
                          value="cancelled"
                          @change="selectFLType('cancelled')"
                          >Cancelled</b-form-radio
                        >
                        <b-form-radio
                          v-model="fl_type"
                          name="fl_type"
                          value="all"
                          @change="selectFLType('all')"
                          >All</b-form-radio
                        >
                      </b-form-group>
                    </b-col>
                    <b-col lg="12" md="12">
                      <b-form-group id="input-group-4" label="Select Freelancer" class="required">
                        <span class="custom-dropdown">
                          <multiselect
                            v-model="selected_mw_arr"
                            id="ajax"
                            label="fl_name"
                            track-by="id"
                            placeholder="Type to search freelancer"
                            open-direction="bottom"
                            :options="mwList"
                            :multiple="true"
                            :clear-on-select="false"
                            :close-on-select="true"
                            :show-no-results="true"
                            :hide-selected="true"
                            :searchable="true"
                            @input="onFlInput(selected_mw_arr)"
                          >
                            <span slot="noResult">No Freelancer Found.</span>
                          </multiselect>
                        </span>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <div slot="footer" class="form-btn">
                    <b-button
                      type="submit"
                      variant="success desktop_margin_left_zero"
                      :disabled="disabledBtn"
                      >Submit</b-button
                    >
                    <b-button @click="hideModal()" variant="light">Cancel</b-button>
                  </div>
                </div>
                <!-- </div> -->
              </div>
            </div>
          </b-form>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { POST_API, GET_API } from "../../../store/actions.type";
import Multiselect from "vue-multiselect";
import VueSingleSelect from "vue-single-select";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import JwtService from "../../../common/jwt.service";
import StarRating from "vue-star-rating";
import { BTooltip } from "bootstrap-vue";
import Datepicker from "vuejs-datepicker";

import Vue from "vue";
import VueQRCodeComponent from "vue-qrcode-component";
import Bus from "../../../event-bus";
import VueTimepicker from "vuejs-timepicker";

export default {
  data() {
    return {
      charge_type: "",
      hours: [0, 1, 2, 3, 4, 5, 6],
      mwList: [],
      selectedSupervisor: [],
      suspention_reason: "",
      job_start_time: {
        hh: "09",
        mm: "33",
        A: "AM",
      },
      job_end_time: {
        hh: "00",
        mm: "00",
        A: "AM",
      },
      disabledFromDate1: {
        from: new Date(Date.now() + 3600 * 1000 * 24),
      },
      qrcode: "",
      job_start_date: "",
      job_end_date: "",
      lunch_pay_not_provided: "no",
      job_cancellation_reason: "",
      lunch_pay_hours: "",
      jobDatas: [],
      skill: "",
      daily: "",
      full_time: "",
      extended_hours: "",
      extension_rate: "",
      min: "",
      location: "",
      remarks: "",
      type: "",
      keyword: "",
      regen: "generate_ts",
      generateTitle: "Generate TS",
      withNoEmail: "no",
      cancellation_reason: "",
      salary: "",
      error_message: "",
      skillsArr: [],
      tagArr: [],
      value: [0, 15000],
      pager: {},
      pageOfItems: [],
      options: [],
      items: null,
      totalRows: 0,
      sliderValue: 50,
      costListing: [],
      from: "",
      to: "",
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 75, 100],
      form: {
        rowsPerPage: 25,
      },
      activeTab: "in-progress",
      activeApplicantTab: "selected",
      keyVal: 0,
      inprogress: true,
      upcoming: false,
      moreinfo: [],
      completed: false,
      cancelled: false,
      all: false,
      selected: true,
      application: false,
      system_withdrawn: false,
      applicant_withdrawn: false,
      rate_review: false,
      review1: "",
      activeCount: "",
      shortlistedCount: "",
      hiredCount: "",
      rejectedCount: "",
      allCount: "",
      remaining_feature_jobs: 0,
      loading: false,
      webUrl: process.env.VUE_APP_URL,
      webServerUrl: process.env.VUE_APP_SERVER_URL,
      webUrlV1: process.env.VUE_APP_URL_V1,
      userDatas: "",
      selectedsup: [],
      supervisorName: "",
      selectedSupervisor: [],
      fields: [
        { key: "checkbox", label: "Checkbox", sortable: false },

        { key: "name", label: "NAME & EMAIL", sortable: true },
        { key: "mobile_number", label: "MOBILE NUMBER", sortable: true },
        { key: "mobile_number1", label: "MOBILE NUMBER", sortable: true },

        { key: "time", label: "Time", sortable: true },
        { key: "thumb", label: "THUMB", sortable: true },
        { key: "ac_button", label: "Button", sortable: true },
        { key: "toggleAction", label: "ACTION" },
      ],

      checkedStar: [],
      sortBy: null,
      acStatus: "",
      sortDirection: "asc",
      lunch_break: "no",
      filter: "",
      status: "active",
      sortDesc: true,
      range: false,
      date: moment().utc().format("YYYY-MM-DD"),
      format: "YYYY-MM-DD HH:mm",
      auto: true,
      center: "center",
      minDate: null,
      locale: {
        direction: "ltr", //direction of text
        format: "DD-MMM-YYYY", //fomart of the dates displayed
        separator: " - ", //separator between the two ranges
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        /*weekLabel: 'W',
                customRangeLabel: 'Custom Range',*/
        daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 0, //ISO first day of week - see moment documenations for details
        sameDateFormat: {
          from: "DD MM YYYY, hh:mm",
          to: "hh:mm",
        },
      },
      latlongMarkers: [],
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      currentMidx: null,
      fullscreenControl: false,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      MarkerOptions: {
        zIndex: 999999,
        opacity: 0.2,
      },
      timePicker24Hour: true,
      timePicker: true,
      dateInput: {
        placeholder: "Select Date",
      },
      dismissCountDown: 0,
      countDownChanged: "",
      calendarTimeInput: {
        readonly: true,
        step: 30,
        inputClass: "my_custom_class",
      },
      rating: 0,
      addDays: moment().add("3", "days").format("YYYY-MM-DD h:m:s"),
      readOnly: true,
      dateRange: {
        startDate: "",
        endDate: "",
      },
      start_date_filter: "",
      end_date_filter: "",
      filterSearch: "",
      filterServiceCategory: null,
      qr_title: "",
      inprogressCount: 0,
      upcomingCount: 0,
      vacancySet: 0,
      applicant_cancellation_reason: "",
      permissionActions: "",
      allManageJobDPLocal: [],
      extended_min: 0,
      filterSearchSelected: "",
      default_increment_in_vacancy: 0,
      selectedTab: "",
      arrival_condition: "",
      arrival_time: "",
      user_revived_with: "",
      arrival_model_title: "",

      // fields: [
      //   { key: "image_title", label: "Banner Title", sortable: true },
      //   { key: "image_description", label: "Banner Description", sortable: true },
      //   { key: "status", label: "Status", sortable: true },
      //   { key: "toggleAction", label: "Actions" },
      // ],

      fields_one: [
        { key: "name", label: "Name" },
        { key: "appliedcount", label: "Applied" },
        { key: "awarded", label: "Address" },
        // { key: 'acknowledged', label: 'Address', thStyle:  {width: '5%'} },
        { key: "address", label: "Address" },
        { key: "remarks", label: "remarks" },
        { key: "startEnd", label: "Start End Time" },
        { key: "extendHour", label: "Extend Hours" },
        { key: "task", label: "Task" },
        { key: "toggleAction", label: "Toogle" },
      ],

      fields_two: [
        { key: "name", label: "Name", thStyle: { width: "20%" } },
        { key: "awarded", label: "Address" },
        { key: "acknowledged", label: "Address" },
        { key: "address", label: "Address" },
        { key: "remarks", label: "remarks" },
        { key: "ratingStar", label: "Rating" },
        { key: "appliedcount", label: "Applied" },
        { key: "date", label: "Date" },
        { key: "time", label: "Time" },
        { key: "radioStar", label: "Select" },
      ],
      rating__field: [{ key: "reviews", label: "Reviews" }],
      fields_four: [
        { key: "name", label: "Name", thStyle: { width: "10%" } },
        { key: "awarded", label: "Address", thStyle: { width: "10%" } },
        { key: "acknowledged", label: "Address", thStyle: { width: "20%" } },
        { key: "address", label: "Address", thStyle: { width: "20%" } },
        { key: "remarks", label: "remarks" },
        { key: "toggleAction", label: "Toogle", thStyle: { width: "20%" } },
      ],

      form: {
        id: "",
        rowsPerPage: 10,
        filterServiceCategory: "all",
        filterCompanyId: "all",
        filterSearch: "",

        start_time: {
          HH: "00",
          mm: "00",
        },
        end_time: {
          HH: "23",
          mm: "59",
        },
      },
      items: "",
      status: "in-progress",
      serviceCategories: [],
      allCompanies: [],
      statusCompanies: [],
      companiesVal: "",
      company_id: "",
      currentActiveTab: "",
      company_url: "",
      screenWidth: 0,
      user_jobs_remark: "",
      moreInfo: "",
      notify_fl: "",
      is_extension: "",
      weburl: "",
      not_suitable_reason: "",
      disabledSuspendNp: "",
      disabledSuspend: "",
      disabledFromDate: "",

      modify_time: "",
      arrival_time_next_day: "",
      job_url: "",
      companyId: "",
      itemsData: "",
      totalRows: 1,
      perPage: 25,
      pageOptions: [25, 50, 75, 100],
      sortBy: null,
      sortDirection: "asc",
      qrcode: "",
      qr_title: "",

      qr_job_id: "",
      qr_url: "",
      error_message: "",
      countDownChanged: "",
      dismissCountDown: "",
      // status: 'active',
      isBusy: false,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      to: "",
      from: "",
      selectedSupervisor: [],
      supervisorName: "",
      selected_supervisor_id: "",
      selected_company_id: "",
      company_id: "",
      userType: "",
      webUrl: process.env.MIX_APP_URL,
      v2WebUrl: process.env.MIX_APP_URL_V2,

      inprogress: true,
      upcoming: false,
      completed: false,
      cancelled: false,
      all: false,

      from: this.$route.params.from,
      actions: "",
      user_type_id: "",
      link: null,
      user_id: "",
      servicesFilter: "",
      companiesFilter: "",
      start_date: "",
      end_date: "",
      dateRange: {
        startDate: "",
        endDate: "",
      },

      range: false,
      date: moment().format("YYYY-MM-DD"),
      format: "YYYY-MM-DD HH:mm",
      auto: true,
      center: "center",
      minDate: null,
      locale: {
        direction: "ltr", //direction of text
        format: "DD-MMM-YYYY", //fomart of the dates displayed
        separator: " - ", //separator between the two ranges
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        /*weekLabel: 'W',
                customRangeLabel: 'Custom Range',*/
        daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 0, //ISO first day of week - see moment documenations for details
        sameDateFormat: {
          from: "DD MM YYYY, hh:mm",
          to: "hh:mm",
        },
      },
      timePicker24Hour: true,
      timePicker: true,
      dateInput: {
        placeholder: "Select Date",
      },
      calendarTimeInput: {
        readonly: true,
        step: 30,
        inputClass: "my_custom_class",
      },
      readOnly: true,
      checkedValue: [],
      tabIndex: 1,
      tabs: ["#1", "#2", "#3", "#4"],
      notif_title: "",
      notif_description: "",
      broadcast_job_id: "",
      share_job_id: "",
      share_service_id: "0",
      share_company_id: "",
      share_company_name: "",
      keyword_url: "",
      search: "",
      broadcast_date: "",
      broadcast_time: "",
      withNoEmail: "no",
      generateTs: false,
      generateSr: true,
      sr_generate_for_job: "",
      fulltime_remarks: "",
      working_hours_arr: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
      lunch_hours_arr: [0.25, 0.5, 0.75, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5],
      working_minutes_arr: ["00", "15", "30", "45"],
      vacancy: "",
      price: "",
      working_hours: "",
      job_time: "after",
      working_minutes: "",
      lunch_pay_hours: "",
      title: "",
      costListing: [],
      lunch_pay_not_provided: "no",
      mwList: [],
      mwSearch: "",
      isLoading: false,
      selected_mw: [],
      selected_mw_arr: "",
      is_perm_partime: "no",
      selectedJob: "",
      disabledBtn: false,
      selectedUser: [],
      selectAll: false,
      ot_type: "view",
      ot_jobs_array: [],
      billable: "",
      billing_type: "",
      amount: 0,
      remarks: "",
      internal_remarks: "",
      tr_type: "in-progress",
      editable_item: "",
      billing_types: [],
      billable_name: "",
      fl_type: "",
      designation: "",
      break_type: "add",
      selectAllCompleted: [],
      platform_fee_title:'',
      platform_fee:''
    };
  },
  components: {
    Multiselect,
    VueSingleSelect,
    DateRangePicker,
    Datepicker,
    StarRating,
    VueQRCodeComponent,
    BTooltip,
    VueTimepicker,
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD MMM YYYY");
    },
    removeUnderscore: function (value) {
      var i = "";

      if (value != null) {
        var v = value.replace("_", " ");
        v = v.toString();
        i = v.charAt(0).toUpperCase() + v.slice(1);
        return i;
      } else {
        return value;
      }
    },
    moment_1: function (date) {
      return moment(date).format("ddd DD MMM YYYY");
    },
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    capitalizeAll: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.toUpperCase();
    },
    customFormatterOne(date) {
      return moment(date).utc().format("DD MMM YYYY hh:mm A");
    },

    customFormatterOnecreated(date) {
      return moment(date).utc().format("DD MMM YYYY hh:mm A");
    },
    applicantOriginalSET: function (date) {
      if (date == "" || date == null) {
        return "";
      } else {
        return moment(date, "HH:mm:ss").format("hh:mm A");
      }
    },

    applicantOriginalSET1: function (date) {
      console.log(date, "applied date1");
      if (date == "" || date == null) {
        return "";
      } else {
        return moment(date).utc().format("DD MMM YYYY hh:mm A");
      }
    },
    applicantStartEndTime: function (date) {
      if (date == "" || date == null) {
        return "";
      } else {
        return moment(date, "HH:mm:ss").format("hh:mm A");
      }
    },

    applicantStartEndTime1: function (date) {
      if (date == "") {
        return "";
      } else {
        return moment(date, "HH:mm:ss").format("hh:mm A");
      }
    },
    jobStartEndTime: function (date) {
      if (date == "" || date == null) {
        return "";
      } else {
        return moment(date, "HH:mm:ss").format("hh:mm A");
      }
    },
    datePicker1: function (value) {
      if (value == null) {
        return "";
      } else {
        return moment(value).format("DD MMM YYYY");
      }
    },
  },

  methods: {
    // ManageJobRequirementEyeReveal(jobDatakey) {
    //   $("#manage_job_requirement_eye_icon" + jobDatakey).toggleClass("fa-eye fa-eye-slash");
    //   //   $(".manage_job_requirements").toggleClass("show_full_manage_job_requirement");
    //   $(".large-content-requirement-inprogress")
    //     .find("#visible-content-requirement-inprogress" + jobDatakey)
    //     .toggle();
    //   $(".large-content-requirement-inprogress")
    //     .find("#invisible-content-requirement-inprogress" + jobDatakey)
    //     .toggle();
    // },
    // ManageJobScopeEyeReveal(jobDatakey) {
    //   $("#manage_job_scope_eye_icon" + jobDatakey).toggleClass("fa-eye fa-eye-slash");
    //   //   $(".manage_job_scope").toggleClass("show_full_manage_job_scope");
    //   $(".large-content-scope-inprogress")
    //     .find("#visible-content-scope-inprogress" + jobDatakey)
    //     .toggle();
    //   $(".large-content-scope-inprogress")
    //     .find("#invisible-content-scope-inprogress" + jobDatakey)
    //     .toggle();
    // },
    // ManageJobRequirementUpcomingEyeReveal(jobDatakey) {
    //   $("#manage_job_requirement_upcoming_eye_icon" + jobDatakey).toggleClass(
    //     "fa-eye fa-eye-slash"
    //   );
    //   //   $(".manage_job_requirements_upcoming").toggleClass(
    //   //     "show_full_manage_job_requirement_upcoming"
    //   //   );
    //   $(".large-content-requirement-upcoming")
    //     .find("#visible-content-requirement-upcoming" + jobDatakey)
    //     .toggle();
    //   $(".large-content-requirement-upcoming")
    //     .find("#invisible-content-requirement-upcoming" + jobDatakey)
    //     .toggle();
    // },
    // ManageJobScopeUpcomingEyeReveal(jobDatakey) {
    //   $("#manage_job_scope_upcoming_eye_icon" + jobDatakey).toggleClass("fa-eye fa-eye-slash");
    //   //   $(".manage_job_scope_upcoming").toggleClass("show_full_manage_job_scope_upcoming");
    //   $(".large-content-scope-upcoming")
    //     .find("#invisible-content-scope-upcoming" + jobDatakey)
    //     .toggle();
    //   $(".large-content-scope-upcoming")
    //     .find("#visible-content-scope-upcoming" + jobDatakey)
    //     .toggle();
    // },
    // ManageJobRequirementCompletedEyeReveal(jobDatakey) {
    //   $("#manage_job_requirement_completed_eye_icon" + jobDatakey).toggleClass(
    //     "fa-eye fa-eye-slash"
    //   );
    //   //   $(".manage_job_requirements_completed").toggleClass(
    //   //     "show_full_manage_job_requirement_completed"
    //   //   );
    //   $(".large-content-requirement-completed")
    //     .find("#visible-content-requirement-completed" + jobDatakey)
    //     .toggle();
    //   $(".large-content-requirement-completed")
    //     .find("#invisible-content-requirement-completed" + jobDatakey)
    //     .toggle();
    // },
    // ManageJobScopeCompletedEyeReveal(jobDatakey) {
    //   $("#manage_job_scope_completed_eye_icon" + jobDatakey).toggleClass("fa-eye fa-eye-slash");
    //   //   $(".manage_job_scope_completed").toggleClass("show_full_manage_job_scope_completed");
    //   $(".large-content-scope-completed")
    //     .find("#visible-content-scope-completed" + jobDatakey)
    //     .toggle();
    //   $(".large-content-scope-completed")
    //     .find("#invisible-content-scope-completed" + jobDatakey)
    //     .toggle();
    // },
    // ManageJobRequirementCancelledEyeReveal(jobDatakey) {
    //   $("#manage_job_requirement_cancelled_eye_icon" + jobDatakey).toggleClass(
    //     "fa-eye fa-eye-slash"
    //   );
    //   //   $(".manage_job_requirements_cancelled").toggleClass(
    //   //     "show_full_manage_job_requirement_cancelled"
    //   //   );
    //   $(".large-content-requirement-cancelled")
    //     .find("#visible-content-requirement-cancelled" + jobDatakey)
    //     .toggle();
    //   $(".large-content-requirement-cancelled")
    //     .find("#invisible-content-requirement-cancelled" + jobDatakey)
    //     .toggle();
    // },
    // ManageJobScopeCancelledEyeReveal(jobDatakey) {
    //   $("#manage_job_scope_cancelled_eye_icon" + jobDatakey).toggleClass("fa-eye fa-eye-slash");
    //   //   $(".manage_job_scope_cancelled").toggleClass("show_full_manage_job_scope_cancelled");
    //   $(".large-content-scope-cancelled")
    //     .find("#visible-content-scope-cancelled" + jobDatakey)
    //     .toggle();
    //   $(".large-content-scope-cancelled")
    //     .find("#invisible-content-scope-cancelled" + jobDatakey)
    //     .toggle();
    // },
    // ManageJobRequirementAllEyeReveal(jobDatakey) {
    //   $("#manage_job_requirement_eye_icon_all" + jobDatakey).toggleClass("fa-eye fa-eye-slash");
    //   //   $(".manage_job_requirements_all").toggleClass("show_full_manage_job_requirement_all");
    //   $(".large-content-requirement-all")
    //     .find("#visible-content-requirement-all" + jobDatakey)
    //     .toggle();
    //   $(".large-content-requirement-all")
    //     .find("#invisible-content-requirement-all" + jobDatakey)
    //     .toggle();
    // },
    // ManageJobScopeAllEyeReveal(jobDatakey) {
    //   $("#manage_job_scope_eye_icon_all" + jobDatakey).toggleClass("fa-eye fa-eye-slash");
    //   //   $(".manage_job_scope_all").toggleClass("show_full_manage_job_scope_all");
    //   $(".large-content-scope-all")
    //     .find("#visible-content-scope-all" + jobDatakey)
    //     .toggle();
    //   $(".large-content-scope-all")
    //     .find("#invisible-content-scope-all" + jobDatakey)
    //     .toggle();
    // },
    // customFormatterTwo(date) {
    //   return moment(date).utc().format("DD-MM-YYYY");
    // },
    // customFormatterTwoSuspend(date) {
    //   return moment(date).format("DD-MM-YYYY");
    // },
    // cancelJob(id) {
    //   // this.form.username = name;
    //   this.job_cancellation_reason = "";
    //   this.job_id = id;
    //   this.$refs["job-cancel-modal"].show();
    // },
    totalVacancy(current_vacancy) {
      console.log(current_vacancy, this.default_increment_in_vacancy);
      return parseInt(current_vacancy) - parseInt(this.default_increment_in_vacancy);
    },
    locationPtuser(job_id, id) {
      this.job_id = job_id;
      this.getLatLong().then(() => {
        this.geolocate();
        // this.$refs.address.focus();
        this.setPlace(this.currentPlace);
        this.addMarker();
      });
      this.form.user_id = id;
      this.$refs["location-modal"].show();
    },
    clear() {
      this.filterSearch = "";
      this.dateRange.startDate = null;
      this.dateRange.endDate = null;
      this.getList(this.activeTab, this.activeApplicantTab, this.filterSearch);
    },
    clearLatlong() {
      this.center = { lat: 0, lng: 0 };
      // this.latlongMarker = [];
    },
    geolocate: function () {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: +position.coords.latitude,
          lng: +position.coords.longitude,
        };
      });
    },
    show(action) {
      if (this.user_type_id == 2) {
        return this.actions.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: +parseFloat(this.currentPlace.geometry.location.lat()),
          lng: +parseFloat(this.currentPlace.geometry.location.lng()),
        };
        this.form.formattedAddress = this.currentPlace.formatted_address;
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
      }
    },
    setPlace(place) {
      this.currentPlace = place;
      this.addMarker();
    },
    getLatLong() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            job_id: this.job_id,
            user_id: this.logged_user_id,
            // status: status ? status : 'in-progress',
            // servicesFilter: userServices ? userServices : 'all',
            page: this.currentPage,
            keyword: this.filterSearchRate,
            page: this.currentPage,
            rowsPerPage: this.form.rowsPerPage,
            sortBy: this.sortBy,
            sortDirection: this.sortDirection,
            sortDesc: this.sortDesc,
          },
          api: "/api/get-latlong",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.marker = this.$store.getters.getResults.data.latLong;
            this.jobMarker = this.$store.getters.getResults.data.job_location;
            this.updatelatlongMarker();
          }
        });
    },
    updatelatlongMarker() {
      var markers = [];
      $.each(this.marker, function (index, val) {
        var icon = {
          url: val.candidate_profile.profile_image, // url
          scaledSize: new google.maps.Size(30, 30), // scaled size
          // radius:20,
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(0, 0), // anchor
          labelClass: "labelIconlabelIcon",
          //shape:{coords:[17,17,18],type:"circle"},
          //set optimized to false otherwise the marker  will be rendered via canvas
          //and is not accessible via CSS
          // optimized:false,
          // title: 'spot'
          // path: google.maps.SymbolPath.CIRCLE,
          // scale: 8.5,
          // fillColor: "#F00",
          // fillOpacity: 0.4,
          // strokeWeight: 0.4,
          // scaledSize: new google.maps.Size(10,20), // scaled size
          // origin: new google.maps.Point(0,0), // origin
          // anchor: new google.maps.Point(0, 32) // anchor
        };
        markers.push({
          position: { lat: Number(val.current_lat), lng: Number(val.current_long) },
          icon: icon,
          labelClass: "labelIcon",
          // icon:val.candidate_profile.profile_image,
          type: "ptuser",
          //shape:{coords: [0,0,3,3], type: "rect"},
        });
        // str['long'][index].push(val.current_long);
      });
      var jobmarkers = [];
      $.each(this.jobMarker, function (index, val) {
        markers.push({
          position: { lat: Number(val.lat), lng: Number(val.long) },
          type: "company",
        });
        // str['long'][index].push(val.current_long);
      });
      this.latlongMarkers = markers;
      this.center = { lat: 0, lng: 0 };
      setTimeout(() => {
        this.center = this.latlongMarkers[0].position;
      }, 500);
    },

    cancelApplicant(id, job_id, key) {
      // this.form.username = name;
      this.cancellation_reason = "";
      this.applicant_cancellation_reason = "";
      this.user_id = id;
      this.job_id = job_id;
      this.keyVal = key;
      this.$refs["cancel-modal"].show();
    },
    onSubmitReview(evt) {
      evt.preventDefault();
      this.$refs["review-modal"].hide();
      this.$store
        .dispatch(POST_API, {
          data: {
            job_id: this.job_id,
            user_id: this.user_id,
            review: this.review1,
            rating: this.rating,
            logged_user_id: this.logged_user_id,
          },
          api: "/api/supervisor-rate-ptuserWeb",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.success_message = this.$store.getters.getResults.message;
            this.form.review = "";
            this.form.user_id_id = "";
            this.rating = "";
            //this.$refs['review-modal'].hide();
            this.showSuccessAlert(this.success_message);
            this.changeApplicantTabStatus(
              this.activeTab,
              this.activeApplicantTab,
              this.job_id,
              this.keyVal,
              this.user_id
            );
            // this.changeTabStatus(this.activeTab, this.activeApplicantTab, this.filterSearch);
            // window.location.reload();
            //this.getList(this.activeTab,'selected',this.filterSearch);
            // this.$router.push({ name: 'ViewJobDetailCompleted' });
          }
        });
    },
    pdfViewer(link) {
      window.open(link);
    },
    ratePtUser(id) {
      // this.form.username = name;
      this.form.review = "";
      this.form.user_id = id;
      this.$refs["review-modal"].show();
    },
    cancellationReason(type) {
      if (type == "applicant") {
        this.applicant_cancellation_reason = document.getElementById(
          "applicant_cancellation_reason"
        ).value;
      } else {
        this.job_cancellation_reason = document.getElementById("job_cancellation_reason").value;
      }
    },
    onSubmitCancel(evt) {
      console.log(this.applicant_cancellation_reason, "this.applicant_cancellation_reason");
      evt.preventDefault();
      this.$refs["cancel-modal"].hide();
      this.$store
        .dispatch(POST_API, {
          data: {
            job_id: this.job_id,
            user_id: this.user_id,
            cancellation_reason: this.applicant_cancellation_reason,
            logged_user_id: this.logged_user_id,
          },
          api: "/api/cancel-pt-user",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_messages_cancel = this.$store.getters.getErrors;
            this.showAlertCancel();
          } else {
            this.form.cancellation_reason = "";
            this.form.user_id = "";
            this.success_message = this.$store.getters.getResults.message;
            this.showSuccessAlert(this.success_message);
            this.changeApplicantTabStatus(
              this.activeTab,
              this.activeApplicantTab,
              this.job_id,
              this.keyVal,
              this.user_id
            );
            // this.getList(this.activeTab,'selected',this.filterSearch);
            // this.changeTabStatus(this.activeTab, this.activeApplicantTab, this.filterSearch);
            // window.location.reload();
          }
        });
    },
    downloadQR(evt) {
      this.$store
        .dispatch(POST_API, {
          data: {
            id: this.qr_job_id,
          },
          api: "/api/show-qr-code",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_messages_cancel = this.$store.getters.getErrors;
            this.showAlertCancel();
          } else {
            this.qr_url = this.$store.getters.getResults.url;
            var name = this.$store.getters.getResults.name;
            this.$refs["qr-code-modal"].hide();
            window.open(this.qr_url, "_blank");
          }
        });
    },
    extendHour(id, cost, jobId, key) {
      // this.form.username = name;
      this.extended_hours = "";
      this.extended_min = "00";
      this.extension_rate = parseFloat(cost);
      this.user_id = id;
      this.job_id = jobId;
      this.keyVal = key;
      this.$refs["extend-modal"].show();
    },
    viewSuspensionReason(remarks) {
      this.$refs["remarks-modal"].show();
      this.remarks = remarks;
    },
    suspendApplicant(
      user_id,
      job_id,
      lunch_pay_hours_old,
      lunch_pay_not_provided_old,
      job_start_date,
      start_time,
      key
    ) {
      this.suspention_reason = "";
      this.user_id = user_id;
      this.job_id = job_id;
      this.keyVal = key;
      console.log(this.keyVal);
      this.lunch_pay_not_provided = "no";
      this.lunch_break = lunch_pay_not_provided_old;
      this.lunch_pay_hours = lunch_pay_hours_old;
      (this.job_start_time.hh = moment(job_start_date + " " + start_time).format("hh")),
        (this.job_start_time.mm = moment(job_start_date + " " + start_time).format("mm")),
        (this.job_start_time.A = moment(job_start_date + " " + start_time).format("A"));
      this.job_start_date =
        job_start_date + " " + start_time != null
          ? moment(job_start_date + " " + start_time, "YYYY-MM-DD").toDate()
          : "";
      this.job_end_date = moment().format("YYYY-MM-DD");
      this.$refs["suspend-modal"].show();
    },
    suspendApplicant(
      user_id,
      job_id,
      lunch_pay_hours_old,
      lunch_pay_not_provided_old,
      job_start_date,
      start_time
    ) {
      this.suspention_reason = "";
      this.user_id = user_id;
      this.job_id = job_id;
      this.lunch_pay_not_provided = "no";
      this.lunch_break = lunch_pay_not_provided_old;
      this.lunch_pay_hours = lunch_pay_hours_old;
      (this.job_start_time.hh = moment(job_start_date + " " + start_time)
        .utc()
        .format("hh")),
        (this.job_start_time.mm = moment(job_start_date + " " + start_time)
          .utc()
          .format("mm")),
        (this.job_start_time.A = moment(job_start_date + " " + start_time)
          .utc()
          .format("A"));
      this.job_start_date =
        job_start_date + " " + start_time != null
          ? moment(job_start_date + " " + start_time, "YYYY-MM-DD")
              .utc()
              .toDate()
          : "";
      this.$refs["suspend-modal"].show();
    },
    onSubmitSuspend(type) {
      this.$store
        .dispatch(POST_API, {
          data: {
            job_id: this.job_id,
            user_id: this.user_id,
            logged_user_id: this.logged_user_id,
            end_time: this.job_end_time,
            end_date: this.job_end_date != "" ? moment(this.job_end_date).format("YYYY-MM-DD") : "",
            lunch_pay_not_provided: this.lunch_break == "yes" ? this.lunch_pay_not_provided : "no",
            lunch_pay_hours: this.lunch_pay_hours,
            suspention_reason: this.suspention_reason,
            type: type,
          },
          api: "/api/suspend-pt-userWeb",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.form.review = "";
            this.form.user_id = "";
            this.success_message = this.$store.getters.getResults.message;
            this.showSuccessAlert(this.success_message);
            this.$refs["suspend-modal"].hide();
            this.changeApplicantTabStatus(
              this.activeTab,
              this.activeApplicantTab,
              this.job_id,
              this.keyVal,
              this.user_id
            );
            //this.changeTabStatus(this.activeTab,this.activeApplicantTab,this.filterSearch)
            // window.location.reload();
            // this.getList(this.activeTab,'selected',this.filterSearch);
            // window.location.reload();
          }
        });
    },
    onSubmitExtend(evt) {
      evt.preventDefault();
      this.$refs["extend-modal"].hide();
      return this.$store
        .dispatch(POST_API, {
          data: {
            job_id: this.job_id,
            user_id: this.user_id,
            // extended_hours: (this.extended_min == '00' || this.extended_min == '' || this.extended_min == null) ? this.extended_hours + '.0' : this.extended_hours +'.5',
            extended_hours:
              this.extended_min == "00" || this.extended_min == "" || this.extended_min == null
                ? this.extended_hours + ".0"
                : this.extended_hours + "." + this.extended_min,
            extension_rate: this.extension_rate,
            logged_user_id: this.logged_user_id,
            from_web: true,
          },
          api: "/api/extend-pt-user-time",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.extended_hours = "";
            this.extended_min = "00";
            // this.form.extension_rate = '';
            this.success_message = this.$store.getters.getResults.message;
            this.$swal({
              position: "center",
              icon: "success",
              title: "Hour extended successfully",
              showConfirmButton: false,
              timer: 1500,
            });
            this.changeApplicantTabStatus(
              this.activeTab,
              this.activeApplicantTab,
              this.job_id,
              this.keyVal,
              this.user_id
            );
            this.user_id = "";
            // this.changeTabStatus(this.activeTab, this.activeApplicantTab, this.filterSearch);
            // window.location.reload();
            // this.getList(this.activeTab,'selected',this.filterSearch);
            // window.location.reload();
          }
        });
    },
    // getEmpUsers() {
    //   return this.$store
    //     .dispatch(POST_API, {
    //       data: {
    //         company_admin: this.user_id,
    //         company_id: this.company_id,
    //         page: this.currentPage,
    //         keyword: this.filter,
    //         rowsPerPage: this.form.rowsPerPage,
    //         sortBy: this.sortBy ? this.sortBy : "id",
    //         sortDirection: this.sortDesc ? "asc" : "desc",
    //       },
    //       api: "/api/emp-manage-user-list",
    //     })
    //     .then(() => {
    //       if (this.$store.getters.containsErrors) {
    //         this.error_message = this.$store.getters.getErrors;
    //         // this.showAlert();
    //         return [];
    //       } else {
    //         this.items = this.$store.getters.getResults.pageOfItems;
    //         this.totalRows = this.$store.getters.getResults.pager.totalItems;
    //         this.perPage = this.$store.getters.getResults.pager.pageSize;
    //         this.from = this.$store.getters.getResults.pager.from;
    //         this.to = this.$store.getters.getResults.pager.to;
    //         return this.items;
    //       }
    //     });
    // },
    // getHourlyCost() {
    //   return this.$store
    //     .dispatch(POST_API, {
    //       data: {},
    //       api: "/api/hourly-cost",
    //     })
    //     .then(() => {
    //       if (this.$store.getters.containsErrors) {
    //         this.error_message = this.$store.getters.getErrors;
    //         this.showAlert();
    //         return [];
    //       } else {
    //         if (this.$store.getters.getResults.data) {
    //           this.costListing = this.$store.getters.getResults.data;
    //         }
    //       }
    //     });
    // },
    // showAlert() {
    //   this.$swal({
    //     position: "center",
    //     // icon: 'error',
    //     imageUrl: "/assets/images/404_elcy.gif",
    //     customClass: {
    //       container: "mascot_error_container",
    //     },
    //     title: this.error_message,
    //     showConfirmButton: false,
    //     timer: 1500,
    //   });
    // },
    // showAlertCancel() {
    //   this.$swal({
    //     position: "center",
    //     // icon: 'error',
    //     imageUrl: "/assets/images/404_elcy.gif",
    //     customClass: {
    //       container: "mascot_error_container",
    //     },
    //     title: this.error_messages_cancel,
    //     showConfirmButton: false,
    //     timer: 1500,
    //   });
    // },
    btoa(id) {
      return btoa(id);
    },
    // showSuccessAlert(message) {
    //   this.$swal({
    //     position: "center",
    //     icon: "success",
    //     iconColor: "#4c8bf5",
    //     title: message,
    //     showConfirmButton: false,
    //     timer: 1500,
    //   }).then((result) => {
    //     // this.$router.push({ name: 'manage-jobs' });
    //   });
    // },
    exportJobs() {
      var base_url = this.webUrlV1;
      var comp = null;
      var start_date = this.dateRange.startDate
        ? moment(this.dateRange.startDate).format("YYYY-MM-DD HH:mm:ss")
        : null;
      var end_date = this.dateRange.endDate
        ? moment(this.dateRange.endDate).format("YYYY-MM-DD HH:mm:ss")
        : null;
      var search = this.filterSearch != "" ? this.filterSearch : null;
      var act_tab = this.activeTab == "inprogress" ? "in-progress" : this.activeTab;
      var export_url =
        "/api/export-jobs/" +
        act_tab +
        "/" +
        this.user_id +
        "/" +
        search +
        "/" +
        this.filterServiceCategory +
        "/" +
        comp +
        "/" +
        start_date +
        "/" +
        end_date;
      window.open(base_url + export_url);
    },
    exportJobsByName() {
      var base_url = this.webUrlV1;
      var start_date = this.dateRange.startDate
        ? moment(this.dateRange.startDate).format("YYYY-MM-DD HH:mm:ss")
        : null;
      var end_date = this.dateRange.endDate
        ? moment(this.dateRange.endDate).format("YYYY-MM-DD HH:mm:ss")
        : null;
      var search = this.filterSearch != "" ? this.filterSearch : null;
      var comp = null;
      var act_tab = this.activeTab == "inprogress" ? "in-progress" : this.activeTab;
      var export_url =
        "/api/export-job-by-name/" +
        act_tab +
        "/" +
        this.user_id +
        "/" +
        search +
        "/" +
        this.filterServiceCategory +
        "/" +
        comp +
        "/" +
        start_date +
        "/" +
        end_date;
      window.open(base_url + export_url);
    },
    // exportJobsByName(){
    //     var tabStatus = this.activeTab == 'inprogress' ? 'in-progress' : this.activeTab;
    //     var start_date_filter = this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : null;
    //     var end_date_filter = this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') : null;
    //     var filterSearch = this.filterSearch != '' ? this.filterSearch : null;
    //     return this.$store.dispatch(GET_API, {
    //         // api: '/api/exportJobsByName/'+tabStatus
    //         api: '/api/exportJobsByName/'+tabStatus+'/'+this.user_id+'/'+start_date_filter+'/'+end_date_filter+'/'+filterSearch
    //     })
    //     .then(() => {
    //         var base_url = this.webServerUrl;
    //         // var export_url = '/api/exportJobsByName/'+tabStatus+'?nocache=1666084804705/';
    //         var export_url = '/api/exportJobsByName/'+tabStatus+'/'+this.user_id+'/'+start_date_filter+'/'+end_date_filter+'/'+filterSearch+'?nocache=1666084804705/';
    //         // var export_url = '/api/export-jobs/'+this.status+'/'+this.user_id+'/'+search+'/'+this.form.filterServiceCategory+'/'+companiesFilter+'/'+start_date+'/'+end_date;
    //         window.open(base_url+export_url,'_blank')
    //     });
    // },
    changeTabStatusAll(status, user_status, filterSearch) {
      this.loading = true;
      this.activeTab = status;
      this.activeApplicantTab = "selected";
      this.dateRange.startDate = null;
      this.dateRange.endDate = null;
      if (this.activeTab == "upcoming") {
        this.upcoming = true;
        this.inprogress = false;
        this.completed = false;
        this.cancelled = false;
        this.all = false;
        this.form.rowsPerPage = 25;
      } else if (this.activeTab == "completed") {
        this.completed = true;
        this.inprogress = false;
        this.upcoming = false;
        this.cancelled = false;
        this.all = false;
        this.form.rowsPerPage = 10;
      } else if (this.activeTab == "cancelled") {
        this.cancelled = true;
        this.inprogress = false;
        this.upcoming = false;
        this.completed = false;
        this.all = false;
        this.form.rowsPerPage = 25;
      } else if (this.activeTab == "all") {
        this.all = true;
        this.inprogress = false;
        this.upcoming = false;
        this.completed = false;
        this.cancelled = false;
        this.form.rowsPerPage = 25;
      } else {
        this.inprogress = true;
        this.expired = false;
        this.upcoming = false;
        this.completed = false;
        this.cancelled = false;
        this.all = false;
        this.form.rowsPerPage = 25;
      }
      // $(".dashboard-content-inner").animate({
      //         scrollTop: 0
      // }, "slow");
      (this.start_date_filter = this.dateRange.startDate
        ? moment(this.dateRange.startDate).format("YYYY-MM-DD")
        : ""),
        (this.end_date_filter = this.dateRange.endDate
          ? moment(this.dateRange.endDate).format("YYYY-MM-DD")
          : "");
      var api = this.activeTab == "all" ? "/api/adminDailyPaidJobsAll" : "/api/adminDailyPaidJobs";
      if (this.start_date_filter || this.end_date_filter) {
        return this.$store
          .dispatch(POST_API, {
            data: {
              page: this.currentPage,
              rowsPerPage: this.form.rowsPerPage,
              userId: this.logged_user_id,
              company_id: this.company_id,
              user_type_id: this.user_type_id,
              is_company_head: this.is_company_head,
              start_date_filter: this.start_date_filter,
              end_date_filter: this.end_date_filter,
              activeTab: status,
              status: status == "inprogress" ? "in-progress" : status,
              user_status: user_status,
              filterSearch: filterSearch,
            },
            api: api,
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
              this.loading = false;
              return [];
            } else {
              this.loading = false;
              this.jobDatas = this.$store.getters.getResults.pageOfItems;
              this.totalRows = this.$store.getters.getResults.pager.totalItems;
              this.perPage = this.$store.getters.getResults.pager.pageSize;
              this.from = this.$store.getters.getResults.pager.from;
              this.to = this.$store.getters.getResults.pager.to;
              this.inprogressCount = this.$store.getters.getResults.inprogresscounts;
              this.upcomingCount = this.$store.getters.getResults.upcommingcounts;
              // this.vacancySet = this.$store.getters.getResults.vacancySet;
              Bus.$emit("get_em_job_count");
              //return this.jobDatas;
            }
          });
      } else {
        this.jobDatas = [];
        this.totalRows = 0;
        this.perPage = 0;
        this.from = 1;
        this.to = 1;
      }
    },
    changeTabStatus(status, user_status, filterSearch, datepick) {
      this.loading = true;
      this.activeTab = status;
      this.activeApplicantTab = "selected";
      if (datepick == "") {
        this.dateRange.startDate = null;
        this.dateRange.endDate = null;
      }
      if (this.activeTab == "upcoming") {
        this.upcoming = true;
        this.inprogress = false;
        this.completed = false;
        this.cancelled = false;
        this.all = false;
        this.form.rowsPerPage = 25;
      } else if (this.activeTab == "completed") {
        this.completed = true;
        this.inprogress = false;
        this.upcoming = false;
        this.cancelled = false;
        this.all = false;
        this.form.rowsPerPage = 10;
      } else if (this.activeTab == "cancelled") {
        this.cancelled = true;
        this.inprogress = false;
        this.upcoming = false;
        this.completed = false;
        this.all = false;
        this.form.rowsPerPage = 25;
      } else if (this.activeTab == "all") {
        this.all = true;
        this.inprogress = false;
        this.upcoming = false;
        this.completed = false;
        this.cancelled = false;
        this.form.rowsPerPage = 25;
      } else {
        this.inprogress = true;
        this.expired = false;
        this.upcoming = false;
        this.completed = false;
        this.cancelled = false;
        this.all = false;
        this.form.rowsPerPage = 25;
      }
      // $(".dashboard-content-inner").animate({
      //         scrollTop: 0
      // }, "slow");
      (this.start_date_filter = this.dateRange.startDate
        ? moment(this.dateRange.startDate).format("YYYY-MM-DD")
        : ""),
        (this.end_date_filter = this.dateRange.endDate
          ? moment(this.dateRange.endDate).format("YYYY-MM-DD")
          : "");
      var api = this.activeTab == "all" ? "/api/adminDailyPaidJobsAll" : "/api/adminDailyPaidJobs";
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPage,
            rowsPerPage: this.form.rowsPerPage,
            userId: this.logged_user_id,
            company_id: this.company_id,
            user_type_id: this.user_type_id,
            is_company_head: this.is_company_head,
            start_date_filter: this.start_date_filter,
            end_date_filter: this.end_date_filter,
            activeTab: status,
            status: status == "inprogress" ? "in-progress" : status,
            user_status: user_status,
            filterSearch: filterSearch,
          },
          api: api,
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            this.loading = false;
            return [];
          } else {
            this.loading = false;
            this.jobDatas = this.$store.getters.getResults.pageOfItems;
            this.totalRows = this.$store.getters.getResults.pager.totalItems;
            this.perPage = this.$store.getters.getResults.pager.pageSize;
            this.from = this.$store.getters.getResults.pager.from;
            this.to = this.$store.getters.getResults.pager.to;
            this.inprogressCount = this.$store.getters.getResults.inprogresscounts;
            this.upcomingCount = this.$store.getters.getResults.upcommingcounts;
            // this.vacancySet = this.$store.getters.getResults.vacancySet;
            Bus.$emit("get_em_job_count");
            //return this.jobDatas;
          }
        });
    },
    paginate(status, user_status) {
      this.loading = true;
      this.activeTab = status;
      if (this.activeTab == "upcoming") {
        this.upcoming = true;
        this.inprogress = false;
        this.completed = false;
        this.cancelled = false;
        this.all = false;
      } else if (this.activeTab == "completed") {
        this.completed = true;
        this.inprogress = false;
        this.upcoming = false;
        this.cancelled = false;
        this.all = false;
      } else if (this.activeTab == "cancelled") {
        this.cancelled = true;
        this.inprogress = false;
        this.upcoming = false;
        this.completed = false;
        this.all = false;
      } else if (this.activeTab == "all") {
        this.all = true;
        this.inprogress = false;
        this.upcoming = false;
        this.completed = false;
        this.cancelled = false;
      } else {
        this.inprogress = true;
        this.expired = false;
        this.upcoming = false;
        this.completed = false;
        this.cancelled = false;
        this.all = false;
      }
      (this.start_date = this.dateRange.startDate
        ? moment(this.dateRange.startDate).format("YYYY-MM-DD HH:mm:ss")
        : ""),
        (this.end_date = this.dateRange.endDate
          ? moment(this.dateRange.endDate).format("YYYY-MM-DD HH:mm:ss")
          : "");
      var api = this.activeTab == "all" ? "/api/adminDailyPaidJobsAll" : "/api/adminDailyPaidJobs";
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPage,
            rowsPerPage: this.form.rowsPerPage,
            userId: this.user_id,
            company_id: this.company_id,
            user_type_id: this.user_type_id,
            is_company_head: this.is_company_head,
            activeTab: status,
            status: status == "inprogress" ? "in-progress" : status,
            user_status: user_status,
            filterSearch: filterSearch,
          },
          api: api,
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            this.loading = false;
            return [];
          } else {
            this.loading = false;
            this.jobDatas = this.$store.getters.getResults.pageOfItems;
            this.totalRows = this.$store.getters.getResults.pager.totalItems;
            this.perPage = this.$store.getters.getResults.pager.pageSize;
            this.from = this.$store.getters.getResults.pager.from;
            this.to = this.$store.getters.getResults.pager.to;
            // this.inprogressCount = this.$store.getters.getResults.inprogresscounts.progressJobs
            // this.upcomingCount = this.$store.getters.getResults.upcommingcounts.upcomingJobs
            //return this.jobDatas;
          }
        });
    },

    // getList(status, user_status, filterSearch) {
    //   console.log("aga");
    //   this.loading = true;
    //   this.activeTab = status;
    //   if (this.activeTab == "upcoming") {
    //     this.upcoming = true;
    //     this.inprogress = false;
    //     this.completed = false;
    //     this.cancelled = false;
    //     this.all = false;
    //   } else if (this.activeTab == "completed") {
    //     this.completed = true;
    //     this.inprogress = false;
    //     this.upcoming = false;
    //     this.cancelled = false;
    //     this.all = false;
    //   } else if (this.activeTab == "cancelled") {
    //     this.cancelled = true;
    //     this.inprogress = false;
    //     this.upcoming = false;
    //     this.completed = false;
    //     this.all = false;
    //   } else if (this.activeTab == "all") {
    //     this.all = true;
    //     this.inprogress = false;
    //     this.upcoming = false;
    //     this.completed = false;
    //     this.cancelled = false;
    //   } else {
    //     this.inprogress = true;
    //     this.expired = false;
    //     this.upcoming = false;
    //     this.completed = false;
    //     this.cancelled = false;
    //     this.all = false;
    //   }
    //   (this.start_date = this.dateRange.startDate
    //     ? moment(this.dateRange.startDate).format("YYYY-MM-DD HH:mm:ss")
    //     : ""),
    //     (this.end_date = this.dateRange.endDate
    //       ? moment(this.dateRange.endDate).format("YYYY-MM-DD HH:mm:ss")
    //       : "");
    //   var api =
    //     this.activeTab == "all"
    //       ? "/api/adminDailyPaidJobsAll"
    //       : "/api/adminDailyPaidJobsInprogress";
    //   return this.$store
    //     .dispatch(POST_API, {
    //       data: {
    //         page: this.currentPage,
    //         rowsPerPage: this.form.rowsPerPage,
    //         companiesFilter: "all",
    //         company_id: this.company_id,
    //         user_type_id: this.user_type_id,
    //         is_company_head: this.is_company_head,
    //         activeTab: status,
    //         job_status: status == "inprogress" ? "in-progress" : status,
    //         selected_supervisor_id: "",
    //         filterSearch: filterSearch,
    //         start_date_filter: this.start_date,
    //         end_time: this.end_date,
    //         servicesFilter: "all",
    //         sortBy: "",
    //         sortDesc: false,
    //         sortDirection: "asc",
    //         userId: this.user_id,
    //       },
    //       api: api,
    //     })
    //     .then(() => {
    //       if (this.$store.getters.containsErrors) {
    //         this.error_message = this.$store.getters.getErrors;
    //         this.showAlert();
    //         this.loading = false;
    //         return [];
    //       } else {
    //         $(".dashboard-content-container .simplebar-scroll-content").animate(
    //           {
    //             scrollTop: 0,
    //           },
    //           "slow"
    //         );
    //         // this.loading = false;
    //         // this.jobDatas = this.$store.getters.getResults.pageOfItems;
    //         // this.totalRows = this.$store.getters.getResults.pager.totalItems;
    //         // this.perPage = this.$store.getters.getResults.pager.pageSize;
    //         // this.from = this.$store.getters.getResults.pager.from;
    //         // this.to = this.$store.getters.getResults.pager.to;
    //         // this.inprogressCount = this.$store.getters.getResults.inprogresscounts;
    //         // this.upcomingCount = this.$store.getters.getResults.upcommingcounts;
    //         // this.default_increment_in_vacancy =
    //         //   this.$store.getters.getResults.default_increment_in_vacancy;
    //         // this.vacancySet = this.$store.getters.getResults.vacancySet;
    //         //return this.jobDatas;
    //         if (this.$store.getters.getResults.data.data != undefined) {
    //           (this.items = this.$store.getters.getResults.data.data),
    //             (this.totalRows = this.$store.getters.getResults.data.total);
    //           this.perPage = this.$store.getters.getResults.data.per_page;
    //           this.from = this.$store.getters.getResults.data.from;
    //           this.to = this.$store.getters.getResults.data.to;
    //           this.company_id = this.$store.getters.getResults.data.data[0]
    //             ? this.$store.getters.getResults.data.data[0].company_id
    //             : "";
    //           this.user_id = this.$store.getters.getResults.user_id;
    //         } else {
    //           this.items = [];
    //           this.totalRows = 1;
    //           this.perPage = 25;
    //           this.from = "";
    //           this.to = "";
    //         }
    //       }
    //     });
    // },
    // resetModal() {
    //   this.form.id = "";
    //   this.supervisorName = "";
    // },
    // deleteJob(id) {
    //   this.$swal({
    //     title: "Please Confirm",
    //     text: "Are you sure you want to delete this record?",
    //     type: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     confirmButtonText: "Yes!",
    //   })
    //     .then((result) => {
    //       if (result.value) {
    //         return this.$store
    //           .dispatch(POST_API, {
    //             data: {
    //               id: id,
    //             },
    //             api: "/api/deleteJob",
    //           })
    //           .then(() => {
    //             if (this.$store.getters.containsErrors) {
    //               this.error_message = this.$store.getters.getErrors;
    //               this.showAlert();
    //             } else {
    //               var message = "Job Deleted successfully.";
    //               this.showSuccessAlert(message);
    //               window.location.replace("/v2/employer/manage-jobs");
    //             }
    //           });
    //       }
    //     })
    //     .catch((err) => {});
    // },
    // changeApplicantTabStatus(status, user_status, id, key, posted_by) {
    //   /** Start In Progress Active Tab Selection **/
    //   if (user_status == "selected") {
    //     this.activeApplicantTab = "selected";
    //     $("div#app .custom_tabs_daily_manage_jobs_inprogress .nav-item  a").removeClass("active");
    //     $("div#app .custom_tabs_daily_manage_jobs_inprogress .nav-item:nth-child(1) a").addClass(
    //       "active"
    //     );
    //   }
    //   if (user_status == "application") {
    //     $("div#app .custom_tabs_daily_manage_jobs_inprogress .nav-item  a").removeClass("active");
    //     $("div#app .custom_tabs_daily_manage_jobs_inprogress .nav-item:nth-child(2) a").addClass(
    //       "active"
    //     );
    //   }
    //   if (user_status == "cancelled") {
    //     $("div#app .custom_tabs_daily_manage_jobs_inprogress .nav-item  a").removeClass("active");
    //     $("div#app .custom_tabs_daily_manage_jobs_inprogress .nav-item:nth-child(3) a").addClass(
    //       "active"
    //     );
    //   }
    //   if (user_status == "rate_review") {
    //     $("div#app .custom_tabs_daily_manage_jobs_inprogress .nav-item  a").removeClass("active");
    //     $("div#app .custom_tabs_daily_manage_jobs_inprogress .nav-item:nth-child(4) a").addClass(
    //       "active"
    //     );
    //   }
    //   /** End In Progress Active Tab Selection **/
    //   /** Start Upcoming Active Tab Selection **/
    //   if (user_status == "selected") {
    //     this.activeApplicantTab = "selected";
    //     $("div#app .custom_tabs_daily_manage_jobs_upcoming .nav-item  a").removeClass("active");
    //     $("div#app .custom_tabs_daily_manage_jobs_upcoming .nav-item:nth-child(1) a").addClass(
    //       "active"
    //     );
    //   }
    //   if (user_status == "application") {
    //     $("div#app .custom_tabs_daily_manage_jobs_upcoming .nav-item  a").removeClass("active");
    //     $("div#app .custom_tabs_daily_manage_jobs_upcoming .nav-item:nth-child(2) a").addClass(
    //       "active"
    //     );
    //   }
    //   if (user_status == "cancelled") {
    //     $("div#app .custom_tabs_daily_manage_jobs_upcoming .nav-item  a").removeClass("active");
    //     $("div#app .custom_tabs_daily_manage_jobs_upcoming .nav-item:nth-child(3) a").addClass(
    //       "active"
    //     );
    //   }
    //   /** End Upcoming Active Tab Selection **/
    //   /** Start Completed Active Tab Selection **/
    //   if (user_status == "selected") {
    //     $("div#app .custom_tabs_daily_manage_jobs_completed .nav-item  a").removeClass("active");
    //     $("div#app .custom_tabs_daily_manage_jobs_completed .nav-item:nth-child(1) a").addClass(
    //       "active"
    //     );
    //   }
    //   if (user_status == "cancelled") {
    //     $("div#app .custom_tabs_daily_manage_jobs_completed .nav-item  a").removeClass("active");
    //     $("div#app .custom_tabs_daily_manage_jobs_completed .nav-item:nth-child(2) a").addClass(
    //       "active"
    //     );
    //   }
    //   if (user_status == "rate_review") {
    //     $("div#app .custom_tabs_daily_manage_jobs_completed .nav-item  a").removeClass("active");
    //     $("div#app .custom_tabs_daily_manage_jobs_completed .nav-item:nth-child(3) a").addClass(
    //       "active"
    //     );
    //   }
    //   /** End Completed Active Tab Selection **/
    //   /** Start All Active Tab Selection **/
    //   if (user_status == "selected") {
    //     this.activeApplicantTab = "selected";
    //     $("div#app .custom_tabs_daily_manage_jobs_all .nav-item  a").removeClass("active");
    //     $("div#app .custom_tabs_daily_manage_jobs_all .nav-item:nth-child(1) a").addClass("active");
    //   }
    //   if (user_status == "application") {
    //     $("div#app .custom_tabs_daily_manage_jobs_all .nav-item  a").removeClass("active");
    //     $("div#app .custom_tabs_daily_manage_jobs_all .nav-item:nth-child(2) a").addClass("active");
    //   }
    //   if (user_status == "cancelled") {
    //     $("div#app .custom_tabs_daily_manage_jobs_all .nav-item  a").removeClass("active");
    //     $("div#app .custom_tabs_daily_manage_jobs_all .nav-item:nth-child(3) a").addClass("active");
    //   }
    //   if (user_status == "applicant_withdrawn") {
    //     $("div#app .custom_tabs_daily_manage_jobs_all .nav-item  a").removeClass("active");
    //     $("div#app .custom_tabs_daily_manage_jobs_all .nav-item:nth-child(4) a").addClass("active");
    //   }
    //   if (user_status == "system_withdrawn") {
    //     $("div#app .custom_tabs_daily_manage_jobs_all .nav-item  a").removeClass("active");
    //     $("div#app .custom_tabs_daily_manage_jobs_all .nav-item:nth-child(5) a").addClass("active");
    //   }
    //   if (user_status == "rate_review") {
    //     $("div#app .custom_tabs_daily_manage_jobs_all .nav-item  a").removeClass("active");
    //     $("div#app .custom_tabs_daily_manage_jobs_all .nav-item:nth-child(6) a").addClass("active");
    //   }
    //   /** End All Active Tab Selection **/
    //   // $('.manage_job_new_tabs_layout .custom_tabs_daily_manage_jobs ul.nav.nav-tabs li a').removeClass('active');
    //   // $('div#app .manage_job_new_tabs_layout .custom_tabs_daily_manage_jobs ul.nav.nav-tabs li:nth-child(1) a').addClass('active');
    //   console.log(user_status, "userstay");
    //   this.activeTab = status;
    //   this.activeApplicantTab = user_status;
    //   // if(this.activeApplicantTab == 'selected'){
    //   //     this.selected = true;
    //   //     this.application = false;
    //   //     this.system_withdrawn = false;
    //   //     this.applicant_withdrawn = false;
    //   //     this.rate_review = false;
    //   // }
    //   // if(this.activeApplicantTab == 'selected'){
    //   //     this.upcoming = true;
    //   //     this.inprogress = false;
    //   //     this.completed = false;
    //   //     this.cancelled = false;
    //   //     this.all = false;
    //   // }else if(this.activeTab == 'completed'){
    //   //     this.completed = true;
    //   //     this.inprogress = false;
    //   //     this.upcoming = false;
    //   //     this.cancelled = false;
    //   //     this.all = false;
    //   // }else if(this.activeTab == 'cancelled'){
    //   //     this.cancelled = true;
    //   //     this.inprogress = false;
    //   //     this.upcoming = false;
    //   //     this.completed = false;
    //   //     this.all = false;
    //   // }else if(this.activeTab == 'all'){
    //   //     this.all = true;
    //   //     this.inprogress = false;
    //   //     this.upcoming = false;
    //   //     this.completed = false;
    //   //     this.cancelled = false;
    //   // }else{
    //   //     this.inprogress = true;
    //   //     this.expired = false;
    //   //     this.upcoming = false;
    //   //     this.completed = false;
    //   //     this.cancelled = false;
    //   //     this.all = false;
    //   // }
    //   if (this.activeTab == "upcoming") {
    //     this.upcoming = true;
    //     this.inprogress = false;
    //     this.completed = false;
    //     this.cancelled = false;
    //     this.all = false;
    //   } else if (this.activeTab == "completed") {
    //     this.completed = true;
    //     this.inprogress = false;
    //     this.upcoming = false;
    //     this.cancelled = false;
    //     this.all = false;
    //   } else if (this.activeTab == "cancelled") {
    //     this.cancelled = true;
    //     this.inprogress = false;
    //     this.upcoming = false;
    //     this.completed = false;
    //     this.all = false;
    //   } else if (this.activeTab == "all") {
    //     this.all = true;
    //     this.inprogress = false;
    //     this.upcoming = false;
    //     this.completed = false;
    //     this.cancelled = false;
    //   } else {
    //     this.inprogress = true;
    //     this.expired = false;
    //     this.upcoming = false;
    //     this.completed = false;
    //     this.cancelled = false;
    //     this.all = false;
    //   }
    //   return this.$store
    //     .dispatch(POST_API, {
    //       data: {
    //         jobId: id,
    //         // jobId:1887,
    //         status: status == "inprogress" ? "in-progress" : status,
    //         user_status: user_status,
    //         user_id: posted_by,
    //         filterSearchSelected: this.filterSearchSelected,
    //       },
    //       api: "/api/adminDailyPaidJobsById",
    //     })
    //     .then(() => {
    //       if (this.$store.getters.containsErrors) {
    //         this.error_message = this.$store.getters.getErrors;
    //         // this.showAlert();
    //         return [];
    //       } else {
    //         var userDatas =
    //           this.$store.getters.getResults.data.length > 0
    //             ? this.$store.getters.getResults.data[0].userJobs
    //             : [];
    //         var userJobsApplication =
    //           this.$store.getters.getResults.data.length > 0
    //             ? this.$store.getters.getResults.data[0].userJobsApplication
    //             : [];
    //         var userJobsApplicationWithdrawn =
    //           this.$store.getters.getResults.data.length > 0
    //             ? this.$store.getters.getResults.data[0].userJobsApplicationWithdrawn
    //             : [];
    //         var userJobsCancelled =
    //           this.$store.getters.getResults.data.length > 0
    //             ? this.$store.getters.getResults.data[0].userJobsCancelled
    //             : [];
    //         var userJobsSystemWithdrawn =
    //           this.$store.getters.getResults.data.length > 0
    //             ? this.$store.getters.getResults.data[0].userJobsSystemWithdrawn
    //             : [];
    //         var userJobsselected =
    //           this.$store.getters.getResults.data.length > 0
    //             ? this.$store.getters.getResults.data[0].userJobsselected
    //             : [];
    //         var inprogressCount =
    //           this.$store.getters.getResults.data.length > 0
    //             ? this.$store.getters.getResults.data[0].inprogressCount
    //             : [];
    //         var completedCount =
    //           this.$store.getters.getResults.data.length > 0
    //             ? this.$store.getters.getResults.data[0].completedCount
    //             : [];
    //         var awardCount =
    //           this.$store.getters.getResults.data.length > 0
    //             ? this.$store.getters.getResults.data[0].awardCount
    //             : [];
    //         var pendingCount =
    //           this.$store.getters.getResults.data.length > 0
    //             ? this.$store.getters.getResults.data[0].pendingCount
    //             : [];
    //         console.log(this.jobDatas, key, "jobData");
    //         this.jobDatas[key].userJobs = userDatas;
    //         this.jobDatas[key].userJobsApplication = userJobsApplication;
    //         this.jobDatas[key].userJobsApplicationWithdrawn = userJobsApplicationWithdrawn;
    //         this.jobDatas[key].userJobsCancelled = userJobsCancelled;
    //         this.jobDatas[key].userJobsSystemWithdrawn = userJobsSystemWithdrawn;
    //         this.jobDatas[key].userJobsselected = userJobsselected;
    //         this.jobDatas[key].completedCount = completedCount;
    //         this.jobDatas[key].inprogressCount = inprogressCount;
    //         this.jobDatas[key].pendingCount = pendingCount;
    //         this.jobDatas[key].awardCount = awardCount;
    //         // this.vacancySet = this.$store.getters.getResults.vacancySet;
    //         // return this.jobDatas.key;
    //       }
    //     });
    // },
    // changeStatus(id, status) {
    //   if (status == "active") {
    //     var msg = "Are you sure you want to inactivate this record?";
    //     var message = "Job Inactivated successfully.";
    //     var new_status = "inactive";
    //   } else {
    //     var msg = "Are you sure you want to activate this record?";
    //     var message = "Job Activated successfully.";
    //     var new_status = "active";
    //   }
    //   this.$swal({
    //     title: "Please Confirm",
    //     text: msg,
    //     type: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     confirmButtonText: "Yes",
    //     cancelButtonText: "No",
    //   })
    //     .then((result) => {
    //       if (result.value) {
    //         return this.$store
    //           .dispatch(POST_API, {
    //             data: {
    //               id: id,
    //               status: new_status,
    //               page: this.currentPage,
    //               keyword: this.filter,
    //             },
    //             api: "/api/changeJobStatus",
    //           })
    //           .then(() => {
    //             if (this.$store.getters.containsErrors) {
    //               this.error_message = this.$store.getters.getErrors;
    //               this.showAlert();
    //             } else {
    //               this.showSuccessAlert(message);
    //               this.changeTabStatus(this.activeTab, this.activeApplicantTab, this.filterSearch);
    //               this.getTotalCount();
    //               // window.location.replace('/v2/manage-jobs');
    //             }
    //           });
    //       }
    //     })
    //     .catch((err) => {});
    // },
    // featureJob(id, company_id) {
    //   if (this.remaining_feature_jobs == 0) {
    //     var msg =
    //       "Are you sure you want to feature this job? Since you do not have any remaining feature jobs so coins will be deducted from your account";
    //   } else {
    //     var msg =
    //       "Are you sure you want to feature this job? Since you have remaining feature jobs so coins will not be deducted";
    //   }
    //   var message = "Job featured successfully.";
    //   this.$swal({
    //     title: "Please Confirm",
    //     text: msg,
    //     type: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     confirmButtonText: "Yes",
    //     cancelButtonText: "No",
    //   })
    //     .then((result) => {
    //       if (result.value) {
    //         return this.$store
    //           .dispatch(POST_API, {
    //             data: {
    //               id: id,
    //               company_admin_id: this.company_admin_id ? this.company_admin_id : this.user_id,
    //               company_id: company_id,
    //               page: this.currentPage,
    //               keyword: this.filter,
    //             },
    //             api: "/api/featureJob",
    //           })
    //           .then(() => {
    //             if (this.$store.getters.containsErrors) {
    //               this.error_message = this.$store.getters.getErrors;
    //               this.showAlert();
    //               if ((this.error_message = "Insufficient Coins for featuring the job.")) {
    //                 this.$router.push({ name: "elcoins" });
    //               }
    //             } else {
    //               this.showSuccessAlert(message);
    //               this.changeTabStatus(this.activeTab, this.activeApplicantTab, this.filterSearch);
    //               this.getTotalCount();
    //               window.location.replace("/v2/employer/manage-jobs");
    //             }
    //           });
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    // manageCandidate(id) {
    //   window.location.replace("/v2/manage-candidates/" + id);
    // },
    // awardJob(userId, jobId, jobDatakey, posted_by) {
    //   this.$swal({
    //     title: "Please Confirm",
    //     text: "Are you sure to award this job.",
    //     type: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     confirmButtonText: "Yes!",
    //   }).then((result) => {
    //     if (result.value) {
    //       return this.$store
    //         .dispatch(POST_API, {
    //           data: {
    //             job_id: jobId,
    //             user_id: userId,
    //             employer_id: this.user_id,
    //             logged_user_id: this.logged_user_id,
    //           },
    //           api: "/api/awardUpcomingJobWeb",
    //         })
    //         .then(() => {
    //           if (this.$store.getters.containsErrors) {
    //             this.error_message = this.$store.getters.getErrors;
    //             this.showAlert();
    //             return [];
    //           } else {
    //             this.items = this.$store.getters.getResults.data;
    //             this.success_message = this.$store.getters.getResults.message;
    //             this.showSuccessAlert(this.success_message);
    //             // this.selected = true;
    //             this.changeApplicantTabStatus(
    //               this.activeTab,
    //               this.activeApplicantTab,
    //               jobId,
    //               jobDatakey,
    //               posted_by
    //             );
    //             // this.changeTabStatus(this.activeTab, this.activeApplicantTab, this.filterSearch);
    //             // getList()
    //             // window.location.reload();
    //             // this.getList(this.activeTab,'selected',this.filterSearch);
    //             // return this.items;
    //           }
    //         });
    //     }
    //   });
    // },
    // notSuitable(userId, jobId, key) {
    //   this.$swal({
    //     title: "Please Confirm",
    //     text: "Are you sure to remove this user.",
    //     type: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     confirmButtonText: "Yes!",
    //   }).then((result) => {
    //     if (result.value) {
    //       return this.$store
    //         .dispatch(POST_API, {
    //           data: {
    //             job_id: jobId,
    //             user_id: userId,
    //             logged_user_id: this.logged_user_id,
    //           },
    //           api: "/api/mw-not-suitable",
    //         })
    //         .then(() => {
    //           if (this.$store.getters.containsErrors) {
    //             this.error_message = this.$store.getters.getErrors;
    //             this.showAlert();
    //             return [];
    //           } else {
    //             this.keyVal = key;
    //             this.items = this.$store.getters.getResults.data;
    //             this.success_message = this.$store.getters.getResults.message;
    //             this.showSuccessAlert(this.success_message);
    //             this.activeApplicantTab = "application";
    //             console.log(this.keyVal);
    //             this.changeApplicantTabStatus(
    //               this.activeTab,
    //               this.activeApplicantTab,
    //               jobId,
    //               this.keyVal,
    //               userId
    //             );
    //             // this.changeTabStatus(this.activeTab, this.activeApplicantTab, this.filterSearch);
    //             //  window.location.reload();
    //             // this.getList('in-progress','selected',this.filterSearch);
    //             return this.items;
    //           }
    //         });
    //     }
    //   });
    // },
    // markArrival(userId, jobId) {
    //   this.$swal({
    //     title: "Please Confirm",
    //     text: "Are you sure to mark as arrival.",
    //     type: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     confirmButtonText: "Yes!",
    //   }).then((result) => {
    //     if (result.value) {
    //       return this.$store
    //         .dispatch(POST_API, {
    //           data: {
    //             job_id: jobId,
    //             user_id: userId,
    //             status: "in-progress",
    //             lat: "",
    //             long: "",
    //             from: "web",
    //             logged_user_id: this.logged_user_id,
    //           },
    //           api: "/api/markArrival",
    //         })
    //         .then(() => {
    //           if (this.$store.getters.containsErrors) {
    //             this.error_message = this.$store.getters.getErrors;
    //             this.showAlert();
    //             return [];
    //           } else {
    //             this.items = this.$store.getters.getResults.data;
    //             this.success_message = this.$store.getters.getResults.message;
    //             this.showSuccessAlert(this.success_message);
    //             this.getList("in-progress", "selected", this.filterSearch);
    //             window.location.reload();
    //             return this.items;
    //           }
    //         });
    //     }
    //   });
    // },
    // getEmployeeProfile() {
    //   return this.$store
    //     .dispatch(POST_API, {
    //       data: {
    //         id: this.user_id,
    //       },
    //       api: "/api/employee-profile",
    //     })
    //     .then(() => {
    //       if (this.$store.getters.containsErrors) {
    //         this.error_message = this.$store.getters.getErrors;
    //         this.showAlert();
    //         return [];
    //       } else {
    //         if (this.$store.getters.getResults.data) {
    //           this.acStatus = this.$store.getters.getResults.user["account_verification_status"];
    //           this.show_job_list_rate = this.$store.getters.getResults.data["show_job_list_rate"];
    //         } else {
    //           this.form.mobile = "";
    //           this.form.email = "";
    //         }
    //       }
    //     });
    // },
    // assignJobstouser(evt) {
    //   evt.preventDefault();
    //   this.hideModal();
    //   this.$store
    //     .dispatch(POST_API, {
    //       data: {
    //         user_id: this.supervisorName,
    //         job_id: this.job_id_data,
    //         logged_user_id: this.logged_user_id,
    //       },
    //       api: "/api/assignJobstouser",
    //     })
    //     .then(() => {
    //       if (this.$store.getters.containsErrors) {
    //         this.error_message = this.$store.getters.getErrors;
    //         this.showAlert();
    //       } else {
    //         this.success_message = this.$store.getters.getResults.message;
    //         this.$swal({
    //           position: "center",
    //           icon: "success",
    //           iconColor: "#4c8bf5",
    //           title: this.success_message,
    //           showConfirmButton: false,
    //           timer: 1500,
    //         });
    //         this.changeTabStatus(this.activeTab, this.activeApplicantTab, null);
    //       }
    //     });
    // },
    // openAttachSupModal(id) {
    //   this.$refs["attach-sup-modal"].show();
    //   this.job_id_data = id;
    //   return this.$store
    //     .dispatch(POST_API, {
    //       data: {
    //         user_id: this.user_id,
    //         job_id: this.job_id_data,
    //       },
    //       api: "/api/list-supervisors-to-assign-om-jobs",
    //     })
    //     .then(() => {
    //       if (this.$store.getters.containsErrors) {
    //         this.error_message = this.$store.getters.getErrors;
    //         this.showAlert();
    //       } else {
    //         this.selectedSupervisor = this.$store.getters.getResults.data;
    //         this.supervisorName = this.$store.getters.getResults.selected;
    //         return this.selectedSupervisor;
    //       }
    //     });
    // },
    // customLabel({ first_name, last_name }) {
    //   return `${last_name} ${first_name}`;
    // },
    // hideModal() {
    //   this.$refs["attach-sup-modal"].hide();
    // },
    // showQR(code, title, job_id) {
    //   this.$refs["qr-code-modal"].show();
    //   this.qrcode = code;
    //   this.qr_title = title;
    //   this.qr_job_id = job_id;
    // },
    // permissionEM() {
    //   if (this.$store.getters.getCurrentUserPermission) {
    //     if (
    //       this.$store.getters.getCurrentUserPermission.data.length > 0 &&
    //       this.$store.getters.currentUser.is_company_head == "no" &&
    //       (this.$store.getters.currentUser.user_type_id == 3 ||
    //         this.$store.getters.currentUser.user_type_id == 4)
    //     ) {
    //       var menu = this.$store.getters.getCurrentUserPermission.data;
    //       this.permissionActions = permission.getPermission(menu, "Manage Jobs");
    //     }
    //   }
    // },
    showPermissionEM(action) {
      if (
        this.$store.getters.currentUser.is_company_head == "no" &&
        (this.$store.getters.currentUser.user_type_id == 3 ||
          this.$store.getters.currentUser.user_type_id == 4)
      ) {
        return this.permissionActions.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showJobListRateAdmin(action) {
      if (
        this.$store.getters.currentUser.is_company_head == "yes" &&
        (this.$store.getters.currentUser.user_type_id == 3 ||
          this.$store.getters.currentUser.user_type_id == 4)
      ) {
        return this.show_job_list_rate == "yes" ? true : false;
      } else {
        return true;
      }
    },

    getJobdtb() {
      [{ posted_by: 1655 }];
    },
    returnEndDate(date, jobdate, breakTaken, hrs, extra_break_taken, extra_break_hrs) {
      //    console.log(date,jobdate,breakTaken,hrs,extra_break_taken,extra_break_hrs)
      if (date != null) {
        var time =
          breakTaken == "yes"
            ? moment(date).add(hrs, "hours").format("YYYY-MM-DD HH:mm:ss")
            : moment(date).format("YYYY-MM-DD HH:mm:ss");
        if (extra_break_taken == "yes") {
          var time1 = moment(time).add(extra_break_hrs, "hours").format("YYYY-MM-DD HH:mm:ss");
        } else {
          var time1 = time;
        }

        return moment(time1).format("hh:mm A");
      } else return "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    customLabel({ new_name, first_name, last_name, is_company_head }) {
      if (is_company_head == "yes") {
        return `${new_name}`;
      } else {
        return `${last_name} ${first_name}`;
      }
    },

    total_ext_cost(ext_cost, ext_hrs, type) {
      if (type == "cost") {
        var cost = ext_cost == null ? 0 : ext_cost;
        return parseFloat(cost);
      } else {
        ext_hrs = parseFloat(ext_hrs).toFixed(2);
        var ext = ext_hrs.split(".");
        // return ((ext[0] > 10 ? ext[0] : '0'+ext[0]) + ':' + (ext.length > 0 ? (ext[1] == '75' ? '45' : (ext[1] == '50' ? '30' : (ext[1] == '25' ? '15' : '00'))) : '00') );
        return (ext[0] > 10 ? ext[0] : "0" + ext[0]) + ":" + (ext.length > 0 ? ext[1] : "00");
      }
    },
    getSupervisorsList(company_id) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            company_id: company_id,
            userId: this.logged_user_id,
          },
          api: "/api/company-supervisors-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.selectedSupervisor = this.$store.getters.getResults.data.data;
            this.userType = this.$store.getters.currentUser;
            return this.selectedSupervisor;
          }
        });
    },
    // openModel(id, type) {
    //   this.sr_generate_for_job = id;
    //   this.$refs["sr-modal"].show();
    // },

    generateTS() {
      this.$swal
        .fire({
          title: "Please Confirm",
          text: "Do you want to re-generate Timesheet?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  user_id: this.sr_generate_for_job,
                  job_id: this.job_id,
                  type: "admin",
                  generateTs: this.generateTs,
                  with_email: this.withNoEmail,
                },
                api: "/api/generate-ts",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  this.showSuccessAlertStatus("TS generated Successfully");
                  this.getListing("selected");
                  this.$refs["sr-modal"].hide();
                }
              });
          }
        });
    },

    openModel(user_id, type, time, job_id) {
      this.getCostList();
      this.update_user_id = user_id;
      this.job_id = job_id;
      if (type == "modify") {
        this.$refs["modify-user-time-modal"].show();

        return this.$store
          .dispatch(POST_API, {
            data: {
              job_id: Vue.prototype.decrypt(this.$route.params.id),
              user_id: user_id,
              subjected_to_late_penalty: this.subjected_to_late_penalty,
            },
            api: "/api/getStartData",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
            } else {
              this.startData = this.$store.getters.getResults.data;

              this.is_extension = this.startData.extended_hours != null ? "yes" : "no";
              this.lunch_break = this.startData.break_hour_taken;
              this.lunch_pay_not_provided = this.lunch_break;
              this.lunch_pay_hours =
                this.startData.lunch_pay_hours == 0
                  ? this.startData.job_data_val.lunch_pay_hours
                  : this.startData.lunch_pay_hours;

              this.extra_break_taken = this.startData.extra_break_taken;
              this.extra_break_hrs = this.startData.extra_break_hrs;
              this.with_insurance = this.startData.opt_insurance;
              this.insurance_opted = this.startData.opt_insurance;

              if (this.is_extension == "yes") {
                var value = this.startData.extended_hours.split(".");
                var val1 = value[0] < 10 ? value[0] : value[0];
                // var val2 =  value[1] ? (value[1] == '75' ? '45' : ((value[1] == '25') ? '15' : ((value[1] == '5') ? '30' : '00'))) :"00";
                var val2 = value[1] ? value[1] : "00";

                // console.log(val1, val2);

                this.extension_hours = val1;
                this.extension_min = val2;
                this.extension_rate = this.startData.extension_rate;
              } else {
                this.extension_hours =
                  Math.floor(this.startData.extended_hours) == null
                    ? this.extension_hours
                    : Math.floor(this.startData.extended_hours);
                this.extension_min = "00";
                this.extension_rate = parseFloat(this.startData.job_data_val.hourly_cost);
              }

              (this.job_start_time.hh = moment(
                this.startData.job_data_val.start_date + " " + this.startData.start_time
              ).format("hh")),
                (this.job_start_time.mm = moment(
                  this.startData.job_data_val.start_date + " " + this.startData.start_time
                ).format("mm")),
                (this.job_start_time.A = moment(
                  this.startData.job_data_val.start_date + " " + this.startData.start_time
                ).format("A"));
              console.log(parseInt(this.lunch_pay_hours + this.extension_hours));
              this.job_start_date =
                this.startData.job_data_val.start_date + " " + this.startData.start_time != null
                  ? moment(
                      this.startData.job_data_val.start_date + " " + this.startData.start_time,
                      "YYYY-MM-DD"
                    ).toDate()
                  : "";
              // this.job_end_date = (this.startData.end_date+' '+ this.startData.end_time) != null ? (this.lunch_break == 'yes' ? moment((this.startData.end_date+' '+ this.startData.end_time)).subtract((this.lunch_pay_hours + this.extension_hours),'hours').toDate() : moment((this.startData.end_date+' '+ this.startData.end_time),'YYYY-MM-DD hh:mm:ss').toDate())  : ''
              this.job_end_date = this.returnEndDate1(
                this.startData.end_date + " " + this.startData.end_time,
                this.details.end_date + " " + this.details.end_time,
                this.lunch_break,
                this.lunch_pay_hours
              );

              (this.job_end_time.hh = moment(this.job_end_date).format("hh")),
                (this.job_end_time.mm = moment(this.job_end_date).format("mm")),
                (this.job_end_time.A = moment(this.job_end_date).format("A"));
            }
          });
      } else if (type == "generate_sr" || type == "generate_ts") {
        console.log(type, "jhdjhd");
        this.sr_generate_for_job = user_id;
        this.$refs["sr-modal"].show();
        this.generateTitle = type == "generate_sr" ? "Generate SR" : "Generate TS";
        if (type == "generate_ts") {
          this.generateTs = true;
        } else {
          this.generateTs = false;
        }
      } else if (type == "add_break") {
        this.$refs["add-break-modal"].show();
        this.lunch_pay_hours = this.details.lunch_pay_hours;
        this.break_type = time == "yes" ? "remove" : "add";
      } else {
        this.$refs["suspend-modal"].show();

        this.is_extension = this.details.extended_hours != null ? "yes" : "no";
        this.lunch_break = this.details.lunch_pay_not_provided;
        this.lunch_pay_not_provided = this.lunch_break;
        (this.lunch_pay_hours =
          this.lunch_pay_not_provided == "yes" ? this.details.lunch_pay_hours : 0),
          (this.job_start_time.hh = moment(this.details.start_date + " " + time).format("hh")),
          (this.job_start_time.mm = moment(this.details.start_date + " " + time).format("mm")),
          (this.job_start_time.A = moment(this.details.start_date + " " + time).format("A"));

        this.job_start_date =
          this.details.start_date + " " + time != null
            ? moment(this.details.start_date + " " + time, "YYYY-MM-DD").toDate()
            : "";

        if (this.is_extension == "yes") {
          var value = this.startData.extended_hours.split(".");
          var val1 = value[0] < 10 ? value[0] : value[0];
          // var val2 =  value[1] ? (value[1] == '75' ? '45' : ((value[1] == '25') ? '15' : ((value[1] == '5') ? '30' : '00'))) :"00";
          var val2 = value[1] ? value[1] : "00";

          // console.log(val1, val2);

          this.extension_hours = val1;
          this.extension_min = val2;
          this.extension_rate = this.details.extension_rate;
        } else {
          this.extension_hours =
            Math.floor(this.details.extended_hours) == 0
              ? this.extension_hours
              : Math.floor(this.details.extended_hours);
          this.extension_min = "00";
          this.extension_rate = parseFloat(this.details.hourly_cost);
        }
      }
    },
    withNoEmailFunc($event) {
      if ($event != null) {
        this.withNoEmail = $event;
      } else {
        this.withNoEmail = $event;
      }
    },
    generateTSFunc($event) {
      if ($event != null) {
        this.withEmailTs = $event;
      } else {
        this.withEmailTs = $event;
      }
    },
    generateSR() {
      this.$swal({
        title: "Please Confirm",
        text: "Do you want to re-generate Service Report?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.value) {
          return this.$store
            .dispatch(POST_API, {
              data: {
                job_id: this.sr_generate_for_job,
                type: "admin",
                with_email: this.withNoEmail,
                generateTs: this.generateTs,
              },
              api: "/api/generate-sr",
            })
            .then(() => {
              if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showAlert();
              } else {
                this.showSuccessAlert("SR generated Successfully");
                this.getListing("completed", "all", "all", "");
                this.$refs["sr-modal"].hide();
              }
            });
        }
      });
    },
    pdfViewer(link) {
      window.open(link);
    },
    dateFilterFunction(status) {
      this.getListing(
        status,
        this.form.filterServiceCategory,
        this.form.filterCompanyId,
        this.form.filterSearch
      );
    },
    reset(status, service, companyId, search) {
      this.dateRange.startDate = null;
      this.dateRange.endDate = null;

      this.form.filterServiceCategory = "all";
      this.form.filterCompanyId = "all";
      this.form.filterSearch = "null" ? "" : this.form.filterSearch;
      this.status = status;

      this.getListing(status, service, companyId, search);
    },
    changeStatus(status, service, companyId, search) {
      this.form.filterServiceCategory = "all";
      this.form.filterCompanyId = "all";
      this.form.filterSearch = "null" ? "" : this.form.filterSearch;
      this.status = status;

      this.getListing(status, service, companyId, search);

      // if (status == 'upcoming' || status == 'in-progress') {
      // this.getAllCompaniesByStatus();
      // }
    },
    addNote(id, company_id, supervisorName) {
      this.supervisorName = supervisorName;
      this.form.id = id;
      this.company_idVal = company_id;

      this.getSupervisorsList(company_id);
      this.$refs["add-note-modal"].show();
    },
    hideModal() {
      this.$refs["add-note-modal"].hide();
      this.$refs["broadcast-modal"].hide();
      this.$refs["share-modal"].hide();
      this.$refs["share-company-modal"].hide();
      this.$refs["share-modal-keyword"].hide();
      this.$refs["ot-modal"].hide();
      this.$refs["transaction-modal"].hide();
    },
    addNoteFormSubmit(evt) {
      evt.preventDefault();
      this.hideModal();
      this.$store
        .dispatch(POST_API, {
          data: {
            method: "POST",
            id: this.form.id,
            selected_supervisor_id: this.supervisorName,
          },

          api: "/api/supervisor-assigned-to-job",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            console.log(this.form.filterSearch, "ooo");
            this.form.id = "";
            this.supervisorName = "";
            // this.getListing(this.status, this.form.filterServiceCategory, this.form.filterCompanyId,this.form.fromSearch);
            this.getListing(
              this.status,
              this.form.filterServiceCategory,
              this.form.filterCompanyId,
              this.form.filterSearch
            );
            this.showSuccessAlert("Supervisor Assigned Successfully.");
          }
        });
    },
    resetModal() {
      this.form.id = "";
      this.supervisorName = "";
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
      this.$swal({
        position: "top-center",
        type: "error",
        text: this.error_message,
        showConfirmButton: false,
        timer: 3000,
      });
    },
    getListing(status, userServices, userCompanyId, filterData, user_id) {
      // this.items = [];

      this.currentActiveTab = status;
      (this.status = status),
        (this.form.filterSearch = filterData != "" ? filterData : ""),
        (this.form.filterServiceCategory = userServices != "" ? userServices : ""),
        (this.form.filterCompanyId = userCompanyId != "" ? userCompanyId : ""),
        (this.start_date = this.dateRange.startDate
          ? moment(this.dateRange.startDate).format("YYYY-MM-DD HH:mm:ss")
          : ""),
        (this.end_date = this.dateRange.endDate
          ? moment(this.dateRange.endDate).format("YYYY-MM-DD HH:mm:ss")
          : "");

      var api =
        this.currentActiveTab == "all" ? "api/all-jobs-list" : "/api/adminDailyPaidJobsInprogress";
      console.log(this.currentPage, "page");
      return this.$store
        .dispatch(POST_API, {
          data: {
            job_status: status ? status : "in-progress",
            selected_supervisor_id: this.supervisorName ? this.supervisorName : "",
            servicesFilter: userServices != "null" ? userServices : "all",
            companiesFilter: userCompanyId != "null" ? userCompanyId : "all",

            start_time: this.dateRange.startDate
              ? moment(this.dateRange.startDate).format("YYYY-MM-DD HH:mm:ss")
              : "",
            end_time: this.dateRange.endDate
              ? moment(this.dateRange.endDate).format("YYYY-MM-DD HH:mm:ss")
              : "",
            page: this.currentPage,
            keyword: filterData != "null" ? filterData : "",
            rowsPerPage: this.form.rowsPerPage,
            sortBy: this.sortBy,
            sortDirection: this.sortDirection,
            sortDesc: this.sortDesc,
            userId: this.logged_user_id,
          },
          api: api,
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();

            return [];
          } else {
            if (this.$store.getters.getResults.data.data != undefined) {
              (this.items = this.$store.getters.getResults.data.data),
                (this.totalRows = this.$store.getters.getResults.data.data.total);
              this.jobDatas = this.$store.getters.getResults.data.data.data;
              this.perPage = this.$store.getters.getResults.data.data.per_page;
              this.from = this.$store.getters.getResults.data.data.from;
              this.to = this.$store.getters.getResults.data.data.to;

              this.company_id = this.$store.getters.getResults.data.data[0]
                ? this.$store.getters.getResults.data.data[0].company_id
                : "";
              this.user_id = this.$store.getters.getResults.user_id;
            } else {
              this.items = [];
              this.totalRows = 1;
              this.perPage = 25;
              this.from = "";
              this.to = "";
            }
          }

          if (this.items.length > 0) {
            this.getAllCompaniesByStatus(this.status);
          }
        });
    },
    changeSearch(status, service, companyId, searchData) {
      if (status == "upcoming") {
        var search = $("#searchBtnUpcoming").val();
      } else if (status == "completed") {
        var search = $("#searchBtnCompleted").val();
      } else if (status == "cancelled") {
        var search = $("#searchBtnCancelled").val();
      } else if (status == "all") {
        var search = $("#searchBtnAll").val();
      } else {
        var search = $("#searchBtn").val();
      }

      this.form.filterServiceCategory = service;
      this.form.filterCompanyId = companyId;
      this.status = status;
      this.form.filterSearch = search;

      this.getListing(status, service, companyId, search);
    },
    changeServices(service, companyId, status, searchData) {
      this.form.filterServiceCategory = service;
      this.form.filterCompanyId = companyId;
      this.status = status;
      this.form.filterSearch = searchData;

      this.getListing(status, service, companyId, searchData);
    },

    changeCompanies(companyId, service, status, searchData) {
      this.form.filterServiceCategory = service;
      this.form.filterCompanyId = companyId;
      this.status = status;
      this.form.filterSearch = searchData;
      this.getListing(status, service, companyId, searchData);
    },

    getServiceCategory() {
      return this.$store
        .dispatch(POST_API, {
          api: "/api/service-categories-new-ft",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.serviceCategories = this.$store.getters.getResults.data;
            return this.serviceCategories;
          }
        });
    },
    getAllCompanies() {
      return this.$store
        .dispatch(POST_API, {
          data: {},
          api: "api/employer-for-ptuser",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.allCompanies = this.$store.getters.getResults.data;
            return this.allCompanies;
          }
        });
    },
    getAllCompaniesByStatus(status) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            status: this.status,
          },
          api: "api/employer-for-ptuser-by-tab-status",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.statusCompanies = this.$store.getters.getResults.data;

            return this.statusCompanies;
          }
        });
    },
    showSuccessAlert(message) {
      this.$swal({
        position: "top-center",
        type: "success",
        text: message,
        showConfirmButton: false,
        timer: 3000,
      });
    },
    permission() {
      if (JwtService.getMenuPermission()) {
        if (this.user_type_id == 2) {
          var menu = JSON.parse(JwtService.getMenuPermission());
          this.actions = getPermission(menu, "Manage Jobs");
        }
      }
    },
    show(action) {
      if (this.user_type_id == 2) {
        return this.actions.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    exportData() {
      var base_url = this.webUrl;
      var companiesFilter = this.form.filterCompanyId == "null" ? "all" : this.form.filterCompanyId;
      var start_date =
        this.dateRange.startDate != ""
          ? moment(this.dateRange.startDate).format("YYYY-MM-DD HH:mm:ss")
          : null;
      var end_date =
        this.dateRange.endDate != ""
          ? moment(this.dateRange.endDate).format("YYYY-MM-DD HH:mm:ss")
          : null;
      var search = this.form.filterSearch != "" ? this.form.filterSearch : null;
      var export_url =
        "/api/export-jobs/" +
        this.status +
        "/" +
        this.user_id +
        "/" +
        search +
        "/" +
        this.form.filterServiceCategory +
        "/" +
        companiesFilter +
        "/" +
        start_date +
        "/" +
        end_date;

      // console.log(this.status,this.user_id,search,this.form.filterServiceCategory,companiesFilter,start_date,end_date);
      window.open(base_url + export_url, "_blank");
    },
    exportJobByName() {
      var base_url = this.webUrl;
      var companiesFilter = this.form.filterCompanyId == "null" ? "all" : this.form.filterCompanyId;
      var start_date =
        this.dateRange.startDate != ""
          ? moment(this.dateRange.startDate).format("YYYY-MM-DD HH:mm:ss")
          : null;
      var end_date =
        this.dateRange.endDate != ""
          ? moment(this.dateRange.endDate).format("YYYY-MM-DD HH:mm:ss")
          : null;
      var search = this.form.filterSearch != "" ? this.form.filterSearch : null;
      var export_url =
        "/api/export-job-by-name/" +
        this.status +
        "/" +
        this.user_id +
        "/" +
        search +
        "/" +
        this.form.filterServiceCategory +
        "/" +
        companiesFilter +
        "/" +
        start_date +
        "/" +
        end_date;
      // console.log(companiesFilter, 'companiesFilter', search)

      if (
        companiesFilter != "null" &&
        companiesFilter != "all" &&
        search == null &&
        this.status == "all"
      ) {
        this.$swal({
          position: "top-center",
          type: "error",
          text: "Please enter Job ID",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        window.open(base_url + export_url, "_blank");
      }
      //console.log(companiesFilter);
    },
    getList(id, key, status, user_id) {
      console.log(id, key, status, this.logged_user_id, "hdhjdh");

      return this.$store
        .dispatch(POST_API, {
          data: {
            job_status: status,
            id: id,
            userId: this.logged_user_id,
          },
          api: "/api/view-jobs-list-inprogress",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            var job = this.$store.getters.getResults.data.data;
            console.log(this.items[key]);

            this.jobDatas.splice(key, 1);
            this.jobDatas.splice(key, 0, job);
          }
        });
    },
    showQR(code, title, job_id) {
      this.$refs["qr-code-modal"].show();
      this.qrcode = code;
      this.qr_title = title;
      this.qr_job_id = job_id;
    },

    qrf(url, user_id, type) {
      if (type == "download") window.open(url, "_blank");
      else {
        this.$store
          .dispatch(POST_API, {
            data: {
              company_id: url,
              id: user_id,
            },
            api: "api/generate-qr-code",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
            } else {
              this.items = this.$store.getters.getResults.data;
              this.success_message = this.$store.getters.getResults.message;

              this.$swal({
                position: "top-center",
                type: "success",
                text: this.success_message,
                showConfirmButton: false,
                timer: 3000,
              });
              //this.hideModel();
              this.getListing(this.status, "all", "all", "");
            }
          });
      }
    },
    hideModel() {
      this.$refs["sr-modal"].hide();
      this.$refs["remarks-modal"].hide();
    },
    resetVacancy(job_id, type) {
      if (type == "reset") {
        var msg = "Are you sure you want to reset vacancy?";
      } else {
        var msg = "Are you sure you want to update to full vacancy?";
      }
      this.$swal({
        title: "Please Confirm",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(POST_API, {
              data: {
                job_id: job_id,
                type: type,
                userId: this.logged_user_id,
              },

              api: "/api/reset-job-vacancy-v1",
            })
            .then(() => {
              if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showAlert();
              } else {
                this.getListing(
                  this.status,
                  this.form.filterServiceCategory,
                  this.form.filterCompanyId,
                  this.form.fromSearch
                );
                this.showSuccessAlert("Job Vacancy Reset Successfully.");
              }
            });
        }
      });
    },
    openBroadcastModal(job_id) {
      this.$refs["broadcast-modal"].show();
      this.broadcast_job_id = job_id;
      this.broadcast_date = "";
      this.broadcast_time = "";
    },

    async copyURLComp() {
      try {
        let copyText = document.querySelector("#copy-company-url");
        copyText.select();
        document.execCommand("copy");
        // var mytext = $('#copy-url').val();
        // navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
        //     if (result.state == "granted" || result.state == "prompt") {
        //         navigator.clipboard.writeText(mytext);
        //     }
        // });
        $(".share-comp-class").html("Copied!");
        $(".share-comp-class").prop("disabled", true);
        Snackbar.show({
          text: "Copied to clipboard!",
        });
        // alert('Copied to Clipboard.');
      } catch ($e) {
        console.log($e);

        // alert('Cannot copy');
      }
    },

    async copyURLKeyword() {
      try {
        let copyText = document.querySelector("#copy-keyword-url");
        if (copyText != "") {
          copyText.select();
          document.execCommand("copy");
          // var mytext = $('#copy-url').val();
          // navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
          //     if (result.state == "granted" || result.state == "prompt") {
          //         navigator.clipboard.writeText(mytext);
          //     }
          // });
          $(".share-key-class").html("Copied!");
          $(".share-key-class").prop("disabled", true);
        }
        // Snackbar.show({
        //     text: 'Copied to clipboard!',
        // });
        // alert('Copied to Clipboard.');
      } catch ($e) {
        console.log($e);

        // alert('Cannot copy');
      }
    },

    async copyURL() {
      try {
        let copyText = document.querySelector("#copy-url");
        copyText.select();
        document.execCommand("copy");
        // var mytext = $('#copy-url').val();
        // navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
        //     if (result.state == "granted" || result.state == "prompt") {
        //         navigator.clipboard.writeText(mytext);
        //     }
        // });
        $(".share-job-class").html("Copied!");
        $(".share-job-class").prop("disabled", true);

        Snackbar.show({
          text: "Copied to clipboard!",
        });
        // alert('Copied to Clipboard.');
      } catch ($e) {
        console.log($e);

        // alert('Cannot copy');
      }
    },

    openShareJobModal(job_id, comp_id, comp_name, alias, postedby, job_alias) {
      console.log(process.env.MIX_APP_URL_V2_WEB);

      this.$refs["share-modal"].show();
      this.share_job_id = job_id;
      this.share_company_id = comp_id;
      this.share_company_name = comp_name;
      this.job_url =
        process.env.MIX_APP_URL_V2_WEB +
        "job-daily-paid?jobId=" +
        btoa(job_id) +
        "&company_name=" +
        alias +
        "&job_alias=" +
        job_alias;
      this.company_url =
        process.env.MIX_APP_URL_V2_WEB +
        "job-daily-paid-comp?compId=" +
        btoa(comp_id) +
        "&comp_name=" +
        btoa(comp_name) +
        "&emp_id=" +
        btoa(postedby);
    },
    openShareJobModalKeyword() {
      // console.log(process.env.MIX_APP_URL_V2_WEB);
      this.$refs["share-modal-keyword"].show();
      // this.share_job_id = job_id;
      // this.job_url =  process.env.MIX_APP_URL_V2_WEB + "job-daily-paid?jobId=" + btoa(job_id);
      // this.company_url =  process.env.MIX_APP_URL_V2_WEB + "job-daily-paid-comp?compId=" + btoa(comp_id) +"&comp_name="+btoa(comp_name);
    },

    searchUrl() {
      $(".share-key-class").html("Copy URL");
      $(".share-key-class").prop("disabled", false);
      var url =
        process.env.MIX_APP_URL_V2_WEB +
        "job-daily-paid-list?filter=" +
        btoa(this.search) +
        "&category=" +
        this.share_service_id;
      // var url = process.env.MIX_APP_URL_V2_WEB + "singapore-daily-paid-part-time-jobs/" + (this.share_service_id) + "/"+(this.search);;

      $("#copy-keyword-url").val(url);
      this.keyword_url = url;
    },

    openShareCompanyModal(comp_id) {
      console.log(comp_id);

      this.$refs["share-company-modal"].show();
      this.share_company_id = comp_id;
      this.company_url =
        process.env.MIX_APP_URL_V2_WEB + "job-daily-paid-comp?compId=" + btoa(comp_id);
    },
    broadcastFormSubmit(evt) {
      evt.preventDefault();
      this.$store
        .dispatch(POST_API, {
          data: {
            job_id: this.broadcast_job_id,
            title: this.notif_title,
            description: this.notif_description,
            broadcast_date: this.broadcast_date,
            broadcast_time: this.broadcast_time,
          },

          api: "/api/broadcast-job-notification-by-admin-v1",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.broadcast_job_id = "";
            this.notif_title = "";
            this.notif_description = "";
            this.hideModal();
            this.getListing(
              this.status,
              this.form.filterServiceCategory,
              this.form.filterCompanyId,
              this.form.fromSearch
            );
            this.showSuccessAlert("Notification Broadcast Successfully.");
          }
        });
    },
    openFulltimeRemarks(remarks) {
      this.$refs["remarks-modal"].show();
      this.fulltime_remarks = remarks;
    },
    onFlInput(value) {
      console.log(value);
      var arr = value.id;
      this.selected_mw = arr;

      console.log(this.selected_mw, "selecred");
    },
    asyncFind(query) {
      this.isLoading = true;
      this.getMwList(query).then((response) => {
        this.mwSearch = response;
        this.isLoading = false;
      });
    },
    getMwList(response, type) {
      console.log(response, "response", this.broadcast_job_id);
      return this.$store
        .dispatch(POST_API, {
          data: {
            keyword: response,
            job_id: this.broadcast_job_id,
            type: type,
          },
          api: "/api/completed-mw-list-v1",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.mwList = this.$store.getters.getResults.data.data;
            // this.subCatg(this.service_id);
            return this.mwList;
          }
        });
    },
    isInteger(num) {
      return num.toString().indexOf(".") === -1;
    },
    openOTModal(value, type) {
      this.ot_type = type;
      this.$refs["ot-modal"].show();
      this.ot_jobs_array = value;
      console.log(value);
      if (type == "view") {
        // if(value.status == 'active'){
        //     this.$router.push({ name: 'ViewJobDetailUpcomingAdmin', params: {id: Vue.prototype.encrypt(value.id),from:('upcoming'),search:('null'),companyFilter:('all'),serviceCategory:('all')}});
        // }else if(value.status == 'in-progress'){
        //     this.$router.push({ name: 'ViewJobDetailInprogressAdmin', params: {id: Vue.prototype.encrypt(value.id),from:('in-progress'),search:('null'),companyFilter:('all'),serviceCategory:('all')}});
        // }else if(value.status == 'completed'){
        //     this.$router.push({ name: 'ViewJobDetailCompletedAdmin', params: {id: Vue.prototype.encrypt(value.id),from:('completed'),search:('null'),companyFilter:('all'),serviceCategory:('all')}});
        // }else{
        //     this.$router.push({ name: 'ViewJobDetailCancelledAdmin', params: {id: Vue.prototype.encrypt(value.id),from:('cancelled'),search:('null'),companyFilter:('null'),serviceCategory:('all')}});
        // }
      } else {
        this.getCostList();
        this.broadcast_job_id = value.id;
        this.title = value.title;
        var sum_hrs = parseFloat(value.working_hours) + parseFloat(value.lunch_pay_hours);

        if (value.lunch_pay_not_provided == "yes") {
          this.working_hours = this.isInteger(sum_hrs) ? Math.floor(sum_hrs) : Math.floor(sum_hrs);
        } else {
          this.working_hours = this.isInteger(value.working_hours)
            ? value.working_hours
            : Math.floor(value.working_hours);
        }

        var h = sum_hrs.toString();

        var split = this.isInteger(h) ? [h] : h.split(".");
        console.log(split, 1);
        if (split.length > 1) {
          if (split[1] == "25") {
            // this.form.hours = (this.form.hours - 1);
            this.working_minutes = "30";
          } else {
            this.working_minutes = split[1] == "75" ? "45" : split[1] == "5" ? "30" : "00";
          }
        } else {
          this.working_minutes = "00";
        }

        // this.lunch_pay_not_provided = value.lunch_pay_not_provided;
        this.lunch_pay_hours = value.lunch_pay_not_provided == "yes" ? value.lunch_pay_hours : 0;
        this.vacancy = value.total_vacancies;
        this.price = parseFloat(value.hourly_cost);
        this.selected_mw = [];
        this.selected_mw_arr = "";
        this.is_perm_partime = "no";
        this.selectedJob = value;
        this.selectAll = false;
        this.getMwList("", "for_ot_jobs");
      }
    },
    getCostList() {
      return this.$store
        .dispatch(POST_API, {
          api: "/api/per-hour-cost-listing",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.costListing = this.$store.getters.getResults.data;
            return this.costListing;
          }
        });
    },

    submitOTJob(evt) {
      evt.preventDefault();

      if (this.vacancy < this.selected_mw.length) {
        this.error_message = "You have selected FLs more than vacancy.";
        this.showAlert();
      } else {
        this.disabledBtn = true;
        this.$store
          .dispatch(POST_API, {
            data: {
              job_id: this.broadcast_job_id,
              title: this.title,
              vacancy: this.vacancy,
              working_hours:
                this.working_minutes == "00" ||
                this.working_minutes == "" ||
                this.working_minutes == null
                  ? this.working_hours + ".0"
                  : this.working_minutes == "45"
                  ? this.working_hours + ".75"
                  : this.working_hours + ".5",
              lunch_pay_hours: this.lunch_pay_not_provided == "yes" ? this.lunch_pay_hours : 0,
              lunch_pay_not_provided: this.lunch_pay_not_provided,
              price: this.price,
              create_ot_job: "yes",
              user_id: this.selected_mw,
              user_id_arr: this.selected_mw_arr,
              is_perm_partime: this.is_perm_partime,
              job_time: this.job_time,
            },

            api: "/api/create-ot-job",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.disabledBtn = false;
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
            } else {
              this.success_message = this.$store.getters.getResults.message;
              var job_id = this.$store.getters.getResults.data.job_id;
              this.broadcast_job_id = "";
              this.notif_title = "";
              this.notif_description = "";
              this.hideModal();
              this.disabledBtn = false;

              this.getListing(
                this.status,
                this.form.filterServiceCategory,
                this.form.filterCompanyId,
                this.form.fromSearch
              );
              this.showSuccessAlert(this.success_message);
              window.open(
                "/admin/jobs/view-jobsDetail-inprogress/" +
                  Vue.prototype.encrypt(job_id) +
                  "/in-progress/null/all/all",
                "_blank"
              );

              // this.$router.push({ name: 'ViewJobDetailInprogressAdmin', params: {id: Vue.prototype.encrypt(job_id),from:('in-progress'),search:('null'),companyFilter:('all'),serviceCategory:('all')}});
            }
          });
      }
    },
    awardMultipleUsers(job_id){

          this.$swal.fire({
            title: 'Please Confirm',
            text: 'Are you sure to award the job to these applicants ?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!'
            })
            .then(result => {
                if (result.value) {
                    return this.$store.dispatch(POST_API, {
                        data:{
                            user_id:this.selectedUser,
                            job_id:job_id,
                            add_with:'as_selected',
                            award_multiple: true

                        },
                        api: '/api/add-multiple-fl'
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showAlert();
                            return [];
                        } else {
                            this.success_message = this.$store.getters.getResults.message;
                            this.showSuccessAlertStatus(this.success_message);
                            this.getListing('all');

                        }
                    });
                }
            })

        },
    getBillingType() {
      return this.$store
        .dispatch(POST_API, {
          api: "/api/get-billing-types",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.billing_types = this.$store.getters.getResults.data.data;
            return this.billing_types;
          }
        });
    },
    openTransactionModal(value, company_name, job_id, type) {
      this.$refs["transaction-modal"].show();
      this.remarks = company_name + "-" + job_id + "-Transport-Reimbursement";
      this.broadcast_job_id = value;
      this.tr_type = type;
      this.amount = 0;
      this.internal_remarks = "";
      this.selected_mw = [];
      this.selected_mw_arr = "";
      this.billable = "no";
      this.selectAll = false;
      this.billing_type = "";
      this.fl_type = "";

      this.getMwList("", "for_transport_provided_jobs");
      this.getBillingType();
    },
    updateName() {
      this.billing_types.map((item) => {
        if (item.id == this.billing_type) {
          this.billable = item.billable;
          this.internal_remarks = item.name;
        }
      });
    },
    selectFLType(type) {
      var mwList = [];

      this.mwList.map((item) => {
        if (type == "cancelled") {
          if (item.job_status == "cancelled") {
            mwList.push(item);
          }
        } else if (type == "selected") {
          if (item.job_status != "cancelled") {
            mwList.push(item);
          }
        } else {
          mwList.push(item);
        }
      });

      this.selected_mw_arr = [];
      this.selected_mw = [];

      this.selected_mw = mwList.map((item) => {
        return item.id;
      });
      this.selected_mw_arr = mwList;

      console.log(
        mwList,
        "this.mwList",
        this.fl_type,
        this.selected_mw_arr,
        this.selected_mw,
        this.mwList
      );
    },
    submitTransaction(evt) {
      evt.preventDefault();

      this.disabledBtn = true;
      this.$store
        .dispatch(POST_API, {
          data: {
            job_id: this.broadcast_job_id,
            user_id: this.selected_mw,
            amount: this.amount,
            billable: this.billable != null ? this.billable : "no",
            billing_type: this.billing_type,
            remarks: this.remarks,
            type: this.tr_type,
            internal_remarks: this.internal_remarks,
            userId: this.logged_user_id,
          },

          api: "/api/create-multiple-transaction",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.disabledBtn = false;
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.success_message = this.$store.getters.getResults.message;
            this.broadcast_job_id = "";
            this.remarks = "";
            this.amount = 0;
            this.internal_remarks = "";
            this.selected_mw = [];
            this.selected_mw_arr = "";
            this.billable = "no";
            this.tr_type = "in-progress";
            this.selectAll = false;
            this.billing_type = "";
            this.fl_type = "";

            this.hideModal();
            this.disabledBtn = false;

            this.getListing(
              this.status,
              this.form.filterServiceCategory,
              this.form.filterCompanyId,
              this.form.fromSearch
            );
            this.showSuccessAlert(this.success_message);
          }
        });
    },
    editabledate(index, type) {
      this.items[index].enable_vacancies = "yes";
      this.editable_item = type;

      console.log(index, this.items, this.editable_item);
    },
    updateData(item, column) {
      console.log(item, column);

      return this.$store
        .dispatch(POST_API, {
          data: {
            job_id: item.id,
            item: item,
            column: column,
          },
          api: "/api/update-jobs-item",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            this.getListing(
              this.status,
              this.form.filterServiceCategory,
              this.form.filterCompanyId,
              this.form.fromSearch
            );
          } else {
            this.getListing(
              this.status,
              this.form.filterServiceCategory,
              this.form.filterCompanyId,
              this.form.fromSearch
            );
          }
        });
    },

    addUserJobsRemark() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            job_id: this.job_id,
            user_id: this.update_user_id,
            remarks: this.remarks_type == "suspend" ? this.remarks : this.user_jobs_remark,
            type: this.remarks_type,
          },
          api: "/api/add-fl-job-remarks",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            this.items = this.$store.getters.getResults.data;
            this.success_message = this.$store.getters.getResults.message;
            this.remarks = "";
            this.showSuccessAlert(this.success_message);
            this.hideModel();

            this.getListing("selected");
            return this.items;
          }
        });
    },

    openRemarksModel(user_id, type, remarks, job_id) {
      this.$refs["user-jobs-remark-modal"].show();
      this.user_jobs_remark = remarks;
      this.job_id = job_id;
      this.update_user_id = user_id;
      this.remarks_type = type;
    },
    onfocusout(index) {
      this.items[index].enable_vacancies = "no";
    },
    viewFl(id) {
      console.log(this.v2WebUrl, "xyz");

      window.open(this.v2WebUrl + "/admin/database/jobseeker-dp-profile/view/" + id, "_blank");
    },
    cancelJob(id) {
      // this.form.username = name;
      this.form.job_cancellation_reason = "";
      this.job_id = id;
      this.$refs["job-cancel-modal"].show();
    },
    onSubmitCancelJob(evt) {
      evt.preventDefault();

      this.$refs["job-cancel-modal"].hide();

      this.$store
        .dispatch(POST_API, {
          data: {
            job_id: this.job_id,
            cancellation_reason: this.form.job_cancellation_reason,
            is_inProgress: true,
            userId: this.logged_user_id,
          },
          api: "/api/cancel-job-api-v1",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            this.form.job_cancellation_reason = "";
            // this.$bvModal.msgBoxOk('Company Profile Rejected Successfully')
            //this.$refs['job-cancel-modal'].hide();

            this.items = this.$store.getters.getResults.data;
            this.$swal({
              position: "top-center",
              type: "success",
              text: "Job Cancelled Successfully.",
              showConfirmButton: false,
              timer: 3000,
            });
            // Toast.fire({
            //     type: 'success',
            //     title: 'Job Cancelled Successfully.',
            // });
            this.$router.push({ name: "ManageJobsAdmin" });
            return this.items;
          }
        });
    },
    onScreenResize() {
      window.addEventListener("resize", () => {
        this.updateScreenWidth();
      });
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
    showMobileActionModel(item, type) {
      this.selectedTab = type;
      console.log(item, "hello");
      $(".mobile_loader").show();
      this.modelitems = {};
      this.modelitems = item;
      setTimeout(() => {
        this.$refs["job-detail-mobile-action-model"].show();
        var x = $(".mobile_loader").css("display");
        if (x != "none") {
          $(".mobile_loader").hide();
        }
      }, 1500);
    },
    returnEndDate(date, jobdate, breakTaken, hrs, extra_break_taken, extra_break_hrs) {
      //    console.log(date,jobdate,breakTaken,hrs,extra_break_taken,extra_break_hrs)
      if (date != null) {
        var time =
          breakTaken == "yes"
            ? moment(date).add(hrs, "hours").format("YYYY-MM-DD HH:mm:ss")
            : moment(date).format("YYYY-MM-DD HH:mm:ss");
        if (extra_break_taken == "yes") {
          var time1 = moment(time).add(extra_break_hrs, "hours").format("YYYY-MM-DD HH:mm:ss");
        } else {
          var time1 = time;
        }

        return moment(time1).format("hh:mm A");
      } else return "";
    },
    returnEndDate1(date, jobdate, breakTaken, hrs) {
      // console.log(date,jobdate,hrs);
      if (date != null) {
        var time =
          breakTaken == "yes"
            ? moment(date).add(hrs, "hours").format("YYYY-MM-DD HH:mm:ss")
            : moment(date).format("YYYY-MM-DD HH:mm:ss");
        // console.log(time);
        if (time > jobdate) {
          return jobdate;
        } else {
          return time;
        }
      } else return "";
    },
    customFormatterTwo(date) {
      // console.log(moment(date).format('DD-MM-YYYY'));
      return moment(date).format("DD-MM-YYYY");
    },
    countDownChangedRate(dismissCountDownRate) {
      this.dismissCountDownRate = dismissCountDownRate;
    },
    cancelApplicant(id) {
      // this.form.username = name;
      this.form.cancellation_reason = "";
      this.form.user_id = id;
      this.$refs["cancel-modal"].show();
    },
    late_penalty($event) {
      if ($event != null) {
        this.subjected_to_late_penalty = $event;
      } else {
        this.subjected_to_late_penalty = $event;
      }
    },
    acknowledgeApplicant(user_id) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            job_id: Vue.prototype.decrypt(this.$route.params.id),
            user_id: user_id,
            user: "admin",
          },
          api: "/api/acknowledged-by-mw",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            //this.startData = this.$store.getters.getResults.data;

            this.success_message = this.$store.getters.getResults.message;
            this.showSuccessAlert(this.success_message);
            this.getListing("all");
            this.applicantActive = true;

            //this.$router.push({ name: 'ViewJobDetailInprogressAdmin' });
          }
        });
    },

    viewMoreInfo(user_id, job_id) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            job_id: job_id,
            user_id: user_id,
          },
          api: "/api/get-user-more-info",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.$refs["info-modal"].show();

            this.moreInfo = this.$store.getters.getResults.data;
            console.log(this.moreInfo, "kk");
          }
        });
    },

    openArrivalModel(user_id, type, multiple, job_id) {
      this.job_id = job_id;
      if (type == "adjust_arrival") {
        this.$refs["adjust-arrival-modal"].show();
      } else {
        this.arrival_condition = type;
        this.arrival_model_title =
          this.arrival_condition == "arrival" ? "Adjust Arrival Time" : "Revive User";

        this.$refs["arrival-modal"].show();
      }

      this.multiple = multiple;
      this.update_user_id = user_id;

      return this.$store
        .dispatch(POST_API, {
          data: {
            job_id: job_id,
            user_id: this.multiple
              ? this.selectedUser.length > 0
                ? this.selectedUser[0]
                : ""
              : this.update_user_id,
            subjected_to_late_penalty: this.subjected_to_late_penalty,
            userId: this.logged_user_id,
          },
          api: "/api/getStartData",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.startData = this.$store.getters.getResults.data;

            if (type != "adjust_arrival") {
              (this.arrival_time.hh = moment(
                this.startData.job_data_val.start_date +
                  " " +
                  this.startData.job_data_val.start_time
              ).format("hh")),
                (this.arrival_time.mm = moment(
                  this.startData.job_data_val.start_date +
                    " " +
                    this.startData.job_data_val.start_time
                ).format("mm")),
                (this.arrival_time.A = moment(
                  this.startData.job_data_val.start_date +
                    " " +
                    this.startData.job_data_val.start_time
                ).format("A"));
            } else {
              this.is_extension = this.startData.extended_hours != null ? "yes" : "no";
              this.lunch_break = this.startData.break_hour_taken;
              this.lunch_pay_not_provided = this.lunch_break;
              this.insurance_opted = this.startData.opt_insurance;

              (this.lunch_pay_hours =
                this.startData.lunch_pay_hours == 0
                  ? this.startData.job_data_val.lunch_pay_hours
                  : this.startData.lunch_pay_hours),
                (this.job_start_time.hh = moment(
                  this.startData.job_data_val.start_date + " " + this.startData.start_time
                ).format("hh")),
                (this.job_start_time.mm = moment(
                  this.startData.job_data_val.start_date + " " + this.startData.start_time
                ).format("mm")),
                (this.job_start_time.A = moment(
                  this.startData.job_data_val.start_date + " " + this.startData.start_time
                ).format("A"));

              this.job_start_date =
                this.startData.job_data_val.start_date + " " + this.startData.start_time != null
                  ? moment(
                      this.startData.job_data_val.start_date + " " + this.startData.start_time,
                      "YYYY-MM-DD"
                    ).toDate()
                  : "";
              // this.job_end_date = (this.startData.end_date+' '+ this.startData.end_time) != null ? (this.lunch_break == 'yes' ? moment((this.startData.end_date+' '+ this.startData.end_time)).add(this.lunch_pay_hours,'hours').toDate() : moment((this.startData.end_date+' '+ this.startData.end_time),'YYYY-MM-DD hh:mm:ss').toDate())  : ''
              this.job_end_date = this.returnEndDate1(
                this.startData.end_date + " " + this.startData.end_time,
                this.details.end_date + " " + this.details.end_time,
                this.lunch_break,
                this.lunch_pay_hours
              );

              (this.job_end_time.hh = moment(this.job_end_date).format("hh")),
                (this.job_end_time.mm = moment(this.job_end_date).format("mm")),
                (this.job_end_time.A = moment(this.job_end_date).format("A"));

              this.extra_break_taken = this.startData.extra_break_taken;
              this.extra_break_hrs = this.startData.extra_break_hrs;
              this.user_jobs_remark = this.startData.remarks;

              if (this.is_extension == "yes") {
                var value = this.startData.extended_hours.split(".");
                var val1 = value[0] < 10 ? value[0] : value[0];
                // var val2 =  value[1] ? (value[1] == '75' ? '45' : ((value[1] == '25') ? '15' : ((value[1] == '5') ? '30' : '00'))) :"00";
                var val2 = value[1] ? value[1] : "00";

                // console.log(val1, val2);

                this.extension_hours = val1;
                this.extension_min = val2;
                this.extension_rate = this.startData.extension_rate;
              } else {
                this.extension_hours = "";
                this.extension_min = "00";
                this.extension_rate = parseFloat(this.startData.job_data_val.hourly_cost);
              }
            }
          }
        });
    },
    onArrivalTimeSubmit() {
      var api =
        this.arrival_condition == "arrival" ? "/api/update-ptuser-arrival-time" : "/api/revival";

      if (this.selectedUser.length > 30 && this.multiple == true) {
        this.error_message = "Maximum 30 FLs can be selected in one time.";
        this.showAlert();
      } else {
        return this.$store
          .dispatch(POST_API, {
            data: {
              job_id: this.job_id,
              user_id: this.multiple ? this.selectedUser : this.update_user_id,
              subjected_to_late_penalty: this.subjected_to_late_penalty,
              start_time: this.arrival_time,
              job_type: "in-progress",
              revive_with: this.user_revived_with,
              type: this.user_revived_with == "as_selected" ? "revive_job" : "",
              arrival_time_next_day: this.arrival_time_next_day,
              notify_fl: this.notify_fl,
              multiple: this.multiple,
              userId: this.logged_user_id,
            },
            api: api,
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              Swal.fire({
                position: "top-center",
                type: "error",
                text: this.error_message,
                showConfirmButton: false,
                timer: 3000,
              });
            } else {
              this.success_message = this.$store.getters.getResults.message;

              this.$refs["arrival-modal"].hide();
              this.selectActive = true;
              this.selectedUser = [];
              this.selectAll = false;
              this.selectAllCompleted = false;

              this.getList(this.job_id, "", "in-progress", this.user_id);

              // this.getListing("selected");
              this.$swal.fire({
                position: "top-center",
                type: "success",
                text: this.success_message,
                showConfirmButton: false,
                timer: 3000,
              });
            }
          });
      }
    },
    onAdjustTimeSubmit() {
      if (this.selectedUser.length > 20 && this.multiple == true) {
        this.error_message = "Maximum 20 FLs can be selected in one time.";
        this.showAlert();
      } else {
        return this.$store
          .dispatch(POST_API, {
            data: {
              job_id: this.job_id,
              user_id: this.multiple ? this.selectedUser : this.update_user_id,
              start_time: this.job_start_time,
              end_time: this.job_end_time,
              job_type: "completed",
              arrival_time_next_day: this.arrival_time_next_day,
              notify_fl: this.notify_fl,
              multiple: this.multiple,
              modify_time: this.modify_time,
              lunch_pay_not_provided: this.lunch_pay_not_provided,
              lunch_pay_hours: this.lunch_pay_not_provided == "yes" ? this.lunch_pay_hours : 0,
            },
            api: "api/adjust-arrival-time-with-modify",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              Swal.fire({
                position: "top-center",
                type: "error",
                text: this.error_message,
                showConfirmButton: false,
                timer: 3000,
              });
            } else {
              this.success_message = this.$store.getters.getResults.message;

              this.$refs["adjust-arrival-modal"].hide();
              this.selectActive = true;
              this.selectedUser = [];
              this.selectAll = false;
              this.selectAllCompleted = false;

              this.getListing("selected");
              Swal.fire({
                position: "top-center",
                type: "success",
                text: this.success_message,
                showConfirmButton: false,
                timer: 3000,
              });
            }
          });
      }
    },
    withdrawApplicant(user_id, job_id) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            job_id: job_id,
            user_id: user_id,
            user: "admin",
            userId: this.logged_user_id,
          },
          api: "/api/cancel_job",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            //this.startData = this.$store.getters.getResults.data;

            this.success_message = this.$store.getters.getResults.message;
            this.showSuccessAlert(this.success_message);
            this.getListing("all");
            this.applicantActive = true;
            //this.$router.push({ name: 'ViewJobDetailInprogressAdmin' });
          }
        });
    },
    addBack(user_id, job_id) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            job_id: job_id,
            user_id: user_id,
            userId: this.logged_user_id,
          },
          api: "/api/add-back-applicant",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            //this.startData = this.$store.getters.getResults.data;

            this.success_message = this.$store.getters.getResults.message;
            this.showSuccessAlert(this.success_message);
            this.getListing("all");
            this.applicantActive = true;
          }
        });
    },

    total_ext_cost(ext_cost, ext_hrs, type) {
      if (type == "cost") {
        var cost = ext_cost == null ? 0 : ext_cost;
        return parseFloat(cost);
      } else if (type == "extra_hrs") {
        ext_hrs = parseFloat(ext_hrs).toFixed(2);
        var ext = ext_hrs.split(".");
        return (
          (ext[0] >= 10 ? ext[0] : "0" + ext[0]) +
          ":" +
          (ext.length > 0
            ? ext[1] == "75"
              ? "45"
              : ext[1] == "50"
              ? "30"
              : ext[1] == "25"
              ? "15"
              : "00"
            : "00")
        );
      } else {
        ext_hrs = parseFloat(ext_hrs).toFixed(2);
        var ext = ext_hrs.split(".");
        // return ((ext[0] > 10 ? ext[0] : '0'+ext[0]) + ':' + (ext.length > 0 ? (ext[1] == '75' ? '45' : (ext[1] == '50' ? '30' : (ext[1] == '25' ? '15' : '00'))) : '00') );
        return (ext[0] >= 10 ? ext[0] : "0" + ext[0]) + ":" + (ext.length > 0 ? ext[1] : "00");
      }
    },
    total_cost(data) {
      if (data) {
        var end_time = this.details.end_date + " " + this.details.end_time;

        var spl = data.extended_hours.split(".");
        var time1 = moment(end_time).add(spl[0], "hours").format("YYYY-MM-DD HH:mm:ss");

        if (spl.length > 1) {
          time1 = moment(time1).add(spl[1], "minutes").format("YYYY-MM-DD HH:mm:ss");
        }
        console.log(time1, "before");

        if (data.extra_break_taken == "yes") {
          var val1 = data.extra_break_hrs.split(".");
          time1 = moment(time1).add(val1[0], "hours").format("YYYY-MM-DD HH:mm:ss");

          if (val1.length > 1) {
            var val2 = val1[1]
              ? val1[1] == "75"
                ? "45"
                : val1[1] == "25"
                ? "15"
                : val1[1] == "5"
                ? "30"
                : "00"
              : "00";
            time1 = moment(time1).add(val2, "minutes").format("YYYY-MM-DD HH:mm:ss");
          }
        }

        return moment(time1).format("hh:mm A");
      }
    },
    showAlertRate() {
      window.scrollTo(0, 0);
      this.dismissCountDownRate = this.dismissSecsRate;
    },
    showAlertExtend() {
      this.dismissCountDown = this.dismissSecs;
      Swal.fire({
        position: "top-center",
        type: "error",
        text: this.error_messages_extend,
        showConfirmButton: false,
        timer: 3000,
      });
    },
    countDownChangedExtend(dismissCountDownExtend) {
      this.dismissCountDownExtend = dismissCountDownExtend;
    },
    countDownChangedSuspend(dismissCountDownSuspend) {
      this.dismissCountDownSuspend = dismissCountDownSuspend;
    },
    showAlertSuspend() {
      this.dismissCountDown = this.dismissSecs;
      Swal.fire({
        position: "top-center",
        type: "error",
        text: this.error_messages_suspend,
        showConfirmButton: false,
        timer: 3000,
      });
    },
    cancelApplicant(id) {
      // this.form.username = name;
      this.form.cancellation_reason = "";
      this.form.user_id = id;
      this.$refs["cancel-modal"].show();
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    toggleInfoWindow: function (marker, idx) {
      console.log(marker, "d", idx);

      if (marker.type == "ptuser") {
        // this.loc1 = new Location("");

        this.infoWindowPos = marker.position;

        this.getDistance(
          this.jobMarker[0].lat,
          this.jobMarker[0].long,
          this.marker[idx].current_lat,
          this.marker[idx].current_long,
          marker,
          idx
        );
      } else {
        this.infoWindowPos = marker.position;
        this.infoContent = this.getInfoWindowContent(marker, idx, this.jobMarker);
      }

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    getInfoWindowContent: function (marker, idx, detailData) {
      console.log("ddf", detailData, marker);
      var img = this.weburl + "/images/default-user-icon.svg";
      if (marker.type == "ptuser") {
        var estimatedTime = this.totalTimeValue;

        if (detailData[idx].candidate_profile.profile_image) {
          return `<div class="">
                  <div>
                    <div>
                      <div class="m-2">
                        <img  style="width: 15px;"src="${
                          detailData[idx].candidate_profile.profile_image
                        }" alt="Ital Trulli">
                        <div style="float: right; margin-left: 8px;">
                            <b>${detailData[idx].candidate_profile.last_name} ${
            detailData[idx].candidate_profile.first_name
          }</b>

                            <br>
                       <b>ETA:</b>  ${estimatedTime}<br>
                       ${moment(detailData[idx].location_updated_at).format("DD MMM YYYY, h:mm A")}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>`;
        } else {
          return `<div class="">
                  <div>
                    <div>
                      <div class="m-2">
                        <img  style="width: 15px;"src=${img} alt="Italn Trulli">
                        <div style="float: right; margin-left: 8px;">
                            <b>${detailData[idx].candidate_profile.last_name} ${
            detailData[idx].candidate_profile.first_name
          }</b>

                            <br>
                       <b>ETA:</b>  ${estimatedTime}<br>
                      ${moment(detailData[idx].location_updated_at).format("DD MMM YYYY, h:mm A")}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>`;
        }
      } else {
        if (detailData[0].assigned_to.profile_image) {
          return `<div class="">
              <div>
                <div>
                  <div class="m-2">

                    <![if ${detailData[0].assigned_to.profile_image}]>
                    <img  style="width: 100px;"src="${detailData[0].assigned_to.profile_image}" alt="Italian Trulli">

                    <![endif]>
                    <div style="float: right; margin-left: 8px;">
                    <b>${detailData[0].assigned_to.last_name} ${detailData[0].assigned_to.first_name}</b>
                    </div>

                  </div>
                </div>

              </div>
            </div>`;
        } else {
          return `<div class="">
              <div>
                <div>
                  <div class="m-2">
                    <img  style="width: 15px;" src= ${img} alt="Italian Trulli">
                    <div style="float: right; margin-left: 8px;">
                    <b>${detailData[0].assigned_to.last_name} ${detailData[0].assigned_to.first_name}</b>
                    </div>

                  </div>
                </div>

              </div>
            </div>`;
        }
      }
    },
    getDistance(LatOrigin, LngOrigin, LatDest, LngDest, marker, idx) {
      var origin = new google.maps.LatLng(LatDest, LngDest);
      var destination = new google.maps.LatLng(LatOrigin, LngOrigin);
      var service = new google.maps.DistanceMatrixService();

      service.getDistanceMatrix(
        {
          origins: [origin],
          destinations: [destination],
          travelMode: "DRIVING",
          unitSystem: google.maps.UnitSystem.metric,
          avoidHighways: false,
          avoidTolls: false,
        },
        (response, status) => {
          if (status == "OK") {
            var origins = response.originAddresses;
            var destinations = response.destinationAddresses;
            var resultat = response.rows[0].elements[0].distance.text;
            var totalTimeData = response.rows[0].elements[0].duration.text;

            this.totalTimeValue = totalTimeData;
            console.log(totalTimeData, "dd", this.totalTimeValue);
            // resolve(resultat)
            // response = resolve(totalTimeData)
            console.log("distance : " + totalTimeData);

            this.infoContent = this.getInfoWindowContent(marker, idx, this.marker);
          }
          // return response;
        }
      );
      // return totalTimeData;
    },
    refreshMap() {
      setTimeout(() => {
        this.$refs.googleMap.$mapPromise.then((map) => {
          map.panTo(this.markers[0].position);
        });
      }, 500);
    },

    rateAndReview() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            job_id: Vue.prototype.decrypt(this.$route.params.id),

            page: this.currentPage,
            keyword: this.filterSearchRate,
            page: this.currentPage,
            rowsPerPage: this.form.rowsPerPage,
            sortBy: this.sortBy,
            sortDirection: this.sortDirection,
            sortDesc: this.sortDesc,
          },
          api: "/api/supervisor-rating-listWeb",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            this.items = this.$store.getters.getResults.data.rating_detail.data;

            this.totalRowsRate = this.$store.getters.getResults.data.rating_detail.total;
            this.perPageRate = this.$store.getters.getResults.data.rating_detail.per_page;
            this.fromRate = this.$store.getters.getResults.data.rating_detail.from;
            this.toRate = this.$store.getters.getResults.data.rating_detail.to;
            console.log(this.items, "df");

            return this.items;
          }
        });
    },
    applicantArrival(value, type, multiple, job_id, lat, long, job_code) {
      var api = "arrival-with-qr";
      var msg = "";
      var tp = "";

      api = multiple == false ? "arrival-with-qr" : "arrival-with-qr-multiple-fls";

      if (type == "ptArrival") {
        msg = "Are you sure to mark as arrived?";
        tp = "arrival";
      } else if (type == "ptArrivalConfirmation") {
        api = "confirm-arrival-by-supervisor";
        msg = "Are you sure to confirm arrival?";
        tp = "arrival";
      } else if (type == "ptCompletion") {
        msg = "Are you sure to mark completion of job?";
        tp = "completion";
      } else if (type == "ptCompletionConfirmation") {
        api = "mark-completed-by-supervisor";
        msg = "Are you sure to confirm completion of job?";
        tp = "completion";
      }

      // console.log(type, 'type', tp)
      if (this.selectedUser.length > 30 && multiple == true) {
        this.error_message = "Maximum 30 FLs can be selected in one time.";
        this.showAlert();
      } else {
        this.$swal
          .fire({
            title: "Please Confirm",
            text: msg,
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes!",
          })
          .then((result) => {
            if (result.value) {
              return this.$store
                .dispatch(POST_API, {
                  data: {
                    job_id: job_id,
                    user_id: value != "" ? value : this.selectedUser,
                    status: status ? status : "in-progress",
                    lat: lat,
                    long: long,
                    type: tp,
                    code: job_code,
                    from: "web",
                    userId: this.logged_user_id,
                  },
                  api: "/api/" + api,
                })
                .then(() => {
                  if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.selectedUser = [];
                    this.selectAll = false;
                    this.selectAllCompleted = false;
                    this.getListing("selected");
                    this.showAlert();

                    return [];
                  } else {
                    this.items = this.$store.getters.getResults.data;
                    this.selectedUser = [];
                    this.selectAll = false;
                    this.selectAllCompleted = false;
                    this.getListing("selected");

                    return this.items;
                  }
                });
            }
          });
      }
    },
    locationPtuser(id) {
      this.getLatLong().then(() => {
        this.geolocate();
        // this.$refs.address.focus();
        this.setPlace(this.currentPlace);
        this.addMarker();
      });
      this.form.user_id = id;
      this.$refs["location-modal"].show();
    },

    pdfViewer(link) {
      window.open(link);
    },

    addMw(id) {
      this.job_id = id;
      // this.form.username = name;
      // this.getMwList();
      this.form.selected_mw = "";
      this.form.selected_mw_arr = "";

      this.$refs["add-mw-modal"].show();
    },

    fav: function (userId) {
      this.userId = userId;
      this.userId = userId;
      // console.log(this.checkedValue==true,this.form.checkedValue,'dfd',this.favVal,this.favValue);
      // if (this.checkedValue) {

      this.markFavourite(userId);
      this.getListing();
      if (this.favVal == "yes") {
        this.checkedValue = true;
      } else {
        // console.log('else');
        this.checkedValue = false;
      }
    },
    update: function () {
      // console.log(this.$dispatch('selectedRole', this.selectedrole),'feced');
      this.$dispatch("selectedRole", this.selectedrole);
    },
    changeStarttime(status) {
      this.status = status;

      this.getListing(status);
    },
    changeEndtime(status) {
      this.status = status;

      this.getListing(status);
    },

    changeFilter(tab, currentPage) {
      if (tab == "selected") {
        this.currentPage = currentPage;
        this.getListing("selected");
        this.$refs.selected.refresh();
      } else if (tab == "all") {
        this.currentPage = currentPage;
        this.getListing("all");
        this.$refs.all.refresh();
      } else if (tab == "cancelled") {
        this.currentPage = currentPage;
        this.getListing("cancelled");
        this.$refs.cancelled.refresh();
      } else if (tab == "self") {
        this.currentPage = currentPage;
        this.getListing("self");
        this.$refs.self.refresh();
      } else if (tab == "system") {
        this.currentPage = currentPage;
        this.getListing("system");
        this.$refs.system.refresh();
      }

      this.selectedUser = [];
      this.selectAll = false;
      this.selectAllCompleted = false;
    },

    notSuitableModal(id) {
      this.not_suitable_applicant = id;
      this.$refs["not-suitable-modal"].show();
    },
    notSuitable(userId) {
      Swal.fire({
        title: "Please Confirm",
        text: "Are you sure to remove this user?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          return this.$store
            .dispatch(POST_API, {
              data: {
                job_id: Vue.prototype.decrypt(this.$route.params.id),
                user_id: this.not_suitable_applicant,
                reason: this.not_suitable_reason,
              },
              api: "/api/mw-not-suitable",
            })
            .then(() => {
              if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showAlert();
                return [];
              } else {
                this.items = this.$store.getters.getResults.data;
                this.success_message = this.$store.getters.getResults.message;
                this.showSuccessAlert(this.success_message);
                this.hideModel();

                this.getListing("all");
                return this.items;
              }
            });
        }
      });
    },
    markFavourite(userId) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            job_id: Vue.prototype.decrypt(this.$route.params.id),
            user_id: userId,
            status: status ? status : "in-progress",
            page: this.currentPage,
            keyword: this.filter,
            rowsPerPage: this.form.rowsPerPage,
            sortBy: this.sortBy,
            sortDirection: this.sortDirection,
            sortDesc: this.sortDesc,
          },
          api: "/api/markFavourite-applicant-inprogress",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            this.items = this.$store.getters.getResults.data;
            this.favVal = this.$store.getters.getResults.fav;
            this.getListing("all");
            return this.favVal;
          }
        });
    },
    onBreakSubmit() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            job_id: this.job_id,
            user_id: this.update_user_id,
            lunch_pay_hours: this.lunch_pay_hours,
            break_type: this.break_type,
          },
          api: "/api/add-lunch-break",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            this.items = this.$store.getters.getResults.data;
            this.success_message = this.$store.getters.getResults.message;
            this.showSuccessAlert(this.success_message);
            this.hideModel();

            this.getList(this.job_id, "", "in-progress", this.user_id);
            return this.items;
          }
        });
    },

    revertCompletion(id, type, job_id) {
      this.$swal
        .fire({
          title: "Please Confirm",
          text: "Are you sure to revert moving forward?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  user_id: id,
                  job_id: job_id,
                },
                api: "/api/revert-moving-forward",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  this.success_message = this.$store.getters.getResults.message;
                  this.showSuccessAlert(this.success_message);
                  this.getListing("selected");
                }
              });
          }
        });
    },
    allSelected(key) {

      if (this.selectedUser.length > 0) {
        this.selectedUser = [];
      }
      this.selectAllCompleted = false;

      var i = [];
      this.jobDatas[key].selected.map((item) => {
        if (item.job_status != "completed") {
          i.push(item.id);
        }
      });
      this.selectedUser = i;

      console.log(this.selectedUser);
    },
    allSelectCompleted(key) {
      if (this.selectedUser.length > 0) {
        this.selectedUser = [];
      }
      this.selectedUser = [];
      this.selectAll = false;

      var i = [];
      this.jobDatas[key].selected.map((item) => {
        if (item.job_status == "completed") {
          i.push(item.id);
        }
      });
      this.selectedUser = i;

      console.log(this.selectedUser);
    },

    awardJob(userId, job_id) {
      this.$swal
        .fire({
          title: "Please Confirm",
          text: "Are you sure to award the job to user?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })

        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  job_id: job_id,
                  user_id: userId,
                  // status: status ? status : 'in-progress',
                  // original_start_time: this.form.original_start_time,
                  // original_end_time: this.form.original_end_time,
                  page: this.currentPage,
                  keyword: this.filter,
                  rowsPerPage: this.form.rowsPerPage,
                  sortBy: this.sortBy,
                  sortDirection: this.sortDirection,
                  sortDesc: this.sortDesc,
                  userId: this.logged_user_id,
                },
                api: "/api/award-job-upcoming",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                  return [];
                } else {
                  this.items = this.$store.getters.getResults.data;
                  this.success_message = this.$store.getters.getResults.message;
                  this.showSuccessAlert(this.success_message);
                  this.getListing("in-progress");
                  return this.items;
                }
              });
          }
        });
    },

    openPlaformFeeModal(data) {
      this.job_id = data.id;
      this.$refs["platform-fee-modal"].show();
      this.platform_fee =
        data.service_fee_type == "fixed_rate" ? data.service_fee : data.service_fee_per;
      this.platform_fee_title =
        data.service_fee_type == "fixed_rate" ? "Fixed Rate" : "Platform Fee Percent";
    },

    modifyPlatformFee() {
      this.$swal
        .fire({
          title: "Please Confirm",
          text: "Are you sure to modify platform fee?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  platform_fee: this.platform_fee,
                  generateSr: this.generateSr,
                  with_email: this.withNoEmail,
                  job_id: this.job_id,
                },
                api: "/api/modify-platform-fee",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  this.success_message = this.$store.getters.getResults.message;
                  this.showSuccessAlert(this.success_message);
                  this.hideModel();
                  this.getListing("selected");
                }
              });
          }
        });
    },

    downloadList(id) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            job_id: id,
          },
          api: "/api/check-selected-fl-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.$swal.fire({
              position: "top-center",
              type: "error",
              text: this.error_message,
              showConfirmButton: false,
              timer: 3000,
            });
          } else {
            var base_url = this.webUrlV1;

            var export_url = "/api/download-selected-fl-list/" + id;

            window.open(base_url + export_url, "_blank");
          }
        });
    },
    submitMw() {
      this.$refs["add-mw-modal"].hide();

      this.$swal
        .fire({
          title: "Please Confirm",
          text: "Are you sure to apply for this job?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  user_id: this.form.selected_mw_arr,
                  job_id: this.job_id,
                  opt_insurance: this.insurance_opted,
                  insurance_future_required: this.insurance_future_required,
                  userId: this.logged_user_id,
                },
                api: "/api/add-mw-by-admin",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                  return [];
                } else {
                  this.success_message = this.$store.getters.getResults.message;
                  this.showSuccessAlert(this.success_message);
                  //this.$refs['add-mw-modal'].hide();
                  this.getListing("all");
                  this.applicantActive = true;
                  this.selectActive = false;
                  this.mwList = [];
                }
              });
          }
        });
    },
  },

  mounted() {
    $(document).ready(function () {
      // $(document).on('click', '.btn', function(){
      // var moreLessButton = $(".invisible-content").is(':visible') ? 'Read More' : 'Read Less';
      // $(this).text(moreLessButton);
      // $(this).parent('.large-content').find(".invisible-content").toggle();
      // $(this).parent('.large-content').find(".visible-content").toggle();
      // });
    });

    //document.title = "EL Connect - Employer - Manage Jobs";
    this.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.logged_user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.charge_type =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.charge_type : "";

    this.user_type_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.user_type_id : "";
    this.is_company_head =
      this.$store.getters.currentUser != null
        ? this.$store.getters.currentUser.is_company_head
        : "";

    this.company_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_id : "";
    this.company_admin_id =
      this.$store.getters.currentUser != null
        ? this.$store.getters.currentUser.company_admin_id
        : "";

    console.log(this.keyword_url);
    if (this.keyword_url == "") {
      console.log(this.keyword_url, "jjj");

      $(".share-key-class").prop("disabled", true);
    }
    var fromPage = this.$route.params.from;
    var fromSearch = this.$route.params.search;
    var fromCompanyFilter = this.$route.params.companyFilter;
    var fromStartDate = this.$route.params.startDate;
    var fromEndDate = this.$route.params.endDate;
    var fromServiceCategory = this.$route.params.serviceCategory;

    this.service = fromServiceCategory;
    this.companyId = fromCompanyFilter;

    if (this.$route.params.from != undefined) {
      if (fromPage == "in-progress") {
        this.inprogress = true;
      } else if (fromPage == "upcoming") {
        this.upcoming = true;
      } else if (fromPage == "completed") {
        this.completed = true;
      } else if (fromPage == "all") {
        this.all = true;
      } else {
        this.cancelled = true;
      }
      this.status = fromPage;
      this.form.filterServiceCategory = fromServiceCategory;
      this.dateRange.startDate = fromStartDate;
      this.dateRange.endDate = fromEndDate;
      this.form.filterCompanyId = fromCompanyFilter;
      this.form.filterSearch = fromSearch;
    }

    this.getListing(
      this.status,
      this.form.filterServiceCategory,
      this.form.filterCompanyId,
      this.form.fromSearch,
      this.user_id
    );
    this.getServiceCategory();

    //console.log(this.status,'+++++');
    if (this.status != "" && this.status != null) {
      // this.getAllCompaniesByStatus(this.status);
    }

    // this.getAllCompanies();

    // this.getSupervisorsList();
    this.designation = this.$store.getters.currentUser.designation;

    this.user_type_id = this.$store.getters.currentUser.user_type_id;
    // this.permission();
    // this.getEmployeeProfile();

    // this.permissionEM();
    // Bus.$emit("active_users");
    // Bus.$emit("get_em_job_count");
    // this.allManageJobDPLocal = JSON.parse(localStorage.getItem("allManageJobDPLocal"))
    //   ? JSON.parse(localStorage.getItem("allManageJobDPLocal"))
    //   : "";
    // this.activeTab = this.allManageJobDPLocal.activeTabLocal
    //   ? this.allManageJobDPLocal.activeTabLocal
    //   : "inprogress";
    // this.getList(this.activeTab, this.activeApplicantTab, this.filterSearch);
    // localStorage.removeItem("allManageJobDPLocal");

    // this.getHourlyCost();
    // // this.changeApplicantTabStatus(this.activeApplicantTab);

    // $(".dashboard-content-container .simplebar-scroll-content").animate(
    //   {
    //     scrollTop: 0,
    //   },
    //   "fast"
    // );
  },
};
</script>

<style scoped></style>
