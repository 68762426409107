<template>
  <div>
    <!-- <div class="back_arrow_icon">
                <span class="icon-material-outline-arrow-back" @click="$router.go(-1)"></span>
        </div> -->
    <!-- Dashboard Headline -->
    <div class="dashboard-headline">
      <h3>
        Manage Profile
        <!--<span class="btn green-border-btn new_edit_ft_btn guideline_text"><a href="https://elconnect-preprod.sgp1.digitaloceanspaces.com/FREELANCER GUIDE.pdf" target="_blank" style="color: #4c8bf5;">Click here to read our guide</a></span>-->
      </h3>

      <!-- Breadcrumbs -->
    </div>
    <div class="notification notice common_margin_bottom_28" v-if="status == 'pending'">
      <p><span class="icon-feather-info"></span> Account status {{ status }}</p>
      <a class="close"></a>
    </div>
    <div
      class="notification warning"
      v-if="status == 'incomplete'"
      style="background-color: rgb(236 159 167 / 49%); color: rgb(234, 20, 81)"
    >
      <p>
        <span class="icon-feather-info"></span> Account status {{ status }} , to complete profile:
      </p>
      <ul class="notification_box" v-for="(err, key) in validErr">
        <li>{{ key + 1 }}. {{ err }}</li>
        <!-- <li> Save changes to profile </li> -->
      </ul>
      <a class="close"></a>
    </div>
    <div
      class="notification notice"
      style="background-color: rgb(236 159 167 / 49%); color: rgb(234, 20, 81)"
      v-if="status == 'rejected'"
    >
      <p>
        <span class="icon-feather-info"></span> Account status {{ status }} , to complete profile:
      </p>
      <ul class="notification_box">
        <li v-if="this.rejection_comments">
          <b>Rejection Comment-</b> {{ this.rejection_comments }}
        </li>
      </ul>
      <a class="close"></a>
    </div>
    <!-- Row -->
    <!-- <div class="row static_page_row custom_jobs_pdng second_indiv_step_wrap overflow_hidden_mobile"> -->
    <div class="row second_indiv_step_wrap overflow_hidden_mobile">
      <b-form method="post" id="register-account-form" class="single_jb_frm single_jobseeker_lg">
        <!-- Dashboard Box -->
        <!-- <div class="col-xl-12 common_zero_pdng"> -->
        <div class="col-xl-12">
          <!--<b-form-group id="link-group"  class="top_banner_jobs_full">
                            <div class="single-image-upload">
                                <b-form-file class="customUpload form-control-file" name="BannerImage" accept="image/*" @change="onBannerChange" ref="image"  v-model="form.banner_image"></b-form-file>
                                <span>
                                   <b-img v-if='form.profile_image' :src="form.profile_image.image ? form.profile_image.image : webUrl+'assets/images/upload-icon.svg'" :disabled="true" style="max-width: 300px;"></b-img>
                                <b-img v-if='form.profile_image.image' :src="form.profile_image.image"></b-img>

                               <b-img v-else :src="webUrl+'assets/images/upload-icon.svg'" class="default_img"></b-img>
                                <i v-else   class="fa fa-camera default_img"></i>
                                </span>
                            </div>
                        </b-form-group>-->
          <!--<p class="img_txt">Banner Image<span>(Banner Image size should be 952*280.)</span></p>-->
          <div class="dashboard-box desktop_margin_top_zero">
            <!-- Headline -->
            <div class="headline">
              <h3 class="common_pink_text_color common_font_weight_800">
                <i class="icon-material-outline-account-circle common_pink_text_color"></i>My
                Account
              </h3>
              <!-- <button @click="retriveData()" type="button" class="button ripple-effect" style="float: right;margin-bottom:-25px;margin-top:-34px;background-color:red">Retrive my info from SINGPASS</button> -->
            </div>

            <div class="content with-padding">
              <div class="row">
                <div
                  class="col-auto mobile_cl col-xl-3 submit-field max_width_and_flex_769 mobile_max_width_and_flex big_screen_max_width_and_flex"
                >
                  <h5 class="web_bottom_zero line_height_12">
                    Passport/Selfie Image<span class="required_star">*</span>
                  </h5>
                  <b-link
                    @click="showsampleimageModel"
                    style="color: #4c8bf5 !important"
                    class="example_smpl"
                    >(Example Sample)</b-link
                  >
                  <!-- <div class="avatar-wrapper" data-tippy-placement="bottom" title="Change Avatar">
                                        <img class="profile-pic" :src="form.image" alt="" />
                                        <div class="upload-button"></div>
                                        <input class="file-upload" type="file" accept="image/*" @change="onprofileChange"/>
                                    </div> -->
                  <b-form-group
                    id="link-group"
                    class="top_banner_jobs_full new_update_banner profile_top_div mobile_tab_image_change"
                  >
                    <div class="single-image-upload">
                      <b-form-file
                        class="customUpload form-control-file"
                        name="Image"
                        accept="image/*"
                        @change="onprofileChange"
                        ref="image"
                        v-model="form.profile_image"
                        :disabled="
                          account_verification_status == 'Completed' ||
                          account_verification_status == 'Pending'
                        "
                      ></b-form-file>
                      <span>
                        <b-img
                          v-if="form.selectedImage"
                          :src="
                            form.selectedImage.image
                              ? form.selectedImage.image
                              : webUrl + 'assets/images/user-avatar-small-01.png'
                          "
                          :disabled="true"
                          style="max-width: 300px"
                          class=""
                        ></b-img>
                        <!-- <b-img v-if='form.selectedImage' :src="form.selectedImage.image" :disabled="true" style="max-width: 300px;"></b-img> -->
                      </span>
                    </div>
                  </b-form-group>
                </div>
                <div class="col-xl-9 submit-field">
                  <h5 class="img_txt height_38_banner_heading">
                    Profile Banner Image<span>(Recommended Banner size should be 952*280.)</span>
                  </h5>
                  <b-form-group id="link-group" class="top_banner_jobs_full new_update_banner">
                    <div class="single-image-upload">
                      <b-form-file
                        class="customUpload form-control-file"
                        name="BannerImage"
                        accept="image/*"
                        @change="onBannerChange"
                        ref="image"
                        v-model="form.banner_image"
                      ></b-form-file>
                      <span>
                        <!-- <b-img v-if='form.selectedBannerImage' :src="form.selectedBannerImage.image ? form.selectedBannerImage.image : webUrl+'assets/images/upload-icon.svg'" :disabled="true" style="max-width: 200px;"></b-img>-->
                        <b-img
                          v-if="form.selectedBannerImage.image"
                          :src="form.selectedBannerImage.image"
                        ></b-img>

                        <!-- <b-img v-else :src="webUrl+'assets/images/upload-icon.svg'" class="default_img"></b-img>-->
                        <i v-else class="fa fa-camera default_img"></i>
                      </span>
                    </div>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <!-- <div class="col-auto mobile_cl">
                                    <div class="avatar-wrapper" data-tippy-placement="bottom" title="Change Avatar">
                                        <img class="profile-pic" :src="form.image" alt="" />
                                        <div class="upload-button"></div>
                                        <input class="file-upload" type="file" accept="image/*" @change="onprofileChange"/>
                                    </div>
                                    <div class="single-image-upload">
                                        <b-form-file class="customUpload form-control-file" name="Image" accept="image/*" @change="onprofileChange" ref="image"  v-model="form.image"></b-form-file>
                                        <span>
                                            <b-img v-if='form.selectedImage' :src="form.selectedImage.image ? form.selectedImage.image : webUrl+'assets/images/user-avatar-small-01.png'" :disabled="true" style="max-width: 300px;"></b-img>
                                            <b-img v-if='form.selectedImage' :src="form.selectedImage.image" :disabled="true" style="max-width: 300px;"></b-img>
                                        </span>
                                    </div>
                                </div>-->

                <div class="col">
                  <div class="row">
                    <!-- <div class="col-xl-3">
                                            <div class="submit-field error_submit_field">
                                                <h5>Given Name <span class="required_star"></span></h5>
                                                <input type="text" v-model="form.first_name" class="with-border form-control" id="first_name" :disabled="account_verification_status == 'Completed' || account_verification_status == 'Pending'">
                                                <input type="hidden" v-model="form.user_id" class="with-border" >
                                            </div>
                                                     <span class="error_x_white">Please enter Given Name </span>
                                        </div>

                                        <div class="col-xl-3">
                                            <div class="submit-field error_submit_field">
                                                <h5>Surname <span class="required_star"></span></h5>
                                                <input type="text" v-model="form.last_name" class="with-border form-control" id="last_name" :disabled="account_verification_status == 'Completed' || account_verification_status == 'Pending'">
                                            </div>
                                                     <span class="error_x_white">Please enter Surname </span>
                                        </div> -->
                    <div class="col-xl-6">
                      <div class="submit-field error_submit_field">
                        <h5>Full Name<span class="required_star"></span></h5>
                        <input
                          type="text"
                          v-model="form.full_name"
                          class="with-border form-control"
                          id="last_name"
                          :disabled="
                            this.form.singpass_signup == 'yes' ||
                            (account_verification_status == 'Completed' ||
                              account_verification_status == 'Pending')
                              && this.form.email
                          "
                        />
                      </div>
                      <!-- <div class="show_span"> -->
                      <span class="error_x_white">Please enter Full Name </span>
                      <!-- </div> -->
                    </div>
                    <div class="col-xl-2">
                      <div
                        class="submit-field mobile_margin_bottom_28 mobile_bottom_two_eight big_screen_bottom_28 web_bottom_zero"
                      >
                        <h5>Date of Birth <span class="required_star">*</span></h5>
                        <!-- <datepicker v-model="form.dob" :disabled-dates="disabledFromDate" :format="customFormatterDob" :open-date="changePicker()" :minimumView="'day'" :maximumView="'year'" id="scheduled_post" :disabled="account_verification_status == 'Completed' || account_verification_status == 'Pending'" :class="account_verification_status == 'Completed' || account_verification_status == 'Pending' ? 'if_disabled' : 'if_not_disabled'" class="web_bottom_zero scheduled_post add_calender_icon"></datepicker> -->
                        <select
                          :id="name + 'years'"
                          v-model="year"
                          @change="generateDateDropdown('year')"
                          class="custom-select desktop_margin_bottom_zero"
                          :disabled="
                            this.form.singpass_signup == 'yes' ||
                            (account_verification_status == 'Completed' ||
                              account_verification_status == 'Pending')
                              && this.form.email
                          "
                        >
                          <option value="" disabled>Year</option>
                          <option
                            v-for="i in fullYear"
                            v-if="i >= fullYear - 100"
                            :value="i"
                            :key="name + 'years_' + i"
                          >
                            {{ i }}
                          </option>
                        </select>
                      </div>
                      <span class="error_x_white">Please select DOB </span>
                    </div>
                    <div class="col-xl-2 submit-field">
                      <h5 class="common_opacity_zero">
                        Date of Birth <span class="required_star">*</span>
                      </h5>
                      <select
                        :id="name + 'months'"
                        v-model="month"
                        @change="generateDateDropdown('month')"
                        :disabled="
                          (this.form.singpass_signup == 'yes') ||
                          ((disable_dob_month ||
                            account_verification_status == 'Completed' ||
                            account_verification_status == 'Pending') &&
                          this.form.email)
                        "
                        class="custom-select desktop_margin_bottom_zero"
                      >
                        <option value="" disabled>Month</option>
                        <option
                          v-for="i in monthArray"
                          :value="i.toString()"
                          :key="name + 'month_' + i"
                        >
                          {{ getMonths(i) }}
                        </option>
                      </select>
                    </div>

                    <div class="col-xl-2 submit-field">
                      <h5 class="common_opacity_zero">
                        Date of Birth <span class="required_star">*</span>
                      </h5>
                      <select
                        :id="name + 'dates'"
                        v-model="day"
                        :disabled="
                          (this.form.singpass_signup == 'yes') ||
                          ((disable_dob_month ||
                            account_verification_status == 'Completed' ||
                            account_verification_status == 'Pending') &&
                          this.form.email)
                        "
                        class="custom-select desktop_margin_bottom_zero"
                      >
                        <option value="" disabled>Date</option>
                        <option
                          v-for="i in dayArray"
                          :value="i.toString()"
                          :key="name + 'dates_' + i"
                        >
                          {{ i.toString() }}
                        </option>
                      </select>
                    </div>
                    <b-input-group>
                      <div class="col-xl-6">
                        <div
                          class="submit-field error_submit_field mobile_margin_bottom_28 mobile_bottom_two_eight big_screen_bottom_28 mobile_view_numbr mobile_email"
                        >
                          <h5>
                            Mobile Number <span class="required_star">*</span>
                            <!-- <span class="verify_badge" v-if="this.form.mobile_verified_check == true">
                                                            <i class="fa fa-check"></i>Verified</span> -->
                            <div
                              class="verified-badge-with-title custom_verify_badge_em"
                              v-if="this.form.mobile_verified_check == true"
                            >
                              Verified
                            </div>
                            <div
                              class="tooltip_icon"
                              @click="mobileToolTipNumber"
                              :class="
                                this.form.mobile_verified_check == true
                                  ? 'email_tooltip_with_verified'
                                  : 'email_tooltip_with_no_verified'
                              "
                              v-if="this.form.mobile_verified_check == true"
                            >
                              <span class="material-icons-outlined info_tooltip_icon"
                                >info_outlined</span
                              >
                              <span class="tooltip_text tooltip_txt_mobile_number"
                                >Note: Contact support to update Mobile number</span
                              >
                            </div>
                          </h5>
                          <div class="input-group mb-3 web_bottom_zero error_submit_field">
                            <input
                              type="text"
                              v-model="form.country_code"
                              class="with-border mobile_input_prf form-control required-field country_code_txt web_bottom_zero"
                              readonly="readonly"
                            />

                            <input
                              type="text"
                              v-model="form.mobile_number"
                              class="with-border mobile_input_prf form-control required-field web_bottom_zero"
                              id="number"
                              @keyup.enter="sendOTP"
                              :disabled="
                                this.form.mobile_verified_check == true &&
                                this.form.email &&
                                this.form.singpass_signup == 'yes'
                              "
                            />
                            <div class="verify-btn" v-if="this.form.mobile_verified_check == false">
                              <button
                                @click="sendOTP"
                                class="btn btn-outline-primary verify_btn_jb otp_btn_hgt"
                                type="button"
                              >
                                Send OTP
                              </button>
                            </div>
                          </div>
                          <span
                            class="error_x_white desktop_margin_bottom_zero mobile_margin_bottom_28"
                            >Please enter Mobile Number
                          </span>
                        </div>
                        <!-- <div class="show_span">
                                                     <span class="error_x_white">Please enter Contact Number </span>
                                                  </div> -->
                      </div>
                      <div class="col-xl-6">
                        <div class="submit-field error_submit_field mobile_view_numbr">
                          <h5>
                            Email Address<span class="required_star">*</span>
                            <!-- <span class="verify_badge" v-if="this.form.email_verify == 'yes'">
                                                            <i class="fa fa-check"></i>Verified
                                                        </span> -->
                            <div
                              class="verified-badge-with-title custom_verify_badge_em"
                              v-if="this.form.email_verify == 'yes'"
                            >
                              Verified
                            </div>
                            <div
                              class="tooltip_icon"
                              @click="mobileToolTipEmail"
                              :class="
                                this.form.email_verify == 'yes'
                                  ? 'email_tooltip_with_verified'
                                  : 'email_tooltip_with_no_verified'
                              "
                              v-if="this.form.email_verify == 'yes'"
                            >
                              <span class="material-icons-outlined info_tooltip_icon"
                                >info_outlined</span
                              >
                              <span class="tooltip_text tooltip_txt_email"
                                >Note: Contact support to update Email</span
                              >
                            </div>
                          </h5>
                          <div class="input-group mb-3 web_bottom_zero error_submit_field">
                            <input
                              type="text"
                              v-model="form.email"
                              class="web_bottom_zero with-border inut_margin_bottom mobile_input_prf form-control required-field"
                              id="email"
                              :disabled="
                                this.form.email_verify == 'yes' &&
                                this.form.email &&
                                this.form.singpass_signup == 'yes'
                              "
                            />
                            <div
                              class="verify-btn"
                              v-if="this.form.email_verify == 'no' && this.showVerifyEmail"
                            >
                              <button
                                class="btn btn-outline-primary verify_btn_jb otp_btn_hgt"
                                @click="emailVerify"
                                type="button"
                              >
                                Resend Link
                              </button>
                            </div>
                          </div>
                          <h5 class="web_bottom_zero margin_top_13" v-if="countDownEmail > 0">
                            Didn't receive email? Resend email in
                            <span style="color: #4c8bf5 !important">
                              {{ countDownEmail }} seconds</span
                            >
                          </h5>
                          <span class="error_x_white desktop_margin_bottom_zero"
                            >Please enter Email
                          </span>
                        </div>
                        <!-- <div class="show_span">
                                                     <span class="error_x_white">Please enter Email </span>
                                                  </div> -->
                      </div>
                      <!-- <div class="col-xl-12" v-if="this.form.mobile_verified_check == true && this.form.email_verify == 'no'">
                                                        <span ><b style="color: rgb(8, 118, 177);">Note: Contact support to update Mobile number</b></span>
                                                    </div>
                                                    <div class="col-xl-12" v-else-if="this.form.mobile_verified_check == false && this.form.email_verify == 'yes'">
                                                        <span ><b style="color: rgb(8, 118, 177);">Note: Contact support to update Email</b></span>
                                                    </div>
                                                     <div class="col-xl-12" v-else-if="this.form.mobile_verified_check == true && this.form.email_verify == 'yes'">
                                                        <span ><b style="color: rgb(8, 118, 177);">Note: Contact support to update Mobile number or Email</b></span>
                                                    </div> -->

                      <!--<div class="col-xl-6">

                                                <div class="submit-field mobile_view_numbr mobile_email"  v-if="this.form.mobile_verified_check == false"  >
                                                    <h5>Verification Code</h5>
                                                    <div class="input-group mb-3">
                                                    <input type="text" v-model="form.var_code" class="with-border mobile_input_prf form-control" id="verification">
                                                    <div class="input-group-append" v-if="this.form.verify_otp_button == true" >
                                                        <button class="btn btn-outline-primary" @click="mobileVerify" type="button">Verify OTP</button>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div class="show_span">
                                                                <span id="verification-warning-message" class="text-danger"> </span>
                                                            </div>
                                            </div>-->
                    </b-input-group>
                    <div class="col-xl-6">
                      <div class="submit-field error_submit_field">
                        <h5>NRIC <span class="required_star">*</span></h5>
                        <input
                          type="text"
                          v-model="form.nric"
                          class="with-border form-control web_bottom_zero required-field"
                          id="nric"
                          :disabled="
                            this.form.singpass_signup == 'yes' ||
                            (account_verification_status == 'Completed' ||
                              account_verification_status == 'Pending')
                              && this.form.email
                          "
                        />
                      </div>
                      <!-- <div class="show_span"> -->
                      <span class="error_x_white">Please enter NRIC </span>
                      <!-- </div> -->
                    </div>
                    <!-- <div class="custom_xl"></div> -->
                    <div
                      class="tab_nric col-xl-3 max_width_and_flex_769 mobile_max_width_and_flex submit-field"
                    >
                      <div
                        v-if="
                          account_verification_status != 'Completed' &&
                          form.singpass_signup == 'false'
                        "
                      >
                        <h5 class="img_txt height_31_19">
                          NRIC Front <span style="color: red !important">*</span>
                        </h5>
                        <div
                          class="profile-upload-box upload-files-box mobile_margin_bottom_30 top_banner_jobs_full image_float"
                        >
                          <b-form-group
                            id="link-group"
                            class="new_update_banner mobile_tab_image_change"
                          >
                            <div
                              class="single-image-upload common_height_180_nric"
                              v-if="!form.nric_front.image"
                            >
                              <b-form-file
                                class="customUpload form-control-file"
                                name="BannerImage"
                                accept="image/*"
                                @change="onBannerChangefront"
                                ref="image"
                                v-model="form.banner_image_front"
                              ></b-form-file>
                              <span>
                                <b-img
                                  v-if="form.nric_front.image"
                                  :src="form.nric_front.image"
                                ></b-img>
                                <i v-else class="fa fa-camera default_img"></i>
                              </span>
                            </div>
                            <div v-else>
                              <div
                                class="image-upload-box1"
                                v-if="
                                  account_verification_status != 'Completed' &&
                                  account_verification_status != 'Pending'
                                "
                              >
                                <b-form-file
                                  class="cam_input customUpload form-control-file"
                                  name="BannerImage"
                                  accept="image/*"
                                  @change="onBannerChangefront"
                                  ref="image"
                                  v-model="form.banner_image_front"
                                ></b-form-file>

                                <!-- <b-form-file class="customUpload form-control-file" name="BannerImage" accept="image/*" @change="onBannerChangefront" ref="image"  v-model="form.banner_image_front" :disabled="account_verification_status == 'Completed' || account_verification_status == 'Pending'"></b-form-file> -->
                              </div>
                              <!-- <b-img :src="selectedNricFront || webUrl+'/images/upload-icon.svg'" alt="User Icon" title="NRIC Front"> -->
                              <a
                                v-if="account_verification_status != 'Completed'"
                                :href="form.nric_front.image"
                                target="_blank"
                                ><b-img
                                  class="selected_img_nric new_nric_manual common_height_180_nric"
                                  v-if="form.nric_front.image"
                                  :src="form.nric_front.image"
                                ></b-img
                              ></a>
                              <i v-else class="fa fa-camera default_img"></i>

                              <b-img
                                v-if="!form.nric_front.image"
                                :src="webUrl + 'assets/images/upload-icon.svg'"
                                class="default_img file_height"
                              ></b-img>
                            </div>
                          </b-form-group>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="custom_xl"></div> -->
                    <div
                      class="tab_nric col-xl-3 max_width_and_flex_769 mobile_max_width_and_flex submit-field"
                    >
                      <div
                        v-if="
                          account_verification_status != 'Completed' &&
                          form.singpass_signup == 'false'
                        "
                      >
                        <h5 class="img_txt height_31_19">
                          NRIC Back <span style="color: red !important">*</span>
                        </h5>
                        <div
                          class="profile-upload-box upload-files-box mobile_margin_bottom_30 top_banner_jobs_full image_float"
                        >
                          <b-form-group
                            id="link-group"
                            class="new_update_banner mobile_tab_image_change"
                          >
                            <div
                              class="single-image-upload common_height_180_nric"
                              v-if="!form.nric_back.image"
                            >
                              <b-form-file
                                class="customUpload form-control-file"
                                name="BannerImage"
                                accept="image/*"
                                @change="onBannerChangeback"
                                ref="image"
                                v-model="form.banner_image_back"
                              ></b-form-file>
                              <span>
                                <b-img
                                  v-if="form.nric_back.image"
                                  :src="form.nric_back.image"
                                ></b-img>
                                <i v-else class="fa fa-camera default_img"></i>
                              </span>
                            </div>
                            <div v-else>
                              <div
                                class="image-upload-box1"
                                v-if="
                                  account_verification_status != 'Completed' &&
                                  account_verification_status != 'Pending'
                                "
                              >
                                <b-form-file
                                  class="cam_input customUpload form-control-file"
                                  name="BannerImage"
                                  accept="image/*"
                                  @change="onBannerChangeback"
                                  ref="image"
                                  v-model="form.banner_image_back"
                                ></b-form-file>

                                <!-- <b-form-file class="customUpload form-control-file" name="BannerImage" accept="image/*" @change="onBannerChangefront" ref="image"  v-model="form.banner_image_front" :disabled="account_verification_status == 'Completed' || account_verification_status == 'Pending'"></b-form-file> -->
                              </div>
                              <!-- <b-img :src="selectedNricFront || webUrl+'/images/upload-icon.svg'" alt="User Icon" title="NRIC Front"> -->
                              <a
                                v-if="account_verification_status != 'Completed'"
                                :href="form.nric_back.image"
                                target="_blank"
                                ><b-img
                                  class="selected_img_nric new_nric_manual common_height_180_nric"
                                  v-if="form.nric_back.image"
                                  :src="form.nric_back.image"
                                ></b-img
                              ></a>
                              <i v-else class="fa fa-camera default_img"></i>

                              <b-img
                                v-if="!form.nric_back.image"
                                :src="webUrl + 'assets/images/upload-icon.svg'"
                                class="default_img file_height"
                              ></b-img>
                            </div>
                          </b-form-group>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4">
                      <div class="submit-field mobile_bottom_two_eight">
                        <h5>Nationality <span class="required_star">*</span></h5>
                        <b-form-select
                          v-model="form.nationality"
                          class="custom-select web_bottom_zero"
                          id="nationality"
                          :disabled="
                            account_verification_status == 'Completed' ||
                            account_verification_status == 'Pending'
                          "
                        >
                          <option value="" disabled>Select</option>
                          <option :value="country.id" v-for="(country, id) in countries" :key="id">
                            {{ country.title }}
                          </option>
                        </b-form-select>
                      </div>
                    </div>
                    <div class="col-xl-4">
                      <div class="submit-field">
                        <h5>Gender <span class="required_star">*</span></h5>
                        <b-form-select
                          data-size="7"
                          title="Select Gender"
                          v-model="form.gender"
                          id="genderval"
                          class="custom-select web_bottom_zero"
                          :disabled="
                            account_verification_status == 'Completed' ||
                            account_verification_status == 'Pending'
                          "
                        >
                          <option value="" disabled>Select</option>
                          <option value="female">Female</option>
                          <option value="male">Male</option>
                        </b-form-select>
                      </div>
                      <div class="show_span">
                        <span class="error_x_white">Please select Gender </span>
                      </div>
                    </div>
                    <div class="col-xl-4">
                      <div class="submit-field">
                        <h5>Race <span class="required_star">*</span></h5>
                        <b-form-select
                          data-size="7"
                          title="Select Race"
                          v-model="form.raceid"
                          class="custom-select web_bottom_zero"
                          id="race"
                          :disabled="
                            account_verification_status == 'Completed' ||
                            account_verification_status == 'Pending'
                          "
                        >
                          <option :value="null" disabled>Select</option>
                          <option :value="race.id" v-for="(race, id) in race" :key="id">
                            {{ race.title }}
                          </option>
                        </b-form-select>
                      </div>
                    </div>
                    <div class="col-xl-8">
                      <div
                        class="submit-field web_bottom_zero mobile_bottom_two_eight big_screen_bottom_28"
                      >
                        <h5>Address <span class="required_star"></span></h5>
                        <input
                          type="text"
                          v-model="form.address"
                          class="with-border web_bottom_zero"
                          id="address"
                        />
                      </div>
                      <!-- <div class="show_span">
                                                    <span class="error_x_white">Please enter Address </span>
                                                </div> -->
                    </div>
                    <div class="col-xl-4">
                      <div class="submit-field web_bottom_zero">
                        <h5>Daily Paid Account Status <span class="required_star"></span></h5>
                        <input
                          type="text"
                          v-model="account_verification_status"
                          class="web_bottom_zero with-border form-control required-field"
                          id="status"
                          disabled
                        />
                      </div>
                    </div>
                    <!--<div class="col-xl-6">
                                        <div class="submit-field">
                                            <h5>Address</h5>
                                            <input type="text" class="with-border required-field" v-model="form.address" id="address">
                                        </div>
                                         <div class="show_span">
                                                     <span class="error_x_white">Please enter Address </span>
                                                  </div>
                                    </div>-->
                    <!--<div class="col-xl-6">
                                        <div class="submit-field">
                                            <h5>Gender <span class="required_star">*</span></h5>
                                            <b-form-select data-size="7" title="Select Gender"  v-model="form.gender" class="custom-select" id="gender">
                                                <option value="" disabled>Select</option>
                                                <option value="female">Female</option>
                                                <option value="male" selected>Male</option>
                                            </b-form-select>
                                        </div>
                                         <div class="show_span">
                                                     <span class="error_x_white">Please select Gender </span>
                                                  </div>
                                    </div>-->
                    <!--<div class="col-xl-6">
                                        <div class="submit-field">
                                            <h5>Nationality <span class="required_star">*</span></h5>
                                            <b-form-select v-model="form.nationality" class="custom-select">
                                                <option value="" disabled>Select</option>
                                                <option :value="country.id" v-for="(country,id) in countries" :key="id">{{country.country_name}}</option>
                                            </b-form-select>
                                        </div>
                                    </div>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="col-xl-12 common_zero_pdng"> -->

        <!-- <div class="col-xl-12 common_zero_pdng"> -->

        <!-- <div class="col-xl-12">
                    <div  class="dashboard-box">


                        <div class="headline">
                            <h3><i class="icon-material-outline-school jobseekeres_i"></i>Job Preferences</h3>

                        </div>

                        <div class="content with-padding  new_account_pdng">
                            <div class="row">
                                <div class="col-xl-12 checkbox_upper_div">
                                    <input type='checkbox' @click='checkAll()' v-model='isCheckAll'> Check All

                                        <span v-for='cat in category'>
                                        <input type='checkbox' v-bind:value='cat.id' v-model='categories' @change='updateCheckall()'> <label>{{ cat.name }} </label>
                                        </span>

                                    </div>
                            </div>
                        </div>
                    </div>
                </div> -->
        <!-- Dashboard Box -->
        <!-- <div class="col-xl-12 common_zero_pdng"> -->

        <!-- <b-container class="single_accordian">
                <div >
                    <div class="faq-content-box clearfix">
                        <div >
                            <div class="accordion-listing-box ">

                                <b-card no-body >
                                    <b-card-header header-tag="header" role="tab">
                                        <b-button v-b-toggle="'accordion-dp-certificate-details-jbs-'+key" class="m-1" variant="info">
                                                <div class="dashboard-box">
                                                <div class="headline">
                                                    <h3><i class="material-icons-outlined new_icon_sidebar_material accordian_contact">connect_without_contact_outlined</i>Certificate Details</h3>


                                                </div>
                                                </div>
                                        </b-button>
                                    </b-card-header>
                                    <b-collapse :id="'accordion-dp-certificate-details-jbs-'+key" accordion="my-accordion" role="tabpanel">
                                        <b-card-body>
                                                <div class="content with-padding certificate_details_jbs">

                                                    <div class="listings-container compact-list-layout">
                                            <div class="job-overview-inner whole_box_h5">
                                            <div class="boxed-list-headline boxed_border_unset"><h3 class="font_weight_600 common_margin_bottom_zero"><i class="material-icons-outlined new_icon_material icon_flow">book_outlined</i>Certificates<b-link style="float: right;" class="profile_cert btn green-border-btn new_edit_ft_btn desktop_margin_top_zero" v-if="form.cerificate.length == 0" :to="{name: 'manage-certificate'}" >Manage Certificate</b-link></h3></div>

                                                <div class="profile-content-detail" v-if="form.cerificate.length >0">

                                                    <div class="images_listing admin_image_listing">
                                                        <viewer :images="images">
                                                            <div v-for="docs in form.cerificate.slice().reverse()" track-by="id" class="certificate_listings_single">
                                                                    <img  :src="docs.url" :key="docs.url" class="desktop_margin_bottom_zero">
                                                                    <div class="icon_div">
                                                                        <p><span class="span_1"><b>Obtainment Date:</b> {{docs.obtainment_date | customFormatterTwo1}}</span><span><b>Type:</b> {{docs.title}}</span></p>
                                                                        <p><span class="span_3"><b>Expiry Date:</b> {{docs.expiry_date | customFormatterTwo1}}</span><span><a :href="docs.url" target="_blank"><i class="fa fa-download"></i> </a></span></p>
                                                                    </div>
                                                            </div>
                                                        </viewer>
                                                    </div>

                                                </div>
                                                <div class="content text-center" v-else>
                                                    <p class="no_record"><b>No Record Found</b> </p>
                                                </div>
                                            </div>
                                        </div>

                                            </div>
                                    </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </div>
                        </div>
                    </div>
                </div>
            </b-container> -->
        <b-container class="single_accordian pink_accordian">
          <!-- <br> -->
          <div>
            <div class="faq-content-box clearfix">
              <div>
                <div class="accordion-listing-box">
                  <b-card no-body>
                    <b-card-header header-tag="header" role="tab">
                      <b-button
                        v-b-toggle=""
                        class="m-1"
                        variant="info"
                        @click="toggle_Custom_Accordian()"
                      >
                        <div class="dashboard-box">
                          <div class="headline">
                            <h3 class="common_pink_text_color common_font_weight_800">
                              <i
                                class="icon-material-outline-account-circle common_pink_text_color"
                              ></i
                              >Daily Paid Profile
                            </h3>
                          </div>
                        </div>
                      </b-button>
                    </b-card-header>
                    <b-collapse
                      accordion="my-accordion"
                      role="tabpanel"
                      class="show_custom_accordian"
                    >
                      <b-card-body>
                        <div class="content with-padding">
                          <div class="row">
                            <div class="col-xl-12">
                              <h3 class="personal_history">Personal History</h3>
                            </div>
                            <div class="col-xl-6">
                              <div class="submit-field mobile_bottom_two_eight">
                                <h5>
                                  Do you have any previous criminal record?
                                  <span class="required_star">*</span>
                                </h5>
                                <b-form-select
                                  data-size="7"
                                  title="Select Gender"
                                  v-model="form.crime"
                                  class="custom-select web_bottom_zero"
                                  id="gender"
                                  @change="showCrime(form.crime)"
                                  :disabled="account_verification_status == 'Pending'"
                                >
                                  <option value="" disabled>Select</option>
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </b-form-select>
                              </div>
                              <!-- <div class="show_span">
                                                                <span class="error_x_white">please select Pay Out Option </span>
                                                    </div> -->
                            </div>
                            <div class="col-xl-6">
                              <div
                                class="submit-field mobile_margin_bottom_28 big_screen_bottom_28 mobile_bottom_two_eight other_category_class"
                              >
                                <h5>Please specify <span class="required_star">*</span></h5>
                                <input
                                  type="text"
                                  class="with-border web_bottom_zero form-control"
                                  v-model="form.crime_reason"
                                  :disabled="account_verification_status == 'Pending'"
                                />
                              </div>
                              <!-- <div class="show_span">
                                                                <span class="error_x_white">Please enter Mobile </span>
                                                            </div> -->
                            </div>
                            <div class="col-xl-6">
                              <div class="submit-field">
                                <h5>
                                  Do you have any past/previous medical history?
                                  <span class="required_star">*</span>
                                </h5>
                                <b-form-select
                                  data-size="7"
                                  title="Select Medical History"
                                  v-model="form.medical"
                                  class="custom-select web_bottom_zero"
                                  id="gender"
                                  @change="showMedical(form.medical)"
                                  :disabled="account_verification_status == 'Pending'"
                                >
                                  <option value="" disabled>Select</option>
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </b-form-select>
                              </div>
                              <!-- <div class="show_span">
                                                                <span class="error_x_white">please select Pay Out Option </span>
                                                    </div> -->
                            </div>
                            <div class="col-xl-6">
                              <div class="submit-field other_medical_class">
                                <h5>Please specify <span class="required_star">*</span></h5>
                                <b-form-group id="input-group-8" class="required">
                                  <multiselect
                                    v-model="form.medical_reason"
                                    id="ajax"
                                    label="title"
                                    track-by="id"
                                    placeholder="Type to search medical conditions"
                                    open-direction="bottom"
                                    :multiple="true"
                                    :options="medical_history"
                                    :searchable="true"
                                    :clear-on-select="false"
                                    :close-on-select="false"
                                    :allow-empty="true"
                                    @input="medicalHistoryChange()"
                                    class="new_multi_slec_list job_list_multi_select multiselect_border"
                                  >
                                    <span slot="noResult">No Address Found.</span>
                                  </multiselect>
                                </b-form-group>
                              </div>
                              <!-- <div class="show_span">
                                                                <span class="error_x_white">Please enter Mobile </span>
                                                            </div> -->
                            </div>
                            <div class="col-xl-6"></div>
                            <div class="col-xl-6" v-if="other">
                              <div
                                class="submit-field web_bottom_zero mobile_bottom_two_eight"
                                id="pay"
                              >
                                <h5>
                                  Please enter other Medical History
                                  <span class="required_star">*</span>
                                </h5>
                                <input
                                  type="text"
                                  id="mob"
                                  class="with-border web_bottom_zero form-control"
                                  v-model="form.other_medical_history"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xl-12">
                              <h3 class="personal_history">Payout Details</h3>
                            </div>
                            <div class="col-xl-3">
                              <div class="submit-field mobile_bottom_two_eight">
                                <h5>Preferred Payout Option</h5>
                                <b-form-select
                                  data-size="7"
                                  title="Select Payout"
                                  v-model="form.payout"
                                  class="custom-select web_bottom_zero"
                                  id="gender"
                                  @change="payout()"
                                >
                                  <option value="" disabled>Select</option>
                                  <option value="paynow">PayNow</option>
                                  <option value="bank">Bank</option>
                                </b-form-select>
                              </div>
                              <!-- <div class="show_span">
                                                                <span class="error_x_white">please select Pay Out Option </span>
                                                    </div> -->
                            </div>
                            <div class="col-xl-9"></div>
                            <div class="col-xl-3">
                              <div
                                class="submit-field web_bottom_zero mobile_bottom_two_eight"
                                id="pay"
                              >
                                <h5>Paynow Number <span class="required_star">*</span></h5>
                                <input
                                  type="text"
                                  id="mob"
                                  class="with-border web_bottom_zero form-control"
                                  v-model="form.paynow"
                                />
                              </div>
                              <!-- <div class="show_span" id="paynow">
                                                                <span class="error_x_white">Please enter Mobile </span>
                                                            </div> -->
                            </div>
                            <div class="col-xl-9"></div>
                            <div class="col-xl-4">
                              <div
                                class="submit-field mobile_margin_bottom_28 mobile_bottom_two_eight web_bottom_zero big_screen_bottom_28"
                                id="b_name"
                              >
                                <h5>Bank Name</h5>
                                <b-form-select
                                  data-size="7"
                                  title="Select Bank"
                                  v-model="form.bank"
                                  class="custom-select web_bottom_zero"
                                  id="bankName"
                                >
                                  <option value="" disabled>Select</option>
                                  <option :value="bank.id" v-for="(bank, id) in banks" :key="id">
                                    {{ bank.bank_name }}
                                  </option>
                                </b-form-select>
                              </div>
                              <!-- <div class="show_span">
                                                                <span class="error_x_white">Please select Bank </span>
                                                    </div> -->
                            </div>
                            <div class="col-xl-4">
                              <div
                                class="submit-field mobile_margin_bottom_28 mobile_bottom_two_eight big_screen_bottom_28 web_bottom_zero"
                                id="b_number"
                              >
                                <h5>Bank Account Number</h5>
                                <input
                                  type="text"
                                  class="with-border web_bottom_zero form-control"
                                  v-model="form.acc_number"
                                  id="accNo"
                                />
                              </div>
                              <!-- <div class="show_span">
                                                                <span class="error_x_white">Please enter Account Number </span>
                                                            </div> -->
                            </div>
                            <div class="col-xl-4">
                              <div class="submit-field web_bottom_zero" id="b_holder">
                                <h5>Bank Account Holder Name</h5>
                                <input
                                  type="text"
                                  class="with-border web_bottom_zero form-control"
                                  v-model="form.holder_name"
                                  id="accHold"
                                />
                              </div>
                              <!-- <div class="show_span">
                                                                <span class="error_x_white">Please enter Account Holder name </span>
                                                            </div> -->
                            </div>
                          </div>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
            </div>
          </div>
        </b-container>
        <b-container class="single_accordian pink_accordian">
          <!-- <br> -->
          <div>
            <div class="faq-content-box clearfix">
              <div>
                <div class="accordion-listing-box">
                  <b-card no-body>
                    <b-card-header header-tag="header" role="tab" class="contact_header">
                      <b-button
                        v-b-toggle="'accordion-dp-contact-details-jbs-' + key"
                        class="m-1"
                        variant="info"
                      >
                        <div class="dashboard-box">
                          <div class="headline">
                            <h3 class="common_pink_text_color common_font_weight_800">
                              <i
                                class="common_pink_text_color material-icons-outlined new_icon_sidebar_material accordian_contact"
                                >connect_without_contact_outlined</i
                              >Emergency Contact Details
                            </h3>
                          </div>
                        </div>
                      </b-button>
                    </b-card-header>
                    <b-collapse
                      :id="'accordion-dp-contact-details-jbs-' + key"
                      accordion="my-accordion"
                      role="tabpanel"
                      class="error_mrgn"
                    >
                      <b-card-body>
                        <div class="content with-padding">
                          <div class="row">
                            <div class="col-xl-3">
                              <div
                                class="submit-field mobile_margin_bottom_28 mobile_bottom_two_eight big_screen_bottom_28 web_bottom_zero"
                              >
                                <h5>First Name</h5>
                                <input
                                  type="text"
                                  class="with-border web_bottom_zero"
                                  v-model="form.emer_name"
                                  id="emer_name"
                                />
                              </div>
                              <div class="show_span">
                                <span class="error_x_white">Please enter First Name </span>
                              </div>
                            </div>

                            <div
                              class="col-xl-3 mobile_bottom_two_eight tab_margin_bottom_28 big_screen_bottom_28"
                            >
                              <div class="submit-field web_bottom_zero">
                                <h5>Last Name</h5>
                                <input
                                  type="text"
                                  class="with-border web_bottom_zero"
                                  v-model="form.emer_name_last"
                                  id="emer_last_name"
                                />
                              </div>
                              <div class="show_span">
                                <span class="error_x_white">Please enter Last Name </span>
                              </div>
                            </div>

                            <div
                              class="col-xl-3 mobile_bottom_two_eight tab_margin_bottom_28 big_screen_bottom_28"
                            >
                              <div class="submit-field web_bottom_zero">
                                <h5>Mobile Number</h5>
                                <input
                                  type="text"
                                  class="with-border web_bottom_zero"
                                  v-model="form.emer_phone"
                                  id="emer_phone"
                                />
                              </div>
                              <div class="show_span">
                                <span class="error_x_white">Please enter mobile number </span>
                              </div>
                            </div>

                            <div class="col-xl-3">
                              <div class="submit-field web_bottom_zero">
                                <h5>Relationship</h5>
                                <b-form-select
                                  data-size="7"
                                  title="Select Gender"
                                  v-model="form.emer_relation"
                                  class="custom-select web_bottom_zero"
                                  id="emer_relation"
                                >
                                  <option value="">Select</option>
                                  <option
                                    :value="relation.id"
                                    v-for="(relation, id) in relations"
                                    :key="id"
                                  >
                                    {{ relation.relation_name | capitalize }}
                                  </option>
                                </b-form-select>
                              </div>
                              <div class="show_span">
                                <span class="error_x_white web_bottom_zero"
                                  >Please enter Relationship
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
            </div>
          </div>
        </b-container>
        <b-container class="single_accordian pink_accordian">
          <!-- <br> -->
          <div>
            <div class="faq-content-box clearfix">
              <div>
                <div class="accordion-listing-box">
                  <b-card no-body>
                    <b-card-header header-tag="header" role="tab">
                      <b-button
                        v-b-toggle="'accordion-dp-job-prefrences-jbs-' + key"
                        class="m-1"
                        variant="info"
                      >
                        <div class="dashboard-box">
                          <div class="headline">
                            <h3 class="common_pink_text_color common_font_weight_800">
                              <i
                                class="common_pink_text_color material-icons-outlined new_icon_sidebar_material accordian_contact"
                                >work_outline_outlined</i
                              >Daily Paid Job Preferences
                            </h3>
                          </div>
                        </div>
                      </b-button>
                    </b-card-header>
                    <b-collapse
                      :id="'accordion-dp-job-prefrences-jbs-' + key"
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body>
                        <div class="content with-padding">
                          <div class="row">
                            <div class="col-xl-12 checkbox_upper_div">
                              <input type="checkbox" @click="checkAll()" v-model="isCheckAll" />
                              <label>Check All </label>

                              <span v-for="cat in category">
                                <input
                                  type="checkbox"
                                  v-bind:value="cat.id"
                                  v-model="categories"
                                  @change="updateCheckall()"
                                />
                                <label>{{ cat.name }} </label>
                              </span>
                            </div>
                          </div>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
            </div>
          </div>
        </b-container>
        <!-- <div class="col-xl-12 common_zero_pdng"> -->
        <!-- <div class="col-xl-12">
                    <div id="" class="dashboard-box">


                        <div class="headline">
                            <h3><i class="fas fa-tools"></i> Emergency Contact Details</h3>


                        </div>

                        <div class="content with-padding">
                            <div class="row">
                               <div class="col-xl-3">
                                       <div class="submit-field mobile_margin_bottom_28 mobile_bottom_two_eight big_screen_bottom_28 web_bottom_zero">
                                            <h5>First Name</h5>
                                            <input type="text" class="with-border web_bottom_zero" v-model="form.emer_name" id="emer_name">
                                        </div>
                                         <div class="show_span">
                                                     <span class="error_x_white">Please enter First Name </span>
                                            </div>
                                    </div>

                                    <div class="col-xl-3">
                                       <div class="submit-field mobile_margin_bottom_28 mobile_bottom_two_eight big_screen_bottom_28 web_bottom_zero">
                                            <h5>Last Name</h5>
                                            <input type="text" class="with-border web_bottom_zero" v-model="form.emer_name_last" id="emer_last_name">
                                        </div>
                                         <div class="show_span">
                                                     <span class="error_x_white">Please enter Last Name </span>
                                                  </div>
                                    </div>

                                    <div class="col-xl-3">
                                       <div class="submit-field mobile_margin_bottom_28 mobile_bottom_two_eight big_screen_bottom_28 web_bottom_zero">
                                            <h5>Mobile Number</h5>
                                            <input type="text" class="with-border web_bottom_zero" v-model="form.emer_phone" id="emer_phone">
                                        </div>
                                         <div class="show_span">
                                                     <span class="error_x_white">Please enter mobile number </span>
                                                  </div>
                                    </div>

                                    <div class="col-xl-3">
                                       <div class="submit-field web_bottom_zero">
                                            <h5>Relationship</h5>
                                            <b-form-select data-size="7" title="Select Gender"  v-model="form.emer_relation" class="custom-select web_bottom_zero" id="emer_relation">
                                                        <option value="">Select</option>
                                                        <option :value="relation.id" v-for="(relation,id) in relations" :key="id">{{relation.relation_name | capitalize}}</option>

                                                    </b-form-select>
                                        </div>
                                         <div class="show_span">
                                                     <span class="error_x_white">Please enter Relationship </span>
                                                  </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div> -->

        <!-- <div class="col-xl-12 common_zero_pdng">  -->
        <!-- <div class="col-xl-12">
                <div class="tacbox agree_note">
                        <input id="checkbox" type="checkbox" value="true" style="font-size:8px;" required/>
                        <span for=""> I hereby agree to the <a href="/terms-of-service" target="_blank">Terms of Service</a> of EL Connect Daily Paid.</span>
                    </div>
                </div> -->

        <!-- Dashboard Box -->

        <!-- Button -->
        <!-- <div class="col-xl-12 common_zero_pdng"> -->
        <div class="col-xl-12">
          <button
            @click="updatePersonalInfo()"
            type="button"
            class="button ripple-effect margin-top-30"
          >
            Save Profile
          </button>
        </div>
      </b-form>
      <b-modal
        ref="crop-modal"
        title="Edit Passport/Selfie Image"
        hide-footer
        :no-close-on-backdrop="noClose"
        content-class="common_model_header common_model_title"
        modal-class="background_custom_class"
      >
        <b-form @submit="onSubmitImg">
          <template>
            <div>
              <div class="content" @change="setImage">
                <section class="cropper-area">
                  <div class="img-cropper">
                    <vue-cropper
                      ref="cropper"
                      :aspect-ratio="1 / 1"
                      :src="tempLogo"
                      preview=".preview"
                    />
                  </div>
                </section>
              </div>
            </div>
            <div class="pt-2 mt-3 text-md-left text-center common_crop_reset_btn">
              <b-button
                variant="success"
                class="text-uppercase x-md btn-shadow"
                @click.prevent="cropImage"
                >Crop</b-button
              >

              <b-button
                variant="danger"
                class="text-uppercase x-md btn-shadow"
                @click.prevent="reset"
                >Reset</b-button
              >
            </div>
          </template>
        </b-form>
      </b-modal>
      <b-modal
        ref="crop-modal-banner"
        title="Edit NRIC Front Image"
        hide-footer
        :no-close-on-backdrop="noClose"
        content-class="common_model_header common_model_title"
        modal-class="background_custom_class"
      >
        <b-form @submit="onSubmitImgBanner">
          <template>
            <div>
              <div class="content" @change="setImageBanner">
                <section class="cropper-area">
                  <div class="img-cropper">
                    <vue-cropper
                      ref="cropper"
                      :aspect-ratio="3 / 2"
                      :src="tempLogoBanner"
                      preview=".preview"
                    />
                  </div>
                </section>
              </div>
            </div>
            <div class="pt-2 mt-3 text-md-left text-center common_crop_reset_btn">
              <b-button
                variant="success"
                class="text-uppercase x-md btn-shadow"
                @click.prevent="cropImageBanner"
                >Crop</b-button
              >

              <b-button
                variant="danger"
                class="text-uppercase x-md btn-shadow"
                @click.prevent="reset"
                >Reset</b-button
              >
            </div>
          </template>
        </b-form>
      </b-modal>

      <b-modal
        ref="crop-modal-banner-back"
        title="Edit NRIC Back Image"
        hide-footer
        :no-close-on-backdrop="noClose"
        content-class="common_model_header common_model_title"
        modal-class="background_custom_class"
      >
        <b-form @submit="onSubmitImgBannerback">
          <template>
            <div>
              <div class="content" @change="setImageBannerback">
                <section class="cropper-area">
                  <div class="img-cropper">
                    <vue-cropper
                      ref="cropper"
                      :aspect-ratio="3 / 2"
                      :src="tempLogoBanner"
                      preview=".preview"
                    />
                  </div>
                </section>
              </div>
            </div>
            <div class="pt-2 mt-3 text-md-left text-center common_crop_reset_btn">
              <b-button
                variant="success"
                class="text-uppercase x-md btn-shadow"
                @click.prevent="cropImageBannerback"
                >Crop</b-button
              >

              <b-button
                variant="danger"
                class="text-uppercase x-md btn-shadow"
                @click.prevent="reset"
                >Reset</b-button
              >
            </div>
          </template>
        </b-form>
      </b-modal>

      <b-modal
        ref="crop-modal-food"
        title="Edit Food Certificate"
        hide-footer
        :no-close-on-backdrop="noClose"
      >
        <b-form @submit="onSubmitFoodCert">
          <template>
            <div>
              <div class="content" @change="setImageBannerback">
                <section class="cropper-area">
                  <div class="img-cropper">
                    <vue-cropper
                      ref="cropper"
                      :aspect-ratio="0 / 0"
                      :src="tempLogoBanner"
                      preview=".preview"
                    />
                  </div>
                </section>
              </div>
            </div>
            <div class="pt-2 mt-3 text-md-left text-center">
              <b-button
                variant="success"
                class="text-uppercase x-md btn-shadow"
                @click.prevent="cropFoodCert"
                >Crop</b-button
              >

              <b-button
                variant="danger"
                class="text-uppercase x-md btn-shadow"
                @click.prevent="reset"
                >Reset</b-button
              >
            </div>
          </template>
        </b-form>
      </b-modal>

      <b-modal
        ref="crop-modal-seq"
        title="Edit Security Certificate"
        hide-footer
        :no-close-on-backdrop="noClose"
      >
        <b-form @submit="onSubmitSeqCert">
          <template>
            <div>
              <div class="content" @change="setImageBannerback">
                <section class="cropper-area">
                  <div class="img-cropper">
                    <vue-cropper
                      ref="cropper"
                      :aspect-ratio="0 / 0"
                      :src="tempLogoBanner"
                      preview=".preview"
                    />
                  </div>
                </section>
              </div>
            </div>
            <div class="pt-2 mt-3 text-md-left text-center">
              <b-button
                variant="success"
                class="text-uppercase x-md btn-shadow"
                @click.prevent="cropSeqCert"
                >Crop</b-button
              >

              <b-button
                variant="danger"
                class="text-uppercase x-md btn-shadow"
                @click.prevent="reset"
                >Reset</b-button
              >
            </div>
          </template>
        </b-form>
      </b-modal>
      <b-modal
        ref="verify-mobile-modal"
        no-close-on-backdrop
        hide-footer
        content-class="mobile_verification_model common_model_title"
        title="Phone Verification"
        modal-class="background_custom_class mobile_pop_up_mdl"
      >
        <template #modal-title>
          Phone Verification
          <span
            ><button type="button" @click="mobileVerifyCancel" class="close closefirstmodal">
              &times;
            </button></span
          >
        </template>
        <b-form class="">
          <div class="edit-form-box web_top_zero">
            <div class="profile-repeate form-box-field">
              <div class="col-xl-12">
                <!-- <h3><b>Phone Verification</b></h3> -->
                <h5 class="gray_h5">
                  Enter the verification code that<br />
                  was sent to your mobile number.
                </h5>
              </div>
              <b-row>
                <b-col lg="12" md="12">
                  <div class="form-map-box">
                    <b-col lg="12" md="12" class="input-with-icon-left">
                      <i class="fa fa-mobile pop_up_i"></i>
                      <b-form-group id="input-group-9" label="" class="web_bottom_zero">
                        <b-form-input
                          id="input-9"
                          maxlength="20"
                          v-model="form.mobile_number"
                          type="text"
                          placeholder=""
                          readonly="readonly"
                          class="number_input"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col lg="12" md="12">
                      <b-form-group id="input-group-9" label="" class="web_bottom_zero">
                        <input
                          type="text"
                          v-model="form.var_code"
                          class="with-border form-control"
                          placeholder="Enter the 4-digit code"
                        />
                      </b-form-group>
                    </b-col>

                    <div slot="footer" class="form-btn col-md-12 col-lg-12">
                      <b-button
                        @click="mobileVerify"
                        variant="success"
                        style="background-color: #4c8bf5 !important"
                        class="otp_btn verify_otp_btn_mod"
                        >Verify OTP</b-button
                      >
                      <!-- <b-button @click = "hideVerifyMobileModel()" variant="light">Cancel</b-button> -->
                    </div>
                  </div>
                </b-col>
              </b-row>
              <div class="col-xl-12 bottom_text_pop_phone">
                <h5>
                  Verification code may take up to a few <br />minutes to be sent to your phone.
                </h5>
                <h5 class="web_bottom_zero" v-if="countDown > 0">
                  Didn't receive the code? Resend code in
                  <span style="color: #4c8bf5 !important"> {{ countDown }} seconds</span>
                </h5>
                <h5 class="web_bottom_zero" v-else>
                  Didn't recevie the code?
                  <b-link @click="sendOTP" style="color: #4c8bf5 !important"> Resend Code</b-link>
                </h5>
              </div>
            </div>
          </div>
        </b-form>
      </b-modal>
      <b-modal
        ref="sample-image-modal"
        no-close-on-backdrop
        hide-footer
        content-class="mobile_verification_model common_model_title"
        title="Sample Image"
        modal-class="background_custom_class"
      >
        <b-form class="">
          <div class="edit-form-box web_top_zero">
            <div class="profile-repeate form-box-field">
              <div class="col-xl-12">
                <!-- <h3><b>Sample Image</b></h3> -->
              </div>
              <b-row>
                <b-col lg="12" md="12">
                  <img
                    src="https://sgp1.digitaloceanspaces.com/elconnect/v2_images/sample_profile.png"
                    alt="Italian Trulli"
                    class="sample_image_single"
                  />
                </b-col>
              </b-row>
            </div>
          </div>
        </b-form>
      </b-modal>
      <b-modal
        ref="crop-modal-banner-profile"
        id="background_custom_class_for_banner"
        title="Edit Banner Image"
        hide-footer
        :no-close-on-backdrop="noClose"
        content-class="common_model_header common_model_title"
        modal-class="background_custom_class"
      >
        <b-form @submit="onSubmitImgBanner">
          <template>
            <div>
              <div class="content" @change="setImageBanner">
                <section class="cropper-area">
                  <div class="img-cropper">
                    <vue-cropper
                      ref="cropper"
                      :aspect-ratio="10 / 3"
                      :src="tempLogoBanner1"
                      preview=".preview"
                    />
                  </div>
                </section>
              </div>
            </div>
            <div class="pt-2 mt-3 text-md-left text-center common_crop_reset_btn">
              <b-button
                variant="success"
                class="text-uppercase x-md btn-shadow"
                @click.prevent="cropImageBanner1"
                >Crop</b-button
              >

              <b-button
                variant="danger"
                class="text-uppercase x-md btn-shadow"
                @click.prevent="reset"
                >Reset</b-button
              >
            </div>
          </template>
        </b-form>
      </b-modal>
      <b-modal
        ref="survey-modal"
        id="background_custom_class_for_banner"
        title="Where did you hear about us?"
        hide-footer
        centered
        hide-header
        :no-close-on-backdrop="noClose"
        content-class="common_model_header common_model_title"
        modal-class="background_custom_class survey_model"
      >
        <b-form>
          <template>
            <div class="row survay_img_mobile">
              <div class="col-xl-8 col-md-8 survay_img">
                <img :src="webUrl + 'assets/images/Pencil 1.png'" />
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12">
                <h2 class="survay_heading">Where did you hear about us?</h2>
              </div>
            </div>
            <div class="row">
              <div
                class="mobile_tab_col_four col-xl-4 col-md-4 desktop_margin_bottom_zero single_profile_eye"
                id="show_hide_current_password"
              >
                <b-form-group
                  id="input-group-8"
                  label=""
                  class="category-radio employer_multiple_radio"
                >
                  <b-form-radio v-model="heard_from" value="facebook"><b>Facebook</b></b-form-radio>
                  <b-form-radio v-model="heard_from" value="instagram"
                    ><b>Instagram</b></b-form-radio
                  >
                  <b-form-radio v-model="heard_from" value="tiktok"><b>Tiktok</b></b-form-radio>
                  <b-form-radio v-model="heard_from" value="linkedin"><b>Linkedin</b></b-form-radio>
                  <b-form-radio v-model="heard_from" value="google"><b>Google</b></b-form-radio>
                  <b-form-radio v-model="heard_from" value="friends_family"
                    ><b>Friends/Family</b></b-form-radio
                  >
                  <b-form-radio v-model="heard_from" value="other"><b>Other</b></b-form-radio>
                </b-form-group>
              </div>
              <div class="col-xl-8 col-md-8 survay_img survay_img_desktop">
                <img :src="webUrl + 'assets/images/Pencil 1.png'" />
              </div>
            </div>
            <div class="row">
              <div class="col-xl-8 col-md-8">
                <div
                  class="submit-field desktop_margin_bottom_zero"
                  :class="heard_from == 'other' ? '' : 'other_margin_top_zero'"
                  v-if="heard_from == 'other'"
                >
                  <div class="">
                    <div class="survay_textarea">
                      <!-- <h5 class="">Remark<span class="required_star">*</span></h5> -->
                      <textarea
                        type="text"
                        class="model_margin_bottom_zero with-border desktop_margin_bottom_zero form-control"
                        v-model="heard_from_comment"
                        placeholder="State here"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Button -->
              <div
                class="col-xl-4 col-md-4 text-md-right new_text_align_end common_crop_reset_btn survey_model_btn"
              >
                <b-button
                  variant="success"
                  class="text-uppercase x-md btn-shadow"
                  @click.prevent="surveyFormUpdateFLEmp"
                  >Submit</b-button
                >
              </div>
            </div>
          </template>
        </b-form>
      </b-modal>
    </div>
    <!-- Row / End -->
  </div>
</template>

<script>
import { POST_API, GET_API } from "../../../store/actions.type";
import Multiselect from "vue-multiselect";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import CKEditor from "ckeditor4-vue";
import Bus from "../../../event-bus";

export default {
  data() {
    return {
      fullYear: new Date().getFullYear(),
      d_date: "",
      year: "",
      month: "",
      day: "",
      dayArray: [],
      monthArray: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
      disable_dob_date: true,
      disable_dob_month: true,
      form: {
        first_name: "",
        last_name: "",
        full_name: "",

        nric: "",
        raceid: null,
        banner_image_back: "",
        banner_image_front: "",
        seq_cert_image: "",
        cerificate: "",
        food_cert_image: "",
        crime: "no",
        crime_reason: "",
        food_type: "",
        medical: "no",
        medical_reason: "",
        profile_image: "",
        other_medical_history: "",
        address: "",
        race: "",
        verify_otp_button: false,
        mobile_verified_check: false,
        sortdesc: "",
        skills: [],
        dob: "",
        obtainment_date: "",
        thumbnail: "",
        payout: "paynow",
        paynow: "",
        nric_front: {
          name: "",
          image: "",
        },

        nric_back: {
          name: "",
          image: "",
        },
        selectedBannerImage: {
          name: "",
          image: "",
        },
        selectedImage: {
          name: "",
          image: "",
        },

        selectedFoodCert: {
          name: "",
          image: "",
        },

        selectedseqCert: {
          name: "",
          image: "",
        },
        selectedResume: {
          name: "",
          image: "",
        },
        user_id: "",
        gender: "",
        nationality: "5",
        bank: "",
        acc_number: "",
        holder_name: "",
        emer_name: "",
        emer_name_last: "",

        emer_phone: "",
        emer_relation: "",
      },
      images: [],
      type: [],

      category: [],
      categories: [],
      isCheckAll: false,
      age: 0,
      rejection_comments: "",
      seqcerificateType: [],
      foodcerificateType: [],
      countDown: 60,
      countDownEmail: 0,
      showVerifyOtp: true,
      showVerifyEmail: true,

      link: {
        website: "",
        fb: "",
        google: "",
        twitter: "",
        linkedin: "",
      },
      email: "",
      password: "",
      user_type: "5",
      cerificate: [],

      status: "",
      account_verification_status: "",
      showDismissibleAlert: false,
      error_message: "",
      success_message: "",
      isActive: 1,
      appendQa: [],
      workExperienceData: [],
      qualificationData: [],
      socialData: [],
      blockRemovalQualification: true,
      appendExp: [],
      blockRemovalExperience: true,
      skillsArr: [],
      countries: [],
      types: [],
      race: [],
      banks: [],
      relations: [],
      qualificationArr: [],
      fieldOfStudyArr: [],
      universityArr: [],
      validErr: [],

      disabledFromDate: {
        from: new Date(Date.now() - 8640000),
        to: new Date(moment().subtract(100, "years")),
      },
      disabledFromDateDob: {
        from: new Date(moment(moment().subtract(15, "years")).format("DD-MM-YYYY")),
      },
      personal: true,
      links: false,
      disabled: true,
      img: "",
      showCV: "",
      cvUrl: "",
      cropImg: "",
      data: null,
      cropmove: null,
      tempLogo: "",
      cropImgBanner: "",
      cropImgBannerback: "",

      tempLogoBanner: "",
      tempLogoBanner1: "",

      noClose: true,
      duration_yrs: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      duration_mn: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      webUrl: process.env.VUE_APP_URL,
      editorConfig: {
        removeButtons: "Maximize",
        extraPlugins: "copyformatting,colorbutton,bidi,colordialog,widget,justify",
      },
      coreStyles_bold: {
        element: "b",
        overrides: "strong",
      },
      // Custom style definition for the Italic feature.
      coreStyles_italic: {
        element: "i",
        overrides: "em",
      },
      status: "",
      is_production_val: "yes",
      heard_from: "",
      heard_from_comment: "",
      key: "",
      name: "",
      medical_history: [],
      ispregnant: "false",
      other: false,
    };
  },
  components: {
    Multiselect,
    Datepicker,
    VueCropper,
    ckeditor: CKEditor.component,
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    customLabel({ title }) {
      return `${title}`;
    },
  },
  methods: {
    toggle_Custom_Accordian() {
      $(".show_custom_accordian").slideToggle();
    },
    setImageBanner(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.cropImgBanner = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    onSubmitImgBanner(evt) {
      evt.preventDefault();
      this.$refs["crop-modal-banner"].hide();
      this.$refs["crop-modal-banner"].refresh();
    },

    medicalHistoryChange() {
      console.log(this.form.medical_reason);
      this.form.medical_reason.forEach((item, index) => {
        if (item.title == "Other") {
          console.log(item.title);
          this.other = true;
        } else {
          this.other = false;
        }
      });
    },
    generateDateDropdown(selectedVal) {
      if (selectedVal == "year") {
        this.disable_dob_month = false;
      }
      if (selectedVal == "month") {
        this.disable_dob_date = false;
      }
      if (selectedVal == "monthyear") {
        this.disable_dob_month = false;
        this.disable_dob_date = false;
      }
      const daysInMonth = new Date(this.year, this.month, 0).getDate();
      console.log(daysInMonth, "++++++++++++++++++++++++++");
      this.dayArray = [];
      for (let i = 1; i <= daysInMonth; i++) {
        this.dayArray.push(i.toString().padStart(2, 0));
      }
      if (this.day > daysInMonth) {
        this.day = "";
      }
      console.log(this.dayArray, "++++++++++++++++++++++++++");
    },
    getMonths(m) {
      return moment()
        .month(parseInt(m) - 1)
        .format("MMMM");
    },
    mobileToolTipNumber() {
      var newWindowWidth = $(window).width();
      if (newWindowWidth <= 999) {
        $(".tooltip_txt_mobile_number").toggleClass("tooltip_text_mobile_view_number");
      }
    },
    mobileToolTipEmail() {
      var newWindowWidth = $(window).width();
      if (newWindowWidth <= 999) {
        $(".tooltip_txt_email").toggleClass("tooltip_text_mobile_view_email");
      }
    },
    countDownTimer() {
      if (this.countDown > 0) {
        this.showVerifyOtp = false;
        this.timeOutCounter = setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.showVerifyOtp = true;
      }
    },
    countDownTimerEmail() {
      if (this.countDownEmail > 0) {
        this.showVerifyEmail = false;
        this.timeOutCounterEmail = setTimeout(() => {
          this.countDownEmail -= 1;
          this.countDownTimerEmail();
        }, 1000);
      } else {
        this.showVerifyEmail = true;
      }
    },
    showCrime(id) {
      if (id == "yes") {
        $(".other_category_class").css("display", "block");
      } else {
        $(".other_category_class").css("display", "none");
      }
    },

    showRemark(id) {
      if (id == "yes") {
        $(".remark_class").css("display", "block");
      } else {
        $(".remark_class").css("display", "none");
      }
    },
    changePicker() {
      if (this.form.dob) {
        return this.form.dob;
      } else {
        return new Date(moment().subtract(15, "years"));
      }
    },

    showMedical(id) {
      if (id == "yes") {
        $(".other_medical_class").css("display", "block");
      } else {
        $(".other_medical_class").css("display", "none");
      }
    },

    showUniversity(id, index) {
      if (id == this.other_category_id_university) {
        $("#other_university_class" + index).css("display", "block");
      } else {
        $("#other_university_class" + index).css("display", "none");
      }
    },
    showsampleimageModel() {
      this.$refs["sample-image-modal"].show();
    },
    cropImage() {
      this.$refs["crop-modal"].hide();
      this.form.selectedImage.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    setImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.cropImg = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
    flipX() {
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute("data-scale", scale);
    },
    flipY() {
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute("data-scale", scale);
    },
    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
    },
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setCropBoxData() {
      if (!this.data) return;
      this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
    },
    setData() {
      if (!this.data) return;
      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    showFileChooser() {
      this.$refs.input.click();
    },

    showImg() {
      this.$refs["crop-modal"].show();
    },
    onSubmitImg(evt) {
      evt.preventDefault();
      this.$refs["crop-modal"].hide();
      this.$refs["crop-modal"].refresh();
    },

    cropImageBanner() {
      this.$refs["crop-modal-banner"].hide();
      this.form.nric_front.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    cropImageBanner1() {
      this.$refs["crop-modal-banner-profile"].hide();
      this.form.selectedBannerImage.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },

    cropImageBannerback() {
      this.$refs["crop-modal-banner-back"].hide();
      this.form.nric_back.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },

    cropFoodCert() {
      this.$refs["crop-modal-food"].hide();
      this.form.selectedFoodCert.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },

    cropSeqCert() {
      this.$refs["crop-modal-seq"].hide();
      this.form.selectedseqCert.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    setImageBanner(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.cropImgBanner = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },

    setImageBannerback(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.cropImgBannerback = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },

    showImgBanner() {
      this.$refs["crop-modal-banner"].show();
    },

    showImgBanner1() {
      this.$refs["crop-modal-banner-profile"].show();
    },

    showfoodCert() {
      this.$refs["crop-modal-food"].show();
    },
    showseqCert() {
      this.$refs["crop-modal-seq"].show();
    },

    showImgBannerback() {
      this.$refs["crop-modal-banner-back"].show();
    },
    onSubmitImgBanner(evt) {
      evt.preventDefault();
      this.$refs["crop-modal-banner"].hide();
      this.$refs["crop-modal-banner"].refresh();
    },

    onSubmitImgBannerback(evt) {
      evt.preventDefault();
      this.$refs["crop-modal-banner-back"].hide();
      this.$refs["crop-modal-banner-back"].refresh();
    },

    onSubmitFoodCert(evt) {
      evt.preventDefault();
      this.$refs["crop-modal-food"].hide();
      this.$refs["crop-modal-food"].refresh();
    },

    onSubmitSeqCert(evt) {
      evt.preventDefault();
      this.$refs["crop-modal-seq"].hide();
      this.$refs["crop-modal-seq"].refresh();
    },

    onBannerChangeback(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        this.form.banner_image_back = input.files[0];
        if (
          this.form.banner_image_back.type != "image/png" &&
          this.form.banner_image_back.type != "image/jpeg" &&
          this.form.banner_image_back.type != "image/jpg"
        ) {
          this.form.nric_back.image = this.webUrl + "../assets/images/upload-icon.svg";
          this.error_message = "Please select only .png,.jpg or .jpeg image.";
          this.form.nric_back.image = "";
          this.form.nric_back.name = "";
          event.target.value = "";
          this.showAlert();
        } else if (this.form.banner_image_back.size > 5242880) {
          this.form.nric_back.image = this.webUrl + "../assets/images/upload-icon.svg";
          this.error_message = "NRIC back image should be less than 5 MB.";
          this.form.nric_back.image = "";
          this.form.nric_back.name = "";
          event.target.value = "";
          this.showAlert();
        } else {
          reader.onload = (e) => {
            this.tempLogoBanner = e.target.result;
            this.form.nric_back.name = this.form.banner_image_back.name;
            this.showImgBannerback();
            event.target.value = "";
          };
        }
      }
    },

    checkAll: function () {
      this.isCheckAll = !this.isCheckAll;
      this.categories = [];
      if (this.isCheckAll) {
        // Check all
        for (var key in this.category) {
          this.categories.push(this.category[key].id);
        }
      }
    },
    updateCheckall: function () {
      if (this.categories.length == this.category.length) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
    },
    // showImgBanner() {
    //     this.$refs['crop-modal-banner-profile'].show();
    // },
    onBannerChange(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        this.form.banner_image = input.files[0];
        if (
          this.form.banner_image.type != "image/png" &&
          this.form.banner_image.type != "image/jpeg" &&
          this.form.banner_image.type != "image/jpg"
        ) {
          this.form.selectedBannerImage.image = this.webUrl + "../assets/images/upload-icon.svg";
          this.error_message = "Please select only .png,.jpg or .jpeg image.";
          this.form.selectedBannerImage.image = "";
          this.form.selectedBannerImage.name = "";
          event.target.value = "";
          this.showAlert();
        } else if (this.form.banner_image.size > 5242880) {
          this.form.selectedBannerImage.image = this.webUrl + "../assets/images/upload-icon.svg";
          this.error_message = "Banner image should be less than 5 MB.";
          this.form.selectedBannerImage.image = "";
          this.form.selectedBannerImage.name = "";
          event.target.value = "";
          this.showAlert();
        } else {
          reader.onload = (e) => {
            //    this.tempLogoBanner = e.target.result;
            this.tempLogoBanner1 = e.target.result;

            this.form.selectedBannerImage.name = this.form.banner_image.name;
            this.showImgBanner1();
            event.target.value = "";
            console.log();
          };
        }
      }
    },
    onBannerChangefront(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        this.form.banner_image_front = input.files[0];
        if (
          this.form.banner_image_front.type != "image/png" &&
          this.form.banner_image_front.type != "image/jpeg" &&
          this.form.banner_image_front.type != "image/jpg"
        ) {
          this.form.nric_front.image = this.webUrl + "../assets/images/upload-icon.svg";
          this.error_message = "Please select only .png,.jpg or .jpeg image.";
          this.form.nric_front.image = "";
          this.form.nric_front.name = "";
          event.target.value = "";
          this.showAlert();
        } else if (this.form.banner_image_front.size > 5242880) {
          this.form.nric_front.image = this.webUrl + "../assets/images/upload-icon.svg";
          this.error_message = "NRIC front image should be less than 5 MB.";
          this.form.nric_front.image = "";
          this.form.nric_front.name = "";
          event.target.value = "";
          this.showAlert();
        } else {
          reader.onload = (e) => {
            this.tempLogoBanner = e.target.result;
            this.form.nric_front.name = this.form.banner_image_front.name;
            this.showImgBanner();
            event.target.value = "";
          };
        }
      }
    },

    onFoodCertChange(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        this.form.food_cert_image = input.files[0];
        if (
          this.form.food_cert_image.type != "image/png" &&
          this.form.food_cert_image.type != "image/jpeg" &&
          this.form.food_cert_image.type != "image/jpg"
        ) {
          this.form.selectedFoodCert.image = this.webUrl + "../assets/images/upload-icon.svg";
          this.error_message = "Please select only .png,.jpg or .jpeg image.";
          this.form.selectedFoodCert.image = "";
          this.showAlert();
        } else if (this.form.food_cert_image.size > 5242880) {
          this.form.selectedFoodCert.image = this.webUrl + "../assets/images/upload-icon.svg";
          this.error_message = "Banner image should be less than 5 MB.";
          this.form.selectedFoodCert.image = "";
          this.showAlert();
        } else {
          reader.onload = (e) => {
            this.tempLogoBanner = e.target.result;
            this.form.selectedFoodCert.name = this.form.food_cert_image.name;
            this.showfoodCert();
            event.target.value = "";
          };
        }
      }
    },

    onSeqCertChange(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        this.form.seq_cert_image = input.files[0];
        if (
          this.form.seq_cert_image.type != "image/png" &&
          this.form.seq_cert_image.type != "image/jpeg" &&
          this.form.seq_cert_image.type != "image/jpg"
        ) {
          this.form.selectedseqCert.image = this.webUrl + "../assets/images/upload-icon.svg";
          this.error_message = "Please select only .png,.jpg or .jpeg image.";
          this.form.selectedseqCert.image = "";
          this.showAlert();
        } else if (this.form.seq_cert_image.size > 5242880) {
          this.form.selectedseqCert.image = this.webUrl + "../assets/images/upload-icon.svg";
          this.error_message = "Banner image should be less than 5 MB.";
          this.form.selectedseqCert.image = "";
          this.showAlert();
        } else {
          reader.onload = (e) => {
            this.tempLogoBanner = e.target.result;
            this.form.selectedseqCert.name = this.form.seq_cert_image.name;

            this.showseqCert();
            event.target.value = "";
          };
        }
      }
    },
    onprofileChange(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        this.form.profile_image = input.files[0];
        if (
          this.form.profile_image.type != "image/png" &&
          this.form.profile_image.type != "image/jpeg" &&
          this.form.profile_image.type != "image/jpg"
        ) {
          this.form.selectedImage.image = this.webUrl + "../assets/images/upload-icon.svg";
          this.error_message = "Please select only .png,.jpg or .jpeg image.";
          this.form.selectedImage.image = "";
          this.form.selectedImage.name = "";
          event.target.value = "";
          this.showAlert();
        } else if (this.form.profile_image.size > 1048576 * 2) {
          this.form.selectedImage.image = this.webUrl + "../assets/images/upload-icon.svg";
          this.error_message = "Profile image should be less than 2 MB.";
          this.form.selectedImage.image = "";
          this.form.selectedImage.name = "";
          event.target.value = "";
          this.showAlert();
        } else {
          reader.onload = (e) => {
            this.tempLogo = e.target.result;
            this.form.selectedImage.name = this.form.profile_image.name;
            this.showImg();
            event.target.value = "";
          };
        }
      }
    },

    mobileVerify() {
      if (!$("#verification").val()) {
        $("#verification").addClass("invalid");
        $("#verification-warning-message").show();
        $("#verification-warning-message").html("Please Enter Verification code!");
      }

      $("#verification").focusin(function () {
        $("#verification-warning-message").hide();
        $("#verification").removeClass("invalid");
      });
      $("#verification").focusout(function () {
        if ($("#verification").val() === "") {
          $("#verification").addClass("invalid");
          $("#verification-warning-message").show();
        } else {
          $("#verification").removeClass("invalid");
          $("#verification-warning-message").hide();
        }
      });
      return this.$store
        .dispatch(POST_API, {
          data: {
            verification_code: this.form.var_code,
            user_id: this.form.user_id,
          },
          api: "/api/verifyMobile",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            // return [];
          } else {
            this.form.mobile_verified_check = true;
            this.hideVerifyMobileModel();

            if (this.form.paynow == "") {
              this.form.paynow = this.$store.getters.getResults.data.mobile_number;
            }
            this.$swal({
              position: "center",
              icon: "success",
              iconColor: "#4c8bf5",
              title: "Mobile Number verified successfully.",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              this.form.mobile_verify == "yes";

              $("#number").attr("disabled", "disabled");

              // window.location.reload();
            });
          }
        });
    },

    payout() {
      if (this.form.payout == "bank") {
        $("#mob").removeClass("error");
        $("#paynow").hide();
        $("#mob").parent().parent().find(".error_x_white").removeClass("show");

        $("#accNo").addClass("required-field");
        $("#accHold").addClass("required-field");
        $("#pay").css("display", "none");

        $("#b_name").css("display", "block");
        $("#b_number").css("display", "block");
        $("#b_holder").css("display", "block");
      } else {
        $("#mob").addClass("required-field");

        $("#accNo").removeClass("error");
        $("#accNo").parent().parent().find(".error_x_white").removeClass("show");

        $("#accHold").removeClass("error");
        $("#accHold").parent().parent().find(".error_x_white").removeClass("show");

        $("#pay").css("display", "block");
        $("#b_name").css("display", "none");
        $("#b_number").css("display", "none");
        $("#b_holder").css("display", "none");
      }
    },
    activeTab() {
      this.personal = true;
      this.links = false;
    },
    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    sendOTP() {
      if (this.showVerifyOtp) {
        clearTimeout(this.timeOutCounter);
        this.$store
          .dispatch(POST_API, {
            data: {
              mobile: this.form.mobile_number,
              user_id: this.form.user_id,
              user_type_id: 5,
            },
            api: "/api/jobseekerSendOTP",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;

              this.$swal({
                position: "center",
                icon: "error",
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              this.form.verify_otp_button = true;
              if (this.form.paynow == "") {
                this.form.paynow = this.$store.getters.getResults.data.mobile_number;
              }
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "OTP sent successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                this.showVerifyMobileModel();
              });
            }
          });
      } else {
        this.$swal({
          position: "center",
          icon: "error",
          title: "Please try again in " + this.countDown + " seconds.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },

    showVerifyMobileModel() {
      (this.countDown = 60), (this.form.var_code = "");
      this.countDownTimer();
      this.$refs["verify-mobile-modal"].show();
    },
    mobileVerifyCancel() {
      this.$swal({
        title: "Please Confirm",
        text: "Are you sure you want to close this popup?",
        // text: "You can resend OTP in " +this.countDown+ " seconds.Are you sure you want to close this popup?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((value) => {
        if (value.isConfirmed) {
          //clearTimeout(this.timeOutCounter);
          this.$refs["verify-mobile-modal"].hide();
        }
      });
    },
    hideVerifyMobileModel() {
      clearTimeout(this.timeOutCounter);
      this.$refs["verify-mobile-modal"].hide();
    },
    customFormatterTwo(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    customFormatterDob(date) {
      return moment(date).format("DD MMM YYYY");
    },

    maxdate() {
      return moment().subtract(16, "years");
    },

    customFormattermonth(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    getCountryCode() {
      return this.$store
        .dispatch(POST_API, {
          api: "/api/get-nationality-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.countries = this.$store.getters.getResults.data;

            // this.showSuccessPopup();
          }
        });
    },

    getrace() {
      return this.$store
        .dispatch(GET_API, {
          api: "/api/race",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.race = this.$store.getters.getResults.data;

            // this.showSuccessPopup();
          }
        });
    },
    getMedicalHistory() {
      return this.$store
        .dispatch(GET_API, {
          api: "/api/medicalHistory",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.medical_history = this.$store.getters.getResults.data;
            // this.medical_history.some(function (o) {
            //   this.ispregnant = o["title"] == "Pregnant";
            // });
            // this.showSuccessPopup();
          }
        });
    },

    getMedicalHistoryByName() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            medical_name: this.form.medical_reason,
          },
          api: "/api/medicalHistoryByName",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.form.medical_reason = this.$store.getters.getResults.data;

            this.medicalHistoryChange();

            console.log(this.form.medical_reason);

            // this.showSuccessPopup();
          }
        });
    },

    getbanks() {
      return this.$store
        .dispatch(GET_API, {
          api: "/api/banks",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.banks = this.$store.getters.getResults.data;

            // this.showSuccessPopup();
          }
        });
    },

    getrelation() {
      return this.$store
        .dispatch(GET_API, {
          api: "/api/relations",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.relations = this.$store.getters.getResults.data;

            // this.showSuccessPopup();
          }
        });
    },
    getIsProductionSetting() {
      return this.$store
        .dispatch(GET_API, {
          api: "/api/get-is-production-nric",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.is_production_val = this.$store.getters.getResults.data;
          }
        });
    },
    getCertificate() {
      return this.$store
        .dispatch(GET_API, {
          api: "/api/certificate",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.cerificate = this.$store.getters.getResults.data;

            if (this.cerificate[1]) {
              this.getSecurityType();
            }

            if (this.cerificate[0]) {
              this.getFoodType();
            }

            // this.showSuccessPopup();
          }
        });
    },

    // getDropdownParamsData(type,field,id,row)
    // {

    //     return this.$store.dispatch(POST_API, {
    //             data:{
    //                 field:field,
    //                 id:id,
    //                 table:type

    //             },
    //             api:'/api/get-params-dropdown-data',
    //         })
    //         .then(() => {
    //             if (this.$store.getters.containsErrors) {
    //                 this.error_message = this.$store.getters.getErrors;
    //                 this.showAlert();
    //             } else {
    //                 $('.fos-'+row).val();

    //                 this.fieldOfStudyArr[row] = '';
    //                 this.fieldOfStudyArr[row] = this.$store.getters.getResults.data;
    //                 return this.fieldOfStudyArr[row];
    //             }
    //         });
    // },
    onInput(value) {
      this.inputArr = value.map((a) => a.id);
    },

    retriveData() {
      return this.$store
        .dispatch(GET_API, {
          api: "/api/getsingpassdata",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.relations = this.$store.getters.getResults.data;

            // this.showSuccessPopup();
          }
        });
      ///this.callAuthoriseApi();
    },

    callAuthoriseApi() {
      var authApiUrl = "https://sandbox.api.myinfo.gov.sg/com/v3/authorise"; // URL for authorise API
      var clientId = "STG-202006353G-ELCONNECT-JOBSEEKERREG"; // your app_id/client_id provided to you during onboarding
      var redirectUrl = "http://localhost:3001/callback"; //callback url for your application

      var attributes = "uinfin,name,sex,race,dob,nationality,mobileno,email,regadd"; // the attributes you are retrieving for your application to fill the form
      var authLevel = "L0"; // the auth level, determines the flow
      // the purpose of your data retrieval
      var purpose = "demonstrating MyInfo APIs";

      // randomly generated state
      var state = "123";
      var authoriseUrl =
        authApiUrl +
        "?client_id=" +
        clientId +
        "&attributes=" +
        attributes +
        "&purpose=" +
        purpose +
        "&state=" +
        encodeURIComponent(state) +
        "&redirect_uri=" +
        redirectUrl;

      window.location = authoriseUrl;
    },
    updatePersonalInfo() {
      console.log(this.year, this.month, this.day, "ddd");
      var err = false;

      if (
        this.form.emer_name != "" ||
        this.form.emer_name_last != "" ||
        this.form.emer_phone != "" ||
        this.form.emer_relation != ""
      ) {
        // console.log(this.form.emer_name,this.form.emer_name_last,this.form.emer_phone,this.form.emer_relation,'hello' );
        if (
          this.form.emer_name.trim().length === 0 ||
          this.form.emer_name_last.trim().length === 0 ||
          (this.form.emer_phone.trim().length === 0) == "" ||
          this.form.emer_relation == ""
        ) {
          console.log(
            this.form.emer_name,
            this.form.emer_name_last,
            this.form.emer_phone,
            this.form.emer_relation,
            "hello"
          );
          if (this.form.emer_name == "" || this.form.emer_name.trim().length === 0) {
            $("#emer_name").addClass("required-field");
            $(".single_jobseeker_lg .contact_header").addClass("red_border_header");
          }
          if (this.form.emer_name_last == "" || this.form.emer_name_last.trim().length === 0) {
            $("#emer_last_name").addClass("required-field");
            $(".single_jobseeker_lg .contact_header").addClass("red_border_header");
          }

          if (this.form.emer_phone == "" || this.form.emer_phone.trim().length === 0) {
            $("#emer_phone").addClass("required-field");
            $(".single_jobseeker_lg .contact_header").addClass("red_border_header");
          }

          if (this.form.emer_relation == "") {
            $("#emer_relation").addClass("required-field");
            $(".single_jobseeker_lg .contact_header").addClass("red_border_header");
          }

          err = true;
          document
            .querySelector(".second_indiv_step_wrap .red_border_header")
            .scrollIntoView({ behavior: "smooth", block: "center" });
        } else {
          $("#emer_relation").removeClass("required-field");
          $("#emer_phone").removeClass("required-field");
          $("#emer_name").removeClass("required-field");
          $("#emer_last_name").removeClass("required-field");
          $("div#app .single_jobseeker_lg .contact_header").removeClass("red_border_header");
        }
      } else {
        $("#emer_relation").removeClass("required-field");
        $("#emer_phone").removeClass("required-field");
        $("#emer_name").removeClass("required-field");
        $("#emer_last_name").removeClass("required-field");
        $("div#app .single_jobseeker_lg .contact_header").removeClass("red_border_header");
      }

      $(".required-field").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        });
      }, 100);
      if (this.form.payout == "bank") {
        $("#mob").removeClass("error");
        $("#mob").removeClass("required-field");

        $("#paynow").hide();
        $("#mob").parent().parent().find(".error_x_white").removeClass("show");

        $("#accNo").addClass("required-field");
        $("#accHold").addClass("required-field");
        // $('#pay').css('display','none');

        $("#b_name").css("display", "block");
        $("#b_number").css("display", "block");
        $("#b_holder").css("display", "block");
      } else {
        $("#mob").addClass("required-field");

        $("#accNo").removeClass("required-field");
        $("#accHold").removeClass("required-field");

        $("#accNo").removeClass("error");
        $("#accNo").parent().parent().find(".error_x_white").removeClass("show");
        $("#accHold").removeClass("error");
        $("#accHold").parent().parent().find(".error_x_white").removeClass("show");
        $("#pay").css("display", "block");
        // $('#b_name').css('display','none');
        // $('#b_number').css('display','none');
        // $('#b_holder').css('display','none');
      }
      $(".scheduled_post").each(function () {
        if (!$("#scheduled_post").val()) {
          err = true;
          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });

      $("#genderval").each(function () {
        if (!$("#genderval").val()) {
          err = true;

          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });

      $("#nationality").each(function () {
        if (!$("#nationality").val()) {
          err = true;

          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });
      $("#race").each(function () {
        if (!$("#race").val()) {
          err = true;

          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });
      $("#dob").each(function () {
        if (!$("#dob").val()) {
          err = true;

          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });

      if (
        (err == false && this.form.nric_front.image != "" && this.form.nric_back.image == "") ||
        (this.form.nric_back.image != "" && this.form.nric_front.image == "")
      ) {
        err = true;
        this.$swal({
          position: "center",
          icon: "error",
          title: "Please upload nric image.",
          showConfirmButton: false,
          timer: 1500,
        });
      }

      if (err == false && (this.year == "" || this.month == "" || this.day == "")) {
        // if(err == false && this.year != ''){
        if (this.month == "" || this.day == "") {
          err = true;
          this.$swal({
            position: "center",
            icon: "error",
            title: "Please enter Date of Birth.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }

      if (err == false && !this.form.mobile_number.match("^[0-9]*$")) {
        err = true;

        this.$swal({
          position: "center",
          icon: "error",
          title: "Only number allow for mobile number",
          showConfirmButton: false,
          timer: 1500,
        });
      }

      // if(!this.form.medical || !this.form.crime){
      if (err == false && (!this.form.medical || !this.form.crime)) {
        err = true;
        this.$swal({
          position: "center",
          icon: "error",
          title: "Please fill personal history.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      var cur = moment().diff(this.form.dob, "years");
      if (err == false && this.form.nric && this.is_production_val == "yes") {
        this.form.nric = this.form.nric.toUpperCase();
        var icArray = new Array(9);
        for (var i = 0; i < 9; i++) {
          icArray[i] = this.form.nric.charAt(i);
        }
        icArray[1] *= 2;
        icArray[2] *= 7;
        icArray[3] *= 6;
        icArray[4] *= 5;
        icArray[5] *= 4;
        icArray[6] *= 3;
        icArray[7] *= 2;
        var weight = 0;
        for (var i = 1; i < 8; i++) {
          weight += parseInt(icArray[i]);
        }
        var offset = icArray[0] == "T" || icArray[0] == "G" ? 4 : 0;
        var temp = (offset + weight) % 11;
        var st = Array("J", "Z", "I", "H", "G", "F", "E", "D", "C", "B", "A");
        var fg = Array("X", "W", "U", "T", "R", "Q", "P", "N", "M", "L", "K");
        var theAlpha;
        if (icArray[0] == "S" || icArray[0] == "T") {
          theAlpha = st[temp];
        } else if (icArray[0] == "F" || icArray[0] == "G") {
          theAlpha = fg[temp];
        }
        var nricCheck = icArray[8] == theAlpha;
        if (!nricCheck || this.form.nric.length > 9) {
          err = true;

          this.$swal({
            position: "center",
            icon: "error",
            title: "Invalid NRIC.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }

      if (err == false && this.form.nric && this.is_production_val == "no") {
        if (!this.form.nric.match(/^[S-Ts-t]+\d{7}[A-Za-z]+$/)) {
          err = true;
          this.$swal({
            position: "center",
            icon: "error",
            title: "Invalid NRIC.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
      if (err == false && this.form.medical == "yes") {
        const medicalReason = this.form.medical_reason;
        // if (!medicalReason || medicalReason.trim().length === 0) {
        //   // if(this.form.medical_reason == '' || this.form.medical_reason == null){
        //   err = true;
        //   this.$swal({
        //     position: "center",
        //     icon: "error",
        //     title: "Please enter medical reason.",
        //     showConfirmButton: false,
        //     timer: 1500,
        //   });
        // }
        if (!medicalReason || medicalReason.length === 0) {
          // if(this.form.medical_reason == '' || this.form.medical_reason == null){
          err = true;
          this.$swal({
            position: "center",
            icon: "error",
            title: "Please enter medical reason.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }

      if (err == false && this.form.crime == "yes") {
        // if(this.form.crime_reason == '' || this.form.crime_reason == null){
        const crimeReason = this.form.crime_reason;
        if (!crimeReason || crimeReason.trim().length === 0) {
          err = true;

          this.$swal({
            position: "center",
            icon: "error",
            title: "Please enter criminal reason.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }

      // if($('#checkbox:checked').val() != "true"){
      //     err = true;
      //     this.$swal({
      //         position: 'center',
      //         icon: 'error',
      //         title: 'Please accept terms and condition first.',
      //         showConfirmButton: false,
      //         timer: 1500
      //     })
      // }

      if (!this.form.profile_image) {
        err = true;
        this.$swal({
          position: "center",
          icon: "error",
          title: "Profile image is required.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      //     else if(!this.form.nric_front.image){

      //     err = true;
      //      this.$swal({
      //         position: 'center',
      //         icon: 'error',
      //         title: 'NRIC front image is required.',
      //         showConfirmButton: false,
      //         timer: 1500
      //     })
      // }
      // else if(!this.form.nric_back.image){

      //     err = true;
      //      this.$swal({
      //         position: 'center',
      //         icon: 'error',
      //         title: 'NRIC back image is required.',
      //         showConfirmButton: false,
      //         timer: 1500
      //     })
      // }

      this.form.dob = this.year + this.month + this.day;
      console.log(this.form.dob);
      var cur = moment().diff(this.form.dob, "years");

      if (err == false && this.form.dob && cur < 16) {
        err = true;
        this.$swal({
          position: "center",
          icon: "error",
          title: "The age should be minimum of 16 year.",
          showConfirmButton: false,
          timer: 1500,
        });
      }

      if (err == false) {
        this.form.skills = this.form.skills != [] ? this.form.skills : null;
        this.form.dob = this.form.dob == "Invalid date" ? null : this.form.dob;

        this.$store
          .dispatch(POST_API, {
            data: {
              items: this.form,
              image: this.form.selectedImage.image,
              categories: this.categories,
            },
            api: "/api/update-daily-job-jobseeker",
          })
          .then(() => {
            var yearERR = "";
            var monthERR = ""; // see the change here
            // see the change here

            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
            } else {
              this.success_message = this.$store.getters.getResults.message;
              Bus.$emit("active_users");
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: this.success_message,
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                // if(this.$store.getters.currentUser.profile_image != this.form.profile_image){
                // this.$store.getters.currentUser.profile_image = this.form.selectedImage;
                window.location.reload();
                // this.getUserData();
                // this.getrace();
                // this.getbanks();
                // this.getrelation();
                // this.getCountryCode();
                // this.getCertificate();

                // }

                // window.scrollTo(0,0);
              });
            }
          });
      }
    },

    surveyFormUpdateFLEmp() {
      this.$store
        .dispatch(POST_API, {
          data: {
            heard_from: this.heard_from,
            heard_from_comment: this.heard_from_comment,
            user_id: this.form.user_id,
          },
          api: "/api/surveyFormUpdateFLEmp",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.$refs["survey-modal"].hide();
            this.$swal({
              position: "center",
              icon: "success",
              iconColor: "#4c8bf5",
              title: "Thank you for your time",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              console.log("survey completed");
            });
          }
        });
    },
    getUserData() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: this.form.user_id,
          },
          api: "/api/user-data",
        })
        .then(async () => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            if (this.$store.getters.getResults.data) {
              this.form.selectedImage.image =
                this.$store.getters.getResults.data.profile_image != null
                  ? this.$store.getters.getResults.data.profile_image
                  : this.webUrl + "../assets/images/user-avatar-small-01.png";
              // this.form.selectedBannerImage.image = this.$store.getters.getResults.data.banner_image;
              this.rejection_comments =
                this.$store.getters.getResults.rejection_comments.length > 0
                  ? this.$store.getters.getResults.rejection_comments[0].comment
                  : "";
              this.form.selectedBannerImage.image =
                this.$store.getters.getResults.data.banner_image;
              this.form.first_name = this.$store.getters.getResults.data.first_name;
              this.form.full_name = this.$store.getters.getResults.data.full_name;

              this.form.mobile_number = this.$store.getters.getResults.data.mobile_number;
              this.form.country_code = this.$store.getters.getResults.data.country_code;

              this.form.verify_otp_button = this.$store.getters.getResults.data.mobile_number
                ? (this.form.verify_otp_button = true)
                : (this.form.verify_otp_button = false);
              this.user_type = this.$store.getters.getResults.data.user_type_id;
              this.form.expected_salary = this.$store.getters.getResults.data.expected_salary;
              this.form.about = this.$store.getters.getResults.data.about;
              this.form.nric = this.$store.getters.getResults.data.nric;
              this.form.raceid = this.$store.getters.getResults.data.race_id;
              this.form.dob = this.$store.getters.getResults.data.dob
                ? this.$store.getters.getResults.data.dob
                : "";
              this.d_date = this.form.dob.split("-");
              if (this.form.dob == "") {
                // this.year = '';
                // this.month = '';
                // this.day = '';
                // console.log('if block')
              } else {
                console.log("else block");
                this.year = this.d_date[0];
                this.month = this.d_date[1];
                await this.generateDateDropdown("monthyear");
                this.day = this.d_date[2];
              }
              this.form.obtainment_date =
                this.$store.getters.getResults.data.hygiene_cert_obtainment_date;
              this.form.thumbnail = this.$store.getters.getResults.data.thumbnail;

              this.form.gender =
                this.$store.getters.getResults.data.gender == null
                  ? ""
                  : this.$store.getters.getResults.data.gender;

              this.form.nationality =
                this.$store.getters.getResults.data.nationality == null
                  ? "5"
                  : this.$store.getters.getResults.data.nationality;
              this.form.food_type = this.$store.getters.getResults.data.hygiene_cert_type_id;
              this.category = this.$store.getters.getResults.dp_category;

              for (var key in this.$store.getters.getResults.dp_preferences) {
                this.categories.push(
                  this.$store.getters.getResults.dp_preferences[key].service_categories_id
                );
              }

              var j = 0;
              var k = 0;
              for (var i = 0; i < this.$store.getters.getResults.certificate.length; i++) {
                this.images[j] = this.$store.getters.getResults.certificate[i].url;
                this.type[j] = this.$store.getters.getResults.certificate[i].title;
                j++;
              }

              this.form.email = this.$store.getters.getResults.data.email;
              this.form.last_name = this.$store.getters.getResults.data.last_name;
              this.form.address = this.$store.getters.getResults.data.address;
              this.form.crime =
                this.$store.getters.getResults.data.criminal_record != "not_answered"
                  ? this.$store.getters.getResults.data.criminal_record
                  : "no";
              this.form.crime_reason = this.$store.getters.getResults.data.criminal_record_desc;
              this.form.payout = this.$store.getters.getResults.data.payout_type;
              this.form.singpass_signup = this.$store.getters.getResults.data.singpass_signup;

              this.form.medical =
                this.$store.getters.getResults.data.medical_condition != "not_answered"
                  ? this.$store.getters.getResults.data.medical_condition
                  : "no";
              this.form.medical_reason = this.$store.getters.getResults.data.medical_condition_desc;
              this.form.other_medical_history =
                this.$store.getters.getResults.data.other_medical_desc;

              this.form.skills =
                this.$store.getters.getResults.data.skills != null
                  ? this.$store.getters.getResults.data.skills.split(",")
                  : null;
              // this.form.skills = ["Vue", "Angular", "React"];
              this.form.email_verify = this.$store.getters.getResults.data.email_verified;
              this.showCV =
                this.$store.getters.getResults.data.cv != "" &&
                this.$store.getters.getResults.data.cv != null
                  ? true
                  : false;
              this.cvUrl =
                this.$store.getters.getResults.data.cv != "" &&
                this.$store.getters.getResults.data.cv != null
                  ? this.$store.getters.getResults.data.cv
                  : "";
              this.form.resume = this.cvUrl;
              this.form.profile_image =
                this.$store.getters.getResults.data.profile_image != null
                  ? this.$store.getters.getResults.data.profile_image
                  : "";
              // this.form.banner_image_back = this.$store.getters.getResults.data.banner_image_back != null ? this.$store.getters.getResults.data.banner_image_back : '';
              // this.form.banner_image_front = this.$store.getters.getResults.data.banner_image_front != null ? this.$store.getters.getResults.data.banner_image_front : '';
              this.form.mobile_verify = this.$store.getters.getResults.data.mobile_verified;
              this.form.cerificate = this.$store.getters.getResults.certificate;

              this.workExperienceData = this.$store.getters.getResults.experience;
              this.qualificationData = this.$store.getters.getResults.qualification;
              this.socialData = this.$store.getters.getResults.social;
              this.form.mobile_verified_check =
                this.$store.getters.getResults.data.mobile_verified == "yes"
                  ? (this.form.mobile_verified_check = true)
                  : (this.form.mobile_verified_check = false);
              this.status = this.$store.getters.getResults.data.account_verification_status;
              this.account_verification_status =
                this.$store.getters.getResults.data.account_verification_status
                  .charAt(0)
                  .toUpperCase() +
                this.$store.getters.getResults.data.account_verification_status.slice(1);

              this.form.paynow = this.$store.getters.getResults.payout[0]
                ? this.$store.getters.getResults.payout[0].mobile_number
                : "";
              this.heard_from = this.$store.getters.getResults.data.heard_from;
              if (this.heard_from == "" || this.heard_from == null) {
                this.$refs["survey-modal"].show();
              }

              if (this.form.paynow == "") {
                this.form.paynow = this.$store.getters.getResults.data.mobile_number;
              }

              if (this.form.mobile_verified_check != true) {
                this.validErr.push("Verify Mobile Number");
              }
              if (this.form.email_verify != "yes") {
                this.validErr.push("Verify Email Address");
              }

              if (this.form.singpass_signup == "false") {
                if (
                  this.form.selectedImage.image == "" ||
                  this.form.nric_front.image == "" ||
                  this.form.nric_back.image == "" ||
                  this.form.nric == "" ||
                  this.form.nric == null ||
                  (this.form.paynow == "" &&
                    (this.form.bank == "" ||
                      this.form.acc_number == "" ||
                      this.form.holder_name == "")) ||
                  this.form.dob == "" ||
                  this.form.dob == "Invalid date" ||
                  this.form.dob == null ||
                  this.form.gender == null ||
                  this.form.nationality == null ||
                  this.form.raceid == null
                ) {
                  this.validErr.push("Complete Personal Profile section");
                }
              } else {
                if (
                  this.form.selectedImage.image == "" ||
                  this.form.nric == "" ||
                  this.form.nric == null ||
                  (this.form.paynow == "" &&
                    (this.form.bank == "" ||
                      this.form.acc_number == "" ||
                      this.form.holder_name == "")) ||
                  this.form.dob == "" ||
                  this.form.dob == "Invalid date" ||
                  this.form.dob == null ||
                  this.form.gender == null ||
                  this.form.nationality == null ||
                  this.form.raceid == null
                ) {
                  this.validErr.push("Complete Personal Profile section");
                }
              }

              // if(this.account_verification_status == 'Pending' || this.account_verification_status == 'Completed'){
              //     $('#checkbox').prop('checked', true);

              //     $(".tacbox").hide();

              // }
              if (this.$store.getters.getResults.bank[0]) {
                this.form.bank = this.$store.getters.getResults.bank[0].bank_id;
                this.form.acc_number = this.$store.getters.getResults.bank[0].account_number;
                this.form.holder_name = this.$store.getters.getResults.bank[0].account_holder_name;
              }
              if (this.$store.getters.getResults.emer[0]) {
                this.form.emer_name = this.$store.getters.getResults.emer[0].first_name;
                this.form.emer_name_last = this.$store.getters.getResults.emer[0].last_name;

                this.form.emer_phone = this.$store.getters.getResults.emer[0].mobile_number;
                this.form.emer_relation = this.$store.getters.getResults.emer[0].relation_id
                  ? this.$store.getters.getResults.emer[0].relation_id
                  : "";
              }

              if (this.$store.getters.getResults.document[0]) {
                this.form.banner_image_front = this.$store.getters.getResults.document[0].document;
                this.form.nric_front.image = this.$store.getters.getResults.document[0].document;
              }

              if (this.$store.getters.getResults.food_cert_data[0]) {
                this.form.food_cert_image = this.$store.getters.getResults.food_cert_data[0].url;
                this.form.selectedFoodCert.image =
                  this.$store.getters.getResults.food_cert_data[0].url;

                this.form.food_type = this.$store.getters.getResults.food_cert_data[0].cert_type;

                this.form.food_obtainment_date =
                  this.$store.getters.getResults.food_cert_data[0].obtainment_date;
              }

              if (this.$store.getters.getResults.seq_cert_data[0]) {
                this.form.seq_cert_image = this.$store.getters.getResults.seq_cert_data[0].url;
                this.form.selectedseqCert.image =
                  this.$store.getters.getResults.seq_cert_data[0].url;

                this.form.seq_type = this.$store.getters.getResults.seq_cert_data[0].cert_type;
                this.form.seq_obtainment_date =
                  this.$store.getters.getResults.seq_cert_data[0].obtainment_date;
              }

              this.age = moment().diff(this.form.dob, "years");

              this.getMedicalHistoryByName();

              if (this.$store.getters.getResults.document[1]) {
                this.form.banner_image_back = this.$store.getters.getResults.document[1].document;
                this.form.nric_back.image = this.$store.getters.getResults.document[1].document;
              }

              $("#status").attr("disabled", "disabled");

              if (this.form.email_verify == "yes") {
                $("#email").attr("disabled", "disabled");
              } else {
                $("#email").removeAttr("disabled");
              }

              if (this.form.mobile_verify == "yes") {
                $("#number").attr("disabled", "disabled");
              } else {
                $("#number").removeAttr("disabled");
              }

              if (this.form.crime == "yes") {
                $(".other_category_class").css("display", "block");
              } else {
                $(".other_category_class").css("display", "none");
              }

              if (this.form.full_time == "yes") {
                $(".remark_class").css("display", "block");
              } else {
                $(".remark_class").css("display", "none");
              }

              if (this.form.medical == "yes") {
                $(".other_medical_class").css("display", "block");
              } else {
                $(".other_medical_class").css("display", "none");
              }

              if (!this.form.paynow) {
                this.form.payout = "paynow";
              }
              this.payout();

              if (this.form.payout == "paynow") {
                $("#mob").addClass("required-field");
              } else {
                $("#accNo").addClass("required-field");
                $("#accHold").addClass("required-field");
              }
            } else {
            }
          }
        });
    },

    emailVerify() {
      if (this.showVerifyEmail) {
        clearTimeout(this.timeOutCounterEmail);
        // this.countDownEmail = 30;
        return this.$store
          .dispatch(POST_API, {
            data: {
              email: this.form.email,
              user_id: this.form.user_id,
            },
            api: "/api/sendEmailVerify",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
              return [];
            } else {
              (this.countDownEmail = 30), this.countDownTimerEmail();
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "Verification mail sent successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                // window.location.reload();
              });
            }
          });
      } else {
        this.$swal({
          position: "center",
          icon: "error",
          title: "Please try again in " + this.countDownEmail + " seconds.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    getFoodType() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: this.cerificate[0].id,
          },
          api: "/api/certificatetype",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.foodcerificateType = this.$store.getters.getResults.data;

            // this.showSuccessPopup();
          }
        });
    },

    getSecurityType() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: this.cerificate[1].id,
          },
          api: "/api/certificatetype",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.seqcerificateType = this.$store.getters.getResults.data;

            // this.showSuccessPopup();
          }
        });
    },
  },
  mounted() {
    $(".show_custom_accordian").show();
    this.fullYear = new Date();
    this.fullYear.setFullYear(this.fullYear.getFullYear() - 16);
    this.fullYear = this.fullYear.getFullYear();
    // Setting current date and time
    // this.year = moment().format('YYYY');
    // this.month = moment().format('MM');
    // this.generateDateDropdown();
    // this.day = moment().format('DD');

    let params = new URLSearchParams(location.search);
    var data = params.get("data");

    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );

    this.form.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    Bus.$emit("active_users");

    localStorage.removeItem("singpassData");

    console.log("aya");

    this.getUserData();
    this.getrace();
    this.getbanks();
    this.getrelation();
    this.getMedicalHistory();

    this.getCountryCode();
    this.getCertificate();
    this.getIsProductionSetting();
  },
};
</script>
<style>
.single-image-upload .form-control-file,
.single-image-upload .form-control-range {
  position: absolute;
  height: 100%;
  opacity: 0;
}
.single-image-upload .form-control-file,
.custom-file-input {
  height: 200px;
}
</style>
