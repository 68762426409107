<template>
    <div>

         <!-- <div class="container full_container_dsk new_full_container_dsk">
            <div class="row mobile_email_row custom_dsk_row"> -->
        <div class="row">
            <div class="container my_new_container">
                <!-- Dashboard Headline -->
                <!-- <div class="dashboard-headline margin_bottom_40 mobile_margin_bottom_20"> -->
                <div class="dashboard-headline">
                    <h1>Invoice</h1>
                    <!-- <b-link class="btn green-border-btn ml-2 text-white" @click="exportPdf">Export PDF</b-link>
                    <b-link :to="{}" class="btn green-border-btn ml-2 text-white" @click="exportData">Export CSV</b-link> -->

                    <!-- Breadcrumbs -->
                    <nav id="breadcrumbs" class="dark desktop_margin_top_zero">
                        <ul>
                            <li><a href="#">Home</a></li>
                            <li>Invoice</li>
                        </ul>
                    </nav>
                </div>
                <!-- <div class="col-xl-12"> -->
                    <div class="row"> 
                    <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job submit-field mobile_margin_bottom_18 ">
                    <div class="search-box custom_search_admin desktop_margin_top_zero">
                        <!-- <b-input-group> -->
                        <b-input-group-prepend>
                            <b-form-input id="searchBtn" @keyup.enter.native="filter = searchKeyword" v-model="searchKeyword" placeholder="Search by keyword"></b-form-input>
                            <b-button class="btn points_events"><i class="fa fa-search" @click="filter = searchKeyword"></i></b-button>
                        </b-input-group-prepend>
                        <!-- </b-input-group> -->
                        </div>
                    </div> 
                    <!-- <div class="col-xl-5 col-md-5">
                    </div> -->
                    <div class="double_btn col-xl-8 col-md-8 submit-field service_report_dual_btn mobile_tab_max_width_flex new_text_align_end">
                        <b-link class="btn green-border-btn ml-2 text-white desktop_margin_top_zero desktop_margin_left_zero" :class="this.totalRows == 0 ? 'disabled' : ''" @click="exportPdf" v-if="showPermissionEM('export pdf')">Export PDF</b-link>
                        <b-link :to="{}" class="btn green-border-btn ml-2 text-white desktop_margin_top_zero desktop_margin_right_zero" :class="this.totalRows == 0 ? 'disabled' : ''" @click="exportData" v-if="showPermissionEM('export csv')">Export CSV</b-link>
                    </div>
                    </div>
                <!-- </div>                    -->
            </div>
        </div>
        <!-- Page Content
        ================================================== -->
        <!-- <div class="container full_container_dsk margin_top_25_mobile new_full_container_dsk">
           <div class="row"> -->
        <div class="row">
            <div class="container my_new_container show_filter_arrow">
               <b-table ref="datatable" show-empty striped hover responsive :items="getTransaction" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="memberhip_six_grid_table mobile_min_width_1000">
                <template #cell(invoice_id)="items">
                    {{ items.item.dep_invoice_no ? items.item.dep_invoice_no : items.item.invoice_number | capitalize }}
                </template>
                <template  #cell(department_name)="items">
                        {{items.item.department_name != null ? items.item.department_name : '' }}
                    </template>
                    <template  #cell(inv_month)="items">
                        {{items.item.start_date != null ? items.item.start_date : '' | monthYear}}
                    </template>
                    <template #cell(amount)="items">
                        ${{items.item.total_with_gst ? items.item.total_with_gst:items.item.invoice_amount}}
                    </template>
                    <template #cell(status)="items">
                        {{items.item.status | capitalize | removeUnderscore}}
                    </template>
                    <template #cell(due_date)="items">
                        {{items.item.due_date | customFormatterOne}}
                    </template>
                     <template #cell(invoice_date)="items">
                        {{items.item.invoice_date | customFormatterOne}}
                    </template>
                    <template #cell(toggleAction)="items">
                        <div class="toggle-action toggle_margin_unset">
                            <b-dropdown class="mx-1" right text="Action" boundary="window">
                                <b-dropdown-item @click="downloadPdf(items.item.dep_invoice_no ? items.item.dep_url:items.item.url)" v-if="showPermissionEM('view invoice')">View Invoice</b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </template>
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                    <div class="page-row-box d-inline-block">
                        <b-form-group id="input-group-4">
                             <span class="d-inline-block">Rows per page</span>
                            <span class="d-inline-block"><b-form-select v-model="form.rowsPerPage" @change='getTransaction'>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>75</option>
                                        <option>100</option>
                            </b-form-select></span>
                        </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block">
                        <ul>
                            <li>
                                <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                            </li>
                        </ul>
                    </div>
                    <b-pagination :total-rows="totalRows"   :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                </nav>
			</div>
        </div>
        <!-- Spacer -->
        <!-- <div class="margin-top-70"></div> -->
        <!-- Spacer / End-->
        <!-- Row / End -->
    </div>
</template>

<script>
import { POST_API } from '../../store/actions.type'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker';
import permission from "../../../../server/permission.js";
import Bus from "../../event-bus";

export default {
    components: {
        Datepicker,
    },
    
    data() {
        return {
            fields: [
                { key: 'invoice_id', label: 'Invoice Number', sortable: true },
                { key: 'department_name', label: 'Department Name', sortable: false },
                { key: 'inv_month', label: 'Invoice Month & Year', sortable: true },
                { key: 'amount', label: 'Amount', sortable: true },
                { key: 'status', label: 'Status', sortable: true },
                { key: 'due_date', label: 'Due Date', sortable: true },
                { key: 'invoice_date', label: 'Invoice Created Date', sortable: true },
                { key: 'toggleAction', label: 'Actions', }
            ],
            pager: {},
            pageOfItems: [],
            items:null,
            totalRows: 0,
            from: '',
            to: '',
            searchKeyword:'',
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 75, 100],
            sortBy: null,
            sortDirection: 'desc',
            filter: '',
            status: 'active',
            sortDesc: true,
            webUrl: process.env.VUE_APP_URL,
            base_url: process.env.VUE_APP_URL_V1,

            form:{
                rowsPerPage:25,
                date:"",
            },
            permissionActions:''

        }
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        customFormatterOne(date) {
            return moment(date).utc().format('DD MMM YYYY');
        },
        removeUnderscore: function(value) {
            if (!value) return ''
            var i, frags = value.split('_');
            for (i=0; i<frags.length; i++) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
            }
            return frags.join(' ');
        },
        monthYear: function(value) {

            if (value == null) {
                return '';
            } else {

              value =   moment(value).format('YYYY-MM-DD')
              var spl = value.split('-');


           
              if(spl[2] == '16'){
                return moment(value).format('16-MMM-YYYY')
              }else{
                return moment(value).format('01-MMM-YYYY')
              }
            }
        },
    },
    methods:{
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                // this.$router.push({ name: 'country-list' });
            });
        },
        showAlert() {
            this.$swal({
                position: 'center',
                // icon: 'error',
                imageUrl: '/assets/images/404_elcy.gif',
                customClass:{
                    container: 'mascot_error_container',
                },
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        

        changeServices()
        {
            this.getTransaction().then(() => {
                this.$refs.datatable.refresh();
            });
        },
        exportPdf(){
        var base_url = this.base_url;
        var date = this.form.date ? moment(this.form.date).format('YYYY-MM-DD') : "null";
        
        var filter = this.filter ? this.filter : "null";
        var userID = this.company_admin_id ? this.company_admin_id : this.user_id
        var export_url = '/api/export-invoice-pdf/'+filter+'/'+date+'/'+userID;
        window.open(base_url+export_url,'_blank')
        },

        exportData(){

        var base_url = this.base_url;
        var date = this.form.date ? moment(this.form.date).format('YYYY-MM-DD') : "null";
        var filter = this.filter ? this.filter : "null";
        var userID = this.company_admin_id ? this.company_admin_id : this.user_id

        var export_url = '/api/export-invoice/'+filter+'/'+date+'/'+userID;
        
        window.open(base_url+export_url,'_blank')
        },
        reset(){
          this.form.date = "";
          this.filter = "";
          this.changeServices();
        },
        downloadPdf(url){
          window.open(url);
        },
        getTransaction() {
            return this.$store.dispatch(POST_API, {
                data:{
                    page: this.currentPage,
                    keyword: this.filter,
                    id:this.company_admin_id ? this.company_admin_id : this.user_id,
                    rowsPerPage:this.form.rowsPerPage,
                    sortBy: this.sortBy ? this.sortBy: 'id',
                    sortDirection:this.sortDesc ? 'desc' : 'asc',
                    date: this.form.date ? moment(this.form.date).format('YYYY-MM-DD') : "",

                },
                api: '/api/invoice'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    this.items = this.$store.getters.getResults.pageOfItems;
                    this.totalRows = this.$store.getters.getResults.pager.totalItems;
                    this.perPage = this.$store.getters.getResults.pager.pageSize;
                    this.from = this.$store.getters.getResults.pager.from;
                    this.to = this.$store.getters.getResults.pager.to;
                    return this.items;
                }
            });
        },
        permissionEM(){
            if(this.$store.getters.getCurrentUserPermission){
                 if (this.$store.getters.getCurrentUserPermission.data.length > 0 && this.$store.getters.currentUser.is_company_head == 'no' && (this.$store.getters.currentUser.user_type_id == 3 || this.$store.getters.currentUser.user_type_id == 4)) {
                    var menu = this.$store.getters.getCurrentUserPermission.data;
                    this.permissionActions = permission.getPermission(menu,'Invoice');
                }
            }
        },
        showPermissionEM(action){
            if (this.$store.getters.currentUser.is_company_head == 'no' && (this.$store.getters.currentUser.user_type_id == 3 || this.$store.getters.currentUser.user_type_id == 4)) {
                return this.permissionActions.indexOf(action) >= 0 ? true : false ;  
            }else{
                return true;
            }
        },

       
    },
     mounted(){
            $(".show_filter_arrow .table thead th div").contents().unwrap();
             $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
            }, "fast");
            //document.title = "EL Connect - Employer - Transactions";
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.company_admin_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_admin_id : '');
            this.permissionEM();
            Bus.$emit('active_users');

     }
}
</script>
