<template>
    <div>

        <div id="custom_footer_emp" class="v2_footer">
                <!-- Footer Top Section -->
                <div class="footer-top-section">
                    <div class="container custom_container">
                        <div class="row">
                            <div class="col-xl">
                                <!-- <div class="row"> -->
                            <!-- <div class="custom_top_section_footer sec_1">                            -->
                            <div class="col-md-2 col-sm-2 sec_1 custom_top_section_footer border_footer_right">
                             <div id="custom_logo">
                                <!--<b-link href="" :to="{name: 'homepage'}" ><img :src="webUrl+'assets/images/logo2.png'" :data-sticky-logo="webUrl+'assets/images/logo.png'" :data-transparent-logo="webUrl+'assets/images/logo.png'" alt="footer_logo"></b-link>-->
                                <b-link href="" :to="{name: 'jobseekers2'}" >
                                    <img :src="webUrl+'assets/employerImages/logo.png'">
                                </b-link>
                            </div></div>
                            <!-- <div class="custom_top_section_footer sec_2"> -->
                                <div class="col-md-2 col-sm-2 sec_2 custom_top_section_footer border_footer_right">
                                    <center>
                                <h2>Contact Us:</h2>
                                <h2 class="anchor_h2"><a :href="'tel:'+ contact_us[0].option_value" >{{contact_us[0].option_key == 'telephone_no' ? contact_us[0].option_value : contact_us[1].option_value}}</a></h2>
                                    </center>
                            </div>
                             <div class="col-md-2 col-sm-2 sec_2 custom_top_section_footer border_footer_right width_21_sec_2">
                                <center>
                                    <h2>Our Office:</h2>
                                    <h2 class="anchor_h2">{{get_address[0].option_key == 'address' ? get_address[0].option_value: ''}}</h2>
                                </center>
                            </div>
                            <div class="col-md-3 col-sm-3 sec_3 custom_top_section_footer  width_19_sec_2">
                            <center>
                              <h2> Business Hour:</h2>
                              <h2 class="anchor_h2">Mon - Fri: 8:00AM - 5:00PM</h2>
                              <h2 class="anchor_h2">Public Holiday: Closed</h2>
                            </center>
                          </div>

                            <!-- <div class="custom_top_section_footer sec_3" v-if="about_us.length > 0">
                                <h2>About Us:</h2>
                                <h2 class="anchor_h2">
                                  <span class="read_more_footer"><p>{{ about_us[0].description.substring(3,110)+"..." }}</p><b-link :to="{name: 'aboutUs'}" >Read More <i class="fa fa-angle-right"></i></b-link></span>
                                </h2>
                            </div> -->
                            <!-- <div class="custom_top_section_footer sec_4"> -->
                                <div class="col-md-2 col-sm-2 sec_4 custom_top_section_footer border_footer_right border_footer_left">
                                <ul class="footer-social-links">
                                    <li>
                                        <a href="https://www.facebook.com/elconnectsgp/" alt="facebook" target="_blank"><i class="fa fa-facebook"></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/elconnectsgp/" alt="instagram" target="_blank"><i class="fa fa-instagram"></i></a>
                                    </li>
                                    <!-- <li>
                                        <a href="#" data-tippy-placement="bottom" data-tippy-theme="light" data-tippy="" data-original-title="Google Plus"><i class="icon-brand-google-plus-g"></i></a>
                                    </li> -->
                                    <li>
                                        <a href="https://www.youtube.com/c/elconnect" alt="youtube" target="_blank"><i class="fa fa-youtube-play"></i></a>
                                    </li>
                                     <li>
                                        <a href="https://www.tiktok.com/@elconnectsgp" alt="tiktok" target="_blank"><i class="fa-brands fa-tiktok"></i></a>
                                    </li>
                                </ul>
                            </div>
                            <!-- <div class="custom_top_section_footer sec_5"> -->
                                <div class="col-md-3 custom_top_section_footer col-sm-3 sec_5">
                                    <!-- <div class="desktop_float_right"> -->
                                    <div class="">
                                        <img :src="webUrl+'/images/MicrosoftTeams-image.png'" alt="apple">
                                        <img :src="webUrl+'/images/MicrosoftTeams-image (1).png'" alt="play_store">
                                        <div class="inner_anchor_mobile">
                                            <a href="https://apps.apple.com/sg/app/el-connect-employer/id1515716043" class="first_a_footer"   target="_blank"><i class="fa fa-apple"></i><span class="full_span">Download on the <span class="app_bt">App Store</span></span></a>
                                            <a href="https://play.google.com/store/apps/details?id=com.elconnectsupervisor&hl=en"   target="_blank" class="last_a_footer"><i class='fab fa-google-play'></i><span class="full_span">Get it on<span class="app_bt">Google Play</span></span></a>
                                        </div>
                                    </div>
                                </div>
                            <!-- </div> -->
                        </div>
                    </div>
                </div>
                </div>
                <!-- Footer Top Section / End -->

                <!-- Footer Copyrights -->
                <div class="footer-bottom-section">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-12">
                               <p class="desktop_margin_bottom_zero"> Ⓡ 2025 <strong>EL Connect</strong>. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Footer Copyrights / End -->

            </div>

    </div>

</template>

<script>
import { POST_API } from "../../../store/actions.type";

export default{
    data() {
        return {
            webUrl: process.env.VUE_APP_URL,
            items:'',
            items1:0,
            contact_us:'',
          get_address:'',
        }
    },
    filters:{
        trimString: function(value) {
            if (!value) return '';
            else
            return value.length > 35 ? value.substring(0, 33) + "..." : value;
        }
    },
    methods:{
        getContent() {

           return this.$store.dispatch(POST_API, {
                   data: {

                   },
                   api: '/api/get-distinct-jobs-for-jobseekers'
               })
               .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    }
                    else
                    {
                        this.items = this.$store.getters.getResults.data;
                        this.items1 = this.$store.getters.getResults.data1;

                        return this.items;
                    }
               });
       },
       getFooterData() {

return this.$store.dispatch(POST_API, {
    api: '/api/footer-data'
})
.then(() => {
    if (this.$store.getters.containsErrors) {
        this.error_message = this.$store.getters.getErrors;
        // this.showAlert();
        return [];
    } else {
        this.contact_us = this.$store.getters.getResults.contact_us;
        this.get_header = this.$store.getters.getResults.get_header;
        this.about_us = this.$store.getters.getResults.about_us;
        this.get_android_link = this.$store.getters.getResults.get_android_link;
        this.get_ios_link = this.$store.getters.getResults.get_ios_link;
        this.get_facebook_link = this.$store.getters.getResults.get_facebook_link;
        this.get_instagram_link = this.$store.getters.getResults.get_instagram_link;
        this.get_youtube_link = this.$store.getters.getResults.get_youtube_link;
        this.get_tiktok_link = this.$store.getters.getResults.get_tiktok_link;
        this.get_address = this.$store.getters.getResults.get_address;
    }
});
},
        openLink(){
            window.open("https://elconnectptuser.app.link/OSbf0HwLOib","_blank");
        }
    },
    mounted()
    {
        //this.getContent();
      this.getFooterData();


    }
}
</script>
