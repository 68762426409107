<script>
    import { POST_API } from "../../store/actions.type"

    export default {
       
        mounted(){

            this.$store.dispatch(POST_API, {
                    data: {
                        cat_id: this.$route.query.category,
                    },
                    api: '/api/catById'
                })
                .then(() => {

                var cat_alias = this.$store.getters.getResults.data[0].alias

            //document.title = "EL Connect - Terms of Service"; 
                    window.location.href = process.env.VUE_APP_URL +'singapore-daily-paid-part-time-jobs/'+ cat_alias + "/" + atob(this.$route.query.filter);
                })
        },
    }
</script>
