<template>
  <div>
    <div class="row">
      <div class="container my_new_container">
        <!-- Dashboard Headline -->
        <!-- <div class="dashboard-headline">
            <h1>Transactions</h1>
            <nav id="breadcrumbs" class="dark">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>Transactions</li>
                </ul>
            </nav>
        </div> -->
        <div id="breadcrumb-v2">
          <b-breadcrumb>
            <b-breadcrumb-item href="" :to="{ name: 'employer-list' }">Employers</b-breadcrumb-item>
            <b-breadcrumb-item active>{{ name | capitalize }} Transaction</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
        <!-- <b-tabs class="my_tab_job tab_header_action_th"> -->
        <!-- <b-tab :title="'ELC Credits'" @click="changeTab"> -->
        <b-tabs class="my_tab_job tab_header_action_th">
          <b-tab :title="'Jobs'" @click="changeTabJob">
            <div class="container new_cnt_pdng my_new_container">
              <div class="row">
                <div
                  class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div class="search-box custom_search_admin desktop_margin_top_zero">
                    <!-- <b-input-group> -->
                    <!-- <br/> -->
                    <b-input-group-prepend>
                      <b-form-input
                        id="searchBtn"
                        @keyup.enter.native="filterJobs = searchKeywordJobs"
                        v-model="searchKeywordJobs"
                        placeholder="Search by keyword"
                      ></b-form-input>
                      <b-button class="btn points_events"
                        ><i class="fa fa-search" @click="filterJobs = searchKeywordJobs"></i
                      ></b-button>
                    </b-input-group-prepend>
                    <!-- </b-input-group> -->
                  </div>
                </div>

                <div
                  class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div class="search-box custom_search_admin desktop_margin_top_zero">
                    <!-- <b-input-group> -->
                    <!-- <br/> -->
                    <b-form-group
                      id="input-group-6"
                      class="common_date_range_border_new desktop_margin_bottom_zero common_date_range_picker_new"
                    >
                      <date-range-picker
                        class="web_bottom_zero transactions_jb_calender calender_div_job_in_job"
                        ref="picker"
                        :minDate="minDate"
                        :opens="center"
                        :locale-data="locale"
                        :auto-apply="auto"
                        v-model="dateRange"
                        :ranges="range"
                        @update="changeTabJob()"
                      >
                        <div slot="input" slot-scope="picker">
                          {{ picker.startDate | date }} - {{ picker.endDate | date }}
                        </div>
                      </date-range-picker>
                    </b-form-group>
                    <!-- </b-input-group> -->
                  </div>
                </div>

                <template>
                  <div
                    class="col-xl-3 col-md-3 search_common_margin_bottom_28 mobile_tab_max_width_flex"
                  >
                    <b-form-group id="input-group-8" class="desktop_margin_bottom_zero">
                      <multiselect
                        id="selectServices"
                        v-model="form.status"
                        :options="transactionStatusOptions"
                        :multiple="false"
                        placeholder="Transaction Status"
                        track-by="value"
                        label="label"
                        class="common_black_multiselect_border job_list_multi_select model_multi_select admin_multi_select"
                        @input="changeTabJob()"
                      >
                      </multiselect>
                    </b-form-group>
                  </div>
                </template>

                <template>
                  <div
                    class="col-xl-3 col-md-3 search_common_margin_bottom_28 mobile_tab_max_width_flex"
                  >
                    <b-form-group id="input-group-8" class="desktop_margin_bottom_zero">
                      <multiselect
                        id="selectServices"
                        v-model="form.mode"
                        :options="transactionModeOptions"
                        :multiple="false"
                        placeholder="Transaction Mode"
                        track-by="value"
                        label="label"
                        class="common_black_multiselect_border job_list_multi_select model_multi_select admin_multi_select"
                        @input="changeTabJob()"
                      >
                      </multiselect>
                    </b-form-group>
                  </div>
                </template>

                <div
                  class="col-xl-3 col-md-3 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div class="search-box custom_search_admin desktop_margin_top_zero">
                    <!-- <b-input-group> -->
                    <!-- <br/> -->
                    <!-- <b-form-select id="selectServices" :plain="true" v-model="form.type" @change="changeTabJob()" class="remove_box_shadow desktop_margin_bottom_zero">
                                      <option value="">Transaction Type</option>
                                      <option value="add_back">Add Back</option>
                                      <option value="deduct">Deduct</option>
                                      <option value="fast_payment">Fast Payment Cash-out</option>
                                      <option value="incentive">Incentive</option>
                                      <option value="insurance">Insurance</option>
                                      <option value="job_completed">Job Completed</option>
                                      <option value="card">Job Payment Card</option>
                                      <option value="monthly_salary_payment">Monthly Salary Payment</option>
                                      <option value="paynow">Pay Now Cash-out</option>
                                      <option value="penalty">Penalty</option>
                                      <option value="penalty_waiver">Penalty Waiver</option>
                                      <option value="referral">Referral</option>
                                      <option value="refund">Refund</option>
                                      <option value="signup_bonus">Signup Bonus</option>
                                      <option value="transport_reimburse">Transport Reimburse</option>

                                    </b-form-select> -->
                    <b-form-select
                      v-model="form.type"
                      @change="changeTabJob()"
                      class="remove_box_shadow desktop_margin_bottom_zero date_style_size"
                    >
                      <option value="">Select Transaction</option>
                      <option :value="transaction" v-for="transaction in transactions">
                        {{ transaction | removeUnderscore }}
                      </option>
                    </b-form-select>
                    <!-- </b-input-group> -->
                  </div>
                </div>
                <div
                  class="col-xl-3 col-md-3 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div class="search-box custom_search_admin desktop_margin_top_zero">
                    <b-form-select
                      v-model="form.sub_type"
                      @change="changeTabJob()"
                      class="remove_box_shadow desktop_margin_bottom_zero date_style_size"
                    >
                      <option value="">Select Sub Transaction</option>
                      <option :value="transaction.id" v-for="transaction in transaction_sub_types">
                        {{ transaction.title | capitalize }}
                      </option>
                    </b-form-select>
                    <!-- </b-input-group> -->
                  </div>
                </div>

                <div
                  class="common_z_index_zero mobile_three_btn_transaction mobile_tab_max_width_flex transaction_1250_screen col-xl-12 search_common_margin_bottom_28 mobile_tab_max_width_flex search_common_margin_bottom_28"
                >
                  <!-- <button
                    class="button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero add_btn_admin_e"
                    @click="openMultipleTransactionModel()"
                    style="background-color: #4c8bf5; color: white"
                  >
                    Add
                  </button> -->
                  <b-button
                    class="common_z_index_zero button ripple-effect add_btn_admin_e ml-0"
                    @click="exportCoinTransactions()"
                    v-if="show('export')"
                    >Export</b-button
                  >
                  <b-button
                    @click="changeTabJob()"
                    class="button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero add_btn_admin_e"
                    >Search</b-button
                  >

                  <b-button
                    v-if="
                      filterJobs ||
                      searchKeywordJobs ||
                      form.status ||
                      form.mode ||
                      form.type ||
                      form.tran_type ||
                      form.sub_type ||
                      dateRange.startDate != start_date_filter ||
                      dateRange.endDate != end_date_filter
                    "
                    @click="clearFilterChangeJobs()"
                    class="mobile_reset_emp_transaction_single_user ml-3 new_reset_text mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0"
                    style="background-color: red; margin-top: 20px"
                    ><span class="mobile_reset">Reset filters</span></b-button
                  >
                </div>
              </div>
            </div>

            <!-- Page Content
        ================================================== -->
            <div class="row">
              <div class="container my_new_container show_filter_arrow">
                <b-table
                  ref="datatableAll"
                  show-empty
                  striped
                  hover
                  responsive
                  :items="itemsJobs"
                  :fields="fields"
                  :current-page="currentPageJobs"
                  :per-page="perPageJobs"
                  :filter="filterJobs"
                  :sort-by.sync="sortByJobs"
                  :sort-desc.sync="sortDescJobs"
                  :sort-direction="sortDirectionJobs"
                  class="desktop_margin_top_zero new_box_tbl_mrgn mobile_min_width_1500 thirteen_grid_tbl_new"
                >
                  <template #cell(transaction_number)="items">
                    {{ items.item.transaction_number | capitalize }}
                  </template>
                  <!-- <template #cell(transaction_id)="items">
                        {{items.item.transaction_id | capitalize}}
                    </template> -->
                  <template #cell(amount)="items">
                    <!-- ${{items.item.amount}} -->
                    <p
                      v-if="items.item.mode == 'credit'"
                      v-bind:class="{ green_credit_status: items.item.mode == 'credit' }"
                    >
                      ${{ items.item.amount }}
                    </p>
                    <p
                      v-if="items.item.mode == 'debit'"
                      v-bind:class="{ red_debit_status: items.item.mode == 'debit' }"
                    >
                      ${{ items.item.amount }}
                    </p>
                  </template>
                  <template #cell(last_name)="items">
                    <b-link
                      :to="
                        showFreelancer('view profile')
                          ? {
                              name: 'jobseeker-dp-profile-view',
                              params: { id: encrypt(items.item.user_id) },
                            }
                          : null
                      "
                      target="_blank"
                      style="color: #4c8bf5"
                    >
                      {{ items.item.last_name | capitalize }}
                      {{ items.item.first_name | capitalize }}
                    </b-link>
                  </template>
                  <template #cell(job_unique_id)="items">
                    {{ items.item.job_unique_id | capitalize }}
                  </template>

                  <template #cell(transaction_status)="items">
                    {{
                      items.item.transaction_status == "successfull"
                        ? "successful"
                        : items.item.transaction_status | capitalize
                    }}
                  </template>
                  <template #cell(coins)="items">
                    <p
                      v-if="items.item.mode == 'credit'"
                      v-bind:class="{ green_credit_status: items.item.mode == 'credit' }"
                    >
                      {{ items.item.coins }}
                    </p>
                    <p
                      v-if="items.item.mode == 'debit'"
                      v-bind:class="{ red_debit_status: items.item.mode == 'debit' }"
                    >
                      {{ items.item.coins }}
                    </p>
                    <!-- ${{items.item.coins}} -->
                  </template>
                  <template #cell(type)="items">
                    {{ items.item.type | capitalize | removeUnderscore }}<br />
                    <span v-if="items.item.sub_type_name != null"
                      >({{ items.item.sub_type_name | capitalize }})</span
                    >
                  </template>

                  <template #cell(billing_type)="items">
                    {{ items.item.billing_type_name | capitalize }}
                  </template>
                  <template #cell(mode)="items">
                    <!-- {{items.item.mode | capitalize}} -->
                    <p
                      v-if="items.item.mode == 'credit'"
                      v-bind:class="{ green_credit_status: items.item.mode == 'credit' }"
                    >
                      {{ items.item.mode | capitalize }}
                    </p>
                    <p
                      v-if="items.item.mode == 'debit'"
                      v-bind:class="{ red_debit_status: items.item.mode == 'debit' }"
                    >
                      {{ items.item.mode | capitalize }}
                    </p>
                  </template>
                  <template #cell(remarks)="items">
                    {{ items.item.remarks | capitalize }}
                  </template>
                  <template #cell(internal_remarks)="items">
                    {{ items.item.internal_remarks | capitalize }}
                  </template>
                  <template #cell(created_by_user_last_name)="items">
                    {{ items.item.created_by_user_last_name | capitalize }}
                    {{ items.item.created_by_user_first_name | capitalize }}
                  </template>
                  <template #cell(created_at)="items">
                    {{ items.item.trans_created_at | customFormatterOne }}
                  </template>
                  <template #cell(toggleAction)="items">
                    <div class="toggle-action toggle_margin_unset">
                      <b-dropdown class="mx-1" right text="Action" boundary="window">
                        <b-dropdown-item
                          v-if="
                            (items.item.remarks || items.item.internal_remarks) &&
                            show('update remark')
                          "
                          v-on:click="updateRemark(items.item.id, 'update', items.item.type)"
                          >Edit Remark
                        </b-dropdown-item>
                        <!-- <b-dropdown-item
                          v-else-if="show('add remark')"
                          v-on:click="updateRemark(items.item.id, 'add', items.item.type)"
                          >Add Remark
                        </b-dropdown-item> -->
                        <b-dropdown-item
                          v-if="
                            items.item.mode == 'debit' &&
                            items.item.type == 'job_completed' &&
                            (items.item.coins != '' || items.item.coins != null) &&
                            show('update elc credits')
                          "
                          v-on:click="
                            openCoinModel(
                              items.item.id,
                              items.item.user_id,
                              items.item.coins,
                              items.item.mode
                            )
                          "
                          >Update ELC Credits
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="
                            (items.item.type == 'add_back' ||
                              items.item.type == 'deduct' ||
                              items.item.type == 'transport_reimburse') &&
                            show('update amount')
                          "
                          v-on:click="
                            openAmountModel(
                              items.item.id,
                              items.item.user_id,
                              items.item.amount,
                              items.item.mode
                            )
                          "
                          >Update Amount
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="
                            (items.item.type == 'add_back' ||
                              items.item.type == 'deduct' ||
                              items.item.type == 'transport_reimburse') &&
                            show('delete transaction')
                          "
                          v-on:click="
                            deleteJobsAddBackDeductData(
                              items.item.id,
                              items.item.user_id,
                              items.item.amount,
                              items.item.mode,
                              items.item.type,
                              items.item.created_by
                            )
                          "
                          >Delete Transaction
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="items.item.has_image == 'yes' && show('view images')"
                          v-on:click="openImagesModel(items.item.id)"
                          >View Images
                        </b-dropdown-item>
                        <!-- <b-dropdown-item @click="downloadPdf(items.item.invoice_pdf)">View Invoice</b-dropdown-item> -->
                      </b-dropdown>
                    </div>
                  </template>
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                  <div class="page-row-box d-inline-block">
                    <b-form-group id="input-group-4">
                      <span class="d-inline-block">Rows per page</span>
                      <span class="d-inline-block"
                        ><b-form-select v-model="form.rowsPerPageJobs" @change="getJobTransaction">
                          <option>25</option>
                          <option>50</option>
                          <option>75</option>
                          <option>100</option>
                        </b-form-select></span
                      >
                    </b-form-group>
                  </div>
                  <div class="total-page-count d-inline-block">
                    <ul>
                      <li>
                        <span v-if="totalRowsJobs != 0"
                          >{{ fromJobs }} - {{ toJobs }} of {{ totalRowsJobs }}</span
                        >
                      </li>
                    </ul>
                  </div>
                  <b-pagination
                    :total-rows="totalRowsJobs"
                    :per-page="perPageJobs"
                    v-model="currentPageJobs"
                    prev-text=""
                    next-text=""
                    hide-goto-end-buttons
                  />
                </nav>
              </div>
            </div>
          </b-tab>

          <b-tab :title="'ELC Credits'" @click="changeTab">
            <div class="container new_cnt_pdng my_new_container">
              <div class="row">
                <div
                  class="col-xl-4 transaction_1250_screen mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div class="search-box custom_search_admin desktop_margin_top_zero">
                    <!-- <b-input-group> -->
                    <!-- <br/> -->
                    <b-input-group-prepend>
                      <b-form-input
                        id="searchBtn"
                        @keyup.enter.native="filter = searchKeyword"
                        v-model="searchKeyword"
                        placeholder="Search by keyword"
                      ></b-form-input>
                      <b-button class="btn points_events"
                        ><i class="fa fa-search" @click="filter = searchKeyword"></i
                      ></b-button>
                    </b-input-group-prepend>
                    <!-- </b-input-group> -->
                  </div>
                </div>

                <div
                  class="col-xl-4 transaction_1250_screen mobile_tab_max_width_flex search_common_margin_bottom_28"
                >
                  <!-- <label class="new_style_label required_sign required desktop_margin_bottom_zero">Date Range</label>                                 -->
                  <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">
                    <date-range-picker
                      class="cut_calender_fix manage_job_date_claneder common_date_range_picker_new common_width_100 transactions_jb_calender"
                      ref="picker"
                      :minDate="minDate"
                      :opens="center"
                      :locale-data="locale"
                      :auto-apply="auto"
                      v-model="dateRange1"
                      :ranges="range"
                      @update="changeTab()"
                    >
                      <div slot="input" slot-scope="picker">
                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                      </div>
                    </date-range-picker>
                  </b-form-group>
                </div>

                <template>
                  <div
                    class="col-xl-3 col-md-3 search_common_margin_bottom_28 mobile_tab_max_width_flex"
                  >
                    <b-form-group id="input-group-8" class="desktop_margin_bottom_zero">
                      <multiselect
                        id="selectServices"
                        v-model="mode_filter"
                        :options="transactionModeOptions"
                        :multiple="false"
                        placeholder="Transaction Mode"
                        track-by="value"
                        label="label"
                        class="common_black_multiselect_border job_list_multi_select model_multi_select admin_multi_select"
                        @input="changeTab()"
                      >
                      </multiselect>
                    </b-form-group>
                  </div>
                </template>

                <div
                  class="common_z_index_zero mobile_three_btn_transaction mobile_tab_max_width_flex transaction_1250_screen col-xl-4 search_common_margin_bottom_28 mobile_tab_max_width_flex search_common_margin_bottom_28"
                >
                  <!-- <button
                    class="desktop_margin_left_zero common_z_index_zero btn button ripple-effect add_btn_admin_e"
                    @click="openMultipleTransactionModel()"
                    style="background-color: #4c8bf5; color: white"
                  >
                    Add
                  </button> -->
                  <b-button
                    class="desktop_margin_left_zero common_z_index_zero button ripple-effect add_btn_admin_e"
                    @click="exportCoinTransactions()"
                    v-if="show('export')"
                    >Export</b-button
                  >
                  <b-button
                    @click="changeTab()"
                    class="button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero add_btn_admin_e"
                    >Search</b-button
                  >

                  <!-- <div class="sidebar-widget job_list_btn_search mobile_space_between penalty_btn desktop_margin_bottom_zero food_btn new_reset_btn"> -->
                  <b-button
                    v-if="
                      filter ||
                      searchKeyword ||
                      company_id_filter ||
                      trans_type_filter ||
                      mode_filter ||
                      dateRange1.startDate != start_date_filter ||
                      dateRange1.endDate != end_date_filter
                    "
                    @click="clearFilterChangeCoin()"
                    class="mobile_reset_emp_transaction_single_user ml-3 new_reset_text mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0"
                    style="background-color: red; margin-top: 20px"
                    ><span class="mobile_reset">Reset filters</span></b-button
                  >
                  <!-- </div> -->
                </div>
              </div>
            </div>

            <!-- Page Content
        ================================================== -->
            <div class="row">
              <div
                class="container my_new_container show_filter_arrow"
                v-if="currentTab == 'coins'"
              >
                <b-table
                  ref="datatableCoin"
                  show-empty
                  striped
                  hover
                  responsive
                  :items="getCoinTransaction"
                  :fields="fieldsCoin"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  class="desktop_margin_top_zero new_box_tbl_mrgn new_layout_table_like_v1 mobile_min_width_850"
                >
                  <template #cell(last_name)="items">
                    <span v-if="items.item.transactions[0].company_id">
                      <b-link
                        :to="
                          showEmployer('view profile')
                            ? {
                                name: 'employer-profile-view',
                                params: {
                                  id: encrypt(items.item.transactions[0].user_id),
                                  company_id: encrypt(items.item.transactions[0].company_id),
                                },
                              }
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{
                          items.item.transactions[0].last_name != null
                            ? items.item.transactions[0].last_name
                            : "" | capitalize
                        }}
                        {{ items.item.transactions[0].first_name | capitalize }}
                      </b-link>
                    </span>
                    <span v-else-if="items.item.transactions[0].last_name">
                      <b-link
                        v-if="items.item.transactions[0].user_type_id == 5"
                        :to="
                          showFreelancer('view profile')
                            ? {
                                name: 'jobseeker-dp-profile-view',
                                params: { id: encrypt(items.item.transactions[0].user_id) },
                              }
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{
                          items.item.transactions[0].last_name != null
                            ? items.item.transactions[0].last_name
                            : "" | capitalize
                        }}
                        {{ items.item.transactions[0].first_name | capitalize }}
                      </b-link>
                    </span>
                    <span v-else>
                      {{ items.item.transactions[0].last_name | capitalize }}
                      {{ items.item.transactions[0].first_name | capitalize }}
                    </span>
                  </template>
                  <template #cell(company_name)="items">
                    {{
                      items.item.transactions[0].company_name
                        ? items.item.transactions[0].company_name
                        : "-" | capitalize
                    }}
                  </template>
                  <!-- <template #cell(job_unique_id)="items">
                        <p v-if="items.item.job_unique_id && items.item.invoice_id">
                        <b-link :to="showManageFTJobs('view job Detail') ? {name: 'fullTimeJobDetail', params: {id: encrypt(items.item.job_id)}} : null" target="_blank" style="color: #4c8bf5;">
                            {{items.item.job_unique_id ? items.item.job_unique_id : '-' | capitalize}}
                        </b-link><br></p>
                        <p v-else-if="items.item.job_id && !items.item.invoice_id && !items.item.job_unique_id">{{items.item.job_id ? 'ID'+items.item.job_id : '-' | capitalize}}</p>
                        <p v-else>{{'-'}}</p>
                     </template> -->

                  <template #cell(job_unique_id)="items">
                    <p
                      v-if="
                        items.item.transactions[0].job_unique_id &&
                        items.item.transactions[0].invoice_id
                      "
                    >
                      <b-link
                        :to="
                          showManageFTJobs('view job Detail')
                            ? {
                                name: 'fullTimeJobDetail',
                                params: { id: encrypt(items.item.transactions[0].job_id) },
                              }
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{
                          items.item.transactions[0].job_unique_id
                            ? items.item.transactions[0].job_unique_id
                            : "-" | capitalize
                        }}
                      </b-link>
                      <br />
                    </p>
                    <p
                      v-else-if="
                        items.item.transactions[0].job_id &&
                        !items.item.transactions[0].invoice_id &&
                        items.item.transactions[0].job_v1_job_unique_id
                      "
                    >
                      <a
                        v-if="items.item.transactions[0].job_v1_status == 'in-progress'"
                        :href="
                          showManageDPJobs('view')
                            ? webUrlV1Admin +
                              '/admin/jobs/view-jobsDetail-inprogress/' +
                              encrypt(items.item.transactions[0].job_id) +
                              '/' +
                              items.item.transactions[0].job_v1_status +
                              '/null/null/null'
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        <!-- {{items.item.job_id ? 'ID'+items.item.job_id : '-' | capitalize}}  -->
                        {{
                          items.item.transactions[0].job_v1_job_unique_id
                            ? items.item.transactions[0].job_v1_job_unique_id
                            : "-" | capitalize
                        }}
                      </a>
                      <a
                        v-else-if="items.item.transactions[0].job_v1_status == 'active'"
                        :href="
                          showManageDPJobs('view')
                            ? webUrlV1Admin +
                              '/admin/jobs/view-jobsDetail-upcoming/' +
                              encrypt(items.item.job_id) +
                              '/' +
                              encrypt(items.item.transactions[0].job_id) +
                              '/null/null/null'
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{
                          items.item.transactions[0].job_v1_job_unique_id
                            ? items.item.transactions[0].job_v1_job_unique_id
                            : "-" | capitalize
                        }}
                      </a>
                      <a
                        v-else-if="items.item.transactions[0].job_v1_status == 'completed'"
                        :href="
                          showManageDPJobs('view')
                            ? webUrlV1Admin +
                              '/admin/jobs/view-jobsDetail-completed/' +
                              encrypt(items.item.transactions[0].job_id) +
                              '/' +
                              items.item.transactions[0].job_v1_status +
                              '/null/null/null'
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{
                          items.item.transactions[0].job_v1_job_unique_id
                            ? items.item.transactions[0].job_v1_job_unique_id
                            : "-" | capitalize
                        }}
                      </a>
                      <a
                        v-else
                        :href="
                          showManageDPJobs('view')
                            ? webUrlV1Admin +
                              '/admin/jobs/view-jobsDetail-cancelled/' +
                              encrypt(items.item.transactions[0].job_id) +
                              '/' +
                              items.item.transactions[0].job_v1_status +
                              '/null/null/null'
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{
                          items.item.transactions[0].job_v1_job_unique_id
                            ? items.item.transactions[0].job_v1_job_unique_id
                            : "-" | capitalize
                        }}
                      </a>
                    </p>
                    <p v-else>{{ "-" }}</p>
                  </template>

                  <!-- <template #cell(job_unique_id)="items" v-else>
                        <p>{{'-'}}</p>
                     </template> -->
                  <template #cell(transaction_number)="items">
                    <div class="d-flex align-items-center">
                      {{ items.item.transactions[0].transaction_number | capitalize }}
                      <b-button
                        v-if="items.item.isExpandable == true"
                        size="sm"
                        @click="items.toggleDetails"
                        class="expand-button"
                        :class="{ expanded: items.detailsShowing }"
                      >
                        <i
                          class="fas"
                          :class="items.detailsShowing ? 'fa-chevron-up' : 'fa-chevron-down'"
                        ></i>
                      </b-button>
                    </div>
                  </template>
                  <template #row-details="row">
                    <div class="d-flex flex-column w-100">
                      <!-- Loop through all transactions -->
                      <b-card
                        v-for="(transaction, index) in row.item.transactions"
                        :key="index"
                        class="expanded-card mb-2"
                      >
                        <div class="d-flex justify-content-start align-items-center">
                          <!-- Status Icon -->

                          <i
                            class="fas"
                            :class="[
                              transaction.transaction_status === 'successfull'
                                ? 'fa-check text-success'
                                : 'fa-times text-danger',
                            ]"
                          >
                          </i>

                          <!-- Date and Time -->
                          <div class="me-auto">
                            <small class="px-2">{{
                              transaction.trans_created_at
                                ? new Date(transaction.trans_created_at).toLocaleDateString()
                                : "-"
                            }}</small>
                            <small class="pr-2">{{
                              transaction.trans_created_at
                                ? new Date(transaction.trans_created_at).toLocaleTimeString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })
                                : "-"
                            }}</small>
                          </div>

                          <!-- Created by -->
                          <div>
                            <small class="pl-5"
                              >Created by: {{ transaction.created_by || "-" }}</small
                            >
                          </div>
                        </div>

                        <!-- Transaction Details -->
                        <ul class="list-unstyled mt-2 mb-0 ps-3">
                          <li><strong>ELC Credits:</strong> {{ transaction.coins || "-" }}</li>
                          <li>
                            <strong>Transact Type:</strong>
                            <span
                              :class="transaction.mode == 'credit' ? 'text-success' : 'text-danger'"
                            >
                              {{ `${transaction.trans_type_export} (${transaction.mode})` || "-" }}
                            </span>
                          </li>
                          <li>
                            <strong>Transac ID:</strong> {{ transaction.transaction_id || "-" }}
                          </li>
                          <li>
                            <strong>Transac No:</strong> {{ transaction.transaction_number || "-" }}
                          </li>
                        </ul>
                      </b-card>
                    </div>
                  </template>
                  <template #cell(coins)="items">
                    <p
                      v-if="items.item.transactions[0].mode == 'credit'"
                      v-bind:class="{
                        green_credit_status: items.item.transactions[0].mode == 'credit',
                      }"
                    >
                      {{ items.item.transactions[0].coins }}
                    </p>
                    <p
                      v-if="items.item.transactions[0].mode == 'debit'"
                      v-bind:class="{
                        red_debit_status: items.item.transactions[0].mode == 'debit',
                      }"
                    >
                      {{ items.item.transactions[0].coins }}
                    </p>
                    <!-- ${{items.item.coins}} -->
                  </template>
                  <!-- <template #cell(type)="items">
                        {{items.item.type == 'job_completed' ? 'Job Ad Posted' : items.item.type == 'elcoins' ? 'ELC Credits' : items.item.type | capitalize | removeUnderscore}}
                    </template> -->
                  <template #cell(type)="items">
                    {{
                      items.item.transactions[0].trans_type_export | capitalize | removeUnderscore
                    }}
                  </template>
                  <template #cell(mode)="items">
                    <p
                      v-if="items.item.transactions[0].mode == 'credit'"
                      v-bind:class="{
                        green_credit_status: items.item.transactions[0].mode == 'credit',
                      }"
                    >
                      {{ items.item.transactions[0].mode | capitalize }}
                    </p>
                    <p
                      v-if="items.item.transactions[0].mode == 'debit'"
                      v-bind:class="{
                        red_debit_status: items.item.transactions[0].mode == 'debit',
                      }"
                    >
                      {{ items.item.transactions[0].mode | capitalize }}
                    </p>
                    <!-- {{items.item.mode | capitalize}} -->
                  </template>
                  <template #cell(transaction_status)="items">
                    {{
                      items.item.transactions[0].transaction_status == "successfull"
                        ? "successful"
                        : items.item.transactions[0].transaction_status | capitalize
                    }}
                  </template>
                  <template #cell(remarks)="items">
                    {{ items.item.transactions[0].remarks | capitalize }}
                  </template>
                  <template #cell(internal_remarks)="items">
                    {{ items.item.transactions[0].internal_remarks | capitalize }}
                  </template>
                  <template #cell(created_by_user_last_name)="items">
                    {{ items.item.transactions[0].created_by_user_last_name | capitalize }}
                    {{ items.item.transactions[0].created_by_user_first_name | capitalize }}
                  </template>
                  <template #cell(created_at)="items">
                    {{ items.item.transactions[0].trans_created_at | customFormatterOne }}
                  </template>
                  <template #cell(toggleAction)="items">
                    <div class="toggle-action toggle_margin_unset">
                      <b-dropdown class="mx-1" right text="Action" boundary="window">
                        <b-dropdown-item
                          v-if="
                            (items.item.transactions[0].remarks ||
                              items.item.transactions[0].internal_remarks) &&
                            show('update remark')
                          "
                          v-on:click="
                            updateRemark(
                              items.transactions[0].item.id,
                              'update',
                              items.transactions[0].item.type
                            )
                          "
                          >Update Remark
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-else-if="show('add remark')"
                          v-on:click="
                            updateRemark(
                              items.item.transactions[0].id,
                              'add',
                              items.item.transactions[0].type
                            )
                          "
                          >Add Remark
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="show('update elc credits')"
                          v-on:click="
                            openCoinModel(
                              items.item.transactions[0].id,
                              items.item.transactions[0].user_id,
                              items.item.transactions[0].coins,
                              items.item.transactions[0].mode
                            )
                          "
                          >Update ELC Credits
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="
                            (items.item.transactions[0].type == 'add_back' ||
                              items.item.transactions[0].type == 'deduct') &&
                            show('delete transaction')
                          "
                          v-on:click="
                            deleteCoinsAddBackDeductTransaction(
                              items.item.transactions[0].id,
                              items.item.transactions[0].user_id,
                              items.item.transactions[0].coins,
                              items.item.transactions[0].mode,
                              items.item.transactions[0].type,
                              items.item.transactions[0].created_by
                            )
                          "
                          >Delete Transaction
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="
                            items.item.transactions[0].has_image == 'yes' && show('view images')
                          "
                          v-on:click="openImagesModel(items.item.transactions[0].id)"
                          >View Images
                        </b-dropdown-item>
                        <!-- <b-dropdown-item @click="downloadPdf(items.item.invoice_pdf)">View Invoice</b-dropdown-item> -->
                      </b-dropdown>
                    </div>
                  </template>
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                  <div class="page-row-box d-inline-block">
                    <b-form-group id="input-group-4">
                      <span class="d-inline-block">Rows per page</span>
                      <span class="d-inline-block"
                        ><b-form-select v-model="form.rowsPerPage" @change="getCoinTransaction">
                          <option>25</option>
                          <option>50</option>
                          <option>75</option>
                          <option>100</option>
                        </b-form-select></span
                      >
                    </b-form-group>
                  </div>
                  <div class="total-page-count d-inline-block">
                    <ul>
                      <li>
                        <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
                      </li>
                    </ul>
                  </div>
                  <b-pagination
                    :total-rows="totalRows"
                    :per-page="perPage"
                    v-model="currentPage"
                    prev-text=""
                    next-text=""
                    hide-goto-end-buttons
                  />
                </nav>
              </div>
            </div>
          </b-tab>

          <b-tab :title="'Job Ads'" @click="changeTabJobAds">
            <div class="container new_cnt_pdng my_new_container">
              <div class="row">
                <div
                  class="col-xl-4 transaction_1250_screen mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div class="search-box custom_search_admin desktop_margin_top_zero">
                    <!-- <b-input-group> -->
                    <!-- <br/> -->
                    <b-input-group-prepend>
                      <b-form-input
                        id="searchBtn"
                        @keyup.enter.native="filter = searchKeywordJobAds"
                        v-model="searchKeywordJobAds"
                        placeholder="Search by keyword"
                      ></b-form-input>
                      <b-button class="btn points_events"
                        ><i class="fa fa-search" @click="filter = searchKeywordJobAds"></i
                      ></b-button>
                    </b-input-group-prepend>
                    <!-- </b-input-group> -->
                  </div>
                </div>

                <div
                  class="col-xl-4 transaction_1250_screen mobile_tab_max_width_flex search_common_margin_bottom_28"
                >
                  <!-- <label class="new_style_label required_sign required desktop_margin_bottom_zero">Date Range</label>                                 -->
                  <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">
                    <date-range-picker
                      class="cut_calender_fix manage_job_date_claneder common_date_range_picker_new common_width_100 transactions_jb_calender"
                      ref="picker"
                      :minDate="minDate"
                      :opens="center"
                      :locale-data="locale"
                      :auto-apply="auto"
                      v-model="dateRange1"
                      :ranges="range"
                      @update="changeTabJobAds()"
                    >
                      <div slot="input" slot-scope="picker">
                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                      </div>
                    </date-range-picker>
                  </b-form-group>
                </div>

                <template>
                  <div
                    class="col-xl-3 col-md-3 search_common_margin_bottom_28 mobile_tab_max_width_flex"
                  >
                    <b-form-group id="input-group-8" class="desktop_margin_bottom_zero">
                      <multiselect
                        id="selectServices"
                        v-model="mode_filter"
                        :options="transactionModeOptions"
                        :multiple="false"
                        placeholder="Transaction Mode"
                        track-by="value"
                        label="label"
                        class="common_black_multiselect_border job_list_multi_select model_multi_select admin_multi_select"
                        @input="changeTabJobAds()"
                      >
                      </multiselect>
                    </b-form-group>
                  </div>
                </template>

                <div
                  class="common_z_index_zero mobile_three_btn_transaction mobile_tab_max_width_flex transaction_1250_screen col-xl-4 search_common_margin_bottom_28 mobile_tab_max_width_flex search_common_margin_bottom_28"
                >
                  <!-- <button
                    class="btn button green-border-btn add_btn_admin_e desktop_margin_left_zero"
                    @click="openMultipleTransactionModel()"
                    style="background-color: #4c8bf5; color: white"
                  >
                    Add
                  </button> -->
                  <b-button
                    class="desktop_margin_left_zero common_z_index_zero button ripple-effect add_btn_admin_e"
                    @click="exportCoinTransactions()"
                    v-if="show('export')"
                    >Export</b-button
                  >
                  <b-button
                    @click="changeTabJobAds()"
                    class="button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero add_btn_admin_e"
                    >Search</b-button
                  >

                  <!-- <div class="sidebar-widget job_list_btn_search mobile_space_between penalty_btn desktop_margin_bottom_zero food_btn new_reset_btn"> -->
                  <b-button
                    v-if="
                      filter ||
                      searchKeywordJobAds ||
                      company_id_filter ||
                      trans_type_filter ||
                      mode_filter ||
                      dateRange1.startDate != start_date_filter ||
                      dateRange1.endDate != end_date_filter
                    "
                    @click="clearFilterChangeCoin()"
                    class="mobile_reset_emp_transaction_single_user ml-3 new_reset_text mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0"
                    style="background-color: red; margin-top: 20px"
                    ><span class="mobile_reset">Reset filters</span></b-button
                  >
                  <!-- </div> -->
                </div>
              </div>
            </div>

            <!-- Page Content
        ================================================== -->
            <div class="row">
              <div
                class="container my_new_container show_filter_arrow"
                v-if="currentTab == 'job_ads'"
              >
                <b-table
                  ref="datatableJobAds"
                  show-empty
                  striped
                  hover
                  responsive
                  :items="getCoinTransaction"
                  :fields="fieldsJobAds"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  class="desktop_margin_top_zero new_box_tbl_mrgn mobile_min_width_1500 thirteen_grid_tbl_new"
                >
                  <template #cell(last_name)="items">
                    <span v-if="items.item.company_id">
                      <b-link
                        :to="
                          showEmployer('view profile')
                            ? {
                                name: 'employer-profile-view',
                                params: {
                                  id: encrypt(items.item.user_id),
                                  company_id: encrypt(items.item.company_id),
                                },
                              }
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{ items.item.last_name != null ? items.item.last_name : "" | capitalize }}
                        {{ items.item.first_name | capitalize }}
                      </b-link>
                    </span>
                    <span v-else-if="items.item.last_name">
                      <b-link
                        v-if="items.item.user_type_id == 5"
                        :to="
                          showFreelancer('view profile')
                            ? {
                                name: 'jobseeker-dp-profile-view',
                                params: { id: encrypt(items.item.user_id) },
                              }
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{ items.item.last_name != null ? items.item.last_name : "" | capitalize }}
                        {{ items.item.first_name | capitalize }}
                      </b-link>
                    </span>
                    <span v-else>
                      {{ items.item.last_name | capitalize }}
                      {{ items.item.first_name | capitalize }}
                    </span>
                  </template>
                  <template #cell(company_name)="items">
                    {{ items.item.company_name ? items.item.company_name : "-" | capitalize }}
                  </template>
                  <!-- <template #cell(job_unique_id)="items">
                        <p v-if="items.item.job_unique_id && items.item.invoice_id">
                        <b-link :to="showManageFTJobs('view job Detail') ? {name: 'fullTimeJobDetail', params: {id: encrypt(items.item.job_id)}} : null" target="_blank" style="color: #4c8bf5;">
                            {{items.item.job_unique_id ? items.item.job_unique_id : '-' | capitalize}}
                        </b-link><br></p>
                        <p v-else-if="items.item.job_id && !items.item.invoice_id && !items.item.job_unique_id">{{items.item.job_id ? 'ID'+items.item.job_id : '-' | capitalize}}</p>
                        <p v-else>{{'-'}}</p>
                     </template> -->

                  <template #cell(job_unique_id)="items">
                    <p v-if="items.item.job_unique_id && items.item.invoice_id">
                      <b-link
                        :to="
                          showManageFTJobs('view job Detail')
                            ? {
                                name: 'fullTimeJobDetail',
                                params: { id: encrypt(items.item.job_id) },
                              }
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{
                          items.item.job_unique_id ? items.item.job_unique_id : "-" | capitalize
                        }} </b-link
                      ><br />
                    </p>
                    <p
                      v-else-if="
                        items.item.job_id &&
                        !items.item.invoice_id &&
                        items.item.job_v1_job_unique_id
                      "
                    >
                      <a
                        v-if="items.item.job_v1_status == 'in-progress'"
                        :href="
                          showManageDPJobs('view')
                            ? webUrlV1Admin +
                              '/admin/jobs/view-jobsDetail-inprogress/' +
                              encrypt(items.item.job_id) +
                              '/' +
                              items.item.job_v1_status +
                              '/null/null/null'
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        <!-- {{items.item.job_id ? 'ID'+items.item.job_id : '-' | capitalize}}  -->
                        {{
                          items.item.job_v1_job_unique_id
                            ? items.item.job_v1_job_unique_id
                            : "-" | capitalize
                        }}
                      </a>
                      <a
                        v-else-if="items.item.job_v1_status == 'active'"
                        :href="
                          showManageDPJobs('view')
                            ? webUrlV1Admin +
                              '/admin/jobs/view-jobsDetail-upcoming/' +
                              encrypt(items.item.job_id) +
                              '/' +
                              items.item.job_v1_status +
                              '/null/null/null'
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{
                          items.item.job_v1_job_unique_id
                            ? items.item.job_v1_job_unique_id
                            : "-" | capitalize
                        }}
                      </a>
                      <a
                        v-else-if="items.item.job_v1_status == 'completed'"
                        :href="
                          showManageDPJobs('view')
                            ? webUrlV1Admin +
                              '/admin/jobs/view-jobsDetail-completed/' +
                              encrypt(items.item.job_id) +
                              '/' +
                              items.item.job_v1_status +
                              '/null/null/null'
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{
                          items.item.job_v1_job_unique_id
                            ? items.item.job_v1_job_unique_id
                            : "-" | capitalize
                        }}
                      </a>
                      <a
                        v-else
                        :href="
                          showManageDPJobs('view')
                            ? webUrlV1Admin +
                              '/admin/jobs/view-jobsDetail-cancelled/' +
                              encrypt(items.item.job_id) +
                              '/' +
                              items.item.job_v1_status +
                              '/null/null/null'
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{
                          items.item.job_v1_job_unique_id
                            ? items.item.job_v1_job_unique_id
                            : "-" | capitalize
                        }}
                      </a>
                    </p>
                    <p v-else>{{ "-" }}</p>
                  </template>

                  <!-- <template #cell(job_unique_id)="items" v-else>
                        <p>{{'-'}}</p>
                     </template> -->
                  <template #cell(transaction_number)="items">
                    {{ items.item.transaction_number | capitalize }}
                  </template>

                  <template #cell(transaction_id)="items">
                    {{ items.item.transaction_id | capitalize }}
                  </template>
                  <template #cell(coins)="items">
                    <p
                      v-if="items.item.mode == 'credit'"
                      v-bind:class="{ green_credit_status: items.item.mode == 'credit' }"
                    >
                      1 Job Ads
                    </p>
                    <p
                      v-if="items.item.mode == 'debit'"
                      v-bind:class="{ red_debit_status: items.item.mode == 'debit' }"
                    >
                      1 Job Ads
                    </p>
                    <!-- ${{items.item.coins}} -->
                  </template>
                  <!-- <template #cell(type)="items">
                        {{items.item.type == 'job_completed' ? 'Job Ad Posted' : items.item.type == 'elcoins' ? 'ELC Credits' : items.item.type | capitalize | removeUnderscore}}
                    </template> -->
                  <template #cell(type)="items">
                    {{ items.item.trans_type_export | capitalize | removeUnderscore }}
                  </template>
                  <template #cell(mode)="items">
                    <p
                      v-if="items.item.mode == 'credit'"
                      v-bind:class="{ green_credit_status: items.item.mode == 'credit' }"
                    >
                      {{ items.item.mode | capitalize }}
                    </p>
                    <p
                      v-if="items.item.mode == 'debit'"
                      v-bind:class="{ red_debit_status: items.item.mode == 'debit' }"
                    >
                      {{ items.item.mode | capitalize }}
                    </p>
                    <!-- {{items.item.mode | capitalize}} -->
                  </template>
                  <template #cell(gst)="items">
                    {{ items.item.gst }}
                  </template>
                  <template #cell(amount)="items">
                    {{ items.item.amount }}
                  </template>
                  <template #cell(transaction_status)="items">
                    {{
                      items.item.transaction_status == "successfull"
                        ? "successful"
                        : items.item.transaction_status | capitalize
                    }}
                  </template>
                  <template #cell(remarks)="items">
                    {{ items.item.remarks | capitalize }}
                  </template>
                  <template #cell(internal_remarks)="items">
                    {{ items.item.internal_remarks | capitalize }}
                  </template>
                  <template #cell(created_by_user_last_name)="items">
                    {{ items.item.created_by_user_last_name | capitalize }}
                    {{ items.item.created_by_user_first_name | capitalize }}
                  </template>
                  <template #cell(created_at)="items">
                    {{ items.item.trans_created_at | customFormatterOne }}
                  </template>
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                  <div class="page-row-box d-inline-block">
                    <b-form-group id="input-group-4">
                      <span class="d-inline-block">Rows per page</span>
                      <span class="d-inline-block"
                        ><b-form-select v-model="form.rowsPerPage" @change="getCoinTransaction">
                          <option>25</option>
                          <option>50</option>
                          <option>75</option>
                          <option>100</option>
                        </b-form-select></span
                      >
                    </b-form-group>
                  </div>
                  <div class="total-page-count d-inline-block">
                    <ul>
                      <li>
                        <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
                      </li>
                    </ul>
                  </div>
                  <b-pagination
                    :total-rows="totalRows"
                    :per-page="perPage"
                    v-model="currentPage"
                    prev-text=""
                    next-text=""
                    hide-goto-end-buttons
                  />
                </nav>
              </div>
            </div>
          </b-tab>
        </b-tabs>
        <!-- </b-tab> -->

        <!-- <b-tab :title="'Jobs'" @click="changeTabJob">
                    <div class="container new_cnt_pdng my_new_container">
                        <div class="row">
                            <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                                <div class="search-box custom_search_admin desktop_margin_top_zero">
                                        <b-input-group-prepend>
                                            <b-form-input id="searchBtn" @keyup.enter.native="filterJobs = searchKeywordJobs" v-model="searchKeywordJobs" placeholder="Search by keyword"></b-form-input>
                                            <b-button class="btn points_events"><i class="fa fa-search" @click="filterJobs = searchKeywordJobs"></i></b-button>
                                        </b-input-group-prepend>
                                </div>
                            </div>

                            <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                                <div class="search-box custom_search_admin desktop_margin_top_zero">
                                    
                                        <b-form-select id="selectServices" :plain="true" v-model="form.status" @change="changeTabJob()" class="remove_box_shadow desktop_margin_bottom_zero">
                                      <option value="">Transaction Status</option>
                                      <option value="successfull">Successfull</option>
                                      <option value="failed">Failed</option>
                                  </b-form-select>
                                </div>
                            </div> 

                            <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                                <div class="search-box custom_search_admin desktop_margin_top_zero">
                                            <b-form-select id="selectServices" :plain="true" v-model="form.mode" @change="changeTabJob()" class="remove_box_shadow desktop_margin_bottom_zero">
                                                <option value="">Transaction Mode</option>
                                                <option value="credit">Credit</option>
                                                <option value="debit">Debit</option>
                                            </b-form-select>
                                </div>
                            </div> 

                            <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                                <div class="search-box custom_search_admin desktop_margin_top_zero">
                                        <b-form-select id="selectServices" :plain="true" v-model="form.type" @change="changeTabJob()" class="remove_box_shadow desktop_margin_bottom_zero">
                                      <option value="">Transaction Type</option>
                                      <option value="paynow">Pay Now Cash-out</option>
                                      <option value="fast_payment">Fast Payment Cash-out</option>
                                      <option value="monthly_salary_payment">Monthly Salary Payment</option>
                                      <option value="card">Job Payment Card</option>
                                      <option value="referral">Referral</option>
                                      <option value="penalty">Penalty</option>
                                      <option value="job_completed">Job Completed</option>
                                      <option value="incentive">Incentive</option>
                                      <option value="penalty_waiver">Penalty Waiver</option>
                                      <option value="add_back">Add Back</option>
                                      <option value="deduct">Deduct</option>
                                      <option value="insurance">Insurance</option>
                                      <option value="refund">Refund</option>

                                  </b-form-select>
                                </div>
                            </div> 
                            <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                                <div class="search-box custom_search_admin desktop_margin_top_zero">
                                        <b-form-group id="input-group-6" class="common_date_range_border_new desktop_margin_bottom_zero common_date_range_picker_new">                                          
                                            <date-range-picker class="web_bottom_zero transactions_jb_calender calender_div_job_in_job" ref="picker" :minDate="minDate" :opens="center" :locale-data="locale" :auto-apply="auto" v-model="dateRange" :ranges='range' @update="changeTabJob()">
                                              <div slot="input" slot-scope="picker">
                                                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                              </div>
                                          </date-range-picker> 
                                      </b-form-group>
                                </div>
                            </div> 
                           
                            <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                                <div class="search-box custom_search_admin desktop_margin_top_zero">
                                        <b-form-select id="selectServices" :plain="true" v-model="form.tran_type" @change="changeTabJob()" class="remove_box_shadow desktop_margin_bottom_zero">
                                      <option value="">All Freelancer</option>
                                      <option value="ft_transaction">Full Time Freelancer</option>
                                  </b-form-select>
                                </div>
                            </div>   
                            
                        </div>
                    </div>
               
        
        <div class="row">
           <div class="container my_new_container show_filter_arrow">
               <b-table ref="datatableAll" show-empty striped hover responsive :items="getJobTransaction" :fields="fields" :current-page="currentPageJobs" :per-page="perPage" :filter="filterJobs" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="desktop_margin_top_zero new_box_tbl_mrgn   mobile_min_width_1000 nine_grid_tbl_new ">
                    <template #cell(transaction_number)="items">
                        {{items.item.transaction_number | capitalize}}
                    </template>
                    <template #cell(amount)="items">
                        <p v-if="items.item.mode == 'credit'" v-bind:class="{ 'green_credit_status' : items.item.mode == 'credit' }">${{items.item.amount}}</p>
                        <p v-if="items.item.mode == 'debit'" v-bind:class="{ 'red_debit_status' : items.item.mode == 'debit' }">${{items.item.amount}}</p>
                    </template>
                    <template #cell(last_name)="items">
                        <b-link :to="{name: 'jobseeker-dp-profile-view', params: {id: encrypt(items.item.user_id)}}" target="_blank" style="color: #4c8bf5;">

                            {{items.item.last_name | capitalize}} {{items.item.first_name | capitalize}}
                        </b-link>
                    </template>
                    <template #cell(job_unique_id)="items">
                        {{items.item.job_unique_id | capitalize}}
                    </template>
                    
                    <template #cell(transaction_status)="items">
                        {{items.item.transaction_status == 'successfull' ? 'successful' : items.item.transaction_status | capitalize}}
                    </template>
                    <template #cell(coins)="items">
                        <p v-if="items.item.mode == 'credit'" v-bind:class="{ 'green_credit_status' : items.item.mode == 'credit' }">{{items.item.coins}}</p>
                        <p v-if="items.item.mode == 'debit'" v-bind:class="{ 'red_debit_status' : items.item.mode == 'debit' }">{{items.item.coins}}</p>
                    </template>
                    <template #cell(type)="items">
                        {{items.item.type | capitalize | removeUnderscore}}
                    </template>
                    
                    <template #cell(mode)="items">
                        <p v-if="items.item.mode == 'credit'" v-bind:class="{ 'green_credit_status' : items.item.mode == 'credit' }">{{items.item.mode | capitalize }}</p>
                        <p v-if="items.item.mode == 'debit'" v-bind:class="{ 'red_debit_status' : items.item.mode == 'debit' }">{{items.item.mode | capitalize }}</p>
                    </template>
                     <template #cell(remarks)="items">
                        {{items.item.remarks | capitalize}}
                    </template>
                     <template #cell(internal_remarks)="items">
                        {{items.item.internal_remarks | capitalize}}
                    </template>
                     <template #cell(created_by_user_last_name)="items">
                        {{items.item.created_by_user_last_name | capitalize}} {{items.item.created_by_user_first_name | capitalize}}
                    </template>
                     <template #cell(created_at)="items">
                        {{items.item.trans_created_at | customFormatterOne}}
                    </template>
                    <template #cell(toggleAction)="items">
                        <div class="toggle-action toggle_margin_unset">
                            <b-dropdown class="mx-1" right text="Action" boundary="window">
                                <b-dropdown-item v-if="items.item.remarks || items.item.internal_remarks" v-on:click="updateRemark(items.item.id,'update')">Update Remark
                                </b-dropdown-item>
                                <b-dropdown-item v-else v-on:click="updateRemark(items.item.id,'add')">Add Remark
                                </b-dropdown-item>
                                <b-dropdown-item v-if="items.item.mode == 'debit' && items.item.type == 'job_completed' && (items.item.coins != '' || items.item.coins != null)" v-on:click="openCoinModel(items.item.id,items.item.user_id,items.item.coins,items.item.mode)">Update ELC Credits
                                </b-dropdown-item>
                                <b-dropdown-item v-if="(items.item.type == 'add_back' || items.item.type == 'deduct')" v-on:click="openAmountModel(items.item.id,items.item.user_id,items.item.amount,items.item.mode)">Update Amount
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </template>
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                    <div class="page-row-box d-inline-block">
                        <b-form-group id="input-group-4">
                             <span class="d-inline-block">Rows per page</span>
                            <span class="d-inline-block"><b-form-select v-model="form.rowsPerPageJobs" @change='getJobTransaction'>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>75</option>
                                        <option>100</option>
                            </b-form-select></span>
                        </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block">
                        <ul>
                            <li>
                                <span v-if='totalRowsJobs != 0'>{{fromJobs}} - {{toJobs}} of {{totalRowsJobs}}</span>
                            </li>
                        </ul>
                    </div>
                    <b-pagination :total-rows="totalRowsJobs"  :per-page="perPageJobs" v-model="currentPageJobs" prev-text="" next-text="" hide-goto-end-buttons />
                </nav>
			</div>
        </div>
                </b-tab> -->
        <!-- </b-tabs> -->
      </div>
    </div>
    <b-modal
      ref="coin-modal"
      title="Update ELC Credits"
      hide-footer
      content-class="common_model_header common_model_title"
      modal-class="background_custom_class"
    >
      <!-- <b-form> -->
      <div class="edit-form-box model_margin_top_zero">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12">
              <div class="form-map-box model_legend model_label">
                <b-col lg="0" md="0" class="submit-field">
                  <div id="show_hide_password_login" class="submit-field">
                    <!-- <b-form-group id="input-group-0" label="Transaction ELC Credits" class="required model_margin_bottom_zero"> -->
                    <!-- <b-form-input id="input-0" type="text" placeholder="" :value="user_coins" @change="v => user_coins = v" class="input-with-border model_border_and_box_shadow"></b-form-input> -->
                    <label class="model_inline model_margin_bottom_zero"
                      >Transaction ELC Credits</label
                    ><span class="model_font_16 model_float_right">
                      {{ transaction_prev_coins }}</span
                    >
                    <!-- </b-form-group> -->
                  </div>
                </b-col>

                <b-col lg="0" md="0" class="submit-field">
                  <div id="show_hide_password_login" class="submit-field">
                    <div class="row">
                      <div class="col-xl-7 mobile_col_7 common_align_middle_text">
                        <label class="model_margin_bottom_zero">ELC Credits</label>
                      </div>
                      <div class="col-xl-5 mobile_col_5">
                        <b-form-input
                          id="input-0"
                          type="text"
                          placeholder=""
                          :value="transaction_new_coins"
                          @change="(v) => (transaction_new_coins = v)"
                          class="input-with-border model_border_and_box_shadow"
                        ></b-form-input>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col lg="0" md="0">
                  <div slot="footer" class="form-btn common_crop_reset_btn">
                    <b-button
                      type="submit"
                      variant="success"
                      @click="submitUpdateCoinInfo($event)"
                      class="model_common_bt_success_color"
                      >Submit</b-button
                    >
                    <b-button
                      @click="hideModel()"
                      variant="light"
                      class="model_common_bt_lite_color"
                      >Cancel</b-button
                    >
                  </div>
                </b-col>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- </b-form> -->
    </b-modal>
    <b-modal
      ref="amount-modal"
      title="Update Amount"
      hide-footer
      content-class="common_model_header common_model_title"
      modal-class="background_custom_class"
    >
      <!-- <b-form> -->
      <div class="edit-form-box model_margin_top_zero">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12">
              <div class="form-map-box model_legend model_label">
                <b-col lg="0" md="0" class="submit-field">
                  <div id="show_hide_password_login" class="submit-field">
                    <!-- <b-form-group id="input-group-0" label="Transaction ELC Credits" class="required model_margin_bottom_zero"> -->
                    <!-- <b-form-input id="input-0" type="text" placeholder="" :value="user_coins" @change="v => user_coins = v" class="input-with-border model_border_and_box_shadow"></b-form-input> -->
                    <label class="model_inline model_margin_bottom_zero">Transaction Amount</label
                    ><span class="model_font_16 model_float_right">
                      {{ transaction_prev_amount }}</span
                    >
                    <!-- </b-form-group> -->
                  </div>
                </b-col>

                <b-col lg="0" md="0" class="submit-field">
                  <div id="show_hide_password_login" class="submit-field">
                    <div class="row">
                      <div class="col-xl-7 mobile_col_7 common_align_middle_text">
                        <label class="model_margin_bottom_zero">Amount</label>
                      </div>
                      <div class="col-xl-5 mobile_col_5">
                        <b-form-input
                          id="input-0"
                          type="text"
                          placeholder=""
                          :value="transaction_new_amount"
                          @change="(v) => (transaction_new_amount = v)"
                          class="input-with-border model_border_and_box_shadow"
                        ></b-form-input>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col lg="0" md="0">
                  <div slot="footer" class="form-btn common_crop_reset_btn">
                    <b-button
                      type="submit"
                      variant="success"
                      @click="submitUpdateAmountInfo($event)"
                      class="model_common_bt_success_color"
                      >Submit</b-button
                    >
                    <b-button
                      @click="hideModel()"
                      variant="light"
                      class="model_common_bt_lite_color"
                      >Cancel</b-button
                    >
                  </div>
                </b-col>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- </b-form> -->
    </b-modal>
    <b-modal
      ref="upload-modal"
      :title="modalTitle"
      hide-footer
      content-class="common_model_header common_model_title"
      modal-class="background_custom_class"
    >
      <b-form @submit="formSubmit">
        <div class="edit-form-box model_legend model_margin_top_zero">
          <div class="profile-repeate form-box-field">
            <b-row>
              <b-col lg="12" md="12">
                <div class="form-map-box">
                  <b-col lg="12" md="12">
                    <b-form-group id="input-group-10" label="Remarks" class="required">
                      <b-form-textarea
                        id="input-10"
                        maxlength="500"
                        v-model="form.remarks"
                        type="text"
                        placeholder=""
                        class="model_border_and_box_shadow"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                  <b-col lg="12" md="12" class="model_submit_field employer_multiple_radio">
                    <b-form-group>
                      <b-form-checkbox v-model="form.copyRemarksVal" @input="clickToCopyRemark()"
                        ><strong>Internal Remark same as Remark</strong></b-form-checkbox
                      >
                    </b-form-group>
                  </b-col>
                  <b-col lg="12" md="12">
                    <b-form-group id="input-group-10" label="Internal Remarks" class="required">
                      <b-form-textarea
                        id="input-10"
                        maxlength="500"
                        v-model="form.internal_remarks"
                        type="text"
                        placeholder=""
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                  <div
                    slot="footer"
                    class="form-btn col-md-12 col-lg-12 gallery_btn common_crop_reset_btn"
                  >
                    <b-button type="submit" variant="success" class="model_common_bt_success_color"
                      >Submit</b-button
                    >
                    <b-button
                      @click="hideModel()"
                      variant="light"
                      class="model_common_bt_lite_color"
                      >Cancel</b-button
                    >
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-form>
    </b-modal>
    <!-- Spacer -->
    <!-- <div class="margin-top-70"></div> -->
    <!-- Spacer / End-->
    <!-- Row / End -->
  </div>
</template>

<script>
import { POST_API } from "../../../store/actions.type";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Multiselect from "vue-multiselect";
import permission from "../../../../../server/permission.js";

export default {
  data() {
    return {
      transactionStatusOptions: [
        { value: "", label: "Transaction Status" },
        { value: "successfull", label: "Successful" },
        { value: "failed", label: "Failed" },
      ],
      transactionModeOptions: [
        { value: "", label: "Transaction Mode" },
        { value: "credit", label: "Credit" },
        { value: "debit", label: "Debit" },
      ],
      fields: [
        { key: "last_name", label: "User Name", sortable: true },

        { key: "transaction_number", label: "Transaction Number", sortable: true },
        // { key: 'transaction_id', label: 'Transaction Id', sortable: true },
        { key: "amount", label: "Amount", sortable: true },
        { key: "job_unique_id", label: "Job ID", sortable: true },
        { key: "transaction_status", label: "Status", sortable: true },
        { key: "coins", label: "ELC Credits", sortable: true },
        { key: "type", label: "Type", sortable: true },
        { key: "mode", label: "Mode", sortable: true },
        { key: "remarks", label: "Remarks", sortable: true },
        { key: "internal_remarks", label: "Internal Remarks", sortable: true },
        { key: "created_by_user_last_name", label: "Created By", sortable: true },
        { key: "created_at", label: "Transaction Date", sortable: true },
        { key: "toggleAction", label: "Actions" },
      ],
      fieldsCoin: [
        // { key: 'last_name', label: 'Name', sortable: true },
        // { key: 'company_name', label: 'Company Name', sortable: true },

        { key: "job_unique_id", label: "Job ID", sortable: true },
        { key: "transaction_number", label: "Transaction ID", sortable: true },
        { key: "coins", label: "ELC Credits", sortable: true },
        { key: "type", label: "Type", sortable: true },
        { key: "mode", label: "Mode", sortable: true },
        { key: "transaction_status", label: "Status", sortable: true },
        { key: "remarks", label: "Remarks", sortable: true },
        { key: "internal_remarks", label: "Internal Remarks", sortable: true },
        { key: "created_by_user_last_name", label: "Created By", sortable: true },
        { key: "created_at", label: "Transaction Date", sortable: true },
        // { key: 'toggleAction', label: 'Actions', }
      ],
      fieldsJobAds: [
        // { key: "last_name", label: "Name", sortable: true },
        // { key: "company_name", label: "Company Name", sortable: true },

        { key: "job_unique_id", label: "Job ID", sortable: true },
        { key: "transaction_number", label: "Transaction ID", sortable: true },
        { key: "transaction_id", label: "Invoice ID", sortable: true },

        { key: "coins", label: "Job Ads", sortable: true },
        { key: "type", label: "Type", sortable: true },
        { key: "mode", label: "Mode", sortable: true },
        { key: "transaction_status", label: "Status", sortable: true },
        { key: "gst", label: "GST", sortable: true },
        { key: "amount", label: "Amount With GST", sortable: true },
        { key: "remarks", label: "Remarks", sortable: true },
        { key: "internal_remarks", label: "Internal Remarks", sortable: true },
        { key: "created_by_user_last_name", label: "Created By", sortable: true },
        { key: "created_at", label: "Transaction Date", sortable: true },

        // { key: "toggleAction", label: "Actions" },
      ],
      pager: {},
      pageOfItems: [],
      minDate: null,
      center: "center",
      date: moment().format("YYYY-MM-DD"),
      items: null,
      totalRows: 0,
      format: "YYYY-MM-DD HH:mm",
      from: "",
      to: "",
      currentPage: 1,
      searchKeyword: "",
      searchKeywordJobs: "",
      searchKeywordJobAds: "",
      sortByJobs: null,
      sortDescJobs: true,
      perPage: 25,
      itemsJobs: null,
      totalRowsJobs: 0,
      fromJobs: "",
      toJobs: "",
      currentPageJobs: 1,
      perPageJobs: 50,
      pageOptionsJobs: [25, 50, 75, 100],
      auto: true,
      range: false,
      locale: {
        direction: "ltr", //direction of text
        format: "DD-MMM-YYYY", //fomart of the dates displayed
        separator: " - ", //separator between the two ranges
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        /*weekLabel: 'W',
                customRangeLabel: 'Custom Range',*/
        daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 0, //ISO first day of week - see moment documenations for details
        sameDateFormat: {
          from: "DD MM YYYY, hh:mm",
          to: "hh:mm",
        },
      },

      dateRange: {
        startDate: "",
        endDate: "",
      },
      dateRange1: {
        startDate: "",
        endDate: "",
      },
      pageOptions: [25, 50, 75, 100],
      // dateRange: {
      //     startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
      //     endDate: moment().format('YYYY-MM-DD')
      // },
      // dateRange1: {
      //     startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
      //     endDate: moment().format('YYYY-MM-DD')
      // },
      sortBy: null,
      sortDirection: "desc",
      sortDirectionJobs: "desc",
      filter: "",
      filterJobs: "",
      status: "active",
      sortDesc: true,
      webUrl: process.env.VUE_APP_URL,
      currentTab: "jobs",
      form: {
        rowsPerPage: 25,
        rowsPerPageJobs: 25,
        mode: "",
        type: "",
        status: "",
        tran_type: "",
        remarks: "",
        internal_remarks: "",
        copyRemarksVal: false,
        coins: "",
        sub_type: "",
      },
      modalTitle: "Add Remark",
      type: "add",
      transaction_sub_types: [],
      transactions: [],
      transaction_id: "",
      update_coin_user_id: "",
      update_coin_transaction_id: "",
      transaction_prev_coins: "",
      transaction_coin_mode: "",
      transaction_new_coins: "",
      update_amount_user_id: "",
      update_amount_transaction_id: "",
      transaction_prev_amount: "",
      transaction_amount_mode: "",
      transaction_new_amount: "",
      company_id_filter: "",
      companies: [],
      start_date_filter: moment().subtract(1, "months").format("YYYY-MM-DD"),
      end_date_filter: moment().format("YYYY-MM-DD"),
      webServerUrl: process.env.VUE_APP_SERVER_URL,
      name: "",
      webUrlV1Admin: process.env.VUE_APP_URL_V1_Admin,
      trans_type_filter: [],
      transactionTypesMultiselect: [
        { name: "Add Back", value: "add_back" },
        { name: "Top UP", value: "top_up" },
        { name: "Refund", value: "refund" },
        { name: "Deduct", value: "deduct" },
        { name: "ELC Credits", value: "elcoins" },
        { name: "Pending", value: "pending" },
        { name: "Featured ad", value: "featured_ad" },
        { name: "Job Completed", value: "job_completed" },
        { name: "Job Ad Posted", value: "job_ad_posted" },
      ],
      mode_filter: "",
      actions: "",
      actionsManageDPJobs: "",
      actionsManageFTJobs: "",
    };
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    customFormatterOne(date) {
      return moment(date).utc().format("DD MMM YYYY hh:mm A");
    },

    removeUnderscore: function (value) {
      if (!value) return "";
      var i,
        frags = value.split("_");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
    date: function (value) {
      if (value == null) {
        return "";
      } else {
        return moment(value).format("DD MMM YYYY");
      }
    },
  },

  components: {
    DateRangePicker,
    Multiselect,
  },
  methods: {
    customLabel({ first_name, last_name, name }) {
      return `${name} (${last_name} ${first_name})`;
    },
    showFreelancer(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actionsFreelancer.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    customLabelTransType({ name }) {
      console.log(name);
      return `${name}`;
    },
    showSuccessAlert(message) {
      this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        if (this.currentTab == "coins") {
          // this.getCoinTransaction().then(() => {
          this.$refs.datatableCoin.refresh();
          // });
        } else {
          // this.getJobTransaction().then(() => {
          this.$refs.datatableAll.refresh();
          // });
        }
      });
    },
    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    downloadPdf(url) {
      window.open(url);
    },
    clearFilterChangeCoin() {
      this.filter = "";
      this.searchKeyword = "";
      this.searchKeywordJobAds = "";
      this.status = "";
      (this.dateRange1.startDate = ""), (this.dateRange1.endDate = "");
      //   (this.dateRange1.startDate = moment().subtract(1, "months").format("YYYY-MM-DD")),
      //     (this.dateRange1.endDate = moment().format("YYYY-MM-DD"));
      this.company_id_filter = "";
      this.trans_type_filter = [];
      this.mode_filter = "";

      this.$refs.datatableCoin.refresh();
      this.$refs.datatableJobAds.refresh();
    },
    clearFilterChangeJobs() {
      this.filterJobs = "";
      this.searchKeywordJobs = "";
      this.form.status = "";
      this.form.mode = "";
      this.form.type = "";
      this.form.tran_type = "";
      this.dateRange = { startDate: null, endDate: null };
      this.form.sub_type = "";

      // (this.dateRange.startDate = moment().subtract(1, "months").format("YYYY-MM-DD")),
      //   (this.dateRange.endDate = moment().format("YYYY-MM-DD"));
      this.$refs.datatableAll.refresh();
    },
    changeTab() {
      this.currentTab = "coins";
      // this.filter = '';
      // this.searchKeyword = '';
      // this.dateRange1.startDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
      // this.dateRange1.endDate = moment().format('YYYY-MM-DD');
      // this.company_id_filter = '';
      // this.getCoinTransaction().then(() => {
      //   this.$refs.datatableCoin.refresh();
      // });.
      this.$refs.datatableCoin.refresh();
    },

    changeTabJob() {
      this.currentTab = "jobs";

      // this.getJobTransaction().then(() => {
      this.$refs.datatableAll.refresh();
      // });
    },
    openCoinModel(id, user_id, transaction_prev_coins, mode) {
      this.update_coin_user_id = user_id;
      this.update_coin_transaction_id = id;
      this.transaction_prev_coins = transaction_prev_coins ? transaction_prev_coins : 0;
      this.transaction_coin_mode = mode;
      this.transaction_new_coins = "";
      this.$refs["coin-modal"].show();
    },

    openAmountModel(id, user_id, transaction_prev_amount, mode) {
      this.update_amount_user_id = user_id;
      this.update_amount_transaction_id = id;
      this.transaction_prev_amount = transaction_prev_amount ? transaction_prev_amount : 0;
      this.transaction_amount_mode = mode;
      this.transaction_new_amount = "";
      this.$refs["amount-modal"].show();
    },

    changeTabJobAds() {
      this.currentTab = "job_ads";

      // this.getJobAdsTransaction().then(() => {
      this.$refs.datatableJobAds.refresh();
      // });
    },

    getJobAdsTransaction() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPage,
            keyword: this.searchKeyword,
            id: atob(this.$route.params.id),
            type: this.currentTab,
            trans_type_filter: this.trans_type_filter.length > 0 ? this.trans_type_filter : [],
            mode_filter: this.mode_filter ? this.mode_filter.value : "",
            dateRange: this.dateRange1,
            start_date: this.dateRange1.startDate
              ? moment(this.dateRange1.startDate).format("YYYY-MM-DD")
              : "",
            end_date: this.dateRange1.endDate
              ? moment(this.dateRange1.endDate).format("YYYY-MM-DD")
              : "",
            // company_id: this.company_id_filter.company_id ? this.company_id_filter.company_id : '',
            rowsPerPage: this.form.rowsPerPage,
            sortBy: this.sortBy ? this.sortBy : "transactions.created_at",
            sortDirection: this.sortDesc ? "desc" : "asc",
          },
          api: "/api/emp-com-all-transaction-list-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.itemsJobAds = this.$store.getters.getResults.pageOfItems;
            this.totalRowsJobs = this.$store.getters.getResults.pager.totalItems;
            this.perPageJobs = this.$store.getters.getResults.pager.pageSize;
            this.fromJobs = this.$store.getters.getResults.pager.from;
            this.toJobs = this.$store.getters.getResults.pager.to;
            return this.itemsJobAds;
          }
        });
    },
    getEmployers() {
      this.$store
        .dispatch(POST_API, {
          data: {},
          api: "/api/employer-job-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.companies = this.$store.getters.getResults.data;
          }
        });
    },
    getTransactions(type) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            type: type,
            transaction_types: this.transaction_types,
          },
          api: type == "all" ? "/api/transaction-types-master" : "/api/transaction-sub-types",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            if (type == "all") {
              this.transactions = this.$store.getters.getResults.data;
              return this.transactions;
            } else {
              this.transaction_sub_types = this.$store.getters.getResults.data;
              return this.transaction_sub_types;
            }
          }
        });
    },
    getCoinTransaction() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPage,
            keyword: this.searchKeyword,
            id: atob(this.$route.params.id),
            type: this.currentTab,
            trans_type_filter: this.trans_type_filter.length > 0 ? this.trans_type_filter : [],
            mode_filter: this.mode_filter ? this.mode_filter.value : "",
            dateRange: this.dateRange1,
            start_date: this.dateRange1.startDate
              ? moment(this.dateRange1.startDate).format("YYYY-MM-DD")
              : "",
            end_date: this.dateRange1.endDate
              ? moment(this.dateRange1.endDate).format("YYYY-MM-DD")
              : "",
            // company_id: this.company_id_filter.company_id ? this.company_id_filter.company_id : '',
            rowsPerPage: this.form.rowsPerPage,
            sortBy: this.sortBy ? this.sortBy : "transactions.created_at",
            sortDirection: this.sortDesc ? "desc" : "asc",
          },
          api: "/api/emp-com-all-transaction-list-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.totalRows = this.$store.getters.getResults.pager.totalItems;
            this.perPage = this.$store.getters.getResults.pager.pageSize;
            this.from = this.$store.getters.getResults.pager.from;
            this.to = this.$store.getters.getResults.pager.to;
            if (this.currentTab == "job_ads") {
              this.items = this.$store.getters.getResults.pageOfItems;
              return this.items;
            } else {
              this.items = this.$store.getters.getResults.pageOfItems;
              console.log(this.items);
              // Grouping by job_id (you can modify grouping logic here as needed)
              const groupedTransactions = this.items.reduce((groups, transaction) => {
                const jobId = transaction.job_id || "no_job_id"; // Group by job_id, or 'no_job_id' for items without job_id
                if (!groups[jobId]) {
                  groups[jobId] = {
                    job_id: jobId,
                    transactions: [],
                    isExpandable: !!transaction.job_id,
                  };
                }
                groups[jobId].transactions.push(transaction);
                return groups;
              }, {});
              console.log(groupedTransactions);
              this.groupedItems = Object.values(groupedTransactions); // Store grouped items for display
              console.log(this.groupedItems);
              return this.groupedItems;
            }
          }
        });
    },

    getJobTransaction() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPageJobs,
            keyword: this.filterJobs,
            id: atob(this.$route.params.id),
            type: this.currentTab,
            tran_type: this.form.tran_type,
            transaction_type: this.form.type,
            trans_type_filter: this.trans_type_filter.length > 0 ? this.trans_type_filter : [],
            mode: this.form.mode ? this.form.mode.value : "",
            status: this.form.status ? this.form.status.value : "",
            dateRange: this.dateRange,
            start_date: this.dateRange.startDate
              ? moment(this.dateRange.startDate).format("YYYY-MM-DD")
              : "",
            end_date: this.dateRange.endDate
              ? moment(this.dateRange.endDate).format("YYYY-MM-DD")
              : "",
            rowsPerPage: this.form.rowsPerPageJobs,
            sortBy: this.sortBy ? this.sortBy : "transactions.created_at",
            sortDirection: this.sortDesc ? "desc" : "asc",
            sub_type: this.form.sub_type,
          },
          api: "/api/emp-com-all-transaction-list-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.itemsJobs = this.$store.getters.getResults.pageOfItems;
            this.totalRowsJobs = this.$store.getters.getResults.pager.totalItems;
            this.perPageJobs = this.$store.getters.getResults.pager.pageSize;
            this.fromJobs = this.$store.getters.getResults.pager.from;
            this.toJobs = this.$store.getters.getResults.pager.to;
            return this.itemsJobs;
          }
        });
    },
    showEmployer(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actionsEmployer.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    clickToAdd(typeVal) {
      this.form.copyRemarksVal = false;
      this.$refs["upload-modal"].show();
      this.modalTitle = typeVal == "add" ? "Add Remark" : "Update Remark";
      this.type = typeVal == "add" ? "Add" : "Update";
    },
    clickToCopyRemark() {
      if (this.form.copyRemarksVal) {
        this.form.internal_remarks = this.form.remarks;
      } else {
        this.form.internal_remarks = this.form.internal_remarks;
      }
    },
    hideModel() {
      this.$refs["upload-modal"].hide();
      this.form.remarks = "";
      this.form.internal_remarks = "";
      this.form.copyRemarksVal = false;
      this.$refs["coin-modal"].hide();
      this.update_coin_user_id = "";
      this.update_coin_transaction_id = "";
      this.transaction_prev_coins = "";
      this.transaction_coin_mode = "";
      this.transaction_new_coins = "";
      this.$refs["amount-modal"].hide();
      this.update_amount_user_id = "";
      this.update_amount_transaction_id = "";
      this.transaction_prev_amount = "";
      this.transaction_amount_mode = "";
      this.transaction_new_amount = "";
    },
    submitUpdateCoinInfo(evt) {
      evt.preventDefault();
      this.$store
        .dispatch(POST_API, {
          data: {
            currentTab: this.currentTab,
            user_id: this.update_coin_user_id,
            transaction_id: this.update_coin_transaction_id,
            transaction_prev_coins: this.transaction_prev_coins,
            transaction_mode: this.transaction_coin_mode,
            transaction_new_coins: this.transaction_new_coins,
          },
          api: "/api/admin-transaction-coins-update",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            // if(this.type == 'update'){
            //     var message='Remark Updated successfully';
            // }else{
            //     var message='Remark Added successfully';
            // }
            var message = "ELC Credits Updated successfully";
            this.hideModel();
            this.showSuccessAlert(message);
          }
        });
    },

    submitUpdateAmountInfo(evt) {
      evt.preventDefault();
      this.$store
        .dispatch(POST_API, {
          data: {
            currentTab: this.currentTab,
            user_id: this.update_amount_user_id,
            transaction_id: this.update_amount_transaction_id,
            transaction_prev_amount: this.transaction_prev_amount,
            transaction_mode: this.transaction_amount_mode,
            transaction_new_amount: this.transaction_new_amount,
          },
          api: "/api/admin-transaction-amount-update",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            // if(this.type == 'update'){
            //     var message='Remark Updated successfully';
            // }else{
            //     var message='Remark Added successfully';
            // }
            var message = "Amount Updated successfully";
            this.hideModel();
            this.showSuccessAlert(message);
          }
        });
    },

    formSubmit(evt) {
      evt.preventDefault();
      this.$store
        .dispatch(POST_API, {
          data: {
            id: this.transaction_id,
            remarks: this.form.remarks,
            internal_remarks: this.form.internal_remarks,
            currentTab: this.currentTab,
          },
          api: "/api/admin-transaction-remarks-update",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            if (this.type == "update") {
              var message = "Remark Updated successfully";
            } else {
              var message = "Remark Added successfully";
            }
            this.hideModel();
            this.showSuccessAlert(message);
          }
        });
    },

    updateRemark(id, addUpdate) {
      this.clickToAdd(addUpdate);
      this.type = addUpdate;
      this.transaction_id = id;

      return this.$store
        .dispatch(POST_API, {
          data: {
            id: id,
            currentTab: this.currentTab,
          },
          api: "/api/admin-transaction-remarks-edit",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            this.form.remarks = this.$store.getters.getResults.data.remarks;
            this.form.internal_remarks = this.$store.getters.getResults.data.internal_remarks;
            this.form.coins = this.$store.getters.getResults.data.coins;
          }
        });
    },
    getComData() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            company_id: atob(this.$route.params.company_id),
          },
          api: "/api/get-company-data",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            if (this.$store.getters.getResults.data) {
              this.name = this.$store.getters.getResults.data.name;
            }
            this.companyAdmin = this.$store.getters.getResults.companyAdmin;
            this.companyDetail = this.$store.getters.getResults.data;
          }
        });
    },
    exportCoinTransactions() {
      var base_url = this.webServerUrl;
      var page = this.currentPage;
      var rowsPerPage = this.form.rowsPerPage;
      var start_date = this.dateRange1.startDate
        ? moment(this.dateRange1.startDate).format("YYYY-MM-DD")
        : "null";
      var end_date = this.dateRange1.endDate
        ? moment(this.dateRange1.endDate).format("YYYY-MM-DD")
        : "null";
      var sortBy = this.sortBy ? this.sortBy : "transactions.created_at";
      var sortDirection = this.sortDesc ? "asc" : "asc";
      var type = this.currentTab ? this.currentTab : "coins";
      // var company_id_filter = this.company_id_filter.company_id ? this.company_id_filter.company_id : null;
      // var keyword = this.filter ? this.filter : null;
      var keyword = this.searchKeyword ? this.searchKeyword : null;

      var trans_type_filter = null;

      var mode_filter = this.mode_filter ? this.mode_filter : null;
      var tran_type_filter = this.form.tran_type ? this.form.tran_type : null;
      var status_filter = this.form.status ? this.form.status : null;

      var id = atob(this.$route.params.id);
      // var export_url = '/api/export-pt-user/'+this.currentTab+'/'+this.userDPAccountStatusFilterAll+'/'+this.userAccountStatusFilterAll+'/'+this.filterRejected+'/null/null'+'/'+this.form.user_id;
      var export_url =
        "/api/emp-com-transaction-list-admin-export/" +
        keyword +
        "/" +
        type +
        "/" +
        id +
        "/" +
        trans_type_filter +
        "/" +
        mode_filter +
        "/" +
        tran_type_filter +
        "/" +
        status_filter +
        "/" +
        page +
        "/" +
        rowsPerPage +
        "/" +
        start_date +
        "/" +
        end_date +
        "/" +
        sortBy +
        "/" +
        sortDirection;

      window.open(base_url + export_url, "_blank");
      // window.open(export_url,'_blank')
    },
    permissionStaff() {
      if (this.$store.getters.getCurrentUserPermission) {
        if (
          this.$store.getters.getCurrentUserPermission.data.length > 0 &&
          this.$store.getters.currentUser.user_type_id == 2
        ) {
          var menu = this.$store.getters.getCurrentUserPermission.data;
          this.actions = permission.getPermissionStaff(menu, "Transactions");
          this.actionsManageFTJobs = permission.getPermissionStaff(menu, "Job Ads");
          this.actionsManageDPJobs = permission.getPermissionStaff(menu, "DP Jobs Admin");
        }
      }
    },
    show(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actions.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showManageFTJobs(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actionsManageFTJobs.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showManageDPJobs(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actionsManageDPJobs.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    $(".show_filter_arrow .table thead th div").contents().unwrap();
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    //document.title = "EL Connect - Jobseeker - Transaction";
    this.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.getEmployers();
    this.getComData();
    this.permissionStaff();
    this.getTransactions("all");
    this.getTransactions("sub_types");
    this.getJobTransaction();
  },
};
</script>
<style scoped>
.expandable-table {
  position: relative;
}
.expand-button {
  background: none;
  border: none;
  color: #4c8bf5;
  padding: 0;
  font-size: 1.2em;
}
.expand-button:focus {
  outline: none;
  box-shadow: none;
}
.expanded-card {
  width: 100% !important;
  display: block !important; /* Make sure it takes up the entire row width */
}
.table-responsive td {
  display: table-cell; /* Make sure table cells behave properly */
  width: 100%;
}
</style>
