<template>

    <div class="all_job_color all_job_top all_job_list__al_al_al">
        <div class="row">
           <div class="container mobile_tab_container_hundered">
               <div class="full-page-container cnt_height common_scroll_content mobile_tab_full_left_right_padding new_layout_side">
                        <div class="full-page-sidebar my_sd_bar">
                            <div class="full-page-sidebar-inner" data-simplebar>
                                <div class="sidebar-container remove_pdng jb_side_bar mobile_padding_right_zero tab_padding_right_zero">

                                     <!-- Keywords -->
                                    <div class="sidebar-widget">
                                        <p>Keywords</p>
                                        <div class="keywords-container">
                                            <div class="keyword-input-container">
                                                <input type="text" class="keyword-input" placeholder="e.g. job title" v-model="keyword" v-on:keyup.enter="submit"/>
                                            </div>
                                            <!-- <div class="keywords-list"></div> -->
                                            <div class="clearfix"></div>
                                        </div>
                                    </div>

                                      <!-- Category -->
                                    <!-- <div class="sidebar-widget">
                                        <h3>Category</h3>
                                        <b-form-select v-model="category_id" class="select_mrgn picker" v-on:change="submit">
                                             <option value="">All Categories</option>
                                            <option :value="category.id" v-for="category in categories">{{category.name | capitalize}}</option>
                                        </b-form-select>
                                    </div> -->
                                    <div class="sidebar-widget">
                                        <p>Company</p>
                                        <b-form-group id="input-group-8" class="required">
                                            <multiselect id="ajax" v-model="company_id_filter"  tag-placeholder="Search Company" placeholder="Enter to search Company" :options="employers" label="name" track-by="company_id" :multiple="true" :searchable="true" :clear-on-select="true" :close-on-select="true" :allow-empty="true" :show-labels="false" class="job_list_multi_select model_multi_select" ><span slot="noResult">No Company Found.</span></multiselect>
                                        </b-form-group>
                                    </div>

                                    <div class="sidebar-widget">
                                        <p>Category</p>
                                        <b-form-group id="input-group-8" class="required">
                                                <multiselect id="ajax" v-model="category_id"  tag-placeholder="Search Category" placeholder="Enter to search Category" :options="categories" label="name" track-by="id" :multiple="true" :searchable="true" :clear-on-select="true" :close-on-select="true" :allow-empty="true" :show-labels="false" class="job_list_multi_select model_multi_select" ><span slot="noResult">No Category Found.</span></multiselect>
                                                </b-form-group>
                                    </div>
                                    
                                    <!-- Location -->
                                    <div class="sidebar-widget">
                                        <p>Location</p>
                                        <div class="input-with-icon">
                                            <!-- <div id="autocomplete-container"> -->
                                                <select v-model="location_id" id="job_location" class="job_location custom-select">
                                                    <option value='' selected='selected' disabled>Select Location</option>
                                                    <optgroup :label="location.title" v-for="location in locations">
                                                        <option :value="location.alias_val[index]" v-for="(area,index) in location.location_names_val">{{area | capitalize}}</option>
                                                    </optgroup>
                                                </select>
                                                <!-- <b-form-select v-model="location_id">
                                                    <option value="" disabled>Select Location</option>
                                                    <option :value="location.id" v-for="location in locations">{{location.title | capitalize}}</option>
                                                </b-form-select> -->
                                                <!-- <input id="autocomplete-input" type="text" v-model="location" placeholder="Location"> -->
                                            <!-- </div> -->
                                            <!-- <i class="icon-material-outline-location-on"></i> -->
                                        </div>
                                    </div>





                                    <!-- Job Types -->
                                    <div class="sidebar-widget">
                                        <p>Job Role Type</p>
                                        <b-form-select v-model="role_type_id">
                                                    <option value="">All Job Role Types</option>
                                                    <option :value="role_type.name" v-for="role_type in role_types">{{role_type.name | customizeRoleType}}</option>
                                                </b-form-select>
                                        <!-- <div class="switches-list">
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" v-model="daily"><span class="switch-button"></span> Daily</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" v-model="full_time"><span class="switch-button"></span> Full Time</label>
                                            </div>
                                        </div> -->
                                    </div>

                                    <!-- Salary -->
                                    <div class="sidebar-widget">
                                        <p>Salary</p>
                                        <div class="salary_slider"><span>${{minimum_val}}-${{maximum_val}}</span></div>
                                         <vue-slider v-model="value" :min="this.min" @change="get_salary_data($event)" :max="this.max" :interval="interval" :formatter="formatter" class="point_slider" style="padding:11px 20px !important"></vue-slider>
                                   </div>

                                     <div class="sidebar-widget">
                                        <p>Tags</p>
                                        <div class="keywords-container">
                                            <div class="keyword-input-container">
                                                 <multiselect v-model="tagArr" @search-change="getTagData($event)"  :show-labels=false tag-placeholder="Add this as new tag" placeholder="Enter to search tag" label="title" track-by="id" :options="options" :multiple="true" class="new_multi_slec_list job_list_multi_select"><span slot="noResult">No Result.</span></multiselect>
                                           </div>
                                        </div>
                                    </div>
                                     <div class="sidebar-widget job_list_btn_search mobile_space_between">
                                    <!-- <b-button @click="submit" class="button ripple-effect">Search</b-button> -->
                                    <b-button @click="clear" class="button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_left_zero" style="background-color: red; margin-left:20px;">Clear Filter</b-button>

                                    <b-button @click="submit" class="button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_right_zero" style="width: 120px;">Search</b-button>

                                </div>

                                <!-- <div class="sidebar-search-button-container sidebar-widget job_list_btn_search"> -->
                                    <!-- <b-button @click="clear" class="button ripple-effect" style="background-color: red;/* position: unset; */">Clear Filter</b-button> -->
                                <!-- </div> -->
                                </div>


                                <!-- Search Button / End-->
                            </div>
                        </div>
                        <div class="full-page-content-container job_list_height fit_height remove_white_bg" data-simplebar>
                            <div class="full-page-content-inner job_list_btm new_job_listss common_padding_30 add_white_bg">

                                <h1 class="page-title search_h1">Full Time Jobs</h1>

                                <!-- <div class="notify-box margin-top-15 desktop_margin_bottom_zero">
                                    <div class="switch-container">
                                        <label class="switch"><input type="checkbox"><span class="switch-button"></span><span class="switch-text">Turn on email alerts for this search</span></label>
                                    </div>
                                </div> -->
                                <div class="content margin-top-30" v-if="jobData.length > 0">
                                    <div class="listings-container new_liting_containerz grid-layout " v-for="jobDatas in jobData" :current-page="currentPage" :per-page="perPage">
                                        <!-- Job Listing -->
                                        <a href="#" @click="jobDetail((jobDatas.alias),(jobDatas.jobId),jobDatas.job_alias,$event)" class="job-listing new_job_listingz" style="width: 100%;">
                                        <!-- <a href="#"  class="job-listing" style="width: 100%;"> -->
                                            <div class="listing_features_new web_feature">
                                                <p v-if="jobDatas.featured == 'yes'" class="featured_h5"><b>Featured Ad</b></p>
                                            </div>
                                            <!-- Job Listing Details -->
                                            <div class="job-listing-details new_job_listing_detailz new_padding_top_10">
                                                <!-- Logo -->
                                                <div class="job-listing-company-logo new_job_listing_company_logo single_logo_img">
                                                    <img :src="jobDatas.thumbnail != null ? jobDatas.thumbnail: jobDatas.company_logo != null ? jobDatas.company_logo:'/assets/images/company-logo-placeholders.png'" alt="company_logo">
                                                    <!-- <img :src="jobDatas.company_logo != null ? jobDatas.company_logo: '/assets/images/company-logo-placeholders.png'" alt="company_logo"> -->
                                                    <!--<h5 v-if="jobDatas.featured == 'yes'"><b>Yes</b></h5>
                                                    <h5 v-else><b>No</b></h5>-->
                                                </div>
                                                <!-- Details -->
                                                <div class="job_listing_card_inner new_job_listing_card_innerz">
                                                <div class="job-listing-description common_br_word_job_listing">
                                                    <!-- <h4 class="job-listing-company bold_heading_iinner suggested_title" v-if="jobDatas.job_title.length >= 25"><b>{{jobDatas.job_title.substring(0,25)+'...' | capitalize}}</b></h4>
                                                    <h4 class="job-listing-company bold_heading_iinner suggested_title" v-else><b>{{jobDatas.job_title | capitalize}}</b></h4> -->
                                                    <h2 class="job-listing-company bold_heading_iinner suggested_title"><b>{{jobDatas.job_title | capitalize}}</b></h2>
                                                    <div class="star_icon joblist_star_icon desktop_flex">
                                                    <div class="featured_job_section_lists">
                                                            <!-- <h5 v-if="jobDatas.featured == 'yes'" class="featured_h5"><b>Featured Ad</b></h5> -->
                                                            <!--<h5 v-else class="red_bg"><b>No Featured Ad</b></h5>-->
                                                        </div>
                                                    </div>
                                                    <div class="job-listing-title new_all_job_title desktop_padding_right_zero" v-if="jobDatas.job_description">
                                                        <p v-if="jobDatas.job_description.length >= 250" v-html="jobDatas.job_description.substring(0,250)+'...' "></p>
                                                        <p v-else v-html="jobDatas.job_description"></p>
                                                    </div>

                                                </div>
                                                <div class="all_job_list_home featured_job_section_lists">
                                                    <!-- <h5 v-if="jobDatas.featured == 'yes'"><b>Featured Ad</b></h5> -->
                                                    <!--<h5 v-else class="red_bg"><b>No Featured Ad</b></h5>-->
                                                </div>
                                                </div>
                                            </div>

                                            <!-- Job Listing Footer -->
                                            <div class="job-listing-footer">

                                                <!-- <span class="bookmark-icon" @click="bookmarkJob(jobDatas.jobId)"></span> -->
                                               <ul>
                                                    <li class="black_job_color"><i class="icon-material-outline-location-on common_i_color"></i>{{jobDatas.job_address == 'Other' ? jobDatas.other_location : jobDatas.job_address | capitalize}}</li>
                                                    <li class="black_job_color"><i class="icon-material-outline-business-center common_i_color"></i>{{jobDatas.job_type | customizeRoleType}}</li>
                                                    <li class="black_job_color" v-if="(jobDatas.min_salary != 0 && jobDatas.max_salary != 0) || jobDatas.min_salary > 0 || jobDatas.max_salary > 0"><i class="common_i_color material-icons-outlined custom dollar_custom_sign">monetization_on_outline</i>${{jobDatas.min_salary}}-${{jobDatas.max_salary}}</li>
                                                    <li class="black_job_color"><i class="icon-material-outline-access-time common_i_color"></i>{{jobDatas.posted_date | dateTimeAgo | capitalize}}</li>
                                               </ul>
                                            </div>
                                        </a>
                                        <!-- Job Listing -->
                                    </div>
                                </div>
                                <div class="content text-center" v-else>
                                    <p class="margin_top_1rem"><b>No Record Found</b> </p>
                                </div>

                                <div class="clearfix"></div>
                                <nav class="pagination-box">
                                    <div class="page-row-box d-inline-block">
                                        <b-form-group id="input-group-4">
                                            <span class="d-inline-block">Rows per page</span>
                                        <span class="d-inline-block"><b-form-select v-model="form.rowsPerPage" @change='getjobsData'>
                                                        <option>25</option>
                                                        <option>50</option>
                                                        <option>75</option>
                                                        <option>100</option>
                                            </b-form-select></span>
                                        </b-form-group>
                                    </div>
                                    <div class="total-page-count d-inline-block">
                                        <ul>
                                            <li>
                                                <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <b-pagination @input='getjobsData' :total-rows="totalRows" :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                                </nav>
                                <div class="clearfix"></div>
                                
                            </div>
                            <div class="tag_blocks_section d-inline-block saerch_tag_web">
                                 <p>People also searched:</p>
                                    <b-form-tag
                                            v-for="jobSearch in jobSearchArr"
                                            :key="jobSearch"
                                            :title="jobSearch"
                                            class="mr-1"
                                        >
                                        <span>
                                        <b-button
                                            @click="onCLickRelatedSearch(jobSearch)"
                                            size="sm"
                                            class="search_btn_filter"
                                            ><i class="material-icons-outlined">search_outlined</i></b-button>
                                        <b-button  @click="onCLickRelatedSearch(jobSearch)" class="tag_btn_webs"> {{ jobSearch }}</b-button></span>
                                </b-form-tag>
                            </div>
                        </div>
                </div>
			</div>
        </div>

    </div>

</template>



<script>
    import { POST_API } from "../../store/actions.type"
    import 'vue-slider-component/theme/default.css'
    import VueSlider from 'vue-slider-component'
    import Multiselect from 'vue-multiselect'
    import moment from 'moment'
import { validateConfig } from 'face-api.js'


    export default {
        metaInfo: {
            title: 'Find and Apply for Full Time Jobs - All Jobs Listing ',
            meta: [
                { charset: 'utf-8' },
            { equiv: 'content-type', content: 'text/html' },
                { name: 'description', content: 'Ready for a career change or finding a new job opportunity? Look no further! Explore over 100 job roles at ELConnect and apply for full time jobs today!'},
                { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                { name: 'keywords', content: 'find full time jobs, apply for full time jobs, full time jobs, career switch, job search, job listing, full time employment'},
                {property: 'og:title', content: 'EL Connect - Full Time Jobs Listing'},
                {property: 'og:site_name', content: 'EL Connect'},
                {property: 'og:type', content: 'website'},
                {property: 'og:url', content: 'https://elconnect.sg/singapore-full-time-jobs'},
                {property: 'og:image', content: 'https://elconnect.sgp1.digitaloceanspaces.com/v2/general_setting/202281202127_general_setting.png'},
                {property: 'og:description', content: 'Ready for a career change or finding a new job opportunity? Look no further! Explore over 100 job roles at ELConnect and apply for full time jobs today!'},
                {name: 'twitter:card', content: 'summary'}
            ],
            link: [
    		    {rel: 'canonical', href: 'https://elconnect.sg/singapore-full-time-jobs'}
  		    ]
	    },
        data() {
            return {
                job_id:'',
                jobData: '',
                category_id:[],
                catArr:[],
                category_id_string:'',
                tagstring:'',
                company_id_string:'',
                company_id_filter:[],
                employers: [],
                companyArr:[],
                daily:'',
                full_time:'',
                type:'',
                salary:'',
                error_message:'',
                categories: [],
                tagArr: [],
                value: [0, 22000],
                pager: {},
                pageOfItems: [],
                options: [],
                items:null,
                totalRows: 0,
                sliderValue: 50,
                minimum_val:0,
                maximum_val:22000,
                from: '',
                to: '',
                currentPage: 1,
                perPage: 25,
                pageOptions: [25, 50, 75, 100],
                form:{
                    rowsPerPage:25,
                },
                locations:[],
                tagrr:[],
                location:'',
                type:'',
                role_types:[],
                allSearchData:[],
                jobTypedata : '',
                category_id :[],
                keyword :'',
                location_id :'',
                role_type_id :'',
                jobSearchData:[],
                jobSearchArr: []

                // category_id :this.$route.params.category_id == 'all' ||this.$route.params.category_id == '' ? '':this.$route.params.category_id,
                // keyword :this.$route.params.keyword == 'all' ||this.$route.params.keyword == '' ? '' :this.$route.params.keyword,
                // location_id :this.$route.params.location == 'all' ||this.$route.params.location == '' ? '':this.$route.params.location,
                // role_type_id :this.$route.params.role_type == 'all' ||this.$route.params.role_type == '' ? '':this.$route.params.role_type
            }
        },
        components: {
                VueSlider,Multiselect
        },

        filters: {
            capitalize: function(value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            dateTimeAgo: function(value) {
                var m = moment(value);  // or whatever start date you have
                // var today = moment().startOf('day');
                var today = moment();
                 var days = Math.round(moment.duration(today - m).asDays());
                if(days > 0){
                    return days +' Days Ago'
                }else{
                    return 'today'
                }
                // if (!value) return ''
                // const dateTimeAgo = moment(value).fromNow();
                // return dateTimeAgo
            },
            // dateTimeAgo: function(value) {


            //     var m = moment(value);  // or whatever start date you have
            //     var today = moment().startOf('day');

            //      var days = Math.round(moment.duration(today - m).asDays());
            //      if(days == -1){
            //         return 'today'
            //     }else{
            //         return days +1 +' Days Ago';
            //     }
            //     // if (!value) return ''
            //     // const dateTimeAgo = moment(value).fromNow();
            //     // return dateTimeAgo
            // },

            customFormatterOne(date) {
                return moment(date).utc().format('DD-MM-YYYY');
            },
            removeUnderscore: function(value) {
                if (!value) return ''
                var i, frags = value.split('_');
                for (i=0; i<frags.length; i++) {
                    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
            },
            customizeRoleType: function(value) {
                if (!value) return ''
                var i, frags = value.split('-');
                for (i=0; i<frags.length; i++) {
                    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
            },
        },
        created() {
            this.min = 0
            this.max = 22000
            this.interval = 1000
            this.formatter = value => `$${value}`
        },

        methods:{
            showAlert() {
                this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: this.error_message,
                    showConfirmButton: false,
                    timer: 1500
                })
            },
            showSuccessAlert(message) {
                this.$swal({
                    position: 'center',
                    icon: 'success',
                    iconColor:'#4c8bf5',
                    title: message,
                    showConfirmButton: false,
                    timer: 1500
                });
            },
             getEmployers(){
                this.$store.dispatch(POST_API, {
                    data:{
                         company_id_string:this.company_id_string,
                    },
                    api:'/api/employer-ft-job-list-website'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.employers = this.$store.getters.getResults.data;
                        this.company_id_filter = this.company_id_string ? this.$store.getters.getResults.company_array : this.company_id_filter;
                        this.getjobsData();
                    }
                });
            },
            clear(){
                window.location.replace('/singapore-full-time-jobs');

                // this.category_id = [];
                // this.location_id = '';
                // this.type = '';
                // this.keyword = '';
                // this.skill = '';
                // this.value =[0, 22000];
                // this.minimum_val=0;
                // this.maximum_val=22000;
                // //this.value = '';
                // this.daily = '';
                // this.tagArr = '';
                // this.role_type_id = '';
                // this.full_time = '';
                // this.getjobsData();
                // this.getJobSerachData();
            },

            getDropdownData(){
                this.$store.dispatch(POST_API, {
                    data:{
                        table:'service_categories',
                        category_id_string:this.category_id_string,
                        tagString:this.tagString

                    },
                    api:'/api/webCategory'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.categories = this.$store.getters.getResults.data;
                        this.category_id = this.category_id_string ? this.$store.getters.getResults.category_array : this.category_id;
                        this.tagArr = this.tagString ? this.$store.getters.getResults.tag : this.tagArr;

                        this.getjobsData();
                    }
                });
            },
            get_salary_data(event){
                this.minimum_val = event[0];
                this.maximum_val = event[1];
                if(this.jobTypedata != 2){
                    if(event[1] <= 10000){
                        this.interval = 1000;
                    }else{
                        this.interval = 2000;
                    }
                }
                // this.submit();
            },
            get_job_type(event){

                this.jobTypedata = event;

                if(event == 2){
                    this.value = [1, 100]
                    this.minimum_val = 1;
                    this.maximum_val = 100;
                    this.min = 1;
                    this.max = 100;
                    this.interval = 1
                }
                this.submit();
            },
            getjobsData() {
                validateConfig
                if(this.salary){
                    var sal = this.salary.split('-');
                    this.value =[parseInt(sal[0]), parseInt(sal[1])];

                    this.minimum_val = parseInt(sal[0]);
                    this.maximum_val = parseInt(sal[1]);
                }
                
                return this.$store.dispatch(POST_API, {
                        data:{
                            page: this.currentPage,
                            rowsPerPage:this.form.rowsPerPage,
                            salary: this.salary ? this.salary.split(',') : '',
                            tagArr: this.tagArr,
                            category: this.category_id == 'all-categories-jobs' || this.category_id == [] || this.category_id == '' ? [] : this.category_id,
                            company: this.company_id_filter == 'all-companies-jobs' || this.company_id_filter == [] || this.company_id_filter == '' ? [] : this.company_id_filter,

                            keyword: this.keyword == 'all-title-jobs' || this.keyword == '' ? '' : this.keyword,
                            location: this.location_id == 'all-location-jobs' || this.location_id == '' ? '' : this.location_id,
                            job_type_id: this.role_type_id == 'all-role-jobs' || this.role_type_id == '' ? '' : this.role_type_id,
                },
                    api: '/api/all-jobs-listing'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.jobData = this.$store.getters.getResults.pageOfItems;
                        this.totalRows = this.$store.getters.getResults.pager.totalItems;
                        this.perPage = this.$store.getters.getResults.pager.pageSize;
                        this.from = this.$store.getters.getResults.pager.from;
                        this.to = this.$store.getters.getResults.pager.to;

                        return this.jobData;
                    }
                });
            },
            getjobRoleType(){
                this.$store.dispatch(POST_API, {
                    data:{
                    },
                    api:'/api/job-role-type'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.role_types = this.$store.getters.getResults.data;
                    }
                });
            },
            getJobSerachData(){
                this.jobSearchArr = [];
                this.$store.dispatch(POST_API, {
                    data:{
                        type: 'full_time',
                        title: this.keyword,
                    },
                    api:'/api/job-search-data'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.jobSearchData = this.$store.getters.getResults.data;
                        this.jobSearchData.forEach(element => {
                            this.jobSearchArr.push(element.title);
                        });
                    }
                });
            },
            getTagData(ev) {

                if(ev){
                    return this.$store.dispatch(POST_API, {
                        data:{
                            popup: true
                    },
                    api: '/api/tagData'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.options = this.$store.getters.getResults.data;

                    }
                });
                }else{
                        this.options = [];
                }
                // this.submit();
            },
            onCLickRelatedSearch(title){
                this.keyword = title;
                this.submit();
                this.getJobSerachData();
                window.scrollTo(0,0);
            },
            submit() {
                console.log(this.category_id);
                // if(this.category_id.length > 0 && this.category_id != 'all-categories-jobs' && this.category_id != '' && this.category_id != []){
                    this.category_id.forEach(async element => {
                    await this.catArr.push(element.alias);
                    })
                    this.category_id = this.catArr.toString();

                    this.category_id = this.category_id.replaceAll(',', '--')
                    this.category_id = this.category_id.replaceAll('/', '*')
                // }

                if(this.category_id == ''){

                    this.category_id = 'all-categories-jobs';
                }
                console.log(this.company_id_filter,this.category_id);
                // if(this.company_id_filter.length > 0 && this.company_id_filter != 'all-companies-jobs' && this.company_id_filter != '' && this.company_id_filter != []){
                    this.company_id_filter.forEach(async element => {
                        console.log(element);
                    await this.companyArr.push(element.alias);
                    })
                    console.log(this.companyArr,'this.companyArr')
                    this.company_id_filter = this.companyArr.toString();

                    this.company_id_filter = this.company_id_filter.replaceAll(',', '--')
                    this.company_id_filter = this.company_id_filter.replaceAll('/', '*')
                // }

                if(this.company_id_filter == ''){

                    this.company_id_filter = 'all-companies-jobs';
                }
                console.log(this.company_id_filter,'this.company_id_filter',this.category_id);
                // if(this.tagArr.length > 0){
                    this.tagArr.forEach(element => {
                    this.tagrr.push(element.title);
                    })
                    this.tagArr1 = this.tagrr.toString();
                    
                    this.tagArr1 = this.tagArr1.replaceAll('-', '_')
                    this.tagArr1 = this.tagArr1.replaceAll(',', '--')
                    this.tagArr1 = this.tagArr1.replaceAll('/', '*')
                    this.tagArr1 = this.tagArr1.replaceAll(' ', '-')
                // }


                if(this.tagArr1 == ''){

                    this.tagArr1 = 'all-tag-jobs';
                }

                if(this.role_type_id == ''){
                    this.role_type_id = 'all-role-jobs';
                }

                if(this.location_id == ''){
                    this.location_id = 'all-location-jobs';
                }

                if(this.keyword == '' || this.keyword == 'undefined'){
                    this.keyword = 'all-title-jobs';
                }

                this.keyword = this.keyword.replaceAll(' ', '-')

                console.log(this.value);

                var salsel = this.value.join('-')



                window.location.replace('/singapore-full-time-jobs/'+this.role_type_id+'/'+this.category_id+'/'+this.company_id_filter+'/'+this.location_id+'/'+this.keyword +'/'+this.tagArr1+'/'+salsel);

                // this.$store.dispatch(POST_API, {

                //     data:{
                //         location: this.location_id,
                //         type: this.type,
                //         keyword: this.keyword,
                //         category: this.category_id,
                //         salary: this.value,
                //         daily: this.daily ? true: false,
                //         tagArr: this.tagArr,
                //         full_time: this.full_time ? true:false,
                //         job_type_id:this.role_type_id,
                //         page: this.currentPage,
                //         rowsPerPage:this.form.rowsPerPage,
                //     },
                //     api:'/api/all-jobs-listing'
                // })
                // .then(() => {
                //     if (this.$store.getters.containsErrors) {
                //         this.error_message = this.$store.getters.getErrors;
                //         this.showAlert();
                //         return [];
                //     } else {
                //         // this.location_id = this.$store.getters.getResults.pager.location;
                //         this.jobData = this.$store.getters.getResults.pageOfItems;
                //         this.totalRows = this.$store.getters.getResults.pager.totalItems;
                //         this.perPage = this.$store.getters.getResults.pager.pageSize;
                //         this.from = this.$store.getters.getResults.pager.from;
                //         this.to = this.$store.getters.getResults.pager.to;
                //         // return this.jobData;
                //     }
                // });
            },

            jobDetail(alias,id,job_alias,event){
                if (event) {
                    event.stopPropagation()
                }
                // window.location.replace('/all-jobs-detail/'+btoa(id));
                window.location.replace('/singapore-job-ad/'+alias+'/'+job_alias+'/'+btoa(id));


                // window.location.replace('/all-jobs-detail/'+btoa(id));

            },
            getLocation(){
                this.$store.dispatch(POST_API, {
                    data:{
                    },
                    api:'/api/weblocation'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.locations = this.$store.getters.getResults.data;
                        this.locations.forEach(element => {
                            if(element.location_names){
                                element.location_names_val = element.location_names.split(',');
                                element.alias_val = element.alias_name.split(',');
                                element.location_names_id = element.location_ids.split(',');
                            }
                        });
                    }
                });
            },
        },
         mounted(){
            this.minimum_val=0;
            this.maximum_val=22000;
            window.scrollTo(0,0);
            this.allSearchData = JSON.parse(localStorage.getItem('allSearchData')) ? JSON.parse(localStorage.getItem('allSearchData')) : '';
            this.category_id_string = this.$route.params.category_id;
            this.company_id_string = this.$route.params.company_id;

            this.tagString = this.$route.params.tag;
            this.salary = this.$route.params.salary;


             this.keyword = this.$route.params.keyword == 'all-title-jobs' || this.$route.params.keyword == '' || !this.$route.params.keyword ? '' : this.$route.params.keyword.split('-').join(' ');
             this.role_type_id = this.$route.params.role_type == 'all-role-jobs' || this.$route.params.role_type == '' || !this.$route.params.role_type ? '' : this.$route.params.role_type;
             this.location_id = this.$route.params.location == 'all-location-jobs' || this.$route.params.location == '' || !this.$route.params.location ? '' : this.$route.params.location;

            //  this.role_type_id = this.$route.params.role_type ? this.$route.params.role_type : '';
            //  this.location_id = this.$route.params.location ? this.$route.params.location : '';

            // this.category_id = this.allSearchData.category_id == 'all' || this.allSearchData.category_id == '' ? []: this.allSearchData.category_id ? this.allSearchData.category_id : [],
            // this.category_id_string = this.allSearchData.category_id == 'all' || this.allSearchData.category_id == '' ? '': this.allSearchData.category_id ? this.allSearchData.category_id : '',

            // this.keyword = this.allSearchData.keyword == 'all' || this.allSearchData.keyword == '' ? '' : this.allSearchData.keyword ? this.allSearchData.keyword : '',
            // this.location_id = this.allSearchData.location_id == 'all' || this.allSearchData.location_id == '' ? '': this.allSearchData.location_id ? this.allSearchData.location_id : '',
            // this.role_type_id = this.allSearchData.role_type_id == 'all' || this.allSearchData.role_type_id == '' ? '': this.allSearchData.role_type_id ? this.allSearchData.role_type_id : ''
            localStorage.removeItem('allSearchData')
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.getLocation();
            this.getjobRoleType();
            this.getJobSerachData();
            this.getDropdownData();
            this.getEmployers();

            // this.getjobsData();
            // this.getTagData();

            //document.title = "EL Connect - All Jobs Listing";


        }
    }
</script>


