import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=584bee63&scoped=true&"
import script from "./Index.vue?vue&type=script&lang=js&"
export * from "./Index.vue?vue&type=script&lang=js&"
import style0 from "./Index.vue?vue&type=style&index=0&id=584bee63&prod&scoped=true&lang=css&"
import style1 from "./Index.vue?vue&type=style&index=1&id=584bee63&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "584bee63",
  null
  
)

/* custom blocks */
import block0 from "./Index.vue?vue&type=custom&index=0&blockType=nav&class=pagination-box%20custom_pagination_border_radius"
if (typeof block0 === 'function') block0(component)
import block1 from "./Index.vue?vue&type=custom&index=1&blockType=b-tab&%3Atitle='Job%20Ads'&%40click=changeTabJobAds"
if (typeof block1 === 'function') block1(component)
import block2 from "./Index.vue?vue&type=custom&index=2&blockType=b-modal&ref=multiple-transaction-modal&title=Add%20Multiple%20transaction&hide-footer=true&modal-class=background_custom_class&content-class=common_model_header%20common_model_title"
if (typeof block2 === 'function') block2(component)
import block3 from "./Index.vue?vue&type=custom&index=3&blockType=b-modal&ref=coin-modal&title=Update%20ELC%20Credits&hide-footer=true&content-class=common_model_header%20common_model_title&modal-class=background_custom_class"
if (typeof block3 === 'function') block3(component)
import block4 from "./Index.vue?vue&type=custom&index=4&blockType=b-modal&ref=amount-modal&title=Update%20Amount&hide-footer=true&content-class=common_model_header%20common_model_title&modal-class=background_custom_class"
if (typeof block4 === 'function') block4(component)
import block5 from "./Index.vue?vue&type=custom&index=5&blockType=b-modal&ref=upload-modal&%3Atitle=modalTitle&hide-footer=true&content-class=common_model_header%20common_model_title&modal-class=background_custom_class"
if (typeof block5 === 'function') block5(component)
import block6 from "./Index.vue?vue&type=custom&index=6&blockType=b-modal&ref=transaction-images-modal&title=Transaction%20Images&hide-footer=true&content-class=common_model_header%20common_model_title&modal-class=background_custom_class"
if (typeof block6 === 'function') block6(component)
import block7 from "./Index.vue?vue&type=custom&index=7&blockType=b-modal&ref=transaction-modal&title=Transaction%20Details&hide-footer=true&content-class=common_model_header%20common_model_title&modal-class=background_custom_class"
if (typeof block7 === 'function') block7(component)

export default component.exports