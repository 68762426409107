import { render, staticRenderFns } from "./dpJobs.vue?vue&type=template&id=08aecacc&scoped=true&"
import script from "./dpJobs.vue?vue&type=script&lang=js&"
export * from "./dpJobs.vue?vue&type=script&lang=js&"
import style0 from "./dpJobs.vue?vue&type=style&index=0&id=08aecacc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08aecacc",
  null
  
)

export default component.exports