<template>
    <!-- Wrapper -->
<div id="wrapper wrapper_pdng">
<!-- <div class="back_arrow_icon new_bak_arr"> -->
    <!-- <span class="icon-material-outline-arrow-back" @click="$router.go(-1)"></span> -->
	<!-- <b-link class="icon-material-outline-arrow-back new_arrow_back" :to="{name: 'jobList'}"></b-link> -->

<!-- </div> -->
 <div id="breadcrumb-v2" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
            <b-breadcrumb
            :items="breadCrumb()"
            />
        </div>
 </div>
<!-- Titlebar
================================================== -->
<!-- <div class="single-page-header freelancer-header single_top_bk">
	<div class="single_gradient">
	<div class="container mx_width">
		<div class="row ">
			<div class="col-md-12">
				<div class="single-page-header-inner">
					<div class="left-side">
						<div class="header-image"><img :src="items.company_logo ? items.company_logo: webUrl+'assets/images/company-logo-placeholders.png'" alt=""></div>
						<div class="header-details">
							<h3 class="name_mrgn">{{ items.name | capitalize }} </h3>
							<h4 class="new_h4">Short Description</h4>
							<ul>
								 <li class="emp_li_fst"><div class="star-rating" data-rating="4.9" v-if="items.average_rating != null"></div></li> 
								<li><star-rating v-model="items.average_rating" v-bind:star-size="20" :show-rating="false"  v-if="items.average_rating != null"></star-rating></li>
								 <li><img class="flag" src="images/flags/de.svg" alt=""> {{ items.country_name }}</li>
								<li><div class="verified-badge-with-title" v-if="items.mobile_verified == 'yes' && items.email_verified == 'yes'">Verified</div></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	</div>
</div> -->

    <div class="bg_img_top new_bg_img_tp new_job_detail_full_dvz" v-bind:style="{backgroundImage: 'url('+( items.banner_image ? items.banner_image: webUrl+'assets/images/single-job.jpg' )+ ')'} " >
        <div class="grd_div inside_grd height_three_zero_one">
        <div class="container my_new_container">
            <div class="row">
			<div class="col-md-12">
				<div class="inrs_main">
					<div class="left-side updated_left_side_banner">
						<div class="header-image updated_header_image_banner updated_image_in_square_shape">
                            <viewer>
                                <img :src="items.company_logo ? items.company_logo: webUrl+'assets/images/company-logo-placeholders.png'" alt="">
                            </viewer>
                            </div>
						<div class="header-details updated_header_details_banner">
							<h1 class="name_mrgn header_detail_new_h1 new_daily_paid_jjobs">{{ items.name | capitalize }} </h1>
							<!-- <h4 class="new_h4" v-if="items.short_description">{{ items.short_description | capitalize }}</h4> -->
							<ul class="mobile_header_ul margin_top_12">
								 <!-- <li class="emp_li_fst" v-if="items.average_rating != null"><div class="star-rating" data-rating="4.9" ></div></li>  -->
								<!-- <li><star-rating v-model="items.average_rating" v-bind:star-size="20" :show-rating="false"  v-if="items.average_rating != null"></star-rating></li> -->
								<!--  <li><img class="flag" src="images/flags/de.svg" alt=""> {{ items.country_name }}</li> -->
								<li><div class="verified-badge-with-title" v-if="items.mobile_verified == 'yes' && items.email_verified == 'yes'">Verified</div></li>
							</ul>
						</div>
					</div>
					<!--<div class="right-side" v-if="min_salary && max_salary">
                        <div class="right_inner_contents">
						<div class="salary-box">
							<div class="salary-type" v-if="job_type == 'part-time'">Per Hour Rate</div>
							<div class="salary-type" v-else>Salary</div>
						<div class="salary-amount">${{min_salary}}-${{max_salary}}</div>
						</div>
                        </div>
					</div> -->
				</div>
			</div>
		</div>
        </div>
     
        
    </div>
    </div>






<!-- Page Content
================================================== -->
<div class="container mx_width my_new_container">
	<div class="row ">
		
		<!-- Content -->
		<div class="mobile_tab_padding_left_right_zero col-xl-8 col-lg-8 content-right-offset mobile_full_col emp_prf tab_max_width_and_flex">
			
			<!-- Page Content -->
			<div class="single-page-section" v-if="items.description">
				<h3 class="margin-bottom-25 font_weight_600">About Company</h3>
				<p v-html="items.description"></p>
			</div>

			<!-- Boxed List -->
			<div class="boxed-list  desktop_margin_top_zero" v-if="company_jobs.length > 0">
				<div class="boxed-list-headline">
					<h3><i class="icon-material-outline-business-center"></i> Full Time Jobs </h3>
                    
				</div>

				<div class="listings-container compact-list-layout position_listing">
					
					<a href="#" @click="jobDetail(encrypt(company_job.jobId),$event)" class="job-listing" v-for="(company_job, index) in company_jobs" v-if="index <= 1">

						<div class="job-listing-details">

							<div class="job-listing-description">
                                <div class="title_and_stars">
								<h3 class="job-listing-title new_all_job_title suggested_title">{{company_job.job_title}}</h3>
                                <div class="star_icon joblist_star_icon desktop_flex">
                                    <ul class="inside_job_ul desktop_margin_bottom_zero">
                                    <b-form-checkbox :value="company_job.jobId" v-model="bookmarkjobs" buttons @click.native="bookmarkCurrentJob(company_job.jobId,$event)" :disabled="true" class="desktop_padding_left_zero desktop_margin_bottom_zero"></b-form-checkbox>
                                    </ul>
                                </div>
								<!-- <div class="job-listing-footer">
									<ul>
										<li><i class="icon-material-outline-location-on"></i>{{company_job.job_address == 'Other' ? company_job.other_location : company_job.job_address | capitalize}}</li>
										<li><i class="icon-material-outline-business-center"></i> {{company_job.job_type | customizeRoleType | capitalize}}</li>
										<li><i class="icon-material-outline-access-time"></i> {{company_job.posted_date | dateTimeAgo | capitalize}}</li>
									</ul>
								</div> -->
							</div>
                            </div>
						</div>
                        <!-- <div class="star_icon compact_star_icon">
                            <ul>
                            <b-form-checkbox :value="company_job.jobId" v-model="bookmarkjobs" buttons @click.native="bookmarkCurrentJob(company_job.jobId,$event)" :disabled="true"></b-form-checkbox>
                            </ul>
                        </div> -->
                        <div class="job-listing-footer desktop_display_inline_width">
                            <ul>
                                <li class="black_job_color"><i class="icon-material-outline-location-on common_i_color"></i>{{company_job.job_address == 'Other' ? company_job.other_location : company_job.job_address | capitalize}}</li>
                                <li class="black_job_color"><i class="icon-material-outline-business-center common_i_color"></i> {{company_job.job_type | customizeRoleType | capitalize}}</li>
                                <li class="black_job_color"><i class="icon-material-outline-access-time common_i_color"></i> {{company_job.posted_date | dateTimeAgo | capitalize}}</li>
                            </ul>
						</div>
						<!-- <span class="bookmark-icon">
                            <b-form-checkbox :value="company_job.jobId" v-model="bookmarkjobs" buttons @click.native="bookmarkCurrentJob(company_job.jobId,$event)" :disabled="true"></b-form-checkbox>
                        </span> -->
					</a>
				</div>

			</div> 
            <div class="details_list_job browse_all_listing_button" v-if="company_jobs_count > 2">
                <b-link :to="{name: 'employerJobList', params: {id: encrypt(items.company_id)}}" target=_blank class="headline-link" v-if="company_jobs_count > 2">Browse All Listing</b-link>
            </div>
            <div class="boxed-list  desktop_margin_top_zero" v-if="company_dp_jobs.length > 0">
				<div class="boxed-list-headline">
					<h3><i class="icon-material-outline-business-center"></i> Part Time Jobs </h3>
                    
				</div>

				<div class="listings-container compact-list-layout position_listing">
					
					<a href="#" @click="jobDetailDP(encrypt(company_job.jobId),$event)" class="job-listing" v-for="(company_job, index) in company_dp_jobs" v-if="index <= 1">

						<div class="job-listing-details">

							<div class="job-listing-description">
                                <div class="title_and_stars">
								<h3 class="job-listing-title new_all_job_title suggested_title">{{company_job.job_title}}</h3>
								<!-- <div class="job-listing-footer">
									<ul>
										<li><i class="icon-material-outline-location-on"></i>{{company_job.job_address == 'Other' ? company_job.other_location : company_job.job_address | capitalize}}</li>
										<li><i class="icon-material-outline-business-center"></i> {{company_job.job_type | customizeRoleType | capitalize}}</li>
										<li><i class="icon-material-outline-access-time"></i> {{company_job.posted_date | dateTimeAgo | capitalize}}</li>
									</ul>
								</div> -->
							</div>
                            </div>
						</div>
                        <!-- <div class="star_icon compact_star_icon">
                            <ul>
                            <b-form-checkbox :value="company_job.jobId" v-model="bookmarkjobs" buttons @click.native="bookmarkCurrentJob(company_job.jobId,$event)" :disabled="true"></b-form-checkbox>
                            </ul>
                        </div> -->
                        <div class="job-listing-footer desktop_display_inline_width">
                            <ul>
                                <li class="black_job_color"><i class="icon-material-outline-location-on common_i_color"></i>{{company_job.job_address == 'Other' ? company_job.other_location : company_job.job_address | capitalize}}</li>
                                <li class="black_job_color"><i class="icon-material-outline-business-center common_i_color"></i> {{company_job.job_type | customizeRoleType | capitalize}}</li>
                                <li class="black_job_color"><i class="icon-material-outline-access-time common_i_color"></i> {{company_job.posted_date | dateTimeAgo | capitalize}}</li>
                            </ul>
						</div>
						<!-- <span class="bookmark-icon">
                            <b-form-checkbox :value="company_job.jobId" v-model="bookmarkjobs" buttons @click.native="bookmarkCurrentJob(company_job.jobId,$event)" :disabled="true"></b-form-checkbox>
                        </span> -->
					</a>
				</div>
            </div>
            <div class="details_list_job browse_all_listing_button" v-if="company_dp_jobs_count > 2">
                <b-link :to="{name: 'empDailyjobList', params: {id: encrypt(items.company_id)}}" target=_blank class="headline-link" v-if="company_dp_jobs_count > 2">Browse All Listing</b-link>
            </div>

            
			<!-- Boxed List / End -->

			<!-- Boxed List -->
			<!-- <div class="boxed-list margin-bottom-60">
				<div class="boxed-list-headline">
					<h3><i class="icon-material-outline-thumb-up"></i> Reviews</h3>
				</div>
				<ul class="boxed-list-ul">
					<li>
						<div class="boxed-list-item">
							<div class="item-content">
								<h4>Doing things the right way <span>Anonymous Employee</span></h4>
								<div class="item-details margin-top-10">
									<div class="star-rating" data-rating="5.0">
										<span><i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
									</span>
									</div>
									<div class="detail-item"><i class="icon-material-outline-date-range"></i> August 2019</div>
								</div>
								<div class="item-description">
									<p>Great company and especially ideal for the career-minded individual. The company is large enough to offer a variety of jobs in all kinds of interesting locations. Even if you never change roles, your job changes and evolves as the company grows, keeping things fresh.</p>
								</div>
							</div>
						</div>
					</li>
					<li>
						<div class="boxed-list-item">
							<div class="item-content">
								<h4>Outstanding Work Environment <span>Anonymous Employee</span></h4>
								<div class="item-details margin-top-10">
									<div class="star-rating" data-rating="5.0">
									<span><i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
										<i class="fa fa-star checked emp_stars" aria-hidden="true"></i>
									</span></div>
									<div class="detail-item"><i class="icon-material-outline-date-range"></i> May 2019</div>
								</div>
								<div class="item-description">
									<p>They do business with integrity and rational thinking. Overall, it's an excellent place to work, with products that are winning in the marketplace.</p>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div> -->

				

				<!-- <div class="centered-button margin-top-35">
					<b-button variant="success" class="text-uppercase x-md btn-shadow" v-if="items.ft_account_status == 'pending'" @click="changeUserStatus('approve', items.user_id)">Approve</b-button>&nbsp;
					<b-button variant="danger" class="text-uppercase x-md btn-shadow" v-if="items.ft_account_status == 'pending'" @click="openModel('reject', items.user_id)">Reject</b-button>
					
					 <a href="#small-dialog"  class="popup-with-zoom-anim button button-sliding-icon">Leave a Review <i class="icon-material-outline-arrow-right-alt review_btn"></i></a> 

				</div> -->

			<!-- Boxed List / End -->

		</div>
		

		<!-- Sidebar -->
		<div class="mobile_tab_padding_left_right_zero col-xl-4 col-lg-4 emp_prf tab_max_width_and_flex">
			<div class="sidebar-container mobile_margin_bottom_zero tab_margin_bottom_zero desktop_margin_bottom_zero">
				<!-- <div class="sidebar-widget">
					<a href="#small-dialog" class="apply-now-button popup-with-zoom-anim margin-bottom-50 msg_btn"><i class="fa fa-envelope new_letter"></i>Send a Message </a>
				</div>
				<div class="sidebar-widget nw_side" v-if="items.address">
					<h3>Company Address</h3>
					{{items.address}}
					
					 <div id="single-job-map-container">
						<div id="singleListingMap" data-latitude="52.520007" data-longitude="13.404954" data-map-icon="im im-icon-Hamburger"></div>
						<a href="#" id="streetView">Street View</a>
					</div>
				</div> 
				<div class="sidebar-widget nw_side" v-if="items.registration_id">
					<h3>Company BRN/UEN</h3>
					{{items.registration_id}}
				</div>  -->

				<!-- Widget -->
				<!-- <div class="sidebar-widget" v-if="social_profile.length > 0">
					<h3>Social Profiles</h3>
					<div class="freelancer-socials margin-top-25">
						<ul>
                            <li><a :href="social_profile[0].url" target="_blank"  title="Visit Website" data-tippy-placement="top"><i class="icon-brand-dribbble"></i></a></li>
                            <li><a :href="social_profile[1].url" target="_blank"  title="Visit Facebook" data-tippy-placement="top"><i class="icon-brand-facebook-f"></i></a></li>
                            <li><a :href="social_profile[2].url" target="_blank"  title="Visit Google Plus" data-tippy-placement="top"><i class="icon-brand-google-plus-g"></i></a></li>
                            <li><a :href="social_profile[3].url" target="_blank"  title="Visit Twitter" data-tippy-placement="top"><i class="icon-brand-twitter"></i></a></li>
                            <li><a :href="social_profile[4].url" target="_blank"  title="Visit LinkedIn" data-tippy-placement="top"><i class="icon-brand-linkedin-in"></i></a></li>
						</ul>
					</div>
				</div> -->
                <div class="sidebar-widget common_box_border margin-bottom-25 ">
                <div class="job-overview">
                    <div class="job-overview-headline common_right_box_heading_padding font_weight_600">Company Details</div>
                        <div class="job-overview-inner">
                            <ul>
                                <li v-if="items.address"><i class="icon-material-outline-email"></i><span>Company Address</span><h5>{{items.address}}</h5></li>
                                <li v-if="items.registration_id"><i class="icon-material-outline-business-center"></i><span>Company BRN/UEN</span><h5>{{items.registration_id}}</h5></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- <div class="sidebar-widget margin-bottom-25" v-if="social_profile.length > 0 ? social_profile[0].url || social_profile[1].url ||  social_profile[2].url||  social_profile[3].url || social_profile[4].url : false">
					<h3 class="font_weight_600">Company Social Profiles</h3>
					<div class="freelancer-socials margin_top_12">
						<ul class="new_social_share_emp">
                            <li v-if="social_profile[0].url"><a :href="social_profile[0].url" target="_blank"  title="Visit Website" data-tippy-placement="top"><i class="icon-brand-dribbble"></i></a></li>
                            <li v-if="social_profile[1].url"><a :href="social_profile[1].url" target="_blank"  title="Visit Facebook" data-tippy-placement="top"><i class="icon-brand-facebook-f"></i></a></li>
                            <li v-if="social_profile[2].url"><a :href="social_profile[2].url" target="_blank"  title="Visit Google Plus" data-tippy-placement="top"><i class="icon-brand-google-plus-g"></i></a></li>
                            <li v-if="social_profile[3].url"><a :href="social_profile[3].url" target="_blank"  title="Visit LinkedIn" data-tippy-placement="top"><i class="icon-brand-linkedin-in"></i></a></li>
							<li v-if="social_profile[4].url"><a :href="social_profile[4].url" target="_blank"  title="Visit Twitter" data-tippy-placement="top"><i class="icon-brand-twitter"></i></a></li>
						</ul>
					</div>
				</div> -->
                <div class="sidebar-widget margin-top-25" v-if="social_profile.length > 0 ? social_profile_website_url || social_profile_fb_url ||  social_profile_link_url || social_profile_twitter_url : false">
					<h3 class="font_weight_600">Company Social Profiles</h3>
					<div class="freelancer-socials margin_top_12">
						<ul class="new_social_share_emp">
                            <li v-if="social_profile_website_url"><a :href="social_profile_website_url" target="_blank"  title="Visit Website" data-tippy-placement="top"><i class="icon-brand-dribbble"></i></a></li>
                            <li v-if="social_profile_fb_url"><a :href="social_profile_fb_url" target="_blank"  title="Visit Facebook" data-tippy-placement="top"><i class="icon-brand-facebook-f"></i></a></li>
                            <!-- <li v-if="social_profile_google_url"><a :href="social_profile_google_url" target="_blank"  title="Visit Google Plus" data-tippy-placement="top"><i class="icon-brand-google-plus-g"></i></a></li> -->
                            <li v-if="social_profile_link_url"><a :href="social_profile_link_url" target="_blank"  title="Visit LinkedIn" data-tippy-placement="top"><i class="icon-brand-linkedin-in"></i></a></li>
							<li v-if="social_profile_twitter_url"><a :href="social_profile_twitter_url" target="_blank"  title="Visit Twitter" data-tippy-placement="top"><i class="icon-brand-twitter"></i></a></li>
						</ul>
					</div>
				</div>
				<!-- <div class="sidebar-widget margin-bottom-25" v-if="social_profile.length > 0 ? social_profile[0].url || social_profile[1].url ||  social_profile[2].url||  social_profile[3].url || social_profile[4].url : false">
					<h3 class="font_weight_600">Company Social Profiles</h3>
					<div class="freelancer-socials margin_top_12">
						<ul class="new_social_share_emp">
                            <li v-if="social_profile[0].url"><a :href="social_profile[0].url" target="_blank"  title="Visit Website" data-tippy-placement="top"><i class="icon-brand-dribbble"></i></a></li>
                            <li v-if="social_profile[1].url"><a :href="social_profile[1].url" target="_blank"  title="Visit Facebook" data-tippy-placement="top"><i class="icon-brand-facebook-f"></i></a></li>
                            <li v-if="social_profile[2].url"><a :href="social_profile[2].url" target="_blank"  title="Visit Google Plus" data-tippy-placement="top"><i class="icon-brand-google-plus-g"></i></a></li>
                            <li v-if="social_profile[3].url"><a :href="social_profile[3].url" target="_blank"  title="Visit LinkedIn" data-tippy-placement="top"><i class="icon-brand-linkedin-in"></i></a></li>
							<li v-if="social_profile[4].url"><a :href="social_profile[4].url" target="_blank"  title="Visit Twitter" data-tippy-placement="top"><i class="icon-brand-twitter"></i></a></li>
						</ul>
					</div>
				</div> -->
                <!-- <div class="sidebar-widget mobile_last_sidebar_widget" v-if="cvUrl != ''">
					<h3>Attachments</h3>
					<div class="attachments-container">
                        <a :href="cvUrl" target="_blank">
                            <div class="attachment-box ripple-effect" v-show="showCV">
                                <span>ACRA</span>
                                <i>PDF</i>
                            </div>
                        </a>
					</div>
				</div> -->
				<!-- Sidebar Widget -->
				 <div class="sidebar-widget tab_margin_bottom_zero desktop_margin_bottom_zero new_bookmark_share_btn margin-bottom-25 mobile_margin_bottom_zero">
					<h3 class="font_weight_600">Bookmark</h3>

					<!-- <button class="bookmark-button margin-bottom-25">
						<span class="bookmark-icon"></span>
						<span class="bookmark-text">Bookmark</span>
						<span class="bookmarked-text">Bookmarked</span>
					</button> -->
                    <b-form-checkbox buttons class="bookmark-button desktop_margin_bottom_zero" id="bookmark" v-model="bookmarkEmployer" @click.native="bookmarkCurrentEmployer($event)" :disabled="true">
                            <span class="bookmark-icon"></span>
                            <span class="bookmarked-text" v-if="bookmarkEmployer == this.id">Bookmarked</span>
                            <span class="bookmark-text" v-if="bookmarkEmployer == false">Bookmark</span>
                    </b-form-checkbox>
					
                    <!-- <b-form-checkbox :value="this.id" v-model="bookmarkEmployer" buttons @click.native="bookmarkCurrentEmployer($event)" :disabled="true"></b-form-checkbox> -->
				
					<!-- <div class="copy-url">
						<input id="copy-url" type="text" value="" class="with-border">
						<button class="copy-url-button ripple-effect" @click="copyUrl()" data-clipboard-target="#copy-url" title="Copy to Clipboard" data-tippy-placement="top"><i class="icon-material-outline-file-copy"></i></button>
					</div>

					<div class="share-buttons margin-top-25">
						<div class="share-buttons-trigger"><i class="icon-feather-share-2"></i></div>
						<div class="share-buttons-content">
							<span>Interesting? <strong>Share It!</strong></span>
							<ul class="share-buttons-icons">
								<li><a href="#" data-button-color="#3b5998" title="Visit Facebook" data-tippy-placement="top"><i class="icon-brand-facebook-f"></i></a></li>
								<li><a href="#" data-button-color="#1da1f2" title="Visit Twitter" data-tippy-placement="top"><i class="icon-brand-twitter"></i></a></li>
								<li><a href="#" data-button-color="#dd4b39" title="Visit Google Plus" data-tippy-placement="top"><i class="icon-brand-google-plus-g"></i></a></li>
								<li><a href="#" data-button-color="#0077b5" title="Visit LinkedIn" data-tippy-placement="top"><i class="icon-brand-linkedin-in"></i></a></li>
							</ul>
						</div>
					</div> -->
				</div> 

			</div>
		</div>
	</div>
</div>


<!-- Spacer -->
<!-- <div class="margin-top-15"></div> -->
<!-- Spacer / End-->

</div>
<!-- Wrapper / End -->



</template>

<script>

import { POST_API } from '../../store/actions.type'
import moment from "moment";
import StarRating from 'vue-star-rating';


export default {

    data() {
        return {
            fields: [
                { key: 'name', label: 'NAME & EMAIL', sortable: true },
                { key: 'mobile_number', label: 'MOBILE NUMBER', sortable: true   },
                { key: 'created_at', label: 'CREATED ON', sortable: true   },
                { key: 'status', label: 'STATUS', sortable: true },
                { key: 'toggleAction', label: 'ACTION', }
            ],
            id: atob(this.$route.params.id),
            pager: {},
            pageOfItems: [],
			company_jobs:[],
            company_dp_jobs:[],
            bookmarkVal:'',
            bookmarkjobs:[],
            bookmarkEmpVal:'',
			bookmarkEmployer:[],
            items:[],
            social_profile:[],
            social_profile_website_url:'',
            social_profile_fb_url:'',
            social_profile_google_url:'',
            social_profile_link_url:'',
            social_profile_twitter_url:'',
            totalRows: 0,
            from: '',
            to: '',
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 75, 100],
            sortBy: null,
            sortDirection: 'desc',
            filter: '',
            status: 'active',
            sortDesc: false,
            webUrl: process.env.VUE_APP_URL,
            form:{
                rowsPerPage:25,
            },
			rejection_remark:'',
			rejected_by: '',
			rejected_user: '',
            noClose:true,
            showCV:'',
            cvUrl:'',
            company_jobs_count:'',
            company_dp_jobs_count:'',
        }
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        moment: function(date) {
            return moment(date).utc().format('DD MMM YYYY')
        },
		dateTimeAgo: function(value) {
                var m = moment(value);  // or whatever start date you have
                // var today = moment().startOf('day');
                var today = moment();
                    var days = Math.round(moment.duration(today - m).asDays());
                if(days > 0){
                    return days +' Days Ago'
                }else{
                    return 'today'
                }
                // if (!value) return ''
                // const dateTimeAgo = moment(value).fromNow();
                // return dateTimeAgo
		    },
           
		customizeRoleType: function(value) {
			if (!value) return ''
			var i, frags = value.split('-');
			for (i=0; i<frags.length; i++) {
				frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
			}
			return frags.join(' ');
		},
    },
	components: {
      StarRating
    },
    methods:{
        breadCrumb(){
                var item = [{
                    to:{name:'jobList'},
                    text: 'Full Time Jobs',
                },{
                    to:null,
                    text: 'View Profile'
                }];
                return item;
        },
		showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            });
        },
        async copyURL() {
                    try {
                       let copyText = document.querySelector("#copy-url");
                        copyText.select();
                        document.execCommand("copy");
                        
                        Snackbar.show({
                            text: 'Copied to clipboard!',
                        }); 
                        // alert('Copied to Clipboard.');
                    } catch($e) {

                        console.log($e);
                        
                        // alert('Cannot copy');
                    }
              },
        getEmployers() {
            return this.$store.dispatch(POST_API, {
                    data:{
                        id:this.id,
                        user_id: this.form.user_id,
                        page: this.currentPage,
                        keyword: this.filter,
                        rowsPerPage:this.form.rowsPerPage,
                        sortBy: this.sortBy ? this.sortBy: 'id',
                        sortDirection:this.sortDesc ? 'desc' : 'asc',
                    },
                    api: '/api/employer-profile-view'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        return [];
                    } else {
                        var checkedJob = [];
                        var checked = [];


                        this.items = this.$store.getters.getResults.company;
                        this.company_jobs = this.$store.getters.getResults.company_jobs;
                        this.company_jobs_count = this.$store.getters.getResults.company_jobs_count;
                        this.company_dp_jobs = this.$store.getters.getResults.company_dp_jobs;
                        this.company_dp_jobs_count = this.$store.getters.getResults.company_dp_jobs_count;
                        console.log(this.company_dp_jobs);
                        this.social_profile = this.$store.getters.getResults.social_profile;
                        if(this.social_profile.length > 0){
							this.social_profile.forEach(element => {
								if(element.account_type == 'website'){
									this.social_profile_website_url = element.url;
								}
								if(element.account_type == 'fb'){
									this.social_profile_fb_url = element.url;
								}
								if(element.account_type == 'google'){
									this.social_profile_google_url = element.url;
								}
								if(element.account_type == 'link'){
									this.social_profile_link_url = element.url;
								}
								if(element.account_type == 'twitter'){
									this.social_profile_twitter_url = element.url;
								}
								
							});
						}
                        this.showCV = (this.$store.getters.getResults.company.acra != '' && this.$store.getters.getResults.company.acra != null) ? true : false;
                        this.cvUrl = (this.$store.getters.getResults.company.acra != '' && this.$store.getters.getResults.company.acra != null) ? this.$store.getters.getResults.company.acra : '';
                        $.each(this.company_jobs, function(key, value) {
                        if(value.bookmark =='yes'){
                            checkedJob.push (value.jobId );
                        }
                        });
                        this.bookmarkjobs = checkedJob;

						if(this.items.bookmark =='yes'){
                            checked.push (this.items.bookmark_to);
							$('#bookmark').parent().addClass('bookmarked');
                        }
                        this.bookmarkEmployer = checked;

                        $.each(this.company_dp_jobs, function(key, value) {
                        if(value.bookmark =='yes'){
                            checkedJob.push (value.jobId );
                        }
                        });
                        this.bookmarkjobs = checkedJob;
                        // this.totalRows = this.$store.getters.getResults.pager.totalItems;
                        // this.perPage = this.$store.getters.getResults.pager.pageSize;
                        // this.from = this.$store.getters.getResults.pager.from;
                        // this.to = this.$store.getters.getResults.pager.to;
                        // return this.items;
                    }
                });
        },
		bookmarkCurrentEmployer(event){
                if (event) {
                event.stopPropagation()
                }
                return this.$store.dispatch(POST_API, {
                        data:{
                            bookmark_to: this.id,
                            bookmark_by: this.form.user_id
                    },
                    api: '/api/bookmark-user'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.bookmarkEmpVal = this.$store.getters.getResults.bookmarkStatus
                        var message = this.$store.getters.getResults.message
                        if (this.bookmarkEmpVal == 'yes') {
                            this.bookmarkEmployer = true;
							$('#bookmark').parent().addClass('bookmarked');
                        } else {
							$('#bookmark').parent().removeClass('bookmarked');
                            this.bookmarkEmployer = false;
                        }
                        this.showSuccessAlert(message);
                        this.getEmployers()
                    }
                });
            },
        bookmarkCurrentJob(job_id,event){
                if (event) {
                event.stopPropagation()
                }
                return this.$store.dispatch(POST_API, {
                        data:{
                            job_id: job_id,
                            user_id: this.form.user_id
                    },
                    api: '/api/bookmark-job'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.bookmarkVal = this.$store.getters.getResults.bookmarkStatus
                        var message = this.$store.getters.getResults.message
                        if (this.bookmarkVal == 'yes') {
                            this.bookmarkjobs = true;
                        } else {
                            this.bookmarkjobs = false;
                        }
                        this.showSuccessAlert(message);
                        this.getEmployers()
                    }
                });
            },
        jobDetail(id,event){
            if (event) {
            event.stopPropagation()
            }
            console.log(id)
            window.location.replace('/v2/jobseeker/ft-jobdetail/'+ id);

        },
        jobDetailDP(id,event){
            if (event) {
                event.stopPropagation()
            }
            console.log(id)
            window.location.replace('/v2/jobseeker/dp-jobdetail/'+ id);
        },
    },
    mounted(){
         window.scrollTo(0,0);
         $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
        }, "fast");
        //document.title = "EL Connect - Jobseeker - Employer Profile";
        this.form.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
        this.getEmployers();
        // $('#copy-url').val(window.location.href);
		 $('#copy-url').val(process.env.VUE_APP_URL + "job?jobId=" + btoa(this.id));
    }
}
</script>
