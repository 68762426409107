<template>
  <div>
    <div class="row">
      <div class="container my_new_container">
        <!-- Dashboard Headline -->
        <div class="dashboard-headline">
          <h1>Jobseekers</h1>
          <!-- <b-link class="btn green-border-btn" :to="{name:'jobseekers-ft-create'}">Add</b-link> -->

          <!-- Breadcrumbs -->
          <nav id="breadcrumbs" class="dark email_tmp_tbl_nav">
            <ul>
              <li><a href="#">Home</a></li>
              <li>Jobseekers</li>
            </ul>
          </nav>
        </div>
        <div class="row">
          <div
            class="col-xl-6 dual_admin_emp_btn col-md-6 mobile_tab_max_width_flex search_common_margin_bottom_28 mobile_three_btn_grd"
          >
            <b-link
              class="common_small_font_thirteen btn green-border-btn add_btn_admin_e desktop_margin_left_zero"
              :to="{ name: 'jobseekers-ft-create' }"
              v-if="show('add worker')"
              >Add</b-link
            >
            <!-- <button
              class="common_small_font_thirteen btn green-border-btn add_btn_admin_e"
              @click="openMultipleTransactionModel()"
              style="background-color: #4c8bf5; color: white"
            >
              Add Multiple Transaction
            </button> -->
            <!-- <button class="btn green-border-btn add_btn_admin_e desktop_margin_left_zero desktop_margin_right_zero" @click="exportUser()" style="background-color: #4c8bf5;color:white">Export</button>
                        <button class="btn green-border-btn add_btn_admin_e desktop_margin_right_zero" @click="exportUserMobile()" style="background-color: #4c8bf5;color:white">Export Mobile Number</button> -->
            <button
              class="common_small_font_thirteen btn green-border-btn add_btn_admin_e desktop_margin_left_zero desktop_margin_right_zero"
              @click="exportUserAllMobile('all_records')"
              v-if="show('export worker')"
              style="background-color: #4c8bf5; color: white"
            >
              Export
            </button>
            <button
              class="common_small_font_thirteen btn green-border-btn add_btn_admin_e desktop_margin_right_zero"
              @click="exportUserAllMobile('mobile_number')"
              style="background-color: #4c8bf5; color: white"
              v-if="show('export worker')"
            >
              Export Mobile Number
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Page Content
        ================================================== -->
    <div class="tab-section jobseeker_tab show_filter_arrow">
      <!-- <b-tabs class="my_tab_job remove_bx"> -->
      <b-tabs class="my_tab_job tab_header_action_th common_small_tab_font">
        <b-tab :title="'ALL'" @click="changeAllStatus">
          <div class="row new_rw">
            <div
              class="col-xl-4 col-md-4 admin_listing_search mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
            >
              <div class="search-box custom_search_admin desktop_margin_top_zero">
                <!-- <b-input-group> -->
                <b-input-group-prepend>
                  <b-form-input
                    id="searchKeywordAll"
                    @keyup.enter.native="filterAll = searchKeywordAll"
                    :value="searchKeywordAll"
                    @change="(v) => (searchKeywordAll = v)"
                    placeholder="Search by Name, Email, Mobile, Nric"
                    class="common_small_font_thirteen"
                    :style="{ lineHeight: '31px !important', padding: '0 10px !important' }"
                  ></b-form-input>
                  <b-button class="btn points_events" @click="filterAll = searchKeywordAll"
                    ><i class="fa fa-search common_small_font_thirteen"></i
                  ></b-button>
                </b-input-group-prepend>
                <!-- </b-input-group> -->
              </div>
            </div>
            <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex search_common_margin_bottom_28">
              <!-- <span>DP Status:</span> -->
              <b-form-select
                v-model="userAccountStatusFilterAll"
                @change="getJobseekersOnStatusChange"
                class="common_small_font_thirteen common_select_focus common_padding_left_right_20 common_border_search height_unset desktop_margin_bottom_zero"
              >
                <option value="all">Account Status</option>
                <option :value="ac_status" v-for="ac_status in accountStatus">
                  {{ ac_status | capitalize }}
                </option>
              </b-form-select>
            </div>
            <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex search_common_margin_bottom_28">
              <!-- <span>DP Status:</span> -->
              <b-form-select
                v-model="userDPAccountStatusFilterAll"
                @change="getJobseekersOnStatusChange"
                class="common_small_font_thirteen common_select_focus common_padding_left_right_20 common_border_search height_unset desktop_margin_bottom_zero"
              >
                <option value="all">DP Status</option>
                <option :value="dp_state" v-for="dp_state in dpStatusAll">
                  {{ dp_state | capitalize }}
                </option>
              </b-form-select>
            </div>
            <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex search_common_margin_bottom_28">
              <!-- <span>DP Status:</span> -->
              <b-form-select
                v-model="userFTAccountStatusFilterAll"
                @change="getJobseekersOnStatusChange"
                class="common_small_font_thirteen common_select_focus common_padding_left_right_20 common_border_search height_unset desktop_margin_bottom_zero"
              >
                <option value="all">FT Status</option>
                <option :value="ft_state" v-for="ft_state in ftStatusAll">
                  {{ ft_state | capitalize }}
                </option>
              </b-form-select>
            </div>
            <!-- <div
              class="big_screen_calendr_font search_common_margin_bottom_28 mobile_tab_max_width_flex select-service-box date-picker-field clearfix col-xl-4 col-md-4 calender_z_index_9 calender_in_mobile new_calender_section"
            >
              <label
                class="new_style_label required_sign required desktop_margin_bottom_zero common_small_font_thirteen"
                >Date Range</label
              >
              <div class="">
                <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">
                  <date-range-picker
                    ref="picker"
                    :minDate="minDate"
                    :opens="center"
                    :locale-data="locale"
                    :auto-apply="auto"
                    v-model="dateRange"
                    @update="dateFilterFunction"
                    :ranges="range"
                    class="common_width_100 common_small_font_thirteen manage_job_date_claneder common_date_range_picker_new"
                  >
                    <div slot="input" slot-scope="picker">
                      {{ picker.startDate | datePicker1 }} - {{ picker.endDate | datePicker1 }}
                    </div>
                  </date-range-picker>
                </b-form-group>
              </div>
            </div> -->
            <div
              class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
            >
              <div
                class="search-box custom_search_admin desktop_margin_top_zero start_end_calender"
              >
                <b-form-input
                  id="input-1"
                  type="date"
                  placeholder=""
                  v-model="dateRange.startDate"
                  class="desktop_margin_bottom_zero"
                  :style="{
                    height: '33px !important',
                    lineHeight: '31px !important',
                    padding: '0 10px !important',
                    fontSize: '13px !important',
                  }"
                ></b-form-input>
              </div>
            </div>
            <div
              class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
            >
              <div
                class="search-box custom_search_admin desktop_margin_top_zero start_end_calender"
              >
                <!-- <datepicker v-model="end_date" @input="changeTab()" :format="customFormatterTwo" id="scheduled_post" class=""></datepicker> -->
                <b-form-input
                  id="input-1"
                  type="date"
                  placeholder=""
                  v-model="dateRange.endDate"
                  class="desktop_margin_bottom_zero"
                  :style="{
                    height: '33px !important',
                    lineHeight: '31px !important',
                    padding: '0 10px !important',
                    fontSize: '13px !important',
                  }"
                ></b-form-input>
              </div>
            </div>

            <div
              class="big_screen_calendr_font search_common_margin_bottom_28 mobile_tab_max_width_flex select-service-box date-picker-field clearfix col-xl-4 col-md-4 calender_z_index_9 calender_in_mobile new_calender_section"
            >
              <label
                class="new_style_label required_sign required desktop_margin_bottom_zero common_small_font_thirteen"
                >Last Login Date Range</label
              >
              <div class="">
                <b-form-group
                  id="input-group-6"
                  class="desktop_margin_bottom_zero"
                  :style="{
                    height: '32px !important',
                    lineHeight: '31px !important',
                    padding: '0 10px !important',
                  }"
                >
                  <date-range-picker
                    ref="picker"
                    :minDate="minDate"
                    :opens="center"
                    :locale-data="locale"
                    :auto-apply="auto"
                    v-model="dateRangeLastLogin"
                    @update="dateFilterFunction"
                    :ranges="range"
                    class="last_login_date_range_calender common_width_100 common_small_font_thirteen manage_job_date_claneder common_date_range_picker_new"
                    style="height: 31px; padding: 0px"
                  >
                    <div slot="input" slot-scope="picker" style="padding: 0px !important">
                      {{ picker.startDate | datePicker1 }} - {{ picker.endDate | datePicker1 }}
                    </div>
                  </date-range-picker>
                </b-form-group>
              </div>
            </div>

            <div class="col-xl-8 col-md-8 search_common_margin_bottom_28 mobile_tab_max_width_flex">
              <!-- <b-form-group id="input-group-6" class="ss desktop_margin_bottom_zero"> -->
              <div class="jobseeker_btn">
                <button
                  class="common_small_font_thirteen customize_filter_btn btn green-border-btn line_height_22 desktop_margin_right_zero desktop_margin_left_zero"
                  @click="openFilter()"
                  style="background-color: #4c8bf5; color: white"
                >
                  Customize filter
                </button>
                <button
                  class="ml-3 mobile_margin_left_10 common_small_font_thirteen customize_filter_btn button ripple-effect search_all_red_btn daily_deployment_red_btn"
                  @click="dateFilterFunction()"
                  style="background-color: #4c8bf5; color: white"
                >
                  Search
                </button>
              </div>
              <!-- </b-form-group> -->
              <b-button
                v-if="
                  filterAll ||
                  searchKeywordAll ||
                  userAccountStatusFilterAll != 'all' ||
                  userDPAccountStatusFilterAll != 'all' ||
                  userFTAccountStatusFilterAll != 'all' ||
                  dateRange.startDate != start_date_filter ||
                  dateRange.endDate != end_date_filter ||
                  dateRangeLastLogin.startDate != start_date_filter_login ||
                  dateRangeLastLogin.endDate != end_date_filter_login
                "
                @click="resetFilterAll()"
                class="ml-3 new_reset_text mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0"
                style="background-color: red; margin-top: 20px"
                ><span class="mobile_reset">Reset filters</span></b-button
              >
            </div>
          </div>
          <div class="row">
            <div class="container my_new_container">
              <!-- <b-table ref="datatableAll" show-empty striped hover responsive :items="getJobseekersAll" :fields="fieldsAllPendingFT" :current-page="currentPageAll" :per-page="perPageAll" :filter="filterAll" :sort-by.sync="sortByAll" :sort-desc.sync="sortDescAll" :sort-direction="sortDirectionAll" class="common_border_top_right_left_radius_4px static_page employer_list_table five_grid_tbl mobile_min_width_1000 new_box_tbl_mrgn desktop_margin_top_zero mobile_margin_top_20"> -->
              <b-table
                ref="datatableAll"
                show-empty
                striped
                hover
                responsive
                :items="getJobseekersAll"
                :fields="fieldsAll"
                :current-page="currentPageAll"
                :per-page="perPageAll"
                :filter="filterAll"
                :sort-by.sync="sortByAll"
                :sort-desc.sync="sortDescAll"
                :sort-direction="sortDirectionAll"
                class="new_layout_table_like_v1 common_small_font_table new_jbs_full_over_flow common_border_top_right_left_radius_4px static_page new_box_tbl_mrgn desktop_margin_top_zero mobile_margin_top_20"
              >
                <template #cell(toggleAction)="items">
                    <!-- New Layout Start For Action Dropdown Menu-->
                  <div class="toggle-action common_ul_radius toggle_margin_unset new_style_dropdown_jbs">
                    <!-- <b-dropdown class="mx-1" right text="Action" boundary="window"> -->
                      <b-dropdown class="mx-1" left text="Action" boundary="window" @show="onDropdownShow" @hide="onDropdownHide">
                        <li class="border_li"><a @click="banviewClick" href="javascript:void(0);">Ban / View EM / Whitelist<i class="fa fa-angle-right main_menu_i_ban_view"></i></a></li>
                     <div class="ban_view_sub_menu" style="display:none;">
                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status != 'rejected' &&
                          items.item.status != 'deleted' &&
                          show('restrict companies')
                        "
                        @click="
                          openInfoModel(items.item.id, 'restrict', items.item.restricted_companies)
                        "
                        >Ban From EM
                           </b-dropdown-item>
                          <b-dropdown-item
                        v-if="
                          items.item.account_verification_status != 'rejected' &&
                          items.item.status != 'deleted' &&
                          show('link companies')
                        "
                        @click="openInfoModel(items.item.id, 'link', items.item.linked_companies)"
                        >View Only EM
                          </b-dropdown-item>
                            <b-dropdown-item
                        v-if="
                          items.item.account_verification_status == 'completed' &&
                          items.item.status != 'deleted' &&
                          show('whitelist fl')
                        "
                        @click="
                          openInfoModel(
                            items.item.id,
                            'whitelist',
                            items.item.whitelisted_companies
                          )
                        "
                        >Whitelist FL
                            </b-dropdown-item>
                      </div>




                      <li class="border_li"><a @click="badgeandcertificateClick" href="javascript:void(0);">Badge & Certificates<i class="fa fa-angle-right main_menu_i_badge_and_certificate"></i></a></li>
                      <div class="badge_and_certificate_sub_menu" style="display:none;">
                           <b-dropdown-item
                        v-if="showBadge('award badge')"
                        @click="openInfoModel(items.item.id, 'badge')"
                        >Award Badge
                            </b-dropdown-item>
                            <b-dropdown-item
                        v-if="showBadge('view')"
                        :to="{
                          name: 'jobseeker-badge-manage',
                          params: { fl_id: encrypt(items.item.id) },
                        }"
                        >Manage Badge</b-dropdown-item
                              >
                              <b-dropdown-item
                        v-if="
                          items.item.account_verification_status != 'rejected' &&
                          items.item.status != 'deleted' &&
                          showCertificate('view')
                        "
                        :to="{
                          name: 'jobseeker-certificate-manage',
                          params: { id: encrypt(items.item.id) },
                        }"
                        >Manage Certificates
                            </b-dropdown-item>
                            <b-dropdown-item
                        v-if="
                          (items.item.account_verification_status == 'completed' ||
                            items.item.account_verification_status == 'pending') &&
                          show('referred mobile workers')
                        "
                        :to="{
                          name: 'jobseeker-referred-fl',
                          params: { id: encrypt(items.item.id) },
                        }"
                        >Referral List
                            </b-dropdown-item>
                            <b-dropdown-item
                        v-if="show('show referral code/Llnk')"
                        @click="generateBranchLink(items.item.id)"
                        >Show Referral Code / Link
                            </b-dropdown-item>
                      </div>

                      <b-dropdown-item class="border_li"
                        v-if="
                          (items.item.account_verification_status == 'completed' ||
                            items.item.account_verification_status == 'pending') &&
                          show('jobs')
                        "
                        :to="{
                        name: 'jobseekerDPjobsAdmin',
                        params: { id: encrypt(items.item.id) },
                      }"
                        >DP Jobs
                      </b-dropdown-item>

                      <b-dropdown-item class="border_li"
                        v-if="
                          (items.item.account_verification_status == 'completed' ||
                            items.item.account_verification_status == 'pending') &&
                          show('jobs')
                        "
                        @click="dpJobs(items.item.id)"
                        >DP Jobs (Old)
                      </b-dropdown-item>

                      <li class="border_li"><a @click="emailmobilepasswordClick" href="javascript:void(0);">Email, Mobile, Password<i class="fa fa-angle-right main_menu_i_email_mobile_password"></i></a></li>
                      <div class="email_mobile_password_sub_menu" style="display:none;">
                          <b-dropdown-item
                        v-if="
                          items.item.account_verification_status != 'rejected' &&
                          items.item.status != 'deleted' &&
                          show('update email')
                        "
                        @click="openInfoModel(items.item.id, 'email')"
                        >Update Email
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="
                              items.item.account_verification_status != 'rejected' &&
                              items.item.status != 'deleted' &&
                              show('update mobile')
                            "
                            @click="openInfoModel(items.item.id, 'mobile')"
                            >Update Mobile Number
                          </b-dropdown-item>
                           <b-dropdown-item
                        v-if="
                          items.item.subscribe_mail == 'no' &&
                          items.item.account_verification_status != 'rejected' &&
                          items.item.status != 'deleted' &&
                          show('subscribe/unsubscribe mailing list')
                        "
                        @click="subscribeUnsubscribeMail(items.item.id)"
                        >Subscribe Mailing List</b-dropdown-item
                            >
                           <b-dropdown-item
                        v-if="
                          items.item.subscribe_mail == 'yes' &&
                          items.item.account_verification_status != 'rejected' &&
                          items.item.status != 'deleted' &&
                          show('subscribe/unsubscribe mailing list')
                        "
                        @click="subscribeUnsubscribeMail(items.item.id)"
                        >Unsubscribe Mailing List
                          </b-dropdown-item >
                          <b-dropdown-item
                        v-if="
                          items.item.email &&
                          items.item.account_verification_status != 'rejected' &&
                          show('send password reset link')
                        "
                        @click="sendResetPasswordLink(items.item.id)"
                        >Send Reset Password Link
                          </b-dropdown-item>
                          <b-dropdown-item
                      v-if="
                        items.item.email_verified == 'no' &&
                        items.item.email &&
                        items.item.account_verification_status != 'rejected' &&
                        show('send verify email link')
                      "
                      @click="sendEmailVerificationLink(items.item.id)"
                      >Send Email Verification Link</b-dropdown-item
                          >
                          <b-dropdown-item
                        v-if="
                          items.item.email_verified == 'no' &&
                          items.item.email &&
                          items.item.account_verification_status != 'rejected' &&
                          items.item.status != 'deleted' &&
                          show('verify email')
                        "
                        v-on:click="verifyEmail(items.item.id)"
                        >Verify Email</b-dropdown-item
                          >
                          <b-dropdown-item
                        v-if="
                          items.item.mobile_verified == 'no' &&
                          items.item.mobile_number &&
                          items.item.account_verification_status != 'rejected' &&
                          items.item.status != 'deleted' &&
                          show('verify mobile')
                        "
                        v-on:click="verifyMobileNumber(items.item.id)"
                        >Verify Mobile Number</b-dropdown-item
                          >
                      </div>





                      <li class="border_li"><a @click="profileClick" href="javascript:void(0);">Profile<i class="fa fa-angle-right main_menu_i"></i></a></li>
                     <div class="profile_sub_menu" style="display:none;">
                      <b-dropdown-item
                        v-on:click="openDisableModel(items.item.id, items.item.status, 'deleted')"
                        v-if="items.item.status == 'deleted' && show('activate/inactivate')"
                        >Activate</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="items.item.status == 'blacklisted' && show('reactive')"
                        v-on:click="openDisableModel(items.item.id, items.item.status, 'reactive')"
                        >Reactive</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-else-if="show('blacklist')"
                        @click="openDisableModel(items.item.id, '', 'blacklist')"
                        >Blacklist</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status != 'rejected' &&
                          items.item.status != 'deleted' &&
                          show('delete worker')
                        "
                        @click="openDisableModel(items.item.id, '', 'jobseeker_deleted')"
                        >Delete Account</b-dropdown-item
                      >
                      <b-dropdown-item class=""
                        v-if="
                          items.item.account_verification_status != 'rejected' &&
                          (items.item.status == 'active' || items.item.status == 'inactive') &&
                          show('edit worker')
                        "
                        :to="{ name: 'jobseekers-ft-edit', params: { id: encrypt(items.item.id) } }"
                        >Edit Profile
                      </b-dropdown-item>
                      <b-dropdown-item class="" 
                        v-on:click="openDisableModel(items.item.id, items.item.status, 'active')"
                        v-if="
                          items.item.status == 'active' &&
                          items.item.account_verification_status != 'rejected' &&
                          show('activate/inactivate')
                        "
                        >Inactivate</b-dropdown-item
                      >
                      <b-dropdown-item class=""
                        v-else-if="
                          items.item.status == 'inactive' &&
                          items.item.account_verification_status != 'rejected' &&
                          show('activate/inactivate')
                        "
                        v-on:click="openDisableModel(items.item.id, items.item.status, 'inactive')"
                        >Activate</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status != 'rejected' &&
                          items.item.status != 'deleted' &&
                          show('logout from app')
                        "
                        @click="logoutFromApp(items.item.id)"
                        >Logout From App
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="show('permanent delete user')"
                        v-on:click="permanentDeleteUser(items.item.id)"
                        >Permanent Delete Worker
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          items.item.status == 'active' &&
                          items.item.account_verification_status != 'rejected' &&
                          show('temporary suspend')
                        "
                        @click="openDisableModel(items.item.id, '', 'disable')"
                        >Temporary Suspend
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status == 'rejected' &&
                          items.item.status != 'deleted' &&
                          show('update rejection remark')
                        "
                        @click="
                          openRejectionModel(
                            items.item.id,
                            items.item.comment != null ? items.item.comment_id : '',
                            items.item.comment != null ? items.item.comment : ''
                          )
                        "
                        >Update Rejection Remark</b-dropdown-item
                      >
                       <b-dropdown-item class=""
                        v-if="show('view profile')"
                        :to="{
                          name: 'jobseeker-dp-profile-view',
                          params: { id: encrypt(items.item.id) },
                        }"
                        >View Profile
                      </b-dropdown-item>
                      </div>
                      <li class="border_li"><a @click="referralClick" href="javascript:void(0);">Referral<i class="fa fa-angle-right main_menu_i_referral"></i></a></li>
                      <div class="referral_sub_menu" style="display:none;">
                          <b-dropdown-item
                        v-if="
                          items.item.account_verification_status != 'rejected' &&
                          items.item.status != 'deleted' &&
                          show('assign referral')
                        "
                        @click="openReferralModel(items.item.id)"
                        >Refer a Friend
                          </b-dropdown-item>
                          <b-dropdown-item
                      v-if="
                        (items.item.account_verification_status == 'completed' ||
                          items.item.account_verification_status == 'pending') &&
                        show('referred mobile workers')
                      "
                      :to="{
                        name: 'jobseeker-referred-fl',
                        params: { id: encrypt(items.item.id) },
                      }"
                      >Referral List
                          </b-dropdown-item>
                           <b-dropdown-item
                        v-if="show('show referral code/Llnk')"
                        @click="generateBranchLink(items.item.id)"
                        >Show Referral Code / Link
                            </b-dropdown-item>
                      </div>





                    <li class="border_li"><a @click="transactionClick" href="javascript:void(0);">Transaction<i class="fa fa-angle-right main_menu_i_transaction"></i></a></li>
                    <div class="transaction_sub_menu" style="display:none;">
                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status == 'completed' &&
                          show('add new transaction')
                        "
                        @click="openTransactionModel(items.item.id)"
                        >Add New Transaction
                          </b-dropdown-item>
                      <b-dropdown-item
                      v-if="
                        items.item.account_number &&
                        items.item.account_verification_status != 'rejected' &&
                        items.item.status != 'deleted' &&
                        show('delete bank')
                      "
                      @click="deletePaynow(items.item.id, 'bank')"
                      >Delete Bank</b-dropdown-item
                          >
                          <b-dropdown-item
                        v-if="
                          items.item.paynow_mobile_number &&
                          items.item.account_verification_status != 'rejected' &&
                          items.item.status != 'deleted' &&
                          show('delete paynow')
                        "
                        @click="deletePaynow(items.item.id, 'payout')"
                        >Delete Paynow
                          </b-dropdown-item>
                          <b-dropdown-item
                        v-if="
                          (items.item.account_verification_status == 'completed' ||
                            items.item.account_verification_status == 'pending') &&
                          show('generate payslip')
                        "
                        @click="openModel(items.item.id)"
                        >Generate Payslip
                          </b-dropdown-item>
                          <b-dropdown-item
                        v-if="
                          (items.item.account_verification_status == 'completed' ||
                            items.item.account_verification_status == 'pending') &&
                          show('incentive')
                        "
                        :to="{ name: 'incentive', params: { id: encrypt(items.item.id) } }"
                        >Incentive Details
                          </b-dropdown-item>
                          <b-dropdown-item
                        v-if="
                          (items.item.account_verification_status == 'completed' ||
                            items.item.account_verification_status == 'pending') &&
                          show('payslip')
                        "
                        :to="{
                          name: 'jobseeker-payslip-view',
                          params: { id: encrypt(items.item.id) },
                        }"
                        >Payslip
                          </b-dropdown-item>
                          <b-dropdown-item
                        v-if="
                          (items.item.account_verification_status == 'completed' ||
                            items.item.account_verification_status == 'pending') &&
                          show('recalculate wallet')
                        "
                        @click="openInfoModel(items.item.id, 'wallet')"
                        >Recalculate Wallet
                          </b-dropdown-item>
                           <b-dropdown-item
                        v-if="
                          (items.item.account_verification_status == 'completed' ||
                            items.item.account_verification_status == 'pending') &&
                          show('transactions')
                        "
                        :to="{
                          name: 'jobseeker-transaction-view',
                          params: { id: encrypt(items.item.id) },
                        }"
                        >Transactions
                          </b-dropdown-item>

                    </div>
                      
                     <!-- <b-dropdown-item :to="{name: 'jobseekers-dp-edit', params: {id: encrypt(items.item.id)}}">Edit DP Profile </b-dropdown-item> -->
                    <!-- <b-dropdown-item
                        v-if="
                          (form.admin_email == 'esther@elconnect.sg' ||
                            form.admin_email == 'apple@elconnect.sg' ||
                            form.admin_user_type_id == 1) &&
                          show('permanent delete user')
                        "
                        v-on:click="permanentDeleteUser(items.item.id)"
                        >Permanent Delete Worker
                      </b-dropdown-item> -->
                     
                     <!-- <b-dropdown-item v-on:click="changeUserStatus(items.item.id, items.item.status)" v-if="items.item.status == 'deleted'">Activate</b-dropdown-item> -->
                    
                    
                      <!-- <b-dropdown-item :to="{name: 'jobseekerDPManagejobsAdmin', params: {id: encrypt(items.item.id)}}">DP Jobs
                                            </b-dropdown-item> -->
                    </b-dropdown>
                  </div>
                    <!-- New Layout End For Action Dropdown Menu-->

                  <!-- Old Desktop Layout Start For Action Dropdown Menu-->
                    <!-- <div class="toggle-action common_ul_radius toggle_margin_unset">
                      <b-dropdown class="mx-1" left text="Action" boundary="window">
                        <b-dropdown-item
                          v-if="show('view profile')"
                          :to="{
                            name: 'jobseeker-dp-profile-view',
                            params: { id: encrypt(items.item.id) },
                          }"
                          >View Profile
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="
                            items.item.account_verification_status != 'rejected' &&
                            (items.item.status == 'active' || items.item.status == 'inactive') &&
                            show('edit worker')
                          "
                          :to="{ name: 'jobseekers-ft-edit', params: { id: encrypt(items.item.id) } }"
                          >Edit
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="
                            (items.item.account_verification_status == 'completed' ||
                              items.item.account_verification_status == 'pending') &&
                            show('jobs')
                          "
                          @click="dpJobs(items.item.id)"
                          >DP Jobs
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="
                            items.item.account_verification_status != 'rejected' &&
                            items.item.status != 'deleted' &&
                            show('assign referral')
                          "
                          @click="openReferralModel(items.item.id)"
                          >Refer a Friend
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="
                            items.item.account_verification_status != 'rejected' &&
                            items.item.status != 'deleted' &&
                            show('restrict companies')
                          "
                          @click="
                            openInfoModel(items.item.id, 'restrict', items.item.restricted_companies)
                          "
                          >Ban From EM
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="
                            items.item.account_verification_status != 'rejected' &&
                            items.item.status != 'deleted' &&
                            show('link companies')
                          "
                          @click="openInfoModel(items.item.id, 'link', items.item.linked_companies)"
                          >View Only EM
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="
                            items.item.account_verification_status == 'completed' &&
                            items.item.status != 'deleted' &&
                            show('whitelist fl')
                          "
                          @click="
                            openInfoModel(
                              items.item.id,
                              'whitelist',
                              items.item.whitelisted_companies
                            )
                          "
                          >Whitelist FL
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="
                            items.item.account_verification_status == 'rejected' &&
                            items.item.status != 'deleted' &&
                            show('update rejection remark')
                          "
                          @click="
                            openRejectionModel(
                              items.item.id,
                              items.item.comment != null ? items.item.comment_id : '',
                              items.item.comment != null ? items.item.comment : ''
                            )
                          "
                          >Update Rejection Remark</b-dropdown-item
                        >
                        <b-dropdown-item
                          v-if="
                            items.item.status == 'active' &&
                            items.item.account_verification_status != 'rejected' &&
                            show('temporary suspend')
                          "
                          @click="openDisableModel(items.item.id, '', 'disable')"
                          >Temporary Suspend
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-on:click="openDisableModel(items.item.id, items.item.status, 'active')"
                          v-if="
                            items.item.status == 'active' &&
                            items.item.account_verification_status != 'rejected' &&
                            show('activate/inactivate')
                          "
                          >Inactivate</b-dropdown-item
                        >
                        <b-dropdown-item
                          v-else-if="
                            items.item.status == 'inactive' &&
                            items.item.account_verification_status != 'rejected' &&
                            show('activate/inactivate')
                          "
                          v-on:click="openDisableModel(items.item.id, items.item.status, 'inactive')"
                          >Activate</b-dropdown-item
                        >
                        <b-dropdown-item
                          v-if="items.item.status == 'blacklisted' && show('reactive')"
                          v-on:click="openDisableModel(items.item.id, items.item.status, 'reactive')"
                          >Reactive</b-dropdown-item
                        >
                        <b-dropdown-item
                          v-else-if="show('blacklist')"
                          @click="openDisableModel(items.item.id, '', 'blacklist')"
                          >Blacklist</b-dropdown-item
                        >
                        <b-dropdown-item
                          v-if="
                            items.item.account_verification_status != 'rejected' &&
                            items.item.status != 'deleted' &&
                            show('delete worker')
                          "
                          @click="openDisableModel(items.item.id, '', 'jobseeker_deleted')"
                          >Delete</b-dropdown-item
                        >
                        <b-dropdown-item
                          v-if="show('permanent delete user')"
                          v-on:click="permanentDeleteUser(items.item.id)"
                          >Permanent Delete Worker
                        </b-dropdown-item>

                        <b-dropdown-item
                          v-if="
                            items.item.email &&
                            items.item.account_verification_status != 'rejected' &&
                            show('send password reset link')
                          "
                          @click="sendResetPasswordLink(items.item.id)"
                          >Send Reset Password Link
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="
                            items.item.email_verified == 'no' &&
                            items.item.email &&
                            items.item.account_verification_status != 'rejected' &&
                            show('send verify email link')
                          "
                          @click="sendEmailVerificationLink(items.item.id)"
                          >Send Email Verification Link</b-dropdown-item
                        >
                        <b-dropdown-item
                          v-if="
                            items.item.email_verified == 'no' &&
                            items.item.email &&
                            items.item.account_verification_status != 'rejected' &&
                            items.item.status != 'deleted' &&
                            show('verify email')
                          "
                          v-on:click="verifyEmail(items.item.id)"
                          >Verify Email</b-dropdown-item
                        >
                        <b-dropdown-item
                          v-if="
                            items.item.mobile_verified == 'no' &&
                            items.item.mobile_number &&
                            items.item.account_verification_status != 'rejected' &&
                            items.item.status != 'deleted' &&
                            show('verify mobile')
                          "
                          v-on:click="verifyMobileNumber(items.item.id)"
                          >Verify Mobile Number</b-dropdown-item
                        >
                        <b-dropdown-item
                          v-if="
                            items.item.account_verification_status != 'rejected' &&
                            items.item.status != 'deleted' &&
                            show('update email')
                          "
                          @click="openInfoModel(items.item.id, 'email')"
                          >Update Email
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="
                            items.item.account_verification_status != 'rejected' &&
                            items.item.status != 'deleted' &&
                            show('update mobile')
                          "
                          @click="openInfoModel(items.item.id, 'mobile')"
                          >Update Mobile Number
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="
                            items.item.subscribe_mail == 'yes' &&
                            items.item.account_verification_status != 'rejected' &&
                            items.item.status != 'deleted' &&
                            show('subscribe/unsubscribe mailing list')
                          "
                          @click="subscribeUnsubscribeMail(items.item.id)"
                          >Unsubscribe Mailing List</b-dropdown-item
                        >
                        <b-dropdown-item
                          v-if="
                            items.item.subscribe_mail == 'no' &&
                            items.item.account_verification_status != 'rejected' &&
                            items.item.status != 'deleted' &&
                            show('subscribe/unsubscribe mailing list')
                          "
                          @click="subscribeUnsubscribeMail(items.item.id)"
                          >Subscribe Mailing List</b-dropdown-item
                        >

                        <b-dropdown-item
                          v-if="
                            (items.item.account_verification_status == 'completed' ||
                              items.item.account_verification_status == 'pending') &&
                            show('recalculate wallet')
                          "
                          @click="openInfoModel(items.item.id, 'wallet')"
                          >Recalculate Wallet
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="
                            (items.item.account_verification_status == 'completed' ||
                              items.item.account_verification_status == 'pending') &&
                            show('incentive')
                          "
                          :to="{ name: 'incentive', params: { id: encrypt(items.item.id) } }"
                          >Incentive Details</b-dropdown-item
                        >
                        <b-dropdown-item
                          v-if="
                            (items.item.account_verification_status == 'completed' ||
                              items.item.account_verification_status == 'pending') &&
                            show('transactions')
                          "
                          :to="{
                            name: 'jobseeker-transaction-view',
                            params: { id: encrypt(items.item.id) },
                          }"
                          >Transactions</b-dropdown-item
                        >
                        <b-dropdown-item
                          v-if="
                            items.item.account_verification_status == 'completed' &&
                            show('add new transaction')
                          "
                          @click="openTransactionModel(items.item.id)"
                          >Add New Transaction
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="
                            (items.item.account_verification_status == 'completed' ||
                              items.item.account_verification_status == 'pending') &&
                            show('payslip')
                          "
                          :to="{
                            name: 'jobseeker-payslip-view',
                            params: { id: encrypt(items.item.id) },
                          }"
                          >Payslip</b-dropdown-item
                        >
                        <b-dropdown-item
                          v-if="
                            (items.item.account_verification_status == 'completed' ||
                              items.item.account_verification_status == 'pending') &&
                            show('generate payslip')
                          "
                          @click="openModel(items.item.id)"
                          >Generate Payslip</b-dropdown-item
                        >

                        <b-dropdown-item
                          v-if="
                            items.item.account_number &&
                            items.item.account_verification_status != 'rejected' &&
                            items.item.status != 'deleted' &&
                            show('delete bank')
                          "
                          @click="deletePaynow(items.item.id, 'bank')"
                          >Delete Bank</b-dropdown-item
                        >
                        <b-dropdown-item
                          v-if="
                            items.item.paynow_mobile_number &&
                            items.item.account_verification_status != 'rejected' &&
                            items.item.status != 'deleted' &&
                            show('delete paynow')
                          "
                          @click="deletePaynow(items.item.id, 'payout')"
                          >Delete Paynow
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-on:click="openDisableModel(items.item.id, items.item.status, 'deleted')"
                          v-if="items.item.status == 'deleted' && show('activate/inactivate')"
                          >Activate</b-dropdown-item
                        >

                        <b-dropdown-item
                          v-if="showBadge('award badge')"
                          @click="openInfoModel(items.item.id, 'badge')"
                          >Award Badge
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="showBadge('view')"
                          :to="{
                            name: 'jobseeker-badge-manage',
                            params: { fl_id: encrypt(items.item.id) },
                          }"
                          >Manage Badge</b-dropdown-item
                        >
                        <b-dropdown-item
                          v-if="show('show referral code/Llnk')"
                          @click="generateBranchLink(items.item.id)"
                          >Show Referral Code / Link
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="
                            items.item.account_verification_status != 'rejected' &&
                            items.item.status != 'deleted' &&
                            showCertificate('view')
                          "
                          :to="{
                            name: 'jobseeker-certificate-manage',
                            params: { id: encrypt(items.item.id) },
                          }"
                          >Manage Certificates</b-dropdown-item
                        >

                        <b-dropdown-item
                          v-if="
                            items.item.account_verification_status != 'rejected' &&
                            items.item.status != 'deleted' &&
                            show('logout from app')
                          "
                          @click="logoutFromApp(items.item.id)"
                          >Logout From App
                        </b-dropdown-item>
                       
                      </b-dropdown>
                    </div> -->
                  <!-- Old Desktop Layout End For Action Dropdown Menu-->
                </template>
                <!-- Old Mobile View Layout Start For Action Dropdown Menu-->
                <!-- <template #cell(toggleAction1)="items">
                  <div class="mobile_action_toggle">
                    <b-button
                      class="mobile_toggle_btn"
                      @click="showMobileActionModel(items.item)"
                    ></b-button>
                  </div>
                </template> -->
                <!-- Old Mobile View  Layout End For Action Dropdown Menu-->
                <template #cell(last_name)="items">
                  <div class="new-name-td-box-full-icon">
                    <b-img
                      :title="items.item.fulltime_remarks"
                      class="full_time_icon"
                      v-if="items.item.is_fulltime == 'yes'"
                      @click="openFulltimeRemarks(items.item.fulltime_remarks)"
                      :src="webUrl + 'assets/images/full_time.png'"
                    ></b-img>
                  </div>
                  <div class="new-name-td-box">
                    <div class="whole_div_img_and_name">
                      <div class="manage_user_img">
                        <b-img
                          v-if="items.item.thumbnail || items.item.profile_image"
                          :src="
                            items.item.thumbnail
                              ? items.item.thumbnail
                              : items.item.profile_image
                              ? items.item.profile_image
                              : webUrl + 'assets/images/user-avatar-small-01.png'
                          "
                          alt="User Icon"
                          height="100px"
                          width="100px"
                          class="square_img"
                        >
                        </b-img>
                        <b-img
                          v-else-if="
                            items.item.ft_profile_image_thumbnail || items.item.ft_profile_image
                          "
                          :src="
                            items.item.ft_profile_image_thumbnail
                              ? items.item.ft_profile_image_thumbnail
                              : items.item.ft_profile_image
                              ? items.item.ft_profile_image
                              : webUrl + 'assets/images/user-avatar-small-01.png'
                          "
                          alt="User Icon"
                          height="100px"
                          width="100px"
                          class="square_img"
                        >
                        </b-img>

                        <b-img
                          v-else
                          :src="webUrl + 'assets/images/user-avatar-small-01.png'"
                          alt="User Icon"
                          height="100px"
                          width="100px"
                          class="square_img"
                        >
                        </b-img>
                      </div>
                      <div class="user_name_div">
                        <b-link
                          :to="
                            show('view profile')
                              ? {
                                  name: 'jobseeker-dp-profile-view',
                                  params: { id: encrypt(items.item.id) },
                                }
                              : null
                          "
                          target="_blank"
                          style="color: #4c8bf5"
                        >
                          {{ items.item.last_name | capitalize }}
                          {{ items.item.first_name | capitalize }}
                        </b-link>
                      </div>
                    </div>
                    <p class="grey-text">
                      {{ items.item.email }}
                    </p>
                  </div>
                  <!-- <div class="whole_div_img_and_name">
                                            <div class="manage_user_img">
                                                <b-img v-if="items.item.thumbnail || items.item.profile_image" :src="items.item.thumbnail ? items.item.thumbnail: items.item.profile_image ? items.item.profile_image: webUrl+'assets/images/user-avatar-small-01.png'" alt="User Icon" height="100px" width="100px" class="square_img">
                                                </b-img>
                                                <b-img v-else-if="items.item.ft_profile_image_thumbnail || items.item.ft_profile_image" :src="items.item.ft_profile_image_thumbnail ? items.item.ft_profile_image_thumbnail: items.item.ft_profile_image ? items.item.ft_profile_image: webUrl+'assets/images/user-avatar-small-01.png'" alt="User Icon" height="100px" width="100px" class="square_img">
                                                </b-img>

                                                <b-img v-else :src="webUrl+'assets/images/user-avatar-small-01.png'" alt="User Icon" height="100px" width="100px" class="square_img">
                                                </b-img>
                                            </div>
                                            <div class="user_name_div">
                                                {{items.item.last_name | capitalize}} {{items.item.first_name | capitalize}}
                                            </div>
                                    </div> -->
                  <!-- <p class="grey-text">
                                            {{items.item.email}}
                                        </p> -->
                </template>
                <template #cell(mobile_number)="items">
                  <b-link
                    :href="'https://wa.me/+65' + items.item.mobile_number"
                    target="_blank"
                    style="color: #4c8bf5"
                  >
                    {{ items.item.country_code }}-{{ items.item.mobile_number }}
                  </b-link>
                  <!-- <b-img :title="items.item.fulltime_remarks" class="full_time_icon" v-if="items.item.is_fulltime == 'yes'" @click="openFulltimeRemarks(items.item.fulltime_remarks)" :src="webUrl+'assets/images/full_time.png'"></b-img><br> -->
                </template>

                <template #cell(users.created_at)="items">
                  {{ items.item.created_at | moment }}
                </template>
                <template #cell(other_description)="items">
                  {{ items.item.other_description ? items.item.other_description : "-" }}
                </template>
                <!-- <template #cell(age)="items">
                                {{items.item.age }}
                                </template>  -->
                <template #cell(last_logged_in_at)="items">
                  {{ items.item.last_logged_in_at | moment }}
                </template>
                <!-- <template #cell(location_updated_at)="items">
                                {{items.item.location_updated_at | moment}}
                                </template> -->
                <template #cell(available_cashout)="items">
                  ${{ items.item.available_cashout ? items.item.available_cashout : 0 | ifNumber }}
                </template>
                <template #cell(nric)="items">
                  {{ items.item.nric }}
                </template>
                <template #cell(applied_jobs_count)="items">
                    {{items.item.applied_jobs_count}}
                    </template>
                    <template #cell(completed_jobs_count)="items">
                    {{items.item.completed_jobs_count}}
                </template>
                <template #cell(status)="items">
                  <p class="green_status" v-if="items.item.status == 'active'">
                    {{ items.item.status | capitalize }}
                  </p>
                  <p class="orange__status" v-if="items.item.status == 'inactive'">
                    {{ items.item.status | capitalize }}
                  </p>
                  <p class="red_status" v-if="items.item.status == 'deleted'">
                    {{ items.item.status | capitalize }}
                  </p>
                  <p class="red_status" v-if="items.item.status == 'blacklisted'">
                    {{ items.item.status | capitalize }}
                  </p>
                </template>
                <template #cell(ft_account_status)="items">
                  <p
                    v-if="items.item.ft_account_status == 'incomplete'"
                    v-bind:class="{ red_status: items.item.ft_account_status == 'incomplete' }"
                  >
                    {{ items.item.ft_account_status | capitalize }}
                  </p>
                  <p
                    v-if="items.item.ft_account_status == 'completed'"
                    v-bind:class="{ green_status: items.item.ft_account_status == 'completed' }"
                  >
                    {{ items.item.ft_account_status | capitalize }}
                  </p>
                  <p
                    v-if="items.item.ft_account_status == 'pending'"
                    v-bind:class="{ orange__status: items.item.ft_account_status == 'pending' }"
                  >
                    {{ items.item.ft_account_status | capitalize }}
                  </p>
                  <p
                    v-if="items.item.ft_account_status == 'rejected'"
                    v-bind:class="{ red_status: items.item.ft_account_status == 'rejected' }"
                  >
                    {{ items.item.ft_account_status | capitalize }}
                  </p>
                </template>
                <template #cell(account_verification_status)="items">
                  <!-- {{items.item.account_verification_status | capitalize}} -->
                  <p
                    v-if="items.item.account_verification_status == 'incomplete'"
                    v-bind:class="{
                      red_status: items.item.account_verification_status == 'incomplete',
                    }"
                  >
                    {{ items.item.account_verification_status | capitalize }}
                  </p>
                  <p
                    v-if="items.item.account_verification_status == 'completed'"
                    v-bind:class="{
                      green_status: items.item.account_verification_status == 'completed',
                    }"
                  >
                    {{ items.item.account_verification_status | capitalize }}
                  </p>
                  <p
                    v-if="items.item.account_verification_status == 'pending'"
                    v-bind:class="{
                      orange__status: items.item.account_verification_status == 'pending',
                    }"
                  >
                    {{ items.item.account_verification_status | capitalize }}
                  </p>
                  <p
                    v-if="items.item.account_verification_status == 'rejected'"
                    v-bind:class="{
                      red_status: items.item.account_verification_status == 'rejected',
                    }"
                  >
                    {{ items.item.account_verification_status | capitalize }}
                  </p>
                </template>
              </b-table>
              <nav class="pagination-box custom_pagination_border_radius">
                <div class="page-row-box d-inline-block">
                  <b-form-group id="input-group-4">
                    <span class="d-inline-block">Rows per page</span>
                    <span class="d-inline-block">
                      <b-form-select
                        v-model="form.rowsPerPageAll"
                        @change="getJobseekersAll"
                        class="common_small_font_thirteen"
                      >
                        <option>25</option>
                        <option>50</option>
                        <option>75</option>
                        <option>100</option>
                      </b-form-select></span
                    >
                  </b-form-group>
                </div>
                <div class="total-page-count d-inline-block">
                  <ul>
                    <li>
                      <span v-if="totalRowsAll != 0"
                        >{{ fromAll }} - {{ toAll }} of {{ totalRowsAll }}</span
                      >
                    </li>
                  </ul>
                </div>
                <b-pagination
                  :total-rows="totalRowsAll"
                  :per-page="perPageAll"
                  v-model="currentPageAll"
                  prev-text=""
                  next-text=""
                  class="common_pagination_button"
                  hide-goto-end-buttons
                />
              </nav>
            </div>
          </div>
        </b-tab>
        <b-tab :title="'PENDING'" @click="changePendingStatus">
          <div class="row">
            <div
              class="col-xl-4 col-md-4 admin_listing_search mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
            >
              <div class="search-box custom_search_admin desktop_margin_top_zero">
                <!-- <b-input-group> -->
                <b-input-group-prepend>
                  <b-form-input
                    id="searchKeywordPending"
                    @keyup.enter.native="filterPending = searchKeywordPending"
                    :value="searchKeywordPending"
                    @change="(v) => (searchKeywordPending = v)"
                    placeholder="Search by Name, Email, Mobile, Nric"
                    class="common_small_font_thirteen"
                  ></b-form-input>
                  <b-button class="btn points_events" @click="filterPending = searchKeywordPending"
                    ><i class="fa fa-search common_small_font_thirteen"></i
                  ></b-button>
                </b-input-group-prepend>
                <!-- </b-input-group> -->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="container my_new_container">
              <!-- <b-table ref="datatablePending" show-empty striped hover responsive :items="getJobseekersPending" :fields="fieldsAllPendingFT" :current-page="currentPagePending" :per-page="perPagePending" :filter="filterPending" :sort-by.sync="sortByPending" :sort-desc.sync="sortDescPending" :sort-direction="sortDirectionPending" class="mobile_margin_top_20 common_border_top_right_left_radius_4px static_page employer_list_table five_grid_tbl mobile_min_width_1000 new_box_tbl_mrgn desktop_margin_top_zero "> -->
              <b-table
                ref="datatablePending"
                show-empty
                striped
                hover
                responsive
                :items="getJobseekersPending"
                :fields="fieldsPendingFT"
                :current-page="currentPagePending"
                :per-page="perPagePending"
                :filter="filterPending"
                :sort-by.sync="sortByPending"
                :sort-desc.sync="sortDescPending"
                :sort-direction="sortDirectionPending"
                class="common_small_font_table new_jbs_full_over_flow common_border_top_right_left_radius_4px static_page new_layout_table_like_v1 new_box_tbl_mrgn desktop_margin_top_zero mobile_margin_top_20"
              >
                <template #cell(last_name)="items">
                  <div class="whole_div_img_and_name">
                    <div class="manage_user_img">
                      <b-img
                        v-if="items.item.thumbnail || items.item.profile_image"
                        :src="
                          items.item.thumbnail
                            ? items.item.thumbnail
                            : items.item.profile_image
                            ? items.item.profile_image
                            : webUrl + 'assets/images/user-avatar-small-01.png'
                        "
                        alt="User Icon"
                        height="100px"
                        width="100px"
                        class="square_img"
                      >
                      </b-img>
                      <b-img
                        v-else-if="
                          items.item.ft_profile_image_thumbnail || items.item.ft_profile_image
                        "
                        :src="
                          items.item.ft_profile_image_thumbnail
                            ? items.item.ft_profile_image_thumbnail
                            : items.item.ft_profile_image
                            ? items.item.ft_profile_image
                            : webUrl + 'assets/images/user-avatar-small-01.png'
                        "
                        alt="User Icon"
                        height="100px"
                        width="100px"
                        class="square_img"
                      >
                      </b-img>

                      <b-img
                        v-else
                        :src="webUrl + 'assets/images/user-avatar-small-01.png'"
                        alt="User Icon"
                        height="100px"
                        width="100px"
                        class="square_img"
                      >
                      </b-img>
                    </div>
                    <div class="user_name_div">
                      <b-link
                        :to="
                          show('view profile')
                            ? {
                                name: 'jobseeker-dp-profile-view',
                                params: { id: encrypt(items.item.id) },
                              }
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{ items.item.last_name | capitalize }}
                        {{ items.item.first_name | capitalize }}
                      </b-link>
                    </div>
                  </div>
                  <p class="grey-text">
                    {{ items.item.email }}
                  </p>
                </template>
                <template #cell(mobile_number)="items">
                  <b-link
                    :href="'https://wa.me/+65' + items.item.mobile_number"
                    target="_blank"
                    style="color: #4c8bf5"
                  >
                    {{ items.item.country_code }}-{{ items.item.mobile_number }}
                  </b-link>
                  <b-img
                    :title="items.item.fulltime_remarks"
                    class="full_time_icon"
                    v-if="items.item.is_fulltime == 'yes'"
                    @click="openFulltimeRemarks(items.item.fulltime_remarks)"
                    :src="webUrl + 'assets/images/full_time.png'"
                  ></b-img
                  ><br />
                </template>

                <template #cell(users.created_at)="items">
                  {{ items.item.created_at | moment }}
                </template>
                <template #cell(other_description)="items">
                  {{ items.item.other_description ? items.item.other_description : "-" }}
                </template>
                <template #cell(last_logged_in_at)="items">
                  {{ items.item.last_logged_in_at | moment }}
                </template>
                <template #cell(location_updated_at)="items">
                  {{ items.item.location_updated_at | moment }}
                </template>

                <template #cell(available_cashout)="items">
                  <b-button size="sm" @click="items.toggleDetails">
                    {{ items.detailsShowing ? "Hide" : "Show" }}
                  </b-button>
                </template>
                <template #row-details="row">
                  <b-row>
                    <b-col sm="3" class="text-sm-right"><b>Available Cashout: </b></b-col>
                    <b-col>
                      ${{ row.item.available_cashout ? row.item.available_cashout : 0 | ifNumber }}
                    </b-col>
                  </b-row>
                </template>

                <template #cell(nric)="items">
                  {{ items.item.nric }}
                </template>
                <template #cell(applied_jobs_count)="items">
                  {{ items.item.applied_jobs_count }}
                </template>
                <template #cell(completed_jobs_count)="items">
                  {{ items.item.completed_jobs_count }}
                </template>
                <template #cell(status)="items">
                  <p class="green_status" v-if="items.item.status == 'active'">
                    {{ items.item.status | capitalize }}
                  </p>
                  <p class="orange__status" v-if="items.item.status == 'inactive'">
                    {{ items.item.status | capitalize }}
                  </p>
                  <p class="red_status" v-if="items.item.status == 'deleted'">
                    {{ items.item.status | capitalize }}
                  </p>
                  <p class="red_status" v-if="items.item.status == 'blacklisted'">
                    {{ items.item.status | capitalize }}
                  </p>
                </template>
                <template #cell(ft_account_status)="items">
                  <!-- {{items.item.ft_account_status | capitalize}} -->
                  <p
                    v-if="items.item.ft_account_status == 'incomplete'"
                    v-bind:class="{ red_status: items.item.ft_account_status == 'incomplete' }"
                  >
                    {{ items.item.ft_account_status | capitalize }}
                  </p>
                  <p
                    v-if="items.item.ft_account_status == 'completed'"
                    v-bind:class="{ green_status: items.item.ft_account_status == 'completed' }"
                  >
                    {{ items.item.ft_account_status | capitalize }}
                  </p>
                  <p
                    v-if="items.item.ft_account_status == 'pending'"
                    v-bind:class="{ orange__status: items.item.ft_account_status == 'pending' }"
                  >
                    {{ items.item.ft_account_status | capitalize }}
                  </p>
                  <p
                    v-if="items.item.ft_account_status == 'rejected'"
                    v-bind:class="{ red_status: items.item.ft_account_status == 'rejected' }"
                  >
                    {{ items.item.ft_account_status | capitalize }}
                  </p>
                </template>
                <template #cell(account_verification_status)="items">
                  <!-- {{items.item.account_verification_status | capitalize}} -->
                  <!-- <p v-if="items.item.account_verification_status == 'incomplete'" v-bind:class="{ 'red_status' : items.item.account_verification_status == 'incomplete'}">{{items.item.account_verification_status | capitalize}}</p>
                                    <p v-if="items.item.account_verification_status == 'completed'" v-bind:class="{ 'green_status' : items.item.account_verification_status == 'completed'}">{{items.item.account_verification_status | capitalize}}</p> -->
                  <p
                    v-if="items.item.account_verification_status == 'pending'"
                    v-bind:class="{
                      orange__status: items.item.account_verification_status == 'pending',
                    }"
                  >
                    {{ items.item.account_verification_status | capitalize }}
                  </p>
                </template>
                <template #cell(toggleAction)="items">
                  <div class="toggle-action common_ul_radius toggle_margin_unset">
                    <!-- <b-dropdown class="mx-1" right text="Action" boundary="window"> -->
                    <b-dropdown class="mx-1" left text="Action" boundary="window">
                      <b-dropdown-item
                        v-if="show('view profile')"
                        :to="{
                          name: 'jobseeker-dp-profile-view',
                          params: { id: encrypt(items.item.id) },
                        }"
                        >View Profile
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          (items.item.status == 'active' || items.item.status == 'inactive') &&
                          show('edit worker')
                        "
                        :to="{ name: 'jobseekers-ft-edit', params: { id: encrypt(items.item.id) } }"
                        >Edit
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          (items.item.account_verification_status == 'completed' ||
                            items.item.account_verification_status == 'pending') &&
                          show('jobs')
                        "
                       :to="{
                        name: 'jobseekerDPjobsAdmin',
                        params: { id: encrypt(items.item.id) },
                      }"
                        >DP Jobs
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          (items.item.account_verification_status == 'completed' ||
                            items.item.account_verification_status == 'pending') &&
                          show('jobs')
                        "
                       @click="dpJobs(items.item.id)"
                        >DP Jobs (Old)
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status != 'rejected' &&
                          items.item.status != 'deleted' &&
                          show('assign referral')
                        "
                        @click="openReferralModel(items.item.id)"
                        >Refer a Friend
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          (items.item.account_verification_status == 'completed' ||
                            items.item.account_verification_status == 'pending') &&
                          show('referred mobile workers')
                        "
                        :to="{
                          name: 'jobseeker-referred-fl',
                          params: { id: encrypt(items.item.id) },
                        }"
                        >Referral List
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status != 'rejected' &&
                          items.item.status != 'deleted' &&
                          show('restrict companies')
                        "
                        @click="
                          openInfoModel(items.item.id, 'restrict', items.item.restricted_companies)
                        "
                        >Ban From EM
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status != 'rejected' &&
                          items.item.status != 'deleted' &&
                          show('link companies')
                        "
                        @click="openInfoModel(items.item.id, 'link', items.item.linked_companies)"
                        >View Only EM
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status == 'completed' &&
                          items.item.status != 'deleted' &&
                          show('whitelist fl')
                        "
                        @click="
                          openInfoModel(
                            items.item.id,
                            'whitelist',
                            items.item.whitelisted_companies
                          )
                        "
                        >Whitelist FL
                      </b-dropdown-item>
                      <!-- <b-dropdown-item :to="{name: 'jobseekers-dp-edit', params: {id: encrypt(items.item.id)}}">Edit DP Profile
                                            </b-dropdown-item> -->
                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status == 'rejected' &&
                          items.item.status != 'deleted' &&
                          show('update rejection remark')
                        "
                        @click="
                          openRejectionModel(
                            items.item.id,
                            items.item.comment != null ? items.item.comment_id : '',
                            items.item.comment != null ? items.item.comment : ''
                          )
                        "
                        >Update Rejection Remark
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="items.item.status == 'active' && show('temporary suspend')"
                        @click="openDisableModel(items.item.id, '', 'disable')"
                        >Temporary Suspend
                      </b-dropdown-item>

                      <b-dropdown-item
                        v-on:click="openDisableModel(items.item.id, items.item.status, 'active')"
                        v-if="items.item.status == 'active' && show('activate/inactivate')"
                        >Inactivate</b-dropdown-item
                      >
                      <!-- <b-dropdown-item v-else-if="items.item.status == 'inactive'" v-on:click="changeUserStatus(items.item.id, items.item.status)">Activate
                                            </b-dropdown-item> -->
                      <b-dropdown-item
                        v-else-if="items.item.status == 'inactive' && show('activate/inactivate')"
                        v-on:click="openDisableModel(items.item.id, items.item.status, 'inactive')"
                        >Activate
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="items.item.status == 'blacklisted' && show('reactive')"
                        v-on:click="openDisableModel(items.item.id, items.item.status, 'reactive')"
                        >Reactive
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-else-if="show('blacklist')"
                        @click="openDisableModel(items.item.id, '', 'blacklist')"
                        >Blacklist
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status != 'rejected' &&
                          items.item.status != 'deleted' &&
                          show('delete worker')
                        "
                        @click="openDisableModel(items.item.id, '', 'jobseeker_deleted')"
                        >Delete</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="show('permanent delete user')"
                        v-on:click="permanentDeleteUser(items.item.id)"
                        >Permanent Delete Worker
                      </b-dropdown-item>

                      <b-dropdown-item
                        v-if="items.item.email && show('send password reset link')"
                        @click="sendResetPasswordLink(items.item.id)"
                        >Send Reset Password Link
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          items.item.email_verified == 'no' &&
                          items.item.email &&
                          show('send verify email link')
                        "
                        @click="sendEmailVerificationLink(items.item.id)"
                        >Send Email Verification Link
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          items.item.email_verified == 'no' &&
                          items.item.email &&
                          show('verify email')
                        "
                        v-on:click="verifyEmail(items.item.id)"
                        >Verify Email</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="
                          items.item.mobile_verified == 'no' &&
                          items.item.mobile_number &&
                          show('verify mobile')
                        "
                        v-on:click="verifyMobileNumber(items.item.id)"
                        >Verify Mobile Number</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status != 'rejected' &&
                          items.item.status != 'deleted' &&
                          show('update email')
                        "
                        @click="openInfoModel(items.item.id, 'email')"
                        >Update Email
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status != 'rejected' &&
                          items.item.status != 'deleted' &&
                          show('update mobile')
                        "
                        @click="openInfoModel(items.item.id, 'mobile')"
                        >Update Mobile Number
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          items.item.subscribe_mail == 'yes' &&
                          show('subscribe/unsubscribe mailing list')
                        "
                        @click="subscribeUnsubscribeMail(items.item.id)"
                        >Unsubscribe Mailing List</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-else-if="show('subscribe/unsubscribe mailing list')"
                        @click="subscribeUnsubscribeMail(items.item.id)"
                        >Subscribe Mailing List</b-dropdown-item
                      >

                      <!-- <b-dropdown-item :to="{name: 'jobseeker-certificate-manage', params: {id: encrypt(items.item.id)}}">Manage Certificate
                                            </b-dropdown-item> -->
                      <b-dropdown-item
                        v-if="
                          (items.item.account_verification_status == 'completed' ||
                            items.item.account_verification_status == 'pending') &&
                          show('recalculate wallet')
                        "
                        @click="openInfoModel(items.item.id, 'wallet')"
                        >Recalculate Wallet
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          (items.item.account_verification_status == 'completed' ||
                            items.item.account_verification_status == 'pending') &&
                          show('incentive')
                        "
                        :to="{ name: 'incentive', params: { id: encrypt(items.item.id) } }"
                        >Incentive Details
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          (items.item.account_verification_status == 'completed' ||
                            items.item.account_verification_status == 'pending') &&
                          show('transactions')
                        "
                        :to="{
                          name: 'jobseeker-transaction-view',
                          params: { id: encrypt(items.item.id) },
                        }"
                        >Transactions
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          (items.item.account_verification_status == 'completed' ||
                            items.item.account_verification_status == 'pending') &&
                          show('payslip')
                        "
                        :to="{
                          name: 'jobseeker-payslip-view',
                          params: { id: encrypt(items.item.id) },
                        }"
                        >Payslip
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          (items.item.account_verification_status == 'completed' ||
                            items.item.account_verification_status == 'pending') &&
                          show('generate payslip')
                        "
                        @click="openModel(items.item.id)"
                        >Generate Payslip
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          items.item.account_number &&
                          items.item.account_verification_status != 'rejected' &&
                          items.item.status != 'deleted' &&
                          show('delete bank')
                        "
                        @click="deletePaynow(items.item.id, 'bank')"
                        >Delete Bank
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          items.item.paynow_mobile_number &&
                          items.item.account_verification_status != 'rejected' &&
                          items.item.status != 'deleted' &&
                          show('delete paynow')
                        "
                        @click="deletePaynow(items.item.id, 'payout')"
                        >Delete Paynow
                      </b-dropdown-item>

                      <!-- <b-dropdown-item  v-on:click="changeUserStatus(items.item.id, items.item.status)" v-if="items.item.status == 'deleted'">Activate</b-dropdown-item> -->
                      <b-dropdown-item
                        v-on:click="openDisableModel(items.item.id, items.item.status, 'deleted')"
                        v-if="items.item.status == 'deleted' && show('activate/inactivate')"
                        >Activate</b-dropdown-item
                      >

                      <b-dropdown-item
                        v-if="showBadge('award badge')"
                        @click="openInfoModel(items.item.id, 'badge')"
                        >Award Badge
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="showBadge('view')"
                        :to="{
                          name: 'jobseeker-badge-manage',
                          params: { fl_id: encrypt(items.item.id) },
                        }"
                        >Manage Badge</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="show('show referral code/Llnk')"
                        @click="generateBranchLink(items.item.id)"
                        >Show Referral Code / Link
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status != 'rejected' &&
                          items.item.status != 'deleted' &&
                          showCertificate('view')
                        "
                        :to="{
                          name: 'jobseeker-certificate-manage',
                          params: { id: encrypt(items.item.id) },
                        }"
                        >Manage Certificates</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status != 'rejected' &&
                          items.item.status != 'deleted' &&
                          show('logout from app')
                        "
                        @click="logoutFromApp(items.item.id)"
                        >Logout From App
                      </b-dropdown-item>
                      <!-- <b-dropdown-item :to="{name: 'jobseeker-transaction-view', params: {id: encrypt(items.item.id)}}">Transaction
                                            </b-dropdown-item>
                                            <b-dropdown-item :to="{name: 'jobseeker-payslip-view', params: {id: encrypt(items.item.id)}}">Payslip
                                            </b-dropdown-item> -->

                      <!-- <b-dropdown-ite && show('jobs')m @click="dpJobs(items.item.id)">DP Jobs
                                            </b-dropdown-item>
                                            <b-dropdown-item v-if="items.item.account_verification_status == 'completed'" :to="{name: 'incentive', params: {id: encrypt(items.item.id)}}">Incentive Details
                                            </b-dropdown-item>


                                            <b-dropdown-item v-if="items.item.account_verification_status == 'completed'" @click="openModel(items.item.id)">Generate Payslip
                                            </b-dropdown-item>

                                            <b-dropdown-item @click="openInfoModel(items.item.id,'wallet')">Recalculate Wallet
                                            </b-dropdown-item>
                                            <b-dropdown-item @click="logoutFromApp(items.item.id)">Logout From App
                                            </b-dropdown-item> -->
                      <!-- <b-dropdown-item :to="{name: 'jobseekerDPManagejobsAdmin', params: {id: encrypt(items.item.id)}}">DP Jobs
                                            </b-dropdown-item> -->
                    </b-dropdown>
                  </div>
                </template>
                <template #cell(toggleAction1)="items">
                  <div class="mobile_action_toggle">
                    <b-button
                      class="mobile_toggle_btn"
                      @click="showMobileActionModel(items.item)"
                    ></b-button>
                  </div>
                </template>
              </b-table>
              <nav class="pagination-box custom_pagination_border_radius">
                <div class="page-row-box d-inline-block">
                  <b-form-group id="input-group-4">
                    <span class="d-inline-block">Rows per page</span>
                    <span class="d-inline-block">
                      <b-form-select
                        v-model="form.rowsPerPagePending"
                        @change="getJobseekersPending"
                        class="common_small_font_thirteen"
                      >
                        <option>25</option>
                        <option>50</option>
                        <option>75</option>
                        <option>100</option>
                      </b-form-select></span
                    >
                  </b-form-group>
                </div>
                <div class="total-page-count d-inline-block">
                  <ul>
                    <li>
                      <span v-if="totalRowsPending != 0"
                        >{{ fromPending }} - {{ toPending }} of {{ totalRowsPending }}</span
                      >
                    </li>
                  </ul>
                </div>
                <b-pagination
                  :total-rows="totalRowsPending"
                  :per-page="perPagePending"
                  v-model="currentPagePending"
                  prev-text=""
                  next-text=""
                  class="common_pagination_button"
                  hide-goto-end-buttons
                />
              </nav>
            </div>
          </div>
        </b-tab>
        <b-tab :title="'REJECTED'" @click="changeRejectedStatus">
          <div class="row">
            <div
              class="col-xl-4 col-md-4 admin_listing_search mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
            >
              <div class="search-box custom_search_admin desktop_margin_top_zero">
                <!-- <b-input-group> -->
                <b-input-group-prepend style="height: 33px">
                  <b-form-input
                    id="searchKeywordRejected"
                    @keyup.enter.native="filterRejected = searchKeywordRejected"
                    :value="searchKeywordRejected"
                    @change="(v) => (searchKeywordRejected = v)"
                    placeholder="Search by Name, Email, Mobile, Nric"
                    class="common_small_font_thirteen"
                    :style="{
                      height: '31px !important',
                      lineHeight: '31px !important',
                      padding: '0 10px !important',
                    }"
                  ></b-form-input>
                  <b-button
                    class="btn points_events"
                    @click="filterRejected = searchKeywordRejected"
                    style="height: 31px"
                    ><i class="fa fa-search common_small_font_thirteen"></i
                  ></b-button>
                </b-input-group-prepend>
                <!-- </b-input-group> -->
              </div>
            </div>

            <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex search_common_margin_bottom_28">
              <!-- <span>DP Status:</span> -->
              <b-form-select
                v-model="singpass_signupFilterRejected"
                @change="changeRejectedStatus"
                class="common_small_font_thirteen common_select_focus common_padding_left_right_20 common_border_search height_unset desktop_margin_bottom_zero"
              >
                <option value="all">Signup Type</option>
                <option :key="signup.value" :value="signup.value" v-for="signup in signup_TypeAll">
                  {{ signup.label }}
                </option>
              </b-form-select>
            </div>
          </div>
          <div class="row">
            <div class="container my_new_container">
              <!-- <b-table ref="datatableRejected" show-empty striped hover responsive :items="getJobseekersRejected" :fields="fieldsRejected" :current-page="currentPageRejected" :per-page="perPageRejected" :filter="filterRejected" :sort-by.sync="sortByRejected" :sort-desc.sync="sortDescRejected" :sort-direction="sortDirectionRejected" class="mobile_margin_top_20 common_border_top_right_left_radius_4px static_page employer_list_table five_grid_tbl mobile_min_width_1000 new_box_tbl_mrgn desktop_margin_top_zero "> -->
              <b-table
                ref="datatableRejected"
                show-empty
                striped
                hover
                responsive
                :items="getJobseekersRejected"
                :fields="fieldsRejected"
                :current-page="currentPageRejected"
                :per-page="perPageRejected"
                :filter="filterRejected"
                :sort-by.sync="sortByRejected"
                :sort-desc.sync="sortDescRejected"
                :sort-direction="sortDirectionRejected"
                class="common_small_font_table new_jbs_full_over_flow common_border_top_right_left_radius_4px static_page new_layout_table_like_v1 new_box_tbl_mrgn desktop_margin_top_zero mobile_margin_top_20"
              >
                <template #cell(last_name)="items">
                  <div class="whole_div_img_and_name">
                    <div class="manage_user_img">
                      <b-img
                        v-if="items.item.thumbnail || items.item.profile_image"
                        :src="
                          items.item.thumbnail
                            ? items.item.thumbnail
                            : items.item.profile_image
                            ? items.item.profile_image
                            : webUrl + 'assets/images/user-avatar-small-01.png'
                        "
                        alt="User Icon"
                        height="100px"
                        width="100px"
                        class="square_img"
                      >
                      </b-img>
                      <b-img
                        v-else-if="
                          items.item.ft_profile_image_thumbnail || items.item.ft_profile_image
                        "
                        :src="
                          items.item.ft_profile_image_thumbnail
                            ? items.item.ft_profile_image_thumbnail
                            : items.item.ft_profile_image
                            ? items.item.ft_profile_image
                            : webUrl + 'assets/images/user-avatar-small-01.png'
                        "
                        alt="User Icon"
                        height="100px"
                        width="100px"
                        class="square_img"
                      >
                      </b-img>

                      <b-img
                        v-else
                        :src="webUrl + 'assets/images/user-avatar-small-01.png'"
                        alt="User Icon"
                        height="100px"
                        width="100px"
                        class="square_img"
                      >
                      </b-img>
                    </div>
                    <div class="user_name_div">
                      <b-link
                        :to="
                          show('view profile')
                            ? {
                                name: 'jobseeker-dp-profile-view',
                                params: { id: encrypt(items.item.id) },
                              }
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{ items.item.last_name | capitalize }}
                        {{ items.item.first_name | capitalize }}
                      </b-link>
                    </div>
                  </div>
                  <p class="grey-text">
                    {{ items.item.email }}
                  </p>
                </template>
                <template #cell(mobile_number)="items">
                  <b-link
                    :href="'https://wa.me/+65' + items.item.mobile_number"
                    target="_blank"
                    style="color: #4c8bf5"
                  >
                    {{ items.item.country_code }}-{{ items.item.mobile_number }}
                  </b-link>
                </template>
                <template #cell(users.created_at)="items">
                  {{ items.item.created_at | moment }}
                </template>
                <template #cell(rejection)="items">
                  {{ items.item.comment ? items.item.comment : "-" | capitalize }}
                </template>

                <template #cell(last_logged_in_at)="items">
                  {{ items.item.last_logged_in_at | moment }}
                </template>
                <template #cell(location_updated_at)="items">
                  {{ items.item.location_updated_at | moment }}
                </template>

                <template #cell(nric)="items">
                  {{ items.item.nric }}
                </template>
                <template #cell(status)="items">
                  <p class="green_status" v-if="items.item.status == 'active'">
                    {{ items.item.status | capitalize }}
                  </p>
                  <p class="orange__status" v-if="items.item.status == 'inactive'">
                    {{ items.item.status | capitalize }}
                  </p>
                  <p class="red_status" v-if="items.item.status == 'deleted'">
                    {{ items.item.status | capitalize }}
                  </p>
                  <p class="red_status" v-if="items.item.status == 'blacklisted'">
                    {{ items.item.status | capitalize }}
                  </p>
                </template>
                <template #cell(ft_account_status)="items">
                  <!-- {{items.item.ft_account_status | capitalize}} -->
                  <p
                    v-if="items.item.ft_account_status == 'incomplete'"
                    v-bind:class="{ red_status: items.item.ft_account_status == 'incomplete' }"
                  >
                    {{ items.item.ft_account_status | capitalize }}
                  </p>
                  <p
                    v-if="items.item.ft_account_status == 'completed'"
                    v-bind:class="{ green_status: items.item.ft_account_status == 'completed' }"
                  >
                    {{ items.item.ft_account_status | capitalize }}
                  </p>
                  <p
                    v-if="items.item.ft_account_status == 'pending'"
                    v-bind:class="{ orange__status: items.item.ft_account_status == 'pending' }"
                  >
                    {{ items.item.ft_account_status | capitalize }}
                  </p>
                  <p
                    v-if="items.item.ft_account_status == 'rejected'"
                    v-bind:class="{ red_status: items.item.ft_account_status == 'rejected' }"
                  >
                    {{ items.item.ft_account_status | capitalize }}
                  </p>
                </template>
                <template #cell(account_verification_status)="items">
                  <!-- {{items.item.account_verification_status | capitalize}} -->
                  <!-- <p v-if="items.item.account_verification_status == 'incomplete'" v-bind:class="{ 'red_status' : items.item.account_verification_status == 'incomplete'}">{{items.item.account_verification_status | capitalize}}</p>
                                    <p v-if="items.item.account_verification_status == 'completed'" v-bind:class="{ 'green_status' : items.item.account_verification_status == 'completed'}">{{items.item.account_verification_status | capitalize}}</p>
                                    <p v-if="items.item.account_verification_status == 'pending'" v-bind:class="{ 'orange__status' : items.item.account_verification_status == 'pending'}">{{items.item.account_verification_status | capitalize}}</p> -->
                  <p
                    v-if="items.item.account_verification_status == 'rejected'"
                    v-bind:class="{
                      red_status: items.item.account_verification_status == 'rejected',
                    }"
                  >
                    {{ items.item.account_verification_status | capitalize }}
                  </p>
                </template>
                <template #cell(toggleAction)="items">
                  <div class="toggle-action common_ul_radius toggle_margin_unset">
                    <!-- <b-dropdown class="mx-1" right text="Action" boundary="window"> -->
                    <b-dropdown class="mx-1" left text="Action" boundary="window">
                      <b-dropdown-item
                        v-if="show('view profile')"
                        :to="{
                          name: 'jobseeker-dp-profile-view',
                          params: { id: encrypt(items.item.id) },
                        }"
                        >View Profile
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="show('permanent delete user')"
                        v-on:click="permanentDeleteUser(items.item.id)"
                        >Permanent Delete Worker
                      </b-dropdown-item>
                      <!-- <b-dropdown-item v-if="items.item.ft_account_status != 'completed'" v-on:click="deleteJobseeker(items.item.id)">Delete</b-dropdown-item> -->
                      <b-dropdown-item
                        v-if="show('update rejection remark')"
                        @click="
                          openRejectionModel(
                            items.item.id,
                            items.item.comment != null ? items.item.comment_id : '',
                            items.item.comment != null ? items.item.comment : ''
                          )
                        "
                        >Update Rejection Remark
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </template>
                <template #cell(toggleAction1)="items">
                  <div class="mobile_action_toggle">
                    <b-button
                      class="mobile_toggle_btn"
                      @click="showMobileActionModel(items.item)"
                    ></b-button>
                  </div>
                </template>
              </b-table>
              <nav class="pagination-box custom_pagination_border_radius">
                <div class="page-row-box d-inline-block">
                  <b-form-group id="input-group-4">
                    <span class="d-inline-block">Rows per page</span>
                    <span class="d-inline-block">
                      <b-form-select
                        v-model="form.rowsPerPageRejected"
                        @change="getJobseekersRejected"
                        class="common_small_font_thirteen"
                      >
                        <option>25</option>
                        <option>50</option>
                        <option>75</option>
                        <option>100</option>
                      </b-form-select></span
                    >
                  </b-form-group>
                </div>
                <div class="total-page-count d-inline-block">
                  <ul>
                    <li>
                      <span v-if="totalRowsRejected != 0"
                        >{{ fromRejected }} - {{ toRejected }} of {{ totalRowsRejected }}</span
                      >
                    </li>
                  </ul>
                </div>
                <b-pagination
                  :total-rows="totalRowsRejected"
                  :per-page="perPageRejected"
                  v-model="currentPageRejected"
                  prev-text=""
                  next-text=""
                  class="common_pagination_button"
                  hide-goto-end-buttons
                />
              </nav>
            </div>
          </div>
        </b-tab>
        <b-tab :title="'DELETED'" @click="changeDeletedStatus">
          <div class="row">
            <div
              class="col-xl-4 col-md-4 admin_listing_search mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
            >
              <div class="search-box custom_search_admin desktop_margin_top_zero">
                <!-- <b-input-group> -->
                <b-input-group-prepend style="height: 33px">
                  <b-form-input
                    id="searchKeywordDeleted"
                    @keyup.enter.native="filterDeleted = searchKeywordDeleted"
                    :value="searchKeywordDeleted"
                    @change="(v) => (searchKeywordDeleted = v)"
                    placeholder="Search by Name, Email, Mobile, Nric"
                    class="common_small_font_thirteen"
                    :style="{
                      height: '31px !important',
                      lineHeight: '31px !important',
                      padding: '0 10px !important',
                    }"
                  ></b-form-input>
                  <b-button
                    class="btn points_events"
                    @click="filterDeleted = searchKeywordDeleted"
                    style="height: 31px"
                    ><i class="fa fa-search common_small_font_thirteen"></i
                  ></b-button>
                </b-input-group-prepend>
                <!-- </b-input-group> -->
              </div>
            </div>

            <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex search_common_margin_bottom_28">
              <!-- <span>DP Status:</span> -->
              <b-form-select
                v-model="userFTAccountStatusFilterDeleted"
                @change="changeDeletedStatus"
                class="common_small_font_thirteen common_select_focus common_padding_left_right_20 common_border_search height_unset desktop_margin_bottom_zero"
              >
                <option value="all">FT Status</option>
                <option :value="ft_state" v-for="ft_state in ftStatusAll">
                  {{ ft_state | capitalize }}
                </option>
              </b-form-select>
            </div>
          </div>
          <div class="row">
            <div class="container my_new_container">
              <!-- <b-table ref="datatableDeleted" show-empty striped hover responsive :items="getJobseekersDeleted" :fields="fieldsDeleted" :current-page="currentPageDeleted" :per-page="perPageDeleted" :filter="filterDeleted" :sort-by.sync="sortByDeleted" :sort-desc.sync="sortDescDeleted" :sort-direction="sortDirectionDeleted" class="mobile_margin_top_20 common_border_top_right_left_radius_4px static_page employer_list_table five_grid_tbl mobile_min_width_1000 new_box_tbl_mrgn desktop_margin_top_zero "> -->
              <b-table
                ref="datatableDeleted"
                show-empty
                striped
                hover
                responsive
                :items="getJobseekersDeleted"
                :fields="fieldsDeleted"
                :current-page="currentPageDeleted"
                :per-page="perPageDeleted"
                :filter="filterDeleted"
                :sort-by.sync="sortByDeleted"
                :sort-desc.sync="sortDescDeleted"
                :sort-direction="sortDirectionDeleted"
                class="common_small_font_table new_jbs_full_over_flow common_border_top_right_left_radius_4px static_page new_layout_table_like_v1 new_box_tbl_mrgn desktop_margin_top_zero mobile_margin_top_20"
              >
                <template #cell(last_name)="items">
                  <div class="whole_div_img_and_name">
                    <div class="manage_user_img">
                      <b-img
                        v-if="items.item.thumbnail || items.item.profile_image"
                        :src="
                          items.item.thumbnail
                            ? items.item.thumbnail
                            : items.item.profile_image
                            ? items.item.profile_image
                            : webUrl + 'assets/images/user-avatar-small-01.png'
                        "
                        alt="User Icon"
                        height="100px"
                        width="100px"
                        class="square_img"
                      >
                      </b-img>
                      <b-img
                        v-else-if="
                          items.item.ft_profile_image_thumbnail || items.item.ft_profile_image
                        "
                        :src="
                          items.item.ft_profile_image_thumbnail
                            ? items.item.ft_profile_image_thumbnail
                            : items.item.ft_profile_image
                            ? items.item.ft_profile_image
                            : webUrl + 'assets/images/user-avatar-small-01.png'
                        "
                        alt="User Icon"
                        height="100px"
                        width="100px"
                        class="square_img"
                      >
                      </b-img>

                      <b-img
                        v-else
                        :src="webUrl + 'assets/images/user-avatar-small-01.png'"
                        alt="User Icon"
                        height="100px"
                        width="100px"
                        class="square_img"
                      >
                      </b-img>
                    </div>
                    <div class="user_name_div">
                      <b-link
                        :to="
                          show('view profile')
                            ? {
                                name: 'jobseeker-dp-profile-view',
                                params: { id: encrypt(items.item.id) },
                              }
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{ items.item.last_name | capitalize }}
                        {{ items.item.first_name | capitalize }}
                      </b-link>
                    </div>
                  </div>
                  <p class="grey-text">
                    {{ items.item.email }}
                  </p>
                </template>
                <template #cell(mobile_number)="items">
                  <b-link
                    :href="'https://wa.me/+65' + items.item.mobile_number"
                    target="_blank"
                    style="color: #4c8bf5"
                  >
                    {{ items.item.country_code }}-{{ items.item.mobile_number }}
                  </b-link>
                </template>
                <template #cell(users.created_at)="items">
                  {{ items.item.created_at | moment }}
                </template>
                <template #cell(total_earning)="items">
                  ${{ items.item.total_earning ? items.item.total_earning : 0 | ifNumber }}
                </template>
                <template #cell(available_cashout)="items">
                  ${{ items.item.available_cashout ? items.item.available_cashout : 0 | ifNumber }}
                </template>
                <!-- <template #cell(cash)="items">
                                    ${{items.item.available_cashout ? items.item.available_cashout : 0 | ifNumber}}
                                </template> -->
                <template #cell(last_logged_in_at)="items">
                  {{ items.item.last_logged_in_at | moment }}
                </template>

                <template #cell(remarks)="items">
                  {{
                    items.item.other_description ? items.item.other_description : "N/A" | capitalize
                  }}
                </template>
                <template #cell(location_updated_at)="items">
                  {{ items.item.location_updated_at | moment }}
                </template>
                <!-- <template #cell(status)="items">
                                {{items.item.status | capitalize}}
                                </template> -->
                <template #cell(nric)="items">
                  {{ items.item.nric }}
                </template>
                <template #cell(ft_account_status)="items">
                  <!-- {{items.item.ft_account_status | capitalize}} -->
                  <p
                    v-if="items.item.ft_account_status == 'incomplete'"
                    v-bind:class="{ red_status: items.item.ft_account_status == 'incomplete' }"
                  >
                    {{ items.item.ft_account_status | capitalize }}
                  </p>
                  <p
                    v-if="items.item.ft_account_status == 'completed'"
                    v-bind:class="{ green_status: items.item.ft_account_status == 'completed' }"
                  >
                    {{ items.item.ft_account_status | capitalize }}
                  </p>
                  <p
                    v-if="items.item.ft_account_status == 'pending'"
                    v-bind:class="{ orange__status: items.item.ft_account_status == 'pending' }"
                  >
                    {{ items.item.ft_account_status | capitalize }}
                  </p>
                  <p
                    v-if="items.item.ft_account_status == 'rejected'"
                    v-bind:class="{ red_status: items.item.ft_account_status == 'rejected' }"
                  >
                    {{ items.item.ft_account_status | capitalize }}
                  </p>
                </template>
                <template #cell(account_verification_status)="items">
                  <!-- {{items.item.account_verification_status | capitalize}} -->
                  <p
                    v-if="items.item.account_verification_status == 'incomplete'"
                    v-bind:class="{
                      red_status: items.item.account_verification_status == 'incomplete',
                    }"
                  >
                    {{ items.item.account_verification_status | capitalize }}
                  </p>
                  <p
                    v-if="items.item.account_verification_status == 'completed'"
                    v-bind:class="{
                      green_status: items.item.account_verification_status == 'completed',
                    }"
                  >
                    {{ items.item.account_verification_status | capitalize }}
                  </p>
                  <p
                    v-if="items.item.account_verification_status == 'pending'"
                    v-bind:class="{
                      orange__status: items.item.account_verification_status == 'pending',
                    }"
                  >
                    {{ items.item.account_verification_status | capitalize }}
                  </p>
                  <p
                    v-if="items.item.account_verification_status == 'rejected'"
                    v-bind:class="{
                      red_status: items.item.account_verification_status == 'rejected',
                    }"
                  >
                    {{ items.item.account_verification_status | capitalize }}
                  </p>
                </template>
                <template #cell(toggleAction)="items">
                  <div class="toggle-action common_ul_radius toggle_margin_unset">
                    <!-- <b-dropdown class="mx-1" right text="Action" boundary="window"> -->
                    <b-dropdown class="mx-1" left text="Action" boundary="window">
                      <b-dropdown-item
                        v-if="show('view profile')"
                        :to="{
                          name: 'jobseeker-dp-profile-view',
                          params: { id: encrypt(items.item.id) },
                        }"
                        >View Profile
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          (items.item.status == 'active' || items.item.status == 'inactive') &&
                          show('edit worker')
                        "
                        :to="{ name: 'jobseekers-ft-edit', params: { id: encrypt(items.item.id) } }"
                        >Edit
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="items.item.account_verification_status == 'completed' && show('jobs')"
                        :to="{
                        name: 'jobseekerDPjobsAdmin',
                        params: { id: encrypt(items.item.id) },
                      }"
                        >DP Jobs
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          (items.item.status == 'active' || items.item.status == 'inactive') &&
                          show('edit worker')
                        "
                        :to="{ name: 'jobseekers-ft-edit', params: { id: encrypt(items.item.id) } }"
                        >Edit
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="items.item.account_verification_status == 'completed' && show('jobs')"
                        @click="dpJobs(items.item.id)"
                        >DP Jobs (Old)
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status == 'completed' &&
                          show('referred mobile workers')
                        "
                        :to="{
                          name: 'jobseeker-referred-fl',
                          params: { id: encrypt(items.item.id) },
                        }"
                        >Referral List
                      </b-dropdown-item>
                      <!-- <b-dropdown-item :to="{name: 'jobseekers-dp-edit', params: {id: encrypt(items.item.id)}}">Edit DP Profile
                                            </b-dropdown-item> -->
                      <!-- <b-dropdown-item v-on:click="changeUserStatus(items.item.id, items.item.status)" v-if="items.item.status == 'deleted'">Activate</b-dropdown-item> -->
                      <b-dropdown-item
                        v-on:click="openDisableModel(items.item.id, items.item.status, 'deleted')"
                        v-if="items.item.status == 'deleted' && show('activate/inactivate')"
                        >Activate</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="show('permanent delete user')"
                        v-on:click="permanentDeleteUser(items.item.id)"
                        >Permanent Delete Worker
                      </b-dropdown-item>
                      <!-- <b-dropdown-item v-if="items.item.status != 'blacklisted'" @click="openDisableModel(items.item.id, '', 'blacklist')">Blacklist
                                            </b-dropdown-item> -->

                      <b-dropdown-item
                        v-if="items.item.email && show('send password reset link')"
                        @click="sendResetPasswordLink(items.item.id)"
                        >Send Reset Password Link
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          items.item.email_verified == 'no' &&
                          items.item.email &&
                          show('send verify email link')
                        "
                        @click="sendEmailVerificationLink(items.item.id)"
                        >Send Email Verification Link
                      </b-dropdown-item>

                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status == 'completed' && show('incentive')
                        "
                        :to="{ name: 'incentive', params: { id: encrypt(items.item.id) } }"
                        >Incentive Details
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status == 'completed' &&
                          show('transactions')
                        "
                        :to="{
                          name: 'jobseeker-transaction-view',
                          params: { id: encrypt(items.item.id) },
                        }"
                        >Transactions
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status == 'completed' && show('payslip')
                        "
                        :to="{
                          name: 'jobseeker-payslip-view',
                          params: { id: encrypt(items.item.id) },
                        }"
                        >Payslip
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status == 'completed' &&
                          show('generate payslip')
                        "
                        @click="openModel(items.item.id)"
                        >Generate Payslip
                      </b-dropdown-item>
                      <!-- <b-dropdown-item v-if="items.item.email_verified == 'no' && items.item.email && show('verify email')" v-on:click="verifyEmail(items.item.id)">Verify Email</b-dropdown-item>
                                            <b-dropdown-item v-if="items.item.mobile_verified == 'no' && items.item.mobile_number && show('verify mobile')" v-on:click="verifyMobileNumber(items.item.id)">Verify Mobile Number</b-dropdown-item>
                                            <b-dropdown-item v-if="items.item.ft_account_status != 'completed'" v-on:click="deleteJobseeker(items.item.id)">Delete</b-dropdown-item>
                                            <b-dropdown-item :to="{name: 'jobseeker-certificate-manage', params: {id: encrypt(items.item.id)}}">Manage Certificate
                                            </b-dropdown-item>
                                            <b-dropdown-item v-if="items.item.status == 'active'" @click="openDisableModel(items.item.id, '', 'disable')">Disable
                                            </b-dropdown-item>
                                            <b-dropdown-item v-if="(items.item.subscribe_mail == 'yes') && show('subscribe/unsubscribe mailing list')" @click="subscribeUnsubscribeMail(items.item.id)">Unsubscribe Mailing List
                                            </b-dropdown-item>
                                            <b-dropdown-item v-else-if="show('subscribe/unsubscribe mailing list')" @click="subscribeUnsubscribeMail(items.item.id)">Subscribe Mailing List
                                            </b-dropdown-item>
                                            <b-dropdown-item @click="openReferralModel(items.item.id)">Assign Referral
                                            </b-dropdown-item>
                                            <b-dropdown-item @click="openInfoModel(items.item.id,'mobile')">Update Mobile Number
                                            </b-dropdown-item>
                                            <b-dropdown-item @click="openInfoModel(items.item.id,'email')">Update Email
                                            </b-dropdown-item>
                                            <b-dropdown-item @click="openInfoModel(items.item.id,'restrict',items.item.restricted_companies)">Ban From EM
                                            </b-dropdown-item>
                                            <b-dropdown-item @click="openInfoModel(items.item.id,'link',items.item.linked_companies)">View only EM
                                            </b-dropdown-item>
                                            <b-dropdown-item v-if="items.item.account_verification_status == 'completed' && items.item.status != 'deleted' && show('whitelist fl')" @click="openInfoModel(items.item.id,'whitelist',items.item.whitelisted_companies)">Whitelist FL </b-dropdown-item>

                                            <b-dropdown-item @click="openInfoModel(items.item.id,'wallet')">Recalculate Wallet
                                            </b-dropdown-item>
                                            <b-dropdown-item @click="logoutFromApp(items.item.id)">Logout From App
                                            </b-dropdown-item> -->
                      <!-- <b-dropdown-item :to="{name: 'jobseekerDPManagejobsAdmin', params: {id: encrypt(items.item.id)}}">DP Jobs
                                            </b-dropdown-item> -->
                    </b-dropdown>
                  </div>
                </template>
                <template #cell(toggleAction1)="items">
                  <div class="mobile_action_toggle">
                    <b-button
                      class="mobile_toggle_btn"
                      @click="showMobileActionModel(items.item)"
                    ></b-button>
                  </div>
                </template>
              </b-table>
              <nav class="pagination-box custom_pagination_border_radius">
                <div class="page-row-box d-inline-block">
                  <b-form-group id="input-group-4">
                    <span class="d-inline-block">Rows per page</span>
                    <span class="d-inline-block">
                      <b-form-select
                        v-model="form.rowsPerPageDeleted"
                        @change="getJobseekersDeleted"
                        class="common_small_font_thirteen"
                      >
                        <option>25</option>
                        <option>50</option>
                        <option>75</option>
                        <option>100</option>
                      </b-form-select></span
                    >
                  </b-form-group>
                </div>
                <div class="total-page-count d-inline-block">
                  <ul>
                    <li>
                      <span v-if="totalRowsDeleted != 0"
                        >{{ fromDeleted }} - {{ toDeleted }} of {{ totalRowsDeleted }}</span
                      >
                    </li>
                  </ul>
                </div>
                <b-pagination
                  :total-rows="totalRowsDeleted"
                  :per-page="perPageDeleted"
                  v-model="currentPageDeleted"
                  prev-text=""
                  next-text=""
                  class="common_pagination_button"
                  hide-goto-end-buttons
                />
              </nav>
            </div>
          </div>
        </b-tab>
        <b-tab :title="'FULL-TIME'" @click="changeFTStatus">
          <div class="row">
            <div
              class="col-xl-4 col-md-4 admin_listing_search mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
            >
              <div class="search-box custom_search_admin desktop_margin_top_zero">
                <!-- <b-input-group> -->
                <b-input-group-prepend>
                  <b-form-input
                    id="searchKeywordFT"
                    @keyup.enter.native="filterFT = searchKeywordFT"
                    :value="searchKeywordFT"
                    @change="(v) => (searchKeywordFT = v)"
                    placeholder="Search by Name, Email, Mobile, Nric"
                    class="common_small_font_thirteen date_style_size"
                  ></b-form-input>
                  <b-button
                    class="btn points_events"
                    @click="filterFT = searchKeywordFT"
                    style="height: 31px"
                    ><i class="fa fa-search common_small_font_thirteen"></i
                  ></b-button>
                </b-input-group-prepend>
                <!-- </b-input-group> -->
              </div>
            </div>
            <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex search_common_margin_bottom_28">
              <b-form-select
                v-model="userAccountStatusFilterFT"
                @change="changeFTStatus"
                class="common_small_font_thirteen common_select_focus common_padding_left_right_20 common_border_search height_unset desktop_margin_bottom_zero"
              >
                <option value="all">Account Status</option>
                <option :value="ac_status" v-for="ac_status in accountStatus">
                  {{ ac_status | capitalize }}
                </option>
              </b-form-select>
            </div>
            <!-- <div
              class="big_screen_calendr_font search_common_margin_bottom_28 mobile_tab_max_width_flex select-service-box date-picker-field clearfix col-xl-4 col-md-4 calender_z_index_9 calender_in_mobile new_calender_section"
            >
              <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">
                <date-range-picker
                  ref="picker"
                  :minDate="minDate"
                  :opens="center"
                  :locale-data="locale"
                  :auto-apply="auto"
                  v-model="dateRange"
                  @update="dateFilterFunctionFT"
                  :ranges="range"
                  class="common_width_100 common_small_font_thirteen manage_job_date_claneder common_date_range_picker_new"
                >
                  <div slot="input" slot-scope="picker">
                    {{ picker.startDate | datePicker1 }} - {{ picker.endDate | datePicker1 }}
                  </div>
                </date-range-picker>
              </b-form-group>
            </div> -->

            <div
              class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
            >
              <div class="search-box custom_search_admin desktop_margin_top_zero">
                <b-form-input
                  id="input-1"
                  type="date"
                  placeholder=""
                  v-model="dateRange.startDate"
                  class="desktop_margin_bottom_zero date_style_size"
                ></b-form-input>
              </div>
            </div>
            <div
              class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
            >
              <div class="search-box custom_search_admin desktop_margin_top_zero">
                <!-- <datepicker v-model="end_date" @input="changeTab()" :format="customFormatterTwo" id="scheduled_post" class=""></datepicker> -->
                <b-form-input
                  id="input-1"
                  type="date"
                  placeholder=""
                  v-model="dateRange.endDate"
                  class="desktop_margin_bottom_zero date_style_size"
                ></b-form-input>
              </div>
            </div>

            <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex search_common_margin_bottom_28">
              <!-- <span>DP Status:</span> -->
              <b-form-select
                v-model="userDPAccountStatusFilterFT"
                @change="changeFTStatus"
                class="common_small_font_thirteen common_select_focus common_padding_left_right_20 common_border_search height_unset desktop_margin_bottom_zero"
              >
                <option value="all">DP Status</option>
                <option :value="dp_state" v-for="dp_state in dpStatus">
                  {{ dp_state | capitalize }}
                </option>
              </b-form-select>
            </div>
            <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex search_common_margin_bottom_28">
              <!-- <span>DP Status:</span> -->
              <b-form-select
                v-model="userFTAccountStatusFilterFT"
                @change="changeFTStatus"
                class="common_small_font_thirteen common_select_focus common_padding_left_right_20 common_border_search height_unset desktop_margin_bottom_zero"
              >
                <option value="all">FT Status</option>
                <option :value="ft_state" v-for="ft_state in ftStatus">
                  {{ ft_state | capitalize }}
                </option>
              </b-form-select>
            </div>

            <div
              class="col-xl-12 col-md-12 search_common_margin_bottom_28 mobile_tab_max_width_flex"
            >
              <b-form-group id="input-group-6" class="ss desktop_margin_bottom_zero">
                <button
                  class="common_small_font_thirteen customize_filter_btn btn green-border-btn line_height_22 desktop_margin_right_zero desktop_margin_left_zero"
                  @click="openFilter()"
                  style="background-color: #4c8bf5; color: white"
                >
                  Customize filter
                </button>
                <button
                  class="ml-3 mobile_margin_left_10 common_small_font_thirteen customize_filter_btn button ripple-effect search_all_red_btn daily_deployment_red_btn"
                  @click="dateFilterFunctionFT()"
                  style="background-color: #4c8bf5; color: white"
                >
                  Search
                </button>
                <b-button
                  v-if="
                    filterFT ||
                    searchKeywordFT ||
                    userAccountStatusFilterFT != 'all' ||
                    userDPAccountStatusFilterFT != 'all' ||
                    userFTAccountStatusFilterFT != 'all' ||
                    dateRange.startDate != start_date_filter ||
                    dateRange.endDate != end_date_filter ||
                    dateRangeLastLogin.startDate != start_date_filter_login ||
                    dateRangeLastLogin.endDate != end_date_filter_login
                  "
                  @click="resetFilterFT()"
                  class="ml-3 new_reset_text mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero"
                  style="background-color: red; margin-top: 20px"
                  ><span class="mobile_reset">Reset filters</span></b-button
                >
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="container my_new_container">
              <!-- <b-table ref="datatableFT" show-empty striped hover responsive :items="getJobseekersFT" :fields="fieldsAllPendingFT" :current-page="currentPageFT" :per-page="perPageFT" :filter="filterFT" :sort-by.sync="sortByFT" :sort-desc.sync="sortDescFT" :sort-direction="sortDirectionFT" class="mobile_margin_top_20 common_border_top_right_left_radius_4px static_page employer_list_table five_grid_tbl mobile_min_width_1000 new_box_tbl_mrgn desktop_margin_top_zero "> -->
              <b-table
                ref="datatableFT"
                show-empty
                striped
                hover
                responsive
                :items="getJobseekersFT"
                :fields="fieldsPendingFT"
                :current-page="currentPageFT"
                :per-page="perPageFT"
                :filter="filterFT"
                :sort-by.sync="sortByFT"
                :sort-desc.sync="sortDescFT"
                :sort-direction="sortDirectionFT"
                class="common_small_font_table new_jbs_full_over_flow common_border_top_right_left_radius_4px static_page new_layout_table_like_v1 new_box_tbl_mrgn desktop_margin_top_zero mobile_margin_top_20"
              >
                <template #cell(last_name)="items">
                  <div class="whole_div_img_and_name">
                    <div class="manage_user_img">
                      <b-img
                        v-if="items.item.thumbnail || items.item.profile_image"
                        :src="
                          items.item.thumbnail
                            ? items.item.thumbnail
                            : items.item.profile_image
                            ? items.item.profile_image
                            : webUrl + 'assets/images/user-avatar-small-01.png'
                        "
                        alt="User Icon"
                        height="100px"
                        width="100px"
                        class="square_img"
                      >
                      </b-img>
                      <b-img
                        v-else-if="
                          items.item.ft_profile_image_thumbnail || items.item.ft_profile_image
                        "
                        :src="
                          items.item.ft_profile_image_thumbnail
                            ? items.item.ft_profile_image_thumbnail
                            : items.item.ft_profile_image
                            ? items.item.ft_profile_image
                            : webUrl + 'assets/images/user-avatar-small-01.png'
                        "
                        alt="User Icon"
                        height="100px"
                        width="100px"
                        class="square_img"
                      >
                      </b-img>

                      <b-img
                        v-else
                        :src="webUrl + 'assets/images/user-avatar-small-01.png'"
                        alt="User Icon"
                        height="100px"
                        width="100px"
                        class="square_img"
                      >
                      </b-img>
                    </div>
                    <div class="user_name_div">
                      <b-link
                        :to="
                          show('view profile')
                            ? {
                                name: 'jobseeker-dp-profile-view',
                                params: { id: encrypt(items.item.id) },
                              }
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{ items.item.last_name | capitalize }}
                        {{ items.item.first_name | capitalize }}
                      </b-link>
                    </div>
                  </div>
                  <p class="grey-text">
                    {{ items.item.email }}
                  </p>
                </template>
                <template #cell(mobile_number)="items">
                  <b-link
                    :href="'https://wa.me/+65' + items.item.mobile_number"
                    target="_blank"
                    style="color: #4c8bf5"
                  >
                    {{ items.item.country_code }}-{{ items.item.mobile_number }}
                  </b-link>
                </template>

                <template #cell(users.created_at)="items">
                  {{ items.item.created_at | moment }}
                </template>
                <template #cell(other_description)="items">
                  {{ items.item.other_description ? items.item.other_description : "-" }}
                </template>
                <template #cell(last_logged_in_at)="items">
                  {{ items.item.last_logged_in_at | moment }}
                </template>
                <template #cell(location_updated_at)="items">
                  {{ items.item.location_updated_at | moment }}
                </template>
                <template #cell(available_cashout)="items">
                  ${{ items.item.available_cashout ? items.item.available_cashout : 0 | ifNumber }}
                </template>
                <template #cell(nric)="items">
                  {{ items.item.nric }}
                </template>
                <template #cell(applied_jobs_count)="items">
                  {{ items.item.applied_jobs_count }}
                </template>
                <template #cell(completed_jobs_count)="items">
                  {{ items.item.completed_jobs_count }}
                </template>
                <template #cell(status)="items">
                  <p class="green_status" v-if="items.item.status == 'active'">
                    {{ items.item.status | capitalize }}
                  </p>
                  <p class="orange__status" v-if="items.item.status == 'inactive'">
                    {{ items.item.status | capitalize }}
                  </p>
                  <p class="red_status" v-if="items.item.status == 'deleted'">
                    {{ items.item.status | capitalize }}
                  </p>
                  <p class="red_status" v-if="items.item.status == 'blacklisted'">
                    {{ items.item.status | capitalize }}
                  </p>
                </template>

                <template #cell(ft_account_status)="items">
                  <!-- {{items.item.ft_account_status | capitalize}} -->
                  <p
                    v-if="items.item.ft_account_status == 'incomplete'"
                    v-bind:class="{ red_status: items.item.ft_account_status == 'incomplete' }"
                  >
                    {{ items.item.ft_account_status | capitalize }}
                  </p>
                  <p
                    v-if="items.item.ft_account_status == 'completed'"
                    v-bind:class="{ green_status: items.item.ft_account_status == 'completed' }"
                  >
                    {{ items.item.ft_account_status | capitalize }}
                  </p>
                  <p
                    v-if="items.item.ft_account_status == 'pending'"
                    v-bind:class="{ orange__status: items.item.ft_account_status == 'pending' }"
                  >
                    {{ items.item.ft_account_status | capitalize }}
                  </p>
                  <p
                    v-if="items.item.ft_account_status == 'rejected'"
                    v-bind:class="{ red_status: items.item.ft_account_status == 'rejected' }"
                  >
                    {{ items.item.ft_account_status | capitalize }}
                  </p>
                </template>
                <template #cell(account_verification_status)="items">
                  <!-- {{items.item.account_verification_status | capitalize}} -->
                  <p
                    v-if="items.item.account_verification_status == 'incomplete'"
                    v-bind:class="{
                      red_status: items.item.account_verification_status == 'incomplete',
                    }"
                  >
                    {{ items.item.account_verification_status | capitalize }}
                  </p>
                  <p
                    v-if="items.item.account_verification_status == 'completed'"
                    v-bind:class="{
                      green_status: items.item.account_verification_status == 'completed',
                    }"
                  >
                    {{ items.item.account_verification_status | capitalize }}
                  </p>
                  <p
                    v-if="items.item.account_verification_status == 'pending'"
                    v-bind:class="{
                      orange__status: items.item.account_verification_status == 'pending',
                    }"
                  >
                    {{ items.item.account_verification_status | capitalize }}
                  </p>
                  <p
                    v-if="items.item.account_verification_status == 'rejected'"
                    v-bind:class="{
                      red_status: items.item.account_verification_status == 'rejected',
                    }"
                  >
                    {{ items.item.account_verification_status | capitalize }}
                  </p>
                </template>
                <template #cell(toggleAction)="items">
                  <div class="toggle-action common_ul_radius toggle_margin_unset">
                    <!-- <b-dropdown class="mx-1" right text="Action" boundary="window"> -->
                    <b-dropdown class="mx-1" left text="Action" boundary="window">
                      <b-dropdown-item
                        v-if="show('view profile')"
                        :to="{
                          name: 'jobseeker-dp-profile-view',
                          params: { id: encrypt(items.item.id) },
                        }"
                        >View Profile
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          (items.item.status == 'active' || items.item.status == 'inactive') &&
                          show('edit worker')
                        "
                        :to="{ name: 'jobseekers-ft-edit', params: { id: encrypt(items.item.id) } }"
                        >Edit
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="items.item.account_verification_status == 'completed' && show('jobs')"
                        :to="{
                        name: 'jobseekerDPjobsAdmin',
                        params: { id: encrypt(items.item.id) },
                      }"
                        >DP Jobs
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="items.item.account_verification_status == 'completed' && show('jobs')"
                        @click="dpJobs(items.item.id)"
                        >DP Jobs (Old)
                      </b-dropdown-item>
                      <!-- <b-dropdown-item :to="{name: 'jobseekers-dp-edit', params: {id: encrypt(items.item.id)}}">Edit DP Profile
                                            </b-dropdown-item> -->
                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status != 'rejected' &&
                          items.item.status != 'deleted' &&
                          show('assign referral')
                        "
                        @click="openReferralModel(items.item.id)"
                        >Refer a Friend
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status == 'completed' &&
                          show('referred mobile workers')
                        "
                        :to="{
                          name: 'jobseeker-referred-fl',
                          params: { id: encrypt(items.item.id) },
                        }"
                        >Referral List</b-dropdown-item
                      >

                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status != 'rejected' &&
                          items.item.status != 'deleted' &&
                          show('restrict companies')
                        "
                        @click="
                          openInfoModel(items.item.id, 'restrict', items.item.restricted_companies)
                        "
                        >Ban From EM
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status != 'rejected' &&
                          items.item.status != 'deleted' &&
                          show('link companies')
                        "
                        @click="openInfoModel(items.item.id, 'link', items.item.linked_companies)"
                        >View Only EM
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status == 'completed' &&
                          items.item.status != 'deleted' &&
                          show('whitelist fl')
                        "
                        @click="
                          openInfoModel(
                            items.item.id,
                            'whitelist',
                            items.item.whitelisted_companies
                          )
                        "
                        >Whitelist FL
                      </b-dropdown-item>

                      <b-dropdown-item
                        v-if="items.item.status == 'active' && show('temporary suspend')"
                        @click="openDisableModel(items.item.id, '', 'disable')"
                        >Temporary Suspend
                      </b-dropdown-item>
                      <!-- <b-dropdown-item v-if="items.item.status != 'blacklisted'" @click="openDisableModel(items.item.id, '', 'blacklist')">Blacklist
                                            </b-dropdown-item> -->
                      <b-dropdown-item
                        v-on:click="openDisableModel(items.item.id, items.item.status, 'active')"
                        v-if="items.item.status == 'active' && show('activate/inactivate')"
                        >Inactivate</b-dropdown-item
                      >
                      <!-- <b-dropdown-item v-else-if="items.item.status == 'inactive'" v-on:click="changeUserStatus(items.item.id, items.item.status)">Activate
                                            </b-dropdown-item> -->
                      <b-dropdown-item
                        v-else-if="items.item.status == 'inactive' && show('activate/inactivate')"
                        v-on:click="openDisableModel(items.item.id, items.item.status, 'inactive')"
                        >Activate
                      </b-dropdown-item>
                      <!-- <b-dropdown-item v-if="items.item.ft_account_status != 'completed'" v-on:click="deleteJobseeker(items.item.id)">Delete</b-dropdown-item> -->
                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status != 'rejected' &&
                          items.item.status != 'deleted' &&
                          show('delete worker')
                        "
                        @click="openDisableModel(items.item.id, '', 'jobseeker_deleted')"
                        >Delete</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="show('permanent delete user')"
                        v-on:click="permanentDeleteUser(items.item.id)"
                        >Permanent Delete Worker
                      </b-dropdown-item>

                      <b-dropdown-item
                        v-if="items.item.email && show('send password reset link')"
                        @click="sendResetPasswordLink(items.item.id)"
                        >Send Reset Password Link
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          items.item.email_verified == 'no' &&
                          items.item.email &&
                          show('send verify email link')
                        "
                        @click="sendEmailVerificationLink(items.item.id)"
                        >Send Email Verification Link
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          items.item.email_verified == 'no' &&
                          items.item.email &&
                          show('verify email')
                        "
                        v-on:click="verifyEmail(items.item.id)"
                        >Verify Email</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="
                          items.item.mobile_verified == 'no' &&
                          items.item.mobile_number &&
                          show('verify mobile')
                        "
                        v-on:click="verifyMobileNumber(items.item.id)"
                        >Verify Mobile Number</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status != 'rejected' &&
                          items.item.status != 'deleted' &&
                          show('update email')
                        "
                        @click="openInfoModel(items.item.id, 'email')"
                        >Update Email
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status != 'rejected' &&
                          items.item.status != 'deleted' &&
                          show('update mobile')
                        "
                        @click="openInfoModel(items.item.id, 'mobile')"
                        >Update Mobile Number
                      </b-dropdown-item>

                      <b-dropdown-item
                        v-if="
                          items.item.subscribe_mail == 'yes' &&
                          show('subscribe/unsubscribe mailing list')
                        "
                        @click="subscribeUnsubscribeMail(items.item.id)"
                        >Unsubscribe Mailing List
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-else-if="show('subscribe/unsubscribe mailing list')"
                        @click="subscribeUnsubscribeMail(items.item.id)"
                        >Subscribe Mailing List
                      </b-dropdown-item>

                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status == 'completed' &&
                          show('recalculate wallet')
                        "
                        @click="openInfoModel(items.item.id, 'wallet')"
                        >Recalculate Wallet
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status == 'completed' &&
                          show('transactions')
                        "
                        :to="{
                          name: 'jobseeker-transaction-view',
                          params: { id: encrypt(items.item.id) },
                        }"
                        >Transactions
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status == 'completed' && show('incentive')
                        "
                        :to="{ name: 'incentive', params: { id: encrypt(items.item.id) } }"
                        >Incentive Details
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status == 'completed' && show('payslip')
                        "
                        :to="{
                          name: 'jobseeker-payslip-view',
                          params: { id: encrypt(items.item.id) },
                        }"
                        >Payslip
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status == 'completed' &&
                          show('generate payslip')
                        "
                        @click="openModel(items.item.id)"
                        >Generate Payslip
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="items.item.account_number && show('delete bank')"
                        @click="deletePaynow(items.item.id, 'bank')"
                        >Delete Bank
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="items.item.paynow_mobile_number && show('delete paynow')"
                        @click="deletePaynow(items.item.id, 'payout')"
                        >Delete Paynow
                      </b-dropdown-item>

                      <b-dropdown-item
                        v-if="showBadge('award badge')"
                        @click="openInfoModel(items.item.id, 'badge')"
                        >Award Badge
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="showBadge('view')"
                        :to="{
                          name: 'jobseeker-badge-manage',
                          params: { fl_id: encrypt(items.item.id) },
                        }"
                        >Manage Badge</b-dropdown-item
                      >
                      <b-dropdown-item
                        v-if="show('show referral code/Llnk')"
                        @click="generateBranchLink(items.item.id)"
                        >Show Referral Code / Link
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          items.item.account_verification_status != 'rejected' &&
                          items.item.status != 'deleted' &&
                          showCertificate('view')
                        "
                        :to="{
                          name: 'jobseeker-certificate-manage',
                          params: { id: encrypt(items.item.id) },
                        }"
                        >Manage Certificates
                      </b-dropdown-item>
                      <!-- <b-dropdown-item :to="{name: 'jobseekerDPManagejobsAdmin', params: {id: encrypt(items.item.id)}}">DP Jobs
                                            </b-dropdown-item> -->
                      <b-dropdown-item
                        v-if="show('logout from app')"
                        @click="logoutFromApp(items.item.id)"
                        >Logout From App
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </template>
                <template #cell(toggleAction1)="items">
                  <div class="mobile_action_toggle">
                    <b-button
                      class="mobile_toggle_btn"
                      @click="showMobileActionModel(items.item)"
                    ></b-button>
                  </div>
                </template>
              </b-table>
              <nav class="pagination-box custom_pagination_border_radius">
                <div class="page-row-box d-inline-block">
                  <b-form-group id="input-group-4">
                    <span class="d-inline-block">Rows per page</span>
                    <span class="d-inline-block">
                      <b-form-select
                        v-model="form.rowsPerPageFT"
                        @change="getJobseekersFT"
                        class="common_small_font_thirteen"
                      >
                        <option>25</option>
                        <option>50</option>
                        <option>75</option>
                        <option>100</option>
                      </b-form-select></span
                    >
                  </b-form-group>
                </div>
                <div class="total-page-count d-inline-block">
                  <ul>
                    <li>
                      <span v-if="totalRowsFT != 0"
                        >{{ fromFT }} - {{ toFT }} of {{ totalRowsFT }}</span
                      >
                    </li>
                  </ul>
                </div>
                <b-pagination
                  :total-rows="totalRowsFT"
                  :per-page="perPageFT"
                  v-model="currentPageFT"
                  prev-text=""
                  next-text=""
                  class="common_pagination_button"
                  hide-goto-end-buttons
                />
              </nav>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>

    <!-- Spacer -->
    <!-- <div class="margin-top-70"></div> -->
    <!-- Spacer / End-->
    <!-- Row / End -->
    <b-modal
      ref="disable-modal"
      :title="updateInfoTitleFL"
      content-class="common_model_header common_model_title"
      hide-footer
      modal-class="background_custom_class"
    >
      <!-- <b-form> -->
      <div class="edit-form-box model_margin_top_zero model_legend">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12">
              <div class="form-map-box">
                <b-col lg="0" md="0" v-if="model_type == 'disable'" class="submit-field">
                  <b-form-group
                    id="input-group-6"
                    label="Re-enable Date"
                    class="model_margin_bottom_zero required model_date_picker"
                  >
                    <datepicker
                      v-model="renable_date"
                      :disabled-dates="disabledFromDate2()"
                      :format="customFormatterOne"
                      class=""
                    ></datepicker>
                    <span class="input-icon"
                      ><span class="count"></span
                      ><b-img
                        v-bind:src="webUrl + 'assets/images/calendar-grey-icon02.svg'"
                        alt="Calander Icon"
                      ></b-img
                    ></span>
                  </b-form-group>
                </b-col>
                <b-col lg="0" md="0" class="submit-field">
                  <b-form-group id="input-group-5" label="Remarks" class="model_margin_bottom_zero">
                    <b-form-textarea
                      id="textarea-3"
                      type="textarea"
                      placeholder=""
                      :value="remarks"
                      @change="(v) => (remarks = v)"
                      class="model_box_shadow_unset model_margin_bottom_zero model_text_area"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
                <b-col lg="12" md="12" v-if="model_type == 'blacklist'">
                  <b-form-group>
                    <b-form-checkbox
                      v-model="withNoEmailBlacklist"
                      @input="withNoEmailFuncBlacklist($event)"
                      ><strong>With No Email</strong></b-form-checkbox
                    >
                  </b-form-group>
                </b-col>
                <b-col lg="0" md="0" class="">
                  <div slot="footer" class="form-btn common_crop_reset_btn">
                    <b-button
                      type="submit"
                      variant="success"
                      v-if="model_type == 'disable'"
                      @click="disableJobseeker()"
                      class="model_margin_left_zero model_common_bt_success_color"
                      >Submit</b-button
                    >
                    <b-button
                      type="submit"
                      variant="success"
                      v-else-if="model_type == 'blacklist'"
                      @click="blacklistJobseeker()"
                      class="model_margin_left_zero model_common_bt_success_color"
                      >Submit</b-button
                    >
                    <b-button
                      type="submit"
                      variant="success"
                      v-else-if="model_type == 'jobseeker_deleted'"
                      @click="deleteJobseeker()"
                      class="model_margin_left_zero model_common_bt_success_color"
                      >Submit</b-button
                    >
                    <b-button
                      type="submit"
                      variant="success"
                      v-else
                      @click="changeUserStatus(update_user_id, usrStatus)"
                      class="model_margin_left_zero model_common_bt_success_color"
                      >Submit</b-button
                    >
                    <b-button
                      @click="hideModel()"
                      variant="light"
                      class="model_common_bt_lite_color"
                      >Cancel</b-button
                    >
                  </div>
                </b-col>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- </b-form> -->
    </b-modal>
    <b-modal
      ref="filter-modal"
      title="Select Filter"
      content-class="common_model_header common_model_title"
      hide-footer
      modal-class="background_custom_class"
    >
      <!-- <b-form> -->
      <div class="edit-form-box model_margin_top_zero model_legend">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12">
              <div class="form-map-box">
                <b-col lg="0" md="0" class="model_submit_field employer_multiple_radio">
                  <b-form-group
                    id="input-group-8"
                    label="Sign-up Type"
                    class="category-radio model_margin_bottom_zero"
                  >
                    <b-form-radio v-model="singpass_signup" name="singpass_signup" value="false"
                      >Normal</b-form-radio
                    >
                    <b-form-radio v-model="singpass_signup" name="singpass_signup" value="true"
                      >Singpass</b-form-radio
                    >
                  </b-form-group>
                  <!-- <b-form-group id="input-group-8" label="Duration " class="category-radio model_margin_bottom_zero">
                                                <b-form-radio v-model="duration" name="duration" value="1">1-15</b-form-radio>
                                                <b-form-radio v-model="duration" name="duration" value="2">16-30</b-form-radio>

                                            </b-form-group> -->
                </b-col>
                <b-col lg="0" md="0" class="model_submit_field employer_multiple_radio">
                  <b-form-group
                    id="input-group-8"
                    label="Gender"
                    class="category-radio model_margin_bottom_zero"
                  >
                    <b-form-radio v-model="gender" name="gender" value="male">Male</b-form-radio>
                    <b-form-radio v-model="gender" name="gender" value="female"
                      >Female</b-form-radio
                    >
                  </b-form-group>
                  <!-- <b-form-group id="input-group-8" label="Duration " class="category-radio model_margin_bottom_zero">
                                                <b-form-radio v-model="duration" name="duration" value="1">1-15</b-form-radio>
                                                <b-form-radio v-model="duration" name="duration" value="2">16-30</b-form-radio>

                                            </b-form-group> -->
                </b-col>
                    <b-col lg="0" md="0" class="model_submit_field employer_multiple_radio">
                  <b-form-group
                    id="input-group-8"
                    label="Opt Out Whatsapp Blast"
                    class="category-radio model_margin_bottom_zero"
                  >
                    <b-form-radio v-model="whatsapp_blast_opt_out" name="on" value="on">ON</b-form-radio>
                    <b-form-radio v-model="whatsapp_blast_opt_out" name="off" value="off"
                      >OFF</b-form-radio
                    >
                  </b-form-group>
                </b-col>
                <b-col lg="0" md="0" class="model_submit_field employer_multiple_radio">
                  <b-form-group label="Preferred Category">
                    <multiselect
                      v-model="cat"
                      id="ajax"
                      label="name"
                      track-by="id"
                      @input="updateval(cat.id)"
                      placeholder="Type to search category"
                      open-direction="bottom"
                      :options="categories"
                      :multiple="true"
                      :searchable="true"
                      :clear-on-select="true"
                      :close-on-select="true"
                      :allow-empty="true"
                      class="job_list_multi_select model_multi_select"
                    >
                      <span slot="noResult">No Data Found.</span>
                    </multiselect>
                  </b-form-group>
                </b-col>

                <b-col lg="0" md="0" class="model_submit_field employer_multiple_radio">
                  <b-form-group label="Race">
                    <multiselect
                      v-model="racearr"
                      id="ajax"
                      label="title"
                      track-by="id"
                      @input="updateval(racearr.id)"
                      placeholder="Type to search race"
                      open-direction="bottom"
                      :options="race"
                      :multiple="true"
                      :searchable="true"
                      :clear-on-select="true"
                      :close-on-select="true"
                      :allow-empty="true"
                      class="job_list_multi_select model_multi_select"
                    >
                      <span slot="noResult">No Data Found.</span>
                    </multiselect>
                  </b-form-group>
                </b-col>

                <b-col lg="0" md="0" class="model_submit_field employer_multiple_radio">
                  <b-form-group label="Age Group">
                    <b-form-checkbox value="15-25" v-model="age"
                      ><strong>15-25</strong></b-form-checkbox
                    >
                    <b-form-checkbox value="26-35" v-model="age"
                      ><strong>26-35</strong></b-form-checkbox
                    >
                    <b-form-checkbox value="36-45" v-model="age"
                      ><strong>36-45</strong></b-form-checkbox
                    >
                    <b-form-checkbox value="46-60" v-model="age"
                      ><strong>46-60</strong></b-form-checkbox
                    >
                  </b-form-group>
                </b-col>
                <b-col lg="0" md="0" class="model_submit_field employer_multiple_radio">
                  <b-form-group label="Preferred Certificate">
                    <multiselect
                      v-model="certificateArr"
                      id="ajax"
                      label="title"
                      track-by="id"
                      @input="updateval(certificateArr.id)"
                      placeholder="Type to search certificate"
                      open-direction="bottom"
                      :options="certificates"
                      :multiple="true"
                      :searchable="true"
                      :clear-on-select="true"
                      :close-on-select="true"
                      :allow-empty="true"
                      class="job_list_multi_select model_multi_select"
                    >
                      <span slot="noResult">No Data Found.</span>
                    </multiselect>
                  </b-form-group>
                </b-col>
                <div slot="footer" class="form-btn common_crop_reset_btn center_layout_btn">
                  <b-button @click="hideModel()" variant="light" class="model_common_bt_lite_color"
                    >Cancel</b-button
                  >
                  <b-button
                    type="submit"
                    variant="success"
                    class="model_common_bt_success_color"
                    @click="customFilterFunction(currentTab)"
                    >Submit</b-button
                  >
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- </b-form> -->
    </b-modal>
    <b-modal
      ref="info-modal"
      :title="updateInfoTitle"
      hide-footer
      content-class="common_model_header common_model_title"
      modal-class="background_custom_class"
    >
      <!-- <b-form> -->
      <div class="edit-form-box model_margin_top_zero">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12">
              <div class="form-map-box model_legend">
                <b-col
                  lg="8"
                  md="8"
                  v-if="updateInfoTitle == 'Update Email'"
                  class="model_padding_left_zero model_padding_right_zero"
                >
                  <b-form-group id="input-group-0" label="Email" class="required submit-field">
                    <b-form-input
                      id="input-0"
                      type="text"
                      placeholder=""
                      :value="email"
                      @change="(v) => (email = v)"
                      class="model_border_and_box_shadow"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col
                  lg="8"
                  md="8"
                  v-if="updateInfoTitle == 'Update Mobile'"
                  class="model_padding_left_zero model_padding_right_zero"
                >
                  <b-form-group
                    id="input-group-0"
                    label="Mobile Number"
                    class="required submit-field"
                  >
                    <b-form-input
                      id="input-0"
                      type="text"
                      placeholder=""
                      :value="mobile_number"
                      @change="(v) => (mobile_number = v)"
                      class="model_border_and_box_shadow"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg="0" md="0" v-if="updateInfoTitle == 'Update Wallet'" class="submit-field">
                  <b-form-group
                    id="input-group-0"
                    label="Current Wallet"
                    class="model_margin_bottom_zero"
                  >
                    <b-form-input
                      id="input-0"
                      type="text"
                      placeholder=""
                      disabled
                      v-model="current_wallet"
                      class="model_border_and_box_shadow"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg="0" md="0" v-if="updateInfoTitle == 'Update Wallet'" class="submit-field">
                  <b-form-group
                    id="input-group-0"
                    label="Recalculated Amount"
                    class="model_margin_bottom_zero"
                  >
                    <b-form-input
                      id="input-0"
                      type="text"
                      placeholder=""
                      disabled
                      v-model="recal_wallet"
                      class="model_border_and_box_shadow"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg="0" md="0" v-if="updateInfoTitle == 'Update Wallet'" class="submit-field">
                  <b-form-group
                    id="input-group-0"
                    label="New Wallet Amount"
                    class="required model_margin_bottom_zero"
                  >
                    <b-form-input
                      id="input-0"
                      type="text"
                      placeholder=""
                      :value="new_wallet"
                      @change="(v) => (new_wallet = v)"
                      class="input-with-border model_border_and_box_shadow"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg="0" md="0">
                  <div slot="footer" class="form-btn common_crop_reset_btn">
                    <b-button
                      type="submit"
                      variant="success"
                      v-if="updateInfoTitle == 'Update Wallet' && designation != 'Customer Service'"
                      @click="saveWallet()"
                      class="model_common_bt_success_color"
                      >Submit</b-button
                    >
                    <b-button
                      type="submit"
                      variant="success"
                      v-else-if="updateInfoTitle != 'Update Wallet'"
                      @click="submitUpdateInfo()"
                      class="model_common_bt_success_color"
                      >Submit</b-button
                    >
                    <b-button
                      @click="hideModel()"
                      variant="light"
                      class="model_common_bt_lite_color"
                      >Cancel</b-button
                    >
                  </div>
                </b-col>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- </b-form> -->
    </b-modal>
    <b-modal
      ref="badge-modal"
      title="Award Badge"
      hide-footer
      modal-class="background_custom_class"
      content-class="common_model_header common_model_title"
    >
      <div class="edit-form-box model_margin_top_zero model_legend">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12" class="submit-field">
              <b-form-group
                id="input-group-8"
                label="Select Badge"
                class="required model_margin_bottom_zero"
              >
                <multiselect
                  v-model="awarded_badge"
                  id="ajax"
                  label="name"
                  track-by="id"
                  placeholder="Type to search badge"
                  open-direction="bottom"
                  :options="allBadges"
                  :multiple="true"
                  :searchable="true"
                  :clear-on-select="true"
                  :close-on-select="true"
                  :value="awarded_badge"
                  :allow-empty="true"
                  class="job_list_multi_select model_multi_select"
                >
                  <span slot="noResult">No Data Found.</span>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12" md="12" class="submit-field">
              <b-form-group
                id="input-group-8"
                label="Select Companies"
                class="required model_margin_bottom_zero"
              >
                <multiselect
                  v-model="awarded_badge_companies"
                  id="ajax"
                  label="name"
                  track-by="id"
                  placeholder="Type to search company"
                  open-direction="bottom"
                  :options="awardedBadgeCompaniesList"
                  :multiple="false"
                  :searchable="true"
                  :clear-on-select="true"
                  :close-on-select="true"
                  :value="awarded_badge_companies"
                  :allow-empty="true"
                  @input="mapCompId(awarded_badge_companies)"
                  class="job_list_multi_select model_multi_select"
                >
                  <span slot="noResult">No Data Found.</span>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12" md="12" class="submit-field">
              <b-form-group id="input-group-0" label="Remarks" class="model_margin_bottom_zero">
                <b-form-textarea
                  id="textarea-3"
                  type="textarea"
                  placeholder=""
                  :value="badge_remarks"
                  @change="(v) => (badge_remarks = v)"
                  class="model_box_shadow_unset model_margin_bottom_zero model_text_area"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="text-md-left text-center desktop_margin_top_zero common_crop_reset_btn">
            <b-button
              type="button"
              @click="saveBadges()"
              variant="success"
              class="model_common_bt_success_color text-uppercase x-md btn-shadow desktop_margin_left_zero desktop_margin_right_zero"
              >Update</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      ref="restrict-modal"
      title="Ban From EM"
      hide-footer
      modal-class="background_custom_class"
      content-class="common_model_header common_model_title"
    >
      <div class="edit-form-box model_margin_top_zero model_legend">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12" class="submit-field">
              <b-form-group
                id="input-group-8"
                label="Select Companies"
                class="required model_margin_bottom_zero"
              >
                <multiselect
                  v-model="restricted_companies"
                  id="ajax"
                  label="name"
                  track-by="id"
                  placeholder="Type to search company"
                  open-direction="bottom"
                  :options="companiesList"
                  :multiple="true"
                  :searchable="true"
                  :clear-on-select="true"
                  :close-on-select="true"
                  :value="restricted_companies"
                  :allow-empty="true"
                  @input="mapCompId(restricted_companies)"
                  class="job_list_multi_select model_multi_select"
                >
                  <span slot="noResult">No Data Found.</span>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12" md="12" class="submit-field">
              <b-form-group id="input-group-0" label="Remarks" class="model_margin_bottom_zero">
                <b-form-textarea
                  id="textarea-3"
                  type="textarea"
                  placeholder=""
                  :value="restrict_remark"
                  @change="(v) => (restrict_remark = v)"
                  class="model_box_shadow_unset model_margin_bottom_zero model_text_area"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="text-md-left text-center desktop_margin_top_zero common_crop_reset_btn">
            <!-- <b-button v-if="designation != 'Customer Service'" type="button" @click="saveCompanies('restrict')" variant="success" class="model_common_bt_success_color text-uppercase x-md btn-shadow desktop_margin_left_zero desktop_margin_right_zero">Update</b-button> -->
            <b-button
              type="button"
              @click="saveCompanies('restrict')"
              variant="success"
              class="model_common_bt_success_color text-uppercase x-md btn-shadow desktop_margin_left_zero desktop_margin_right_zero"
              >Update</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      ref="whitelist-modal"
      title="Whitelist Companies"
      hide-footer
      modal-class="background_custom_class"
      content-class="common_model_header common_model_title"
    >
      <div class="edit-form-box model_margin_top_zero model_legend">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12" class="submit-field">
              <b-form-group
                id="input-group-8"
                label="Select Companies"
                class="required model_margin_bottom_zero"
              >
                <multiselect
                  v-model="whitelisted_companies"
                  id="ajax"
                  :disabled="whitelist_disable_companies"
                  label="name"
                  track-by="id"
                  placeholder="Type to search company"
                  open-direction="bottom"
                  :options="companiesList"
                  :multiple="true"
                  :searchable="true"
                  :clear-on-select="true"
                  :close-on-select="true"
                  :value="whitelisted_companies"
                  :allow-empty="true"
                  @input="mapCompId(whitelisted_companies)"
                  class="job_list_multi_select model_multi_select"
                >
                  <span slot="noResult">No Data Found.</span>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12" md="12">
              <b-form-group>
                <b-form-checkbox
                  v-model="whitelist_all_companies"
                  @input="whitelistAllCompanies($event)"
                  ><strong>All Companies</strong></b-form-checkbox
                >
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12" md="12" class="submit-field">
              <b-form-group id="input-group-0" label="Remarks" class="model_margin_bottom_zero">
                <b-form-textarea
                  id="textarea-3"
                  type="textarea"
                  placeholder=""
                  :value="whitelist_remark"
                  @change="(v) => (whitelist_remark = v)"
                  class="model_box_shadow_unset model_margin_bottom_zero model_text_area"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <div
            class="pt-2 mt-3 text-md-left text-center model_margin_top_zero common_crop_reset_btn"
          >
            <b-button
              type="button"
              v-if="designation != 'Customer Service'"
              @click="saveWhitelistCompanies('whitelist')"
              variant="success"
              class="model_common_bt_success_color text-uppercase x-md btn-shadow desktop_margin_left_zero desktop_margin_right_zero"
              >Update</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      ref="link-modal"
      title="View Only Companies"
      hide-footer
      modal-class="background_custom_class"
      content-class="common_model_header common_model_title"
    >
      <div class="edit-form-box model_margin_top_zero model_legend">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12" class="submit-field">
              <b-form-group
                id="input-group-8"
                label="Select Companies"
                class="required model_margin_bottom_zero"
              >
                <multiselect
                  v-model="linked_companies"
                  id="ajax"
                  label="name"
                  track-by="id"
                  placeholder="Type to search company"
                  open-direction="bottom"
                  :options="companiesList"
                  :multiple="true"
                  :searchable="true"
                  :clear-on-select="true"
                  :close-on-select="true"
                  :value="linked_companies"
                  :allow-empty="true"
                  @input="mapCompId(linked_companies)"
                  class="job_list_multi_select model_multi_select"
                >
                  <span slot="noResult">No Data Found.</span>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12" md="12" class="submit-field">
              <b-form-group id="input-group-0" label="Remarks" class="model_margin_bottom_zero">
                <b-form-textarea
                  id="textarea-3"
                  type="textarea"
                  placeholder=""
                  :value="view_remark"
                  @change="(v) => (view_remark = v)"
                  class="model_box_shadow_unset model_margin_bottom_zero model_text_area"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <div
            class="pt-2 mt-3 text-md-left text-center model_margin_top_zero common_crop_reset_btn"
          >
            <!-- <b-button type="button" v-if="designation != 'Customer Service'" @click="saveCompanies('link')" variant="success" class="model_common_bt_success_color text-uppercase x-md btn-shadow desktop_margin_left_zero desktop_margin_right_zero">Update</b-button> -->
            <b-button
              type="button"
              @click="saveCompanies('link')"
              variant="success"
              class="model_common_bt_success_color text-uppercase x-md btn-shadow desktop_margin_left_zero desktop_margin_right_zero"
              >Update</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      ref="payslip-modal"
      title="Select Month"
      hide-footer
      modal-class="background_custom_class"
      content-class="common_model_header common_model_title"
    >
      <!-- <b-form> -->
      <div class="edit-form-box model_margin_top_zero model_legend model_half_calender">
        <div class="profile-repeate form-box-field">
          <b-row>
            <!-- <b-col lg="12" md="12"> -->
            <div class="form-map-box">
              <b-col lg="12" md="12">
                <b-form-group
                  id="input-group-6"
                  label="Payslip Month & Year"
                  class="model_margin_bottom_zero required"
                >
                  <datepicker
                    v-model="month"
                    :format="customFormatterTwo"
                    initial-view="year"
                    minimum-view="month"
                    :disabled-dates="disabledFromDateTwo"
                  ></datepicker>
                  <span class="input-icon"
                    ><b-img
                      v-bind:src="webUrl + 'assets/images/calendar-grey-icon02.svg'"
                      alt="Calander Icon"
                    ></b-img
                  ></span>
                </b-form-group>
              </b-col>
              <b-col lg="12" md="12">
                <b-form-group>
                  <b-form-checkbox v-model="withNoEmail" @input="withNoEmailFunc($event)"
                    ><strong>With No Email</strong></b-form-checkbox
                  >
                </b-form-group>
              </b-col>
              <b-col lg="12" md="12">
                <div slot="footer" class="form-btn common_crop_reset_btn">
                  <b-button
                    type="submit"
                    variant="success"
                    @click="generatePayslip()"
                    class="model_common_bt_success_color"
                    >Submit</b-button
                  >
                  <b-button @click="hideModel()" variant="light" class="model_common_bt_lite_color"
                    >Cancel</b-button
                  >
                </div>
              </b-col>
            </div>
            <!-- </b-col> -->
          </b-row>
        </div>
      </div>
      <!-- </b-form> -->
    </b-modal>
    <b-modal
      ref="referral-modal"
      title="Referred To User"
      hide-footer
      modal-class="background_custom_class"
      content-class="common_model_header common_model_title"
    >
      <!-- <b-form> -->
      <div class="edit-form-box model_margin_top_zero model_legend">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12">
              <div class="form-map-box">
                <div class="submit-field">
                  <b-form-group
                    id="input-group-8"
                    label="Select User"
                    class="required model_margin_bottom_zero"
                  >
                    <multiselect
                      v-model="referred_to"
                      id="ajax"
                      label="full_name"
                      track-by="id"
                      placeholder="Type to search user"
                      open-direction="bottom"
                      :options="referralsList"
                      :multiple="false"
                      :searchable="true"
                      :clear-on-select="true"
                      :close-on-select="true"
                      :value="referred_to"
                      :allow-empty="true"
                      @input="mapReferralId(referred_to)"
                      @open="handleOpenReferralFL"
                      @search-change="handleSearchChangeFL"
                      class="job_list_multi_select model_multi_select"
                    >
                      <span slot="noResult">No Data Found.</span>
                    </multiselect>
                    <!-- <multiselect
                      v-model="referred_to"
                      id="ajax"
                      label="full_name"
                      track-by="id"
                      placeholder="Type to search user"
                      open-direction="bottom"
                      :options="referralsList"
                      :multiple="true"
                      :searchable="true"
                      :clear-on-select="true"
                      :close-on-select="true"
                      :value="referred_to"
                      :allow-empty="true"
                      @input="mapReferralId(referred_to)"
                      @open="handleOpenReferralFL"
                      @search-change="handleSearchChangeFL"
                      class="job_list_multi_select model_multi_select"
                    >
                      <span slot="noResult">No Data Found.</span>
                    </multiselect> -->
                  </b-form-group>
                </div>
                <!-- <b-col lg="12" md="12"> -->
                <b-form-group id="input-group-7" label="Referal Month">
                  <datepicker
                    v-model="referral_month"
                    :disabled-dates="disabledFromDate"
                    :format="customFormatterOne"
                    clear-button=""
                  ></datepicker>
                  <span class="input-icon calender_input_icon"
                    ><b-img
                      :src="webUrl + 'assets/images/calendar-grey-icon02.svg'"
                      alt="Calander Icon"
                    ></b-img
                  ></span>
                </b-form-group>
                <!-- </b-col> -->
                <!-- <b-col lg="12" md="12"> -->
                <div slot="footer" class="form-btn common_crop_reset_btn">
                  <b-button
                    type="submit"
                    variant="success"
                    @click="referredTosubmit()"
                    class="model_common_bt_success_color"
                    >Submit</b-button
                  >
                  <b-button @click="hideModel()" variant="light" class="model_common_bt_lite_color"
                    >Cancel</b-button
                  >
                </div>
                <!-- </b-col> -->
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- </b-form> -->
    </b-modal>
    <b-modal
      ref="reject-modal"
      title="Update Rejection Remark"
      hide-footer
      modal-class="background_custom_class"
      content-class="common_model_header common_model_title"
    >
      <div class="edit-form-box model_margin_top_zero model_legend">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12">
              <b-form-group id="input-group-3" label="Rejection Remark">
                <b-form-textarea
                  id="textarea-3"
                  type="textarea"
                  placeholder="Enter Comment"
                  :value="comment"
                  @change="(v) => (comment = v)"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="text-md-left text-center common_crop_reset_btn">
            <b-button
              type="button"
              @click="updateRejectionRemark()"
              variant="danger"
              class="model_common_bt_success_color text-uppercase x-md btn-shadow"
              >Update</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      ref="remarks-modal"
      title="Fulltime Remarks"
      hide-footer
      modal-class="background_custom_class"
      content-class="common_model_header common_model_title"
    >
      <!-- <b-form> -->
      <div class="edit-form-box model_margin_top_zero model_legend">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12">
              <div class="form-map-box submit-field">
                <!-- <b-col lg="6" md="6"> -->
                <p class="model_margin_bottom_zero">{{ fulltime_remarks }}</p>
                <!-- </b-col><br> -->
              </div>
              <div slot="footer" class="form-btn model_margin_top_zero common_crop_reset_btn">
                <b-button @click="hideModel()" variant="dark" class="model_margin_left_zero"
                  >Close</b-button
                >
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- </b-form> -->
    </b-modal>
    <b-modal
      ref="transaction-modal"
      title="Add New transaction"
      hide-footer
      modal-class="background_custom_class"
      content-class="common_model_header common_model_title"
    >
      <div class="edit-form-box model_margin_top_zero">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="6" md="6">
              <b-form-group
                id="input-group-3"
                class="contact-fields mobile_margin_bottom_zero submit-field"
              >
                <span class="custom-dropdown w-100">
                  <b-form-select
                    v-model="transaction_type"
                    class="model_margin_bottom_zero"
                    @change="getTransactionSubType()"
                  >
                    <option value="add_back">Add Back</option>
                    <option value="deduct">Deduct</option>
                    <option value="purchase">Purchase</option>
                    <!-- <option value="campaign">Campaign</option> -->
                  </b-form-select>
                </span>
              </b-form-group>
            </b-col>
            <b-col lg="6" md="6">
              <b-form-group id="input-group-5" label="" class="required submit-field">
                <b-form-input
                  id="input-5"
                  type="text"
                  placeholder="Amount"
                  :value="deduct_amount"
                  @change="(v) => (deduct_amount = v)"
                  maxlength="1000"
                  class="input-with-border model_border_and_box_shadow"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="12" md="12" v-if="sub_transactions.length > 0">
              <b-form-group
                id="input-group-3"
                class="contact-fields mobile_margin_bottom_zero submit-field"
              >
                <span class="custom-dropdown w-100">
                  <b-form-select v-model="transaction_sub_type" class="model_margin_bottom_zero">
                    <option value="" disabled>Select Transaction Type</option>
                    <option :value="transaction.id" v-for="transaction in sub_transactions">
                      {{ transaction.title | capitalize }}
                    </option>
                  </b-form-select>
                </span>
              </b-form-group>
            </b-col>
            <b-col lg="12" md="12">
              <div class="submit-field">
                <datepicker
                  placeholder="Transaction Date"
                  :disabled-dates="disabledDateFromTransaction"
                  v-model="transaction_created_at"
                  :format="customFormatterOne"
                  clear-button=""
                  class="ticket_close_btn_from_date"
                ></datepicker>
                <span
                  class="input-icon calender_input_icon obtainment_date_calender common_pop_up_calnder_icon"
                  ><b-img
                    :src="webUrl + 'assets/images/calendar-grey-icon02.svg'"
                    alt="Calander Icon"
                  ></b-img
                ></span>
              </div>
            </b-col>
            <b-col lg="12" md="12">
              <b-form-group id="input-group-3" label="" class="submit-field">
                <b-form-textarea
                  id="textarea-3"
                  type="textarea"
                  placeholder="Full company name - job ID - Reason e.g., Eng Leng Contractors Pte Ltd-ID123456-Taxi Claim"
                  :value="transaction_remarks"
                  @change="(v) => (transaction_remarks = v)"
                  class="model_border_and_box_shadow model_textarea_height model_margin_bottom_zero"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col lg="12" md="12">
              <b-form-group
                id="input-group-3"
                class="contact-fields mobile_margin_bottom_zero submit-field"
              >
                <span class="custom-dropdown w-100">
                  <b-form-select v-model="billing" class="model_margin_bottom_zero">
                    <option value="" disabled>Select Bill Type</option>
                    <option :value="bt.id" v-for="bt in billing_types">
                      {{ bt.name | capitalize }}
                    </option>
                  </b-form-select>
                </span>
              </b-form-group>
            </b-col>
            <b-col lg="12" md="12">
              <b-form-group id="input-group-3" label="" class="submit-field">
                <b-form-textarea
                  id="textarea-3"
                  type="textarea"
                  placeholder="Internal Remarks - Finance view for reference, what is this transaction meant for"
                  :value="transaction_internal_remarks"
                  @change="(v) => (transaction_internal_remarks = v)"
                  class="model_border_and_box_shadow model_textarea_height model_margin_bottom_zero"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
            <!-- <b-col lg="12" md="12" class="submit-field">
                                <div class="upload-form-group">
                                    <h5 id="title-group">Upload Image </h5>
                                    <b-form-group id="link-group" class="required">
                                        <div class="single-image-upload my_single_img common_pointer_class emp_img_one height_unset_desktop mobile_max_width_image" :class="form.selectedImage.image ? 'if_image_exist' : 'if_image_not_exist'">
                                            <b-form-file class="customUpload form-control-file" name="Image" accept="image/*" @change="onImageChange" ref="image"  v-model="form.image"></b-form-file>
                                            <span v-bind:style="form.selectedImage.image ? 'background-color: #fff !important;' : 'background-color:#F2FBFC !important;' ">
                                                <b-img v-if='form.selectedImage.image' :src="form.selectedImage.image" alt="image" class="actual_img"></b-img>
                                                <b-img v-else :src="webUrl+'assets/images/upload-icon.svg'" alt="image" class="dummy_img"></b-img>
                                            </span>
                                        </div>
                                    </b-form-group>
                                </div>
                            </b-col> -->
            <div
              class="col-md-12 col-sm-12 common_text_align_end_desktop mobile_max_width_and_flex"
            >
              <div class="textare_pdng">
                <vue-upload-multiple-image
                  @upload-success="uploadImageSuccess"
                  :maxImage="maxImage"
                  :multiple="multiple"
                  @before-remove="beforeRemove"
                  @edit-image="editImage"
                  :showEdit="false"
                  :data-images="images"
                  dragText="Browse"
                  browseText="Select Files"
                  primaryText=""
                  markIsPrimaryText=""
                  popupText="Image"
                  v-model="form.document"
                  accept=".jpg, .png, .jpeg"
                  limit-exceeded="2"
                  class="image_glr new_post_image add_transaction_img"
                >
                </vue-upload-multiple-image>
              </div>
            </div>
          </b-row>
          <div class="text-md-right new_text_align_end common_crop_reset_btn">
            <b-button
              type="button"
              v-if="designation != 'Customer Service'"
              @click="deduct_transaction()"
              variant="danger"
              class="mr-0 model_common_bt_success_color text-uppercase x-md btn-shadow"
              >Update</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="multiple-transaction-modal"
      title="Add MUltiple transaction"
      hide-footer
      modal-class="background_custom_class"
      content-class="common_model_header common_model_title"
    >
      <div class="edit-form-box model_margin_top_zero">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12">
              <b-form-group
                id="input-group-3"
                class="contact-fields mobile_margin_bottom_zero submit-field"
              >
                <span class="custom-dropdown w-100">
                  <multiselect
                    v-model="freelancer_id"
                    id="ajax"
                    label="full_name"
                    track-by="id"
                    placeholder="Type to search user"
                    open-direction="bottom"
                    :options="freelancerList"
                    :multiple="true"
                    :searchable="true"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :value="referred_to"
                    :allow-empty="true"
                    class="job_list_multi_select model_multi_select"
                  >
                    <span slot="noResult">No Data Found.</span>
                  </multiselect>
                </span>
              </b-form-group>
            </b-col>

            <b-col lg="6" md="6">
              <b-form-group
                id="input-group-3"
                class="contact-fields mobile_margin_bottom_zero submit-field"
              >
                <span class="custom-dropdown w-100">
                  <b-form-select
                    v-model="transaction_type"
                    class="model_margin_bottom_zero"
                    @change="getTransactionSubType()"
                  >
                    <option value="add_back">Add Back</option>
                    <option value="deduct">Deduct</option>
                    <!-- <option value="campaign">Campaign</option> -->
                    <option value="purchase">Purchase</option>
                  </b-form-select>
                </span>
              </b-form-group>
            </b-col>
            <b-col lg="6" md="6">
              <b-form-group id="input-group-5" label="" class="required submit-field">
                <b-form-input
                  id="input-5"
                  type="text"
                  placeholder="Amount"
                  :value="deduct_amount"
                  @change="(v) => (deduct_amount = v)"
                  maxlength="1000"
                  class="input-with-border model_border_and_box_shadow"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="12" md="12" v-if="sub_transactions.length > 0">
              <b-form-group
                id="input-group-3"
                class="contact-fields mobile_margin_bottom_zero submit-field"
              >
                <span class="custom-dropdown w-100">
                  <b-form-select v-model="transaction_sub_type" class="model_margin_bottom_zero">
                    <option value="" disabled>Select Transaction Type</option>
                    <option :value="transaction.id" v-for="transaction in sub_transactions">
                      {{ transaction.title | capitalize }}
                    </option>
                  </b-form-select>
                </span>
              </b-form-group>
            </b-col>
            <b-col lg="12" md="12">
              <div class="submit-field">
                <datepicker
                  placeholder="Transaction Date"
                  :disabled-dates="disabledDateFromTransaction"
                  v-model="transaction_created_at"
                  :format="customFormatterOne"
                  clear-button=""
                  class="ticket_close_btn_from_date"
                ></datepicker>
                <span
                  class="input-icon calender_input_icon obtainment_date_calender common_pop_up_calnder_icon"
                  ><b-img
                    :src="webUrl + 'assets/images/calendar-grey-icon02.svg'"
                    alt="Calander Icon"
                  ></b-img
                ></span>
              </div>
            </b-col>
            <b-col lg="12" md="12">
              <b-form-group id="input-group-3" label="" class="submit-field">
                <b-form-textarea
                  id="textarea-3"
                  type="textarea"
                  placeholder="Full company name - job ID - Reason e.g., Eng Leng Contractors Pte Ltd-ID123456-Taxi Claim"
                  :value="transaction_remarks"
                  @change="(v) => (transaction_remarks = v)"
                  class="model_border_and_box_shadow model_textarea_height model_margin_bottom_zero"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col lg="12" md="12">
              <b-form-group
                id="input-group-3"
                class="contact-fields mobile_margin_bottom_zero submit-field"
              >
                <span class="custom-dropdown w-100">
                  <b-form-select v-model="billing" class="model_margin_bottom_zero">
                    <option value="" disabled>Select Bill Type</option>
                    <option :value="bt.id" v-for="bt in billing_types">
                      {{ bt.name | capitalize }}
                    </option>
                  </b-form-select>
                </span>
              </b-form-group>
            </b-col>
            <b-col lg="12" md="12">
              <b-form-group id="input-group-3" label="" class="submit-field">
                <b-form-textarea
                  id="textarea-3"
                  type="textarea"
                  placeholder="Internal Remarks - Finance view for reference, what is this transaction meant for"
                  :value="transaction_internal_remarks"
                  @change="(v) => (transaction_internal_remarks = v)"
                  class="model_border_and_box_shadow model_textarea_height model_margin_bottom_zero"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
            <!-- <b-col lg="12" md="12" class="submit-field">
                                <div class="upload-form-group">
                                    <h5 id="title-group">Upload Image </h5>
                                    <b-form-group id="link-group" class="required">
                                        <div class="single-image-upload my_single_img common_pointer_class emp_img_one height_unset_desktop mobile_max_width_image" :class="form.selectedImage.image ? 'if_image_exist' : 'if_image_not_exist'">
                                            <b-form-file class="customUpload form-control-file" name="Image" accept="image/*" @change="onImageChange" ref="image"  v-model="form.image"></b-form-file>
                                            <span v-bind:style="form.selectedImage.image ? 'background-color: #fff !important;' : 'background-color:#F2FBFC !important;' ">
                                                <b-img v-if='form.selectedImage.image' :src="form.selectedImage.image" alt="image" class="actual_img"></b-img>
                                                <b-img v-else :src="webUrl+'assets/images/upload-icon.svg'" alt="image" class="dummy_img"></b-img>
                                            </span>
                                        </div>
                                    </b-form-group>
                                </div>
                            </b-col> -->
            <div
              class="col-md-12 col-sm-12 common_text_align_end_desktop mobile_max_width_and_flex"
            >
              <div class="textare_pdng">
                <vue-upload-multiple-image
                  @upload-success="uploadImageSuccess"
                  :maxImage="maxImage"
                  :multiple="multiple"
                  @before-remove="beforeRemove"
                  @edit-image="editImage"
                  :showEdit="false"
                  :data-images="images"
                  dragText="Browse"
                  browseText="Select Files"
                  primaryText=""
                  markIsPrimaryText=""
                  popupText="Image"
                  v-model="form.document"
                  accept=".jpg, .png, .jpeg"
                  limit-exceeded="2"
                  class="image_glr new_post_image add_transaction_img"
                >
                </vue-upload-multiple-image>
              </div>
            </div>
          </b-row>
          <div class="text-md-right new_text_align_end common_crop_reset_btn">
            <b-button
              type="button"
              v-if="designation != 'Customer Service'"
              @click="deduct_multiple_transaction()"
              variant="danger"
              class="mr-0 model_common_bt_success_color text-uppercase x-md btn-shadow"
              >Update</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="crop-modal"
      title="Edit Image"
      hide-footer
      :no-close-on-backdrop="noClose"
      content-class="common_model_header common_model_title"
      modal-class="background_custom_class"
    >
      <b-form @submit="onSubmitImg">
        <template>
          <div>
            <div class="content" @change="setImage">
              <section class="cropper-area">
                <div class="img-cropper">
                  <vue-cropper
                    ref="cropper"
                    :aspect-ratio="0 / 0"
                    :src="tempLogo"
                    preview=".preview"
                  />
                </div>
              </section>
            </div>
          </div>
          <div class="pt-2 mt-3 text-md-left text-center common_crop_reset_btn">
            <b-button
              variant="success"
              class="text-uppercase x-md btn-shadow"
              @click.prevent="cropImage"
              >Crop</b-button
            >
            <b-button variant="danger" class="text-uppercase x-md btn-shadow" @click.prevent="reset"
              >Reset</b-button
            >
          </div>
        </template>
      </b-form>
    </b-modal>
    <b-modal
      v-if="screenWidth <= 999"
      id="modal-jobseeker-admin"
      ref="jobseeker-mobile-action-model"
      title=""
      content-class="common_model_header common_model_title"
      hide-footer
      modal-class="background_custom_class common_mobile_model_classes"
    >
      <b-dropdown-item
        v-if="show('view profile')"
        :to="{ name: 'jobseeker-dp-profile-view', params: { id: encrypt(modelitems.id) } }"
        >View Profile
      </b-dropdown-item>
      <b-dropdown-item
        v-if="
          modelitems.account_verification_status != 'rejected' &&
          (modelitems.status == 'active' || modelitems.status == 'inactive') &&
          show('edit worker')
        "
        :to="{ name: 'jobseekers-ft-edit', params: { id: encrypt(modelitems.id) } }"
        >Edit
      </b-dropdown-item>
      <b-dropdown-item
        v-if="
          (modelitems.account_verification_status == 'completed' ||
            modelitems.account_verification_status == 'pending') &&
          show('jobs')
        "
        :to="{
                        name: 'jobseekerDPjobsAdmin',
                        params: { id: encrypt(items.item.id) },
                      }"
        >DP Jobs
      </b-dropdown-item>
      <b-dropdown-item
        v-if="
          (modelitems.account_verification_status == 'completed' ||
            modelitems.account_verification_status == 'pending') &&
          show('jobs')
        "
       @click="dpJobs(items.item.id)"
        >DP Jobs (Old)
      </b-dropdown-item>
      <b-dropdown-item
        v-if="
          modelitems.account_verification_status != 'rejected' &&
          modelitems.status != 'deleted' &&
          show('assign referral')
        "
        @click="openReferralModel(modelitems.id)"
        >Refer a Friend
      </b-dropdown-item>
      <b-dropdown-item
        v-if="
          (modelitems.account_verification_status == 'completed' ||
            modelitems.account_verification_status == 'pending') &&
          show('referred mobile workers')
        "
        :to="{ name: 'jobseeker-referred-fl', params: { id: encrypt(modelitems.id) } }"
        >Referral List
      </b-dropdown-item>

      <b-dropdown-item
        v-if="
          modelitems.account_verification_status != 'rejected' &&
          modelitems.status != 'deleted' &&
          show('restrict companies')
        "
        @click="openInfoModel(modelitems.id, 'restrict', modelitems.restricted_companies)"
        >Ban From EM
      </b-dropdown-item>
      <b-dropdown-item
        v-if="
          modelitems.account_verification_status != 'rejected' &&
          modelitems.status != 'deleted' &&
          show('link companies')
        "
        @click="openInfoModel(modelitems.id, 'link', modelitems.linked_companies)"
        >View Only EM
      </b-dropdown-item>
      <b-dropdown-item
        v-if="
          modelitems.account_verification_status == 'completed' &&
          modelitems.status != 'deleted' &&
          show('whitelist fl')
        "
        @click="openInfoModel(modelitems.id, 'whitelist', modelitems.whitelisted_companies)"
        >Whitelist FL
      </b-dropdown-item>

      <!-- <b-dropdown-item :to="{name: 'jobseekers-dp-edit', params: {id: encrypt(modelitems.id)}}">Edit DP Profile
                </b-dropdown-item> -->
      <b-dropdown-item
        v-if="
          modelitems.account_verification_status == 'rejected' &&
          modelitems.status != 'deleted' &&
          show('update rejection remark')
        "
        @click="
          openRejectionModel(
            modelitems.id,
            modelitems.comment != null ? modelitems.comment_id : '',
            modelitems.comment != null ? modelitems.comment : ''
          )
        "
        >Update Rejection Remark
      </b-dropdown-item>
      <b-dropdown-item
        v-if="
          modelitems.status == 'active' &&
          modelitems.account_verification_status != 'rejected' &&
          show('temporary suspend')
        "
        @click="openDisableModel(modelitems.id, '', 'disable')"
        >Temporary Suspend
      </b-dropdown-item>
      <b-dropdown-item
        v-if="modelitems.status == 'blacklisted' && show('reactive')"
        v-on:click="openDisableModel(modelitems.id, modelitems.status, 'reactive')"
        >Reactive
      </b-dropdown-item>
      <b-dropdown-item
        v-else-if="show('blacklist')"
        @click="openDisableModel(modelitems.id, '', 'blacklist')"
        >Blacklist
      </b-dropdown-item>
      <b-dropdown-item
        v-on:click="openDisableModel(modelitems.id, modelitems.status, 'active')"
        v-if="
          modelitems.status == 'active' &&
          modelitems.account_verification_status != 'rejected' &&
          show('activate/inactivate')
        "
        >Inactivate</b-dropdown-item
      >
      <!-- <b-dropdown-item v-else-if="modelitems.status == 'inactive' && modelitems.account_verification_status != 'rejected'" v-on:click="changeUserStatus(modelitems.id, modelitems.status)">Activate
                </b-dropdown-item> -->
      <b-dropdown-item
        v-else-if="
          modelitems.status == 'inactive' &&
          modelitems.account_verification_status != 'rejected' &&
          show('activate/inactivate')
        "
        v-on:click="openDisableModel(modelitems.id, modelitems.status, 'inactive')"
        >Activate
      </b-dropdown-item>
      <b-dropdown-item
        v-if="
          modelitems.account_verification_status != 'rejected' &&
          modelitems.status != 'deleted' &&
          show('delete worker')
        "
        @click="openDisableModel(modelitems.id, '', 'jobseeker_deleted')"
        >Delete</b-dropdown-item
      >
      <b-dropdown-item
        v-if="show('permanent delete user')"
        v-on:click="permanentDeleteUser(modelitems.id)"
        >Permanent Delete Worker
      </b-dropdown-item>

      <b-dropdown-item
        v-if="
          modelitems.email &&
          modelitems.account_verification_status != 'rejected' &&
          show('send password reset link')
        "
        @click="sendResetPasswordLink(modelitems.id)"
        >Send Reset Password Link
      </b-dropdown-item>
      <b-dropdown-item
        v-if="
          modelitems.email_verified == 'no' &&
          modelitems.email &&
          modelitems.account_verification_status != 'rejected' &&
          show('send verify email link')
        "
        @click="sendEmailVerificationLink(modelitems.id)"
        >Send Email Verification Link
      </b-dropdown-item>
      <b-dropdown-item
        v-if="
          modelitems.email_verified == 'no' &&
          modelitems.email &&
          modelitems.account_verification_status != 'rejected' &&
          modelitems.status != 'deleted' &&
          show('verify email')
        "
        v-on:click="verifyEmail(modelitems.id)"
        >Verify Email</b-dropdown-item
      >
      <b-dropdown-item
        v-if="
          modelitems.mobile_verified == 'no' &&
          modelitems.mobile_number &&
          modelitems.account_verification_status != 'rejected' &&
          modelitems.status != 'deleted' &&
          show('verify mobile')
        "
        v-on:click="verifyMobileNumber(modelitems.id)"
        >Verify Mobile Number</b-dropdown-item
      >
      <b-dropdown-item
        v-if="
          modelitems.account_verification_status != 'rejected' &&
          modelitems.status != 'deleted' &&
          show('update email')
        "
        @click="openInfoModel(modelitems.id, 'email')"
        >Update Email
      </b-dropdown-item>
      <b-dropdown-item
        v-if="
          modelitems.account_verification_status != 'rejected' &&
          modelitems.status != 'deleted' &&
          show('update mobile')
        "
        @click="openInfoModel(modelitems.id, 'mobile')"
        >Update Mobile Number
      </b-dropdown-item>
      <b-dropdown-item
        v-if="
          modelitems.subscribe_mail == 'yes' &&
          modelitems.account_verification_status != 'rejected' &&
          modelitems.status != 'deleted' &&
          show('subscribe/unsubscribe mailing list')
        "
        @click="subscribeUnsubscribeMail(modelitems.id)"
        >Unsubscribe Mailing List</b-dropdown-item
      >
      <b-dropdown-item
        v-if="
          modelitems.subscribe_mail == 'no' &&
          modelitems.account_verification_status != 'rejected' &&
          modelitems.status != 'deleted' &&
          show('subscribe/unsubscribe mailing list')
        "
        @click="subscribeUnsubscribeMail(modelitems.id)"
        >Subscribe Mailing List
      </b-dropdown-item>

      <b-dropdown-item
        v-if="
          (modelitems.account_verification_status == 'completed' ||
            modelitems.account_verification_status == 'pending') &&
          show('recalculate wallet')
        "
        @click="openInfoModel(modelitems.id, 'wallet')"
        >Recalculate Wallet
      </b-dropdown-item>
      <b-dropdown-item
        v-if="
          (modelitems.account_verification_status == 'completed' ||
            modelitems.account_verification_status == 'pending') &&
          show('incentive')
        "
        :to="{ name: 'incentive', params: { id: encrypt(modelitems.id) } }"
        >Incentive Details
      </b-dropdown-item>
      <b-dropdown-item
        v-if="
          (modelitems.account_verification_status == 'completed' ||
            modelitems.account_verification_status == 'pending') &&
          show('transactions')
        "
        :to="{ name: 'jobseeker-transaction-view', params: { id: encrypt(modelitems.id) } }"
        >Transactions
      </b-dropdown-item>
      <b-dropdown-item
        v-if="modelitems.account_verification_status == 'completed' && show('add new transaction')"
        @click="openTransactionModel(modelitems.id)"
        >Add New Transaction
      </b-dropdown-item>
      <b-dropdown-item
        v-if="
          (modelitems.account_verification_status == 'completed' ||
            modelitems.account_verification_status == 'pending') &&
          show('payslip')
        "
        :to="{ name: 'jobseeker-payslip-view', params: { id: encrypt(modelitems.id) } }"
        >Payslip
      </b-dropdown-item>
      <b-dropdown-item
        v-if="
          (modelitems.account_verification_status == 'completed' ||
            modelitems.account_verification_status == 'pending') &&
          show('generate payslip')
        "
        @click="openModel(modelitems.id)"
        >Generate Payslip
      </b-dropdown-item>
      <b-dropdown-item
        v-if="
          modelitems.account_number &&
          modelitems.account_verification_status != 'rejected' &&
          modelitems.status != 'deleted' &&
          show('delete bank')
        "
        @click="deletePaynow(modelitems.id, 'bank')"
        >Delete Bank
      </b-dropdown-item>
      <b-dropdown-item
        v-if="
          modelitems.paynow_mobile_number &&
          modelitems.account_verification_status != 'rejected' &&
          modelitems.status != 'deleted' &&
          show('delete paynow')
        "
        @click="deletePaynow(modelitems.id, 'payout')"
        >Delete Paynow
      </b-dropdown-item>

      <!-- <b-dropdown-item v-on:click="changeUserStatus(modelitems.id, modelitems.status)" v-if="modelitems.status == 'deleted'">Activate</b-dropdown-item> -->
      <b-dropdown-item
        v-on:click="openDisableModel(modelitems.id, modelitems.status, 'deleted')"
        v-if="modelitems.status == 'deleted' && show('activate/inactivate')"
        >Activate</b-dropdown-item
      >

      <b-dropdown-item
        v-if="showBadge('award badge')"
        @click="openInfoModel(modelitems.id, 'badge')"
        >Award Badge
      </b-dropdown-item>
      <b-dropdown-item
        v-if="showBadge('view')"
        :to="{ name: 'jobseeker-badge-manage', params: { fl_id: encrypt(modelitems.id) } }"
        >Manage Badge</b-dropdown-item
      >
      <b-dropdown-item
        v-if="
          modelitems.account_verification_status != 'rejected' &&
          modelitems.status != 'deleted' &&
          showCertificate('view')
        "
        :to="{ name: 'jobseeker-certificate-manage', params: { id: encrypt(modelitems.id) } }"
        >Manage Certificates</b-dropdown-item
      >
      <b-dropdown-item
        v-if="
          modelitems.account_verification_status != 'rejected' &&
          modelitems.status != 'deleted' &&
          show('logout from app')
        "
        @click="logoutFromApp(modelitems.id)"
        >Logout From App</b-dropdown-item
      >
      <b-dropdown-item
        v-if="show('show referral code/Llnk')"
        @click="generateBranchLink(items.item.id)"
        >Show Referral Code / Link
      </b-dropdown-item>
    </b-modal>

    <b-modal
      ref="branch-modal"
      title="Referral Code / Link"
      content-class="common_model_header common_model_title"
      hide-footer
      modal-class="background_custom_class common_mobile_model_classes"
    >
      <div class="edit-form-box model_margin_top_zero model_legend">
        <div class="profile-repeate form-box-field">
          <div class="">
            <span class="code text-danger"
              ><b>{{ generated_referral_code }}</b></span
            >
            <input type="hidden" id="testing-code" :value="generated_referral_code" />
            <a href="#" class="pl-2" @click="copyCode()"><i class="fa fa-copy"></i></a><br />
            <span id="copied-text-code" class="text-success"></span><br />

            <span class="code text-danger"
              ><b>{{ generated_referral_link }}</b></span
            >
            <input type="hidden" id="testing-copy-url" :value="generated_referral_link" />
            <a href="#" class="pl-2" @click="copyURL()"><i class="fa fa-copy"></i></a><br />
            <span id="copied-text-url" class="text-success"></span>
          </div>
          <div class="pt-2 mt-3 text-md-left text-center common_crop_reset_btn">
            <b-button
              type="button"
              @click="hideModel()"
              variant="success"
              class="model_common_bt_success_color text-uppercase x-md btn-shadow"
              >Close</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
    <div tabindex="0" aria-label="Loading" class="mobile_loader vld-overlay is-active is-full-page">
      <div
        class="vld-background"
        style="background: rgb(128, 128, 128); opacity: 0.6; backdrop-filter: blur(2px)"
      ></div>
      <div class="vld-icon">
        <svg
          viewBox="0 0 120 30"
          xmlns="http://www.w3.org/2000/svg"
          fill="#FFD700"
          width="140"
          height="100"
        >
          <circle cx="15" cy="15" r="15">
            <animate
              attributeName="r"
              from="15"
              to="15"
              begin="0s"
              dur="0.8s"
              values="15;9;15"
              calcMode="linear"
              repeatCount="indefinite"
            ></animate>
            <animate
              attributeName="fill-opacity"
              from="1"
              to="1"
              begin="0s"
              dur="0.8s"
              values="1;.5;1"
              calcMode="linear"
              repeatCount="indefinite"
            ></animate>
          </circle>
          <circle cx="60" cy="15" r="9" fill-opacity="0.3">
            <animate
              attributeName="r"
              from="9"
              to="9"
              begin="0s"
              dur="0.8s"
              values="9;15;9"
              calcMode="linear"
              repeatCount="indefinite"
            ></animate>
            <animate
              attributeName="fill-opacity"
              from="0.5"
              to="0.5"
              begin="0s"
              dur="0.8s"
              values=".5;1;.5"
              calcMode="linear"
              repeatCount="indefinite"
            ></animate>
          </circle>
          <circle cx="105" cy="15" r="15">
            <animate
              attributeName="r"
              from="15"
              to="15"
              begin="0s"
              dur="0.8s"
              values="15;9;15"
              calcMode="linear"
              repeatCount="indefinite"
            ></animate>
            <animate
              attributeName="fill-opacity"
              from="1"
              to="1"
              begin="0s"
              dur="0.8s"
              values="1;.5;1"
              calcMode="linear"
              repeatCount="indefinite"
            ></animate>
          </circle>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { POST_API } from "../../../store/actions.type";
import { GET_API } from "../../../store/actions.type";

import moment from "moment";
import Datepicker from "vuejs-datepicker";
import DateRangePicker from "vue2-daterange-picker";
import Multiselect from "vue-multiselect";
import Bus from "../../../event-bus";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import permission from "../../../../../server/permission.js";

export default {
  data() {
    return {
      dropdownState: 'closed', // Track the dropdown state
      screenWidth: 0,
      disabledFromDate: "",
      fieldsAll: [
        {
          key: "toggleAction",
          label: "Actions",
          // tdClass: "desktop_toggle_action_class_td",
          // thClass: "desktop_toggle_action_class_th",
          thStyle: { width: "4%" },
        },
        // {
        //   key: "toggleAction1",
        //   label: "Actions",
        //   tdClass: "mobile_toggle_action_class_td",
        //   thClass: "mobile_toggle_action_class_th",
        //   thStyle: { width: "4%" },
        // },
        { key: "last_name", label: "Name & Email", sortable: true, thStyle: { width: "10%" } },
        {
          key: "last_logged_in_at",
          label: "Last Login",
          sortable: true,
          thStyle: { width: "14%" },
        },
        // { key: 'age', label: 'Age', sortable: true},

        // { key: 'location_updated_at', label: 'Location Updated At', sortable: true},
        { key: "mobile_number", label: "Mobile Number", sortable: true, thStyle: { width: "9%" } },
        {
          key: "available_cashout",
          label: "Available Cash",
          sortable: true,
          thStyle: { width: "8%" },
        },
        { key: 'applied_jobs_count', label: 'Applied Jobs Count', sortable: true , thStyle:  {width: '5%'}},
        { key: 'completed_jobs_count', label: 'Completed Jobs Count', sortable: true , thStyle:  {width: '5%'}},
        { key: "nric", label: "NRIC", sortable: true, thStyle: { width: "7%" } },
        { key: "status", label: "Account Status", sortable: true, thStyle: { width: "8%" } },

        { key: "ft_account_status", label: "FT Status", sortable: true, thStyle: { width: "10%" } },
        {
          key: "account_verification_status",
          label: "DP Status",
          sortable: true,
          thStyle: { width: "10%" },
        },
        { key: "other_description", label: "Remarks", sortable: true, thStyle: { width: "6%" } },

        {
          key: "users.created_at",
          label: "Register On",
          sortable: true,
          thStyle: { width: "14%" },
        },
        // { key: 'toggleAction', label: 'Actions', }
      ],
      fieldsPendingFT: [
        {
          key: "toggleAction",
          label: "Actions",
          tdClass: "desktop_toggle_action_class_td",
          thClass: "desktop_toggle_action_class_th",
          thStyle: { width: "4%" },
        },
        {
          key: "toggleAction1",
          label: "Actions",
          tdClass: "mobile_toggle_action_class_td",
          thClass: "mobile_toggle_action_class_th",
          thStyle: { width: "4%" },
        },
        { key: "last_name", label: "Name & Email", sortable: true, thStyle: { width: "10%" } },
        // { key: 'last_logged_in_at', label: 'Last Login', sortable: true},
        // { key: 'age', label: 'Age', sortable: true},

        // { key: 'location_updated_at', label: 'Location Updated At', sortable: true},
        { key: "mobile_number", label: "Mobile Number", sortable: true, thStyle: { width: "9%" } },
        {
          key: "available_cashout",
          label: "Available Cash",
          sortable: true,
          thStyle: { width: "10%" },
        },
        // { key: 'applied_jobs_count', label: 'Applied Jobs Count', sortable: true , thStyle:  {width: '5%'}},
        // { key: 'completed_jobs_count', label: 'Completed Jobs Count', sortable: true , thStyle:  {width: '5%'}},
        { key: "nric", label: "NRIC", sortable: true, thStyle: { width: "10%" } },
        { key: "status", label: "Account Status", sortable: true, thStyle: { width: "10%" } },

        { key: "ft_account_status", label: "FT Status", sortable: true, thStyle: { width: "10%" } },
        {
          key: "account_verification_status",
          label: "DP Status",
          sortable: true,
          thStyle: { width: "10%" },
        },
        { key: "other_description", label: "Remarks", sortable: true, thStyle: { width: "10%" } },

        {
          key: "users.created_at",
          label: "Register On",
          sortable: true,
          thStyle: { width: "17%" },
        },
        // { key: 'toggleAction', label: 'Actions', }
      ],
      fieldsRejected: [
        {
          key: "toggleAction",
          label: "Actions",
          tdClass: "desktop_toggle_action_class_td",
          thClass: "desktop_toggle_action_class_th",
          thStyle: { width: "4%" },
        },
        {
          key: "toggleAction1",
          label: "Actions",
          tdClass: "mobile_toggle_action_class_td",
          thClass: "mobile_toggle_action_class_th",
          thStyle: { width: "4%" },
        },
        { key: "last_name", label: "Name & Email", sortable: true, thStyle: { width: "10%" } },
        // { key: 'service_categories', label: 'Industries', sortable: false , thStyle:  {width: '6%'}},
        // { key: 'last_logged_in_at', label: 'Last Login', sortable: true , thStyle:  {width: '9%'}},
        // { key: 'location_updated_at', label: 'Location Updated At', sortable: true , thStyle:  {width: '9%'}},
        { key: "mobile_number", label: "Mobile Number", sortable: true, thStyle: { width: "9%" } },
        { key: "nric", label: "NRIC", sortable: true, thStyle: { width: "10%" } },
        { key: "status", label: "Account Status", sortable: true, thStyle: { width: "12%" } },
        { key: "ft_account_status", label: "FT Status", sortable: true, thStyle: { width: "10%" } },
        {
          key: "account_verification_status",
          label: "DP Status",
          sortable: true,
          thStyle: { width: "13%" },
        },
        { key: "rejection", label: "Rejection Comment", sortable: true, thStyle: { width: "15%" } },

        {
          key: "users.created_at",
          label: "Register On",
          sortable: true,
          thStyle: { width: "17%" },
        },
        // { key: 'toggleAction', label: 'Actions', }
      ],

      fieldsDeleted: [
        {
          key: "toggleAction",
          label: "Actions",
          tdClass: "desktop_toggle_action_class_td",
          thClass: "desktop_toggle_action_class_th",
          thStyle: { width: "4%" },
        },
        {
          key: "toggleAction1",
          label: "Actions",
          tdClass: "mobile_toggle_action_class_td",
          thClass: "mobile_toggle_action_class_th",
          thStyle: { width: "4%" },
        },
        { key: "last_name", label: "Name & Email", sortable: true, thStyle: { width: "10%" } },
        // { key: 'service_categories', label: 'Industries', sortable: false , thStyle:  {width: '6%'}},
        // { key: 'last_logged_in_at', label: 'Last Login', sortable: true , thStyle:  {width: '9%'}},

        // { key: 'location_updated_at', label: 'Location Updated At', sortable: true , thStyle:  {width: '9%'}},
        // { key: 'total_earning', label: 'Wage', sortable: true , thStyle:  {width: '5%'}},
        {
          key: "available_cashout",
          label: "Available Cash",
          sortable: true,
          thStyle: { width: "12%" },
        },

        // { key: 'cash', label: 'Cash', sortable: true , thStyle:  {width: '5%'}},
        { key: "mobile_number", label: "Mobile Number", sortable: true, thStyle: { width: "9%" } },

        { key: "nric", label: "NRIC", sortable: true, thStyle: { width: "10%" } },
        { key: "remarks", label: "Remarks", sortable: true, thStyle: { width: "15%" } },

        { key: "ft_account_status", label: "FT Status", sortable: true, thStyle: { width: "10%" } },
        {
          key: "account_verification_status",
          label: "DP Status",
          sortable: true,
          thStyle: { width: "13%" },
        },
        {
          key: "users.created_at",
          label: "Register On",
          sortable: true,
          thStyle: { width: "17%" },
        },
        // { key: 'toggleAction', label: 'Actions', }
      ],
      modelitems: {},
      itemsAll: null,
      totalRowsAll: 0,
      fromAll: "",
      toAll: "",
      currentPageAll: 1,
      cat: [],
      race: [],
      age: [],
      racearr: [],
      certificateArr: [],
      gender: "",
      singpass_signup: "",
      whatsapp_blast_opt_out:"",
      perPageAll: 50,
      pageOptionsAll: [25, 50, 75, 100],
      itemsPending: null,
      totalRowsPending: 0,
      fromPending: "",
      toPending: "",
      currentPagePending: 1,
      perPagePending: 50,
      pageOptionsPending: [25, 50, 75, 100],
      itemsRejected: null,
      totalRowsRejected: 0,
      fromRejected: "",
      toRejected: "",
      currentPageRejected: 1,
      perPageRejected: 50,
      pageOptionsRejected: [25, 50, 75, 100],
      itemsDeleted: null,
      totalRowsDeleted: 0,
      fromDeleted: "",
      toDeleted: "",
      currentPageDeleted: 1,
      perPageDeleted: 50,
      pageOptionsDeleted: [25, 50, 75, 100],
      itemsFT: null,
      totalRowsFT: 0,
      fromFT: "",
      toFT: "",
      currentPageFT: 1,
      perPageFT: 50,
      pageOptionsFT: [25, 50, 75, 100],
      sortByAll: null,
      sortDirectionAll: "desc",
      sortDescAll: true,
      sortByPending: null,
      sortDirectionPending: "desc",
      sortDescPending: true,
      sortByRejected: null,
      sortDirectionRejected: "desc",
      sortDescRejected: true,
      sortByDeleted: null,
      sortDirectionDeleted: "desc",
      sortDescDeleted: true,
      sortByFT: null,
      sortDirectionFT: "desc",
      sortDescFT: true,
      filterAll: null,
      searchKeywordAll: null,
      filterPending: null,
      searchKeywordPending: null,
      filterRejected: null,
      searchKeywordRejected: null,
      filterDeleted: null,
      searchKeywordDeleted: null,
      filterFT: null,
      searchKeywordFT: null,
      status: "active",
      designation: "",
      webUrl: process.env.VUE_APP_URL,
      webServerUrl: process.env.VUE_APP_SERVER_URL,
      webUrlV1: process.env.VUE_APP_URL_V1,
      webUrlV1Admin: process.env.VUE_APP_URL_V1_Admin,
      minDate: null,
      locale: {
        direction: "ltr", //direction of text
        format: "DD-MMM-YYYY", //fomart of the dates displayed
        separator: " - ", //separator between the two ranges
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        /*weekLabel: 'W',
                customRangeLabel: 'Custom Range',*/
        daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 0, //ISO first day of week - see moment documenations for details
        sameDateFormat: {
          from: "DD MM YYYY, hh:mm",
          to: "hh:mm",
        },
      },
      // dateRange: {
      //   startDate: moment().subtract(3, "months").format("DD MMM YYYY"),
      //   endDate: moment().format("DD MMM YYYY"),
      // },
      dateRange: {
        startDate: moment().subtract(3, "months").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      dateRangeLastLogin: {
        startDate: moment().subtract(3, "months").format("DD MMM YYYY"),
        endDate: moment().format("DD MMM YYYY"),
      },
      start_date_filter_login: moment().subtract(3, "months").format("DD MMM YYYY"),
      end_date_filter_login: moment().format("DD MMM YYYY"),
      start_date_filter: moment().subtract(3, "months").format("YYYY-MM-DD"),
      end_date_filter: moment().format("YYYY-MM-DD"),
      range: false,
      date: moment().utc().format("YYYY-MM-DD"),
      format: "YYYY-MM-DD HH:mm",
      auto: true,
      center: "center",
      form: {
        rowsPerPageAll: 50,
        rowsPerPagePending: 50,
        rowsPerPageRejected: 50,
        rowsPerPageDeleted: 50,
        rowsPerPageFT: 50,
        admin_email: "",
        admin_user_type_id: "",
        selectedImage: {
          name: "",
          image: "",
        },
        image: "",
      },
      disabledFromDateTwo: {
        from: new Date(),
      },
      disabledFromDate1: {
        from: new Date(),
      },
      defaultDate: moment().subtract(3, "months").format("DD MMM YYYY"),
      defaultDate1: moment().format("DD MMM YYYY"),
      defaultDateFT: moment().subtract(3, "months").format("DD MMM YYYY"),
      defaultDate1FT: moment().format("DD MMM YYYY"),

      defaultDateLastLogin: moment().subtract(3, "months").format("DD MMM YYYY"),
      defaultDate1LastLogin: moment().format("DD MMM YYYY"),

      disable_for_days: 3,
      remarks: "",
      model_type: "disable",
      renable_date: "",
      comment: "",
      categories: [],
      certificates: [],
      comment_id: "",
      rejection_comment_for: "",
      userDPAccountStatusFilter: "all",
      userFTAccountStatusFilter: "all",

      userDPAccountStatusFilterAll: "all",
      userFTAccountStatusFilterAll: "all",

      singpass_signupFilterRejected: "all",

      userDPAccountStatusFilterDeleted: "all",
      userFTAccountStatusFilterDeleted: "all",

      userDPAccountStatusFilterFT: "all",
      userFTAccountStatusFilterFT: "all",

      userAccountStatusFilter: "all",
      userAccountStatusFilterAll: "all",
      userAccountStatusFilterFT: "all",
      dpStatus: ["pending", "incomplete", "completed", "rejected"],
      ftStatus: ["pending", "incomplete", "completed"],

      dpStatusAll: ["pending", "incomplete", "completed", "rejected"],
      ftStatusAll: ["pending", "incomplete", "completed"],
      signup_TypeAll: [
        { label: "Singpass", value: "true" },
        { label: "Normal Signup", value: "false" },
      ],
      accountStatus: ["active", "inactive", "blacklisted"],
      restrict: false,
      companiesList: "",
      referralsList: "",
      awarded_badge: "",
      awarded_badge_companies: "",
      awardedBadgeCompaniesList: [],
      badge_remarks: "",
      restricted_companies: "",
      linked_companies: "",
      whitelisted_companies: "",
      whitelist_disable_companies: false,
      whitelist_all_companies: false,
      restrict_remark: "",
      view_remark: "",
      whitelist_remark: "",
      referred_to: "",
      rest_user_id: "",
      badge_user_id: "",
      referred_to_id: [],
      referral_month: "",
      toReferralsList: [],
      companiesList: [],
      allBadges: [],
      payslip_generate_for_user: "",
      withNoEmail: true,
      withNoEmailBlacklist: true,
      month: "",
      year: "",
      current_wallet: 0,
      recal_wallet: "",
      new_wallet: 0,
      mobile_number: "",
      email: "",
      update_user_id: "",
      userData: [],
      updateInfoTitle: "Update Wallet",
      updateInfoTitleFL: "Temporary Suspend Jobseeker",
      fulltime_remarks: "",
      allTab: true,
      pendingTab: false,
      freelancerList: [],
      rejectedTab: false,
      deletedTab: false,
      ft_tab: false,
      currentTab: "all",
      deduct_amount: "",
      transaction_remarks: "",
      transaction_internal_remarks: "",
      transaction_type: "deduct",
      transaction_created_at: "",
      generated_referral_link: "",
      generated_referral_code: "",
      disabledDateFromTransaction: {
        from: new Date(Date.now() + 8640000),
      },
      //for cropper
      cropImg: "",
      data: null,
      cropmove: null,
      tempLogo: "",
      noClose: true,

      //for multiple images
      imagesDoc: [],
      docx: [],
      maxImage: 10,
      multiple: true,
      freelancer_id: [],
      images: [],
      actions: "",
      actionsBadge: "",
      actionsCertificate: "",
      sub_transactions: [],
      transaction_sub_type: "",
      billing_types: [],
      billing: "",
      isReferralFLListLoaded: false,
    };
  },
  components: {
    Datepicker,
    DateRangePicker,
    Multiselect,
    VueCropper,
    VueUploadMultipleImage,
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    ifNumber: function (value) {
      if (!value) {
        return "0.00";
      }
      return value.toFixed(2);
    },
    moment: function (date) {
      if (date != null) return moment(date).utc().format("DD MMM YYYY hh:mm A");
      else return "-";
    },
    datePicker1: function (value) {
      if (value == null) {
        return "";
      } else {
        return moment(value).format("DD MMM YYYY");
      }
    },
  },
  methods: {
    onDropdownHide() {
      this.dropdownState = 'closed';
      //  console.log('else');
       $(".profile_sub_menu").hide();
       $('.border_li').removeClass('jbs_active_color');
       $(".main_menu_i").removeClass('down_arrow_jbs');
       $(".referral_sub_menu").hide();
       $(".main_menu_i_referral").removeClass('down_arrow_jbs');
       $(".email_mobile_password_sub_menu").hide();
       $(".main_menu_i_email_mobile_password").removeClass('down_arrow_jbs');
       $(".transaction_sub_menu").hide();
       $(".main_menu_i_transaction").removeClass('down_arrow_jbs');
       $(".badge_and_certificate_sub_menu").hide();
       $(".main_menu_i_badge_and_certificate").removeClass('down_arrow_jbs');
       $(".ban_view_sub_menu").hide();
       $(".main_menu_i_ban_view").removeClass('down_arrow_jbs');
    },
    onDropdownShow() {
      this.dropdownState = 'open';
      // console.log('if');
    },
    profileClick(){
      $(".profile_sub_menu").slideToggle();
      $(".main_menu_i").toggleClass('down_arrow_jbs');
    },
    referralClick(){
      $(".referral_sub_menu").slideToggle();
      $(".main_menu_i_referral").toggleClass('down_arrow_jbs');
    },
    emailmobilepasswordClick(){
      $(".email_mobile_password_sub_menu").slideToggle();
      $(".main_menu_i_email_mobile_password").toggleClass('down_arrow_jbs');
    },
    transactionClick(){
      $(".transaction_sub_menu").slideToggle();
      $(".main_menu_i_transaction").toggleClass('down_arrow_jbs');
    },
    badgeandcertificateClick(){
      $(".badge_and_certificate_sub_menu").slideToggle();
      $(".main_menu_i_badge_and_certificate").toggleClass('down_arrow_jbs');
    },
    banviewClick(){
       $(".ban_view_sub_menu").slideToggle();
       $(".main_menu_i_ban_view").toggleClass('down_arrow_jbs');
    },
    onScreenResize() {
      window.addEventListener("resize", () => {
        this.updateScreenWidth();
      });
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
    showMobileActionModel(item) {
      $(".mobile_loader").show();
      this.modelitems = {};
      this.modelitems = item;
      setTimeout(() => {
        this.$refs["jobseeker-mobile-action-model"].show();
        var x = $(".mobile_loader").css("display");
        if (x != "none") {
          $(".mobile_loader").hide();
        }
      }, 1500);
    },
    generateBranchLink(id) {
      this.$store
        .dispatch(POST_API, {
          data: {
            id: id,
          },
          api: "/api/generate-branch-link",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.$refs["branch-modal"].show();
            this.generated_referral_link = this.$store.getters.getResults.url;
            this.generated_referral_code = this.$store.getters.getResults.referral_code;
          }
        });
    },
    showSuccessAlert(message) {
      this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: message,
        showConfirmButton: false,
        timer: 2000,
      }).then((result) => {
        this.refreshDataTable();
        // this.$router.push({ name: 'jobseekers-list' });
      });
    },
    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    refreshDataTable() {
      this.$refs.datatableAll.refresh();
      this.$refs.datatablePending.refresh();
      this.$refs.datatableRejected.refresh();
      this.$refs.datatableDeleted.refresh();
      this.$refs.datatableFT.refresh();
    },
    getJobseekersOnStatusChange() {
      this.userAccountStatusFilter = this.userAccountStatusFilterAll;
      this.userDPAccountStatusFilter = this.userDPAccountStatusFilterAll;
      this.userFTAccountStatusFilter = this.userFTAccountStatusFilterAll;

      this.getJobseekersAll().then(() => {
        this.$refs.datatableAll.refresh();
      });
    },
    dateFilterFunction(status) {
      console.log(this.dateRange.startDate, this.dateRange.endDate);

      this.getJobseekersAll().then(() => {
        this.$refs.datatableAll.refresh();
      });
    },

    customFilterFunction(status) {
      this.$refs["filter-modal"].hide();

      if (status == "all") {
        this.getJobseekersAll().then(() => {
          this.$refs.datatableAll.refresh();
        });
      } else {
        this.getJobseekersFT().then(() => {
          this.$refs.datatableFT.refresh();
        });
      }
    },
    dateFilterFunctionFT(status) {
      this.getJobseekersFT().then(() => {
        this.$refs.datatableFT.refresh();
      });
    },
    resetFilterAll() {
      this.searchKeywordAll = "";
      this.age = [];
      this.gender = "";
      this.singpass_signup = "";
      this.whatsapp_blast_opt_out = "";

      this.cat = [];
      this.racearr = [];
      this.certificateArr = [];
      this.currentTab = "all";
      // this.defaultDate = moment().subtract(3, "months").format("DD MMM YYYY");
      // this.defaultDate1 = moment().format("DD MMM YYYY");
      // this.dateRange.startDate = moment().subtract(3, "months").format("DD MMM YYYY");
      // this.dateRange.endDate = moment().format("DD MMM YYYY");
      this.dateRange.startDate = moment().subtract(3, "months").format("YYYY-MM-DD");
      this.dateRange.endDate = moment().format("YYYY-MM-DD");
      this.dateRangeLastLogin.startDate = moment().subtract(3, "months").format("DD MMM YYYY");
      this.dateRangeLastLogin.endDate = moment().format("DD MMM YYYY");
      this.userAccountStatusFilterAll = "all";
      this.userDPAccountStatusFilterAll = "all";
      this.userFTAccountStatusFilterAll = "all";
      this.userAccountStatusFilter = "all";
      this.userDPAccountStatusFilter = "all";
      this.userFTAccountStatusFilter = "all";
      this.$refs.datatableAll.refresh();
    },
    resetFilterFT() {
      this.searchKeywordFT = "";
      this.age = [];
      this.gender = "";
      this.singpass_signup = "";
      this.whatsapp_blast_opt_out = "";
      this.cat = [];
      this.racearr = [];
      this.certificateArr = [];
      this.currentTab = "all";
      // this.defaultDate = moment().subtract(3, "months").format("DD MMM YYYY");
      // this.defaultDate1 = moment().format("DD MMM YYYY");
      // this.dateRange.startDate = moment().subtract(3, "months").format("DD MMM YYYY");
      // this.dateRange.endDate = moment().format("DD MMM YYYY");
      this.dateRange.startDate = moment().subtract(3, "months").format("YYYY-MM-DD");
      this.dateRange.endDate = moment().format("YYYY-MM-DD");
      this.userAccountStatusFilterFT = "all";
      this.userDPAccountStatusFilterFT = "all";
      this.userFTAccountStatusFilterFT = "all";
      this.userAccountStatusFilter = "all";
      this.userDPAccountStatusFilter = "all";
      this.userFTAccountStatusFilter = "all";
      this.$refs.datatableFT.refresh();
    },
    changeAllStatus() {
      this.age = [];
      this.gender = "";
      this.singpass_signup = "";
      this.whatsapp_blast_opt_out = "";
      this.cat = [];
      this.racearr = [];
      this.certificateArr = [];
      this.currentTab = "all";
      // this.defaultDate = moment().subtract(3, "months").format("DD MMM YYYY");
      // this.defaultDate1 = moment().format("DD MMM YYYY");
      // this.dateRange.startDate = moment().subtract(3, "months").format("DD MMM YYYY");
      // this.dateRange.endDate = moment().format("DD MMM YYYY");
      this.dateRange.startDate = moment().subtract(3, "months").format("YYYY-MM-DD");
      this.dateRange.endDate = moment().format("YYYY-MM-DD");
      this.dateRangeLastLogin.startDate = moment().subtract(3, "months").format("DD MMM YYYY");
      this.dateRangeLastLogin.endDate = moment().format("DD MMM YYYY");
      this.userAccountStatusFilter = this.userAccountStatusFilterAll;
      this.userDPAccountStatusFilter = this.userDPAccountStatusFilterAll;
      this.userFTAccountStatusFilter = this.userFTAccountStatusFilterAll;

      // this.form.fulltimeUserAccountStatus = 'all';
      // this.form.fulltimeUserStatus = 'all';

      this.getJobseekersAll().then(() => {
        this.$refs.datatableAll.refresh();
      });
    },

    changePendingStatus() {
      this.age = [];
      this.gender = "";
      this.singpass_signup = "";
this.whatsapp_blast_opt_out = "";
      this.cat = [];
      this.racearr = [];
      this.certificateArr = [];
      this.currentTab = "pending";
      this.userDPAccountStatusFilter = "all";
      this.userFTAccountStatusFilter = "all";

      this.userAccountStatusFilter = "all";
      this.$refs.datatablePending.refresh();
    },
    updateval() {},
    changeRejectedStatus() {
      this.age = [];
      this.gender = "";
      this.singpass_signup = this.singpass_signupFilterRejected;

      this.cat = [];
      this.racearr = [];
      this.certificateArr = [];
      this.currentTab = "rejected";
      this.userDPAccountStatusFilter = "all";
      this.userFTAccountStatusFilter = "all";
      this.userAccountStatusFilter = "all";
      this.$refs.datatableRejected.refresh();
    },
    changeDeletedStatus() {
      this.age = [];
      this.gender = "";
      this.singpass_signup = "";
this.whatsapp_blast_opt_out = "";
      this.cat = [];
      this.racearr = [];
      this.certificateArr = [];
      this.currentTab = "deleted";
      this.userDPAccountStatusFilter = this.userDPAccountStatusFilterDeleted;
      this.userFTAccountStatusFilter = this.userFTAccountStatusFilterDeleted;

      // this.userAccountStatusFilter = 'all';
      this.$refs.datatableDeleted.refresh();
    },
    changeFTStatus() {
      this.age = [];
      this.gender = "";
      this.singpass_signup = "";
this.whatsapp_blast_opt_out = "";
      this.cat = [];
      this.racearr = [];
      this.certificateArr = [];
      this.currentTab = "full_time";
      this.userAccountStatusFilter = this.userAccountStatusFilterFT;
      this.userDPAccountStatusFilter = this.userDPAccountStatusFilterFT;
      this.userFTAccountStatusFilter = this.userFTAccountStatusFilterFT;

      // this.defaultDateFT = moment().subtract(3, "months").format("DD MMM YYYY");
      // this.defaultDate1FT = moment().format("DD MMM YYYY");

      // this.dateRange.startDate = moment().subtract(3, "months").format("DD MMM YYYY");
      // this.dateRange.endDate = moment().format("DD MMM YYYY");
      this.dateRange.startDate = moment().subtract(3, "months").format("YYYY-MM-DD");
      this.dateRange.endDate = moment().format("YYYY-MM-DD");
      this.getJobseekersFT().then(() => {
        this.$refs.datatableFT.refresh();
      });
    },
    mapReferralId(value) {
      console.log(value, "dd");
      this.referred_to_id = value.id;
    },
    mapCompId(value) {
      this.comp_id = value.id;
    },
    mapraceId(value) {
      this.race = value.id;
    },
    customFormatterOne(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    customFormatterTwo(date) {
      return moment(date).format("MMM,YYYY");
    },
    disabledFromDate2() {
      var d = new Date();
      return {
        to: new Date(d.getFullYear(), d.getMonth(), d.getDate() - 1),
      };
    },
    // exportUser(){
    // var base_url = this.webUrlV1;
    // var base_url_v2 = this.webServerUrl;

    // var start_date = this.filterAll == null ? this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : 'null' : 'null';
    // var end_date = this.filterAll == null ? this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') : 'null' : 'null';
    // var start_date_ft = this.filterFT == null ? this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : 'null' : 'null';
    // var end_date_ft = this.filterFT == null ? this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') : 'null' : 'null';
    // var search = this.filterAll != '' ? this.filterAll : 'null';
    // var gender = this.gender ? this.gender : null;
    // var singpass_signup = this.singpass_signup ? this.singpass_signup : null;
    // var raceArr = [];
    // var certArr = [];
    // var catArr = [];

    // if(this.racearr.length > 0){
    //   this.racearr.forEach(element => {
    //     raceArr.push(element.id);
    //   })
    // }

    // if(this.certificateArr.length > 0){
    //   this.certificateArr.forEach(element => {
    //     certArr.push(element.id);
    //   })
    // }

    // if(this.cat.length > 0){
    //   this.cat.forEach(element => {
    //     catArr.push(element.id);
    //   })
    // }

    // // var race_ids = "["+raceArr+"]";
    // // var cat_ids = catArr;
    // // var ageVal = "["+this.age+"]";

    // var race_id = this.racearr.length > 0 ? raceArr : 'null';
    // var category = this.cat.length > 0 ? catArr : 'null';
    // var certificate = this.certificateArr.length > 0 ? certArr : 'null';

    // var age = this.age.length > 0 ? this.age : 'null';

    // // if(this.status == 'active'){
    // // var export_url = '/api/export-pt-user/'+this.status+'/'+search+'/'+start_date+'/'+end_date+'/'+this.form.user_id;
    // // }else if(this.currentTab == 'deleted'){
    // //     var export_url = '/api/export-pt-user/'+this.currentTab+'/'+this.status+'/null/null'+'/'+this.form.user_id;
    // // } else{
    // // var export_url = '/api/export-pt-user/'+this.currentTab+'/'+this.status+'/null/null'+'/'+this.form.user_id;

    // // }
    // var sortBy =  'users.id';
    // var sortDirection= 'desc';
    // var page = 1;
    // var rowsPerPage = 50

    // if(this.currentTab == 'all'){
    //     // var export_url = '/api/export-pt-user-v2/'+this.form.user_id+'/'+gender+'/'+race_id+'/'+category+'/'+age;
    //     var export_url = '/api/export-pt-user-v2/'+this.userDPAccountStatusFilterAll+'/'+search+'/'+start_date+'/'+end_date+'/'+this.form.user_id+'/'+gender+'/'+singpass_signup+'/'+category+'/'+race_id+'/'+age+'/'+certificate;
    //     window.open(base_url+export_url,'_blank')

    //     // var export_url = '/api/export-jobseekers-list-all/'+this.userDPAccountStatusFilterAll+'/'+this.userFTAccountStatusFilterAll+'/'+this.userAccountStatusFilterAll+'/'+this.currentTab+'/'+search+'/'+start_date+'/'+end_date+'/'+gender+'/'+category+'/'+race_id+'/'+age+'/'+certificate+'/'+page+'/'+rowsPerPage+'/'+sortBy+'/'+sortDirection;
    //     // window.open(base_url_v2+export_url,'_blank')

    // }else if(this.currentTab == 'full_time'){
    //     var export_url = '/api/export-pt-user/'+this.currentTab+'/'+this.form.userDPAccountStatusFilterFT+'/'+this.form.userAccountStatusFilterFT+'/'+search+'/'+start_date_ft+'/'+end_date_ft+'/'+this.form.user_id;
    //     window.open(base_url+export_url,'_blank')
    // }else if(this.currentTab == 'deleted'){
    //     var export_url = '/api/export-pt-user/'+this.currentTab+'/'+this.filterDeleted+'/null/null'+'/'+this.form.user_id;
    //     window.open(base_url+export_url,'_blank')
    // } else{
    //     var export_url = '/api/export-pt-user/'+this.currentTab+'/'+this.userDPAccountStatusFilterAll+'/'+this.userAccountStatusFilterAll+'/'+this.filterRejected+'/null/null'+'/'+this.form.user_id;
    //     window.open(base_url+export_url,'_blank')
    // }
    // // this.$router.push(base_url+export_url)
    // // window.location.href = base_url+export_url

    // },
    exportUserAllMobile(export_type) {
      var base_url_v2 = this.webServerUrl;
      var searchFilter = "";
      if (this.currentTab == "all") {
        searchFilter = this.filterAll;
      } else if (this.currentTab == "full_time") {
        searchFilter = this.filterFT;
      } else if (this.currentTab == "deleted") {
        searchFilter = this.filterDeleted;
      } else if (this.currentTab == "rejected") {
        searchFilter = this.filterRejected;
      } else {
        searchFilter = this.filterPending;
      }
      var start_date =
        this.filterAll == null
          ? this.dateRange.startDate
            ? moment(this.dateRange.startDate).format("YYYY-MM-DD")
            : "null"
          : "null";
      var end_date =
        this.filterAll == null
          ? this.dateRange.endDate
            ? moment(this.dateRange.endDate).format("YYYY-MM-DD")
            : "null"
          : "null";
      var start_date_ft =
        this.filterFT == null
          ? this.dateRange.startDate
            ? moment(this.dateRange.startDate).format("YYYY-MM-DD")
            : "null"
          : "null";
      var end_date_ft =
        this.filterFT == null
          ? this.dateRange.endDate
            ? moment(this.dateRange.endDate).format("YYYY-MM-DD")
            : "null"
          : "null";

      var gender = searchFilter == null ? (this.gender ? this.gender : null) : null;
      var singpass_signup =
        searchFilter == null ? (this.singpass_signup ? this.singpass_signup : null) : null;
        var whatsapp_blast_opt_out =
        searchFilter == null ? (this.whatsapp_blast_opt_out ? this.whatsapp_blast_opt_out : null) : null;
      var singpass_signupFilterRejected =
        searchFilter == null
          ? this.singpass_signupFilterRejected
            ? this.singpass_signupFilterRejected
            : null
          : null;
      var raceArr = [];
      var certArr = [];
      var catArr = [];
      var start_date_lastlogin =
        this.filterAll == null
          ? this.dateRangeLastLogin.startDate
            ? moment(this.dateRangeLastLogin.startDate).format("YYYY-MM-DD")
            : "null"
          : "null";
      var end_date_lastlogin =
        this.filterAll == null
          ? this.dateRangeLastLogin.endDate
            ? moment(this.dateRangeLastLogin.endDate).format("YYYY-MM-DD")
            : "null"
          : "null";
      if (this.racearr.length > 0) {
        this.racearr.forEach((element) => {
          raceArr.push(element.id);
        });
      }

      if (this.certificateArr.length > 0) {
        this.certificateArr.forEach((element) => {
          certArr.push(element.id);
        });
      }

      if (this.cat.length > 0) {
        this.cat.forEach((element) => {
          catArr.push(element.id);
        });
      }
      var race_id = searchFilter == null ? (this.racearr.length > 0 ? raceArr : "null") : "null";
      var category = searchFilter == null ? (this.cat.length > 0 ? catArr : "null") : "null";
      var certificate =
        searchFilter == null ? (this.certificateArr.length > 0 ? certArr : "null") : "null";

      var age = searchFilter == null ? (this.age.length > 0 ? this.age : "null") : "null";
      var currentTabFT = "all";
      var currentTabPending = "pending";
      var currentTabRejected = "rejected";
      var currentTabDeleted = "all";

      var all_default = "null";
      var searchAll = this.filterAll != "" ? this.filterAll : "null";
      var pageAll = this.currentPageAll;
      var rowsPerPageAll = this.form.rowsPerPageAll;
      var sortByAll = this.sortByAll ? this.sortByAll : "users.id";
      var sortDirectionAll = this.sortDescAll ? "desc" : "asc";
      var searchFT = this.filterFT != "" ? this.filterFT : "null";
      var pageFT = this.currentPageFT;
      var rowsPerPageFT = this.form.rowsPerPageFT;
      var sortByFT = this.sortByFT ? this.sortByFT : "users.id";
      var sortDirectionFT = this.sortDescFT ? "desc" : "asc";
      var searchPending = this.filterPending != "" ? this.filterPending : "null";
      var pagePending = this.currentPagePending;
      var rowsPerPagePending = this.form.rowsPerPagePending;
      var sortByPending = this.sortByPending ? this.sortByPending : "users.id";
      var sortDirectionPending = this.sortDescPending ? "desc" : "asc";
      var searchRejected = this.filterRejected != "" ? this.filterRejected : "null";
      var pageRejected = this.currentPageRejected;
      var rowsPerPageRejected = this.form.rowsPerPageRejected;
      var sortByRejected = this.sortByRejected ? this.sortByRejected : "users.id";
      var sortDirectionRejected = this.sortDescRejected ? "desc" : "asc";
      var searchDeleted = this.filterDeleted != "" ? this.filterDeleted : "null";
      var pageDeleted = this.currentPageDeleted;
      var rowsPerPageDeleted = this.form.rowsPerPageDeleted;
      var sortByDeleted = this.sortByDeleted ? this.sortByDeleted : "users.id";
      var sortDirectionDeleted = this.sortDescDeleted ? "desc" : "asc";
      console.log(this.currentTab);
      if (this.currentTab == "all") {
        var dpFilterAll = searchFilter == null ? this.userDPAccountStatusFilterAll : "all";
        var ftFilterAll = searchFilter == null ? this.userFTAccountStatusFilterAll : "all";
        var statusFilterAll = searchFilter == null ? this.userAccountStatusFilterAll : "all";
        var export_url =
          "/api/export-jobseekers-list-all/" +
          dpFilterAll +
          "/" +
          ftFilterAll +
          "/" +
          statusFilterAll +
          "/" +
          this.currentTab +
          "/" +
          searchAll +
          "/" +
          start_date +
          "/" +
          end_date +
          "/" +
          start_date_lastlogin +
          "/" +
          end_date_lastlogin +
          "/" +
          gender +
          "/" +
          singpass_signup +
          "/" +
          whatsapp_blast_opt_out +
          "/" +
          category +
          "/" +
          race_id +
          "/" +
          age +
          "/" +
          certificate +
          "/" +
          pageAll +
          "/" +
          rowsPerPageAll +
          "/" +
          sortByAll +
          "/" +
          sortDirectionAll +
          "/no/yes/" +
          export_type;
      } else if (this.currentTab == "full_time") {
        var dpFilterFT = searchFilter == null ? this.userDPAccountStatusFilterFT : "all";
        var ftFilterFT = searchFilter == null ? this.userFTAccountStatusFilterFT : "all";
        var statusFilterFT = searchFilter == null ? this.userAccountStatusFilterFT : "all";
        var export_url =
          "/api/export-jobseekers-list-all/" +
          dpFilterFT +
          "/" +
          ftFilterFT +
          "/" +
          statusFilterFT +
          "/" +
          currentTabFT +
          "/" +
          searchFT +
          "/" +
          start_date_ft +
          "/" +
          end_date_ft +
          "/" +
          all_default +
          "/" +
          all_default +
          "/" +
          gender +
          "/" +
          singpass_signup +
          "/" +
          whatsapp_blast_opt_out +
          "/" +
          category +
          "/" +
          race_id +
          "/" +
          age +
          "/" +
          certificate +
          "/" +
          pageFT +
          "/" +
          rowsPerPageFT +
          "/" +
          sortByFT +
          "/" +
          sortDirectionFT +
          "/yes/no/" +
          export_type;
      } else if (this.currentTab == "deleted") {
        var dpFilterDeleted = searchFilter == null ? this.userDPAccountStatusFilterDeleted : "all";
        var ftFilterDeleted = searchFilter == null ? this.userFTAccountStatusFilterDeleted : "all";
        var export_url =
          "/api/export-jobseekers-list-all/" +
          dpFilterDeleted +
          "/" +
          ftFilterDeleted +
          "/deleted/" +
          currentTabDeleted +
          "/" +
          searchDeleted +
          "/" +
          all_default +
          "/" +
          all_default +
          "/" +
          all_default +
          "/" +
          all_default +
          "/" +
          all_default +
          "/" +
          all_default +
          "/" +
          all_default +
          "/" +
          all_default +
          "/" +
          all_default +
          "/" +
          all_default +
          "/" +
          pageDeleted +
          "/" +
          rowsPerPageDeleted +
          "/" +
          sortByDeleted +
          "/" +
          sortDirectionDeleted +
          "/no/no/" +
          export_type;
      } else if (this.currentTab == "rejected") {
        var export_url =
          "/api/export-jobseekers-list-all/rejected/" +
          all_default +
          "/" +
          all_default +
          "/" +
          currentTabRejected +
          "/" +
          searchRejected +
          "/" +
          all_default +
          "/" +
          all_default +
          "/" +
          all_default +
          "/" +
          all_default +
          "/" +
          all_default +
          "/" +
          singpass_signupFilterRejected +
          "/" +
          all_default +
          "/" +
          all_default +
          "/" +
          all_default +
          "/" +
          all_default +
          "/" +
          pageRejected +
          "/" +
          rowsPerPageRejected +
          "/" +
          sortByRejected +
          "/" +
          sortDirectionRejected +
          "/no/no/" +
          export_type;
      } else {
        var export_url =
          "/api/export-jobseekers-list-all/" +
          all_default +
          "/" +
          all_default +
          "/" +
          all_default +
          "/" +
          currentTabPending +
          "/" +
          searchPending +
          "/" +
          all_default +
          "/" +
          all_default +
          "/" +
          all_default +
          "/" +
          all_default +
          "/" +
          all_default +
          "/" +
          all_default +
          "/" +
          all_default +
          "/" +
          all_default +
          "/" +
          all_default +
          "/" +
          all_default +
          "/" +
          pagePending +
          "/" +
          rowsPerPagePending +
          "/" +
          sortByPending +
          "/" +
          sortDirectionPending +
          "/no/no/" +
          export_type;
      }
      window.open(base_url_v2 + export_url, "_blank");
    },
    // exportUserMobile(){
    // var base_url = this.webUrlV1;
    // var start_date = this.filterAll == null ? this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : 'null' : 'null';
    // var end_date = this.filterAll == null ? this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') : 'null' : 'null';
    // var start_date_ft = this.filterFT == null ? this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : 'null' : 'null';
    // var end_date_ft = this.filterFT == null ? this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') : 'null' : 'null';
    // var search = this.filterAll != '' ? this.filterAll : 'null';
    // var gender = this.gender ? this.gender : null;
    // var singpass_signup = this.singpass_signup ? this.singpass_signup : null;
    // var raceArr = [];
    // var catArr = [];
    // var certArr = [];

    // if(this.certificateArr.length > 0){
    //   this.certificateArr.forEach(element => {
    //     certArr.push(element.id);
    //   })
    // }
    // if(this.racearr.length > 0){
    //   this.racearr.forEach(element => {
    //     raceArr.push(element.id);
    //   })
    // }

    // if(this.cat.length > 0){
    //   this.cat.forEach(element => {
    //     catArr.push(element.id);
    //   })
    // }

    // // var race_ids = "["+raceArr+"]";
    // // var cat_ids = catArr;
    // // var ageVal = "["+this.age+"]";

    // var race_id = this.racearr.length > 0 ? raceArr : 'null';
    // var category = this.cat.length > 0 ? catArr : 'null';
    // var age = this.age.length > 0 ? this.age : 'null';
    // var certificate = this.certificateArr.length > 0 ? certArr : 'null';

    // // if(this.status == 'active'){
    // // var export_url = '/api/export-pt-user/'+this.status+'/'+search+'/'+start_date+'/'+end_date+'/'+this.form.user_id;
    // // }else if(this.currentTab == 'deleted'){
    // //     var export_url = '/api/export-pt-user/'+this.currentTab+'/'+this.status+'/null/null'+'/'+this.form.user_id;
    // // } else{
    // // var export_url = '/api/export-pt-user/'+this.currentTab+'/'+this.status+'/null/null'+'/'+this.form.user_id;

    // // }

    // if(this.currentTab == 'all'){
    //     // var export_url = '/api/export-pt-user-v2/'+this.form.user_id+'/'+gender+'/'+race_id+'/'+category+'/'+age;
    //     var export_url = '/api/export-pt-user-mobile-v2/'+this.userDPAccountStatusFilterAll+'/'+search+'/'+start_date+'/'+end_date+'/'+this.form.user_id+'/'+gender+'/'+singpass_signup+'/'+category+'/'+race_id+'/'+age +'/'+certificate;
    // }else if(this.currentTab == 'full_time'){
    //     var export_url = '/api/export-pt-user-mobile/'+this.currentTab+'/'+this.form.userDPAccountStatusFilterFT+'/'+this.form.userAccountStatusFilterFT+'/'+search+'/'+start_date_ft+'/'+end_date_ft+'/'+this.form.user_id;

    // }else if(this.currentTab == 'deleted'){
    //     var export_url = '/api/export-pt-user-mobile/'+this.currentTab+'/'+this.filterDeleted+'/null/null'+'/'+this.form.user_id;
    // } else{
    //     var export_url = '/api/export-pt-user-mobile/'+this.currentTab+'/'+this.userDPAccountStatusFilterAll+'/'+this.userAccountStatusFilterAll+'/'+this.filterRejected+'/null/null'+'/'+this.form.user_id;
    // }
    // // this.$router.push(base_url+export_url)
    // // window.location.href = base_url+export_url
    // window.open(base_url+export_url,'_blank')
    // },
    // exportUser (){

    //         var filter = this.filter != '' ? this.filter : null;
    //         return this.$store.dispatch(GET_API, {
    //             api: '/api/exportUser/'+filter
    //             // api: '/api/exportJobs/'+tabStatus

    //         })
    //         .then(() => {
    //             var base_url = this.webServerUrl;
    //             var export_url = '/api/exportUser/'+filter+'?nocache=1666084804705/';
    //             // var export_url = '/api/exportJobs/'+tabStatus+'?nocache=1666084804705/';
    //             // var export_url = '/api/export-jobs/'+this.status+'/'+this.user_id+'/'+search+'/'+this.form.filterServiceCategory+'/'+companiesFilter+'/'+start_date+'/'+end_date;
    //             window.open(base_url+export_url,'_blank')
    //         });

    //     },

    getDropdownData() {
      this.$store
        .dispatch(POST_API, {
          data: {
            table: "service_categories",
          },
          api: "/api/service-categories-list-dp",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.categories = this.$store.getters.getResults.data;
          }
        });
    },
    getCert() {
      this.$store
        .dispatch(POST_API, {
          data: {},
          api: "/api/admin-certificate-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.certificates = this.$store.getters.getResults.data;
          }
        });
    },
    updateRejectionRemark() {
      this.$swal({
        title: "Please Confirm",
        text: "Are you sure you want to update this record?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  user_id: this.rejection_comment_for,
                  id: this.comment_id,
                  logged_in_id: this.form.user_id,
                  remarks: this.comment,
                },
                api: "/api/update-rejection-remark-admin",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  this.hideModel();
                  this.comment = "";
                  this.comment_id = "";
                  var message = this.$store.getters.getResults.message;
                  // this.refreshDataTable();
                  this.showSuccessAlert(message);
                }
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    getRace() {
      return this.$store
        .dispatch(GET_API, {
          api: "/api/raceV1",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.race = this.$store.getters.getResults.data;

            // this.showSuccessPopup();
          }
        });
    },
    referredTosubmit() {
      console.log(this.referred_to_id);
      return this.$store
        .dispatch(POST_API, {
          data: {
            to_user: this.referred_to_id,
            from_user: this.referred_by,
            date: this.referral_month,
          },
          api: "/api/assign-referral-store-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.hideModel();
            this.items = this.$store.getters.getResults.data;
            var message = "Referral created successfully";
            // this.refreshDataTable();
            this.showSuccessAlert(message);
            // this.getJobseekersAll();
          }
        });
    },
    submitUpdateInfo() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.update_user_id,
            email: this.email,
            mobile_number: this.mobile_number,
            type: this.updateInfoTitle == "Update Mobile" ? "mobile" : "email",
          },
          api: "/api/jobseeker-save-email-mobile-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.success_message = this.$store.getters.getResults.message;
            this.hideModel();
            this.showSuccessAlert(this.success_message);
            // this.refreshDataTable();
          }
        });
    },
    saveWallet() {
      var msg = "Are you sure you want to update this user wallet?";
      this.$swal({
        title: "Please Confirm",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  user_id: this.rest_user_id,
                  new_wallet: this.new_wallet,
                },
                api: "/api/jobseeker-save-wallet-admin",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                  this.hideModel();
                } else {
                  this.rest_user_id = "";
                  this.restricted_companies = "";
                  this.restrict = "";
                  this.new_wallet = 0;
                  var message = "Wallet recalculated successfully.";
                  // this.refreshDataTable();
                  this.showSuccessAlert(message);
                  this.hideModel();

                  // this.getJobseekersAll();
                }
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    subscribeUnsubscribeMail(id) {
      if (this.screenWidth <= 999) {
        this.$refs["jobseeker-mobile-action-model"].hide();
      }
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: id,
            updated_by: this.form.user_id,
          },
          api: "/api/emp-jobseeker-subscribe-unsubscribe-mail-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            var message = this.$store.getters.getResults.message;
            // this.refreshDataTable();
            this.showSuccessAlert(message);
            this.getJobseekersAll();
          }
        });
    },
    dpJobs(id) {
      if (this.screenWidth <= 999) {
        this.$refs["jobseeker-mobile-action-model"].hide();
      }
      var url = this.webUrlV1Admin + "/admin/mobile/ptuser-jobs/" + btoa(id);
      window.open(url, "_blank");
    },
    sendEmailVerificationLink(id) {
      if (this.screenWidth <= 999) {
        this.$refs["jobseeker-mobile-action-model"].hide();
      }
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: id,
          },
          api: "/api/jobseekerEmp-sendemail-verify-link-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            var message = "Email Verification Link Sent Successfully.";
            this.showSuccessAlert(message);
            // this.$refs.datatable.refresh();
          }
        });
    },
    sendResetPasswordLink(id) {
      if (this.screenWidth <= 999) {
        this.$refs["jobseeker-mobile-action-model"].hide();
      }
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: id,
          },
          api: "/api/jobseekerEmp-reset-password-link-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            var message = "Reset Password Link Sent Successfully.";
            this.showSuccessAlert(message);
            // this.refreshDataTable();
          }
        });
    },
    changeUserStatus(id, status) {
      if (this.screenWidth <= 999) {
        this.$refs["jobseeker-mobile-action-model"].hide();
      }
      // var status = status != null ? status : this.usrStatus;

      if (status == "active") {
        var msg = "Are you sure you want to inactivate this record?";
        var message = "Jobseeker Inactivated Successfully.";
      } else if (status == "inactive" || status == "deleted") {
        var msg = "Are you sure you want to activate this record?";
        var message = "Jobseeker Activated Successfully.";
        this.remarks = this.remarks ? this.remarks : null;
      } else {
        var msg = "Are you sure you want to reactivate this record?";
        var message = "Jobseeker Reactivated Successfully.";
        this.remarks = this.remarks ? this.remarks : null;
      }
      this.$swal({
        title: "Please Confirm",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  id: id,
                  admin_id: this.form.user_id,
                  status: status,
                  remarks: this.remarks,
                },
                api: "/api/jobseeker-active-inactive-admin",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  this.hideModel();
                  this.showSuccessAlert(message);
                  // this.refreshDataTable();
                  this.remarks = null;
                  this.update_user_id = null;
                  // this.getJobseekersAll();
                }
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    saveWhitelistCompanies(type) {
      var msg = "Are you sure you want to whitelist this user for these companies ?";
      var msgg = "FL Whitelisted successfully.";
      this.$swal({
        title: "Please Confirm",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  user_id: this.rest_user_id,
                  admin_id: this.form.user_id,
                  type: type,
                  whitelist_all_companies: this.whitelist_all_companies,
                  companies:
                    this.whitelist_all_companies == false ? this.whitelisted_companies : [],
                  remarks: this.whitelist_remark,
                },
                api: "/api/whitelist-user-from-companies",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  this.hideModel();
                  this.$swal({
                    position: "center",
                    icon: "success",
                    iconColor: "#4c8bf5",
                    title: msgg,
                    showConfirmButton: false,
                    timer: 2500,
                  }).then((result) => {
                    this.rest_user_id = "";
                    this.whitelisted_companies = "";
                    this.whitelist_all_companies = false;
                    this.whitelist_disable_companies = false;
                    this.restricted_companies = "";
                    this.linked_companies = "";
                    this.restrict = "";
                    this.restrict_remark = "";
                    this.view_remark = "";
                    this.whitelist_remark = "";
                    this.refreshDataTable();
                    // this.$router.push({ name: 'jobseekers-list' });
                  });

                  // this.refreshDataTable();
                }
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    saveCompanies(type) {
      var msg =
        type == "restrict"
          ? "Are you sure you want to ban this user for these companies ?"
          : "Are you sure you want this user to view only EM?";
      var msgg = type == "restrict" ? "User Banned successfully." : "Now user can view only EM.";
      this.$swal({
        title: "Please Confirm",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  user_id: this.rest_user_id,
                  admin_id: this.form.user_id,
                  type: type,
                  companies: type == "restrict" ? this.restricted_companies : this.linked_companies,
                  remarks: type == "restrict" ? this.restrict_remark : this.view_remark,
                },
                api: "/api/restrict-user-from-companies",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  this.hideModel();
                  this.$swal({
                    position: "center",
                    icon: "success",
                    iconColor: "#4c8bf5",
                    title: msgg,
                    showConfirmButton: false,
                    timer: 2500,
                  }).then((result) => {
                    this.rest_user_id = "";
                    this.restricted_companies = "";
                    this.linked_companies = "";
                    this.restrict = "";
                    this.restrict_remark = "";
                    this.view_remark = "";
                    this.refreshDataTable();
                    // this.$router.push({ name: 'jobseekers-list' });
                  });

                  // this.refreshDataTable();
                }
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    saveBadges() {
      var msg = "Are you sure you want to award this badge to user ?";
      var msgg = "Badge awarded successfully.";
      this.$swal({
        title: "Please Confirm",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  user_id: this.badge_user_id,
                  awarded_badge: this.awarded_badge,
                  remarks: this.badge_remarks,
                  awarded_badge_companies: this.awarded_badge_companies,
                },
                api: "/api/award-badge-to-user",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  this.hideModel();
                  this.$swal({
                    position: "center",
                    icon: "success",
                    iconColor: "#4c8bf5",
                    title: msgg,
                    showConfirmButton: false,
                    timer: 2500,
                  }).then((result) => {
                    this.badge_user_id = "";
                    this.awarded_badge = "";
                    this.awarded_badge_companies = "";
                    this.badge_remarks = "";
                    this.refreshDataTable();
                  });
                }
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    getUserData(id) {
      this.$store
        .dispatch(POST_API, {
          data: {
            id: id,
          },
          api: "/api/user-data-email-mobile",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.userData = this.$store.getters.getResults.data;
            this.mobile_number = this.userData.mobile_number;
            this.email = this.userData.email;
            this.update_user_id = this.userData.id;
          }
        });
    },
    getCompanies(type) {
      this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.rest_user_id,
            type: type,
          },
          api: "/api/company-list-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.companiesList = this.$store.getters.getResults.companies;
            this.restricted_companies = this.$store.getters.getResults.restricted_companies;
            this.linked_companies = this.$store.getters.getResults.linked_companies;
            if (type == "whitelist") {
              if (this.$store.getters.getResults.whitelisted_companies.length > 0) {
                if (this.$store.getters.getResults.whitelisted_companies[0] == null) {
                  this.whitelist_all_companies = true;
                  this.whitelist_disable_companies = true;
                  this.whitelisted_companies = [];
                } else {
                  this.whitelist_all_companies = false;
                  this.whitelist_disable_companies = false;
                  this.whitelisted_companies = this.$store.getters.getResults.whitelisted_companies;
                }
              } else {
                this.whitelisted_companies = [];
                this.whitelist_all_companies = false;
                this.whitelist_disable_companies = false;
              }
            }
          }
        });
    },

    getBadgeList(id) {
      this.$store
        .dispatch(POST_API, {
          data: {
            user_id: id,
          },
          api: "/api/badge-list-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            // this.allBadges = this.$store.getters.getResults.badges;
            this.allBadges = this.$store.getters.getResults.badges_not_awarded;
            // this.awarded_badge = this.$store.getters.getResults.awarded_badge;
            this.awardedBadgeCompaniesList = this.$store.getters.getResults.companies;
            // this.awarded_badge_companies = this.$store.getters.getResults.awarded_badge_companies;
          }
        });
    },

    getWallet() {
      this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.rest_user_id,
          },
          api: "/api/jobseeker-recal-wallet-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.current_wallet = this.$store.getters.getResults.current_wallet;
            this.recal_wallet = this.$store.getters.getResults.recal_wallet;
            this.$refs["info-modal"].show();
          }
        });
    },
    getJobseekersAll() {
      var searchKeywordAll = $("#searchKeywordAll").val();
      this.defaultDate = this.dateRange.startDate;
      this.defaultDate1 = this.dateRange.endDate;
      this.defaultDateLastLogin = this.dateRangeLastLogin.startDate;
      this.defaultDate1LastLogin = this.dateRangeLastLogin.endDate;

      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPageAll,
            keyword: searchKeywordAll == null ? "" : searchKeywordAll,
            userDPAccountStatusFilter: searchKeywordAll == "" ? this.userDPAccountStatusFilter : "",
            userFTAccountStatusFilter: searchKeywordAll == "" ? this.userFTAccountStatusFilter : "",
            rowsPerPage: this.form.rowsPerPageAll,
            sortBy: this.sortByAll ? this.sortByAll : "users.id",
            sortDirection: this.sortDescAll ? "desc" : "asc",
            userAccountStatusFilter: searchKeywordAll == "" ? this.userAccountStatusFilter : "",
            currentTabStatus: this.currentTab,
            startDate:
              searchKeywordAll == ""
                ? this.defaultDate
                  ? moment(this.defaultDate).format("YYYY-MM-DD")
                  : ""
                : "",
            endDate:
              searchKeywordAll == ""
                ? this.defaultDate1
                  ? moment(this.defaultDate1).format("YYYY-MM-DD")
                  : ""
                : "",
            startDateLastlogin:
              searchKeywordAll == ""
                ? this.defaultDateLastLogin
                  ? moment(this.defaultDateLastLogin).format("YYYY-MM-DD")
                  : ""
                : "",
            endDateLastlogin:
              searchKeywordAll == ""
                ? this.defaultDate1LastLogin
                  ? moment(this.defaultDate1LastLogin).format("YYYY-MM-DD")
                  : ""
                : "",
            gender: searchKeywordAll == "" ? (this.gender ? this.gender : "") : "",
            singpass_signup:
              searchKeywordAll == "" ? (this.singpass_signup ? this.singpass_signup : "") : "",
            race_id: searchKeywordAll == "" ? (this.racearr.length > 0 ? this.racearr : []) : [],
            certificate_id:
              searchKeywordAll == ""
                ? this.certificateArr.length > 0
                  ? this.certificateArr
                  : []
                : [],
            preferred_category: searchKeywordAll == "" ? (this.cat.length > 0 ? this.cat : []) : [],
            age: searchKeywordAll == "" ? (this.age.length > 0 ? this.age : []) : [],
            whatsapp_blast_opt_out: searchKeywordAll == "" ? (this.whatsapp_blast_opt_out ? this.whatsapp_blast_opt_out : "") : "",
          },
          api: "/api/jobseekers-list-all",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            return [];
          } else {
            this.itemsAll = this.$store.getters.getResults.pageOfItems;
            this.totalRowsAll = this.$store.getters.getResults.pager.totalItems;
            this.perPageAll = this.$store.getters.getResults.pager.pageSize;
            this.fromAll = this.$store.getters.getResults.pager.from;
            this.toAll = this.$store.getters.getResults.pager.to;
            Bus.$emit("get_fl_emp_user_count");
            return this.itemsAll;
          }
        });
    },
    getJobseekersPending() {
      var searchKeywordPending = $("#searchKeywordPending").val();

      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPagePending,
            keyword: searchKeywordPending == null ? "" : searchKeywordPending,
            userDPAccountStatusFilter: this.userDPAccountStatusFilter,
            rowsPerPage: this.form.rowsPerPagePending,
            sortBy: this.sortByPending ? this.sortByPending : "users.id",
            sortDirection: this.sortDescPending ? "desc" : "asc",
            userAccountStatusFilter: this.userAccountStatusFilter,
            currentTabStatus: this.currentTab,
            startDate: "",
            endDate: "",
            gender: searchKeywordPending == null ? (this.gender ? this.gender : "") : "",
            singpass_signup:
              searchKeywordPending == null
                ? this.singpass_signup
                  ? this.singpass_signup
                  : ""
                : "",
            race_id:
              searchKeywordPending == null ? (this.racearr.length > 0 ? this.racearr : []) : [],
            preferred_category:
              searchKeywordPending == null ? (this.cat.length > 0 ? this.cat : []) : [],
            age: searchKeywordPending == null ? (this.age.length > 0 ? this.age : []) : [],
            whatsapp_blast_opt_out: searchKeywordAll == "" ? (this.whatsapp_blast_opt_out ? this.whatsapp_blast_opt_out : "") : "",

          },
          api: "/api/jobseekers-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            return [];
          } else {
            this.itemsPending = this.$store.getters.getResults.pageOfItems;
            this.totalRowsPending = this.$store.getters.getResults.pager.totalItems;
            this.perPagePending = this.$store.getters.getResults.pager.pageSize;
            this.fromPending = this.$store.getters.getResults.pager.from;
            this.toPending = this.$store.getters.getResults.pager.to;
            Bus.$emit("get_fl_emp_user_count");
            return this.itemsPending;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getJobseekersRejected() {
      var searchKeywordRejected = $("#searchKeywordRejected").val();

      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPageRejected,
            keyword: searchKeywordRejected == null ? "" : searchKeywordRejected,
            userDPAccountStatusFilter: "all",
            rowsPerPage: this.form.rowsPerPageRejected,
            sortBy: this.sortByRejected ? this.sortByRejected : "users.id",
            sortDirection: this.sortDescRejected ? "desc" : "asc",
            userAccountStatusFilter: "rejected",
            currentTabStatus: "all",
            singpass_signup:
              searchKeywordRejected == null
                ? this.singpass_signup
                  ? this.singpass_signup
                  : ""
                : "",
            whatsapp_blast_opt_out: searchKeywordAll == "" ? (this.whatsapp_blast_opt_out ? this.whatsapp_blast_opt_out : "") : "",
            
          },
          api: "/api/jobseekers-list-rejected",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            return [];
          } else {
            this.itemsRejected = this.$store.getters.getResults.pageOfItems;
            this.totalRowsRejected = this.$store.getters.getResults.pager.totalItems;
            this.perPageRejected = this.$store.getters.getResults.pager.pageSize;
            this.fromRejected = this.$store.getters.getResults.pager.from;
            this.toRejected = this.$store.getters.getResults.pager.to;
            Bus.$emit("get_fl_emp_user_count");
            return this.itemsRejected;
          }
        });
    },
    getJobseekersDeleted() {
      var searchKeywordDeleted = $("#searchKeywordDeleted").val();

      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPageDeleted,
            keyword: searchKeywordDeleted == null ? "" : searchKeywordDeleted,
            userDPAccountStatusFilter: this.userDPAccountStatusFilter,
            userFTAccountStatusFilter: this.userFTAccountStatusFilter,

            rowsPerPage: this.form.rowsPerPageDeleted,
            sortBy: this.sortByDeleted ? this.sortByDeleted : "users.id",
            sortDirection: this.sortDescDeleted ? "desc" : "asc",
            userAccountStatusFilter: "deleted",
            currentTabStatus: "all",
          },
          api: "/api/jobseekers-list-deleted",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            return [];
          } else {
            this.itemsDeleted = this.$store.getters.getResults.pageOfItems;
            this.totalRowsDeleted = this.$store.getters.getResults.pager.totalItems;
            this.perPageDeleted = this.$store.getters.getResults.pager.pageSize;
            this.fromDeleted = this.$store.getters.getResults.pager.from;
            this.toDeleted = this.$store.getters.getResults.pager.to;
            Bus.$emit("get_fl_emp_user_count");
            return this.itemsDeleted;
          }
        });
    },
    getJobseekersFT() {
      var searchKeywordFT = $("#searchKeywordFT").val();
      this.defaultDateFT = this.dateRange.startDate;
      this.defaultDate1FT = this.dateRange.endDate;

      return this.$store
        .dispatch(POST_API, {
          data: {
            is_fulltime: "yes",
            page: this.currentPageFT,
            keyword: searchKeywordFT == null ? "" : searchKeywordFT,
            userDPAccountStatusFilter: this.userDPAccountStatusFilter,
            userFTAccountStatusFilter: this.userFTAccountStatusFilter,

            rowsPerPage: this.form.rowsPerPageFT,
            sortBy: this.sortByFT ? this.sortByFT : "users.id",
            sortDirection: this.sortDescFT ? "desc" : "asc",
            userAccountStatusFilter: this.userAccountStatusFilter,
            currentTabStatus: "all",
            //commented on 07-03-2024
            // startDate: searchKeywordFT == '' ? this.defaultDateFT ? moment(this.defaultDateFT).startOf('month').format('YYYY-MM-DD') : '' :'',
            // endDate: searchKeywordFT == '' ? this.defaultDate1FT ? moment(this.defaultDate1FT).endOf('month').format('YYYY-MM-DD') : '' : '',
            //commented on 07-03-2024
            startDate:
              searchKeywordFT == ""
                ? this.defaultDateFT
                  ? moment(this.defaultDateFT).format("YYYY-MM-DD")
                  : ""
                : "",
            endDate:
              searchKeywordFT == ""
                ? this.defaultDate1FT
                  ? moment(this.defaultDate1FT).format("YYYY-MM-DD")
                  : ""
                : "",
            gender: searchKeywordFT == "" ? (this.gender ? this.gender : "") : "",
            singpass_signup:
              searchKeywordFT == "" ? (this.singpass_signup ? this.singpass_signup : "") : "",

            race_id: searchKeywordFT == "" ? (this.racearr.length > 0 ? this.racearr : []) : [],
            certificate_id:
              searchKeywordFT == ""
                ? this.certificateArr.length > 0
                  ? this.certificateArr
                  : []
                : [],
            preferred_category: searchKeywordFT == "" ? (this.cat.length > 0 ? this.cat : []) : [],
            age: searchKeywordFT == "" ? (this.age.length > 0 ? this.age : []) : [],
            whatsapp_blast_opt_out: searchKeywordAll == "" ? (this.whatsapp_blast_opt_out ? this.whatsapp_blast_opt_out : "") : "",

          },
          api: "/api/jobseekers-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            return [];
          } else {
            this.itemsFT = this.$store.getters.getResults.pageOfItems;
            this.totalRowsFT = this.$store.getters.getResults.pager.totalItems;
            this.perPageFT = this.$store.getters.getResults.pager.pageSize;
            this.fromFT = this.$store.getters.getResults.pager.from;
            this.toFT = this.$store.getters.getResults.pager.to;
            Bus.$emit("get_fl_emp_user_count");
            return this.itemsFT;
          }
        });
    },
    hideModel() {
      this.$refs["disable-modal"].hide();
      this.$refs["restrict-modal"].hide();
      this.$refs["badge-modal"].hide();
      this.$refs["link-modal"].hide();
      this.$refs["payslip-modal"].hide();
      this.$refs["referral-modal"].hide();
      this.$refs["info-modal"].hide();
      this.$refs["reject-modal"].hide();
      this.$refs["remarks-modal"].hide();
      this.$refs["filter-modal"].hide();
      this.$refs["transaction-modal"].hide();
      this.$refs["multiple-transaction-modal"].hide();

      this.$refs["branch-modal"].hide();
      this.$refs["whitelist-modal"].hide();
    },
    openModel(id) {
      if (this.screenWidth <= 999) {
        this.$refs["jobseeker-mobile-action-model"].hide();
      }
      this.payslip_generate_for_user = id;
      this.$refs["payslip-modal"].show();
    },
    openReferralModel(id) {
      if (this.screenWidth <= 999) {
        this.$refs["jobseeker-mobile-action-model"].hide();
      }
      this.referred_by = id;
      this.$refs["referral-modal"].show();
      // this.getToReferrals();
      this.loadLimitedReferrals();
    },
    handleOpenReferralFL() {
      if (!this.isReferralFLListLoaded) {
        this.loadLimitedReferrals();
      }
    },
    handleSearchChangeFL(searchDataFL) {
      this.searchDataFL = searchDataFL;
      if (searchDataFL) {
        this.searchReferrals(searchDataFL);
      } else {
        this.loadLimitedReferrals();
      }
    },
    loadLimitedReferrals() {
      this.$store
        .dispatch(POST_API, {
          data: { limit: 20, id: this.referred_by },
          api: "/api/assign-referral-list-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.referralsList = this.$store.getters.getResults.freelancers;
            this.isReferralFLListLoaded = true;
          }
        });
    },
    searchReferrals(searchDataFL) {
      this.$store
        .dispatch(POST_API, {
          data: { keyword: searchDataFL, id: this.referred_by },
          api: "/api/assign-referral-list-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.referralsList = this.$store.getters.getResults.freelancers;
          }
        });
    },
    openTransactionModel(id) {
      if (this.screenWidth <= 999) {
        this.$refs["jobseeker-mobile-action-model"].hide();
      }
      this.getTransactionSubType();
      this.getBillingTypes();
      this.transaction_user_id = id;
      this.imagesDoc = [];
      this.transaction_created_at = "";
      this.$refs["transaction-modal"].show();
      this.transaction_sub_type = "";
      this.sub_transactions = [];
      this.billing = "";
    },

    openMultipleTransactionModel(id) {
      if (this.screenWidth <= 999) {
        this.$refs["jobseeker-mobile-action-model"].hide();
      }
      this.getTransactionSubType();
      this.getBillingTypes();
      this.getFreelancerList();
      this.transaction_user_id = id;
      this.imagesDoc = [];
      this.transaction_created_at = "";
      this.$refs["multiple-transaction-modal"].show();
      this.transaction_sub_type = "";
      this.sub_transactions = [];
      this.billing = "";
    },
    getTransactionSubType() {
      this.$store
        .dispatch(POST_API, {
          data: {
            transaction_type: this.transaction_type,
          },
          api: "/api/transaction-sub-types",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.sub_transactions = this.$store.getters.getResults.data;
          }
        });
    },
    getBillingTypes() {
      this.$store
        .dispatch(POST_API, {
          data: {},
          api: "/api/billing-types",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.billing_types = this.$store.getters.getResults.data;
          }
        });
    },
    deduct_transaction() {
      this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.transaction_user_id,
            transaction_type: this.transaction_type,
            transaction_sub_type: this.transaction_sub_type,
            billing_type: this.billing,
            amount: this.deduct_amount,
            transaction_remarks: this.transaction_remarks,
            transaction_internal_remarks: this.transaction_internal_remarks,
            loggedin_user_id: this.form.user_id,
            transaction_created_at: this.transaction_created_at
              ? moment(this.transaction_created_at).format("YYYY-MM-DD HH:mm:ss")
              : null,
            documents: this.imagesDoc,
          },
          api: "/api/deduct-transaction",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.hideModel();

            this.success_message = this.$store.getters.getResults.message;

            this.showSuccessAlert(this.success_message);

            this.payslip_generate_for_user = "";
            this.deduct_amount = "";
            this.transaction_remarks = "";
            this.transaction_internal_remarks = "";
            this.transaction_type = "deduct";
          }
        });
    },

    deduct_multiple_transaction() {
      this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.transaction_user_id,
            fl_id: this.freelancer_id,
            transaction_type: this.transaction_type,
            transaction_sub_type: this.transaction_sub_type,
            billing_type: this.billing,
            amount: this.deduct_amount,
            transaction_remarks: this.transaction_remarks,
            transaction_internal_remarks: this.transaction_internal_remarks,
            loggedin_user_id: this.form.user_id,
            transaction_created_at: this.transaction_created_at
              ? moment(this.transaction_created_at).format("YYYY-MM-DD HH:mm:ss")
              : null,
            documents: this.imagesDoc,
          },
          api: "/api/deduct-multiple-transaction",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.hideModel();

            this.success_message = this.$store.getters.getResults.message;

            this.showSuccessAlert(this.success_message);

            this.payslip_generate_for_user = "";
            this.deduct_amount = "";
            this.transaction_remarks = "";
            this.transaction_internal_remarks = "";
            this.transaction_type = "deduct";
          }
        });
    },

    openRejectionModel(rejection_comment_for, id, comment) {
      if (this.screenWidth <= 999) {
        this.$refs["jobseeker-mobile-action-model"].hide();
      }
      this.comment = comment;
      this.comment_id = id;
      this.rejection_comment_for = rejection_comment_for;
      this.$refs["reject-modal"].show();
    },
    openFulltimeRemarks(remarks) {
      this.$refs["remarks-modal"].show();
      this.fulltime_remarks = remarks;
    },

    openFilter() {
      // this.cat = [];
      // this.racearr = [];
      // this.certificateArr = [];
      // this.age = [];
      // this.gender = '';
      this.$refs["filter-modal"].show();
    },
    openInfoModel(id, type, info = null) {
      if (this.screenWidth <= 999) {
        this.$refs["jobseeker-mobile-action-model"].hide();
      }
      if (type == "mobile") {
        this.updateInfoTitle = "Update Mobile";
      } else if (type == "wallet") {
        this.new_wallet = 0;
        this.updateInfoTitle = "Update Wallet";
      } else {
        this.updateInfoTitle = "Update Email";
      }

      if (type == "restrict") {
        this.restrict = type;
        this.rest_user_id = id;

        this.getCompanies(type);
        this.$refs["restrict-modal"].show();
      } else if (type == "link") {
        this.restrict = type;
        this.rest_user_id = id;

        this.getCompanies(type);
        this.$refs["link-modal"].show();
      } else if (type == "whitelist") {
        this.restrict = type;
        this.rest_user_id = id;

        this.getCompanies(type);
        this.$refs["whitelist-modal"].show();
      } else if (type == "wallet") {
        this.restrict = info;
        this.rest_user_id = id;

        this.getWallet();
        // this.$refs['info-modal'].show();
      } else if (type == "badge") {
        this.awarded_badge = "";
        this.awarded_badge_companies = "";
        this.badge_remarks = "";
        this.badge_user_id = id;
        this.getBadgeList(id);
        this.$refs["badge-modal"].show();
      } else {
        this.$refs["info-modal"].show();
        this.getUserData(id);
      }
    },
    openDisableModel(id, status = null, type) {
      if (this.screenWidth <= 999) {
        this.$refs["jobseeker-mobile-action-model"].hide();
      }
      if (type == "disable") {
        this.model_type = "disable";
        this.updateInfoTitleFL = "Temporary Suspend Jobseeker";
      } else if (type == "blacklist") {
        this.model_type = "blacklist";
        this.updateInfoTitleFL = "Blacklist Jobseeker";
      } else if (type == "inactive" || type == "reactive" || type == "deleted") {
        this.updateInfoTitleFL = "Activate Jobseeker";
        this.model_type = "inactive";
      } else if (type == "jobseeker_deleted") {
        this.updateInfoTitleFL = "Delete Jobseeker";
        this.model_type = "jobseeker_deleted";
      } else {
        this.updateInfoTitleFL = "Inactivate Jobseeker";
        this.model_type = "inactive";
      }
      this.usrStatus = status;
      this.remarks = "";
      this.update_user_id = id;
      this.renable_date = "";
      this.$refs["disable-modal"].show();
    },
    withNoEmailFunc($event) {
      if ($event != null) {
        this.withNoEmail = $event;
      } else {
        this.withNoEmail = $event;
      }
    },
    withNoEmailFuncBlacklist($event) {
      if ($event != null) {
        this.withNoEmailBlacklist = $event;
      } else {
        this.withNoEmailBlacklist = $event;
      }
    },
    whitelistAllCompanies($event) {
      console.log($event, "ffg");
      if ($event != null) {
        this.whitelist_all_companies = $event;
      } else {
        this.whitelist_all_companies = $event;
      }
      if (this.whitelist_all_companies) {
        this.whitelist_disable_companies = true;
        this.whitelisted_companies = [];
      } else {
        this.whitelist_disable_companies = false;
      }
    },
    categoryadd($event) {
      alert(this.cat);
    },
    getToReferrals() {
      this.$store
        .dispatch(POST_API, {
          data: {
            id: this.referred_by,
          },
          api: "/api/assign-referral-list-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.referralsList = this.$store.getters.getResults.data;
          }
        });
    },

    getFreelancerList() {
      this.$store
        .dispatch(POST_API, {
          api: "/api/freelancer-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.freelancerList = this.$store.getters.getResults.data;
          }
        });
    },
    generatePayslip() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            month: this.month != "" ? moment(this.month).format("MM") : "",
            year: this.month != "" ? moment(this.month).format("YYYY") : "",
            user_id: this.payslip_generate_for_user,
            with_no_email: this.withNoEmail,
            type: "admin",
          },
          api: "/api/jobseeker-generate-payslip-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.items = this.$store.getters.getResults.data;
            this.success_message = this.$store.getters.getResults.message;
            this.success = this.$store.getters.getResults.success;
            if (this.success) {
              window.open(this.$store.getters.getResults.url, "_blank");
              this.showSuccessAlert(this.success_message);
            } else {
              this.showSuccessAlert(this.error_message);
            }
          }
          this.payslip_generate_for_user = "";
          this.month = "";
          this.year = "";
          this.hideModel();
          // this.refreshDataTable();
        });
    },
    disableJobseeker() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.update_user_id,
            admin_id: this.form.user_id,
            disable_for_days: this.disable_for_days,
            renable_date: this.renable_date,
            remarks: this.remarks,
          },
          api: "/api/jobseeker-disable-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            var message = "Jobseeker disabled successfully.";
            this.$refs["disable-modal"].hide();
            this.showSuccessAlert(message);
            // this.refreshDataTable();
          }
        });
    },
    blacklistJobseeker() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.update_user_id,
            admin_id: this.form.user_id,
            status: "blacklisted",
            remarks: this.remarks,
            with_no_email: this.withNoEmailBlacklist,
          },
          api: "/api/jobseeker-blacklist-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            var message = "Jobseeker blacklisted successfully.";
            this.$refs["disable-modal"].hide();
            this.showSuccessAlert(message);
          }
        });
    },

    verifyEmail(id) {
      if (this.screenWidth <= 999) {
        this.$refs["jobseeker-mobile-action-model"].hide();
      }
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: id,
          },
          api: "/api/jobseeker-verify-email-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            var message = "Email Verified successfully.";
            // this.refreshDataTable();
            this.showSuccessAlert(message);
          }
        });
    },
    verifyMobileNumber(id) {
      if (this.screenWidth <= 999) {
        this.$refs["jobseeker-mobile-action-model"].hide();
      }
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: id,
          },
          api: "/api/jobseeker-verify-mob-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            var message = "Mobile Number Verified successfully.";
            // this.refreshDataTable();
            this.showSuccessAlert(message);
          }
        });
    },
    deletePaynow(id, payout_type) {
      if (this.screenWidth <= 999) {
        this.$refs["jobseeker-mobile-action-model"].hide();
      }
      this.$swal({
        title: "Please Confirm",
        text: "Are you sure you want to delete this record?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(POST_API, {
              data: {
                user_id: id,
                payout_type: payout_type,
              },
              api: "/api/jobseeker-delete-payout-admin",
            })
            .then(() => {
              if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showAlert();
              } else {
                var message = "Payout detail deleted successfully";
                this.showSuccessAlert(message);
                // this.getJobseekersAll();
                this.hideModel();
              }
            });
        }
      });
    },
    logoutFromApp(id) {
      if (this.screenWidth <= 999) {
        this.$refs["jobseeker-mobile-action-model"].hide();
      }
      this.$swal({
        title: "Please Confirm",
        text: "Are you sure you want to logout this Jobseeker from the App?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(POST_API, {
              data: {
                user_id: id,
              },
              api: "/api/jobseeker-app-logout-admin",
            })
            .then(() => {
              if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showAlert();
              } else {
                var message = "Jobseeker logged out successfully.";
                // this.refreshDataTable();
                this.showSuccessAlert(message);
                // this.getJobseekersAll();
                this.hideModel();
              }
            });
        }
      });
    },
    permanentDeleteUser(id) {
      if (this.screenWidth <= 999) {
        this.$refs["jobseeker-mobile-action-model"].hide();
      }
      this.$swal({
        title: "Please Confirm",
        text: "Are you sure you want to permanently delete this record?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  id: id,
                },
                api: "/api/admin-empjobseeker-permanent-delete",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  var message = "Jobseeker Deleted successfully.";
                  // this.refreshDataTable();
                  this.showSuccessAlert(message);
                  // this.getJobseekersAll();
                }
              });
          }
        })
        .catch((err) => {});
    },
    deleteJobseeker() {
      if (this.screenWidth <= 999) {
        this.$refs["jobseeker-mobile-action-model"].hide();
      }
      this.$swal({
        title: "Please Confirm",
        text: "Are you sure you want to delete this record?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  id: this.update_user_id,
                  logged_id_id: this.form.user_id,
                  remarks: this.remarks,
                },
                api: "/api/admin-empjobseeker-delete",
              })
              .then(() => {
                this.$refs["disable-modal"].hide();

                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  var message = "Jobseeker Deleted successfully.";
                  // this.refreshDataTable();
                  this.showSuccessAlert(message);
                  // this.getJobseekersAll();
                }
              });
          }
        })
        .catch((err) => {});
    },
    copyURL() {
      let testingUrlToCopy = document.querySelector("#testing-copy-url");
      testingUrlToCopy.setAttribute("type", "text");
      testingUrlToCopy.select();

      testingUrlToCopy.setSelectionRange(0, 99999); // For mobile devices

      // Copy the text inside the text field
      navigator.clipboard.writeText(testingUrlToCopy.value);
      $("#copied-text-url").empty().append("Coped to Clipboard!!");

      // Alert the copied text
      testingUrlToCopy.setAttribute("type", "hidden");
    },
    copyCode() {
      let testingCodeToCopy = document.querySelector("#testing-code");
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();

      testingCodeToCopy.setSelectionRange(0, 99999); // For mobile devices

      // Copy the text inside the text field
      navigator.clipboard.writeText(testingCodeToCopy.value);
      $("#copied-text-code").empty().append("Coped to Clipboard!!");

      // Alert the copied text
      testingCodeToCopy.setAttribute("type", "hidden");
    },
    cropImage() {
      this.$refs["crop-modal"].hide();
      this.form.selectedImage.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    setImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.cropImg = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
    flipX() {
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute("data-scale", scale);
    },
    flipY() {
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute("data-scale", scale);
    },
    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
    },
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setData() {
      if (!this.data) return;
      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    showImg() {
      this.$refs["crop-modal"].show();
    },
    onSubmitImg(evt) {
      evt.preventDefault();
      this.$refs["crop-modal"].hide();
      this.$refs["crop-modal"].refresh();
    },
    onImageChange(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        this.form.image = input.files[0];
        if (this.form.image.type != "image/jpeg" && this.form.image.type != "image/jpg") {
          this.form.selectedImage.image = this.webUrl + "/images/upload-icon.svg";
          this.error_message = "Please select only .jpg or .jpeg image.";
          this.form.selectedImage.image = "";
          this.showAlert();
        } else if (this.form.image.size > 5242880) {
          this.form.selectedImage.image = this.webUrl + "/images/upload-icon.svg";
          this.error_message = "Image should be less than 5 MB.";
          this.form.selectedImage.image = "";
          this.showAlert();
        } else {
          reader.onload = (e) => {
            this.tempLogo = e.target.result;
            this.form.selectedImage.name = this.form.image.name;
            this.showImg();
            event.target.value = "";
          };
        }
      }
    },
    uploadImageSuccess(formData, index, fileList) {
      for (var i = 0; i < fileList.length; i++) {
        var stringLength = fileList[i].path.length - "data:image/png;base64,".length;
        var sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
        var sizeInMB = sizeInBytes / 1000000;
        var name = fileList[i].name.split(".");
        if (
          name[name.length - 1] != "png" &&
          name[name.length - 1] != "jpg" &&
          name[name.length - 1] != "jpeg"
        ) {
          this.error_message = "Please select only .png,.jpg or .jpeg image.";
          fileList.splice(index, 1);
          this.showAlert();
        } else if (sizeInMB > 5) {
          this.error_message = "Image should be less than 5 MB.";
          fileList.splice(index, 1);
          this.showAlert();
        } else {
          if (typeof fileList[i].url == "undefined") {
            var url = fileList[i].path;
            fileList[i].url = url;
          }
        }
      }

      this.imagesDoc = fileList;
    },
    beforeRemove(index, done, fileList) {
      fileList.splice(index, 1);

      this.imagesDoc = fileList;
    },
    editImage(formData, index, fileList) {
      for (var i = 0; i < fileList.length; i++) {
        var stringLength = fileList[i].path.length - "data:image/png;base64,".length;
        var sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
        var sizeInMB = sizeInBytes / 1000000;
        var name = fileList[i].name.split(".");
        if (
          name[name.length - 1] != "png" &&
          name[name.length - 1] != "jpg" &&
          name[name.length - 1] != "jpeg"
        ) {
          this.error_message = "Please select only .png,.jpg or .jpeg image.";
          fileList.splice(index, 1);
          this.showAlert();
        } else if (sizeInMB > 5) {
          this.error_message = "Image should be less than 5 MB.";
          fileList.splice(index, 1);
          this.showAlert();
        } else {
          if (typeof fileList[i].url == "undefined") {
            var url = fileList[i].path;
            fileList[i].url = url;
          }
        }
      }
      this.imagesDoc = fileList;
    },
    permissionStaff() {
      if (this.$store.getters.getCurrentUserPermission) {
        if (
          this.$store.getters.getCurrentUserPermission.data.length > 0 &&
          this.$store.getters.currentUser.user_type_id == 2
        ) {
          var menu = this.$store.getters.getCurrentUserPermission.data;
          this.actions = permission.getPermissionStaff(menu, "Jobseekers");
          this.actionsBadge = permission.getPermissionStaff(menu, "Manage Badge");
          this.actionsCertificate = permission.getPermissionStaff(menu, "Manage Certificate");
        }
      }
    },
    show(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actions.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showBadge(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actionsBadge.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showCertificate(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actionsCertificate.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
  },
  mounted() {
     $(document).on('click', '.border_li', function() { 
       $('.border_li').removeClass('jbs_active_color');  // Remove 'active' from all
        $(this).addClass('jbs_active_color');  // Add 'active' to the clicked item
    });
    this.updateScreenWidth();
    this.onScreenResize();

    $(".mobile_loader").hide();
    $(".show_filter_arrow .table thead th div").contents().unwrap();
    // document.title = "EL Connect - Admin - Jobseekers List";
    this.form.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.form.admin_user_type_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.user_type_id : "";

    this.form.admin_email =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.email : "";

    this.designation =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.designation : "";

    console.log(this.designation, "designation");
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    Bus.$emit("get_fl_emp_user_count");
    // this.getJobseekersAll();
    this.getDropdownData();
    // this.getFreelancerList();
    this.getCert();

    this.getRace();
    this.permissionStaff();
  },
};
</script>
