<template>
  <div>
    <div class="row">
      <div class="container my_new_container anchor_height_ticket">
        <!-- Dashboard Headline -->
        <div class="dashboard-headline">
          <h1>Tickets</h1>
          <!-- Breadcrumbs -->
          <nav id="breadcrumbs" class="dark">
            <ul>
              <li><a href="#">Home</a></li>
              <li>Tickets</li>
            </ul>
          </nav>
        </div>
        <b-form-checkbox
          v-model="auto_reply_enabled"
          size="lg"
          name="check-button"
          switch
          @change="change_auto_reply($event)"
          class="payout_auto_pay"
        >
          Auto Reply
        </b-form-checkbox>
        <b-tabs class="my_tab_job common_tab_scroll_mobile tab_header_action_th">
          <b-tab :title="'Open'" @click="changeTab">
            <div class="container  my_new_container input_feild_big_screenz">
              <div class="row">
                <div
                  class="col-md-3 col-xl-3 col-md-3 col-sm-12 desktop_margin_bottom_zero only_mobile_margin_bottom_18 mobile_max_width_and_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div class="search-box custom_search_admin desktop_margin_top_zero">
                    <!-- <b-input-group> -->
                    <!-- <br/> -->
                    <b-input-group-prepend>
                      <b-form-input
                        id="searchBtn"
                        @keyup.enter.native="filterOpen = searchKeywordOpen"
                        v-model="searchKeywordOpen"
                        v-on:change="changeTab"
                        placeholder="Search by keyword"
                      ></b-form-input>
                      <b-button class="btn points_events"
                        ><i class="fa fa-search" @click="filterOpen = searchKeywordOpen"></i
                      ></b-button>
                    </b-input-group-prepend>
                    <!-- </b-input-group> -->
                  </div>
                </div>
                <div
                  class="col-md-3 col-xl-3 col-sm-12 mobile_top_9 only_mobile_margin_bottom_18 mobile_max_width_and_flex"
                >
                  <b-form-group
                    id="input-group-3"
                    class="contact-fields desktop_margin_bottom_zero"
                  >
                    <span class="custom-dropdown w-100">
                      <b-form-select
                        v-model="userFilter"
                        @change="changeTab"
                        class="common_height_38 desktop_margin_bottom_zero desktop_margin_bottom_zero date_style_size"
                      >
                        <option value="">Select Generated By</option>
                        <option :value="user.id" v-for="user in users">
                          {{ user.last_name ? user.last_name : "" | capitalize }}
                          {{ user.first_name ? user.first_name : "" | capitalize }}
                        </option>
                      </b-form-select>
                    </span>
                  </b-form-group>
                </div>
                <div
                  class="col-md-3 col-xl-3 col-sm-12 mobile_top_9 only_mobile_margin_bottom_18 mobile_max_width_and_flex"
                >
                  <b-form-group
                    id="input-group-3"
                    class="contact-fields desktop_margin_bottom_zero"
                  >
                    <span class="custom-dropdown w-100">
                      <b-form-select
                        v-model="ticketFilter"
                        @change="changeTab"
                        class="common_height_38 desktop_margin_bottom_zero desktop_margin_bottom_zero date_style_size"
                      >
                        <option value="">Select Ticket Type</option>
                        <option :value="user.id" v-for="user in ticket_type">
                          {{ user.title != null ? user.title : "" | capitalize }}
                        </option>
                      </b-form-select>
                    </span>
                  </b-form-group>
                </div>
                <div class="col-md-3 col-xl-3 col-sm-12 mobile_top_9 mobile_max_width_and_flex">
                  <b-form-group
                    id="input-group-3"
                    class="contact-fields desktop_margin_bottom_zero"
                  >
                    <span class="custom-dropdown w-100">
                      <b-form-select
                        v-model="priorityFilter"
                        @change="changeTab"
                        class="common_height_38 desktop_margin_bottom_zero desktop_margin_bottom_zero date_style_size"
                      >
                        <option value="">Select Priority</option>
                        <option value="highest">Highest</option>
                        <option value="high">High</option>
                        <option value="medium">Medium</option>
                        <option value="low">Low</option>
                      </b-form-select>
                    </span>
                  </b-form-group>
                </div>
              </div>
            </div>

            <!-- Page Content
            ================================================== -->
            <div class="top_b_tab">
              <h2>Tickets</h2>
            </div>
            <div
              class="ticket_second_box desktop_margin_top_zero"
              v-if="itemsOpen && itemsOpen.length >= 0"
            >
              <!-- <h3 class="ticket_h3">Your Tickets</h3> -->

              <div
                class="edit-profile-section border-radius-4 white-bg p-lg-4 p-3 common_box_shadow common_ticket_desc"
                v-if="itemsOpen != null"
                v-for="(itemsOpen, key) in itemsOpen"
              >
                <div class="row">
                  <div
                    class="desktop_padding_left_right_zero col-md-8 col-sm-8 new_ticket_max_width_and_flex"
                  >
                    <h3 class="ticket_box_heading" v-if="itemsOpen.title">
                      {{ itemsOpen.title | capitalize }} -
                      {{ itemsOpen.ticket_subject ? itemsOpen.ticket_subject : "" | capitalize }}
                    </h3>
                  </div>
                  <div
                    class="desktop_padding_left_right_zero col-md-4 padding_top_20_mobile col-sm-4 new_ticket_max_width_and_flex opne_close_btn_ticket_list"
                  >
                    <h4 class="ticket_box_heading date_heading">
                      <span>{{ itemsOpen.created_at | moment }}</span>
                    </h4>
                    <h4 class="ticket_box_heading date_heading">
                      <span>Ticket ID: {{ itemsOpen.ticket_unique_id }}</span>
                    </h4>
                  </div>
                </div>
                <!-- <h3 class="ticket_box2_heading">{{itemsOpen.ticket_subject ? itemsOpen.ticket_subject : '' | capitalize}}</h3> -->

                <ul class="ticket_second_box_ul">
                  <!-- <li>TICKET ID - <span> {{itemsOpen.ticket_unique_id}}</span></li> -->
                  <div v-if="itemsOpen.ticket_messages_val.length > 0">
                    <span v-if="itemsOpen.ticket_messages_val[0].ticket_sender_profile">
                      <li
                        v-if="
                          itemsOpen.ticket_messages_val &&
                          itemsOpen.ticket_messages_val[0].ticket_sender_profile.user_type_id == 5
                        "
                      >
                        <strong>Name - </strong
                        ><b-link
                          :to="
                            showFreelancer('view profile')
                              ? {
                                  name: 'jobseeker-dp-profile-view',
                                  params: {
                                    id: encrypt(
                                      itemsOpen.ticket_messages_val[0].ticket_sender_profile.id
                                    ),
                                  },
                                }
                              : null
                          "
                          target="_blank"
                          style="color: #4c8bf5"
                          ><span>
                            {{
                              itemsOpen.ticket_messages_val[0].ticket_sender_profile.last_name
                                ? itemsOpen.ticket_messages_val[0].ticket_sender_profile.last_name
                                : "" | capitalize
                            }}
                            {{
                              itemsOpen.ticket_messages_val[0].ticket_sender_profile.first_name
                                ? itemsOpen.ticket_messages_val[0].ticket_sender_profile.first_name
                                : "" | capitalize
                            }}</span
                          ></b-link
                        >
                      </li>
                      <li
                        v-if="
                          itemsOpen.ticket_messages_val &&
                          (itemsOpen.ticket_messages_val[0].ticket_sender_profile.user_type_id ==
                            3 ||
                            itemsOpen.ticket_messages_val[0].ticket_sender_profile.user_type_id ==
                              4)
                        "
                      >
                        <strong>Name - </strong
                        ><b-link
                          :to="
                            showEmployer('view profile')
                              ? {
                                  name: 'employer-profile-view',
                                  params: {
                                    id: encrypt(
                                      itemsOpen.ticket_messages_val[0].ticket_sender_profile.id
                                    ),
                                    company_id: encrypt(
                                      itemsOpen.ticket_messages_val[0].ticket_sender_profile
                                        .company_id
                                    ),
                                  },
                                }
                              : null
                          "
                          target="_blank"
                          style="color: #4c8bf5"
                          ><span>
                            {{
                              itemsOpen.ticket_messages_val[0].ticket_sender_profile.last_name
                                ? itemsOpen.ticket_messages_val[0].ticket_sender_profile.last_name
                                : "" | capitalize
                            }}
                            {{
                              itemsOpen.ticket_messages_val[0].ticket_sender_profile.first_name
                                ? itemsOpen.ticket_messages_val[0].ticket_sender_profile.first_name
                                : "" | capitalize
                            }}</span
                          ></b-link
                        >
                      </li>
                    </span>
                  </div>
                  <li v-if="itemsOpen.job_id">
                    <strong> Job ID - </strong><span> {{ itemsOpen.job_id | capitalize }}</span>
                  </li>
                  <li v-if="itemsOpen.priority">
                    <strong>Priority - </strong
                    ><span>
                      {{ itemsOpen.priority ? itemsOpen.priority : "N/A" | capitalize
                      }}<span
                        class="material-icons-outlined"
                        v-if="itemsOpen.priority == 'highest'"
                        style="vertical-align: middle; color: red"
                        >keyboard_double_arrow_up_outlined</span
                      >
                      <span
                        class="material-icons-outlined"
                        v-if="itemsOpen.priority == 'high'"
                        style="vertical-align: middle; color: red"
                        >keyboard_arrow_up_outlined</span
                      >
                      <span
                        class="material-icons-outlined"
                        v-if="itemsOpen.priority == 'medium'"
                        style="vertical-align: middle; color: orange"
                        >drag_handle_outlined</span
                      >
                      <span
                        class="material-icons-outlined"
                        v-if="itemsOpen.priority == 'low'"
                        style="vertical-align: middle; color: rgb(54, 160, 216)"
                        >keyboard_arrow_down_outlined</span
                      ></span
                    >
                  </li>
                  <li>
                    <strong>Ticket Status - </strong>
                    <span> {{ itemsOpen.status | capitalize }}</span>
                  </li>
                  <!-- <li>DATE - <span> {{itemsOpen.created_at | moment}}</span></li> -->
                </ul>
                <!-- <h3 class="ticket_box_second_heading">{{itemsOpen.ticket_subject | capitalize}}</h3> -->
                <!-- <div>
                                    <div class="images" v-viewer>
                                    <img v-for="src in images" :key="src" :src="src">
                                    </div>
                                    <b-link type="button" style="text-decoration: underline;" @click="showDoc(itemsOpen.medical_document)" v-if="itemsOpen.medical_document.length > 0">Click to show Attachment</b-link>
                                </div> -->
                <div class="row">
                  <div
                    class="desktop_padding_left_right_zero col-md-8 col-sm-8 new_ticket_max_width_and_flex"
                  >
                    <p v-if="itemsOpen.ticket_messages_val[0].message.length <= 150">
                      <span class="red-text"><strong>Issue - </strong></span
                      >{{ itemsOpen.ticket_messages_val[0].message | capitalize }}
                    </p>
                    <p v-else>
                      <span class="red-text">Issue - </span
                      >{{
                        (itemsOpen.ticket_messages_val[0].message.substring(0, 150) + "...")
                          | capitalize
                      }}
                    </p>
                  </div>
                  <!-- <div class="" v-if="itemsOpen.ticket_messages_val"> -->
                  <!-- <div class="issue_div"> -->
                  <!-- <b-row>
                                            <b-col md="2" v-for="(docs,key) in itemsOpen.medical_document" :key="key">
                                                <viewer :images="itemsOpen">

                                                <b-img :src="docs.document" v-if="docs.image_type == 'image'" height="80" width="100" alt="certification img" class="ticket_document_img"></b-img>
                                                <b-img :src="webUrl+'assets/images/pdf.svg'" v-if="docs.image_type == 'pdf'" alt="certification img"></b-img>

                                                </viewer>
                                            </b-col>
                                        </b-row> -->
                  <!-- </div> -->
                  <div
                    class="desktop_padding_left_right_zero col-md-4 padding_top_20_mobile col-sm-4 new_ticket_max_width_and_flex"
                    v-if="itemsOpen.ticket_messages_val"
                  >
                    <div class="ticket_btn_divs">
                      <b-link
                        :to="{
                          name: 'admin-support-ticket-view',
                          params: { id: encrypt(itemsOpen.id) },
                        }"
                        class="button ripple-effect big"
                        >Ticket Details</b-link
                      >
                      <!-- <a href="#" class="button ripple-effect big">Ticket Detail</a> -->
                    </div>
                  </div>
                  <!-- </div> -->
                </div>
              </div>
            </div>
            <nav class="pagination-box custom_pagination_border_radius">
              <div class="page-row-box d-inline-block">
                <b-form-group id="input-group-4">
                  <span class="d-inline-block">Rows per page</span>
                  <span class="d-inline-block">
                    <b-form-select v-model="form.rowsPerPageOpen" @change="changeTab">
                      <option>25</option>
                      <option>50</option>
                      <option>75</option>
                      <option>100</option>
                    </b-form-select></span
                  >
                </b-form-group>
              </div>
              <div class="total-page-count d-inline-block">
                <ul>
                  <li>
                    <span v-if="totalRowsOpen != 0"
                      >{{ fromOpen }} - {{ toOpen }} of {{ totalRowsOpen }}</span
                    >
                  </li>
                </ul>
              </div>
              <b-pagination
                @input="changeTab"
                :total-rows="totalRowsOpen"
                :per-page="perPageOpen"
                v-model="currentPageOpen"
                prev-text=""
                next-text=""
                hide-goto-end-buttons
              />
            </nav>
          </b-tab>
          <b-tab :title="'Pending (' + pendingCount + ')'" @click="changeTabPending">
            <template #title>
              <span
                >Pending<span class="ticket_count_span">{{ pendingCount }}</span></span
              >
            </template>
            <div class="container new_cnt_pdng my_new_container input_feild_big_screenz">
              <div class="row">
                <div
                  class="col-md-3 col-xl-3 col-md-3 col-sm-12 desktop_margin_bottom_zero only_mobile_margin_bottom_18 mobile_max_width_and_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div class="search-box custom_search_admin desktop_margin_top_zero">
                    <!-- <b-input-group> -->
                    <!-- <br/> -->
                    <b-input-group-prepend>
                      <b-form-input
                        id="searchBtn"
                        @keyup.enter.native="filterPending = searchKeywordPending"
                        v-model="searchKeywordPending"
                        v-on:change="changeTab"
                        placeholder="Search by keyword"
                      ></b-form-input>
                      <b-button class="btn points_events"
                        ><i class="fa fa-search" @click="filterPending = searchKeywordPending"></i
                      ></b-button>
                    </b-input-group-prepend>
                    <!-- </b-input-group> -->
                  </div>
                </div>
                <div
                  class="col-md-3 col-xl-3 col-sm-12 mobile_top_9 only_mobile_margin_bottom_18 mobile_max_width_and_flex"
                >
                  <b-form-group
                    id="input-group-3"
                    class="contact-fields desktop_margin_bottom_zero"
                  >
                    <span class="custom-dropdown w-100">
                      <b-form-select
                        v-model="userFilterPending"
                        @change="changeTabPending"
                        class="common_height_38 desktop_margin_bottom_zero desktop_margin_bottom_zero date_style_size"
                      >
                        <option value="">Select Generated By</option>
                        <option :value="user.id" v-for="user in usersPending">
                          {{ user.last_name ? user.last_name : "" | capitalize }}
                          {{ user.first_name ? user.first_name : "" | capitalize }}
                        </option>
                      </b-form-select>
                    </span>
                  </b-form-group>
                </div>
                <div
                  class="col-md-3 col-xl-3 col-sm-12 mobile_top_9 only_mobile_margin_bottom_18 mobile_max_width_and_flex"
                >
                  <b-form-group
                    id="input-group-3"
                    class="contact-fields desktop_margin_bottom_zero"
                  >
                    <span class="custom-dropdown w-100">
                      <b-form-select
                        v-model="ticketFilterPending"
                        @change="changeTabPending"
                        class="common_height_38 desktop_margin_bottom_zero desktop_margin_bottom_zero date_style_size"
                      >
                        <option value="">Select Ticket Type</option>
                        <option :value="user.id" v-for="user in ticket_type">
                          {{ user.title != null ? user.title : "" | capitalize }}
                        </option>
                      </b-form-select>
                    </span>
                  </b-form-group>
                </div>
                <div class="col-md-3 col-xl-3 col-sm-12 mobile_top_9 mobile_max_width_and_flex">
                  <b-form-group
                    id="input-group-3"
                    class="contact-fields desktop_margin_bottom_zero"
                  >
                    <span class="custom-dropdown w-100">
                      <b-form-select
                        v-model="priorityFilterPending"
                        @change="changeTabPending"
                        class="common_height_38 desktop_margin_bottom_zero desktop_margin_bottom_zero date_style_size"
                      >
                        <option value="">Select Priority</option>
                        <option value="highest">Highest</option>
                        <option value="high">High</option>
                        <option value="medium">Medium</option>
                        <option value="low">Low</option>
                      </b-form-select>
                    </span>
                  </b-form-group>
                </div>
              </div>
            </div>

            <!-- Page Content
            ================================================== -->
            <div class="top_b_tab">
              <h2>Tickets</h2>
            </div>
            <div
              class="ticket_second_box desktop_margin_top_zero"
              v-if="itemsPending && itemsPending.length >= 0"
            >
              <!-- <h3 class="ticket_h3">Your Tickets</h3> -->

              <div
                class="edit-profile-section border-radius-4 white-bg p-lg-4 p-3 common_box_shadow common_ticket_desc"
                v-if="itemsPending != null"
                v-for="(itemsPending, key) in itemsPending"
              >
                <div class="row">
                  <div
                    class="desktop_padding_left_right_zero col-md-8 col-sm-8 new_ticket_max_width_and_flex"
                  >
                    <h3 class="ticket_box_heading" v-if="itemsPending.title">
                      {{ itemsPending.title | capitalize }} -
                      {{
                        itemsPending.ticket_subject ? itemsPending.ticket_subject : "" | capitalize
                      }}
                    </h3>
                  </div>
                  <div
                    class="desktop_padding_left_right_zero col-md-4 padding_top_20_mobile col-sm-4 new_ticket_max_width_and_flex opne_close_btn_ticket_list"
                  >
                    <h4
                      class="ticket_box_heading date_heading"
                      v-if="itemsPending.ticket_selected_admin_profile"
                    >
                      <span
                        >{{ itemsPending.ticket_selected_admin_profile.last_name | capitalize }}
                        {{
                          itemsPending.ticket_selected_admin_profile.first_name | capitalize
                        }}</span
                      >
                    </h4>
                    <h4 class="ticket_box_heading date_heading">
                      <span>{{ itemsPending.created_at | moment }}</span>
                    </h4>
                    <h4 class="ticket_box_heading date_heading">
                      <span>Ticket ID: {{ itemsPending.ticket_unique_id }}</span>
                    </h4>
                  </div>
                </div>
                <!-- <h3 class="ticket_box2_heading">{{itemsPending.ticket_subject ? itemsPending.ticket_subject : '' | capitalize}}</h3> -->
                <div class="row ticket_section">
                  <div class="col-xl-8 col-md-8">
                    <ul class="ticket_second_box_ul">
                      <!-- <li>TICKET ID - <span> {{itemsPending.ticket_unique_id}}</span></li> -->
                      <div v-if="itemsPending.ticket_messages_val.length > 0">
                        <span v-if="itemsPending.ticket_messages_val[0].ticket_sender_profile">
                          <li
                            v-if="
                              itemsPending.ticket_messages_val &&
                              itemsPending.ticket_messages_val[0].ticket_sender_profile
                                .user_type_id == 5
                            "
                          >
                            <strong>Name - </strong
                            ><b-link
                              :to="
                                showFreelancer('view profile')
                                  ? {
                                      name: 'jobseeker-dp-profile-view',
                                      params: {
                                        id: encrypt(
                                          itemsPending.ticket_messages_val[0].ticket_sender_profile
                                            .id
                                        ),
                                      },
                                    }
                                  : null
                              "
                              target="_blank"
                              style="color: #4c8bf5"
                              ><span>
                                {{
                                  itemsPending.ticket_messages_val[0].ticket_sender_profile
                                    .last_name
                                    ? itemsPending.ticket_messages_val[0].ticket_sender_profile
                                        .last_name
                                    : "" | capitalize
                                }}
                                {{
                                  itemsPending.ticket_messages_val[0].ticket_sender_profile
                                    .first_name
                                    ? itemsPending.ticket_messages_val[0].ticket_sender_profile
                                        .first_name
                                    : "" | capitalize
                                }}</span
                              ></b-link
                            >
                          </li>
                          <li
                            v-if="
                              itemsPending.ticket_messages_val &&
                              (itemsPending.ticket_messages_val[0].ticket_sender_profile
                                .user_type_id == 3 ||
                                itemsPending.ticket_messages_val[0].ticket_sender_profile
                                  .user_type_id == 4)
                            "
                          >
                            <strong>Name - </strong
                            ><b-link
                              :to="
                                showEmployer('view profile')
                                  ? {
                                      name: 'employer-profile-view',
                                      params: {
                                        id: encrypt(
                                          itemsPending.ticket_messages_val[0].ticket_sender_profile
                                            .id
                                        ),
                                        company_id: encrypt(
                                          itemsPending.ticket_messages_val[0].ticket_sender_profile
                                            .company_id
                                        ),
                                      },
                                    }
                                  : null
                              "
                              target="_blank"
                              style="color: #4c8bf5"
                              ><span>
                                {{
                                  itemsPending.ticket_messages_val[0].ticket_sender_profile
                                    .last_name
                                    ? itemsPending.ticket_messages_val[0].ticket_sender_profile
                                        .last_name
                                    : "" | capitalize
                                }}
                                {{
                                  itemsPending.ticket_messages_val[0].ticket_sender_profile
                                    .first_name
                                    ? itemsPending.ticket_messages_val[0].ticket_sender_profile
                                        .first_name
                                    : "" | capitalize
                                }}</span
                              ></b-link
                            >
                          </li>
                        </span>
                      </div>
                      <li v-if="itemsPending.job_id">
                        <strong> Job ID - </strong
                        ><span> {{ itemsPending.job_id | capitalize }}</span>
                      </li>
                      <li v-if="itemsPending.priority">
                        <strong>Priority - </strong
                        ><span>
                          {{ itemsPending.priority ? itemsPending.priority : "N/A" | capitalize
                          }}<span
                            class="material-icons-outlined"
                            v-if="itemsPending.priority == 'highest'"
                            style="vertical-align: middle; color: red"
                            >keyboard_double_arrow_up_outlined</span
                          >
                          <span
                            class="material-icons-outlined"
                            v-if="itemsPending.priority == 'high'"
                            style="vertical-align: middle; color: red"
                            >keyboard_arrow_up_outlined</span
                          >
                          <span
                            class="material-icons-outlined"
                            v-if="itemsPending.priority == 'medium'"
                            style="vertical-align: middle; color: orange"
                            >drag_handle_outlined</span
                          >
                          <span
                            class="material-icons-outlined"
                            v-if="itemsPending.priority == 'low'"
                            style="vertical-align: middle; color: rgb(54, 160, 216)"
                            >keyboard_arrow_down_outlined</span
                          ></span
                        >
                      </li>
                      <li>
                        <strong>Ticket Status - </strong>
                        <span> {{ itemsPending.status | capitalize }}</span>
                      </li>
                      <!-- <li>DATE - <span> {{itemsPending.created_at | moment}}</span></li> -->
                    </ul>
                  </div>
                  <div class="col-xl-4 col-md-4">
                    <h4
                      v-if="itemsPending.last_reply_by != null"
                      class="mb-0 pending_message_badge ticket_box_heading date_heading"
                    >
                      <span>1 New Reply</span>
                    </h4>
                  </div>
                </div>
                <!-- <h3 class="ticket_box_second_heading">{{itemsPending.ticket_subject | capitalize}}</h3> -->
                <!-- <div>
                                    <div class="images" v-viewer>
                                    <img v-for="src in images" :key="src" :src="src">
                                    </div>
                                    <b-link type="button" style="text-decoration: underline;" @click="showDoc(itemsPending.medical_document)" v-if="itemsPending.medical_document.length > 0">Click to show Attachment</b-link>
                                </div> -->
                <div class="row">
                  <div
                    class="desktop_padding_left_right_zero col-md-8 col-sm-8 new_ticket_max_width_and_flex"
                  >
                    <p v-if="itemsPending.ticket_messages_val[0].message.length <= 150">
                      <span class="red-text"><strong>Issue - </strong></span
                      >{{ itemsPending.ticket_messages_val[0].message | capitalize }}
                    </p>
                    <p v-else>
                      <span class="red-text">Issue - </span
                      >{{
                        (itemsPending.ticket_messages_val[0].message.substring(0, 150) + "...")
                          | capitalize
                      }}
                    </p>
                  </div>
                  <!-- <div class="" v-if="itemsPending.ticket_messages_val"> -->
                  <!-- <div class="issue_div"> -->
                  <!-- <b-row>
                                            <b-col md="2" v-for="(docs,key) in itemsPending.medical_document" :key="key">
                                                <viewer :images="itemsPending">

                                                <b-img :src="docs.document" v-if="docs.image_type == 'image'" height="80" width="100" alt="certification img" class="ticket_document_img"></b-img>
                                                <b-img :src="webUrl+'assets/images/pdf.svg'" v-if="docs.image_type == 'pdf'" alt="certification img"></b-img>

                                                </viewer>
                                            </b-col>
                                        </b-row> -->
                  <!-- </div> -->
                  <div
                    class="desktop_padding_left_right_zero col-md-4 padding_top_20_mobile col-sm-4 new_ticket_max_width_and_flex"
                    v-if="itemsPending.ticket_messages_val"
                  >
                    <div class="ticket_btn_divs">
                      <b-link
                        :to="{
                          name: 'admin-support-ticket-view',
                          params: { id: encrypt(itemsPending.id) },
                        }"
                        class="button ripple-effect big"
                        >Ticket Details</b-link
                      >
                      <!-- <a href="#" class="button ripple-effect big">Ticket Detail</a> -->
                    </div>
                  </div>
                  <!-- </div> -->
                </div>
              </div>
            </div>
            <nav class="pagination-box custom_pagination_border_radius">
              <div class="page-row-box d-inline-block">
                <b-form-group id="input-group-4">
                  <span class="d-inline-block">Rows per page</span>
                  <span class="d-inline-block">
                    <b-form-select v-model="form.rowsPerPageOpen" @change="changeTabPending">
                      <option>25</option>
                      <option>50</option>
                      <option>75</option>
                      <option>100</option>
                    </b-form-select></span
                  >
                </b-form-group>
              </div>
              <div class="total-page-count d-inline-block">
                <ul>
                  <li>
                    <span v-if="totalRowsPending != 0"
                      >{{ fromPending }} - {{ toPending }} of {{ totalRowsPending }}</span
                    >
                  </li>
                </ul>
              </div>
              <b-pagination
                @input="changeTabPending"
                :total-rows="totalRowsPending"
                :per-page="perPagePending"
                v-model="currentPagePending"
                prev-text=""
                next-text=""
                hide-goto-end-buttons
              />
            </nav>
          </b-tab>
          <b-tab :title="'Closed'" @click="changeTabClosed">
            <div class="container new_cnt_pdng my_new_container input_feild_big_screenz">
              <div class="row">
                <div
                  class="col-md-3 col-xl-3 col-md-3 col-sm-12 desktop_margin_bottom_zero only_mobile_margin_bottom_18 mobile_max_width_and_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div class="search-box custom_search_admin desktop_margin_top_zero">
                    <!-- <b-input-group> -->
                    <!-- <br/> -->
                    <b-input-group-prepend>
                      <b-form-input
                        id="searchBtn"
                        @keyup.enter.native="filterClose = searchKeywordClose"
                        v-model="searchKeywordClose"
                        v-on:change="changeTabClosed"
                        placeholder="Search by keyword"
                      ></b-form-input>
                      <b-button class="btn points_events"
                        ><i class="fa fa-search" @click="filterClose = searchKeywordClose"></i
                      ></b-button>
                    </b-input-group-prepend>
                    <!-- </b-input-group> -->
                  </div>
                </div>

                <div
                  class="col-md-3 col-xl-3 col-sm-12 mobile_top_9 only_mobile_margin_bottom_18 mobile_max_width_and_flex"
                >
                  <b-form-group
                    id="input-group-3"
                    class="contact-fields desktop_margin_bottom_zero"
                  >
                    <span class="custom-dropdown w-100">
                      <b-form-select
                        v-model="userFilterClose"
                        @change="changeTabClosed"
                        class="common_height_38 desktop_margin_bottom_zero desktop_margin_bottom_zero date_style_size"
                      >
                        <option value="">Select Generated By</option>
                        <option :value="user.id" v-for="user in usersClose">
                          {{ user.last_name ? user.last_name : "" | capitalize }}
                          {{ user.first_name ? user.first_name : "" | capitalize }}
                        </option>
                      </b-form-select>
                    </span>
                  </b-form-group>
                </div>
                <div
                  class="col-md-3 col-xl-3 col-sm-12 mobile_top_9 only_mobile_margin_bottom_18 mobile_max_width_and_flex"
                >
                  <b-form-group
                    id="input-group-3"
                    class="contact-fields desktop_margin_bottom_zero"
                  >
                    <span class="custom-dropdown w-100">
                      <b-form-select
                        v-model="ticketFilterClose"
                        @change="changeTabClosed"
                        class="common_height_38 desktop_margin_bottom_zero desktop_margin_bottom_zero date_style_size"
                      >
                        <option value="">Select Ticket Type</option>
                        <option :value="user.id" v-for="user in ticket_type">
                          {{ user.title != null ? user.title : "" | capitalize }}
                        </option>
                      </b-form-select>
                    </span>
                  </b-form-group>
                </div>
                <div class="col-md-3 col-xl-3 col-sm-12 mobile_top_9 mobile_max_width_and_flex">
                  <b-form-group
                    id="input-group-3"
                    class="contact-fields desktop_margin_bottom_zero"
                  >
                    <span class="custom-dropdown w-100">
                      <b-form-select
                        v-model="priorityFilterClose"
                        @change="changeTabClosed"
                        class="common_height_38 desktop_margin_bottom_zero desktop_margin_bottom_zero date_style_size"
                      >
                        <option value="">Select Priority</option>
                        <option value="highest">Highest</option>
                        <option value="high">High</option>
                        <option value="medium">Medium</option>
                        <option value="low">Low</option>
                      </b-form-select>
                    </span>
                  </b-form-group>
                </div>
              </div>
            </div>

            <!-- Page Content
                        ================================================== -->
            <div class="top_b_tab">
              <h2>Tickets</h2>
            </div>
            <div
              class="ticket_second_box desktop_margin_top_zero"
              v-if="itemsClose && itemsClose.length >= 0"
            >
              <!-- <h3 class="ticket_h3">Your Tickets</h3> -->

              <div
                class="edit-profile-section border-radius-4 white-bg p-lg-4 p-3 common_box_shadow common_ticket_desc"
                v-if="itemsClose != null"
                v-for="(itemsClose, key) in itemsClose"
              >
                <div class="row">
                  <div
                    class="desktop_padding_left_right_zero col-md-8 col-sm-8 new_ticket_max_width_and_flex"
                  >
                    <h3 class="ticket_box_second_heading" v-if="itemsClose.title">
                      {{ itemsClose.title | capitalize }} -
                      {{ itemsClose.ticket_subject ? itemsClose.ticket_subject : "" | capitalize }}
                    </h3>
                  </div>
                  <div
                    class="desktop_padding_left_right_zero col-md-4 padding_top_20_mobile col-sm-4 new_ticket_max_width_and_flex opne_close_btn_ticket_list"
                  >
                    <h4 class="ticket_box_heading date_heading">
                      <span> {{ itemsClose.created_at | moment }}</span>
                    </h4>
                  </div>
                </div>
                <!-- <h3 class="ticket_box2_heading">{{itemsClose.ticket_subject ? itemsClose.ticket_subject : '' | capitalize}}</h3> -->

                <ul class="ticket_second_box_ul">
                  <!-- <li>TICKET ID - <span> {{itemsClose.ticket_unique_id}}</span></li> -->
                  <div v-if="itemsClose.ticket_messages_val.length > 0">
                    <span v-if="itemsClose.ticket_messages_val[0].ticket_sender_profile">
                      <li
                        v-if="
                          itemsClose.ticket_messages_val &&
                          itemsClose.ticket_messages_val[0].ticket_sender_profile.user_type_id == 5
                        "
                      >
                        <strong>Name - </strong
                        ><b-link
                          :to="
                            showFreelancer('view profile')
                              ? {
                                  name: 'jobseeker-dp-profile-view',
                                  params: {
                                    id: encrypt(
                                      itemsClose.ticket_messages_val[0].ticket_sender_profile.id
                                    ),
                                  },
                                }
                              : null
                          "
                          target="_blank"
                          style="color: #4c8bf5"
                          ><span>
                            {{
                              itemsClose.ticket_messages_val[0].ticket_sender_profile.last_name
                                ? itemsClose.ticket_messages_val[0].ticket_sender_profile.last_name
                                : "" | capitalize
                            }}
                            {{
                              itemsClose.ticket_messages_val[0].ticket_sender_profile.first_name
                                ? itemsClose.ticket_messages_val[0].ticket_sender_profile.first_name
                                : "" | capitalize
                            }}</span
                          ></b-link
                        >
                      </li>
                      <li
                        v-if="
                          itemsClose.ticket_messages_val &&
                          (itemsClose.ticket_messages_val[0].ticket_sender_profile.user_type_id ==
                            3 ||
                            itemsClose.ticket_messages_val[0].ticket_sender_profile.user_type_id ==
                              4)
                        "
                      >
                        <strong>Name - </strong
                        ><b-link
                          :to="
                            showEmployer('view profile')
                              ? {
                                  name: 'employer-profile-view',
                                  params: {
                                    id: encrypt(
                                      itemsClose.ticket_messages_val[0].ticket_sender_profile.id
                                    ),
                                    company_id: encrypt(
                                      itemsClose.ticket_messages_val[0].ticket_sender_profile
                                        .company_id
                                    ),
                                  },
                                }
                              : null
                          "
                          target="_blank"
                          style="color: #4c8bf5"
                          ><span>
                            {{
                              itemsClose.ticket_messages_val[0].ticket_sender_profile.last_name
                                ? itemsClose.ticket_messages_val[0].ticket_sender_profile.last_name
                                : "" | capitalize
                            }}
                            {{
                              itemsClose.ticket_messages_val[0].ticket_sender_profile.first_name
                                ? itemsClose.ticket_messages_val[0].ticket_sender_profile.first_name
                                : "" | capitalize
                            }}</span
                          ></b-link
                        >
                      </li>
                    </span>
                  </div>
                  <li v-if="itemsClose.job_id">
                    <strong>Job ID - </strong><span> {{ itemsClose.job_id | capitalize }}</span>
                  </li>
                  <li v-if="itemsClose.priority">
                    <strong>Priority - </strong
                    ><span>
                      {{ itemsClose.priority ? itemsClose.priority : "N/A" | capitalize
                      }}<span
                        class="material-icons-outlined"
                        v-if="itemsClose.priority == 'highest'"
                        style="vertical-align: middle; color: red"
                        >keyboard_double_arrow_up_outlined</span
                      >
                      <span
                        class="material-icons-outlined"
                        v-if="itemsClose.priority == 'high'"
                        style="vertical-align: middle; color: red"
                        >keyboard_arrow_up_outlined</span
                      >
                      <span
                        class="material-icons-outlined"
                        v-if="itemsClose.priority == 'medium'"
                        style="vertical-align: middle; color: orange"
                        >drag_handle_outlined</span
                      >
                      <span
                        class="material-icons-outlined"
                        v-if="itemsClose.priority == 'low'"
                        style="vertical-align: middle; color: rgb(54, 160, 216)"
                        >keyboard_arrow_down_outlined</span
                      ></span
                    >
                  </li>
                  <li>
                    <strong>Ticket Status - </strong
                    ><span> {{ itemsClose.status | capitalize }}</span>
                  </li>
                  <!-- <li>DATE - <span> {{itemsClose.created_at | moment}}</span></li> -->
                </ul>
                <!-- <h3 class="ticket_box_second_heading">{{itemsClose.ticket_subject | capitalize}}</h3> -->
                <!-- <div>
                                    <div class="images" v-viewer>
                                    <img v-for="src in images" :key="src" :src="src">
                                    </div>
                                    <b-link type="button" style="text-decoration: underline;" @click="showDoc(itemsClose.medical_document)" v-if="itemsClose.medical_document.length > 0">Click to show Attachment</b-link>
                                </div> -->
                <div class="row">
                  <div
                    class="desktop_padding_left_right_zero col-md-8 col-sm-8 new_ticket_max_width_and_flex"
                  >
                    <p v-if="itemsClose.ticket_messages_val[0].message.length <= 150">
                      <span class="red-text">Issue - </span
                      >{{ itemsClose.ticket_messages_val[0].message | capitalize }}
                    </p>
                    <p v-else>
                      <span class="red-text"><strong>Issue - </strong></span
                      >{{
                        (itemsClose.ticket_messages_val[0].message.substring(0, 150) + "...")
                          | capitalize
                      }}
                    </p>
                  </div>
                  <!-- <div class="ticket_detail_second_box_inner" v-if="itemsClose.ticket_messages_val"> -->
                  <!-- <div class="issue_div"> -->
                  <!-- <b-row>
                                        <b-col md="2" v-for="(docs,key) in itemsClose.medical_document" :key="key">
                                            <viewer :images="itemsClose">

                                            <b-img :src="docs.document" v-if="docs.image_type == 'image'" height="80" width="100" alt="certification img" class="ticket_document_img"></b-img>
                                            <b-img :src="webUrl+'assets/images/pdf.svg'" v-if="docs.image_type == 'pdf'" alt="certification img"></b-img>

                                            </viewer>
                                        </b-col>
                                    </b-row> -->
                  <!-- </div> -->
                  <div
                    class="desktop_padding_left_right_zero col-md-4 padding_top_20_mobile col-sm-4 new_ticket_max_width_and_flex"
                    v-if="itemsClose.ticket_messages_val"
                  >
                    <div class="ticket_btn_divs">
                      <b-link
                        :to="{
                          name: 'admin-support-ticket-view',
                          params: { id: encrypt(itemsClose.id) },
                        }"
                        class="button ripple-effect big"
                        >Ticket Details</b-link
                      >
                      <!-- <a href="#" class="button ripple-effect big">Ticket Detail</a> -->
                    </div>
                  </div>
                </div>
                <!-- </div> -->
              </div>
            </div>
            <nav class="pagination-box custom_pagination_border_radius">
              <div class="page-row-box d-inline-block">
                <b-form-group id="input-group-4">
                  <span class="d-inline-block">Rows per page</span>
                  <span class="d-inline-block">
                    <b-form-select v-model="form.rowsPerPageClose" @change="changeTabClosed">
                      <option>25</option>
                      <option>50</option>
                      <option>75</option>
                      <option>100</option>
                    </b-form-select></span
                  >
                </b-form-group>
              </div>
              <div class="total-page-count d-inline-block">
                <ul>
                  <li>
                    <span v-if="totalRowsClose != 0"
                      >{{ fromClose }} - {{ toClose }} of {{ totalRowsClose }}</span
                    >
                  </li>
                </ul>
              </div>
              <b-pagination
                @input="changeTabClosed"
                :total-rows="totalRowsClose"
                :per-page="perPageClose"
                v-model="currentPageClose"
                prev-text=""
                next-text=""
                hide-goto-end-buttons
              />
            </nav>
          </b-tab>
          <b-tab :title="'Self (' + selfCount + ')'" @click="changeTabSelf">
            <template #title>
              <span
                >Self<span class="ticket_count_span">{{ selfCount }}</span></span
              >
            </template>
            <div class="container new_cnt_pdng my_new_container input_feild_big_screenz">
              <div class="row">
                <div
                  class="col-md-3 col-xl-3 col-md-3 col-sm-12 desktop_margin_bottom_zero only_mobile_margin_bottom_18 mobile_max_width_and_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div class="search-box custom_search_admin desktop_margin_top_zero">
                    <!-- <b-input-group> -->
                    <!-- <br/> -->
                    <b-input-group-prepend>
                      <b-form-input
                        id="searchBtn"
                        @keyup.enter.native="filterSelf = searchKeyworSelf"
                        v-model="searchKeywordSelf"
                        v-on:change="changeTabSelf"
                        placeholder="Search by keyword"
                      ></b-form-input>
                      <b-button class="btn points_events"
                        ><i class="fa fa-search" @click="filterSelf = searchKeywordSelf"></i
                      ></b-button>
                    </b-input-group-prepend>
                    <!-- </b-input-group> -->
                  </div>
                </div>
                <div
                  class="col-md-3 col-xl-3 col-sm-12 mobile_top_9 only_mobile_margin_bottom_18 mobile_max_width_and_flex"
                >
                  <b-form-group
                    id="input-group-3"
                    class="contact-fields desktop_margin_bottom_zero"
                  >
                    <span class="custom-dropdown w-100">
                      <b-form-select
                        v-model="userFilterSelf"
                        @change="changeTabSelf"
                        class="common_height_38 desktop_margin_bottom_zero desktop_margin_bottom_zero date_style_size"
                      >
                        <option value="">Select Generated By</option>
                        <option :value="user.id" v-for="user in users">
                          {{ user.last_name ? user.last_name : "" | capitalize }}
                          {{ user.first_name ? user.first_name : "" | capitalize }}
                        </option>
                      </b-form-select>
                    </span>
                  </b-form-group>
                </div>
                <div
                  class="col-md-3 col-xl-3 col-sm-12 mobile_top_9 only_mobile_margin_bottom_18 mobile_max_width_and_flex"
                >
                  <b-form-group
                    id="input-group-3"
                    class="contact-fields desktop_margin_bottom_zero"
                  >
                    <span class="custom-dropdown w-100">
                      <b-form-select
                        v-model="ticketFilterSelf"
                        @change="changeTabSelf"
                        class="common_height_38 desktop_margin_bottom_zero desktop_margin_bottom_zero date_style_size"
                      >
                        <option value="">Select Ticket Type</option>
                        <option :value="user.id" v-for="user in ticket_type">
                          {{ user.title != null ? user.title : "" | capitalize }}
                        </option>
                      </b-form-select>
                    </span>
                  </b-form-group>
                </div>
                <div class="col-md-3 col-xl-3 col-sm-12 mobile_top_9 mobile_max_width_and_flex">
                  <b-form-group
                    id="input-group-3"
                    class="contact-fields desktop_margin_bottom_zero"
                  >
                    <span class="custom-dropdown w-100">
                      <b-form-select
                        v-model="priorityFilterSelf"
                        @change="changeTabSelf"
                        class="common_height_38 desktop_margin_bottom_zero desktop_margin_bottom_zero date_style_size"
                      >
                        <option value="">Select Priority</option>
                        <option value="highest">Highest</option>
                        <option value="high">High</option>
                        <option value="medium">Medium</option>
                        <option value="low">Low</option>
                      </b-form-select>
                    </span>
                  </b-form-group>
                </div>
              </div>
            </div>

            <!-- Page Content
                        ================================================== -->
            <div class="top_b_tab">
              <h2>Tickets</h2>
            </div>
            <div
              class="ticket_second_box desktop_margin_top_zero"
              v-if="itemsSelf && itemsSelf.length >= 0"
            >
              <!-- <h3 class="ticket_h3">Your Tickets</h3> -->

              <div
                class="edit-profile-section border-radius-4 white-bg p-lg-4 p-3 common_box_shadow common_ticket_desc"
                v-if="itemsSelf != null"
                v-for="(itemsSelf, key) in itemsSelf"
              >
                <div class="row">
                  <div
                    class="desktop_padding_left_right_zero col-md-8 col-sm-8 new_ticket_max_width_and_flex"
                  >
                    <h3 class="ticket_box_heading" v-if="itemsSelf.title">
                      {{ itemsSelf.title | capitalize }} -
                      {{ itemsSelf.ticket_subject ? itemsSelf.ticket_subject : "" | capitalize }}
                    </h3>
                  </div>
                  <div
                    class="desktop_padding_left_right_zero col-md-4 padding_top_20_mobile col-sm-4 new_ticket_max_width_and_flex opne_close_btn_ticket_list"
                  >
                    <h4 class="ticket_box_heading date_heading">
                      <span>{{ itemsSelf.created_at | moment }}</span>
                    </h4>
                    <h4 class="ticket_box_heading date_heading">
                      <span>Ticket ID: {{ itemsSelf.ticket_unique_id }}</span>
                    </h4>
                  </div>
                </div>
                <!-- <h3 class="ticket_box2_heading">{{itemsOpen.ticket_subject ? itemsOpen.ticket_subject : '' | capitalize}}</h3> -->

                <ul class="ticket_second_box_ul">
                  <!-- <li>TICKET ID - <span> {{itemsOpen.ticket_unique_id}}</span></li> -->
                  <div v-if="itemsSelf.ticket_messages_val.length > 0">
                    <span v-if="itemsSelf.ticket_messages_val[0].ticket_sender_profile">
                      <li
                        v-if="
                          itemsSelf.ticket_messages_val &&
                          itemsSelf.ticket_messages_val[0].ticket_sender_profile.user_type_id == 5
                        "
                      >
                        <strong>Name - </strong
                        ><b-link
                          :to="
                            showFreelancer('view profile')
                              ? {
                                  name: 'jobseeker-dp-profile-view',
                                  params: {
                                    id: encrypt(
                                      itemsSelf.ticket_messages_val[0].ticket_sender_profile.id
                                    ),
                                  },
                                }
                              : null
                          "
                          target="_blank"
                          style="color: #4c8bf5"
                          ><span>
                            {{
                              itemsSelf.ticket_messages_val[0].ticket_sender_profile.last_name
                                ? itemsSelf.ticket_messages_val[0].ticket_sender_profile.last_name
                                : "" | capitalize
                            }}
                            {{
                              itemsSelf.ticket_messages_val[0].ticket_sender_profile.first_name
                                ? itemsSelf.ticket_messages_val[0].ticket_sender_profile.first_name
                                : "" | capitalize
                            }}</span
                          ></b-link
                        >
                      </li>
                      <li
                        v-if="
                          itemsSelf.ticket_messages_val &&
                          (itemsSelf.ticket_messages_val[0].ticket_sender_profile.user_type_id ==
                            3 ||
                            itemsSelf.ticket_messages_val[0].ticket_sender_profile.user_type_id ==
                              4)
                        "
                      >
                        <strong>Name - </strong
                        ><b-link
                          :to="
                            showEmployer('view profile')
                              ? {
                                  name: 'employer-profile-view',
                                  params: {
                                    id: encrypt(
                                      itemsSelf.ticket_messages_val[0].ticket_sender_profile.id
                                    ),
                                    company_id: encrypt(
                                      itemsSelf.ticket_messages_val[0].ticket_sender_profile
                                        .company_id
                                    ),
                                  },
                                }
                              : null
                          "
                          target="_blank"
                          style="color: #4c8bf5"
                          ><span>
                            {{
                              itemsSelf.ticket_messages_val[0].ticket_sender_profile.last_name
                                ? itemsSelf.ticket_messages_val[0].ticket_sender_profile.last_name
                                : "" | capitalize
                            }}
                            {{
                              itemsSelf.ticket_messages_val[0].ticket_sender_profile.first_name
                                ? itemsSelf.ticket_messages_val[0].ticket_sender_profile.first_name
                                : "" | capitalize
                            }}</span
                          ></b-link
                        >
                      </li>
                    </span>
                  </div>
                  <li v-if="itemsSelf.job_id">
                    <strong> Job ID - </strong><span> {{ itemsSelf.job_id | capitalize }}</span>
                  </li>
                  <li v-if="itemsSelf.priority">
                    <strong>Priority - </strong
                    ><span>
                      {{ itemsSelf.priority ? itemsSelf.priority : "N/A" | capitalize
                      }}<span
                        class="material-icons-outlined"
                        v-if="itemsSelf.priority == 'highest'"
                        style="vertical-align: middle; color: red"
                        >keyboard_double_arrow_up_outlined</span
                      >
                      <span
                        class="material-icons-outlined"
                        v-if="itemsSelf.priority == 'high'"
                        style="vertical-align: middle; color: red"
                        >keyboard_arrow_up_outlined</span
                      >
                      <span
                        class="material-icons-outlined"
                        v-if="itemsSelf.priority == 'medium'"
                        style="vertical-align: middle; color: orange"
                        >drag_handle_outlined</span
                      >
                      <span
                        class="material-icons-outlined"
                        v-if="itemsSelf.priority == 'low'"
                        style="vertical-align: middle; color: rgb(54, 160, 216)"
                        >keyboard_arrow_down_outlined</span
                      ></span
                    >
                  </li>
                  <li>
                    <strong>Ticket Status - </strong>
                    <span> {{ itemsSelf.status | capitalize }}</span>
                  </li>
                  <!-- <li>DATE - <span> {{itemsOpen.created_at | moment}}</span></li> -->
                </ul>
                <!-- <h3 class="ticket_box_second_heading">{{itemsOpen.ticket_subject | capitalize}}</h3> -->
                <!-- <div>
                                    <div class="images" v-viewer>
                                    <img v-for="src in images" :key="src" :src="src">
                                    </div>
                                    <b-link type="button" style="text-decoration: underline;" @click="showDoc(itemsOpen.medical_document)" v-if="itemsOpen.medical_document.length > 0">Click to show Attachment</b-link>
                                </div> -->
                <div class="row">
                  <div
                    class="desktop_padding_left_right_zero col-md-8 col-sm-8 new_ticket_max_width_and_flex"
                  >
                    <p v-if="itemsSelf.ticket_messages_val[0].message.length <= 100">
                      <span class="red-text"><strong>Issue - </strong></span
                      >{{ itemsSelf.ticket_messages_val[0].message | capitalize }}
                    </p>
                    <!-- <p v-else><span class="red-text">Issue - </span>{{ itemsSelf.ticket_messages_val[0].message.substring(0,150)+"..." | capitalize}}</p> -->
                    <p v-else>
                      <span class="red-text">Issue -</span>
                      <span
                        v-html="itemsSelf.ticket_messages_val[0].message.substring(0, 150)"
                      ></span>

                      <!-- {{ items.ticket_messages_val[0]?items.ticket_messages_val[0].message.substring(0,150)+"..." : '' | capitalize}}</p> -->
                    </p>
                  </div>
                  <!-- <div class="" v-if="itemsOpen.ticket_messages_val"> -->
                  <!-- <div class="issue_div"> -->
                  <!-- <b-row>
                                            <b-col md="2" v-for="(docs,key) in itemsOpen.medical_document" :key="key">
                                                <viewer :images="itemsOpen">

                                                <b-img :src="docs.document" v-if="docs.image_type == 'image'" height="80" width="100" alt="certification img" class="ticket_document_img"></b-img>
                                                <b-img :src="webUrl+'assets/images/pdf.svg'" v-if="docs.image_type == 'pdf'" alt="certification img"></b-img>

                                                </viewer>
                                            </b-col>
                                        </b-row> -->
                  <!-- </div> -->
                  <div
                    class="desktop_padding_left_right_zero col-md-4 padding_top_20_mobile col-sm-4 new_ticket_max_width_and_flex"
                    v-if="itemsSelf.ticket_messages_val"
                  >
                    <div class="ticket_btn_divs">
                      <b-link
                        :to="{
                          name: 'admin-support-ticket-view',
                          params: { id: encrypt(itemsSelf.id) },
                        }"
                        class="button ripple-effect big"
                        >Ticket Details</b-link
                      >
                      <!-- <a href="#" class="button ripple-effect big">Ticket Detail</a> -->
                    </div>
                  </div>
                  <!-- </div> -->
                </div>
              </div>
            </div>
            <nav class="pagination-box custom_pagination_border_radius">
              <div class="page-row-box d-inline-block">
                <b-form-group id="input-group-4">
                  <span class="d-inline-block">Rows per page</span>
                  <span class="d-inline-block">
                    <b-form-select v-model="form.rowsPerPageSelf" @change="changeTabSelf">
                      <option>25</option>
                      <option>50</option>
                      <option>75</option>
                      <option>100</option>
                    </b-form-select></span
                  >
                </b-form-group>
              </div>
              <div class="total-page-count d-inline-block">
                <ul>
                  <li>
                    <span v-if="totalRowsSelf != 0"
                      >{{ fromSelf }} - {{ toSelf }} of {{ totalRowsSelf }}</span
                    >
                  </li>
                </ul>
              </div>
              <b-pagination
                @input="changeTabSelf"
                :total-rows="totalRowsSelf"
                :per-page="perPageSelf"
                v-model="currentPageSelf"
                prev-text=""
                next-text=""
                hide-goto-end-buttons
              />
            </nav>
          </b-tab>
          <b-tab :title="'Chatbot Log (' + chatbotCount + ')'" @click="changeTabChatbot">
            <template #title>
              <span
                >Chatbot<span class="ticket_count_span">{{ chatbotCount }}</span></span
              >
            </template>
            <div class="container  my_new_container input_feild_big_screenz">
              <div class="row">
                <div
                  class="col-md-4 col-xl-4 col-sm-12 desktop_margin_bottom_zero only_mobile_margin_bottom_18 mobile_max_width_and_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div class="search-box custom_search_admin desktop_margin_top_zero">
                    <!-- <b-input-group> -->
                    <!-- <br/> -->
                    <b-input-group-prepend class="common_bg_white">
                      <b-form-input
                        id="searchBtn"
                        @keyup.enter.native="filterChatbot = searchKeywordChatbot"
                        v-model="searchKeywordChatbot"
                        placeholder="Search"
                        class="common_bg_white"
                      ></b-form-input>
                      <b-button class="btn points_events"
                        ><i
                          class="fa fa-search common_bg_white"
                          @click="filterChatbot = searchKeywordChatbot"
                        ></i
                      ></b-button>
                    </b-input-group-prepend>
                    <!-- </b-input-group> -->
                  </div>
                </div>
                <div
                  class="col-xl-5 col-md-6 chatbot_label_with_timesheet desktop_margin_bottom_zero only_mobile_margin_bottom_18 mobile_max_width_and_flex  search_common_margin_bottom_28 admin_transaction_calender_date_range"
                >
                  <label class="timesheet_label new_style_label required_sign required desktop_margin_bottom_zero"
                    >Date Range</label
                  >
                  <b-form-group id="input-group-8" class="desktop_margin_bottom_zero">
                    <date-range-picker
                      class="chatbot_tab_calender manage_job_date_claneder common_date_range_picker_new transactions_jb_calender common_width_100"
                      ref="picker"
                      :minDate="minDate"
                      :opens="center"
                      :locale-data="locale"
                      :auto-apply="auto"
                      v-model="dateRange"
                      :ranges="range"
                      @update="changeTabChatbot()"
                    >
                      <div slot="input" slot-scope="picker">
                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                      </div>
                    </date-range-picker>
                  </b-form-group>
                </div>
                <div
                  class="col-xl-2 col-md-2"
                >
                  <b-button
                    @click="clearFilter()"
                    class="mt-1 ml-0 mr-0 desktop_padding_bottom_zero pb-0 new_reset_text  mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero  new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0"
                    style="background-color: red; margin-top: 20px;"
                    ><span class="mobile_reset">Reset filters</span></b-button
                  >
                </div>
              </div>
            </div>

            <!-- Page Content
                        ================================================== -->
            <div class="row">
              <div class="container my_new_container">
                <b-table
                  ref="datatableChatbot"
                  show-empty
                  striped
                  hover
                  responsive
                  :items="getChatbotList"
                  :fields="fieldsChatbot"
                  :current-page="currentPageChatbot"
                  :per-page="perPageChatbot"
                  :filter="filterChatbot"
                  :sort-by.sync="sortByChatbot"
                  :sort-desc.sync="sortDescChatbot"
                  :sort-direction="sortDirectionChatbot"
                  class="nric_three_grid_table_new mobile_min_width_1000"
                >
                  <template #cell(view_count)="items">
                    {{ items.item.view_count }}
                  </template>
                  <template #cell(date)="items">
                    {{ items.item.date | customFormatterChatbot }}
                  </template>
                </b-table>
                <div v-if="chatbot_total_clickcount > 0" class="uploaded_count">
                  <h1>
                    <b>Total Click Count: {{ chatbot_total_clickcount }}</b>
                  </h1>
                </div>
                <nav class="pagination-box">
                  <div class="page-row-box d-inline-block">
                    <b-form-group id="input-group-4">
                      <span class="d-inline-block">Rows per page</span>
                      <span class="d-inline-block"
                        ><b-form-select v-model="form.rowsPerPageChatbot" @change="getChatbotList">
                          <option>25</option>
                          <option>50</option>
                          <option>75</option>
                          <option>100</option>
                        </b-form-select></span
                      >
                    </b-form-group>
                  </div>
                  <div class="total-page-count d-inline-block">
                    <ul>
                      <li>
                        <span v-if="totalRowsChatbot != 0"
                          >{{ fromChatbot }} - {{ toChatbot }} of {{ totalRowsChatbot }}</span
                        >
                      </li>
                    </ul>
                  </div>
                  <b-pagination
                    :total-rows="totalRowsChatbot"
                    :per-page="perPageChatbot"
                    v-model="currentPageChatbot"
                    prev-text=""
                    next-text=""
                    hide-goto-end-buttons
                  />
                </nav>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>

    <!-- Spacer -->
    <!-- <div class="margin-top-70"></div> -->
    <!-- Spacer / End-->
    <!-- Row / End -->
  </div>
</template>

<script>
import { POST_API } from "../../../store/actions.type";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Bus from "../../../event-bus";
import permission from "../../../../../server/permission.js";

export default {
  data() {
    return {
      fieldsChatbot: [
        { key: "date", label: "Date", sortable: true },
        { key: "view_count", label: "Click Count", sortable: true },
      ],
      pager: {},
      pageOfItems: [],
      minDate: null,
      date: moment().format("YYYY-MM-DD"),
      format: "YYYY-MM-DD HH:mm",
      itemsOpen: null,
      totalRowsOpen: 0,
      fromOpen: "",
      toOpen: "",
      currentPageOpen: 1,
      searchKeywordOpen: "",
      perPageOpen: 25,
      pageOptionsOpen: [25, 50, 75, 100],
      itemsPending: null,
      totalRowsPending: 0,
      fromPending: "",
      toPending: "",
      currentPagePending: 1,
      searchKeywordPending: "",
      perPagePending: 25,
      pageOptionsPending: [25, 50, 75, 100],
      searchKeywordClose: "",
      itemsClose: null,
      totalRowsClose: 0,
      fromClose: "",
      toClose: "",
      currentPageClose: 1,
      perPageClose: 25,
      pageOptionsClose: [25, 50, 75, 100],
      auto: true,
      range: false,

      Open: null,

      filterOpen: "",
      filterPending: "",
      filterClose: "",
      status: "active",
      sortByOpen: null,
      sortDirectionOpen: "desc",
      sortDescOpen: true,
      sortByPending: null,
      sortDirectionPending: "desc",
      sortDescPending: true,
      sortByClose: null,
      sortDirectionClose: "desc",
      sortDescClose: true,
      webUrl: process.env.VUE_APP_URL,
      form: {
        rowsPerPageOpen: 25,
        rowsPerPageSelf: 25,
        rowsPerPagePending: 25,
        rowsPerPageClose: 25,
        rowsPerPageChatbot: 25,
        mode: "",
        type: "",
        status: "",
        tran_type: "",
      },
      userFilter: "",
      priorityFilter: "",
      ticketFilter: "",
      userFilterPending: "",
      priorityFilterPending: "",
      ticketFilterPending: "",
      userFilterClose: "",
      priorityFilterClose: "",
      ticketFilterClose: "",
      userFilterSelf: "",
      priorityFilterSelf: "",
      ticketFilterSelf: "",
      users: [],
      usersPending: [],
      usersClose: [],
      ticket_type: [],
      ticket_filter:null,
      auto_reply_enabled: false,
      pendingCount: 0,
      selfCount: 0,
      itemsSelf: null,
      totalRowsSelf: 0,
      fromSelf: "",
      toSelf: "",
      currentPageSelf: 1,
      searchKeywordSelf: "",
      perPageSelf: 25,
      pageOptionsSelf: [25, 50, 75, 100],
      filterSelf: "",
      sortBySelf: null,
      sortDirectionSelf: "desc",
      sortDescSelf: true,
      actions: "",
      actionsFreelancer: "",
      actionsEmployer: "",
      auto: true,
      range: false,
      minDate: null,
      center: "center",
      locale: {
        direction: "ltr", //direction of text
        format: "DD-MMM-YYYY", //fomart of the dates displayed
        separator: " - ", //separator between the two ranges
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        /*weekLabel: 'W',
                customRangeLabel: 'Custom Range',*/
        daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 0, //ISO first day of week - see moment documenations for details
        sameDateFormat: {
          from: "DD MM YYYY, hh:mm",
          to: "hh:mm",
        },
      },
      dateRange: {
        startDate: "",
        endDate: "",
      },
      dateRange: {
        startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      itemsChatbot: null,
      totalRowsChatbot: 0,
      fromChatbot: "",
      toChatbot: "",
      currentPageChatbot: 1,
      searchKeywordChatbot: "",
      perPageChatbot: 25,
      pageOptionsChatbot: [25, 50, 75, 100],
      filterChatbot: "",
      sortByChatbot: null,
      sortDirectionChatbot: "desc",
      sortDescChatbot: true,
      chatbotCount: 0,
      searchKeyworChatbot: "",
      chatbot_total_clickcount: 0,
    };
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },

    moment: function (date) {
      return moment(date).format("DD MMM YYYY hh:mm A");
    },

    removeUnderscore: function (value) {
      if (!value) return "";
      var i,
        frags = value.split("_");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },

    customFormatterChatbot(date) {
      return moment(date).format("DD MMM YYYY");
    },
    date: function (date) {
      if (date) {
        return moment(date).format("DD MMM YYYY");
      } else {
        return "-";
      }
    },
  },

  components: {
    DateRangePicker,
  },
  methods: {
    showSuccessAlert(message) {
      this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        // this.$router.push({ name: 'country-list' });
      });
    },
    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    downloadPdf(url) {
      window.open(url);
    },

    changeTab() {
      this.getOpenTicketsList();
    },
    changeTabPending() {
      this.getPendingTicketsList();
    },
    changeTabClosed() {
      this.getClosedTicketsList();
    },
    changeTabSelf() {
      this.getSelfTicketsList();
    },
    changeTabChatbot() {
      // this.getChatbotList().then(() => {
      this.$refs.datatableChatbot.refresh();
      // });
    },
    clearFilter() {
      this.filterChatbot = "";
      this.searchKeywordChatbot = "";
      (this.dateRange.startDate = moment().subtract(7, "days").format("YYYY-MM-DD")),
        (this.dateRange.endDate = moment().format("YYYY-MM-DD"));
      this.$refs.datatableChatbot.refresh();
    },
    change_auto_reply($event) {
      if ($event != null) {
        this.auto_reply_enabled = $event;
      } else {
        this.auto_reply_enabled = $event;
      }

      return this.$store
        .dispatch(POST_API, {
          data: {
            ticket_auto_reply: this.auto_reply_enabled,
          },
          api: "/api/ticketType-update-auto-reply",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            this.success_message = this.$store.getters.getResults.message;
            this.showSuccessAlert(this.success_message);
          }
        });
    },
    getOpenTicketsList() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            status: "open",
            generated_by: this.userFilter,
            priority: this.priorityFilter,
            ticket_type: this.ticketFilter,
            page: this.currentPageOpen,
            keyword: this.searchKeywordOpen,
            rowsPerPage: this.form.rowsPerPageOpen,
            sortBy: this.sortByOpen,
            sortDirection: this.sortDirectionOpen,
            sortDesc: this.sortDescOpen,
            self: "no",
          },
          api: "/api/all-support-ticket-list-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.itemsOpen = this.$store.getters.getResults.data.data;
            // this.contact = this.$store.getters.getResults.contact;
            this.ticket_type = this.$store.getters.getResults.ticket_type;

            if (this.ticket_type && Array.isArray(this.ticket_type)) {
              this.ticket_type.sort((a, b) => {
                const titleA = a.title ? a.title.toLowerCase() : '';
                const titleB = b.title ? b.title.toLowerCase() : '';
                return titleA.localeCompare(titleB);
              });
            }
            this.totalRowsOpen = this.$store.getters.getResults.data.total;
            this.perPageOpen = this.$store.getters.getResults.data.per_page;
            this.fromOpen = this.$store.getters.getResults.data.from;
            this.toOpen = this.$store.getters.getResults.data.to;
            this.pendingCount = this.$store.getters.getResults.pending_count;
            this.selfCount = this.$store.getters.getResults.self;

            this.getGeneratedByUser("open");
            Bus.$emit("get_admin_support_count");
            return this.itemsOpen;
          }
        });
    },
    getPendingTicketsList() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            status: "pending",
            generated_by: this.userFilterPending,
            priority: this.priorityFilterPending,
            ticket_type: this.ticketFilterPending,
            page: this.currentPagePending,
            keyword: this.searchKeywordPending,
            rowsPerPage: this.form.rowsPerPagePending,
            sortBy: this.sortByPending,
            sortDirection: this.sortDirectionPending,
            sortDesc: this.sortDescPending,
            self: "no",
          },
          api: "/api/all-support-ticket-list-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.itemsPending = this.$store.getters.getResults.data.data;
            // this.contact = this.$store.getters.getResults.contact;
            this.ticket_type = this.$store.getters.getResults.ticket_type;

            if (this.ticket_type && Array.isArray(this.ticket_type)) {
              this.ticket_type.sort((a, b) => {
                const titleA = a.title ? a.title.toLowerCase() : '';
                const titleB = b.title ? b.title.toLowerCase() : '';
                return titleA.localeCompare(titleB);
              });
            }

            this.totalRowsPending = this.$store.getters.getResults.data.total;
            this.perPagePending = this.$store.getters.getResults.data.per_page;
            this.fromPending = this.$store.getters.getResults.data.from;
            this.toPending = this.$store.getters.getResults.data.to;
            this.pendingCount = this.$store.getters.getResults.pending_count;
            this.selfCount = this.$store.getters.getResults.self;

            this.getGeneratedByUser("pending");
            Bus.$emit("get_admin_support_count");
            return this.itemsPending;
          }
        });
    },
    getClosedTicketsList() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            status: "close",
            generated_by: this.userFilterClose,
            priority: this.priorityFilterClose,
            ticket_type: this.ticketFilterClose,
            page: this.currentPageClose,
            keyword: this.searchKeywordClose,
            rowsPerPage: this.form.rowsPerPageClose,
            sortBy: this.sortByClose,
            sortDirection: this.sortDirectionClose,
            sortDesc: this.sortDescClose,
            self: "no",
          },
          api: "/api/all-support-ticket-list-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.itemsClose = this.$store.getters.getResults.data.data;
            // this.contact = this.$store.getters.getResults.contact;
            this.ticket_type = this.$store.getters.getResults.ticket_type;

            if (this.ticket_type && Array.isArray(this.ticket_type)) {
              this.ticket_type.sort((a, b) => {
                const titleA = a.title ? a.title.toLowerCase() : '';
                const titleB = b.title ? b.title.toLowerCase() : '';
                return titleA.localeCompare(titleB);
              });
            }

            this.totalRowsClose = this.$store.getters.getResults.data.total;
            this.perPageClose = this.$store.getters.getResults.data.per_page;
            this.fromClose = this.$store.getters.getResults.data.from;
            this.toClose = this.$store.getters.getResults.data.to;
            this.pendingCount = this.$store.getters.getResults.pending_count;
            this.selfCount = this.$store.getters.getResults.self;

            this.getGeneratedByUser("close");
            Bus.$emit("get_admin_support_count");
            return this.itemsClose;
          }
        });
    },
    getChatbotList() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPageChatbot,
            keyword: this.filterChatbot,
            rowsPerPage: this.form.rowsPerPageChatbot,
            start_date: this.dateRange.startDate
              ? moment(this.dateRange.startDate).format("YYYY-MM-DD")
              : "",
            end_date: this.dateRange.endDate
              ? moment(this.dateRange.endDate).format("YYYY-MM-DD")
              : "",
            sortBy: this.sortByChatbot ? this.sortByChatbot : "id",
            sortDirection: this.sortDescChatbot ? "desc" : "asc",
          },
          api: "/api/all-chatbot-log-list-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.chatbotCount = this.$store.getters.getResults.count;
            this.itemsChatbot = this.$store.getters.getResults.pageOfItems;
            this.totalRowsChatbot = this.$store.getters.getResults.pager.totalItems;
            this.perPageChatbot = this.$store.getters.getResults.pager.pageSize;
            this.fromChatbot = this.$store.getters.getResults.pager.from;
            this.toChatbot = this.$store.getters.getResults.pager.to;
            this.chatbot_total_clickcount =
              this.itemsChatbot.length > 0 ? this.itemsChatbot[0].total_view_count : 0;
            Bus.$emit("get_admin_support_count");
            return this.itemsChatbot;
          }
        });
    },
    getSelfTicketsList() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            status: "all",
            generated_by: this.userFilterSelf,
            priority: this.priorityFilterSelf,
            ticket_type: this.ticketFilterSelf,
            page: this.currentPageSelf,
            keyword: this.searchKeywordSelf,
            rowsPerPage: this.form.rowsPerPageSelf,
            sortBy: this.sortBySelf,
            sortDirection: this.sortDirectionSelf,
            sortDesc: this.sortDescSelf,
            self: "yes",
          },
          api: "/api/all-support-ticket-list-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.itemsSelf = this.$store.getters.getResults.data.data;
            // this.contact = this.$store.getters.getResults.contact;
            this.ticket_type = this.$store.getters.getResults.ticket_type;

            if (this.ticket_type && Array.isArray(this.ticket_type)) {
              this.ticket_type.sort((a, b) => {
                const titleA = a.title ? a.title.toLowerCase() : '';
                const titleB = b.title ? b.title.toLowerCase() : '';
                return titleA.localeCompare(titleB);
              });
            }

            this.totalRowsSelf = this.$store.getters.getResults.data.total;
            this.perPageSelf = this.$store.getters.getResults.data.per_page;
            this.fromSelf = this.$store.getters.getResults.data.from;
            this.toSelf = this.$store.getters.getResults.data.to;
            this.pendingCount = this.$store.getters.getResults.pending_count;
            this.selfCount = this.$store.getters.getResults.self;

            this.getGeneratedByUser("self");
            Bus.$emit("get_admin_support_count");
            return this.itemsSelf;
          }
        });
    },
    getGeneratedByUser(status) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            status: status,
          },
          api: "/api/all-generated-by-users-list-admin",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.auto_reply_enabled =
              this.$store.getters.getResults.auto_reply_enabled == "yes" ||
              this.$store.getters.getResults.auto_reply_enabled == "Yes"
                ? true
                : false;
            if (status == "open") {
              this.users = this.$store.getters.getResults.data;
              return this.users;
            } else if (status == "pending") {
              this.usersPending = this.$store.getters.getResults.data;
              return this.usersPending;
            } else {
              this.usersClose = this.$store.getters.getResults.data;
              return this.usersClose;
            }
          }
        });
    },
    permissionStaff() {
      if (this.$store.getters.getCurrentUserPermission) {
        if (
          this.$store.getters.getCurrentUserPermission.data.length > 0 &&
          this.$store.getters.currentUser.user_type_id == 2
        ) {
          var menu = this.$store.getters.getCurrentUserPermission.data;
          this.actions = permission.getPermissionStaff(menu, "Tickets");
          this.actionsFreelancer = permission.getPermissionStaff(menu, "Jobseekers");
          this.actionsEmployer = permission.getPermissionStaff(menu, "Companies");
        }
      }
    },
    show(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actions.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showFreelancer(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actionsFreelancer.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showEmployer(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actionsEmployer.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    $(".show_filter_arrow .table thead th div").contents().unwrap();
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    //document.title = "EL Connect - Jobseeker - Transaction";
    this.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.getOpenTicketsList();
    this.permissionStaff();
  },
};
</script>
