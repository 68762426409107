<template>

    <div class="">

        <!-- Dashboard Headline -->
        <!-- Page Content
        ================================================== -->
            <!-- <div class="back_arrow_icon">
                <span class="icon-material-outline-arrow-back" @click="$router.go(-1)"></span>
        </div> -->
        <!--<div class="single-page-header" :data-background-image="jobData.banner_image ? jobData.banner_image: webUrl+'assets/images/single-job.jpg'">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="single-page-header-inner">
					<div class="left-side">
						<div class="header-image"><b-link :to="{name: 'jobseeker-employer-profile-view', params: {id: posted_by}}"><img :src="profile_image != null ? profile_image : webUrl+'assets/images/company-logo-placeholders.png'" alt=""></b-link></div>
						<div class="header-details">
							<h3><b-link :to="{name: 'jobseeker-employer-profile-view', params: {id: posted_by}}">{{title | capitalize}}</b-link></h3>
							<h5>About the Employer</h5>
							<ul>
								<li><a href="#"><i class="icon-material-outline-business"></i> {{company_name | capitalize}}</a></li>
								<li v-if="company_description">
                                    <p v-if="company_description.length >= 200" v-html="company_description.substring(0,200)+'...' "></p>
                                    <p v-else v-html="company_description"></p>

                                </li>
								<li v-if="average_rating"><div class="star-rating" :data-rating="average_rating"></div></li>
								<li><img class="flag" src="images/flags/gb.svg" alt="">{{user_address | capitalize}}</li>
								<li v-if='email_verified == "yes"'><div class="verified-badge-with-title">Verified</div></li>
							</ul>
						</div>
					</div>
					<div class="right-side">
						<div class="salary-box">
							<div class="salary-type" v-if="job_type == 'part-time'">Per Hour Rate</div>
							<div class="salary-type" v-else>Salary</div>
						<div class="salary-amount">${{min_salary}}-${{max_salary}}</div>
						</div>
					</div>
				</div>
			</div>
            </div>
            </div>
            </div> -->

            <!--hello -->
            <div class="mobile_view_jobs_banner bg_img_top new_bg_img_tp new_job_detail_full_dvz" v-bind:style="{backgroundImage: 'url('+( jobData.banner_image ? jobData.banner_image: webUrl+'assets/images/single-job.jpg' )+ ')'} " >

            </div>
            <div class="mobile_view_jobs bg_img_top new_detail_bg_img new_job_detail_full_dvz new_bg_img_tp" v-bind:style="{backgroundImage: 'url('+( jobData.banner_image ? jobData.banner_image: webUrl+'assets/images/single-job.jpg' )+ ')'} " >
                <div class="grd_div inside_grd height_three_zero_one desktop_height_hundered">
                <div class="container mobile_tab_container_hundered">
                    <div class="back_arrow_icon new_bak_arr">
                        <span class="icon-material-outline-arrow-back" @click="prevRoute.path == '/' ? homeUrl() : $router.go(-1)"></span>
                    </div>
                    <div class="row">
                    <div class="col-md-12">
                        <div class="inrs_main">
                            <div class="left-side updated_left_side_banner">
                                <div class="header-image updated_image_in_square_shape updated_header_image_banner"><b-link :to="{name: 'web-emp-profile-view', params: {id: jobData.assigned_to}}"><img :src="jobData.company_logo != null ? jobData.company_logo : webUrl+'assets/images/company-logo-placeholders.png'" alt=""></b-link>
                                    <div class="right-side daily_right_side mobile_view_salary">
                                        <div class="right_inner_contents">
                                        <div class="salary-box">
                                            <!-- <div class="salary-type" v-if="job_type == 'part-time'">Per Hour Rate</div> -->
                                            <div class="salary-type">Salary</div>
                                        <!-- <div class="salary-amount">S${{jobData.hourly_cost.toFixed(2)}}/HR</div> -->
                                        <div class="salary-amount">S${{parseFloat(jobData.hourly_cost).toFixed(2)}}/HR</div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="header-details updated_header_details_banner">
                                    <!-- <h1 class="header_detail_new_h1">{{title | capitalize}}</h1> -->
                                    <h1 class="header_detail_new_h1 new_daily_paid_jjobs">{{jobData.job_title}}<span class="daily_paid_on_demand_h5_detail" v-if="jobData.job_type_id == 2"><b>On-Demand</b></span></h1>
                                    <h5>About the Employer</h5>
                                    <ul class="mobile_header_ul margin_top_12">
                                        <li :class="jobData.company_name ? 'margin_right_12' : 'margin_right_zero'">
                                            <b-link :to="{name: 'web-emp-profile-view', params: {id: jobData.assigned_to}}">
                                                <!-- <i class="icon-material-outline-business"></i> {{company_name | capitalize}} -->
                                                <i class="icon-material-outline-business common_i_color"></i> <span class="highlight company_name_dt">{{jobData.company_name | capitalize}}</span>
                                            </b-link>
                                        </li>
                                        <!-- <li v-if="company_description">
                                            <p v-if="company_description.length >= 200" v-html="company_description.substring(0,200)+'...' "></p>
                                            <p v-else v-html="company_description"></p>
                                        </li> -->
                                        <!-- <li v-if="average_rating"><div class="star-rating" :data-rating="average_rating"></div></li> -->
                                        <!-- <li><img class="flag" src="images/flags/gb.svg" alt="">{{user_address | capitalize}}</li> -->
                                        <!-- <li v-if='email_verified == "yes"'><div class="verified-badge-with-title">Verified</div></li> -->
                                        <!-- <li ><div class="verified-badge-with-title" v-if="jobData.email_verified == 'yes' && jobData.mobile_verified == 'yes'">Verified</div></li> -->
                                    </ul>
                                </div>
                            </div>
                            <!-- <div class="right-side" v-if="(min_salary != 0 && max_salary != 0) || min_salary > 0 || max_salary > 0">
                                <div class="right_inner_contents">
                                <div class="salary-box">
                                    <div class="salary-type" v-if="job_type == 'part-time'">Per Hour Rate</div>
                                    <div class="salary-type" v-else>Salary</div>
                                <div class="salary-amount">${{min_salary}}-${{max_salary}}</div>
                                </div>
                                </div>
                            </div> -->
                            <div class="right-side daily_right_side desktop_salary">
                                <div class="right_inner_contents">
                                <div class="salary-box">
                                    <!-- <div class="salary-type" v-if="job_type == 'part-time'">Per Hour Rate</div> -->
                                    <div class="salary-type">Salary</div>
                                <!-- <div class="salary-amount">S${{jobData.hourly_cost.toFixed(2)}}/HR</div> -->
                                <div class="salary-amount">S${{parseFloat(jobData.hourly_cost).toFixed(2)}}/HR</div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>


                </div>
            </div>




<div class="container mobile_tab_container_hundered">
	<div class="row">

		<!-- Content -->
		<div class="col-xl-8 col-lg-8 single_page_mrgin_bottom_last single_left_content tab_max_width_and_flex">

			<!-- <div class="single-page-section" v-if="description">
				<h3 class="margin-bottom-25">Job Description</h3>
				<p v-html="description"></p>
			</div> -->
            <div class="mobile_apply_now_button">
                    <a v-b-modal.modal-23 class="apply-now-button" style="color: white;cursor: pointer;" v-if="(user_type_id != 3 && user_type_id != 4)">Apply Now <i class="icon-material-outline-arrow-right-alt"></i></a>
                        <!-- <b-modal id="modal-23" title="" content-class="job_detail_new_pop_up"> -->
                           <b-modal id="modal-23" hide-header  centered ref="rewards-modal-without-login-mobile" title="" hide-footer content-class="job_detail_new_pop_up common_model_header common_model_title" modal-class="background_custom_class rewards_pop_up">
                            <!-- <h4 class="top_pop_up_heading">{{qrdata.title}}</h4> -->
                            <!-- <div class="new_app_images">
                                <img :src="webUrl+'assets/images/dual-store-icon.png'">
                            </div>
                            <div class="full_pop_up_div">
                                <div class="big_image_pop_up">
                                    <img :src="webUrl+'assets/images/new-pop-up-mobile.png'">
                                </div> -->
                                <!-- <div class="small_pop_up_image">
                                    <a :href="qrdata.link" target="_blank"><img :src="qrdata.image != null ? qrdata.image : webUrl+'assets/images/pop-up-qr.png'" class="width_162 mobile_width_140"></a>
                                    <div class="pop_up_store_icon_detail">
                                        <div class="store_icons">
                                            <a :href="link[0].option_value" target="_blank">
                                            <button class="store_btn">
                                                <div class="play_icon width_162 mobile_width_140">
                                                    <div class="apple_logo_div">
                                                        <img :src="webUrl+'assets/jobeekerImages/play_icon.png'">
                                                    </div>
                                                        <div class="content_icon_div">
                                                            <h2 class="font_ten">GET IT ON<br> <span>Google Play</span></h2>
                                                        </div>
                                                    </div>
                                            </button>
                                            </a>
                                            <a :href="link[1].option_value" target="_blank">
                                            <button class="store_btn margin_top_20">
                                                <div class="apple_icon width_162 mobile_width_140">
                                                    <div class="apple_logo_div">
                                                        <i class="fa fa-apple" aria-hidden="true"></i>
                                                    </div>
                                                    <div class="content_icon_div">
                                                        <h2>Available on the<br> <span>App Store</span></h2>
                                                    </div>
                                                </div>
                                            </button>
                                            </a>
                                        </div>

                                    </div>
                                </div> -->
                            <!-- </div> -->
                             <div class="reward_modal_whole_div">
                                    <div class="left_gradient_div_rewards">
                                         <div class="button_div_rewards mobile_reward_btn">
                                            <button type="button" aria-label="Close" class="close" @click="closeRewardsModelMobile()">×</button>
                                        </div>
                                        <div class="reward_cut_img">
                                            <img :src="webUrl+'assets/images/Group-808.png'">
                                        </div>
                                    </div>
                                    <div class="right_content_div_rewards">
                                        <div class="button_div_rewards desktop_reward_btn">
                                            <button type="button" aria-label="Close" class="close" @click="closeRewardsModelMobile()">×</button>
                                        </div>
                                        <div class="inner_content_rewards">
                                            <h2>{{qrdata.title}}</h2>
                                            <p>Discover part time jobs and full-time opportunities at your fingertips. Stay connected and manage your profile easily with our app. </p>
                                            <div class="whole_icon_rewards">
                                                <div class="qr_div_reward">
                                                    <!-- <img :src="webUrl+'assets/images/image-560.png'" class="qr_rewards"> -->
                                                   <a target="_blank" href="https://onelink.to/pkye4c"><img :src="qrdata.image != null ? qrdata.image : webUrl+'assets/images/pop-up-qr.png'" class="qr_rewards"></a>
                                                </div>
                                                <div class="store_div_rewards"> 
                                                    <a :href="link[1] ? link[1].option_value : '#'" target="_blank">
                                                        <img :src="webUrl+'assets/images/image-550.png'" class="apple_rewards">
                                                    </a>
                                                    <br>
                                                     <a :href="link[0] ? link[0].option_value : '#'" target="_blank">
                                                        <img :src="webUrl+'assets/images/image-551.png'" class="play_rewards">
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                             </div>
                            <!-- <div class="download_now_btn_div_pop_up">
                                <a :href="qrdata.link" target="_blank" class="apply-now-button">Download Now <span class="icon-feather-download"></span></a>
                            </div> -->
                        </b-modal>
            </div>
            <div class="mobile_apply_now_button  margin-bottom-25 common_box_border ">
					<div class="job-overview">
						<div class="job-overview-headline bold_heading_iinner common_right_box_heading_padding font_weight_600">Job Summary</div>
						<div class="job-overview-inner common_padding_25">
							<ul>
                                <li>
									<i class="material-icons-outlined job_id_custom"></i>
									<span>Job ID</span>
									<h5>{{jobData.job_unique_id}}</h5>
								</li>
								<li>
									<i class="icon-line-awesome-calendar-o"></i>
									<span>Job Date</span>
									<h5>{{jobData.start_date | customFormatterOne}}  {{jobData.start_date | customFormatterda}}</h5>
								</li>
                                <li>
									<i class="icon-material-outline-access-time"></i>
									<span>Job Timing</span>
									<h5>{{jobData.start_time | momentTime}} - {{jobData.end_time | momentTime}}</h5>
                                    <h5 v-if="jobData.working_hours && jobData.lunch_pay_hours">({{jobData.working_hours}} Hrs + {{jobData.lunch_pay_hours}} hrs unpaid break)</h5>
                                    <h5 v-else-if="jobData.working_hours">({{jobData.working_hours}} Hrs)</h5>

								</li>
                                <!-- <li>
									<i class="icon-feather-users"></i>
									<span>Vacancy</span>
									<h5>{{jobData.total_vacancies}}</h5>
								</li> -->
                                <li>
									<i class="icon-material-outline-business"></i>
									<span>Job Category</span>
									<!-- <h5>{{jobData.service_categories_name == 'Other' ? jobData.other_service_category : jobData.service_categories_name | removeUnderscore}}</h5> -->
                                    <h5>{{jobData.service_categories_name | capitalize}}</h5>
								</li>
								<li>
									<i class="icon-material-outline-business-center"></i>
									<span>Job Type</span>
									<!-- <h5>{{job_type | customizeRoleType | capitalize}}</h5> -->
                                    <h5>Daily Paid</h5>
								</li>
                                <li>
									<i class="icon-material-outline-location-on"></i>
									<span>Location</span>
									<!-- <h5>{{address == 'Other' ? other_location : address | capitalize}}</h5> -->
                                    <h5>{{jobData.job_address | capitalize}}</h5>
								</li>
                                <!-- <li>
                                    <i class="icon-material-outline-school"></i>
									<span>Minimum Qualification Level</span>
									<h5>{{jobData.qualification_title | capitalize}}</h5>
								</li>
                                <li>
									<i class="icon-material-outline-watch"></i>
									<span>Minimum Years of Experience</span>
									<h5>{{jobData.experience}}</h5>
								</li>
                                <li>
									<i class="icon-material-outline-face"></i>
									<span>Position Level</span>
									<h5>{{jobData.level_title | capitalize}}</h5>
								</li>
								<li v-if="(min_salary != 0 && max_salary != 0) || min_salary > 0 || max_salary > 0">
									<i class="icon-material-outline-local-atm"></i>
									<span v-if="job_type == 'part-time'">Per Hour Rate</span>
									<span v-else>Salary</span>
									<h5>${{min_salary}}-${{max_salary}}</h5>
								</li>
                                <li>
									<i class="icon-material-outline-face"></i>
									<span>Featured</span>
									<h5>{{jobData.featured | capitalize}}</h5>
								</li>
								<li>
									<i class="icon-material-outline-access-time"></i>
									<span>Date Posted</span>
									<h5>{{posted_date}}</h5>
								</li> -->
							</ul>
						</div>
					</div>
			</div>
            <div class="single-page-section" v-if="jobData.job_scope">
				<h3 class="margin-bottom-25 color_black font_weight_600">Job Scope</h3>
				<p style="white-space: pre-wrap;word-break: break-word;">{{jobScope | capitalize}}</p>
			</div>

            <div class="single-page-section" v-if="jobData.job_description">
				<h3 class="margin-bottom-25 color_black font_weight_600">Job Requirements</h3>
				<p style="white-space: pre-wrap;word-break: break-word;">{{jobDesc | capitalize}}</p>
			</div>

           <!--<div class="single-page-section">
				<h3 class="margin-bottom-30">Location</h3>
                {{address | capitalize}}
				 <div id="single-job-map-container">
					<div id="singleListingMap" data-latitude="51.507717" data-longitude="-0.131095" data-map-icon="im im-icon-Hamburger"></div>
					<a href="#" id="streetView">Street View</a>
				</div>

			</div>-->

            <div class="single-page-section">
				<h3 class="margin-bottom-30 color_black font_weight_600">Location</h3>
                    <GmapMap
                    :center="center"
                    :zoom="18"
                    style="width:100%;height: 400px;"
                    map-type-id="terrain"
                    :options="{
                    zoomControl: true,
                    scrollwheel: false,
                    }"
                >
                <GmapMarker
                    :key="index"
                        v-for="(m, index) in markers"
                    :position="m.position"
                        @dragend="showLocation"
                    @click="center=m.position"
                />
                </GmapMap>
			</div>
            <!-- <div class="single-page-section desktop_margin_bottom_zero">
                <h3 class="margin-bottom-25 color_black">Suggested Jobs</h3>
            </div> -->
            <!-- <div class="suggested_jobs_daily_paid job_list_btm"> -->
                <!-- <a href="#" class="job-listing" style="width: 100%;" v-for="(suggestedJob,key) in suggestedJobs">
                    <div class="job-listing-details"> -->
                        <!-- <div class="job-listing-company-logo">
                            <img :src="suggestedJob.company_logo != null ? suggestedJob.company_logo : webUrl+'assets/images/company-logo-placeholders.png'" alt="">
                        </div> -->
                        <!-- <div class="job_listing_card_inner mobile_display_block">
                            <div class="job-listing-description">
                                <h4 class="job-listing-company bold_heading_iinner suggested_title"><b>{{suggestedJob.job_title}}</b></h4>
                                <h4 class="salary_h4_color">S${{suggestedJob.hourly_cost}}/HR</h4> -->
                                <!-- <h4 class="job-listing-company">Cleaning</h4> -->
                                <!-- <h3 class="job-listing-title new_all_job_title">
                                <p>dsfdsfdffd</p></h3> -->
                                <!-- <h4 class="job-listing-company">sak</h4> -->
                                <!-- </div> -->
                                <!-- <div class="star_icon joblist_star_icon desktop_flex">
                                    <div class="featured_job_section_lists">
                                        <h5 class="daily_paid_on_demand_h5"><b>On-Demand</b></h5>
                                    </div>
                                </div> -->
                                <!-- </div>
                                </div> -->
                                <!-- <div class="job-listing-footer">
                                    <ul class="daily_paid_uls suggested_job_ftr">
                                        <li>
                                            <i class="icon-material-outline-location-on"></i>{{suggestedJob.job_address | capitalize}}
                                        </li>
                                        <li>
                                            <i class="icon-material-outline-access-time"></i>{{suggestedJob.start_time | momentTime}} - {{suggestedJob.end_time | momentTime}}
                                        </li>
                                        <li>
                                            <i class="icon-line-awesome-calendar-o"></i>{{suggestedJob.start_date | customFormatterOne}}
                                        </li>
                                        <li>
                                            <i class="icon-feather-users"></i>{{suggestedJob.total_vacancies}} Vacancy
                                        </li>
                                    </ul>
                                </div> -->
                <!-- </a> -->
                <!-- </div> -->
		    </div>


		<!-- Sidebar -->
		<div class="col-xl-4 col-lg-4 single_right_sidebar tab_max_width_and_flex">
			<div class="sidebar-container desktop_margin_bottom_zero">

                <div class="desktop_apply_now_button">
                    <a v-b-modal.modal-22 class="apply-now-button" style="color: white;cursor: pointer;" v-if="(user_type_id != 3 && user_type_id != 4)">Apply Now <i class="icon-material-outline-arrow-right-alt"></i></a>
                        <!-- <b-modal id="modal-22" title="" content-class="job_detail_new_pop_up"> -->
                            <b-modal id="modal-22" hide-header  centered ref="rewards-modal-without-login" title="" hide-footer content-class="job_detail_new_pop_up common_model_header common_model_title" modal-class="background_custom_class rewards_pop_up">
                                <!-- <h4 class="top_pop_up_heading">{{qrdata.title}}</h4> -->
                                <!-- <div class="new_app_images">
                                    <img :src="webUrl+'assets/images/dual-store-icon.png'">
                                </div> -->
                                <!-- <div class="full_pop_up_div desktop_full_pop_up">
                                    <div class="big_image_pop_up_desktop">
                                        <img :src="webUrl+'assets/images/new-pop-up-mobile.png'">
                                    </div>
                                    <div class="small_pop_up_image">
                                        <a target="_blank"><img :src="qrdata.image != null ? qrdata.image : webUrl+'assets/images/pop-up-qr.png'" class="width_162 mobile_width_140"></a>
                                        <div class="pop_up_store_icon_detail">
                                            <div class="store_icons">
                                                <a :href="link[0] ? link[0].option_value : '#'" target="_blank">
                                                <button class="store_btn">
                                                    <div class="play_icon width_162 mobile_width_140">
                                                        <div class="apple_logo_div">
                                                            <img :src="webUrl+'assets/jobeekerImages/play_icon.png'">
                                                        </div>
                                                            <div class="content_icon_div">
                                                                <h2 class="font_ten">GET IT ON<br> <span>Google Play</span></h2>
                                                            </div>
                                                        </div>
                                                </button>
                                                </a>
                                                <a :href="link[1] ? link[1].option_value : '#'" target="_blank">
                                                <button class="store_btn margin_top_20">
                                                    <div class="apple_icon width_162 mobile_width_140">
                                                        <div class="apple_logo_div">
                                                            <i class="fa fa-apple" aria-hidden="true"></i>
                                                        </div>
                                                        <div class="content_icon_div">
                                                            <h2>Available on the<br> <span>App Store</span></h2>
                                                        </div>
                                                    </div>
                                                </button>
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </div> -->
                                <!-- <div class="download_now_btn_div_pop_up">
                                    <a :href="qrdata.link" target="_blank" class="apply-now-button">Download Now <span class="icon-feather-download"></span></a>
                                </div> -->
                                  <div class="reward_modal_whole_div">
                                    <div class="left_gradient_div_rewards">
                                        <div class="reward_cut_img">
                                            <img :src="webUrl+'assets/images/Group-808.png'">
                                        </div>
                                    </div>
                                    <div class="right_content_div_rewards">
                                        <div class="button_div_rewards">
                                            <button type="button" aria-label="Close" class="close" @click="closeRewardsModel()">×</button>
                                        </div>
                                        <div class="inner_content_rewards">
                                            <h2>{{qrdata.title}}</h2>
                                            <p>Discover part time jobs and full-time opportunities at your fingertips. Stay connected and manage your profile easily with our app. </p>
                                            <div class="whole_icon_rewards">
                                                <div class="qr_div_reward">
                                                    <!-- <img :src="webUrl+'assets/images/image-560.png'" class="qr_rewards"> -->
                                                   <a target="_blank" href="https://onelink.to/pkye4c"><img :src="qrdata.image != null ? qrdata.image : webUrl+'assets/images/pop-up-qr.png'" class="qr_rewards"></a>
                                                </div>
                                                <div class="store_div_rewards"> 
                                                    <a :href="link[1] ? link[1].option_value : '#'" target="_blank">
                                                        <img :src="webUrl+'assets/images/image-550.png'" class="apple_rewards">
                                                    </a>
                                                    <br>
                                                     <a :href="link[0] ? link[0].option_value : '#'" target="_blank">
                                                        <img :src="webUrl+'assets/images/image-551.png'" class="play_rewards">
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </b-modal>
                </div>
                <!-- <div class="tab_apply_now_button"> -->
                    <!-- <a v-b-modal.modal-24 class="apply-now-button" style="color: white;cursor: pointer;" v-if="(user_type_id != 3 && user_type_id != 4)">Apply Now <i class="icon-material-outline-arrow-right-alt"></i></a> -->
                    <!-- <b-modal id="modal-24" title="" content-class="job_detail_new_pop_up"> -->
                        <!-- <h4 class="top_pop_up_heading">{{qrdata.title}}</h4> -->
                        <!-- <h4 class="top_pop_up_heading">Download the App to apply for Daily Paid Jobs</h4> -->
                        <!-- <div class="new_app_images">
                             <img :src="webUrl+'assets/images/dual-store-icon.png'">
                        </div> -->
                        <!-- <div class="full_pop_up_div"> -->
                            <!-- <div class="big_image_pop_up">
                                <img :src="webUrl+'assets/images/new-pop-up-mobile.png'">
                            </div> -->
                            <!-- <div class="small_pop_up_image">
                                <a :href="qrdata.link" target="_blank"><img :src="qrdata.image != null ? qrdata.image : webUrl+'assets/images/pop-up-qr.png'" class="width_162 mobile_width_140"></a>
                                <div class="pop_up_store_icon_detail">
                                    <div class="store_icons">
                                        <a :href="link[0].option_value" target="_blank">
                                        <button class="store_btn">
                                            <div class="play_icon width_162 mobile_width_140">
                                                <div class="apple_logo_div">
                                                    <img :src="webUrl+'assets/jobeekerImages/play_icon.png'">
                                                </div>
                                                    <div class="content_icon_div">
                                                        <h2 class="font_ten">GET IT ON<br> <span>Google Play</span></h2>
                                                    </div>
                                                </div>
                                        </button>
                                        </a>
                                        <a :href="link[1].option_value" target="_blank">
                                        <button class="store_btn margin_top_20">
                                            <div class="apple_icon width_162 mobile_width_140">
                                                <div class="apple_logo_div">
                                                    <i class="fa fa-apple" aria-hidden="true"></i>
                                                </div>
                                                <div class="content_icon_div">
                                                    <h2>Available on the<br> <span>App Store</span></h2>
                                                </div>
                                            </div>
                                        </button>
                                        </a>
                                    </div>

                                </div>
                            </div> -->
                        <!-- </div> -->
                        <!-- <div class="download_now_btn_div_pop_up">
                            <a :href="qrdata.link" target="_blank" class="apply-now-button">Download Now <span class="icon-feather-download"></span></a>
                        </div> -->
                <!-- </b-modal> -->
                <!-- </div> -->

				<!--<a v-if="alreadyApplied == false || job_end_date > today" @click="apply" class="apply-now-button" style="color: white;background: blue;cursor: pointer;">Apply Now <i class="icon-material-outline-arrow-right-alt"></i></a>-->
				<!-- <a v-if="is_applied == 'yes'" class="apply-now-button" style="color: white;background: green;">Already Applied </a> -->



				<!-- Sidebar Widget -->
				<div class="sidebar-widget margin-bottom-25 common_box_border desktop_apply_now_button">
					<div class="job-overview">
						<div class="job-overview-headline bold_heading_iinner common_right_box_heading_padding font_weight_600">Job Summary</div>
						<div class="job-overview-inner">
							<ul>
                                <li>
									<i class="material-icons-outlined job_id_custom"></i>
									<span>Job ID</span>
									<h5>{{jobData.job_unique_id}}</h5>
								</li>
								<li>
									<i class="icon-line-awesome-calendar-o"></i>
									<span>Job Date</span>
									<h5>{{jobData.start_date | customFormatterOne}}  {{jobData.start_date | customFormatterda}}</h5>
								</li>
                                <li>
									<i class="icon-material-outline-access-time"></i>
									<span>Job Timing</span>
									<h5>{{jobData.start_time | momentTime}} - {{jobData.end_time | momentTime}}</h5>
                                    <h5 v-if="jobData.working_hours && jobData.lunch_pay_hours">({{jobData.working_hours}} Hrs + {{jobData.lunch_pay_hours}} hrs unpaid break)</h5>
                                    <h5 v-else-if="jobData.working_hours">({{jobData.working_hours}} Hrs)</h5>

								</li>
                                <!-- <li>
									<i class="icon-feather-users"></i>
									<span>Vacancy</span>
									<h5>{{jobData.total_vacancies}}</h5>
								</li> -->
                                <li>
									<i class="icon-material-outline-business"></i>
									<span>Job Category</span>
									<!-- <h5>{{jobData.service_categories_name == 'Other' ? jobData.other_service_category : jobData.service_categories_name | removeUnderscore}}</h5> -->
                                    <h5>{{jobData.service_categories_name | capitalize}}</h5>
								</li>
								<li>
									<i class="icon-material-outline-business-center"></i>
									<span>Job Type</span>
									<!-- <h5>{{job_type | customizeRoleType | capitalize}}</h5> -->
                                    <h5>Daily Paid</h5>
								</li>
                                <li>
									<i class="icon-material-outline-location-on"></i>
									<span>Location</span>
									<!-- <h5>{{address == 'Other' ? other_location : address | capitalize}}</h5> -->
                                    <h5>{{jobData.job_address | capitalize}}</h5>
								</li>
                                <!-- <li>
                                    <i class="icon-material-outline-school"></i>
									<span>Minimum Qualification Level</span>
									<h5>{{jobData.qualification_title | capitalize}}</h5>
								</li>
                                <li>
									<i class="icon-material-outline-watch"></i>
									<span>Minimum Years of Experience</span>
									<h5>{{jobData.experience}}</h5>
								</li>
                                <li>
									<i class="icon-material-outline-face"></i>
									<span>Position Level</span>
									<h5>{{jobData.level_title | capitalize}}</h5>
								</li>
								<li v-if="(min_salary != 0 && max_salary != 0) || min_salary > 0 || max_salary > 0">
									<i class="icon-material-outline-local-atm"></i>
									<span v-if="job_type == 'part-time'">Per Hour Rate</span>
									<span v-else>Salary</span>
									<h5>${{min_salary}}-${{max_salary}}</h5>
								</li>
                                <li>
									<i class="icon-material-outline-face"></i>
									<span>Featured</span>
									<h5>{{jobData.featured | capitalize}}</h5>
								</li>
								<li>
									<i class="icon-material-outline-access-time"></i>
									<span>Date Posted</span>
									<h5>{{posted_date}}</h5>
								</li> -->
							</ul>
						</div>
					</div>
				</div>

				<!-- Sidebar Widget -->
				<div class="sidebar-widget desktop_margin_bottom_zero new_bookmark_share_btn">
					<!-- <h3>Bookmark or Share</h3> -->

					<!-- Bookmark Button -->
					<!-- <button class="bookmark-button margin-bottom-25"> -->
                        <!-- <b-form-checkbox buttons class="bookmark-button margin-bottom-25" id="bookmark" v-model="bookmarkjobs" @click.native="bookmarkCurrentJob($event)" :disabled="true">

                            <span class="bookmark-icon"></span>

                            <span class="bookmarked-text"  v-if="bookmarkjobs == jobId">Bookmarked</span>

                            <span class="bookmark-text" v-if="bookmarkjobs == false">Bookmark</span>
                              </b-form-checkbox> -->
						<!-- <span class="bookmark-icon"></span>
						<span class="bookmark-text">Bookmark</span>
						<span class="bookmarked-text">Bookmarked</span> -->
					<!-- </button> -->



					<!-- Copy URL -->
					<div class="copy-url">
						<input id="copy-url" type="text" value="" class="with-border" readonly>
						<button class="copy-url-button ripple-effect" @click="copyURL()" data-clipboard-target="#copy-url" title="Copy to Clipboard" data-tippy-placement="top"><i class="icon-material-outline-file-copy"></i></button>
					</div>

					<!-- Share Buttons -->
					<div class="share-buttons margin-top-25">
						<div class="share-buttons-trigger"><i class="icon-feather-share-2"></i></div>
						<div class="share-buttons-content">
							<span>Interesting? <strong>Share It!</strong></span>

                             <q-btn>

                                </q-btn>

							<ul class="share-buttons-icons">
                               <li style="">
                                <ShareNetwork
                                    network="facebook"
                                    quote="AwesomeArticle"
                                    description="test"
                                    :url="this.jobUrl"

                                    hashtags="Jobs, SharedJobs">
                                    <!-- <span  style="color:#3b5998"><v-icon class="mr-3" style="color: #3b5998">fab fa-facebook</v-icon></span> -->
                                </ShareNetwork>
                               </li>

                                <li>

                                <ShareNetwork
                                    network="twitter"
                                    :url="this.jobUrl"
                                    title="Please find the shared job by using the given url"
                                    hashtags="Jobs, SharedJobs">
                                    <!-- <span  style="color:#3b5998">Share on Twitter</span> -->
                                </ShareNetwork>
                               </li>

                                <li>

                               <ShareNetwork
                                    network="linkedin"
                                    :url="this.jobUrl"
                                    title="Please find the shared job by using the given url"
                                    description="test"

                                    hashtags="Jobs, SharedJobs">
                                    <!-- <span  style="color:#3b5998">Share on Twitter</span> -->
                                </ShareNetwork>
                               </li>


								<!-- <li><a href="#" data-button-color="#3b5998" title="Share on Facebook" data-tippy-placement="top"><i class="icon-brand-facebook-f"></i></a></li>
								<li><a href="#" data-button-color="#1da1f2" title="Share on Twitter" data-tippy-placement="top"><i class="icon-brand-twitter"></i></a></li>
								<li><a href="#" data-button-color="#dd4b39" title="Share on Google Plus" data-tippy-placement="top"><i class="icon-brand-google-plus-g"></i></a></li>
								<li><a href="#" data-button-color="#0077b5" title="Share on LinkedIn" data-tippy-placement="top"><i class="icon-brand-linkedin-in"></i></a></li> -->
							</ul>
						</div>
					</div>
				</div>

			</div>
		</div>
    </div>

        <!-- Single Page Job Slider Start-->

        <div class="row" v-if="suggestedJobs.length">
            <div class="col-xl-12 col-lg-12 mobile_top_margin_25 tab_margin_top_25">
                 <h3 class="margin-bottom-25 font_weight_600 common_futura_heavy">Suggested Jobs By Category</h3>
                <VueSlickCarousel :arrows="true" :dots="true" v-bind="settings" ref="carousel" v-if="suggestedJobs.length" class="job_detail_slider">

						<div v-for="(suggestedJob, index) in suggestedJobs" :key="index">

                            <a href="#" @click="jobDetail(suggestedJob.alias,suggestedJob.job_alias,encrypt(suggestedJob.jobId),$event)" class="job-listing job_detail_footer_cs desktop_margin_bottom_zero">
                                <!-- Job Listing Details -->
                                <div class="job-listing-details">
                                    <!-- Logo -->
                                    <div class="job-listing-company-logo">
                                        <img :src="suggestedJob.company_logo != null ? suggestedJob.company_logo : webUrl+'assets/images/company-logo-placeholders.png'" alt="">
                                    </div>

                                    <!-- Details -->
                                    <div class="job-listing-description">
                                        <!-- <h4 class="job-listing-company">{{suggestedJob.name}}</h4> -->
                                         <!-- <h3 class="job-listing-title" v-if="suggestedJob.job_title.length >= 25" v-html="suggestedJob.job_title.substring(0,25)+'...' "></h3> -->
                                         <h3 class="job-listing-company new_headinz" v-if="suggestedJob.job_title.length >= 42" v-html="suggestedJob.job_title.substring(0,42)+'...' "></h3>
                                        <!-- <h3 v-else v-html="suggestedJob.job_title" class="job-listing-company company_height new_headinz"></h3> -->
                                        <h3 v-else v-html="suggestedJob.job_title" class="job-listing-company"></h3>

                                        <h4 class="salary_h4_color desktop_margin_top_zero">S${{suggestedJob.hourly_cost.toFixed(2)}}/HR</h4>
                                    </div>
                                </div>

                                <!-- Job Listing Footer -->
                                <div class="job-listing-footer ">
                                    <ul>
                                        <!-- <li class="location_ht"><i class="icon-material-outline-location-on common_i_color"></i> <span>{{suggestedJob.job_address == 'Other' ? suggestedJob.other_location : suggestedJob.job_address | capitalize}}</span></li> -->
                                        <li><i class="icon-material-outline-business-center common_i_color"></i> {{suggestedJob.sc_name | capitalize}}</li>
                                        <div class="li_main_div_dts">
                                            <!-- <li class="one_li" v-if="suggestedJob.min_salary && suggestedJob.max_salary"><i class="common_i_color material-icons-outlined custom dollar_custom_sign new_dollar_custom_sign">monetization_on_outline</i> ${{suggestedJob.min_salary != null ? suggestedJob.min_salary : 0}}-${{suggestedJob.max_salary != null ? suggestedJob.max_salary : 0}}</li> -->
                                            <!-- <li class="two_li"><i class="common_i_color icon-material-outline-access-time"></i> {{suggestedJob.posted_date | dateTimeAgo | capitalize}}</li> -->
                                            <!-- <li class="two_li"><i class="common_i_color icon-material-outline-access-time"></i> {{suggestedJob.posted_date | capitalize}}</li> -->
                                            <li class="two_lis"><i class="common_i_color icon-material-outline-access-time"></i> {{suggestedJob.start_date | moment}} {{suggestedJob.start_time | momentTime}}</li>


                                        </div>
                                        <!-- <li class="location_ht"><i class="icon-material-outline-location-on common_i_color"></i> <span>{{suggestedJob.job_address == 'Other' ? suggestedJob.other_location : suggestedJob.job_address | capitalize}}</span></li> -->
                                        <li class="location_ht"><i class="icon-material-outline-location-on common_i_color" v-if="suggestedJob.job_address"></i>
                                        <span v-if="suggestedJob.job_address.length > 77">{{suggestedJob.job_address.substring(0,77)+"..."  | capitalize}}</span>
                                        <span v-else >{{suggestedJob.job_address | capitalize}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </a>
                        </div>

				</VueSlickCarousel>
            </div>
        </div><br>


        <!-- SUGGESTED JOB BY COMPANY -->
       <!--  <div class="row" v-if="suggestedJobsCompany.length">
          <div class="col-xl-12 col-lg-12 mobile_top_margin_25 tab_margin_top_25">
                 <h3 class="margin-bottom-25 font_weight_600 common_futura_heavy">Suggested Jobs By Company</h3>
                <VueSlickCarousel :arrows="true" :dots="true" v-bind="settings" ref="carousel" v-if="suggestedJobsCompany.length" class="job_detail_slider">

              <div v-for="(suggestedJob, index) in suggestedJobsCompany" :key="index">

                  <a href="#" @click="jobDetail(suggestedJob.jobId,$event)" class="job-listing job_detail_footer_cs desktop_margin_bottom_zero">
                      Job Listing Details
                      <div class="job-listing-details">
                      Logo
                        <div class="job-listing-company-logo">
                          <img :src="suggestedJob.company_logo != null ? suggestedJob.company_logo : webUrl+'assets/images/company-logo-placeholders.png'" alt="">
                        </div>

                      Details
                          <div class="job-listing-description">
                            <h4 class="job-listing-company">{{suggestedJob.name}}</h4>
                            <h3 class="job-listing-title" v-if="suggestedJob.job_title.length >= 25" v-html="suggestedJob.job_title.substring(0,25)+'...' "></h3>
                            <h3 class="job-listing-company new_headinz" v-if="suggestedJob.job_title.length >= 42" v-html="suggestedJob.job_title.substring(0,42)+'...' "></h3>
                              <h3 v-else v-html="suggestedJob.job_title" class="job-listing-company company_height new_headinz"></h3>
                              <h3 class="job-listing-company" v-if="suggestedJob.job_title.length >= 42" v-html="suggestedJob.job_title.substring(0,42)+'...' "></h3>
                              <h3 v-else v-html="suggestedJob.job_title" class="job-listing-company"></h3>
                              <h4 class="salary_h4_color desktop_margin_top_zero">S${{suggestedJob.hourly_cost.toFixed(2)}}/HR</h4>
                          </div>
                      </div>

                      Job Listing Footer
                      <div class="job-listing-footer ">
                          <ul>
                              <li class="location_ht"><i class="icon-material-outline-location-on common_i_color"></i> <span>{{suggestedJob.job_address == 'Other' ? suggestedJob.other_location : suggestedJob.job_address | capitalize}}</span></li>
                              <li><i class="icon-material-outline-business-center common_i_color"></i> {{suggestedJob.sc_name | capitalize}}</li>
                              <div class="li_main_div_dts">
                                  <li class="one_li" v-if="suggestedJob.min_salary && suggestedJob.max_salary"><i class="common_i_color material-icons-outlined custom dollar_custom_sign new_dollar_custom_sign">monetization_on_outline</i> ${{suggestedJob.min_salary != null ? suggestedJob.min_salary : 0}}-${{suggestedJob.max_salary != null ? suggestedJob.max_salary : 0}}</li>
                                  <li class="two_li"><i class="common_i_color icon-material-outline-access-time"></i> {{suggestedJob.posted_date | dateTimeAgo | capitalize}}</li>
                                  <li class="two_lis"><i class="common_i_color icon-material-outline-access-time"></i> {{suggestedJob.start_date | moment}} {{suggestedJob.start_time | momentTime}}</li>
                              </div>
                            <li class="location_ht"><i class="icon-material-outline-location-on common_i_color"></i> <span>{{suggestedJob.job_address == 'Other' ? suggestedJob.other_location : suggestedJob.job_address | capitalize}}</span></li>
                              <li class="location_ht"><i class="icon-material-outline-location-on common_i_color" v-if="suggestedJob.job_address"></i>
                              <span v-if="suggestedJob.job_address.length > 73">{{suggestedJob.job_address.substring(0,73)+"..."  | capitalize}}</span>
                              <span v-else >{{suggestedJob.job_address | capitalize}}</span>
                              </li>
                          </ul>
                      </div>
                  </a>
              </div>
				        </VueSlickCarousel>
            </div>
        </div> -->

        <!-- Single Page Job Slider / End -->

</div>
<div class="margin-bottom-50 mobile_tab_margin_bottom_35"></div>
    </div>

</template>

<script>
    import { POST_API } from "../../store/actions.type"
    import moment from 'moment'
    import { Carousel, Slide } from 'vue-carousel';
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
    import VueSocialSharing from 'vue-social-sharing'
    import VueGoogleAutocomplete from "vue-google-autocomplete";


    export default {
                metaInfo: {
                    title: 'EL Connect - Daily Paid Part Time Platform',
                    meta: [
                        { charset: 'utf-8' },
            { equiv: 'content-type', content: 'text/html' },
                        { name: 'description', content: 'Part time job with incentives, bonus and lucky draws! Get to win up to $888 in cash.' },
                        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                        {property: 'og:title', content: 'EL Connect - Daily Paid Part Time Platform'},
                        {property: 'og:site_name', content: 'EL Connect'},
                        {property: 'og:type', content: 'website'},
                        {property: 'og:url', content: 'https://elconnect.sg/dp-jobs-detail'},
                        {property: 'og:image', content: 'https://elconnect.sgp1.digitaloceanspaces.com/v2/general_setting/202281202127_general_setting.png'},
                        {property: 'og:description', content: 'Part time job with incentives, bonus and lucky draws! Get to win up to $888 in cash.'},
                        {name: 'twitter:card', content: 'summary'}
                    ],
                    link: [
                        {rel: 'canonical', href: 'https://elconnect.sg/dp-jobs-detail'}
                    ]
                },

         data() {
            return {
                prevRoute: null,
                bookmarkVal:'',
                cv:'',
                bookmarkjobs:[],
                bookmarkjobsData:'',
				suggestedJobs: [],
                suggestedJobsCompany :[],
                simJobs:[],
                is_applied:'no',
                id: atob(this.$route.params.id),
                alias:this.$route.params.company_name,
                job_alias:this.$route.params.job_alias,
                title:'',
                daily:'',
                jobDesc:'',
                jobScope:'',
                posted_by:'',
                profile_image:'',
                email_verified:'',
                formattedAddress:'',
                items: null,
                job_type:'',
                keyword:'',
                userId:'',
                jobId:'',
                jobUrl:'',
                alreadyApplied : false,
                error_message:'',
                jobData:[],
                pager: {},
                pageOfItems: [],
                items:null,
                totalRows: 0,

                to: '',
                qrdata: [],
                link:[],
                user_type_id: '',
                currentPage: 1,
                perPage: 25,
                pageOptions: [25, 50, 75, 100],
                autocompleteOptions: {
                    componentRestrictions: {
                        country: [
                            'SG'
                        ],
                    },
                },
                center: { lat: 1.3521, lng: 103.8198 },
                markers: [],
                places: [],
                costListing:[],
                currentPlace: null,
                form:{
                    rowsPerPage:25,
                },
                webUrl: process.env.VUE_APP_URL,
                user_address:'',
                settings: {
				"slidesToShow": 3,
				"slidesToScroll": 3,
				"speed": 800,
				"infinite": false,
				// responsive: [
				// 	{
				// 	breakpoint: 768,
				// 	settings: {
				// 		"slidesToShow": 1,
				// 			"slidesToScroll": 1,
				// 			"speed": 800,
				// 			"infinite": false,
                //             "dots":true,
                //             "arrows":false
				// 	    }
				// 	}
				// ]
                responsive: [
					{
					// breakpoint: 768,
					// settings: {
					// 	"slidesToShow": 1,
					// 		"slidesToScroll": 1,
					// 		"speed": 800,
					// 		"infinite": false,
                    //         "dots":true,
                    //         "arrows":false
					//     }
                    "breakpoint": 998,
                            "settings": {
                                "slidesToShow": 1,
                                "slidesToScroll": 1,
                                // "infinite": true,
                                "dots": true,
                                "arrow":true
                            }
                            },
                            {
                            "breakpoint": 1000,
                            "settings": {
                                "slidesToShow": 1,
                                "slidesToScroll": 1,
                                // "infinite": true,
                                "dots": true,
                                "arrow":true
                            }
                            },
                            {
                            "breakpoint": 768,
                            "settings": {
                                "slidesToShow": 1,
                                "slidesToScroll": 1,
                                "initialSlide": 1,
                                "dots": true,
                                "arrow":true
                            }
                            },
                            {
                            "breakpoint": 480,
                            "settings": {
                                "slidesToShow": 1,
                                "slidesToScroll": 1,
                                "dots": true,
                                "arrow":true
                            }

					}
				]
			}
            }
        },
        beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
        },
        components: {
            VueGoogleAutocomplete,
        },

        filters: {
            capitalize: function(value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            moment: function(date) {
                return moment(date).format('DD MMM YYYY')
            },
            dateTimeAgo: function(value) {
                if (!value) return ''
                const dateTimeAgo = moment(value).fromNow();
                return dateTimeAgo
            },
            momentTime: function(date) {
				return moment(date, "HH:mm:ss").format("hh:mm A")
			},

            customFormatterOne(date) {
                // return moment(date).format(' ddd, DD MMM YYYY');
                return moment(date).format('  DD MMM YYYY');
            },
            customFormatterda(date) {
                // return moment(date).format(' ddd, DD MMM YYYY');
                return moment(date).format('dddd');
            },
            removeUnderscore: function(value) {
                if (!value) return ''
                var i, frags = value.split('_');
                for (i=0; i<frags.length; i++) {
                    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
            },
            customizeRoleType: function(value) {
                if (!value) return ''
                var i, frags = value.split('-');
                for (i=0; i<frags.length; i++) {
                    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
            },
        },

        methods:{
            closeRewardsModel(){
                this.$refs["rewards-modal-without-login"].hide();
            },
            closeRewardsModelMobile(){
                this.$refs["rewards-modal-without-login-mobile"].hide();
            },
            showAlert() {
                    this.$swal({
                        position: 'center',
                        icon: 'error',
                        title: this.error_message,
                        showConfirmButton: false,
                        timer: 1500
                    })
            },

            jobDetail(alias,job_alias,id,event){

                if (event) {
                event.stopPropagation()
                }
                console.log(alias,id);
                 window.location.replace('/singapore-jobs-daily-paid/'+alias +'/'+job_alias +'/'+id);

            },
            homeUrl(id,event){
                 window.location.replace('/');
            },
            async copyURL() {
                    try {

                        let copyText = document.querySelector("#copy-url");
                        copyText.select();
                        document.execCommand("copy");
                        // var mytext = $('#copy-url').val();
                        // navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
                        //     if (result.state == "granted" || result.state == "prompt") {
                        //         navigator.clipboard.writeText(mytext);
                        //     }
                        // });

                        Snackbar.show({
                            text: 'Copied to clipboard!',
                        });
                        // alert('Copied to Clipboard.');
                    } catch($e) {

                        console.log($e);

                        // alert('Cannot copy');
                    }
              },
            showSuccessAlert(message) {
                this.$swal({
                    position: 'center',
                    icon: 'success',
                    iconColor:'#4c8bf5',
                    title: message,
                    showConfirmButton: false,
                    timer: 1500
                });
            },


             showLocation: function(evt){
                //   this.l1=evt.latlng.latitude;

             this.datas = evt.latLng.toString().split(',');


             const marker = {
              lat: +this.datas[0].substring(1),
              lng: +this.datas[1].substring(0, this.datas[1].length-1)
            };
            this.center.lat= this.datas[0].substring(1),
            this.center.lng= this.datas[1].substring(0, this.datas[1].length-1)
             console.log( evt.latLng.toString(),this.center.lat,this.center.lng);

            this.center = marker;
            this.currentPlace = null;
            const _googleApiGeocoder = new google.maps.Geocoder();


                  _googleApiGeocoder.geocode(
                    {
                      location: {
                        lat: parseFloat(this.center.lat),
                        lng: parseFloat(this.center.lng)
                      }
                    },
                    (results_, status_) => {
                        console.log(results_, "results");
                      if (status_ !== google.maps.GeocoderStatus.OK) {
                        // error process here
                      } else {
                        this.formattedAddress=results_[0].formatted_address;
                      }
                    });
        },
        setPlace(place) {
            console.log(place);
            this.l1 = place.latitude;
            this.l2 = place.longitude;

          this.currentPlace = place;
          this.addMarker();
        },
        getAddressData: function (addressData, placeResultData, id) {

            console.log(addressData);
        this.address = addressData;
      },
        geolocate() {

          navigator.geolocation.getCurrentPosition(position => {

            this.center = {
              lat: +parseFloat(position.coords.latitude),
              lng: +parseFloat(position.coords.longitude)
            };

              const marker = {
                  lat: this.center.lat,
                  lng: this.center.lng
                };
                // this.formattedAddress =this.currentPlace.formatted_address;
                this.markers = [];
                this.markers.push({ position: marker });
                this.places = [];

                this.places.push(this.currentPlace);

                console.log(marker,'marker');
                // this.center = marker;
                this.currentPlace = this.center;
                this.addressUpdate();

          }, err => {

                  this.addMarker();
                  this.addressUpdate();
                  //console.log(err.message);

                });
        },
        addressUpdate(){
             setTimeout(()=>{


                const _googleApiGeocoder = new google.maps.Geocoder();


                  _googleApiGeocoder.geocode(
                    {
                      location: {
                        lat: parseFloat(this.center.lat),
                        lng: parseFloat(this.center.lng)
                      }
                    },
                    (results_, status_) => {
                        console.log(results_, "results");
                      if (status_ !== google.maps.GeocoderStatus.OK) {
                        // error process here
                      } else {
                        this.formattedAddress=results_[0].formatted_address;
                      }
                    });
              },200);
        },
        addMarker() {
            console.log(this.currentPlace,'retr',this.center);
          if (this.currentPlace) {
            const marker = {
              lat: +parseFloat(this.currentPlace.latitude),
              lng: +parseFloat(this.currentPlace.longitude)
            };
        console.log(this.currentPlace.locality);
        if(this.currentPlace.street_number){

            this.currentPlace.street_number = this.currentPlace.street_number;
        }else{

                    this.currentPlace.street_number = '';

        }
            this.formattedAddress =this.currentPlace.street_number + " " + this.currentPlace.route + ", " + this.currentPlace.locality;
            this.markers = [];
            this.markers.push({ position: marker });
            this.places = [];

            this.places.push(this.currentPlace);

            this.center = marker;
            this.currentPlace = null;
          }
        //   else {
        //     const marker = {
        //       lat: this.center.lat,
        //       lng: this.center.lng
        //     };
        //     console.log(marker,'juyuyuyuyuyuyuy');
        //     // this.formattedAddress =this.currentPlace.formatted_address;
        //     this.markers = [];
        //     this.markers.push({ position: marker });
        //     this.places = [];

        //     this.places.push(this.currentPlace);

        //     this.center = marker;
        //     this.currentPlace = null;
        //     // console.log(this.center);
        //   }
        },

            getjobsDataById() {

                var id = (this.id);

                return this.$store.dispatch(POST_API, {
                    data:{
                            job_id: this.id,
                            user_id:this.user_id
                },
                    api: '/api/dpjob-detail-web'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        var checked = [];



                        this.jobData = this.$store.getters.getResults.data[0];
                        this.jobDesc = this.$store.getters.getResults.data[0].job_description.replace(/(?:\r\n|\r|\n)/g, '\n');
                        this.jobScope = this.$store.getters.getResults.data[0].job_scope.replace(/(?:\r\n|\r|\n)/g, '\n');


                        this.suggestedJobs = this.$store.getters.getResults.suggested_jobs;
                        this.suggestedJobsCompany = this.$store.getters.getResults.suggested_jobs_company;



                        this.l1 = this.$store.getters.getResults.data[0].lat;
                        this.l2 = this.$store.getters.getResults.data[0].long;
                        const marker = {
                            lat: +parseFloat(this.l1),
                            lng: +parseFloat(this.l2)
                        };
                        console.log(marker,'marker');
                        this.markers.push({ position : marker });
                        console.log(this.places,marker,'marker');
                        this.addMarker();
                        // this.places.push(this.currentPlace);
                        this.center = marker;
                    }
                });

            },

             getQRData() {
                return this.$store.dispatch(POST_API, {

                    api: '/api/qr-data'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        if(this.$store.getters.getResults.data){

                            this.qrdata = this.$store.getters.getResults.data;

                        }else{

                        }
                    }
                });
            },

            getlinkdata() {
                return this.$store.dispatch(POST_API, {

                    api: '/api/linkSettings'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        if(this.$store.getters.getResults.data){

                            this.link = this.$store.getters.getResults.data;

                        }else{

                        }
                    }
                });
            },
            jobViewCount(){
                this.$store.dispatch(POST_API, {
                    data:{
                    id: this.id,
                    },
                    api:'/api/dp-job-view-count'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        return [];
                    }
                    else {
                    }
                });
            },


        },
        mounted(){
            $(".share-buttons-icons").addClass('hide_social_share');
            $(".share-buttons-content").click(function(){
                $(".share-buttons-content").toggleClass('show_hide_share_text');
                // $(".share-buttons-icons").addClass('show_social_share');
                $(".share-buttons-icons").toggleClass('show_social_share');
            });
            $(".share-buttons-trigger").click(function(){
                $(".share-buttons-content").toggleClass('show_hide_share_text');
                // $(".share-buttons-icons").addClass('show_social_share');
                $(".share-buttons-icons").toggleClass('show_social_share');
            });
            window.scrollTo(0,0);
            //document.title = "EL Connect - Jobseeker - Job Detail";
            // this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');

            this.getjobsDataById();
            this.getQRData();
            this.getlinkdata();

		    // this.similarJobs(this.category_id);
            $('#copy-url').val(process.env.VUE_APP_URL + "job-daily-paid?jobId=" + btoa(this.id) +"&company_name="+this.alias + "&job_alias="+this.job_alias);
           this.jobUrl =  process.env.VUE_APP_URL + "job-daily-paid?jobId=" + btoa(this.id)  +"&company_name="+this.alias;
           this.user_type_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.user_type_id : '');
           this.jobViewCount();

        },
        components: {
        VueSlickCarousel,
        Carousel,
        VueSocialSharing,
        Slide
    }
    }
</script>


