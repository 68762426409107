<template>
    <div>

         <div class="row">
            <div class="container my_new_container">
        <!-- Dashboard Headline -->
        <div class="dashboard-headline">
            <h1>Daily Deployment</h1>
            <!-- Breadcrumbs -->
            <nav id="breadcrumbs" class="dark">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>Daily Deployment</li>
                </ul>
            </nav>
        </div>
        <b-tabs class="my_tab_job tab_header_action_th daily_deployment_tabs jobseeker_tab ">

   <!-- TOTAL
    ================================================== -->
            <b-tab :title="'TOTAL'" @click="changeTabTotal">
              <div class="container new_cnt_pdng my_new_container">
                    <div class="row">
                        <div class="big_screen_calendr_font search_common_margin_bottom_28 mobile_tab_max_width_flex  select-service-box date-picker-field clearfix col-xl-6 col-md-6 calender_z_index_9 calender_in_mobile  new_calender_section">
                            <!-- <div class="search-box custom_search_admin desktop_margin_top_zero"> -->
                                <!-- <b-input-group> -->
                                    <!-- <br/> -->
                                    <div>
                                        <!-- <label class="new_style_label required_sign required desktop_margin_bottom_zero">Date Range</label>                                 -->
                                        <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">
                                                <date-range-picker class="daily_deployment_date_range manage_job_date_claneder common_date_range_picker_new transactions_jb_calender common_width_100" ref="picker" :minDate="minDate" :opens="center" :locale-data="locale" :auto-apply="auto" v-model="dateRange" :ranges='range' @update="changeTabTotal()">
                                                <div slot="input" slot-scope="picker">
                                                    {{ picker.startDate | datePicker }} - {{ picker.endDate | datePicker }}
                                                </div>
                                            </date-range-picker>
                                        </b-form-group>
                                    </div>
                                    <!-- </b-input-group> -->
                            <!-- </div> -->
                        </div>
                        <div class="col-xl-6 col-md-6 mobile_tab_max_width_flex search_common_margin_bottom_28">
                            <div class="sidebar-widget job_list_btn_search mobile_space_between penalty_btn desktop_margin_bottom_zero food_btn">
                                <!-- <b-button @click="changeTabTotal()" class="button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero desktop_margin_right_zero" style="width: 120px;">Search</b-button> -->
                                <b-button v-if="(filter || searchKeywordBreakdown || (dateRange.startDate != start_date_filter || dateRange.endDate != end_date_filter))" @click="clearFilterTotal()" class="ml-3 new_reset_text  mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0" style="background-color: red; margin-top: 20px;"><span class="mobile_reset">Reset filters</span></b-button>
                                <!-- <b-button @click="clearFilterTotal()" class="button ripple-effect search_all_red_btn  filter_btn_widht_120 desktop_margin_right_zero" style="background-color: red;">Reset Filter</b-button> -->
                                <b-button class="button ripple-effect add_btn_admin_e  desktop_margin_right_zero desktop_margin_left_zero" v-if="show('export')" @click="exportTotal()">Export</b-button>
                            </div>
                        </div>
                    </div>
              </div>
              <div class="row">
                  <div class="col-xl-6">
                      <div v-if="items && items.length > 0 && total_uploaded >= 0" class="uploaded_count total_deployed_heading"><h1><b>Total Deployed: {{total_uploaded}}</b></h1></div>
                  <div class="container my_new_container show_filter_arrow">
                      <b-table ref="datatableTotal" show-empty striped hover responsive :items="getTotal" :fields="fieldsTotal" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="margin_bottom_table desktop_margin_top_zero new_box_tbl_mrgn   mobile_min_width_1000 el_coin_four_grid_table  ">
                          <template #cell(start_date)="items">
                              {{items.item.date | momentDate}}
                          </template>
                          <template #cell(fl_count)="items">
                              {{items.item.fl_count}}
                          </template>
                          <template #cell(fulltime_count)="items">
                              {{items.item.fulltime_count}}
                          </template>
                          <template #cell(total_count)="items">
                              {{items.item.total_count}}
                          </template>

                      </b-table>

                  </div>
                  </div>
                      <div class="col-xl-6">
                      <div class="container my_new_container">
                          <h4><b>Statistics</b></h4>
                          <canvas ref="lineChart" class="canvas_daily_deployment"></canvas>
                      </div>
                  </div>
              </div>

            </b-tab>

     <!-- BREAKDOWN
    ================================================== -->
            <b-tab :title="'BREAKDOWN'" @click="changeTabBreakdown" v-if="this.designation != 'external agent'">
                <div class="container new_cnt_pdng my_new_container">
                    <div class="row">
                        <div class="col-xl-3 col-md-3 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                            <div class="search-box custom_search_admin desktop_margin_top_zero">
                                <!-- <b-input-group> -->
                                    <!-- <br/> -->
                                    <b-input-group-prepend class="common_bg_white">
                                        <b-form-input id="searchBtn" :value="searchKeywordBreakdown" @change="v => searchKeywordBreakdown = v" @keyup.enter="getBreakdown" placeholder="Search by keyword" class="common_bg_white"></b-form-input>
                                        <!-- <b-form-input id="searchBtn" @keyup.enter.native="getBreakdown" v-model="searchKeywordBreakdown" placeholder="Search" class="common_bg_white"></b-form-input> -->

                                        <b-button class="btn points_events"><i class="fa fa-search common_bg_white" @click="getBreakdown"></i></b-button>
                                    </b-input-group-prepend>
                                    <!-- </b-input-group> -->
                            </div>
                        </div>

                        <div class="col-xl-3 col-md-3 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                            <div class="search-box custom_search_admin desktop_margin_top_zero">
                                <!-- <b-input-group> -->
                                    <!-- <br/> -->
                                        <b-form-select id="selectServices" :plain="true" v-model="company_id" class="common_border_search remove_box_shadow desktop_margin_bottom_zero" @change="getBreakdown()">
                                            <option value="">Company</option>
                                            <option :value="(company.id)" v-for="company in allStatusCompanies" :key="company.id">{{company.name}}</option>
                                        </b-form-select>
                                    <!-- </b-input-group> -->
                            </div>
                        </div>
                        <div class="col-xl-3 col-md-3 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                            <div class="search-box custom_search_admin desktop_margin_top_zero">
                                <!-- <b-input-group> -->
                                    <!-- <br/> -->
                                    <!-- <label class="new_style_label required_sign required desktop_margin_bottom_zero">Upload Date</label>  -->
                                    <b-form-group id="input-group-6" class="desktop_margin_bottom_zero common_date_range_picker_new">
                                        <!-- <date-range-picker class="common_date_range_picker_new  web_bottom_zero transactions_jb_calender calender_div_job_in_job" ref="picker" :minDate="minDate" :maxDate="maxDate" :opens="center" :locale-data="locale" :auto-apply="auto" v-model="dateRangeBreakdown" :ranges='range' @update="setMaxDate()">
                                            <div slot="input" slot-scope="picker">
                                                {{ picker.startDate | datePicker }} - {{ picker.endDate | datePicker }}
                                            </div>
                                        </date-range-picker>  -->
                                        <datepicker v-model="startDateBreakdown" placeholder="Start Date" @input="getBreakdown" class="add_calender_icon daily_deployment_icon common_normal_calender_height "></datepicker>

                                    </b-form-group>
                                    <!-- </b-input-group> -->
                            </div>
                        </div>
                        <div class="col-xl-3 col-md-3 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                            <div class="search-box custom_search_admin desktop_margin_top_zero">
                                <!-- <b-input-group> -->
                                    <!-- <br/> -->
                                    <!-- <label class="new_style_label required_sign required desktop_margin_bottom_zero">Upload Date</label>  -->
                                    <b-form-group id="input-group-6" class="desktop_margin_bottom_zero common_date_range_picker_new">
                                        <!-- <date-range-picker class="common_date_range_picker_new  web_bottom_zero transactions_jb_calender calender_div_job_in_job" ref="picker" :minDate="minDate" :maxDate="maxDate" :opens="center" :locale-data="locale" :auto-apply="auto" v-model="dateRangeBreakdown" :ranges='range' @update="setMaxDate()">
                                            <div slot="input" slot-scope="picker">
                                                {{ picker.startDate | datePicker }} - {{ picker.endDate | datePicker }}
                                            </div>
                                        </date-range-picker>  -->

                                    <datepicker v-model="endDateBreakdown" @input="getBreakdown" :disabled-dates="disabledDatesBreakdown()" placeholder="End Date" class="add_calender_icon daily_deployment_icon common_normal_calender_height "></datepicker>
                                    </b-form-group>
                                    <!-- </b-input-group> -->
                            </div>
                        </div>
                        <div class="col-xl-12 col-md-12 col-12 mobile_tab_max_width_flex search_common_margin_bottom_28 deployment_mobile_btn_div">
                            <div class="sidebar-widget job_list_btn_search mobile_space_between penalty_btn desktop_margin_bottom_zero food_btn">

                                <!-- Reset Filters button - now placed first -->
                                <b-button
                                v-if="(filterBreakdown || searchKeywordBreakdown || company_id || (startDateBreakdown != start_date_filter || endDateBreakdown != end_date_filter))"
                                @click="clearFilterBreakdown()"
                                class="ml-3 new_reset_text mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0"
                                style="background-color: red; margin-top: 20px;"
                                >
                                <span class="mobile_reset">Reset filters</span>
                                </b-button>

                                <!-- Search button -->
                                <b-button
                                @click="getBreakdown()"
                                class="button search_btn_all add_btn_admin_e ripple-effect mr-3 ml-3"
                                style="width: 120px;"
                                >
                                Search
                                </b-button>

                                <!-- Export button -->
                                <b-button
                                class="button search_btn_all add_btn_admin_e ripple-effect mr-3 ml-3"
                                v-if="show('export')"
                                @click="exportBreakdown()"
                                >
                                Export
                                </b-button>

                            </div>
                        </div>
                    </div>
                </div>

    <!-- Page Content
    ================================================== -->
    <div class="row">
        <div class="container my_new_container show_filter_arrow">
            <b-table
                    ref="datatableBreakdown"
                    show-empty
                    striped
                    hover
                    responsive
                    :items="itemsBreakdown"
                    :fields="fieldsBreakdownDynamic"
                        :tbody-tr-class="(item, index) => itemsBreakdown && index === itemsBreakdown.length - 1 ? 'font-weight-bold' : ''"
                    class="desktop_margin_top_zero new_box_tbl_mrgn mobile_min_width_1500 eight_grid_tbl_new breakdown_table">
                    <template #cell(company_name)="items">
                        <span v-if="items.item.company_name != 'Total Deployed'">
                            <div class="whole_div_img_and_name">
                                <div class="manage_user_img">
                                    <b-img v-if="items.item.profile_image || items.item.thumbnail" :src="items.item.thumbnail != null ?  items.item.thumbnail : items.item.profile_image ? items.item.profile_image : webUrl+'assets/images/user-avatar-small-01.png'" alt="User Icon" height="100px" width="100px" class="">
                                    </b-img>
                                    <b-img v-else :src="webUrl+'assets/images/user-avatar-small-01.png'" alt="User Icon" height="100px" width="100px" class="">
                                    </b-img>
                                </div>
                                <div class="user_name_div">
                                    <b-link :to="showEmployer('view profile') ? {name: 'employer-profile-view', params: {id: encrypt(items.item.user_id),company_id: encrypt(items.item.com_id)}} : null" target="_blank" style="color: #4c8bf5;">
                                    <p>{{items.item.company_name | capitalize}}</p>
                                    </b-link>
                                    <p class="grey-text">
                                        {{items.item.email}}
                                    </p>
                                </div>
                            </div>
                        </span>
                        <span v-else>
                        <h3><b>{{items.item.email}}</b></h3>
                        </span>
                    </template>
                </b-table>
            <!-- <b-table ref="datatableBreakdown" show-empty striped hover responsive :items="getBreakdown" :fields="fieldsBreakdown" :current-page="currentPageBreakdown" :per-page="perPage" :filter="filterBreakdown" :sort-by.sync="sortByBreakdown" :sort-desc.sync="sortDescBreakdown" :sort-direction="sortDirectionBreakdown" class="desktop_margin_top_zero new_box_tbl_mrgn   mobile_min_width_1000 nine_grid_tbl_new ">
                <template #cell(name)="items">
                    <b-link :to="showEmployer('view profile') ? {name: 'employer-profile-view', params: {id: encrypt(items.item.id),company_id: encrypt(items.item.com_id)}} : null" target="_blank" style="color: #4c8bf5;">
                        {{items.item.name | capitalize}}
                    </b-link>
                    <p class="grey-text">
                        {{items.item.email}}
                    </p>
                </template>
            </b-table> -->
            <nav class="pagination-box custom_pagination_border_radius">
                <div class="page-row-box d-inline-block">
                    <b-form-group id="input-group-4">
                            <span class="d-inline-block">Rows per page</span>
                        <span class="d-inline-block"><b-form-select v-model="form.rowsPerPageBreakdown" @input='getBreakdown'>
                                    <option>25</option>
                                    <option>50</option>
                                    <option>75</option>
                                    <option>100</option>
                        </b-form-select></span>
                    </b-form-group>
                </div>
                <div class="total-page-count d-inline-block">
                    <ul>
                        <li>
                            <span v-if='totalRowsBreakdown != 0'>{{fromBreakdown}} - {{toBreakdown}} of {{totalRowsBreakdown}}</span>
                        </li>
                    </ul>
                </div>
                <b-pagination @input='getBreakdown' :total-rows="totalRowsBreakdown"  :per-page="perPageBreakdown" v-model="currentPageBreakdown" prev-text="" next-text="" hide-goto-end-buttons />
            </nav>
        </div>
    </div>
            </b-tab>

    <!-- UPCOMING I
    ================================================== -->
            <b-tab :title="'UPCOMING JOBS'" @click="changeTabUpcoming">
                <div class="container new_cnt_pdng my_new_container">
                    <div class="row">
                        <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                            <div class="search-box custom_search_admin desktop_margin_top_zero">
                                    <b-input-group-prepend class="common_bg_white">
                                        <!-- <b-form-input id="searchBtn" @keyup.enter.native="filterUpcoming = searchKeywordUpcoming" v-model="searchKeywordUpcoming" placeholder="Search" class="common_bg_white"></b-form-input> -->
                                        <!-- <b-button class="btn points_events"><i class="fa fa-search common_bg_white" @click="filterUpcoming = searchKeywordUpcoming"></i></b-button> -->
                                    <b-form-input id="searchBtn" :value="searchKeywordUpcoming" @change="v => searchKeywordUpcoming = v" @keyup.enter="getUpcoming" placeholder="Search by keyword" class="common_bg_white"></b-form-input>
                                        <b-button class="btn points_events"><i class="fa fa-search common_bg_white" @click="getUpcoming"></i></b-button>
                                    </b-input-group-prepend>
                            </div>
                        </div>
                            <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                            <div class="search-box custom_search_admin desktop_margin_top_zero">
                                <!-- <b-input-group> -->
                                    <!-- <br/> -->
                                        <b-form-select id="selectServices" :plain="true" v-model="company_id" @change="getUpcoming" class="common_border_search remove_box_shadow desktop_margin_bottom_zero">
                                            <option value="">Company</option>
                                            <option :value="(company.id)" v-for="company in allStatusCompanies" :key="company.id">{{company.name}}</option>
                                        </b-form-select>
                                    <!-- </b-input-group> -->
                            </div>
                        </div>
                            <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                            <div class="search-box custom_search_admin desktop_margin_top_zero">
                                <b-form-select id="selectServices" :plain="true" v-model="service_category_id" @change="getUpcoming" class="common_border_search remove_box_shadow desktop_margin_bottom_zero">
                                    <option value="">Service Category</option>
                                    <option :value="(service_category.id)" v-for="service_category in allServiceCategory" :key="service_category.id">{{service_category.name | capitalize}}</option>
                                </b-form-select>
                            </div>
                        </div>
                            <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                            <div class="search-box custom_search_admin desktop_margin_top_zero">
                                <b-form-select id="selectServices" :plain="true" v-model="closed_by" @change="getUpcoming" class="common_border_search remove_box_shadow desktop_margin_bottom_zero">
                                    <option value="">Closed By</option>
                                    <option :value="(closed_by.id)" v-for="closed_by in allClosedBy" :key="closed_by.id">{{closed_by.last_name | capitalize}} {{closed_by.first_name | capitalize}}</option>
                                </b-form-select>
                            </div>
                        </div>
                            <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                            <div class="search-box custom_search_admin desktop_margin_top_zero">
                                <!-- <b-input-group> -->
                                    <!-- <br/> -->
                                    <!-- <label class="new_style_label required_sign required desktop_margin_bottom_zero">Upload Date</label>  -->
                                    <b-form-group id="input-group-6" class="desktop_margin_bottom_zero common_date_range_picker_new">
                                        <date-range-picker class="common_date_range_picker_new  web_bottom_zero transactions_jb_calender calender_div_job_in_job" ref="picker" :minDate="minDateUpcoming" :opens="center" :locale-data="locale" :auto-apply="auto" v-model="dateRangeUpcoming" :ranges='range' @update="getUpcoming" >
                                            <div slot="input" slot-scope="picker">
                                                {{ picker.startDate | datePicker }} - {{ picker.endDate | datePicker }}
                                            </div>
                                        </date-range-picker>
                                    </b-form-group>
                                    <!-- </b-input-group> -->
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                            <div class="search-box custom_search_admin desktop_margin_top_zero">
                                <b-form-group id="input-group-8" class="desktop_margin_bottom_zero multiselct_z_index">
                                            <multiselect v-model="job_start_time" label="start_time" track-by="start_time" placeholder="Type to search start time" open-direction="bottom" :multiple="true" :options="selectedTime"  :searchable="true" :clear-on-select="false" :close-on-select="false"
                                            :allow-empty="true"  @close="dateFilterFunction()" class="common_full_height_filter_multiselect common_black_multiselect_border job_list_multi_select model_multi_select admin_multi_select">
                                                <span slot="noResult">No Start Time Found.</span>
                                            </multiselect>
                                        </b-form-group>
                            </div>
                        </div>
                        <div class="col-xl-12 col-md-12 col-12 mobile_tab_max_width_flex search_common_margin_bottom_28 deployment_mobile_btn_div">
                            <div class="sidebar-widget job_list_btn_search mobile_space_between penalty_btn desktop_margin_bottom_zero food_btn">

                                <!-- Reset Filters button - now placed first -->
                                <b-button
                                v-if="(filterUpcoming || searchKeywordUpcoming || company_id || (startDateUpcoming != start_date_filter || endDateUpcoming != end_date_filter))"
                                @click="clearFilterUpcoming()"
                                class="ml-3 new_reset_text mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0"
                                style="background-color: red; margin-top: 20px;"
                                >
                                <span class="mobile_reset">Reset filters</span>
                                </b-button>

                                <!-- Search button -->
                                <b-button
                                @click="getUpcoming()"
                                class="button search_btn_all add_btn_admin_e ripple-effect mr-3 ml-3"
                                style="width: 120px;"
                                >
                                Search
                                </b-button>

                                <!-- Export button -->
                                <b-button
                                class="button search_btn_all add_btn_admin_e ripple-effect mr-3 ml-3"
                                v-if="show('export')"
                                @click="exportUpcoming()"
                                >
                                Export
                                </b-button>

                            </div>
                        </div>
                    </div>
                </div>

    <!-- Page Content
    ================================================== -->
    <div class="row">
        <div class="container my_new_container show_filter_arrow" v-if="itemsUpcoming && itemsUpcoming.length > 0">
        <div>
            <b-container class="single_accordian daily_deployment_accordian">
                <div class="faq-content-box clearfix">
                    <div class="accordion-listing-box">
                        <b-card v-for="(item, index) in itemsUpcoming" :key="index">
                            <b-card-header header-tag="header" role="tab">
                                <b-button v-b-toggle="'collapse-' + index" variant="info" class="m-1">{{ item.date }}</b-button>
                            </b-card-header>
                            <!-- Collapsible content for jobs -->
                            <b-collapse :id="'collapse-' + index">
                                <!-- Iterate over categories -->
                                <b-row v-for="(jobs, category,indexJobs) in item.jobs" :key="category">
                                <b-col cols="12">
                                    <h3 class="cat_heading_deployment"><b>{{ category }}</b></h3>
                                    <!-- Nested table to display job details -->
                                    <div class="table-responsive mobile_min_width_1500">
                                        <!-- <b-table :items="jobs" :key="keyValueDD" :fields="fieldsUpcoming" class="daily_deployment_tbl  table-striped table-hover eight_grid_tbl_new"> -->
                                        <b-table :items="jobs" :fields="fieldsUpcoming" class="daily_deployment_tbl  table-striped table-hover eight_grid_tbl_new">

                                    <!-- <b-table ref="datatableUpcoming" show-empty striped hover responsive :items="jobs" :fields="fieldsUpcoming" :current-page="currentPageUpcoming" :per-page="perPageUpcoming" :filter="filterUpcoming" :sort-by.sync="sortByUpcoming" :sort-desc.sync="sortDescUpcoming" :sort-direction="sortDirectionUpcoming" class="desktop_margin_top_zero new_box_tbl_mrgn   mobile_min_width_1000 nine_grid_tbl_new "> -->

                                        <!-- Job details -->
                                        <template #cell(title)="job">
                                                <span v-if="job.item.job_data.status == 'completed'"><a :href='showManageDPJobs("view") ? webUrlV1Admin +"/admin/jobs/view-jobsDetail-completed/" + encrypt(job.item.job_data.id)+ "/"+job.item.job_data.status+"/null/null/null" : null'
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    style="color: #4c8bf5;"
                                                    >
                                                <div class="name-td-box">
                                                    <div class="whole_div_img_and_name">
                                                        <div class="manage_user_img">
                                                            <b-img :src="job.item.job_data.thumbnail != null ? job.item.job_data.thumbnail : (job.item.job_data != null ? job.item.job_data.company_logo: webUrl+'/images/company-logo-placeholders.png')" alt="User Icon">
                                                            </b-img>
                                                        </div>
                                                        <div class="user_name_div">
                                                            <p>{{job.item.job_data.title | capitalize}}({{job.item.job_data.job_unique_id}})</p>
                                                                <p class="grey-text word-break company_name_daily_deployment">
                                                                    {{job.item.job_data.job_company_name | capitalize}}
                                                                </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                </a>
                                                </span>
                                                <span v-else-if="job.item.job_data.status == 'in-progress'"><a :href='showManageDPJobs("view") ? webUrlV1Admin +"/admin/jobs/view-jobsDetail-inprogress/" + encrypt(job.item.job_data.id)+ "/"+job.item.job_data.status+"/null/null/null" : null'
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    style="color: #4c8bf5;"
                                                    >
                                                <div class="name-td-box">
                                                    <div class="whole_div_img_and_name">
                                                        <div class="manage_user_img">
                                                            <b-img :src="job.item.job_data.thumbnail != null ? job.item.job_data.thumbnail : (job.item.job_data != null ? job.item.job_data.company_logo: webUrl+'/images/company-logo-placeholders.png')" alt="User Icon">
                                                            </b-img>
                                                        </div>
                                                        <div class="user_name_div">
                                                            <p>{{job.item.job_data.title | capitalize}}({{job.item.job_data.job_unique_id}})</p>
                                                                <p class="grey-text word-break company_name_daily_deployment">
                                                                    {{job.item.job_data.job_company_name | capitalize}}
                                                                </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                </a>
                                                </span>
                                                <span v-else><a :href='showManageDPJobs("view") ? webUrlV1Admin +"/admin/jobs/view-jobsDetail-upcoming/" + encrypt(job.item.job_data.id)+ "/"+job.item.job_data.status+"/null/null/null" : null'
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    style="color: #4c8bf5;"
                                                    >
                                                <div class="name-td-box">
                                                    <div class="whole_div_img_and_name">
                                                        <div class="manage_user_img">
                                                            <b-img :src="job.item.job_data.thumbnail != null ? job.item.job_data.thumbnail : (job.item.job_data != null ? job.item.job_data.company_logo: webUrl+'/images/company-logo-placeholders.png')" alt="User Icon">
                                                            </b-img>
                                                        </div>
                                                        <div class="user_name_div">
                                                            <p>{{job.item.job_data.title | capitalize}}({{job.item.job_data.job_unique_id}})</p>
                                                                <p class="grey-text word-break company_name_daily_deployment">
                                                                    {{job.item.job_data.job_company_name | capitalize}}
                                                                </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                </a>
                                                </span>
                                        </template>
                                        <template #cell(start_date)="job">
                                            <!-- {{totalJobsCategoryCountsUpcoming[item.date].totalJobsCategory}}{{indexJobs}}{{'dd'}}{{keyValueDD}} -->
                                            {{job.item.job_data.date | momentDate}} {{job.item.job_data.start_time }}
                                        </template>
                                            <template #cell(total_vacancies)="job">
                                            {{job.item.job_data.true_vacancies}}
                                        </template>
                                        <template #cell(job_upcoming_count)="job">
                                            {{job.item.job_data.job_upcoming_count}}
                                        </template>
                                        <template #cell(selected_candidates_data_count)="job">
                                            {{job.item.job_data.selected_candidates_data_count}}
                                        </template>
                                        <template #cell(total_applicants_cancelled_by_company)="job">
                                            {{job.item.job_data.total_applicants_cancelled_by_company}}
                                        </template>

                                        <template #cell(total_applicants_cancelled_by_freelancer)="job">
                                            {{job.item.job_data.total_applicants_cancelled_by_freelancer}}
                                        </template>
                                        <template #cell(total_applicants_withdraw_system_count)="job">
                                            {{job.item.job_data.total_applicants_withdraw_system_count}}
                                        </template>
                                        <!-- Add other templates for job details as needed -->
                                        <template v-slot:custom-foot v-if="totalJobsCategoryCountsUpcoming && (totalJobsCategoryCountsUpcoming[item.date].totalJobsCategory == indexJobs + 1)">
                                             <tr v-if="totalCountsUpcoming && totalCountsUpcoming[item.date]">
                                                <td></td>
                                                <td><h3 class="total-count"><b>Total</b></h3></td>
                                                <td v-for="(count, key) in totalCountsUpcoming[item.date]" :key="key">
                                                    <h3 class="total-count"><b>{{ count }}</b></h3>
                                                </td>
                                            </tr>
                                        </template>
                                        </b-table>
                                    </div>
                                </b-col>
                                </b-row>
                                <!-- <div class="last_row_table table-responsive">
                                    <table class="table b-table table-striped table-hover">
                                        <tr>
                                            <td></td>
                                            <td><span class="total-count"><b>Total</b></span></td>
                                            <td v-for="(count, key) in totalCountsUpcoming[item.date]" :key="key">
                                                <span class="total-count"><b>{{ count }}</b></span>
                                            </td>
                                        </tr>
                                    </table>
                                </div> -->
                            </b-collapse>

                        </b-card>
                    </div>
                </div>
            </b-container>
        </div>
        <nav class="pagination-box custom_pagination_border_radius">
            <div class="page-row-box d-inline-block">
                <b-form-group id="input-group-4">
                        <span class="d-inline-block">Rows per page</span>
                    <span class="d-inline-block"><b-form-select v-model="form.rowsPerPageUpcoming" @input='getUpcoming'>
                                <option>25</option>
                                <option>50</option>
                                <option>75</option>
                                <option>100</option>
                    </b-form-select></span>
                </b-form-group>
            </div>
            <div class="total-page-count d-inline-block">
                <ul>
                    <li>
                        <span v-if='totalRowsUpcoming != 0'>{{fromUpcoming}} - {{toUpcoming}} of {{totalRowsUpcoming}}</span>
                    </li>
                </ul>
            </div>
            <b-pagination @input='getUpcoming' :total-rows="totalRowsUpcoming"  :per-page="perPageUpcoming" v-model="currentPageUpcoming" prev-text="" next-text="" hide-goto-end-buttons />
        </nav>
    </div>
        <div class="container my_new_container show_filter_arrow" v-else>
            <div class="table-responsive">
                <table class="table b-table table-striped">
                    <tbody>
                        <tr class="b-table-empty-row">
                            <td>
                                <div>
                                    <div class="text-center my-2">
                                        There are no records to show
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
            </b-tab>

    <!-- UPCOMING II
    ================================================== -->
            <b-tab :title="'UPCOMING STATS'" @click="changeTabUpcomingV1">
                <div class="container new_cnt_pdng my_new_container">
                    <div class="row">
                        <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                            <div class="search-box custom_search_admin desktop_margin_top_zero">
                                    <b-input-group-prepend class="common_bg_white">
                                    <b-form-input id="searchBtn" :value="searchKeywordUpcomingV1" @change="v => searchKeywordUpcomingV1 = v" @keyup.enter="searchUpcomingV1" placeholder="Search by keyword" class="common_bg_white"></b-form-input>
                                        <b-button class="btn points_events"><i class="fa fa-search common_bg_white" @click="searchUpcomingV1"></i></b-button>
                                    </b-input-group-prepend>
                            </div>
                        </div>
                            <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                            <div class="search-box custom_search_admin desktop_margin_top_zero">
                                <b-form-select id="selectServices" :plain="true" v-model="company_id_upcoming_v1" @change="searchUpcomingV1" class="common_border_search remove_box_shadow desktop_margin_bottom_zero">
                                    <option value="all">Company</option>
                                    <option :value="(company.id)" v-for="company in allStatusCompanies" :key="company.id">{{company.name}}</option>
                                </b-form-select>
                            </div>
                        </div>

                        <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                            <div class="search-box custom_search_admin desktop_margin_top_zero">
                                <b-form-select id="selectServices" :plain="true" v-model="closed_by_upcoming_v1" @change="searchUpcomingV1" class="common_border_search remove_box_shadow desktop_margin_bottom_zero">
                                    <option value="all">Closed By</option>
                                    <option :value="(closed_by.id)" v-for="closed_by in allClosedBy" :key="closed_by.id">{{closed_by.last_name | capitalize}} {{closed_by.first_name | capitalize}}</option>
                                </b-form-select>
                            </div>
                        </div>
                            <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                            <div class="search-box custom_search_admin desktop_margin_top_zero">
                                <!-- <b-input-group> -->
                                    <!-- <br/> -->
                                    <!-- <label class="new_style_label required_sign required desktop_margin_bottom_zero">Upload Date</label>  -->
                                    <b-form-group id="input-group-6" class="desktop_margin_bottom_zero common_date_range_picker_new">
                                        <date-range-picker class="common_date_range_picker_new  web_bottom_zero transactions_jb_calender calender_div_job_in_job" ref="picker" :minDate="minDateUpcomingV1" :opens="center" :locale-data="locale" :auto-apply="auto" v-model="dateRangeUpcomingV1" :ranges='range' @update='searchUpcomingV1'>
                                            <div slot="input" slot-scope="picker">
                                                {{ picker.startDate | datePicker }} - {{ picker.endDate | datePicker }}
                                            </div>
                                        </date-range-picker>
                                    </b-form-group>
                                    <!-- </b-input-group> -->
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                            <div class="search-box custom_search_admin desktop_margin_top_zero">
                                <b-form-group id="input-group-8" class="desktop_margin_bottom_zero multiselct_z_index">
                                            <multiselect v-model="job_start_time_upcoming_v1" @change="searchUpcomingV1"  label="start_time" track-by="start_time" placeholder="Type to search start time" open-direction="bottom" :multiple="true" :options="selectedTimeUpcomingV1"  :searchable="true" :clear-on-select="false" :close-on-select="false"
                                            :allow-empty="true"  @close="dateFilterFunction()" class="common_full_height_filter_multiselect common_black_multiselect_border job_list_multi_select model_multi_select admin_multi_select">
                                                <span slot="noResult">No Start Time Found.</span>
                                            </multiselect>
                                        </b-form-group>
                            </div>
                        </div>
                        <div class="col-xl-12 col-md-12 col-12 mobile_tab_max_width_flex search_common_margin_bottom_28 deployment_mobile_btn_div">
                            <div class="sidebar-widget job_list_btn_search mobile_space_between penalty_btn desktop_margin_bottom_zero food_btn">

                                <!-- Reset Filters button - now placed first -->
                                <b-button
                                v-if="(filterUpcomingV1 || searchKeywordUpcomingV1 || company_id || (startDateUpcomingV1 != start_date_filter || endDateUpcomingV1 != end_date_filter))"
                                @click="clearFilterUpcomingV1()"
                                class="ml-3 new_reset_text mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0"
                                style="background-color: red; margin-top: 20px;"
                                >
                                <span class="mobile_reset">Reset filters</span>
                                </b-button>

                                <!-- Search button -->
                                <b-button
                                @click="getUpcomingV1()"
                                class="button search_btn_all add_btn_admin_e ripple-effect mr-3 ml-3"
                                style="width: 120px;"
                                >
                                Search
                                </b-button>

                                <!-- Export button -->
                                <b-button
                                class="button search_btn_all add_btn_admin_e ripple-effect mr-3 ml-3"
                                v-if="show('export')"
                                @click="exportUpcomingV1()"
                                >
                                Export
                                </b-button>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="container my_new_container show_filter_arrow">
                        <b-table ref="datatableUpcomingV1" show-empty striped hover responsive :items="getUpcomingV1" :fields="fieldsUpcomingV1" :current-page="currentPageUpcomingV1" :per-page="perPageUpcomingV1" :filter="filterUpcomingV1" :sort-by.sync="sortByUpcomingV1" :sort-desc.sync="sortDescUpcomingV1" :sort-direction="sortDirectionUpcomingV1" class="v1_full_table margin_bottom_table desktop_margin_top_zero new_box_tbl_mrgn mobile_min_width_1500 seven_grid_tbl">
                            <template #cell(name)="items">
                                <span v-if="items.item.status == 'completed'"><a :href='showManageDPJobs("view") ? webUrlV1Admin +"/admin/jobs/view-jobsDetail-completed/" + encrypt(items.item.id)+ "/"+items.item.status+"/null/null/null" : null'
                                    target="_blank"
                                    rel="noreferrer"
                                    style="color: #4c8bf5;"
                                    >
                                    <div class="name-td-box">
                                        <div class="whole_div_img_and_name">
                                            <div class="manage_user_img">
                                                <b-img :src="items.item.job_company.thumbnail != null ? items.item.job_company.thumbnail : (items.item.job_company != null ? items.item.job_company.company_logo: webUrl+'/images/company-logo-placeholders.png')" alt="User Icon">
                                                </b-img>
                                            </div>
                                            <div class="user_name_div">
                                                <p>{{items.item.title | capitalize}}({{items.item.job_unique_id}})</p>
                                                    <p class="grey-text word-break company_name_daily_deployment">
                                                        {{items.item.job_company.name | capitalize}}
                                                    </p>
                                            </div>
                                        </div>
                                    </div>
                                    </a>
                                </span>
                                <span v-else-if="items.item.status == 'in-progress'"><a :href='showManageDPJobs("view") ? webUrlV1Admin +"/admin/jobs/view-jobsDetail-inprogress/" + encrypt(items.item.id)+ "/"+items.item.status+"/null/null/null" : null'
                                    target="_blank"
                                    rel="noreferrer"
                                    style="color: #4c8bf5;"
                                    >
                                    <div class="name-td-box">
                                        <div class="whole_div_img_and_name">
                                            <div class="manage_user_img">
                                                <b-img :src="items.item.job_company.thumbnail != null ? items.item.job_company.thumbnail : (items.item.job_company != null ? items.item.job_company.company_logo: webUrl+'/images/company-logo-placeholders.png')" alt="User Icon">
                                                </b-img>
                                            </div>
                                            <div class="user_name_div">
                                                <p>{{items.item.title | capitalize}}({{items.item.job_unique_id}})</p>
                                                    <p class="grey-text word-break company_name_daily_deployment">
                                                        {{items.item.job_company.name | capitalize}}
                                                    </p>
                                            </div>
                                        </div>
                                    </div>
                                    </a>
                                </span>
                                <span v-else><a :href='showManageDPJobs("view") ? webUrlV1Admin +"/admin/jobs/view-jobsDetail-upcoming/" + encrypt(items.item.id)+ "/"+items.item.status+"/null/null/null" : null'
                                    target="_blank"
                                    rel="noreferrer"
                                    style="color: #4c8bf5;"
                                    >
                                    <div class="name-td-box">
                                        <div class="whole_div_img_and_name">
                                            <div class="manage_user_img">
                                                <b-img :src="items.item.job_company.thumbnail != null ? items.item.job_company.thumbnail : (items.item.job_company != null ? items.item.job_company.company_logo: webUrl+'/images/company-logo-placeholders.png')" alt="User Icon">
                                                </b-img>
                                            </div>
                                            <div class="user_name_div">
                                                <p>{{items.item.title | capitalize}}({{items.item.job_unique_id}})</p>
                                                    <p class="grey-text word-break company_name_daily_deployment">
                                                        {{items.item.job_company.name | capitalize}}
                                                    </p>
                                            </div>
                                        </div>
                                    </div>
                                    </a>
                                </span>
                            </template>
                            <template #cell(date)="items">
                                <div class="table-registered-on">
                                {{items.item.start_date | momentDate}} {{items.item.start_time }}
                                </div>
                            </template>
                            <template #cell(vacancies)="items">
                                {{items.item.true_vacancies}}
                            </template>
                            <template #cell(upcoming_count)="items">
                                {{items.item.job_upcoming_count}}
                            </template>
                            <template #cell(awarded_count)="items">
                                {{items.item.selected_candidates_data_count}}
                            </template>
                            <template #cell(withdrawn_by_self)="items">
                                {{items.item.total_applicants_withdraw_self_count}}
                            </template>

                            <template #cell(withdrawn_by_system)="items">
                                {{items.item.total_applicants_withdraw_system_count}}
                            </template>
                            <template #cell(created_at)="items">
                                <div class="table-registered-on">
                                {{dateArr | momentDate}}
                                </div>
                            </template>
                            <template v-slot:custom-foot>
                                <tr>
                                    <td></td>
                                    <td><h3 class="total-count"><b>Total</b></h3></td>
                                    <div v-for="(count, key) in counts" :key="key" class="upcoming_v1_loop_div">
                                        <td>
                                            <h3 class="total-count"><b>{{ count.vacancies }}</b></h3>
                                        </td>
                                        <td>
                                            <h3 class="total-count"><b>{{ count.pending_count }}</b></h3>
                                        </td>
                                        <td>
                                            <h3 class="total-count"><b>{{ count.awarded_count }}</b></h3>
                                        </td>
                                        <td>
                                            <h3 class="total-count"><b>{{ count.self_withdraw_count }}</b></h3>
                                        </td>
                                        <td>
                                            <h3 class="total-count"><b>{{ count.system_withdraw_count }}</b></h3>
                                        </td>
                                    </div>
                                </tr>
                            </template>
                        </b-table>
                        <!-- <b-table thead-class="d-none" show-empty striped hover responsive :items="counts" :fields="fieldsUpcomingCountV1" >
                            <template #cell(title)="items" style="font-weight: bold;font-size: larger;">
                                <div class="table-registered-on">
                                <h3><strong> Total</strong></h3>
                                </div>
                            </template>
                            <template slot="date" slot-scope="items">
                                <div class="table-registered-on">
                                <h3><strong> {{items.vacancies}}</strong></h3>
                                </div>
                            </template>
                            <template slot="date" slot-scope="items">
                                <div class="table-registered-on">
                                <h3><strong> {{items.pending_count}}</strong></h3>
                                </div>
                            </template>
                            <template slot="date" slot-scope="items">
                                <div class="table-registered-on">
                                <h3><strong> {{items.awarded_count}}</strong></h3>
                                </div>
                            </template>
                            <template slot="date" slot-scope="items">
                                <div class="table-registered-on">
                                <h3><strong> {{items.self_withdraw_count}}</strong></h3>
                                </div>
                            </template>
                            <template slot="date" slot-scope="items">
                                <div class="table-registered-on">
                                <h3><strong> {{items.system_withdraw_count}}</strong></h3>
                                </div>
                            </template>
                        </b-table> -->

                    </div>
                    <nav class="pagination-box custom_pagination_border_radius">
                        <div class="page-row-box d-inline-block">
                            <b-form-group id="input-group-4">
                                    <span class="d-inline-block">Rows per page</span>
                                <span class="d-inline-block"><b-form-select v-model="form.rowsPerPageUpcomingV1" @change='getUpcomingV1'>
                                            <option>25</option>
                                            <option>50</option>
                                            <option>75</option>
                                            <option>100</option>
                                </b-form-select></span>
                            </b-form-group>
                        </div>
                        <div class="total-page-count d-inline-block">
                            <ul>
                                <li>
                                    <span v-if='totalRowsUpcomingV1 != 0'>{{fromUpcomingV1}} - {{toUpcomingV1}} of {{totalRowsUpcomingV1}}</span>
                                </li>
                            </ul>
                        </div>
                        <b-pagination :total-rows="totalRowsUpcomingV1"  :per-page="perPageUpcomingV1" v-model="currentPageUpcomingV1" prev-text="" next-text="" hide-goto-end-buttons />
                    </nav>
                </div>
            </b-tab>

    <!-- PAST STATS
    ================================================== -->
            <b-tab :title="'PAST STATS'" @click="changeTabPast" v-if="this.designation != 'external agent'">
                <div class="container new_cnt_pdng my_new_container">
                    <div class="row">
                        <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                            <div class="search-box custom_search_admin desktop_margin_top_zero">
                                    <b-input-group-prepend class="common_bg_white">
                                        <!-- <b-form-input id="searchBtn" @keyup.enter.native="filterPast = searchKeywordPast" v-model="searchKeywordPast" placeholder="Search" class="common_bg_white"></b-form-input> -->
                                        <!-- <b-button class="btn points_events"><i class="fa fa-search common_bg_white" @click="filterPast = searchKeywordPast"></i></b-button> -->
                                    <b-form-input id="searchBtn" :value="searchKeywordPast" @change="v => searchKeywordPast = v" @keyup.enter="getPast" placeholder="Search by keyword" class="common_bg_white"></b-form-input>
                                        <b-button class="btn points_events"><i class="fa fa-search common_bg_white" @click="getPast"></i></b-button>
                                    </b-input-group-prepend>
                            </div>
                        </div>
                            <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                            <div class="search-box custom_search_admin desktop_margin_top_zero">
                                <!-- <b-input-group> -->
                                    <!-- <br/> -->
                                        <b-form-select id="selectServices" :plain="true" @change="getPast"  v-model="company_id" class="common_border_search remove_box_shadow desktop_margin_bottom_zero">
                                            <option value="">Company</option>
                                            <option :value="(company.id)" v-for="company in allStatusCompanies" :key="company.id">{{company.name}}</option>
                                        </b-form-select>
                                    <!-- </b-input-group> -->
                            </div>
                        </div>
                            <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                            <div class="search-box custom_search_admin desktop_margin_top_zero">
                                <b-form-select id="selectServices" :plain="true" v-model="service_category_id" @change="getPast" class="common_border_search remove_box_shadow desktop_margin_bottom_zero">
                                    <option value="">Service Category</option>
                                    <option :value="(service_category.id)" v-for="service_category in allServiceCategory" :key="service_category.id">{{service_category.name | capitalize}}</option>
                                </b-form-select>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                            <div class="search-box custom_search_admin desktop_margin_top_zero">
                                <b-form-select id="selectServices" :plain="true" v-model="closed_by" @change="getPast" class="common_border_search remove_box_shadow desktop_margin_bottom_zero">
                                    <option value="">Closed By</option>
                                    <option :value="(closed_by.id)" v-for="closed_by in allClosedBy" :key="closed_by.id">{{closed_by.last_name | capitalize}} {{closed_by.first_name | capitalize}}</option>
                                </b-form-select>
                            </div>
                        </div>
                            <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                            <div class="search-box custom_search_admin desktop_margin_top_zero">
                                <!-- <b-input-group> -->
                                    <!-- <br/> -->
                                    <!-- <label class="new_style_label required_sign required desktop_margin_bottom_zero">Upload Date</label>  -->
                                    <b-form-group id="input-group-6" class="desktop_margin_bottom_zero common_date_range_picker_new">
                                        <date-range-picker class="common_date_range_picker_new  web_bottom_zero transactions_jb_calender calender_div_job_in_job" @update="getPast"  ref="picker" :minDate="minDate" :maxDate="maxDatePast" :opens="center" :locale-data="locale" :auto-apply="auto" v-model="dateRangePast" :ranges='range'>
                                            <div slot="input" slot-scope="picker">
                                                {{ picker.startDate | datePicker }} - {{ picker.endDate | datePicker }}
                                            </div>
                                        </date-range-picker>
                                    </b-form-group>
                                    <!-- </b-input-group> -->
                            </div>
                        </div>



                        <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                            <div class="search-box custom_search_admin desktop_margin_top_zero">
                                <b-form-group id="input-group-8" class="desktop_margin_bottom_zero multiselct_z_index">
                                            <multiselect v-model="job_start_time" label="start_time" @change="getPast"  track-by="start_time" placeholder="Type to search start time" open-direction="bottom" :multiple="true" :options="selectedTime"  :searchable="true" :clear-on-select="false" :close-on-select="false"
                                            :allow-empty="true"  @close="dateFilterFunction()" class="common_full_height_filter_multiselect common_black_multiselect_border job_list_multi_select model_multi_select admin_multi_select">
                                                <span slot="noResult">No Start Time Found.</span>
                                            </multiselect>
                                        </b-form-group>
                            </div>
                        </div>
                        <div class="col-xl-12 col-md-12 col-12 mobile_tab_max_width_flex search_common_margin_bottom_28 deployment_mobile_btn_div">
                            <div class="sidebar-widget job_list_btn_search mobile_space_between penalty_btn desktop_margin_bottom_zero food_btn">

                                <!-- Reset Filters button - now placed first -->
                                <b-button
                                v-if="(filterPast || searchKeywordPast || company_id || (startDatePast != start_date_filter || endDatePast != end_date_filter))"
                                @click="clearFilterPast()"
                                class="ml-3 new_reset_text mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0"
                                style="background-color: red; margin-top: 20px;"
                                >
                                <span class="mobile_reset">Reset filters</span>
                                </b-button>

                                <!-- Search button -->
                                <b-button
                                @click="getPast()"
                                class="button search_btn_all add_btn_admin_e ripple-effect mr-3 ml-3"
                                style="width: 120px;"
                                >
                                Search
                                </b-button>

                                <!-- Export button -->
                                <b-button
                                class="button search_btn_all add_btn_admin_e ripple-effect mr-3 ml-3"
                                v-if="show('export')"
                                @click="exportPast()"
                                >
                                Export
                                </b-button>

                            </div>
                        </div>
                    </div>
                </div>

    <!-- Page Content
    ================================================== -->
    <div class="row">
        <div class="container my_new_container show_filter_arrow" v-if="itemsPast && itemsPast.length > 0">
        <div v-if="total_uploaded_past >= 0" class="uploaded_count total_deployed_heading"><h1><b>Total Deployed: {{total_uploaded_past}}</b></h1></div>
            <b-container class="single_accordian daily_deployment_accordian">
                <div class="faq-content-box clearfix">
                    <div class="accordion-listing-box">
                        <b-card v-for="(item, index) in itemsPast" :key="index" no-body>
                            <b-card-header header-tag="header" role="tab">
                                <b-button v-b-toggle="'collapse-' + index" variant="info" class="m-1">{{ item.date }}</b-button>
                            </b-card-header>
                            <b-collapse :id="'collapse-' + index">
                                <!-- Iterate over categories -->
                                <b-row v-for="(jobs, category,indexJobs) in item.jobs" :key="category">
                                <b-col cols="12">
                                    <h3 class="cat_heading_deployment"><b>{{ category }}</b></h3>
                                    <!-- Nested table to display job details -->
                                    <div class="table-responsive mobile_min_width_1500">
                                        <b-table :items="jobs" :fields="fieldsPast" class="daily_deployment_tbl  table-striped table-hover eight_grid_tbl_new">
                                    <!-- <b-table ref="datatablePast" show-empty striped hover responsive :items="jobs" :fields="fieldsPast" :current-page="currentPagePast" :per-page="perPagePast" :filter="filterPast" :sort-by.sync="sortByPast" :sort-desc.sync="sortDescPast" :sort-direction="sortDirectionPast" class="desktop_margin_top_zero new_box_tbl_mrgn   mobile_min_width_1000 nine_grid_tbl_new "> -->

                                        <!-- Job details -->
                                        <template #cell(title)="job">
                                                <span v-if="job.item.job_data.status == 'completed'"><a :href='showManageDPJobs("view") ? webUrlV1Admin +"/admin/jobs/view-jobsDetail-completed/" + encrypt(job.item.job_data.id)+ "/"+job.item.job_data.status+"/null/null/null" : null'
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    style="color: #4c8bf5;"
                                                    >
                                                <div class="name-td-box">
                                                    <div class="whole_div_img_and_name">
                                                        <div class="manage_user_img">
                                                            <b-img :src="job.item.job_data.thumbnail != null ? job.item.job_data.thumbnail : (job.item.job_data != null ? job.item.job_data.company_logo: webUrl+'/images/company-logo-placeholders.png')" alt="User Icon">
                                                            </b-img>
                                                        </div>
                                                        <div class="user_name_div">
                                                            <p>{{job.item.job_data.title | capitalize}}({{job.item.job_data.job_unique_id}})</p>
                                                                <p class="grey-text word-break company_name_daily_deployment">
                                                                    {{job.item.job_data.job_company_name | capitalize}}
                                                                </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                </a>
                                                </span>
                                                <span v-else-if="job.item.job_data.status == 'in-progress'"><a :href='showManageDPJobs("view") ? webUrlV1Admin +"/admin/jobs/view-jobsDetail-inprogress/" + encrypt(job.item.job_data.id)+ "/"+job.item.job_data.status+"/null/null/null" : null'
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    style="color: #4c8bf5;"
                                                    >
                                                <div class="name-td-box">
                                                    <div class="whole_div_img_and_name">
                                                        <div class="manage_user_img">
                                                            <b-img :src="job.item.job_data.thumbnail != null ? job.item.job_data.thumbnail : (job.item.job_data != null ? job.item.job_data.company_logo: webUrl+'/images/company-logo-placeholders.png')" alt="User Icon">
                                                            </b-img>
                                                        </div>
                                                        <div class="user_name_div">
                                                            <p>{{job.item.job_data.title | capitalize}}({{job.item.job_data.job_unique_id}})</p>
                                                                <p class="grey-text word-break company_name_daily_deployment">
                                                                    {{job.item.job_data.job_company_name | capitalize}}
                                                                </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                </a>
                                                </span>
                                                <span v-else><a :href='showManageDPJobs("view") ? webUrlV1Admin +"/admin/jobs/view-jobsDetail-upcoming/" + encrypt(job.item.job_data.id)+ "/"+job.item.job_data.status+"/null/null/null" : null'
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    style="color: #4c8bf5;"
                                                    >
                                                <div class="name-td-box">
                                                        <div class="whole_div_img_and_name">
                                                        <div class="manage_user_img">
                                                            <b-img :src="job.item.job_data.thumbnail != null ? job.item.job_data.thumbnail : (job.item.job_data != null ? job.item.job_data.company_logo: webUrl+'/images/company-logo-placeholders.png')" alt="User Icon">
                                                            </b-img>
                                                        </div>
                                                            <div class="user_name_div">
                                                            <p>{{job.item.job_data.title | capitalize}}({{job.item.job_data.job_unique_id}})</p>
                                                                <p class="grey-text word-break company_name_daily_deployment">
                                                                    {{job.item.job_data.job_company_name | capitalize}}
                                                                </p>
                                                            </div>
                                                        </div>
                                                </div>
                                                </a>
                                                </span>
                                        </template>
                                        <template #cell(start_date)="job">
                                            {{job.item.job_data.date | momentDate}} {{job.item.job_data.start_time }}
                                        </template>
                                            <template #cell(total_vacancies)="job">
                                            {{job.item.job_data.true_vacancies}}
                                        </template>
                                        <template #cell(job_completed_suspended_count)="job">
                                            {{job.item.job_data.job_completed_suspended_count}}
                                        </template>
                                        <template #cell(selected_candidates_data_count)="job">
                                            {{job.item.job_data.selected_candidates_data_count}}
                                        </template>
                                        <template #cell(total_applicants_cancelled_by_company)="job">
                                            {{job.item.job_data.total_applicants_cancelled_by_company}}
                                        </template>

                                        <template #cell(total_applicants_cancelled_by_freelancer)="job">
                                            {{job.item.job_data.total_applicants_cancelled_by_freelancer}}
                                        </template>
                                        <template #cell(total_applicants_withdraw_system_count)="job">
                                            {{job.item.job_data.total_applicants_withdraw_system_count}}
                                        </template>
                                        <!-- Add other templates for job details as needed -->
                                         <template v-slot:custom-foot v-if="totalJobsCategoryCountsPast && (totalJobsCategoryCountsPast[item.date].totalJobsCategory == indexJobs + 1)">
                                            <tr v-if="totalCountsPast && totalCountsPast[item.date]">
                                                <td></td>
                                                <td><h3 class="total-count"><b>Total</b></h3></td>
                                                <td v-for="(count, key) in totalCountsPast[item.date]" :key="key">
                                                    <h3 class="total-count"><b>{{ count }}</b></h3>
                                                </td>
                                            </tr>
                                         </template>
                                        </b-table>
                                    </div>
                                </b-col>
                                </b-row>
                                 <!-- <div class="last_row_table table-responsive">
                                    <table class="table b-table table-striped table-hover">
                                        <tr>
                                            <td></td>
                                            <td><span class="total-count"><b>Total</b></span></td>
                                            <td v-for="(count, key) in totalCountsPast[item.date]" :key="key">
                                                <span class="total-count"><b>{{ count }}</b></span>
                                            </td>
                                        </tr>
                                    </table>
                                </div> -->
                            </b-collapse>
                        </b-card>
                    </div>
                </div>
            </b-container>
        <div>
        </div>
        <nav class="pagination-box custom_pagination_border_radius">
            <div class="page-row-box d-inline-block">
                <b-form-group id="input-group-4">
                        <span class="d-inline-block">Rows per page</span>
                    <span class="d-inline-block"><b-form-select v-model="form.rowsPerPagePast" @input='getPast'>
                                <option>25</option>
                                <option>50</option>
                                <option>75</option>
                                <option>100</option>
                    </b-form-select></span>
                </b-form-group>
            </div>
            <div class="total-page-count d-inline-block">
                <ul>
                    <li>
                        <span v-if='totalRowsPast != 0'>{{fromPast}} - {{toPast}} of {{totalRowsPast}}</span>
                    </li>
                </ul>
            </div>
            <b-pagination @input='getPast' :total-rows="totalRowsPast"  :per-page="perPagePast" v-model="currentPagePast" prev-text="" next-text="" hide-goto-end-buttons />
        </nav>
        </div>
        <div class="container my_new_container show_filter_arrow" v-else>
            <div class="table-responsive">
            <table class="table b-table table-striped">
                <tbody>
                    <tr class="b-table-empty-row">
                        <td>
                            <div>
                                <div class="text-center my-2">
                                    There are no records to show
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>
        </div>
    </div>
            </b-tab>
        </b-tabs>

        </div>
        </div>
        <!-- Spacer -->
        <!-- <div class="margin-top-70"></div> -->
        <!-- Spacer / End-->
        <!-- Row / End -->
    </div>
</template>

<script>
import { POST_API } from '../../../store/actions.type'
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Datepicker from 'vuejs-datepicker';
import Multiselect from 'vue-multiselect'
import Chart from 'chart.js';
import permission from "../../../../../server/permission.js";

export default {

    data() {
        return {
            fields: [
                { key: 'last_name', label: 'User Name', sortable: true },
                { key: 'mobile_number', label: 'Mobile Number', sortable: true , thStyle:  {width: '13%'} },
                { key: 'certificate', label: 'Certificate Type', sortable: true },
                // { key: 'certificate_number', label: 'Certificate No.', sortable: true },
                { key: 'obtainment_date', label: 'Obtainment Date', sortable: true },
                { key: 'expiry_date', label: 'Expiry Date', sortable: true },
                { key: 'created_at', label: 'Upload Date', sortable: true },
                { key: 'status', label: 'Status', sortable: true },
                { key: 'rejected_reason', label: 'Rejected Reason', sortable: true },
                { key: 'url', label: 'Certificate', sortable: true },
            ],
            fieldsTotal: [
                { key: 'start_date', label: 'Date', sortable: true },
                { key: 'fl_count', label: 'FL Deployed', sortable: true },
                { key: 'fulltime_count', label: 'Full Time Deployed', sortable: true },
                { key: 'total_count', label: 'Total Deployed', sortable: true },

            ],

            fieldsUpcoming: [
                { key: 'title', label: 'Job Title & ID', sortable: false, thStyle:{width: '20%'}},
                { key: 'start_date', label: 'Job Date', sortable: false, thStyle:{width: '20%'}},
                { key: 'total_vacancies', label: 'True Vacancies', sortable: false, thStyle:{width: '10%'}},
                { key: 'job_upcoming_count', label: 'Pending Applicants', sortable: false, thStyle:{width: '10%'}},
                { key: 'selected_candidates_data_count', label: 'Awarded', sortable: false, thStyle:{width: '10%'}},
                { key: 'total_applicants_cancelled_by_company', label: 'Cancelled By Company', sortable: false, thStyle:{width: '10%'}},
                { key: 'total_applicants_cancelled_by_freelancer', label: 'Cancelled By Freelancer', sortable: false, thStyle:{width: '10%'}},
                { key: 'total_applicants_withdraw_system_count', label: 'Withdrawn By System', sortable: false, thStyle:{width: '10%'}},
            ],
            fieldsUpcomingV1: [
                { key: 'name', label: 'Job Title', sortable: true , thStyle:  {width: '10%'} },
                { key: 'date', label: 'Job Date', sortable: true , thStyle:  {width: '10%'} },
                { key: 'vacancies', label: 'True Vacancies', sortable: true , thStyle:  {width: '7%'} },
                { key: 'upcoming_count', label: 'Pending Applicants', sortable: true , thStyle:  {width: '7%'} },
                { key: 'awarded_count', label: 'Total Awarded', sortable: true , thStyle:  {width: '5%'}},
                { key: 'withdrawn_by_self', label: 'Withdrawn by Self', sortable: true , thStyle:  {width: '7%'} },
                { key: 'withdrawn_by_system', label: 'Withdrawn by System ', sortable: true , thStyle:  {width: '7%'} },
            ],
            fieldsPast: [
                { key: 'title', label: 'Job Title & ID', sortable: false, thStyle:{width: '20%'}},
                { key: 'start_date', label: 'Job Date', sortable: false, thStyle:{width: '20%'}},
                { key: 'total_vacancies', label: 'True Vacancies', sortable: false, thStyle:{width: '10%'}},
                { key: 'selected_candidates_data_count', label: 'Awarded', sortable: false, thStyle:{width: '10%'}},
                { key: 'job_completed_suspended_count', label: 'Deployed', sortable: false, thStyle:{width: '10%'}},
                { key: 'total_applicants_cancelled_by_company', label: 'Cancelled by Company', sortable: false, thStyle:{width: '10%'}},
                { key: 'total_applicants_cancelled_by_freelancer', label: 'Cancelled by Freelancer', sortable: false, thStyle:{width: '10%'}},
                { key: 'total_applicants_withdraw_system_count', label: 'Withdrawn by System', sortable: false, thStyle:{width: '10%'}},
            ],
            fieldsUpcomingCountV1: [
                { key: 'name', label: '', sortable: false , thStyle:  {width: '25%'} },
                { key: 'title', label: '', sortable: false , thStyle:  {width: '35%'}},
                { key: 'vacancies', label: '', sortable: false , thStyle:  {width: '10%'}},
                { key: 'pending_count', label: '', sortable: false , thStyle:  {width: '10%'}},
                { key: 'awarded_count', label: '', sortable: false , thStyle:  {width: '10%'}},
                { key: 'self_withdraw_count', label: '', sortable: false , thStyle:  {width: '10%'}},
                { key: 'system_withdraw_count', label: '', sortable: false , thStyle:  {width: '10%'}},

            ],

            counts:[{
                'name': '',
                'title': 'Total',
                'vacancies': 0,
                'pending_count': 0,
                'awarded_count': 0,
                'self_withdraw_count': 0,
                'system_withdraw_count': 0,

            }],

            pager: {},
            pageOfItems: [],
            minDate: null,
            maxDate:null,
            center: 'center',
            // date: moment().format('YYYY-MM-DD'),

            items:[],
            itemsGraph:[],
            filter: '',
            totalRows: 0,
            format: 'YYYY-MM-DD HH:mm',
            sortBy: null,
            sortDesc: true,
            sortDirection: 'desc',
            from: '',
            to: '',
            currentPage: 1,
            searchKeyword:'',
            perPage: 25,
            pageOptions: [25, 50, 75, 100],

            itemsBreakdown:[],
            searchKeywordBreakdown:'',
            totalRowsBreakdown: 0,
            fromBreakdown: '',
            toBreakdown: '',
            currentPageBreakdown: 1,
            perPageBreakdown: 50,
            pageOptionsBreakdown: [25, 50, 75, 100],
            sortByBreakdown: null,
            sortDirectionBreakdown: 'desc',
            filterBreakdown:'',
            sortDescBreakdown: true,

            itemsUpcoming:[],
            totalCountsUpcoming:{},
            totalJobsCategoryCountsUpcoming:'',

            searchKeywordUpcoming:'',
            totalRowsUpcoming: 0,
            fromUpcoming: '',
            toUpcoming: '',
            currentPageUpcoming: 1,
            perPageUpcoming: 50,
            pageOptionsUpcoming: [25, 50, 75, 100],
            sortByUpcoming: null,
            sortDirectionUpcoming: 'desc',
            filterUpcoming:'',
            sortDescUpcoming: false,

            itemsUpcomingV1:[],
            searchKeywordUpcomingV1:'',
            totalRowsUpcomingV1: 0,
            fromUpcomingV1: '',
            toUpcomingV1: '',
            currentPageUpcomingV1: 1,
            perPageUpcomingV1: 50,
            pageOptionsUpcomingV1: [25, 50, 75, 100],
            sortByUpcomingV1: null,
            sortDirectionUpcomingV1: 'desc',
            filterUpcomingV1:'',
            sortDescUpcomingV1: false,

            itemsPast:[],
            totalCountsPast:{},
            totalJobsCategoryCountsPast:'',
            searchKeywordPast:'',
            totalRowsPast: 0,
            fromPast: '',
            toPast: '',
            currentPagePast: 1,
            perPagePast: 50,
            pageOptionsPast: [25, 50, 75, 100],
            sortByPast: null,
            sortDirectionPast: 'desc',
            filterPast:'',
            sortDescPast: false,

            auto: true,
            range: false,
            minDateUpcoming:moment().format('YYYY-MM-DD'),
            minDateUpcomingV1:moment().format('YYYY-MM-DD'),
            maxDatePast:moment().subtract(1, 'days').format('YYYY-MM-DD'),
            dateRange: {
                startDate: '',
                endDate: ''
            },
            dateRangeBreakdown: {
                startDate: '',
                endDate: ''
            },
            dateRangeUpcoming: {
                startDate: '',
                endDate: ''
            },
            dateRangeUpcomingV1: {
                startDate: '',
                endDate: ''
            },
            dateRangePast: {
                startDate: '',
                endDate: ''
            },
            pageOptions: [25, 50, 75, 100],
            dateRange: {
                startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD')
            },
            dateRangeBreakdown: {
                startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD')
            },
            dateRangeUpcoming: {
                startDate: moment().format('YYYY-MM-DD'),
                endDate: moment().add(7, 'days').format('YYYY-MM-DD')
            },
            dateRangeUpcomingV1: {
                startDate: moment().format('YYYY-MM-DD'),
                endDate: moment().add(7, 'days').format('YYYY-MM-DD')
            },
            dateRangePast: {
                startDate: moment().subtract(8, 'days').format('YYYY-MM-DD'),
                endDate: moment().subtract(1, 'days').format('YYYY-MM-DD')
            },
            locale: {
                direction: 'ltr', //direction of text
                format: 'DD-MMM-YYYY', //fomart of the dates displayed
                separator: ' - ', //separator between the two ranges
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                /*weekLabel: 'W',
                customRangeLabel: 'Custom Range',*/
                daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
                monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
                firstDay: 0, //ISO first day of week - see moment documenations for details
                sameDateFormat: {
                    from: "DD MM YYYY, hh:mm",
                    to: "hh:mm",
                },
            },

            webUrl: process.env.VUE_APP_URL,
            webUrlV1: process.env.VUE_APP_URL_V1,

            currentTab:'total',
            totalDeployedArr:[],
            allDatesArr:[],
            form:{
                rowsPerPage:25,
                rowsPerPageBreakdown:100,
                rowsPerPageUpcoming:25,
                rowsPerPageUpcomingV1:50,
                rowsPerPagePast:25,
                status:'',
                userStatus:'all',
            },
            total_uploaded:0,
            total_uploaded_past:0,
            webServerUrl: process.env.VUE_APP_SERVER_URL,
            designation:'',
            attachedCompanies:'',
            allStatusCompanies:[],
            selectedTime:[],
            selectedTimeUpcomingV1:[],
            job_start_time:null,
            job_start_time_upcoming_v1:null,
            allCompanies:[],
            allServiceCategory:[],
            allClosedBy:[],
            company_id:'',
            service_category_id: '',
            closed_by: '',
            fieldsBreakdownDynamic:[],
            startDateBreakdown: moment().subtract(7, 'days').format('YYYY-MM-DD'),
            endDateBreakdown:moment().format('YYYY-MM-DD'),
            start_date_filter:moment().subtract(7, 'days').format('YYYY-MM-DD'),
            end_date_filter:moment().format('YYYY-MM-DD'),
            webUrlV1Admin: process.env.VUE_APP_URL_V1_Admin,

            chartData: {
                // labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                labels: [],

                datasets: [{
                label: 'Sales Data',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
                // data: [65, 59, 80, 81, 56, 55, 40]
                data: []

                }]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: true,
                legend: {
                    display: false,
                    position: 'top', // Position legend at the top
                    labels: {
                        boxWidth: 0, // Hide color box
                    }
                },
                scales: {
                yAxes: [{
                    ticks: {
                    beginAtZero: true,
                    }
                }]
                }
            },
            sum_upcoming_v1 : 0,
            company_id_upcoming_v1:'all',
            closed_by_upcoming_v1:'all',
            actions:'',
            actionsEmployer:'',
            actionsManageDPJobs:''
        }
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        customFormatterOne(date) {
            return moment(date).utc().format('DD MMM YYYY hh:mm A');
        },

        removeUnderscore: function(value) {
            if (!value) return ''
            var i, frags = value.split('_');
            for (i=0; i<frags.length; i++) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
            }
            return frags.join(' ');
        },
        date: function(date) {
            if(date){
                return moment(date).format('DD MMM YYYY');
            }else{
                return '-'
            }
        },
        datePicker: function(value) {
            if (value == null) {
                return '';
            } else {
                return moment(value).format('DD MMM YYYY')
            }
        },
        momentDate: function(date) {
            if(date != null)
              return moment(date).format('DD-MM-YYYY')
            else
              return '-';
        },
        momentTime: function(date) {
            if(date != null)
              return moment(date, "HH:mm:ss").format("hh:mm A")
            else
              return '-';
        },
    },

    components: {
            DateRangePicker,
            Datepicker,
            Multiselect,
            Chart
    },

    methods:{
        disabledDatesBreakdown() {
            if (!this.startDateBreakdown) return { to: new Date() };
            const maxEndDate = new Date(this.startDateBreakdown);
            maxEndDate.setDate(maxEndDate.getDate() + 6);
            this.endDateBreakdown = moment(maxEndDate).format('YYYY-MM-DD');
            return { from: maxEndDate,to: this.startDateBreakdown };
        },

        resetTodateBreakdown(){
            this.endDateBreakdown = "";
        },

        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                if(this.currentTab == 'total'){
                    this.getTotal().then(() => {
                        this.$refs.datatableTotal.refresh();
                    });
                }else if(this.currentTab == 'breakdown'){
                    this.getBreakdown()
                }else if(this.currentTab == 'upcoming'){
                    this.getUpcoming().then(() => {
                        this.company_id = '';
                    this.getAllCompaniesByStatus('upcoming');
                            // this.$refs.datatableUpcoming.refresh();
                        });
                }else if(this.currentTab == 'upcoming_v1'){
                    this.company_id_upcoming_v1 = 'all';
                    this.getAllCompaniesByStatus('upcoming_v1');
                    this.getUpcomingV1()
                }else{
                    this.getPast().then(() => {
                        this.company_id = '';
                this.getAllCompaniesByStatus('past');
                        // this.$refs.datatablePast.refresh();
                    });
                }
            });
        },
        dateFilterFunction()
        {
            if(this.currentTab == 'total'){
                this.getTotal().then(() => {
                    this.$refs.datatableTotal.refresh();
                });
            }else if(this.currentTab == 'breakdown'){
                this.getBreakdown()
            }else if(this.currentTab == 'upcoming'){
                this.getUpcoming().then(() => {
                this.company_id = '';
                this.getAllCompaniesByStatus('upcoming');
                    // this.$refs.datatableUpcoming.refresh();
                });
            }else if(this.currentTab == 'upcoming_v1'){
                this.getUpcomingV1().then(() => {
                this.company_id_upcoming_v1 = 'all';
                this.getAllCompaniesByStatus('upcoming_v1');
                    this.$refs.datatableUpcomingV1.refresh();
                });
            }else{
                this.getPast().then(() => {
                this.company_id = '';
                this.getAllCompaniesByStatus('past');
                    // this.$refs.datatablePast.refresh();
                });
            }
        },
        searchUpcomingV1()
        {
            this.getUpcomingV1().then(() => {
            this.getAllCompaniesByStatus('upcoming_v1');
            this.getAllClosedByByStatus();
                this.$refs.datatableUpcomingV1.refresh();
            });
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        downloadPdf(url){
          window.open(url);
        },

        changeTabTotal()
        {
            this.currentTab='total';
            this.getTotal().then(() => {
                this.$refs.datatableTotal.refresh();
            });

        },
        changeTabBreakdown()
        {
            this.currentTab='breakdown';
            this.company_id = '';
            this.getAllCompaniesByStatus('individual');
            this.getBreakdown().then(() => {
                this.$refs.datatableBreakdown.refresh();
            });
        },
        changeTabUpcoming()
        {
            this.currentTab='upcoming';
            this.company_id = '';
            this.service_category_id = '';
            this.closed_by = '';
            this.job_start_time = null;
            this.selectedTime = [];
            this.getAllCompaniesByStatus('upcoming');
            this.getUpcoming().then(() => {
                // this.$refs.datatableUpcoming.refresh();
            });
        },
        changeTabUpcomingV1()
        {
            console.log('dddd')
            this.currentTab='upcoming_v1';
            this.company_id_upcoming_v1 = 'all';
            this.closed_by_upcoming_v1 = 'all';
            this.job_start_time_upcoming_v1 = null;
            this.selectedTimeUpcomingV1 = [];
            this.getAllCompaniesByStatus('upcoming_v1');
            this.getUpcomingV1().then(() => {
                this.$refs.datatableUpcomingV1.refresh();
            });
        },
        changeTabPast()
        {
            this.currentTab='past';
            this.company_id = '';
            this.service_category_id = '';
            this.closed_by = '';
            this.job_start_time = null;
            this.selectedTime = [];
            this.getAllCompaniesByStatus('past');
            this.getPast().then(() => {
                // this.$refs.datatablePast.refresh();
            });
        },
        clearFilterTotal(){
            this.dateRange.startDate = moment().subtract(7, 'days').format('YYYY-MM-DD'),
            this.dateRange.endDate = moment().format('YYYY-MM-DD')
            this.$refs.datatableTotal.refresh();
        },
        clearFilterBreakdown(){
            this.filterBreakdown = '';
            this.searchKeywordBreakdown = '';
            this.company_id = '';
            this.startDateBreakdown = moment().subtract(7, 'days').format('YYYY-MM-DD'),
            this.endDateBreakdown = moment().format('YYYY-MM-DD')
            this.getBreakdown();
            this.dateFilterFunction();
            // this.$refs.datatableBreakdown.refresh();
        },
        clearFilterUpcoming(){
            this.filterUpcoming = '';
            this.searchKeywordUpcoming = '';
            this.company_id = '';
            this.service_category_id = '';
            this.closed_by = '';
            this.job_start_time = null;
            this.selectedTime = [];
            this.dateRangeUpcoming.startDate = moment().format('YYYY-MM-DD'),
            this.dateRangeUpcoming.endDate = moment().add(7, 'days').format('YYYY-MM-DD')
            this.getUpcoming();
            this.dateFilterFunction();
        },
        clearFilterUpcomingV1(){
            this.filterUpcomingV1 = '';
            this.searchKeywordUpcomingV1 = '';
            this.company_id_upcoming_v1 = 'all';
            this.closed_by_upcoming_v1 = 'all';
            this.job_start_time_upcoming_v1 = null;
            this.selectedTimeUpcomingV1 = [];
            this.dateRangeUpcomingV1.startDate = moment().format('YYYY-MM-DD'),
            this.dateRangeUpcomingV1.endDate = moment().add(7, 'days').format('YYYY-MM-DD')
            this.getUpcomingV1();
            this.dateFilterFunction();
        },
        clearFilterPast(){
            this.filterPast = '';
            this.searchKeywordPast = '';
            this.company_id = '';
            this.service_category_id = '';
            this.closed_by = '';
            this.job_start_time = null;
            this.selectedTime = [];
            this.dateRangePast.startDate = moment().subtract(8, 'days').format('YYYY-MM-DD'),
            this.dateRangePast.endDate = moment().subtract(1, 'days').format('YYYY-MM-DD')
            this.getPast();
            this.dateFilterFunction();
        },
        // exportTotal(){
        //     var base_url = this.webServerUrl;
        //     var page= this.currentPage;
        //     var rowsPerPage= this.form.rowsPerPage;
        //     var start_date= this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : '';
        //     var end_date= this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') :  '';
        //     var sortBy=  this.sortBy ? this.sortBy : 'user_certificates.created_at';
        //     var sortDirection=this.sortDesc ? 'asc' : 'desc';
        //     var export_url = '/api/daily-deployment-total-excel/'+page+'/'+rowsPerPage+'/'+start_date+'/'+end_date+'/'+sortBy+'/'+sortDirection;
        //     window.open(base_url+export_url,'_blank');
        //     // window.open(export_url,'_blank')

        // },
        exportTotal(){
            var base_url = this.webUrlV1;
            var comp = (this.user_type_id == 2) && (this.designation == 'external agent') ? this.attachedCompanies :(this.company_id != '' ? this.company_id : 'null');
            comp = comp == 'null' && this.company_id == '' ? 'all' : comp
            var start_date = this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : '';
            var end_date = this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') : '';

            var time = 'null';
            var export_url = '/api/export-daily-deployment/allStatus/'+start_date+'/'+end_date+'/null/' +comp+ '/'+time;

            window.open(base_url+export_url,'_blank')
        },
        exportUpcomingV1(){
            var base_url = this.webUrlV1;
            // var comp = (this.user_type_id == 2) && (this.designation == 'external agent') ? this.attachedCompanies : (this.company_id_upcoming_v1 != '' ? this.company_id_upcoming_v1 : 'null');
            // comp = comp == 'null' && this.company_id_upcoming_v1 == 'all' ? 'all' : comp
            var comp = this.company_id_upcoming_v1 != '' ? this.company_id_upcoming_v1 : 'null';
            var start_date = this.dateRangeUpcomingV1.startDate ? moment(this.dateRangeUpcomingV1.startDate).format('YYYY-MM-DD') : '';
            var end_date = this.dateRangeUpcomingV1.endDate ? moment(this.dateRangeUpcomingV1.endDate).format('YYYY-MM-DD') : '';
            var keyword = this.searchKeywordUpcomingV1 ? this.searchKeywordUpcomingV1 : null;
            var time = (this.job_start_time_upcoming_v1 ? this.job_start_time_upcoming_v1.map(obj => obj.start_time):  'null');
            var export_url = '/api/export-daily-deployment/upcoming/'+start_date+'/'+end_date+'/'+ keyword +'/'+comp+ '/'+time;
            window.open(base_url+export_url,'_blank')
        },
        exportBreakdown(){
            if(this.startDateBreakdown && this.endDateBreakdown){
                var base_url = this.webServerUrl;
                var page= this.currentPageBreakdown;
                var rowsPerPage= this.form.rowsPerPageBreakdown;
                var keyword = this.searchKeywordBreakdown ? this.searchKeywordBreakdown : null;
                var start_date= this.startDateBreakdown ? moment(this.startDateBreakdown).format('YYYY-MM-DD') : '';
                var end_date= this.endDateBreakdown ? moment(this.endDateBreakdown).format('YYYY-MM-DD') : '';

                var sortBy=  this.sortByBreakdown ? this.sortByBreakdown : 'j.start_date';
                var sortDirection=this.sortDescBreakdown ? 'desc' : 'asc';
                var attachedCompanies = (this.user_type_id == 2) && (this.designation == 'external agent') ? this.attachedCompanies :(this.company_id != '' ? this.company_id : 'null');
                var company_id= this.company_id ? this.company_id : 'null'
                var export_url = '/api/daily-deployment-breakdown-excel/'+keyword+'/'+company_id+'/'+attachedCompanies+'/'+page+'/'+rowsPerPage+'/'+start_date+'/'+end_date+'/'+sortBy+'/'+sortDirection;
                window.open(base_url+export_url,'_blank')
            }else{
                this.error_message = 'Please select Start Date and End Date';
                this.showAlert();
            }

        },
        exportUpcoming(){
                var base_url = this.webServerUrl;
                var page= this.currentPageUpcoming;
                var rowsPerPage= this.form.rowsPerPageUpcoming;
                var keyword = this.searchKeywordUpcoming ? this.searchKeywordUpcoming : null;
                var start_date= this.dateRangeUpcoming.startDate ? moment(this.dateRangeUpcoming.startDate).format('YYYY-MM-DD') : '';
                var end_date= this.dateRangeUpcoming.endDate ? moment(this.dateRangeUpcoming.endDate).format('YYYY-MM-DD') : '';

                var sortBy=  this.sortByUpcoming ? this.sortByUpcoming : 'j.start_date';
                var sortDirection=this.sortDescUpcoming ? 'desc' : 'asc';
                var attachedCompanies = (this.user_type_id == 2) && (this.designation == 'external agent') ? this.attachedCompanies :(this.company_id != '' ? this.company_id : 'null');
                var company_id= this.company_id ? this.company_id : 'null'
                var service_categories_id= this.service_category_id ? this.service_category_id : 'null'
                var closed_by= this.closed_by ? this.closed_by : 'null'
                // var startTimeArr = [];

                // if(this.job_start_time.length > 0){
                //     this.job_start_time.forEach(element => {
                //         startTimeArr.push(element.start_time);
                //     })
                // }
                // var time = this.job_start_time.length > 0 ? startTimeArr : 'null';
                var time = (this.job_start_time ? this.job_start_time.map(obj => obj.start_time):  'null');

                var export_url = '/api/daily-deployment-upcoming-excel/'+keyword+'/'+company_id+'/'+attachedCompanies+'/'+page+'/'+rowsPerPage+'/'+start_date+'/'+end_date+'/'+service_categories_id+'/'+closed_by+'/'+time+'/'+sortBy+'/'+sortDirection;
                window.open(base_url+export_url,'_blank')

        },
        exportPast(){
                var base_url = this.webServerUrl;
                var page= this.currentPagePast;
                var rowsPerPage= this.form.rowsPerPagePast;
                var keyword = this.searchKeywordPast ? this.searchKeywordPast : null;
                var start_date= this.dateRangePast.startDate ? moment(this.dateRangePast.startDate).format('YYYY-MM-DD') : '';
                var end_date= this.dateRangePast.endDate ? moment(this.dateRangePast.endDate).format('YYYY-MM-DD') : '';

                var sortBy=  this.sortByPast ? this.sortByPast : 'j.start_date';
                var sortDirection=this.sortDescPast ? 'desc' : 'asc';
                var attachedCompanies = (this.user_type_id == 2) && (this.designation == 'external agent') ? this.attachedCompanies :(this.company_id != '' ? this.company_id : 'null');
                var company_id= this.company_id ? this.company_id : 'null'
                var service_categories_id= this.service_category_id ? this.service_category_id : 'null'
                var closed_by= this.closed_by ? this.closed_by : 'null'
                var time = (this.job_start_time ? this.job_start_time.map(obj => obj.start_time):  'null');
                var export_url = '/api/daily-deployment-past-excel/'+keyword+'/'+company_id+'/'+attachedCompanies+'/'+page+'/'+rowsPerPage+'/'+start_date+'/'+end_date+'/'+service_categories_id+'/'+closed_by+'/'+time+'/'+sortBy+'/'+sortDirection;
                window.open(base_url+export_url,'_blank')
        },
        getTotal() {
            return this.$store.dispatch(POST_API, {
                data:{
                    page: this.currentPage,
                    keyword: this.filter,
                    type:this.currentTab,
                    start_date:this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : '',
                    end_date:this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') : '',
                    rowsPerPage:this.form.rowsPerPage,
                    sortBy: this.sortBy ? this.sortBy: 'start_date',
                    sortDirection:this.sortDesc ? 'desc' : 'asc',
                    sortDesc:this.sortDesc ? true : false,

                    from_v2: 'yes',
                    logged_in_user: this.user_id,
                },
                api: '/api/daily-deployment-total'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    this.items = this.$store.getters.getResults.data.data;
                    this.getTotalGraph();
                    return this.items;
                }
            });
        },
        getTotalGraph() {
            return this.$store.dispatch(POST_API, {
                data:{
                    page: this.currentPage,
                    keyword: this.filter,
                    type:this.currentTab,
                    start_date:this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : '',
                    end_date:this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') : '',
                    rowsPerPage:this.form.rowsPerPage,
                    sortBy: 'start_date',
                    sortDirection:'asc',
                    sortDesc: false,

                    from_v2: 'yes',
                    logged_in_user: this.user_id,
                },
                api: '/api/daily-deployment-total'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    this.itemsGraph = this.$store.getters.getResults.data.data;
                    var allDatesArr = [];
                    var totalDeployedArr = [];
                    if(this.itemsGraph.length > 0){
                        this.itemsGraph.forEach(element => {
                            allDatesArr.push(element.date);
                            totalDeployedArr.push(element.total_count);
                        });
                        const totalCount = totalDeployedArr.reduce((acc, value) => {
                            return acc + parseInt(value);
                        }, 0);
                        this.total_uploaded = totalCount > 0 ? totalCount : 0;

                        this.totalDeployedArr = totalDeployedArr;
                        this.allDatesArr = allDatesArr;
                        this.chartDataArr = {
                            labels:this.allDatesArr,
                            datasets: [{
                                label: ' ',
                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                borderColor: 'rgba(54, 162, 235, 1)', // Blue color
                                borderWidth: 1,
                                data: this.totalDeployedArr
                            }]
                        };

                        const ctx = this.$refs.lineChart.getContext('2d');
                        new Chart(ctx, {
                            type: 'line',
                            data: this.chartDataArr,
                            options: this.chartOptions,
                            elements: {
                            line: {
                                tension: 0, // Make lines straight
                            }
                            }
                        });
                    }else{
                        this.totalDeployedArr = [];
                        this.allDatesArr = [];
                        const ctx = this.$refs.lineChart.getContext('2d');
                        new Chart(ctx, {
                            type: 'line',
                            data: this.chartDataArr,
                            options: this.chartOptions,
                            elements: {
                            line: {
                                tension: 0, // Make lines straight
                            }
                            }
                        });
                    }
                }
            });
        },
        getBreakdown() {
            if(this.startDateBreakdown && this.endDateBreakdown){
                return this.$store.dispatch(POST_API, {
                    data:{
                        page: this.currentPageBreakdown,
                        keyword: this.searchKeywordBreakdown,
                        type:this.currentTab,
                        start_date: this.startDateBreakdown ? moment(this.startDateBreakdown).format('YYYY-MM-DD') : '',
                        end_date:  this.endDateBreakdown ? moment(this.endDateBreakdown).format('YYYY-MM-DD') : '',
                        rowsPerPage:this.form.rowsPerPageBreakdown,
                        sortBy: this.sortByBreakdown ? this.sortByBreakdown: 'id',
                        sortDirection:this.sortDescBreakdown ? 'desc' : 'asc',
                        attachedCompanies: this.attachedCompanies,
                        company_id:this.company_id
                    },
                    api: '/api/daily-deployment-breakdown'
                })
                .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    this.itemsBreakdown = this.$store.getters.getResults.pageOfItems;
                    this.totalRowsBreakdown = this.$store.getters.getResults.pager.totalItems;
                    this.perPageBreakdown = this.$store.getters.getResults.pager.pageSize;
                    this.fromBreakdown = this.$store.getters.getResults.pager.from;
                    this.toBreakdown = this.$store.getters.getResults.pager.to;
                    this.getAllCompaniesByStatus('individual');

                    if (this.itemsBreakdown.length > 0) {
                        const columnSums = {};
                        this.itemsBreakdown.forEach(item => {
                            Object.keys(item).forEach(key => {
                                if (typeof item[key] === 'number') {
                                    columnSums[key] = (columnSums[key] || 0) + item[key];
                                }
                            });
                        });

                        const sumRow = {};
                        Object.keys(this.itemsBreakdown[0]).forEach(key => {
                            if (typeof this.itemsBreakdown[0][key] === 'number') {
                                sumRow[key] = columnSums[key] || 0;
                            } else {
                                sumRow[key] = 'Total Deployed';
                            }
                        });

                        this.itemsBreakdown.push(sumRow);

                        this.fieldsBreakdownDynamic = Object.keys(this.itemsBreakdown[0])
                            .filter(key => key !== 'user_jobs_count' && key != 'thumbnail' && key !== 'email'  && key != 'profile_image' && key != 'user_id' && key != 'com_id')
                            .map((key,index) => ({
                                key: key,
                                label: key === 'company_name' ? 'Company' : key,
                                sortable: key === 'company_name',
                                thStyle: index === 0 ? { width: '20%'} : {}
                            }));
                        }
                    }
                });
        }else{
            this.error_message = 'Please select Start Date and End Date';
            this.showAlert();
        }
       },
        getUpcoming() {
            return this.$store.dispatch(POST_API, {
                data:{
                    page: this.currentPageUpcoming,
                    keyword: this.searchKeywordUpcoming,
                    type:this.currentTab,
                    start_date: this.dateRangeUpcoming.startDate ? moment(this.dateRangeUpcoming.startDate).format('YYYY-MM-DD') : '',
                    end_date: this.dateRangeUpcoming.endDate ? moment(this.dateRangeUpcoming.endDate).format('YYYY-MM-DD') : '',
                    rowsPerPage:this.form.rowsPerPageUpcoming,
                    sortBy: this.sortByUpcoming ? this.sortByUpcoming: 'id',
                    sortDirection:this.sortDescUpcoming ? 'desc' : 'asc',
                    sortDesc:this.sortDescUpcoming ? true : false,
                    from_v2: 'yes',
                    logged_in_user: this.user_id,
                    start_time: (this.job_start_time ? this.job_start_time.map(obj => obj.start_time):  null),
                    company_id:this.company_id,
                    closed_by:this.closed_by,
                    service_categories_id:this.service_category_id,
                    attachedCompanies:this.attachedCompanies
                },
                api: '/api/daily-deployment-upcoming'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    this.itemsUpcoming = this.$store.getters.getResults.pageOfItems;
                    this.totalCountsUpcoming = this.$store.getters.getResults.totalCounts;
                    this.totalJobsCategoryCountsUpcoming = this.$store.getters.getResults.totalJobsCategoryCounts;

// console.log(this.totalJobsCountsUpcoming,'this.totalJobsCountsUpcoming',this.totalJobsCountsUpcoming['20-04-2024'].totalJobs,'ddd')
                    this.totalRowsUpcoming = this.$store.getters.getResults.pager.totalItems;
                    this.perPageUpcoming = this.$store.getters.getResults.pager.pageSize;
                    this.fromUpcoming = this.$store.getters.getResults.pager.from;
                    this.toUpcoming = this.$store.getters.getResults.pager.to;
                    this.getAllCompaniesByStatus('upcoming');
                    this.getAllServiceCategoryByStatus('upcoming');
                    this.getAllClosedByByStatus();

                    const distinctStartTimes = this.itemsUpcoming.flatMap(page =>
                    Object.values(page.jobs)
                        .flatMap(jobArray => jobArray.map(job => job.job_data.start_time))
                    ).filter((start_time, index, self) =>
                        index === self.findIndex(t => t === start_time)
                    ).map(start_time => ({ start_time })); // Add a key named start_time for each value

                    this.selectedTime = distinctStartTimes;
                    // console.log(this.selectedTime);
                        return this.itemsUpcoming;
                    }
            });
        },
        getUpcomingV1() {
            return this.$store.dispatch(POST_API, {
                data:{
                    status: this.form.userStatus,
                    page: this.currentPageUpcomingV1,
                    keyword: this.searchKeywordUpcomingV1,
                    rowsPerPage:this.form.rowsPerPageUpcomingV1,
                    sortBy:this.sortByUpcomingV1,
                    sortDirection:this.sortDescUpcomingV1 ? 'desc' : 'asc',
                    sortDesc:this.sortDescUpcomingV1 ? true : false,
                    start_date : this.dateRangeUpcomingV1.startDate ? moment(this.dateRangeUpcomingV1.startDate).format('YYYY-MM-DD') : '',
                    end_date : this.dateRangeUpcomingV1.endDate ? moment(this.dateRangeUpcomingV1.endDate).format('YYYY-MM-DD') : '',
                    closed_by : this.closed_by_upcoming_v1,
                    // start_time: (this.job_start_time_upcoming_v1 ? this.job_start_time_upcoming_v1.map(obj => obj.start_time):  null),
                    start_time: this.job_start_time_upcoming_v1
                    ? this.job_start_time_upcoming_v1.map(obj => 
                        moment(obj.start_time, 'hh:mm A').format('HH:mm:ss')
                    )
                    : null,
                    company_id: this.company_id_upcoming_v1,
                    from_v2: 'yes',
                    logged_in_user: this.user_id,
                },
                api: '/api/daily-deployment-upcoming-v1'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                    return [];
                } else {
                    this.upcomingV1 = this.$store.getters.getResults.data.data;
                    this.totalRowsUpcomingV1 = this.$store.getters.getResults.data.total;
                    this.perPageUpcomingV1 = this.$store.getters.getResults.data.per_page;
                    this.fromUpcomingV1 = this.$store.getters.getResults.data.from
                    this.toUpcomingV1 = this.$store.getters.getResults.data.to
                    // this.sum_upcoming_v1 = this.$store.getters.getResults.sum

                    this.upcomingV1.forEach(job => {
                        if (job.start_time) {
                            job.start_time = moment(job.start_time, 'HH:mm:ss').format('hh:mm A');
                        }
                    });

                    this.selectedTimeUpcomingV1 = this.upcomingV1.filter((job, index, self) => {
                        return index === self.findIndex(j => j.start_time === job.start_time);
                    });

                    var vacancies =  Object.values(this.upcomingV1).reduce((a, b) => a + b.true_vacancies, 0)
                    var pend =  Object.values(this.upcomingV1).reduce((a, b) => a + b.job_upcoming_count, 0)
                    var awar =  Object.values(this.upcomingV1).reduce((a, b) => a + b.selected_candidates_data_count, 0)
                    var self =  Object.values(this.upcomingV1).reduce((a, b) => a + b.total_applicants_withdraw_self_count, 0)
                    var system =  Object.values(this.upcomingV1).reduce((a, b) => a + b.total_applicants_withdraw_system_count, 0)

                    this.counts[0]['name'] = '';
                    this.counts[0]['title'] = 'Total';
                    this.counts[0]['vacancies'] = vacancies;
                    this.counts[0]['pending_count'] = pend;
                    this.counts[0]['awarded_count'] = awar;
                    this.counts[0]['self_withdraw_count'] = self;
                    this.counts[0]['system_withdraw_count'] = system;

                    console.log(this.currentPageUpcoming, 'pagw', this.counts)

                    // this.dateFilterFunction()
                    return this.upcomingV1;

                }
            });
        },
         getPast() {
            return this.$store.dispatch(POST_API, {
                data:{
                    page: this.currentPagePast,

                    keyword: this.searchKeywordPast,
                    type:this.currentTab,
                    start_date: this.dateRangePast.startDate ? moment(this.dateRangePast.startDate).format('YYYY-MM-DD') : '',
                    end_date: this.dateRangePast.endDate ? moment(this.dateRangePast.endDate).format('YYYY-MM-DD') : '',
                    // start_date:'2021-01-01',
                    // end_date:'2025-12-31',
                    rowsPerPage:this.form.rowsPerPagePast,
                    sortBy: this.sortByPast ? this.sortByPast: 'id',
                    sortDirection:this.sortDescPast ? 'desc' : 'asc',
                    sortDesc:this.sortDescPast ? true : false,
                    from_v2: 'yes',
                    logged_in_user: this.user_id,
                    start_time: (this.job_start_time ? this.job_start_time.map(obj => obj.start_time):  null),
                    company_id:this.company_id,
                    closed_by:this.closed_by,
                    service_categories_id:this.service_category_id,
                    attachedCompanies:this.attachedCompanies
                },
                api: '/api/daily-deployment-past'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    this.itemsPast = this.$store.getters.getResults.pageOfItems;
                    this.totalCountsPast = this.$store.getters.getResults.totalCounts;
                    this.totalJobsCategoryCountsPast = this.$store.getters.getResults.totalJobsCategoryCounts;

                    this.totalRowsPast = this.$store.getters.getResults.pager.totalItems;
                    this.perPagePast = this.$store.getters.getResults.pager.pageSize;
                    this.fromPast = this.$store.getters.getResults.pager.from;
                    this.toPast = this.$store.getters.getResults.pager.to;
                    this.getAllCompaniesByStatus('past');
                    this.getAllServiceCategoryByStatus('past');
                    this.getAllClosedByByStatus();

                    const distinctStartTimes = this.itemsPast.flatMap(page =>
                    Object.values(page.jobs)
                        .flatMap(jobArray => jobArray.map(job => job.job_data.start_time))
                        ).filter((start_time, index, self) =>
                            index === self.findIndex(t => t === start_time)
                        ).map(start_time => ({ start_time })); // Add a key named start_time for each value

                    this.selectedTime = distinctStartTimes;

                    let pastCountArray = [];

                    this.itemsPast.forEach(item => {
                        Object.values(item.jobs).forEach(jobs => {
                            jobs.forEach(job => {
                                pastCountArray.push(job.job_data.job_completed_suspended_count);
                            });
                        });
                    });

                    this.total_uploaded_past = pastCountArray.reduce((total, count) => total + count, 0) > 0 ? pastCountArray.reduce((total, count) => total + count, 0) : 0;
                    console.log("Total Job Past Count:", this.total_uploaded_past);
                    return this.itemsPast;
                }
            });
        },

        getAllCompaniesByStatus(status){
            var start_date = '';
            var end_date = '';
            console.log('dddd',status)
            if(status == 'upcoming'){
                start_date = this.dateRangeUpcoming.startDate ? moment(this.dateRangeUpcoming.startDate).format('YYYY-MM-DD') : '';
                end_date = this.dateRangeUpcoming.endDate ? moment(this.dateRangeUpcoming.endDate).format('YYYY-MM-DD') : '';
            }else if(status == 'upcoming_v1'){
                start_date = this.dateRangeUpcomingV1.startDate ? moment(this.dateRangeUpcomingV1.startDate).format('YYYY-MM-DD') : '';
                end_date = this.dateRangeUpcomingV1.endDate ? moment(this.dateRangeUpcomingV1.endDate).format('YYYY-MM-DD') : '';
            }else if(status == 'past'){
                start_date = this.dateRangePast.startDate ? moment(this.dateRangePast.startDate).format('YYYY-MM-DD') : '';
                end_date = this.dateRangePast.endDate ? moment(this.dateRangePast.endDate).format('YYYY-MM-DD') : '';
            }else if(status == 'individual'){
                start_date = this.startDateBreakdown ? moment(this.startDateBreakdown).format('YYYY-MM-DD') : '';
                end_date =  this.endDateBreakdown ? moment(this.endDateBreakdown).format('YYYY-MM-DD') : '';
            }
            return this.$store.dispatch(POST_API, {
                 data:{
                    start_date : start_date,
                    end_date : end_date,
                    status:status == 'upcoming_v1' ? 'upcoming' : status,
                    date:this.dateRangeBreakdown,
                    from_v2: 'yes',
                    logged_in_user: this.user_id
                 },
                 api: '/api/daily-deployment-companies-by-status'
              })
              .then(() => {
                  if (this.$store.getters.containsErrors) {
                      this.error_message = this.$store.getters.getErrors;
                      this.showAlert();
                      return [];
                  } else {
                      this.allStatusCompanies = this.$store.getters.getResults.data;
                  }
              });
        },
        getAllServiceCategoryByStatus(status){
            var start_date = '';
            var end_date = '';

            if(status == 'upcoming'){
                start_date = this.dateRangeUpcoming.startDate ? moment(this.dateRangeUpcoming.startDate).format('YYYY-MM-DD') : '';
                end_date = this.dateRangeUpcoming.endDate ? moment(this.dateRangeUpcoming.endDate).format('YYYY-MM-DD') : '';
            }else if(status == 'past'){
                start_date = this.dateRangePast.startDate ? moment(this.dateRangePast.startDate).format('YYYY-MM-DD') : '';
                end_date = this.dateRangePast.endDate ? moment(this.dateRangePast.endDate).format('YYYY-MM-DD') : '';
            }else if(status == 'individual'){
                start_date = this.startDateBreakdown ? moment(this.startDateBreakdown).format('YYYY-MM-DD') : '';
                end_date =  this.endDateBreakdown ? moment(this.endDateBreakdown).format('YYYY-MM-DD') : '';
            }
              return this.$store.dispatch(POST_API, {
                 data:{
                    status:status,
                    start_date : start_date,
                    end_date : end_date,
                 },
                 api: '/api/daily-deployment-category-by-status'
              })
              .then(() => {
                  if (this.$store.getters.containsErrors) {
                      this.error_message = this.$store.getters.getErrors;
                      this.showAlert();
                      return [];
                  } else {
                      this.allServiceCategory = this.$store.getters.getResults.data;
                  }
              });
        },
        getAllClosedByByStatus(){
              return this.$store.dispatch(POST_API, {
                 data:{
                 },
                 api: '/api/daily-deployment-closedby-by-status'
              })
              .then(() => {
                  if (this.$store.getters.containsErrors) {
                      this.error_message = this.$store.getters.getErrors;
                      this.showAlert();
                      return [];
                  } else {
                      this.allClosedBy = this.$store.getters.getResults.data;
                  }
              });
        },
        // getAllCompanies(){
        //     this.$store.dispatch(POST_API, {
        //         data:{
        //         },
        //         api:'/api/employer-job-list'
        //     })
        //     .then(() => {
        //         if (this.$store.getters.containsErrors) {
        //             this.error_message = this.$store.getters.getErrors;

        //             this.$swal({
        //                 position: 'center',
        //                 icon: 'error',
        //                 title: this.error_message,
        //                 showConfirmButton: false,
        //                 timer: 1500
        //             });

        //         } else {
        //             this.allCompanies = this.$store.getters.getResults.data;
        //         }
        //     });
        // },
        permissionStaff(){
            if(this.$store.getters.getCurrentUserPermission){
              if (this.$store.getters.getCurrentUserPermission.data.length > 0 && this.$store.getters.currentUser.user_type_id == 2) {
               var menu = this.$store.getters.getCurrentUserPermission.data;
               this.actions = permission.getPermissionStaff(menu,'Daily Deployment');
               this.actionsEmployer = permission.getPermissionStaff(menu,'Companies');
               this.actionsManageDPJobs = permission.getPermissionStaff(menu,'DP Jobs Admin');

              }
            }
        },
        show(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                return this.actions.indexOf(action) >= 0 ? true : false ;
            }else{
                return true;
            }
        },
        showEmployer(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                return this.actionsEmployer.indexOf(action) >= 0 ? true : false ;
            }else{
                return true;
            }
        },
        showManageDPJobs(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                return this.actionsManageDPJobs.indexOf(action) >= 0 ? true : false ;
            }else{
                return true;
            }
        },
    },
     mounted(){
            $(".show_filter_arrow .table thead th div").contents().unwrap();
            $(".dashboard-content-container .simplebar-scroll-content").animate({
            scrollTop: 0
            }, "fast");
            //document.title = "EL Connect - Jobseeker - Transaction";
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.user_type_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.user_type_id : '');

            this.designation = this.$store.getters.currentUser.designation ? this.$store.getters.currentUser.designation.toLowerCase() : '';
            this.attachedCompanies = (this.$store.getters.currentUser.attached_with_company != null ? this.$store.getters.currentUser.attached_with_company : 'null') ;
            // this.getAllServiceCategoryByStatus();
            if(this.designation == 'external agent'){
                this.changeTabUpcoming()
            }
            this.permissionStaff();



    }
}
</script>
