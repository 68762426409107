import EmailTemplateList from "../components/admin/EmailTemplate/Index.vue";
import EmailTemplateEdit from "../components/admin/EmailTemplate/Edit.vue";
import BroadcastNotificationEmailList from "../components/admin/BroadcastNotificationEmail/Index.vue";

import EducationalQualificationList from "../components/admin/EducationalQualification/Index.vue";
import EducationalQualificationCreate from "../components/admin/EducationalQualification/Create.vue";
import EducationalQualificationEdit from "../components/admin/EducationalQualification/Edit.vue";
import UniversityList from "../components/admin/University/Index.vue";
import UniversityCreate from "../components/admin/University/Create.vue";
import UniversityEdit from "../components/admin/University/Edit.vue";
import NricInitialList from "../components/admin/NricInitial/Index.vue";
import NricInitialCreate from "../components/admin/NricInitial/Create.vue";
import CountryList from "../components/admin/Country/Index.vue";
import NationalityList from "../components/admin/Nationality/Index.vue";
import FieldOfStudyList from "../components/admin/FieldOfStudy/Index.vue";
import FieldOfStudyCreate from "../components/admin/FieldOfStudy/Create.vue";
import FieldOfStudyEdit from "../components/admin/FieldOfStudy/Edit.vue";
import StaffList from "../components/admin/Staff/Index.vue";
import StaffCreate from "../components/admin/Staff/Create.vue";
import StaffEdit from "../components/admin/Staff/Edit.vue";
import StaffViewProfile from "../components/admin/Staff/ViewProfile.vue";
import StaffAttachedUsers from "../components/admin/Staff/AttachedUsers.vue";

import TransactionList from "../components/admin/Transaction/Index.vue";
import PenaltyManagementList from "../components/admin/PenaltyManagement/Index.vue";
import PayoutList from "../components/admin/Payout/Index.vue";
import PayoutView from "../components/admin/Payout/View.vue";

import BroadcastNotificationList from "../components/admin/BroadcastNotification/Index.vue";
import BroadcastNotificationView from "../components/admin/BroadcastNotification/View.vue";
import BroadcastNotificationEdit from "../components/admin/BroadcastNotification/Edit.vue";

import AdminSupportTicketList from "../components/admin/SupportTicket/Index.vue";
import AdminSupportTicketView from "../components/admin/SupportTicket/View.vue";
import AdminSupportMessageList from "../components/admin/SupportMessage/Index.vue";
import AdminSupportMessageView from "../components/admin/SupportMessage/View.vue";
import AdminOnlineTrainingList from "../components/admin/OnlineTraining/Index.vue";

import SubscribedEmailList from "../components/admin/SubscribedEmail/Index.vue";
import BankList from "../components/admin/Bank/Index.vue";
import BankCreate from "../components/admin/Bank/Create.vue";
import BankEdit from "../components/admin/Bank/Edit.vue";
import PostalDistrictList from "../components/admin/PostalDistrict/Index.vue";
import PostalDistrictCreate from "../components/admin/PostalDistrict/Create.vue";
import PostalDistrictEdit from "../components/admin/PostalDistrict/Edit.vue";
import RewardList from "../components/admin/Reward/Index.vue";
import RewardCreate from "../components/admin/Reward/Create.vue";
import RewardEdit from "../components/admin/Reward/Edit.vue";
import ServiceCategoryList from "../components/admin/ServiceCategory/Index.vue";
import ServiceCategoryCreate from "../components/admin/ServiceCategory/Create.vue";
import ServiceCategoryEdit from "../components/admin/ServiceCategory/Edit.vue";
import ServiceCategoryDPList from "../components/admin/ServiceCategoryDP/Index.vue";
import ServiceCategoryDPCreate from "../components/admin/ServiceCategoryDP/Create.vue";
import ServiceCategoryDPEdit from "../components/admin/ServiceCategoryDP/Edit.vue";
import RaceList from "../components/admin/Race/Index.vue";
import RaceCreate from "../components/admin/Race/Create.vue";
import RaceEdit from "../components/admin/Race/Edit.vue";
import PositionLevelList from "../components/admin/PositionLevel/Index.vue";
import PositionLevelCreate from "../components/admin/PositionLevel/Create.vue";
import PositionLevelEdit from "../components/admin/PositionLevel/Edit.vue";
import LocationList from "../components/admin/Location/Index.vue";
import LocationCreate from "../components/admin/Location/Create.vue";
import LocationEdit from "../components/admin/Location/Edit.vue";
import AreaList from "../components/admin/Area/Index.vue";
import AreaCreate from "../components/admin/Area/Create.vue";
import AreaEdit from "../components/admin/Area/Edit.vue";
import CertificateList from "../components/admin/Certificate/Index.vue";
import CertificateCreate from "../components/admin/Certificate/Create.vue";
import CertificateEdit from "../components/admin/Certificate/Edit.vue";
import CertificateTypeList from "../components/admin/CertificateType/Index.vue";
import CertificateTypeCreate from "../components/admin/CertificateType/Create.vue";
import CertificateTypeEdit from "../components/admin/CertificateType/Edit.vue";
import PerHourCostList from "../components/admin/PerHourCost/Index.vue";
import EmploymentStatusList from "../components/admin/EmploymentStatus/Index.vue";
import EmploymentStatusCreate from "../components/admin/EmploymentStatus/Create.vue";
import EmploymentStatusEdit from "../components/admin/EmploymentStatus/Edit.vue";
import DesignationList from "../components/admin/Designation/Index.vue";
import DesignationCreate from "../components/admin/Designation/Create.vue";
import DesignationEdit from "../components/admin/Designation/Edit.vue";
import GeneralSettingList from "../components/admin/GeneralSetting/Index.vue";
import GeneralSettingEdit from "../components/admin/GeneralSetting/Edit.vue";
import FaqList from "../components/admin/Faq/Index.vue";
import FaqCreate from "../components/admin/Faq/Create.vue";
import FaqEdit from "../components/admin/Faq/Edit.vue";
import TestimonialList from "../components/admin/Testimonial/Index.vue";
import TestimonialCreate from "../components/admin/Testimonial/Create.vue";
import TestimonialEdit from "../components/admin/Testimonial/Edit.vue";
import StaticPageList from "../components/admin/StaticPage/Index.vue";
import StaticPageEdit from "../components/admin/StaticPage/Edit.vue";
import ServiceAgreement from "../components/admin/ServiceAgreement/index.vue";
import ServiceAgreementEdit from "../components/admin/ServiceAgreement/edit.vue";

import GreetingMessageList from "../components/admin/ELCYGreetingMessages/Index.vue";
import GreetingMessageCreate from "../components/admin/ELCYGreetingMessages/Create.vue";
import GreetingMessageEdit from "../components/admin/ELCYGreetingMessages/Edit.vue";

import ProcessDescriptionList from "../components/admin/ProcessDescription/Index.vue";
import ProcessDescriptionEdit from "../components/admin/ProcessDescription/Edit.vue";
import AppContentList from "../components/admin/AppContent/Index.vue";
import AppContentEdit from "../components/admin/AppContent/Edit.vue";
import BlogList from "../components/admin/Blog/Index.vue";
import BlogCreate from "../components/admin/Blog/Create.vue";
import BlogEdit from "../components/admin/Blog/Edit.vue";

import PastWinnerList from "../components/admin/PastWinner/Index.vue";
import PastWinnerCreate from "../components/admin/PastWinner/Create.vue";
import PastWinnerEdit from "../components/admin/PastWinner/Edit.vue";
import JobseekerContentList from "../components/admin/JobseekerContent/Index.vue";
import JobseekerContentEdit from "../components/admin/JobseekerContent/Edit.vue";
import BannerList from "../components/admin/Banner/Index.vue";
import BannerCreate from "../components/admin/Banner/Create.vue";
import BannerEdit from "../components/admin/Banner/Edit.vue";
import AppBannerList from "../components/admin/AppBanner/Index.vue";
import AppBannerCreate from "../components/admin/AppBanner/Create.vue";
import AppBannerEdit from "../components/admin/AppBanner/Edit.vue";
import PartnerList from "../components/admin/Partner/Index.vue";
import PartnerCreate from "../components/admin/Partner/Create.vue";
import PartnerEdit from "../components/admin/Partner/Edit.vue";
import MarketingPopupList from "../components/admin/MarketingPopup/Index.vue";
import MarketingPopupCreate from "../components/admin/MarketingPopup/Create.vue";
import MarketingPopupEdit from "../components/admin/MarketingPopup/Edit.vue";
import MarketingPopupEmployerList from "../components/admin/MarketingPopupEmployer/Index.vue";
import MarketingPopupEmployerCreate from "../components/admin/MarketingPopupEmployer/Create.vue";
import MarketingPopupEmployerEdit from "../components/admin/MarketingPopupEmployer/Edit.vue";
import JobPosterList from "../components/admin/JobPoster/Index.vue";
import JobPosterCreate from "../components/admin/JobPoster/Create.vue";
import JobPosterEdit from "../components/admin/JobPoster/Edit.vue";
import PermanentPTJobList from "../components/admin/PermanentPTJob/Index.vue";
import PermanentPTJobCreate from "../components/admin/PermanentPTJob/Create.vue";
import PermanentPTJobEdit from "../components/admin/PermanentPTJob/Edit.vue";
import GigplaceList from "../components/admin/Gigplace/Index.vue";
import GigplaceEdit from "../components/admin/Gigplace/Edit.vue";
import PromotionList from "../components/admin/Promotion/Index.vue";
import PromotionCreate from "../components/admin/Promotion/Create.vue";
import PromotionEdit from "../components/admin/Promotion/Edit.vue";
import WhatsAppTemplateList from "../components/admin/WhatsAppTemplate/Index.vue";
import WhatsAppTemplateCreate from "../components/admin/WhatsAppTemplate/Create.vue";
import WhatsAppTemplateEdit from "../components/admin/WhatsAppTemplate/Edit.vue";
import QRList from "../components/admin/QRCode/index.vue";
import QREdit from "../components/admin/QRCode/Edit.vue";
import CampaignList from "../components/admin/Campaign/Index.vue";
import CampaignCreate from "../components/admin/Campaign/Create.vue";
import CampaignEdit from "../components/admin/Campaign/Edit.vue";
import PtuserJob from "../components/admin/jobseeker/dpJobs.vue";

import GalleryList from "../components/admin/Gallery/Index.vue";
import PenaltyRemarkList from "../components/admin/PenaltyRemark/Index.vue";

import ELCoinList from "../components/admin/ELCoin/Index.vue";
import ELCoinCreate from "../components/admin/ELCoin/Create.vue";
import ELCoinEdit from "../components/admin/ELCoin/Edit.vue";
import ELMembershipList from "../components/admin/ELMembership/Index.vue";
import ELMembershipEdit from "../components/admin/ELMembership/Edit.vue";

import EmployerDashboard from "../components/Employer/EmployerDashboard.vue";
import EmployeeProfile from "../components/Employer/empProfile.vue";
import OmSupervisorProfile from "../components/OmSupervisor/OmSupervisorProfile.vue";
import EmployerSetting from "../components/Employer/EmployerSetting.vue";
import EmployerChangePassword from "../components/Employer/ChangePassword.vue";
import JobseekerChangePassword from "../components/Jobseeker/ChangePassword.vue";
import JobseekerDeactivateAccount from "../components/Jobseeker/DeactivateAccount.vue";

import EmployerRatingsReviews from "../components/Employer/RatingsReviews.vue";
import EmployerServiceReport from "../components/Employer/ServiceReport.vue";
import EmployerInvoice from "../components/Employer/Invoice.vue";

import EmployerTicketList from "../components/Employer/Ticket/Index.vue";
import EmployerTicketCreate from "../components/Employer/Ticket/Create.vue";
import EmployerTicketView from "../components/Employer/Ticket/View.vue";

import JobseekerTicketList from "../components/Jobseeker/Ticket/Index.vue";
import JobseekerTicketCreate from "../components/Jobseeker/Ticket/Create.vue";
import JobseekerTicketView from "../components/Jobseeker/Ticket/View.vue";

import PostJob from "../components/Employer/postJob.vue";
import EditJob from "../components/Employer/editJob.vue";
import EditSavedJob from "../components/Employer/editSavedJob.vue";
import RepostJob from "../components/Employer/repostJob.vue";
import RepostJobDaily from "../components/Employer/DailyPaid/repost.vue";

import TicketTypeList from "../components/admin/TicketType/index.vue";
import TicketTypeCreate from "../components/admin/TicketType/create.vue";
import TicketTypeEdit from "../components/admin/TicketType/Edit.vue";

// import AdminDashboard from "../components/admin/Dashboard.vue";
import AdminDashboardList from "../components/admin/AdminDashboard.vue";

import JobseekerDashboard from "../components/Dashboard.vue";
import EmployeeDashboard from "../components/Dashboard.vue";

import JobseekerProfile from "../components/admin/jobseeker/JobseekerProfile.vue";
import empDailyjobList from "../components/admin/jobseeker/empDailyjobList.vue";
import JobseekerList from "../components/admin/JobseekerAdmin/Index.vue";
import JobseekerFtCreate from "../components/admin/JobseekerAdmin/FtCreate.vue";
import JobseekerDpCreate from "../components/admin/JobseekerAdmin/DpCreate.vue";
import JobseekerFtEdit from "../components/admin/JobseekerAdmin/FtEdit.vue";
import JobseekerDpEdit from "../components/admin/JobseekerAdmin/DpEdit.vue";
import JobseekerProfileView from "../components/admin/JobseekerAdmin/JobseekerProfileView.vue";
import JobseekerCertificateManage from "../components/admin/JobseekerAdmin/Certificate/manageCertificate.vue";
import JobseekerCertificateAdd from "../components/admin/JobseekerAdmin/Certificate/addCertificate.vue";
import JobseekerCertificateEdit from "../components/admin/JobseekerAdmin/Certificate/editCertificate.vue";
import JobseekerBadgeManage from "../components/admin/JobseekerAdmin/Badge/manageBadge.vue";

import JobseekerTransactionsbyid from "../components/admin/JobseekerAdmin/transaction.vue";
import JobseekerPayslipbyid from "../components/admin/JobseekerAdmin/payslip.vue";
import JobseekerReferredFlbyid from "../components/admin/JobseekerAdmin/ReferredFreelancer.vue";
import FreelancerRating from "../components/admin/ReviewAndRating/FreelancerRating.vue";

import JobseekerDailyPaidProfileView from "../components/admin/JobseekerAdmin/JobseekerDailyPaidProfileView.vue";
import DailyDeploymentList from "../components/admin/DailyDeployment/Index.vue";
import CompanyPostingList from "../components/admin/CompanyPosting/Index.vue";
import HeatMapList from "../components/admin/HeatMap/Index.vue";

import DailySignupApprovedList from "../components/admin/SignUp/Index.vue";

import FoodHygieneCertList from "../components/admin/Table/FoodHygieneCert/Index.vue";
import SurveyResults from "../components/admin/Table/SurveyResults/Index.vue";
import EmpServiceAgreementList from "../components/admin/Table/EmpServiceAgreement/Index.vue";
import CompanyWhitelist from "../components/admin/Table/CompanyWhitelist/Index.vue";
import OptOutWhatsappBlast from "../components/admin/Table/OptOutWhatsappBlast/Index.vue";

import ChatbotConversations from "../components/admin/Table/ChatbotConversations/Index.vue";
import UploadImage from "../components/admin/Table/UploadImage/Index.vue";
import UploadImageEdit from "../components/admin/Table/UploadImage/Edit.vue";

import UserIncentiveList from "../components/admin/Incentive/UserIncentive/Index.vue";
import IncentiveManagementList from "../components/admin/Incentive/IncentiveManagement/Index.vue";
import IncentiveManagementCreate from "../components/admin/Incentive/IncentiveManagement/Create.vue";
import IncentiveManagementEdit from "../components/admin/Incentive/IncentiveManagement/Edit.vue";
import MasterIncentiveManagementList from "../components/admin/Incentive/IncentiveManagement/MasterIndex.vue";

import JobseekerSettings from "../components/admin/jobseeker/JobseekerSettings.vue";
import jobList from "../components/admin/jobseeker/jobListing.vue";
import dailyjobList from "../components/admin/jobseeker/dailyjobListing.vue";
import dailyJobDetail from "../components/admin/jobseeker/dailyJobDetail.vue";

import jobDetail from "../components/admin/jobseeker/jobDetail.vue";
import EmployerJobList from "../components/admin/jobseeker/EmployerJobList.vue";

import ReferralHistoryList from "../components/admin/Referal/index.vue";
import UserLog from "../components/admin/Logs/index.vue";
import BadgeMaster from "../components/admin/BadgeMaster/Index.vue";
import BadgeCreate from "../components/admin/BadgeMaster/Create.vue";
import BadgeEdit from "../components/admin/BadgeMaster/Edit.vue";
import Holidays from "../components/admin/Holidays/Index.vue";
import HolidayCreate from "../components/admin/Holidays/Create.vue";
import HolidayEdit from "../components/admin/Holidays/Edit.vue";

import EmployerList from "../components/admin/Employer/Index.vue";
import EmployerCreate from "../components/admin/Employer/Create.vue";
import EmployerEdit from "../components/admin/Employer/Edit.vue";
import EmployerAllJobs from "../components/admin/Employer/DailyPaidJobs.vue";
import EmployerAuditJobs from "../components/admin/Employer/AuditJobs.vue";
import EmployerAuditJobsDetail from "../components/admin/Employer/AuditJobsDetail.vue";
import EmployerRating from "../components/admin/ReviewAndRating/EmployerRating.vue";

import EmployerProfileView from "../components/admin/Employer/EmployerProfileView.vue";
import EmpManageUserAdmin from "../components/admin/Employer/ManageUser.vue";
import EmpTransactionAdmin from "../components/admin/Employer/EmpTransaction.vue";

import ServiceReport from "../components/admin/BillsAndReport/EmployerServiceReport.vue";
import EmpInvoices from "../components/admin/BillsAndReport/EmployerInvoices.vue";
import RevenueReport from "../components/admin/BillsAndReport/RevenueReport.vue";
import EmpBalance from "../components/admin/Employer/emBalance.vue";
import PayIncentive from "../components/admin/BillsAndReport/PayIncentive.vue";
import BonusUsers from "../components/admin/BillsAndReport/BonusUsers.vue";
import CreditNote from "../components/admin/BillsAndReport/CreditNote.vue";
import FLTimesheet from "../components/admin/BillsAndReport/FLTimesheet.vue";
import FLBalance from "../components/admin/BillsAndReport/FLBalance.vue";
import FLPayslip from "../components/admin/BillsAndReport/FLPayslip.vue";

import BillingAddress from "../components/admin/Employer/BillingAddress.vue";
import addbillingAddressAdmin from "../components/admin/Employer/AddBillingAddress.vue";
import editbillingAddressAdmin from "../components/admin/Employer/EditbillingAddress.vue";

import EmpGalleryAdmin from "../components/admin/Employer/Gallery.vue";
import EmpLogAdmin from "../components/admin/Employer/empLog.vue";

import EmpManageUserViewAdmin from "../components/admin/Employer/ViewUserProfile.vue";
import EmpManageUserEditAdmin from "../components/admin/Employer/EditUser.vue";
import empManageUserCreateAdmin from "../components/admin/Employer/CreateUser.vue";

import FullTimeJobList from "../components/admin/FullTimeJob/Index.vue";
import FullTimeEmployerJobList from "../components/admin/FullTimeJob/EmployerJobList.vue";
import FullTimeJobViewCandidates from "../components/admin/FullTimeJob/ViewCandidates.vue";
import FullTimeFLProfile from "../components/admin/FullTimeJob/JobseekerProfileView.vue";

import FullTimeJobDetail from "../components/admin/FullTimeJob/JobDetail.vue";
import FullTimePostJob from "../components/admin/FullTimeJob/postJob.vue";
import FullTimeEditJob from "../components/admin/FullTimeJob/editJob.vue";
import FullTimeEditSavedJob from "../components/admin/FullTimeJob/editSavedJob.vue";
import FullTimeRepostJob from "../components/admin/FullTimeJob/repostJob.vue";

import ManageCandidates from "../components/Employer/ManageCandidates.vue";
import ManageJobs from "../components/Employer/ManageJobs.vue";
import SavedJobs from "../components/Employer/SavedJobs.vue";
import ManageAllJobsCandidates from "../components/Employer/ManageAllJobsCandidates.vue";
import JobseekerBookmarkJobList from "../components/Jobseeker/BookmarkJob/Index.vue";
import JobseekerBookmarkEmployerList from "../components/Jobseeker/BookmarkEmployer/Index.vue";
// import JobseekerAppliedJobList from '../components/admin/jobseeker/appliedJobs.vue'
import JobseekerAppliedJobList from "../components/Jobseeker/AppliedJobs/Index.vue";

import EmployerBookmarkJobseekerList from "../components/Employer/BookmarkJobseeker/Index.vue";
import approveJob from "../components/Employer/approveJob.vue";

import payment from "../components/admin/jobseeker/make-payment.vue";
import CampaignBonus from "../components/admin/jobseeker/CampaignBonus.vue";

import elcoins from "../components/ElCoins/coinPurchase.vue";
import userCoins from "../components/Employer/coinsDetail.vue";
import employerJobDetail from "../components/Employer/JobDetail.vue";
import empDailyPaidProfile from "../components/Employer/empDailyPaidProfile.vue";

import billingAddress from "../components/Employer/billing_address.vue";
import addbillingAddress from "../components/Employer/add-billing-address.vue";
import editbillingAddress from "../components/Employer/edit-billing-address.vue";

import cardList from "../components/Employer/card-list.vue";
import addCard from "../components/Employer/add-card.vue";
import transactions from "../components/Employer/transaction.vue";
import jobTransactions from "../components/Employer/jobTransactions.vue";
import empRewardList from "../components/Employer/Reward/Index.vue";
import empRewardView from "../components/Employer/Reward/View.vue";
import empRewardEnquiry from "../components/Employer/Reward/Enquiry.vue";

import EmployerJobseekerProfileView from "../components/Employer/EmployerJobseekerProfileView.vue";
import EmployerJobseekerDPProfileView from "../components/Employer/EmployerJobseekerDPProfileView.vue";

import JobseekerEmployerProfileView from "../components/Jobseeker/JobseekerEmployerProfileView.vue";
import JobseekerEmployerDPProfileView from "../components/Jobseeker/JobseekerEmployerDPProfileView.vue";

import EmployerManageUserList from "../components/Employer/ManageUser/Index.vue";
import EmployerManageUserCreate from "../components/Employer/ManageUser/Create.vue";
import EmployerManageUserEdit from "../components/Employer/ManageUser/Edit.vue";
import EmployerManageUserView from "../components/Employer/ManageUser/View.vue";

import JobseekerPayment from "../components/Jobseeker/ELCoin//make-payment.vue";
import JobseekerElcoins from "../components/Jobseeker/ELCoin//coinPurchase.vue";
import JobseekerUserCoins from "../components/Jobseeker/ELCoin/coinsDetail.vue";
import JobseekerBillingAddress from "../components/Jobseeker/ManageBillingAddress/billing_address.vue";
import JobseekerAddbillingAddress from "../components/Jobseeker/ManageBillingAddress/add-billing-address.vue";
import JobseekerCardList from "../components/Jobseeker/ManageCard/card-list.vue";
import JobseekerAddCard from "../components/Jobseeker/ManageCard/add-card.vue";
import JobseekerTransactions from "../components/Jobseeker/Transaction/Index.vue";
import DailyJobProfile from "../components/admin/jobseeker/dailyjobsprofile.vue";
import IncentiveHistory from "../components/admin/JobseekerAdmin/incentive.vue";
import JobseekerManageDPJobAdmin from "../components/admin/JobseekerAdmin/dpManagejobs.vue";

import ManageCertificate from "../components/admin/jobseeker/managecertificate.vue";
import addCertificates from "../components/admin/jobseeker/addCertificates.vue";
import editCertificates from "../components/admin/jobseeker/editCertificates.vue";

import DailyJobPost from "../components/Employer/DailyPaid/postjobs.vue";
import DailyJobPostAdmin from "../components/admin/ManageDpJobs/postjobs.vue";

import DailyJobEdit from "../components/Employer/DailyPaid/editjobs.vue";
import DailyJobManage from "../components/Employer/DailyPaid/manageJobs.vue";

import LandingPageList from "../components/admin/LandingPage/Index.vue";
import LandingPageCreate from "../components/admin/LandingPage/Create.vue";
import LandingPageEdit from "../components/admin/LandingPage/Edit.vue";

import SrCheckoutForm from "../components/ElCoins/checkout.vue";
import SrReturn from "../components/ElCoins/Srreturn.vue";

import companyDepartments from "../components/admin/Employer/CompanyDepartments.vue";
import addCompanyDepartmentAdmin from "../components/admin/Employer/AddCompanyDepartment.vue";
import editCompanyDepartmentAdmin from "../components/admin/Employer/EditCompanyDepartment.vue";

import TransactionSubTypes from "../components/admin/TransactionSubTypes/Index.vue";
import TransactionSubTypeCreate from "../components/admin/TransactionSubTypes/Create.vue";
import TransactionSubTypeEdit from "../components/admin/TransactionSubTypes/Edit.vue";

import BillingTypes from "../components/admin/BillingTypes/Index.vue";
import BillingTypeCreate from "../components/admin/BillingTypes/Create.vue";
import BillingTypeEdit from "../components/admin/BillingTypes/Edit.vue";

import candidateSearch from "../components/Employer/candidate-search.vue";
import MedicalHistoryList from "../components/admin/MedicalHistory/index.vue";
import MedicalHistoryCreate from "../components/admin/MedicalHistory/create.vue";

import ManageDpJobs from "../components/admin/ManageDpJobs/manageJobs.vue";
import ManageDpJobsPost from "../components/admin/ManageDpJobs/postjobs.vue";
import ManageDpJobsRepost from "../components/admin/ManageDpJobs/repost.vue";
import ManageDpJobsEdit from "../components/admin/ManageDpJobs/editjobs.vue";

export default [
  {
    path: "/v2/admin/dashboard",
    name: "admin-dashboard-list",
    route: "admin-dashboard-list",
    component: AdminDashboardList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/reviews-and-ratings/freelancer-rating",
    name: "freelancer-rating-view",
    route: "freelancer-rating-view",
    component: FreelancerRating,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/reviews-and-ratings/employer-rating",
    name: "employer-rating-view",
    route: "employer-rating-view",
    component: EmployerRating,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/jobseeker/dashboard",
    name: "jobseeker-dashboard",
    route: "jobseeker-dashboard",
    component: JobseekerDashboard,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "jobseeker" },
  },
  {
    path: "/v2/employer/dashboard-demo",
    name: "employee-dashboard",
    route: "employee-dashboard",
    component: EmployeeDashboard,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },

  {
    path: "/v2/jobseeker/jobseeker-profile",
    name: "jobseeker-profile",
    route: "jobseeker-profile",
    component: JobseekerProfile,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "jobseeker" },
  },

  {
    path: "/v2/admin/database/jobseeker-transaction/:id",
    name: "jobseeker-transaction-view",
    route: "jobseeker-transaction-view",
    component: JobseekerTransactionsbyid,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },

  {
    path: "/v2/admin/database/jobseeker-payslip/:id",
    name: "jobseeker-payslip-view",
    route: "jobseeker-payslip-view",
    component: JobseekerPayslipbyid,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },

  {
    path: "/v2/admin/database/jobseeker-referred-fl/:id",
    name: "jobseeker-referred-fl",
    route: "jobseeker-referred-fl",
    component: JobseekerReferredFlbyid,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },

  {
    path: "/v2/admin/database/jobseeker-profile/view/:id",
    name: "jobseeker-profile-view",
    route: "jobseeker-profile-view",
    component: JobseekerProfileView,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/jobseeker/jobseeker-certificate/manage/:id",
    name: "jobseeker-certificate-manage",
    route: "jobseeker-certificate-manage",
    component: JobseekerCertificateManage,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/jobseeker/jobseeker-certificate/add/:id/:cert_id",
    name: "jobseeker-certificate-add",
    route: "jobseeker-certificate-add",
    component: JobseekerCertificateAdd,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/jobseeker/jobseeker-certificate/edit/:id/:cert_id/:edit_id",
    name: "jobseeker-certificate-edit",
    route: "jobseeker-certificate-edit",
    component: JobseekerCertificateEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/jobseeker/jobseeker-badge/manage/:fl_id",
    name: "jobseeker-badge-manage",
    route: "jobseeker-badge-manage",
    component: JobseekerBadgeManage,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/deployment/daily-deployment",
    name: "daily-deployment-list",
    route: "daily-deployment-list",
    component: DailyDeploymentList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/deployment/company-posting",
    name: "company-posting-list",
    route: "company-posting-list",
    component: CompanyPostingList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/heatmap",
    name: "heat-map-list",
    route: "heat-map-list",
    component: HeatMapList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/daily-signup-approved",
    name: "daily-signup-approved",
    route: "daily-signup-approved",
    component: DailySignupApprovedList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/table/food-hygiene-cert",
    name: "food-hygiene-cert-list",
    route: "food-hygiene-cert-list",
    component: FoodHygieneCertList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/table/survey-results",
    name: "survey-results",
    route: "survey-results",
    component: SurveyResults,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/table/emp-service-agreement",
    name: "emp-service-agreement-list",
    route: "emp-service-agreement-list",
    component: EmpServiceAgreementList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/table/company-whitelists",
    name: "company-whitelists",
    route: "company-whitelists",
    component: CompanyWhitelist,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/table/opt-out-whatsapp-blast",
    name: "opt-out-whatsapp-blast",
    route: "opt-out-whatsapp-blast",
    component: OptOutWhatsappBlast,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/table/chatbot-conversations",
    name: "chatbot-conversations",
    route: "chatbot-conversations",
    component: ChatbotConversations,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/table/upload-image",
    name: "upload-image",
    route: "upload-image",
    component: UploadImage,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/upload-image/edit/:id",
    name: "upload-image-edit",
    route: "upload-image-edit",
    component: UploadImageEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/incentives/user-incentive",
    name: "user-incentive-list",
    route: "user-incentive-list",
    component: UserIncentiveList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/incentives/incentive-management",
    name: "incentive-management-list",
    route: "incentive-management-list",
    component: IncentiveManagementList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/incentives/incentive-management/create",
    name: "incentive-management-create",
    route: "incentive-management-create",
    component: IncentiveManagementCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/incentives/incentive-management/edit/:id",
    name: "incentive-management-edit",
    route: "incentive-management-edit",
    component: IncentiveManagementEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/incentives/master-incentive-management",
    name: "master-incentive-management-list",
    route: "master-incentive-management-list",
    component: MasterIncentiveManagementList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/jobseeker-dp-profile/view/:id",
    name: "jobseeker-dp-profile-view",
    route: "jobseeker-dp-profile-view",
    component: JobseekerDailyPaidProfileView,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/jobseeker-settings",
    name: "jobseeker-settings",
    route: "jobseeker-settings",
    component: JobseekerSettings,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "jobseeker" },
  },
  {
    path: "/v2/admin/database/broadcast-notification-email",
    name: "broadcast-notification-email-list",
    route: "broadcast-notification-email-list",
    component: BroadcastNotificationEmailList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/email-template",
    name: "emailtemplate-list",
    route: "emailtemplate-list",
    component: EmailTemplateList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/email-template/edit/:id",
    name: "emailtemplate-edit",
    route: "emailtemplate-edit",
    component: EmailTemplateEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/landing-page",
    name: "landingpage-list",
    route: "landingpage-list",
    component: LandingPageList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/landing-page/create",
    name: "landingpage-add",
    route: "landingpage-add",
    component: LandingPageCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/landing-page/edit/:id",
    name: "landingpage-edit",
    route: "landingpage-edit",
    component: LandingPageEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/employer/dashboard",
    name: "employer-dashboard",
    route: "employer-dashboard",
    component: EmployerDashboard,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/employer/employer-profile",
    name: "employee-profile",
    route: "employee-profile",
    component: EmployeeProfile,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/employer/om-supervisor-profile",
    name: "om-supervisor-profile",
    route: "om-supervisor-profile",
    component: OmSupervisorProfile,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/admin/jobseekers",
    name: "jobseekers-list",
    route: "jobseekers-list",
    component: JobseekerList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/jobseekers/ft-create",
    name: "jobseekers-ft-create",
    route: "jobseekers-ft-create",
    component: JobseekerFtCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/jobseekers/ft-edit/:id",
    name: "jobseekers-ft-edit",
    route: "jobseekers-ft-edit",
    component: JobseekerFtEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/jobseekers/dp-edit/:id",
    name: "jobseekers-dp-edit",
    route: "jobseekers-dp-edit",
    component: JobseekerDpEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/jobseekers/dp-create",
    name: "jobseekers-dp-create",
    route: "jobseekers-dp-create",
    component: JobseekerDpCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },

  {
    path: "/v2/admin/employer",
    name: "employer-list",
    route: "employer-list",
    component: EmployerList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/employer/create",
    name: "employer-create",
    route: "employer-create",
    component: EmployerCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/employer/edit/:id",
    name: "employer-edit",
    route: "employer-edit",
    component: EmployerEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/employer/all-jobs/:id",
    name: "employer-all-jobs",
    route: "employer-all-jobs",
    component: EmployerAllJobs,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/employer/audit-jobs/:id/:company_id",
    name: "employer-audit-jobs",
    route: "employer-audit-jobs",
    component: EmployerAuditJobs,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/employer/audit-jobs-detail/:id/",
    name: "employer-audit-jobs-detail",
    route: "employer-audit-jobs-detail",
    component: EmployerAuditJobsDetail,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/fulltime-job-list",
    name: "fulltime-job-list",
    route: "fulltime-job-list",
    component: FullTimeJobList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/fulltime-emp-job-list/:id",
    name: "fulltime-emp-job-list",
    route: "fulltime-emp-job-list",
    component: FullTimeEmployerJobList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin-fulltimejob-viewcandidates/:id",
    name: "fulltimejob-viewcandidates",
    route: "fulltimejob-viewcandidates",
    component: FullTimeJobViewCandidates,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/fulltime-job-fl-profile/view/:id",
    name: "fulltime-job-fl-profile",
    route: "fulltime-job-fl-profile",
    component: FullTimeFLProfile,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/fulltime/job-detail/:id",
    name: "fullTimeJobDetail",
    route: "fullTimeJobDetail",
    component: FullTimeJobDetail,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/fulltime/post-job",
    name: "fullTimePostJob",
    route: "fullTimePostJob",
    component: FullTimePostJob,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/fulltime/edit-job/:id",
    name: "fullTimeEditJob",
    route: "fullTimeEditJob",
    component: FullTimeEditJob,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/fulltime/edit-drafted-job/:id",
    name: "fullTimeEditSavedJob",
    route: "fullTimeEditSavedJob",
    component: FullTimeEditSavedJob,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/fulltime/repost-job/:id",
    name: "fullTimeRepostJob",
    route: "fullTimeRepostJob",
    component: FullTimeRepostJob,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/employer-profile/view/:id/:company_id",
    name: "employer-profile-view",
    route: "employer-profile-view",
    component: EmployerProfileView,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/educational-qualification",
    name: "educational-qualification-list",
    route: "educational-qualification-list",
    component: EducationalQualificationList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/educational-qualification/create",
    name: "educational-qualification-create",
    route: "educational-qualification-create",
    component: EducationalQualificationCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/educational-qualification/edit/:id",
    name: "educational-qualification-edit",
    route: "educational-qualification-edit",
    component: EducationalQualificationEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/university",
    name: "university-list",
    route: "university-list",
    component: UniversityList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/university/create",
    name: "university-create",
    route: "university-create",
    component: UniversityCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/university/edit/:id",
    name: "university-edit",
    route: "university-edit",
    component: UniversityEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/nric-initial",
    name: "nric-initial-list",
    route: "nric-initial-list",
    component: NricInitialList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/nric-initial/create",
    name: "nric-initial-create",
    route: "nric-initial-create",
    component: NricInitialCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/country",
    name: "country-list",
    route: "country-list",
    component: CountryList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/nationality",
    name: "nationality-list",
    route: "nationality-list",
    component: NationalityList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/field-of-study",
    name: "field-of-study-list",
    route: "field-of-study-list",
    component: FieldOfStudyList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/field-of-study/create",
    name: "field-of-study-create",
    route: "field-of-study-create",
    component: FieldOfStudyCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/field-of-study/edit/:id",
    name: "field-of-study-edit",
    route: "field-of-study-edit",
    component: FieldOfStudyEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/staff",
    name: "staff-list",
    route: "staff-list",
    component: StaffList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/staff/create",
    name: "staff-create",
    route: "staff-create",
    component: StaffCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/staff/edit/:id",
    name: "staff-edit",
    route: "staff-edit",
    component: StaffEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/staff/profile/:id",
    name: "staff-view-profile",
    route: "staff-view-profile",
    component: StaffViewProfile,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/staff/attached-users/:id",
    name: "staff-attached-users",
    route: "staff-attached-users",
    component: StaffAttachedUsers,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/transaction",
    name: "transaction-list",
    route: "transaction-list",
    component: TransactionList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/penalty-management",
    name: "penalty-management-list",
    route: "penalty-management-list",
    component: PenaltyManagementList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/payout",
    name: "payout-list",
    route: "payout-list",
    component: PayoutList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/payout-view/:id",
    name: "payout-view",
    route: "payout-view",
    component: PayoutView,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/broadcast-notification",
    name: "broadcast-notification-list",
    route: "broadcast-notification-list",
    component: BroadcastNotificationList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/broadcast-notification-view/:id",
    name: "broadcast-notification-view",
    route: "broadcast-notification-view",
    component: BroadcastNotificationView,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/broadcast-notification-edit/:id",
    name: "broadcast-notification-edit",
    route: "broadcast-notification-edit",
    component: BroadcastNotificationEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/support-ticket-list",
    name: "admin-support-ticket-list",
    route: "admin-support-ticket-list",
    component: AdminSupportTicketList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/support-ticket-view/:id",
    name: "admin-support-ticket-view",
    route: "admin-support-ticket-view",
    component: AdminSupportTicketView,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/support-message-list",
    name: "admin-support-message-list",
    route: "admin-support-message-list",
    component: AdminSupportMessageList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/support-message/pending/:id",
    name: "admin-support-message-pending",
    route: "admin-support-message-pending",
    component: AdminSupportMessageView,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/support-message/solved/:id",
    name: "admin-support-message-solved",
    route: "admin-support-message-solved",
    component: AdminSupportMessageView,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/online-training",
    name: "admin-online-training",
    route: "admin-online-training",
    component: AdminOnlineTrainingList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },

  {
    path: "/v2/admin/database/subscribed-email",
    name: "subscribed-email-list",
    route: "subscribed-email-list",
    component: SubscribedEmailList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/bank",
    name: "bank-list",
    route: "bank-list",
    component: BankList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/bank/create",
    name: "bank-create",
    route: "bank-create",
    component: BankCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/bank/edit/:id",
    name: "bank-edit",
    route: "bank-edit",
    component: BankEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/postal-district",
    name: "postal-district-list",
    route: "postal-district-list",
    component: PostalDistrictList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/postal-district/create",
    name: "postal-district-create",
    route: "postal-district-create",
    component: PostalDistrictCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/postal-district/edit/:id",
    name: "postal-district-edit",
    route: "postal-district-edit",
    component: PostalDistrictEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/reward",
    name: "reward-list",
    route: "reward-list",
    component: RewardList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/reward/create",
    name: "reward-create",
    route: "reward-create",
    component: RewardCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/reward/edit/:id",
    name: "reward-edit",
    route: "reward-edit",
    component: RewardEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/service-category",
    name: "service-category-list",
    route: "service-category-list",
    component: ServiceCategoryList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/service-category/create",
    name: "service-category-create",
    route: "service-category-create",
    component: ServiceCategoryCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/service-category/edit/:id",
    name: "service-category-edit",
    route: "service-category-edit",
    component: ServiceCategoryEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/service-category-dp",
    name: "service-category-dp-list",
    route: "service-category-dp-list",
    component: ServiceCategoryDPList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/service-category-dp/create",
    name: "service-category-dp-create",
    route: "service-category-dp-create",
    component: ServiceCategoryDPCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/service-category-dp/edit/:id",
    name: "service-category-dp-edit",
    route: "service-category-dp-edit",
    component: ServiceCategoryDPEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/race",
    name: "race-list",
    route: "race-list",
    component: RaceList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/race/create",
    name: "race-create",
    route: "race-create",
    component: RaceCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/race/edit/:id",
    name: "race-edit",
    route: "race-edit",
    component: RaceEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/position-level",
    name: "position-level-list",
    route: "position-level-list",
    component: PositionLevelList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/position-level/create",
    name: "position-level-create",
    route: "position-level-create",
    component: PositionLevelCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/position-level/edit/:id",
    name: "position-level-edit",
    route: "position-level-edit",
    component: PositionLevelEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/location",
    name: "location-list",
    route: "location-list",
    component: LocationList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/location/create",
    name: "location-create",
    route: "location-create",
    component: LocationCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/location/edit/:id",
    name: "location-edit",
    route: "location-edit",
    component: LocationEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/area",
    name: "area-list",
    route: "area-list",
    component: AreaList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/area/create",
    name: "area-create",
    route: "area-create",
    component: AreaCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/area/edit/:id",
    name: "area-edit",
    route: "area-edit",
    component: AreaEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/certificate",
    name: "certificate-list",
    route: "certificate-list",
    component: CertificateList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/certificate/create",
    name: "certificate-create",
    route: "certificate-create",
    component: CertificateCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/certificate/edit/:id",
    name: "certificate-edit",
    route: "certificate-edit",
    component: CertificateEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/certificate-type",
    name: "certificate-type-list",
    route: "certificate-type-list",
    component: CertificateTypeList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/certificate-type/create",
    name: "certificate-type-create",
    route: "certificate-type-create",
    component: CertificateTypeCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/certificate-type/edit/:id",
    name: "certificate-type-edit",
    route: "certificate-type-edit",
    component: CertificateTypeEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/per-hour-cost",
    name: "per-hour-cost-list",
    route: "per-hour-cost-list",
    component: PerHourCostList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/employment-status",
    name: "employment-status-list",
    route: "employment-status-list",
    component: EmploymentStatusList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/employment-status/create",
    name: "employment-status-create",
    route: "employment-status-create",
    component: EmploymentStatusCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/employment-status/edit/:id",
    name: "employment-status-edit",
    route: "employment-status-edit",
    component: EmploymentStatusEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/designation",
    name: "designation-list",
    route: "designation-list",
    component: DesignationList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/designation/create",
    name: "designation-create",
    route: "designation-create",
    component: DesignationCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/designation/edit/:id",
    name: "designation-edit",
    route: "designation-edit",
    component: DesignationEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/general-setting",
    name: "general-setting-list",
    route: "general-setting-list",
    component: GeneralSettingList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/general-setting/edit",
    name: "general-setting-edit",
    route: "general-setting-edit",
    component: GeneralSettingEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/faq",
    name: "faq-list",
    route: "faq-list",
    component: FaqList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/faq/create",
    name: "faq-create",
    route: "faq-create",
    component: FaqCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/faq/edit/:id",
    name: "faq-edit",
    route: "faq-edit",
    component: FaqEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/testimonial",
    name: "testimonial-list",
    route: "testimonial-list",
    component: TestimonialList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/testimonial/create",
    name: "testimonial-create",
    route: "testimonial-create",
    component: TestimonialCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/testimonial/edit/:id",
    name: "testimonial-edit",
    route: "testimonial-edit",
    component: TestimonialEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/ticket-type",
    name: "ticket-type-list",
    route: "ticket-type-list",
    component: TicketTypeList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/ticket-type/create",
    name: "ticket-type-create",
    route: "ticket-type-create",
    component: TicketTypeCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/ticket-type/edit/:id",
    name: "ticket-type-edit",
    route: "ticket-type-edit",
    component: TicketTypeEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/website-content/static-page",
    name: "static-page-list",
    route: "static-page-list",
    component: StaticPageList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/website-content/static-page/edit/:id",
    name: "static-page-edit",
    route: "static-page-edit",
    component: StaticPageEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },

  {
    path: "/v2/admin/database/service-agreement",
    name: "service-agreement",
    route: "service-agreement",
    component: ServiceAgreement,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/service-agreement/edit",
    name: "service-agreement-edit",
    route: "service-agreement-edit",
    component: ServiceAgreementEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/website-content/process-description",
    name: "process-description-list",
    route: "process-description-list",
    component: ProcessDescriptionList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/website-content/process-description/edit/:id",
    name: "process-description-edit",
    route: "process-description-edit",
    component: ProcessDescriptionEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/app-content",
    name: "app-content-list",
    route: "app-content-list",
    component: AppContentList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/app-content/edit/:id",
    name: "app-content-edit",
    route: "app-content-edit",
    component: AppContentEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/elcy-greeting-messages",
    name: "elcy-greeting-messages-list",
    route: "elcy-greeting-messages-list",
    component: GreetingMessageList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/elcy-greeting-messages/create",
    name: "elcy-greeting-messages-create",
    route: "elcy-greeting-messages-create",
    component: GreetingMessageCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/elcy-greeting-messages/edit/:messageId",
    name: "elcy-greeting-messages-edit",
    route: "elcy-greeting-messages-edit",
    component: GreetingMessageEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/website-content/blog",
    name: "blog-list",
    route: "blog-list",
    component: BlogList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/website-content/blog/create",
    name: "blog-create",
    route: "blog-create",
    component: BlogCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/website-content/blog/edit/:id",
    name: "blog-edit",
    route: "blog-edit",
    component: BlogEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },

  {
    path: "/v2/admin/website-content/past-winner",
    name: "past-winner-list",
    route: "past-winner-list",
    component: PastWinnerList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/website-content/past-winner/create",
    name: "past-winner-create",
    route: "past-winner-create",
    component: PastWinnerCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/website-content/past-winner/edit/:id",
    name: "past-winner-edit",
    route: "past-winner-edit",
    component: PastWinnerEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/jobseeker-content",
    name: "jobseeker-content-list",
    route: "jobseeker-content-list",
    component: JobseekerContentList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/jobseeker-content/edit/:id",
    name: "jobseeker-content-edit",
    route: "jobseeker-content-edit",
    component: JobseekerContentEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/banner",
    name: "banner-list",
    route: "banner-list",
    component: BannerList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/banner/create",
    name: "banner-create",
    route: "banner-create",
    component: BannerCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/banner/edit/:id",
    name: "banner-edit",
    route: "banner-edit",
    component: BannerEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/app-banner",
    name: "app-banner-list",
    route: "app-banner-list",
    component: AppBannerList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/app-banner/create",
    name: "app-banner-create",
    route: "app-banner-create",
    component: AppBannerCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/app-banner/edit/:id",
    name: "app-banner-edit",
    route: "app-banner-edit",
    component: AppBannerEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/partner",
    name: "partner-list",
    route: "partner-list",
    component: PartnerList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/partner/create",
    name: "partner-create",
    route: "partner-create",
    component: PartnerCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/partner/edit/:id",
    name: "partner-edit",
    route: "partner-edit",
    component: PartnerEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/marketing-popup",
    name: "marketing-popup-list",
    route: "marketing-popup-list",
    component: MarketingPopupList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/marketing-popup/create",
    name: "marketing-popup-create",
    route: "marketing-popup-create",
    component: MarketingPopupCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/marketing-popup/edit/:id",
    name: "marketing-popup-edit",
    route: "marketing-popup-edit",
    component: MarketingPopupEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/marketing-popup-employer",
    name: "marketing-popup-employer-list",
    route: "marketing-popup-employer-list",
    component: MarketingPopupEmployerList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/marketing-popup-employer/create",
    name: "marketing-popup-employer-create",
    route: "marketing-popup-employer-create",
    component: MarketingPopupEmployerCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/marketing-popup-employer/edit/:id",
    name: "marketing-popup-employer-edit",
    route: "marketing-popup-employer-edit",
    component: MarketingPopupEmployerEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/website-content/job-poster",
    name: "job-poster-list",
    route: "job-poster-list",
    component: JobPosterList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/website-content/job-poster/create",
    name: "job-poster-create",
    route: "job-poster-create",
    component: JobPosterCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/website-content/job-poster/edit/:id",
    name: "job-poster-edit",
    route: "job-poster-edit",
    component: JobPosterEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/website-content/permanent-pt-job",
    name: "permanent-pt-job-list",
    route: "permanent-pt-job-list",
    component: PermanentPTJobList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/website-content/permanent-pt-job/create",
    name: "permanent-pt-job-create",
    route: "permanent-pt-job-create",
    component: PermanentPTJobCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/website-content/permanent-pt-job/edit/:id",
    name: "permanent-pt-job-edit",
    route: "permanent-pt-job-edit",
    component: PermanentPTJobEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/gigplace",
    name: "gigplace-list",
    route: "gigplace-list",
    component: GigplaceList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/gigplace/edit/:id",
    name: "gigplace-edit",
    route: "gigplace-edit",
    component: GigplaceEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/promotion",
    name: "promotion-list",
    route: "promotion-list",
    component: PromotionList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/promotion/create",
    name: "promotion-create",
    route: "promotion-create",
    component: PromotionCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/promotion/edit/:id",
    name: "promotion-edit",
    route: "promotion-edit",
    component: PromotionEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/website-content/campaign",
    name: "campaign-list",
    route: "campaign-list",
    component: CampaignList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/website-content/campaign/create",
    name: "campaign-create",
    route: "campaign-create",
    component: CampaignCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/website-content/campaign/edit/:id",
    name: "campaign-edit",
    route: "campaign-edit",
    component: CampaignEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/whatsapp-template",
    name: "whatsapp-template-list",
    route: "whatsapp-template-list",
    component: WhatsAppTemplateList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/whatsapp-template/create",
    name: "whatsapp-template-create",
    route: "whatsapp-template-create",
    component: WhatsAppTemplateCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/whatsapp-template/edit/:id",
    name: "whatsapp-template-edit",
    route: "whatsapp-template-edit",
    component: WhatsAppTemplateEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/gallery",
    name: "gallery-list",
    route: "gallery-list",
    component: GalleryList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/penalty-remark",
    name: "penalty-remark-list",
    route: "penalty-remark-list",
    component: PenaltyRemarkList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/el-coin",
    name: "el-coin-list",
    route: "el-coin-list",
    component: ELCoinList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/el-coin/create",
    name: "el-coin-create",
    route: "el-coin-create",
    component: ELCoinCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/el-coin/edit/:id",
    name: "el-coin-edit",
    route: "el-coin-edit",
    component: ELCoinEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/el-membership",
    name: "el-membership-list",
    route: "el-membership-list",
    component: ELMembershipList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/el-membership/edit/:id",
    name: "el-membership-edit",
    route: "el-membership-edit",
    component: ELMembershipEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/referral-history",
    name: "referral-history-list",
    route: "referral-history-list",
    component: ReferralHistoryList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/user-logs",
    name: "user-logs",
    route: "user-logs",
    component: UserLog,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/badge",
    name: "badge-list",
    route: "badge-list",
    component: BadgeMaster,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/badge/create",
    name: "badge-create",
    route: "badge-create",
    component: BadgeCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/badge/edit/:id",
    name: "badge-edit",
    route: "badge-edit",
    component: BadgeEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/holidays",
    name: "holidays-list",
    route: "holidays-list",
    component: Holidays,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/holidays/create",
    name: "holiday-create",
    route: "holiday-create",
    component: HolidayCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/holidays/edit/:id",
    name: "holiday-edit",
    route: "holiday-edit",
    component: HolidayEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },

  {
    path: "/v2/admin/incentive/:id",
    name: "incentive",
    route: "incentive",
    component: IncentiveHistory,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/jobseeker/dp-managejobs/:id",
    name: "jobseekerDPManagejobsAdmin",
    route: "jobseekerDPManagejobsAdmin",
    component: JobseekerManageDPJobAdmin,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/jobseeker/ptuser-jobs/:id",
    name: "jobseekerDPjobsAdmin",
    route: "jobseekerDPjobsAdmin",
    component: PtuserJob,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/settings",
    name: "employer-setting",
    route: "employer-setting",
    component: EmployerSetting,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/change-password",
    name: "employer-change-password",
    route: "employer-change-password",
    component: EmployerChangePassword,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/employer/employer-ratings-reviews",
    name: "employer-ratings-reviews",
    route: "employer-ratings-reviews",
    component: EmployerRatingsReviews,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/employer/employer-service-report",
    name: "employer-service-report",
    route: "employer-service-report",
    component: EmployerServiceReport,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/employer/employer-invoice",
    name: "employer-invoice",
    route: "employer-invoice",
    component: EmployerInvoice,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/employer/employer-ticket-list",
    name: "employer-ticket-list",
    route: "employer-ticket-list",
    component: EmployerTicketList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/employer/employer-ticket-create",
    name: "employer-ticket-create",
    route: "employer-ticket-create",
    component: EmployerTicketCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/employer/employer-ticket-view/:id",
    name: "employer-ticket-view",
    route: "employer-ticket-view",
    component: EmployerTicketView,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/jobseeker/jobseeker-ticket-list",
    name: "jobseeker-ticket-list",
    route: "jobseeker-ticket-list",
    component: JobseekerTicketList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "jobseeker" },
  },
  {
    path: "/v2/jobseeker/jobseeker-ticket-create",
    name: "jobseeker-ticket-create",
    route: "jobseeker-ticket-create",
    component: JobseekerTicketCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "jobseeker" },
  },
  {
    path: "/v2/jobseeker/jobseeker-ticket-view/:id",
    name: "jobseeker-ticket-view",
    route: "jobseeker-ticket-view",
    component: JobseekerTicketView,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "jobseeker" },
  },
  {
    path: "/v2/jobseeker/change-password",
    name: "jobseeker-change-password",
    route: "jobseeker-change-password",
    component: JobseekerChangePassword,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "jobseeker" },
  },
  {
    path: "/v2/jobseeker/deactivate-account",
    name: "jobseeker-deactivate-account",
    route: "jobseeker-deactivate-account",
    component: JobseekerDeactivateAccount,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "jobseeker" },
  },
  {
    path: "/v2/employer/post-job",
    name: "postJob",
    route: "postJob",
    component: PostJob,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/edit-job/:id",
    name: "editJob",
    route: "editJob",
    component: EditJob,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/edit-drafted-job/:id",
    name: "editSavedJob",
    route: "editSavedJob",
    component: EditSavedJob,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/repost-job/:id",
    name: "repostJob",
    route: "repostJob",
    component: RepostJob,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/repost-job-daily/:id",
    name: "repostJobDaily",
    route: "repostJobDaily",
    component: RepostJobDaily,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/jobseeker/joblist",
    name: "jobList",
    route: "jobList",
    component: jobList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "jobseeker" },
  },

  {
    path: "/v2/jobseeker/daily-joblist/",
    name: "dailyjobList",
    route: "dailyjobList",
    component: dailyjobList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "jobseeker" },
  },

  {
    path: "/v2/jobseeker/emp-daily-joblist/:id",
    name: "empDailyjobList",
    route: "empDailyjobList",
    component: empDailyjobList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "jobseeker" },
  },
  {
    path: "/v2/jobseeker/emp-joblist/:id",
    name: "employerJobList",
    route: "employerJobList",
    component: EmployerJobList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "jobseeker" },
  },
  {
    path: "/v2/jobseeker/ft-jobdetail/:id",
    name: "jobDetail",
    route: "jobDetail",
    component: jobDetail,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "jobseeker" },
  },
  {
    path: "/v2/jobseeker/dp-jobdetail/:id",
    name: "dailyJobDetail",
    route: "dailyJobDetail",
    component: dailyJobDetail,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "jobseeker" },
  },
  {
    path: "/v2/manage-candidates/:id",
    name: "manage-candidates",
    route: "manage-candidates",
    component: ManageCandidates,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/employer/manage-all-jobs-candidates",
    name: "manage-all-jobs-candidates",
    route: "manage-all-jobs-candidates",
    component: ManageAllJobsCandidates,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/employer/manage-jobs",
    name: "manage-jobs",
    route: "manage-jobs",
    component: ManageJobs,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/employer/drafted-jobs",
    name: "saved-jobs",
    route: "saved-jobs",
    component: SavedJobs,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/jobseeker/jobseeker-bookmark-job-list",
    name: "jobseeker-bookmark-job-list",
    route: "jobseeker-bookmark-job-list",
    component: JobseekerBookmarkJobList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "jobseeker" },
  },
  {
    path: "/v2/jobseeker/jobseeker-bookmark-emp-list",
    name: "jobseeker-bookmark-emp-list",
    route: "jobseeker-bookmark-emp-list",
    component: JobseekerBookmarkEmployerList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "jobseeker" },
  },
  {
    path: "/v2/employer/employer-bookmark-jobseeker-list",
    name: "employer-bookmark-jobseeker-list",
    route: "employer-bookmark-jobseeker-list",
    component: EmployerBookmarkJobseekerList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/jobseeker/applied-jobs-list",
    name: "applied-jobs-list",
    route: "applied-jobs-list",
    component: JobseekerAppliedJobList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "jobseeker" },
  },
  {
    path: "/v2/payment",
    name: "paymentst",
    component: payment,
    meta: { requiresAuth: true, hideForAuth: false },
  },
  {
    path: "/v2/employer/ft-jobdetail/:id",
    name: "employerJobDetail",
    route: "employerJobDetail",
    component: employerJobDetail,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },

  {
    path: "/v2/employer/elcoins",
    name: "elcoins",
    component: elcoins,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },

  {
    path: "/v2/employer/user-coins",
    name: "userCoins",
    component: userCoins,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/employer/profile-daily-paid-detail",
    name: "empDailyPaidProfile",
    component: empDailyPaidProfile,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/employer/billing-address-list",
    name: "billingAddress",
    component: billingAddress,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/employer/billing-address-add",
    name: "addbillingAddress",
    component: addbillingAddress,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },

  {
    path: "/v2/employer/approve-job",
    name: "approveJob",
    component: approveJob,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/admin/employer/billing-address-add/:id/:company_id",
    name: "addbillingAddressAdmin",
    component: addbillingAddressAdmin,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/employer/billing-address-edit/:id/:user_id/:company_id",
    name: "editbillingAddressAdmin",
    component: editbillingAddressAdmin,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/employer/billing-address-edit/:id",
    name: "editbillingAddress",
    component: editbillingAddress,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/employer/card-list",
    name: "cardList",
    component: cardList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/employer/card-add",
    name: "addCard",
    component: addCard,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },

  {
    path: "/v2/employer/transactions",
    name: "transactions",
    component: transactions,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },

  {
    path: "/v2/employer/job-transactions",
    name: "jobTransactions",
    component: jobTransactions,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },

  {
    path: "/v2/employer/rewards",
    name: "emp-reward-list",
    route: "emp-reward-list",
    component: empRewardList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },

  {
    path: "/v2/employer/reward-detail/:id",
    name: "emp-reward-view",
    route: "emp-reward-view",
    component: empRewardView,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },

  {
    path: "/v2/employer/reward-enquiry",
    name: "emp-reward-enquiry",
    route: "emp-reward-enquiry",
    component: empRewardEnquiry,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },

  {
    path: "/v2/employer/manage-user",
    name: "empManageUserList",
    route: "empManageUserList",
    component: EmployerManageUserList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/employer/manage-user/create",
    name: "empManageUserCreate",
    route: "empManageUserCreate",
    component: EmployerManageUserCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/employer/manage-user/edit/:id",
    name: "empManageUserEdit",
    route: "empManageUserEdit",
    component: EmployerManageUserEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/employer/manage-user/view/:id",
    name: "emp-manage-user-view",
    route: "emp-manage-user-view",
    component: EmployerManageUserView,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/employer/candidate-search/:id",
    name: "candidateSearch",
    route: "candidateSearch",
    component: candidateSearch,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/jobseeker/payment",
    name: "jobseekerPayment",
    component: JobseekerPayment,
    meta: { requiresAuth: true, hideForAuth: false },
  },
  {
    path: "/v2/jobseeker/elcoins",
    name: "jobseekerElcoins",
    component: JobseekerElcoins,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "jobseeker" },
  },

  {
    path: "/v2/jobseeker/user-coins",
    name: "jobseekerUserCoins",
    component: JobseekerUserCoins,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "jobseeker" },
  },

  {
    path: "jobseeker/billing-address-list",
    name: "jobseekerBillingAddress",
    component: JobseekerBillingAddress,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "jobseeker" },
  },
  {
    path: "/v2/jobseeker/billing-address-add",
    name: "jobseekerAddbillingAddress",
    component: JobseekerAddbillingAddress,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "jobseeker" },
  },
  {
    path: "/v2/jobseeker/card-list",
    name: "jobseekerCardList",
    component: JobseekerCardList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "jobseeker" },
  },
  {
    path: "/v2/jobseeker/card-add",
    name: "jobseekerAddCard",
    component: JobseekerAddCard,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "jobseeker" },
  },
  {
    path: "/v2/jobseeker/transactions",
    name: "jobseekerTransactions",
    component: JobseekerTransactions,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "jobseeker" },
  },

  {
    path: "/v2/employer/jobseeker-profile/view/:id",
    name: "employer-jobseeker-profile-view",
    route: "employer-jobseeker-profile-view",
    component: EmployerJobseekerProfileView,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },

  {
    path: "/v2/employer/jobseeker-dp-profile/view/:id",
    name: "employer-jobseeker-dp-profile-view",
    route: "employer-jobseeker-dp-profile-view",
    component: EmployerJobseekerDPProfileView,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },

  {
    path: "/v2/jobseeker/employer-profile/view/:id",
    name: "jobseeker-employer-profile-view",
    route: "jobseeker-employer-profile-view",
    component: JobseekerEmployerProfileView,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "jobseeker" },
  },
  {
    path: "/v2/jobseeker/employer-dp-profile/view/:id",
    name: "jobseeker-employer-dp-profile-view",
    route: "jobseeker-employer-dp-profile-view",
    component: JobseekerEmployerDPProfileView,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "jobseeker" },
  },

  {
    path: "/v2/jobseeker/daily-job-profile",
    name: "jobseeker-daily-job-profile",
    route: "jobseeker-daily-job-profile",
    component: DailyJobProfile,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "jobseeker" },
  },

  {
    path: "/v2/jobseeker/manage-certificate",
    name: "manage-certificate",
    route: "manage-certificate",
    component: ManageCertificate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "jobseeker" },
  },

  {
    path: "/v2/jobseeker/add-certificate/:id/:cert_id",
    name: "add-certificate",
    route: "add-certificate",
    component: addCertificates,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "jobseeker" },
  },
  {
    path: "/v2/jobseeker/edit-certificate/:id/:cert_id/:edit_id",
    name: "edit-certificate",
    route: "edit-certificate",
    component: editCertificates,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "jobseeker" },
  },

  {
    path: "/v2/employer/dp-job-post",
    name: "DailyJobPost",
    route: "DailyJobPost",
    component: DailyJobPost,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },

  {
    path: "/v2/admin/dp-job-post",
    name: "DailyJobPostAdmin",
    route: "DailyJobPostAdmin",
    component: DailyJobPostAdmin,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/employer/dp-job-edit/:id",
    name: "DailyJobEdit",
    route: "DailyJobEdit",
    component: DailyJobEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },
  {
    path: "/v2/employer/dp-job-manage",
    name: "DailyJobManage",
    route: "DailyJobManage",
    component: DailyJobManage,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "employer" },
  },

  {
    path: "/v2/admin/emp-transaction/:id/:company_id",
    name: "EmpTransactionAdmin",
    route: "EmpTransactionAdmin",
    component: EmpTransactionAdmin,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },

  {
    path: "/v2/admin/emp-manage-user/:id",
    name: "EmpManageUserAdmin",
    route: "EmpManageUserAdmin",
    component: EmpManageUserAdmin,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/bills-and-reports/invoices-list",
    name: "EmpInvoices",
    route: "EmpInvoices",
    component: EmpInvoices,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/bills-and-reports/revenue-report",
    name: "RevenueReport",
    route: "RevenueReport",
    component: RevenueReport,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/bills-and-reports/service-report",
    name: "ServiceReport",
    route: "ServiceReport",
    component: ServiceReport,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/emp-balance",
    name: "EmpBalance",
    route: "EmpBalance",
    component: EmpBalance,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/campaign-bonus",
    name: "CampaignBonus",
    route: "CampaignBonus",
    component: CampaignBonus,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/bills-and-reports/pay-incentive",
    name: "PayIncentive",
    route: "PayIncentive",
    component: PayIncentive,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/bills-and-reports/users-bonus-list",
    name: "BonusUsers",
    route: "BonusUsers",
    component: BonusUsers,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/bills-and-reports/credit-note",
    name: "CreditNote",
    route: "CreditNote",
    component: CreditNote,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/bills-and-reports/fl-timesheet",
    name: "FLTimesheet",
    route: "FLTimesheet",
    component: FLTimesheet,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/bills-and-reports/fl-balance",
    name: "FLBalance",
    route: "FLBalance",
    component: FLBalance,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/bills-and-reports/fl-payslip",
    name: "FLPayslip",
    route: "FLPayslip",
    component: FLPayslip,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/emp-billing-address/:id/:company_id",
    name: "BillingAddress",
    route: "BillingAddress",
    component: BillingAddress,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },

  {
    path: "/v2/admin/emp-gallery-admin/:id",
    name: "emp-gallery-admin",
    route: "emp-gallery-admin",
    component: EmpGalleryAdmin,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/emp-log/:id/:company_id",
    name: "emp-log-admin",
    route: "emp-log-admin",
    component: EmpLogAdmin,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/emp-manage-user-view/:id",
    name: "EmpManageUserViewAdmin",
    route: "EmpManageUserViewAdmin",
    component: EmpManageUserViewAdmin,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/emp-manage-user-edit/:id/:company_id",
    name: "EmpManageUserEditAdmin",
    route: "EmpManageUserEditAdmin",
    component: EmpManageUserEditAdmin,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },

  {
    path: "/v2/admin/emp-manage-user-create/:id",
    name: "empManageUserCreateAdmin",
    route: "empManageUserCreateAdmin",
    component: empManageUserCreateAdmin,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/qr-code",
    name: "qr-code-list",
    route: "qr-code-list",
    component: QRList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/qr-edit/:id",
    name: "qr-code-edit",
    route: "qr-code-edit",
    component: QREdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/employer/checkout",
    component: SrCheckoutForm,
    meta: {
      title: "Payment",
      showFor: "employer",
    },
  },
  {
    path: "/return",
    component: SrReturn,
    meta: { title: "Return" },
  },
  {
    path: "/v2/admin/company-department/:id/:company_id",
    name: "companyDepartments",
    route: "companyDepartments",
    component: companyDepartments,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/employer/company-department/:id/:company_id",
    name: "addCompanyDepartmentAdmin",
    component: addCompanyDepartmentAdmin,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/employer/company-department/:id/:user_id/:company_id",
    name: "editCompanyDepartmentAdmin",
    component: editCompanyDepartmentAdmin,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  // {
  //   path: '/v2/employer/company-departments',
  //   name: 'companyDepartments',
  //   component: companyDepartments,
  //   meta: { requiresAuth: true, hideForAuth:false , showFor:'employer' }
  // },
  // {
  //   path: '/v2/employer/add-company-department',
  //   name: 'addCompanyDepartment',
  //   component: addCompanyDepartment,
  //   meta: { requiresAuth: true, hideForAuth:false  , showFor:'employer'}
  // },
  // {
  //   path: '/v2/employer/edit-company-department/:id',
  //   name: 'editCompanyDepartment',
  //   component: editCompanyDepartment,
  //   meta: { requiresAuth: true, hideForAuth:false  , showFor:'employer'}
  // },

  {
    path: "/v2/admin/database/transaction-sub-types",
    name: "TransactionSubTypes",
    route: "TransactionSubTypes",
    component: TransactionSubTypes,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/transaction-sub-types/create",
    name: "TransactionSubTypeCreate",
    route: "TransactionSubTypeCreate",
    component: TransactionSubTypeCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/transaction-sub-types/edit/:id",
    name: "TransactionSubTypeEdit",
    route: "TransactionSubTypeEdit",
    component: TransactionSubTypeEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/billing-types",
    name: "BillingTypes",
    route: "BillingTypes",
    component: BillingTypes,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/billing-types/create",
    name: "BillingTypeCreate",
    route: "BillingTypeCreate",
    component: BillingTypeCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/billing-types/edit/:id",
    name: "BillingTypeEdit",
    route: "BillingTypeEdit",
    component: BillingTypeEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/medical-history",
    name: "medical-history-list",
    route: "medical-history-list",
    component: MedicalHistoryList,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/medical-history-create",
    name: "medical-history-create",
    route: "medical-history-create",
    component: MedicalHistoryCreate,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/dp-jobs-post",
    name: "dp-jobs-post",
    route: "dp-jobs-post",
    component: ManageDpJobsPost,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/dp-jobs",
    name: "dp-jobs",
    route: "dp-jobs",
    component: ManageDpJobs,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/dp-jobs-repost",
    name: "dp-jobs-repost",
    route: "dp-jobs-repost",
    component: ManageDpJobsRepost,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
  {
    path: "/v2/admin/database/dp-jobs-edit",
    name: "dp-jobs-edit",
    route: "dp-jobs-edit",
    component: ManageDpJobsEdit,
    meta: { requiresAuth: true, hideForAuth: false, showFor: "admin" },
  },
];
